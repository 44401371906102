<uni-modal-confirmation
  [isActive]="isTroubleshootModalActive$ | async"
  [title]="'troubleshooting.modal.toggleConfirm.title' | translate"
  [confirmLabel]="confirmLabel$ | async | translate"
  [confirmationType]="confirmType$ | async"
  [cancelLabel]="'action.cancel' | translate"
  maxWidth="640px"
  (close)="closeTroubleshootModal()"
  (confirm)="confirmTroubleshootMode()"
>
  <ng-container *ngIf="isTroubleshootingEnabled; else troubleshootingDisabled">
    <uni-section [isFirst]="true">
      <uni-section-header>
        <uni-text-header [level]="'300'" [color]="uniTextColor.primary" [isUppercase]="false">
          {{ 'troubleshooting.modal.toggleConfirm.disableHeader' | translate }}
        </uni-text-header>
      </uni-section-header>
    </uni-section>

    <uni-section [isLast]="true">
      <uni-text-body [color]="uniTextColor.secondary" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.disableDescription' | translate }}
      </uni-text-body>
    </uni-section>
  </ng-container>

  <ng-template #troubleshootingDisabled>
    <uni-section [isFirst]="true">
      <uni-section-header>
        <uni-text-header [level]="'300'" [color]="uniTextColor.primary" [isUppercase]="false">
          {{ 'troubleshooting.modal.toggleConfirm.header' | translate }}
        </uni-text-header>
      </uni-section-header>
    </uni-section>

    <uni-section>
      <uni-section-header [isBottomSpace]="false">
        <uni-text-header [color]="uniTextColor.primary" [isUppercase]="false">
          {{ 'troubleshooting.modal.toggleConfirm.sectionHeader1' | translate }}
        </uni-text-header>
      </uni-section-header>

      <uni-text-body [color]="uniTextColor.secondary" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.sectionDescription1' | translate }}
      </uni-text-body>
    </uni-section>

    <uni-section>
      <uni-section-header [isBottomSpace]="false">
        <uni-text-header [color]="uniTextColor.primary" [isUppercase]="false">
          {{ 'troubleshooting.modal.toggleConfirm.sectionHeader2' | translate }}
        </uni-text-header>
      </uni-section-header>

      <uni-text-body [color]="uniTextColor.secondary" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.sectionDescription2' | translate }}
      </uni-text-body>
    </uni-section>

    <uni-section [isLast]="true">
      <uni-section-header [isBottomSpace]="false">
        <uni-text-header [color]="uniTextColor.primary" [isUppercase]="false">
          {{ 'troubleshooting.modal.toggleConfirm.sectionHeader3' | translate }}
        </uni-text-header>
      </uni-section-header>

      <uni-text-body [color]="uniTextColor.secondary" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.sectionDescription3' | translate }}
      </uni-text-body>
    </uni-section>
  </ng-template>
</uni-modal-confirmation>
