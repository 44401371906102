import { isEmpty } from 'lodash';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cc-campaign-recipients-modal',
  templateUrl: './campaign-recipients-modal.component.html',
  styleUrls: ['./campaign-recipients-modal.component.scss'],
})
export class CampaignRecipientsModalComponent {
  @Input() isModalActive = false;
  @Input() invalidNumbers: string[] = [];
  @Input() validNumbers: string[] = [];
  @Output() toggleModal = new EventEmitter();
  @Output() submit = new EventEmitter();

  get hasValidNumbers(): boolean {
    return !isEmpty(this.validNumbers);
  }

  constructor() {}

  changeModalState(): void {
    this.toggleModal.emit();
  }

  onSubmitButton(): void {
    this.submit.emit();
  }
}
