import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';

import { SettingsService } from '../../../shared';

export function HttpLoaderFactory(http: HttpClient, settings: SettingsService) {
  const buildRandomNumber = get(settings, 'environment.buildRandomNumber');

  return new TranslateHttpLoader(http, '/build/i18n/', '.json?v=' + (buildRandomNumber || (+new Date()).toString()));
}

interface JsonFiles {
  en: any;
  ar?: any;
  tr?: any;
  fr?: any;
}

class JsonTranslateLoader implements TranslateLoader {
  constructor(
    private jsonFiles: JsonFiles,
  ) {}

  getTranslation(lang: string): Observable<any> {
    if (lang === 'ar' || lang === 'tr' || lang === 'fr') {
      return of(this.jsonFiles[lang]);
    }

    return of(this.jsonFiles.en);
  }
}

export const jsonTranslateLoaderFactory = (jsonFiles: JsonFiles): TranslateLoader => {
  return new JsonTranslateLoader(jsonFiles);
};

