import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectTimezones, selectTimezonesQuery, TimezonesState } from '../store/uni-timezones';
import * as timezonesActions from '../store/uni-timezones/uni-timezones.actions';

@Injectable()
export class UniTimezonesFacade {
  timezones$ = this.store.pipe(select(selectTimezones));
  timezonesQuery$ = this.store.pipe(select(selectTimezonesQuery));

  constructor(private store: Store<TimezonesState>) {}

  setTimezones(): void {
    this.store.dispatch(new timezonesActions.SetTimezones());
  }
}
