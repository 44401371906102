import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PaginationActions } from '../../shared/uni-pagination.model';

@Component({
  selector: 'uni-pagination-controllers',
  templateUrl: './uni-pagination-controllers.component.html',
  styleUrls: ['./uni-pagination-controllers.component.scss']
})
export class UniPaginationControllersComponent {
  @Input() currentPage: number;
  @Input() pagesAmount: number;
  @Input() hasNextPage: boolean;
  @Output() controllerAction = new EventEmitter();
  next = PaginationActions.next;
  prev = PaginationActions.prev;

  get isNextDisabled(): boolean {
    return this.currentPage >= this.pagesAmount && !this.hasNextPage;
  }

  get isPrevDisabled(): boolean {
    return this.currentPage === 1;
  }

  onControllerAction(action: PaginationActions): void {
    this.controllerAction.emit(action);
  }
}
