import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CountriesState } from './uni-countries.state';

export const selectCountriesFeature = createFeatureSelector<CountriesState>('countries');

export const selectCountries = createSelector(
  selectCountriesFeature,
  (state: CountriesState) => state.countries,
);

export const selectCountriesQuery = createSelector(
  selectCountriesFeature,
  (state: CountriesState) => state.getCountriesQuery,
);

export const selectCountriesChoice = createSelector(
  selectCountriesFeature,
  (state: CountriesState) => state.countriesChoice,
);
