import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-form-multi-autocomplete-controllers',
  templateUrl: './uni-form-multi-autocomplete-controllers.component.html',
  styleUrls: ['./uni-form-multi-autocomplete-controllers.component.scss']
})
export class UniFormMultiAutocompleteControllersComponent {
  @Input() isValidationError = false;
  @Input() isLoading = false;
  @Output() apply = new EventEmitter();
  @Output() clear = new EventEmitter();

  onApply(): void {
    this.apply.next();
  }

  onClear(): void {
    this.clear.next();
  }
}
