<form [formGroup]="searchForm" (keydown)="onSearchKeydown($event)">
  <uni-form-search
    [form]="searchForm"
    [controlName]="controlName"
    [margin]="margin"
    [isClear]="isClear"
    [isLoading]="isLoading"
    [label]="label"
    (searchInputCleared)="onSearchInputCleared()"
  ></uni-form-search>
</form>
