import { Component, HostBinding, Input } from '@angular/core';

import { UniSnackbarFacade } from '../../shared';
import { UniSnackbarProps } from '../uni-snackbar/uni-snackbar.model';

@Component({
  selector: 'uni-snackbar-item',
  templateUrl: 'uni-snackbar-item.component.html',
  styleUrls: ['./uni-snackbar-item.component.scss'],
})
export class UniSnackbarItemComponent {
  @Input() snackbar: UniSnackbarProps;

  @HostBinding('class') get getClass() {
    return `snackbar-item--${this.snackbar.status}`;
  }

  constructor(private uniSnackbarFacade: UniSnackbarFacade) { }

  hideSnackbar() {
    this.uniSnackbarFacade.hide();
  }

  onAction(): void {
    if (this.snackbar.action) {
      this.snackbar.action.action();
      this.uniSnackbarFacade.hide();
    }
  }
}
