import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'uni-form-select',
  templateUrl: './uni-form-select.component.html',
  styleUrls: ['./uni-form-select.component.scss']
})
export class UniFormSelectComponent {
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() data: string[];
  @Input() translation = false;
  @Input() canClear = false;
  @Input() disableOptionCentering = true;

  onClear(): void {
    this.control.setValue('');
  }
}
