import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipOptions, ChartTooltipItem, ChartData, ChartArea } from 'chart.js';
import { setAlpha } from '../../../utils';

@Injectable()
export class UniChartTooltipService {

  constructor(private translateService: TranslateService) { }

  initCustomTooltip(chart, service, tooltipModel) {
    const tooltipEl = document.getElementById('uni-chart-tooltip') as HTMLDivElement;
    const position = chart._chart.canvas.getBoundingClientRect();
    service.setBody(tooltipEl);

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    service.setCaretPosition(tooltipModel, tooltipEl);
    service.setText(tooltipModel, tooltipEl);
    service.setPosition(tooltipModel, tooltipEl, position);
  }

  getTranslation(label: string): string {
    return this.translateService.instant(label);
  }

  setBody(tooltipEl: HTMLDivElement) {
    if (tooltipEl) { return; }

    tooltipEl = document.createElement('div');
    tooltipEl.id = 'uni-chart-tooltip';
    document.body.appendChild(tooltipEl);
  }

  setCaretPosition(tooltipModel, tooltipEl: HTMLDivElement) {
    tooltipEl.classList.remove(
      'y-top',
      'y-bottom',
      'y-center',
      'x-center',
      'x-left',
      'x-right',
    );
    tooltipEl.classList.add(`y-${tooltipModel.yAlign}`);
    tooltipEl.classList.add(`x-${tooltipModel.xAlign}`);
  }

  setTitle(tooltipModel): string {
    return tooltipModel.title.map((title: string, id: number) => {
      const colors = tooltipModel.labelColors[id];
      const backgroundColor = setAlpha(colors.backgroundColor, .05);
      const borderColor = setAlpha(colors.borderColor, 1);

      return (`
        <div class="uni-chart-tooltip__titles">
          <div
            class="uni-chart-tooltip__category"
            style="background: ${backgroundColor}; border-color: ${borderColor};"
          ></div>
          <div class="uni-chart-tooltip__title">${this.getTranslation(title)}</div>
        </div>
      `);
    });
  }

  setLabel(tooltipModel): string {
    return tooltipModel.body.map(({ before, lines, after }) => (`
      <div class="uni-chart-tooltip__data">
        <div class="uni-chart-tooltip__value">
          <div class="uni-chart-tooltip__number">${before[0] || 0}</div>
          <div class="uni-chart-tooltip__unit">${this.getTranslation(lines[0])}</div>
        </div>
        <div class="uni-chart-tooltip__label">${after[0] || '-'}</div>
      </div>
    `)).join('');
  }

  setText(tooltipModel, tooltipEl: HTMLDivElement) {
    if (!tooltipModel.body) {
      return;
    }

    tooltipEl.querySelector('.uni-chart-tooltip__content').innerHTML = `
      ${this.setTitle(tooltipModel)}
      ${this.setLabel(tooltipModel)}`;
  }

  setPosition(tooltipModel, tooltipEl: HTMLDivElement, position: ChartArea) {
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = tooltipModel.caretX + 'px';
    tooltipEl.style.top = tooltipModel.caretY + 'px';
    tooltipEl.style.fontFamily = '"Gotham Rounded", "Tahoma"';
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }

  getDataset(tooltipItem: ChartTooltipItem, data: ChartData) {
    const { datasetIndex } = tooltipItem;
    const { datasets } = data;

    return datasets[datasetIndex];
  }

  getTooltip(): ChartTooltipOptions {
    const initCustomTooltip = this.initCustomTooltip;
    const service = this;

    return {
      enabled: false,
      custom(tooltip) {
        initCustomTooltip(this, service, tooltip);
      },
      callbacks: {
        title: (tooltipItem, data) => {
          const { label } = this.getDataset(tooltipItem[0], data);
          return label;
        },
        beforeLabel: (tooltipItem) => {
          return tooltipItem.yLabel;
        },
        label: (tooltipItem, data) => {
          const { unit } = this.getDataset(tooltipItem, data);
          return unit;
        },
        afterLabel: (tooltipItem) => {
          return tooltipItem.xLabel;
        },
      },
      mode: 'label',
      intersect: false,
    };
  }

}
