import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Settings } from './settings';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class SettingsHttpService {

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {}

  initializeApp(): Promise<Settings> {
    return new Promise<Settings>(resolve => {
      this.http.get<Settings>('assets/settings/settings.json')
        .toPromise()
        .then(response => {
          Object.entries(response).forEach(([key, value])=>{
            this.settingsService.settings[key] = value;
          });
          resolve(response);
        });
      }
    );
  }
}
