import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MessageLogsRepository } from "../../data-access/message-logs.repository";
import { MessageLogsFiltersStore } from "../../store/message-logs-filters.store";

type State = {
    id: string
    loading: boolean
    loaded: boolean
    entity: any
}

const initialState: State = {
    id: '',
    loading: false,
    loaded: false,
    entity: {},
}

@Injectable()
export class MessageLogsTableDetailsVoiceStore extends ComponentStore<State>{

    constructor(
        private messageLogsRepository: MessageLogsRepository,
        private messageLogsFiltersStore: MessageLogsFiltersStore
    ){
        super(initialState)
    }

    //selectors
    readonly id$ = this.select(state => state.id)
    readonly loading$ = this.select(state => state.loading)
    readonly loaded$ = this.select(state => state.loaded)
    readonly entity$ = this.select(state => state.entity)

    readonly isCommInbound$ = this.select(
        this.entity$,
        (entity) => entity.commDirection === 'INBOUND'
    )


    readonly vm$ = this.select(
        this.id$,
        this.loading$,
        this.loaded$,
        this.entity$,
        this.isCommInbound$,
        (id, loading, loaded, entity, isCommInbound) => {
            return {
                id,
                loading,
                loaded,
                entity,
                isCommInbound
            }
        }
    )

    //effects
    readonly fetchData = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(() => {
                this.patchState({loading: true})
            }),
            withLatestFrom(
                this.id$,
                this.messageLogsFiltersStore.accountId$,
                this.messageLogsFiltersStore.queryParams$,
            ),
            switchMap(([_, id, accountId, params]) => this.messageLogsRepository.qpQueryMessageLogsVoiceDetails({
                id,
                ...params,
                limit: 1,
                offset: 0
            }).pipe(
                tapResponse(
                    (entity) => {
                        this.patchState({loading: false, loaded: true, entity: {...entity.entries[0]}})
                    },
                    () => {
                        this.patchState({loading: false, loaded: false, entity: {}})
                    }

                )
            ))
        )
    })

}
