import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-more-items',
  templateUrl: './uni-more-items.component.html',
  styleUrls: ['./uni-more-items.component.scss'],
})
export class UniMoreItemsComponent {
  @Input() items: string[] = [];
  @Input() length = 1;
  @Input() isActive = false;

  get isMoreIcon(): boolean {
    return this.length < this.items.length;
  }

  toggleText(): void {
    this.isActive = !this.isActive;
  }

  displayText(): string {
    if (!this.items.length) {
      return '';
    }

    return this.isActive
      ? this.items.join(', ')
      : this.items[0];
  }
}
