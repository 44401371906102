import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {KeycloakTokenResponse} from './keycloak-client.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class KeycloakRptService {
  constructor(
    @Optional() @Inject('environment') private environment,
    private httpClient: HttpClient,
    private cookieService: CookieService) {
  }

  public getRpt(audience: string): Observable<KeycloakTokenResponse> {
    const keycloakTokenUrl = `${this.environment.keycloakUrl}/auth/realms/unifonic-cloud/protocol/openid-connect/token`;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers = headers.append('Authorization', 'Bearer ' + this.cookieService.get('KC_JWT'));
    const params = new HttpParams()
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
      .set('audience', audience);

    return this.httpClient.post<KeycloakTokenResponse>(keycloakTokenUrl, params, {
      headers,
    });
  }
  public hasRpt(): boolean {
    return localStorage.getItem('access_token') === null;
  }
  public setRpt(keycloakTokenResponse: KeycloakTokenResponse) {
    localStorage.setItem('access_token', keycloakTokenResponse.access_token);
    localStorage.setItem('refresh_token', keycloakTokenResponse.refresh_token);
  }
}
