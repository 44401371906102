<span class="uni-action-widget-content__header">
  <uni-text-body>
    <uni-icon
      *ngIf="isWarning"
      class="uni-action-widget-content__warning-icon"
      [style]="'fas'"
      [name]="'exclamation-triangle'"
    ></uni-icon>

    {{ translationPath + '.header' | translate }}
  </uni-text-body>

  <uni-button
    [style]="'secondary'"
    [fitContent]="true"
    (click)="onActionButtonClick()"
    id="{{ buttonId }}"
  >
    {{ translationPath + '.action' | translate }}
  </uni-button>
</span>

<span
  class="uni-action-widget-content__main-content"
  [ngClass]="{
    'is-reversed': isReversed
  }"
>
  <span *ngIf="numberContent != null" class="uni-action-widget-content__number">
    {{ numberContent }}
  </span>
  {{ rawText || (translationPath + '.text' | translate) }}
</span>
