import { get, isEmpty, xor } from 'lodash';

import { Component, ElementRef, forwardRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'uni-multiselect-list',
  templateUrl: './uni-multiselect-list.component.html',
  styleUrls: ['./uni-multiselect-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniMultiselectListComponent),
      multi: true
    }
  ]
})
export class UniMultiselectListComponent implements ControlValueAccessor {
  @HostBinding('class.uni-multiselect-list--no-margin') @Input() noMargin = false;
  @Input() nameKey: string;
  @Input() versionKey: string;
  @Input() isTotalItems = true;
  @Input() totalItemsKey = ['label.item', 'label.items'];
  @Input() disabled = false;
  @Input() numbers = false;
  value: any[] = [];

  propagateChange: any = () => {};


  @HostBinding('class.is-disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class.is-empty')
  get isEmptyClass(): boolean {
    return isEmpty(this.value);
  }

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
  ) {}

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  onRemove(item: any): void {
    if (this.disabled) {
      return;
    }

    this.value = xor(this.value, [item]);
    this.propagateChange(this.value);
  }

  getName(item: any): string {
    return this.nameKey
      ? get(item, this.nameKey)
      : item;
  }

  isTotalItemsContent(): boolean {
    return !isEmpty(this.value) && this.isTotalItems;
  }

  getTotalItems(): number {
    return isEmpty(this.value) ? 0 : this.value.length;
  }
}
