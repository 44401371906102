import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './uni-data.reducers';
import { UniDataEffects } from './uni-data.effects';
import { UNI_DATA_STATE } from './uni-data.state';
import { AccountsFacade } from '../facade/accounts.facade';
import { AccountsRepository } from '../repository/accounts.repository';
import { OperatorsFacade } from '../facade/operators.facade';
import { OperatorsRepository } from '../repository/operators.repository';
import { SenderNameFacade } from '../facade/sender-names.facade';
import { SenderNamesRepository } from '../repository/sender-names.repository';
import { ProvidersRepository } from '../repository';
import { PackagesFacade, ProvidersFacade } from '../facade';

@NgModule({
  imports: [
    StoreModule.forFeature(UNI_DATA_STATE, reducer),
    EffectsModule.forFeature([UniDataEffects]),
  ],
  providers: [
    AccountsFacade,
    AccountsRepository,
    OperatorsFacade,
    OperatorsRepository,
    SenderNameFacade,
    SenderNamesRepository,
    ProvidersFacade,
    ProvidersRepository,
    PackagesFacade
  ]
})
export class UniDataStateModule { }
