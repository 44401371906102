import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-preview-mobile',
  styleUrls: ['./uni-preview-mobile.component.scss'],
  templateUrl: './uni-preview-mobile.component.html',
})
export class UniPreviewMobileComponent {
  @HostBinding('class.is-thumbnail') @Input() isThumbnail = false;
}
