import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Params, Router } from '@angular/router';

@Component({
  selector: 'uni-table-action',
  templateUrl: './uni-table-action.component.html',
  styleUrls: ['./uni-table-action.component.scss'],
})
export class UniTableActionComponent {
  @Input() name: string;
  @Input() path: string;
  @Input() text: string;
  @Input() params: Params;
  @Input() isExternalPath = false;
  @Input() style = 'far';

  @HostBinding('class.is-expanded') @Input() isExpanded = false;
  @HostBinding('class.is-disabled') @Input() isDisabled = false;
  @HostBinding('class.is-flat') @Input() isFlat = false;
  @HostBinding('class.is-multi') @Input() isMulti = false;

  @HostListener('click')
  onClick() {
    if (!this.path || this.isDisabled) {
      return false;
    }

    if (this.isExternalPath) {
      return this.router.navigate([this.path]);
    }

    return this.router.navigate([this.path], { ...this.params });
  }

  constructor(private router: Router) {}
}
