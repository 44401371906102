import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniWidgetContainerComponent } from './components/uni-widget-container/uni-widget-container.component';
import { UniWidgetComponent } from './components/uni-widget/uni-widget.component';
import { UniWidgetContentComponent } from './components/uni-widget-content/uni-widget-content.component';
import { UniWidgetContentDescriptionComponent } from './components/uni-widget-content-description/uni-widget-content-description.component';
import { UniWidgetContentValueComponent } from './components/uni-widget-content-value/uni-widget-content-value.component';
import { UniWidgetContentSubtitleComponent } from './components/uni-widget-content-subtitle/uni-widget-content-subtitle.component';
import { UniWidgetHeaderComponent } from './components/uni-widget-header/uni-widget-header.component';
import { UniWidgetHeaderTitleComponent } from './components/uni-widget-header-title/uni-widget-header-title.component';

const components = [
  UniWidgetContainerComponent,
  UniWidgetComponent,
  UniWidgetContentComponent,
  UniWidgetContentDescriptionComponent,
  UniWidgetContentValueComponent,
  UniWidgetContentSubtitleComponent,
  UniWidgetHeaderComponent,
  UniWidgetHeaderTitleComponent
];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class UniWidgetModule { }
