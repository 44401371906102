<uni-label *ngIf="!!label">
  {{ label | translate }}
</uni-label>
<div class="uni-form-select__container">
  <mat-form-field *ngIf="form" [formGroup]="form">
    <uni-form-field [margin]="false">
      <mat-select
        [disableOptionCentering]="disableOptionCentering"
        [placeholder]="placeholder | translate"
        [formControl]="control"
      >
        <mat-option *ngFor="let item of data" [value]="item">
          {{ translation ? ('label.' + item | translate) : item }}
        </mat-option>
      </mat-select>
    </uni-form-field>
  </mat-form-field>
  <div
    *ngIf="canClear && control.value && !control.disabled"
    class="uni-form-select__icon"
    uniSuffix
  >
    <uni-icon
      name="times"
      (click)="onClear()"
    ></uni-icon>
  </div>
</div>
