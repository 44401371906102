<ng-container>
  <uni-label>
    {{ 'label.callRetries' | translate }}
  </uni-label>

  <uni-form-container [formGroup]="contentForm">
    <uni-form-field
      [isRow]="true"
      [margin]="retriesEnabledControl.value"
    >
      <div>
        <uni-toggle
          [formControl]="retriesEnabledControl"
          [activeLabel]="'label.retriesEnabled' | translate"
          [inactiveLabel]="'label.disabled' | translate"
          (click)="updateFields()"
        ></uni-toggle>
      </div>
    </uni-form-field>

    <ng-container *ngIf="retriesEnabledControl.value">
      <uni-form-field [margin]="false">
          <mat-form-field>
            <mat-select
              [formControl]="retrialControl">
              <mat-option
                *ngFor="let retry of retries"
                [value]="retry"
              >
                {{ retry }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </uni-form-field>
    </ng-container>
  </uni-form-container>
</ng-container>
