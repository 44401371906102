import { get } from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { Permission, UserAccount } from '../../../uni-auth/shared/uni-auth.model';
import { ActivePackages, PackagesFacade, UniNumberService } from '../../../../shared';
import { UniTopBarBalanceService } from '../../shared/uni-top-bar-balance.service';
import { UniPriceService } from '../../../uni-price';

@Component({
  selector: 'uni-top-bar-balance',
  templateUrl: './uni-top-bar-balance.component.html',
  styleUrls: ['./uni-top-bar-balance.component.scss']
})
export class UniTopBarBalanceComponent implements OnInit, OnDestroy {
  account$ = this.uniAuthFacade.account$;
  activePackages$ = this.uniPackagesFacade.packages$;
  impersonatee = this.uniAuthFacade.getImpersonatee();
  canSeePackages = this.uniAuthFacade.hasSomePermission('UC.PACKAGE', [Permission.seeOwn, Permission.transfer]);

  get defaultCurrency(): string {
    return get(this.uniAuthFacade.user, 'defaultCurrency') || 'USD';
  }

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniPackagesFacade: PackagesFacade,
    private uniTopBarBalanceService: UniTopBarBalanceService,
    private uniPriceService: UniPriceService,
    private uniNumberService: UniNumberService,
  ) {}

  ngOnInit(): void {
    if (this.canSeePackages) {
      this.uniPackagesFacade.setActivePackages();
    }
    this.watchBalance();
  }

  ngOnDestroy() {
    this.uniTopBarBalanceService.killInterval();
  }

  balance(userAccount: UserAccount, isFull = false): string {
    const currentBalance = get(userAccount, 'balance') || 0;

    return isFull
      ? this.uniPriceService.getPrice(currentBalance, this.defaultCurrency, 2)
      : this.uniPriceService.getShortPrice(currentBalance, this.defaultCurrency);
  }

  units(packages: ActivePackages): string {
    return !!packages.units ? this.uniNumberService.formatShortNumber(packages.units) : '0';
  }

  watchBalance() {
    if (!this.uniAuthFacade.isBalance) {
      return;
    }

    this.uniTopBarBalanceService.initInterval(this.canSeePackages);
  }

  isNumber(value: any): boolean {
    return !isNaN(value);
  }
}
