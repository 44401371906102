export interface ActivePackages {
  accountId?: string;
  units?: number;
}

export interface Package {
  name: string;
  totalUnits: number;
  remainingUnits: number;
  usedUnits: number;
  expiryDate: string;
  startDate: string;
  status: string;
  balanceName: string;
  serviceType: string;
  isImmutablePackage: boolean;
  expired: boolean;
  lifeTime: boolean;
  daysToExpiryDate: number;
  unitsType: string;
  items?: number;
}

export enum PackageStatus {
  expired = 'expired',
  active = 'active'
}

export enum ServiceType {
  sms = 'sms',
  voice = 'voice',
  whatsapp = 'whatsapp',
  chatbot = 'chatbot',
  auth = 'authenticate',
  push = 'push',
  mcc = 'mcc',
  any = 'any',
  flow = 'flowStudio',
  twitter = 'twitter',
  multi = 'multiChannelCampaign',
}

export enum UnitType {
  monetary = 'monetary',
  units = 'units',
  regular = 'regular'
}

export enum PackageUnits {
  usd = 'USD',
  sessions = 'sessions',
  messages = 'messages',
  seconds = 'seconds',
  units = 'units'
}
