import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-list-row',
  templateUrl: './uni-list-row.component.html',
  styleUrls: ['./uni-list-row.component.scss'],
})
export class UniListRowComponent {
  @HostBinding('class.is-active') @Input() isActive = false;
}
