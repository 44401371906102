import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlockedWordsStore } from './blocked-words.store';

@Component({
  selector: 'app-blocked-words',
  templateUrl: './blocked-words.component.html',
  styleUrls: ['./blocked-words.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BlockedWordsStore]
})
export class BlockedWordsComponent {
  constructor(
    private store: BlockedWordsStore
  ) { }


  ngOnInit(): void {
  }

  vm$ = this.store.vm$
}
