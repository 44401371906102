import { QueryActions, queryReducer } from '../../../store/query/index';
import * as NavActions from './uni-nav.actions';
import { initialState, NavState } from './uni-nav.state';

const queryState = (state: NavState, action: NavActions.All | QueryActions.All): NavState => ({
  ...state,
  ...queryReducer<NavState>([], action as QueryActions.All),
});

export function navReducer(
  state = initialState,
  action: NavActions.All,
): NavState {
  switch (action.type) {
    case NavActions.SET_NAV_STATE: {
      return {
        ...state,
        isNavOpen: action.state,
      };
    }
    case NavActions.SET_PRODUCT_NAV_STATE: {
      return {
        ...state,
        isProductNavOpen: action.state,
      };
    }
    case NavActions.SET_PROFILE_NAV_STATE: {
      return {
        ...state,
        isProfileNavOpen: action.state,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
