import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UniPaginationFacade } from './uni-pagination.facade';

@Injectable()
export class UniPaginationService {

  constructor(
    private paginationFacade: UniPaginationFacade,
    private activatedRoute: ActivatedRoute,
  ) {}

  setContentPage(items: number, limit = 10) {
    const { page } = this.activatedRoute.snapshot.queryParams;

    if (
      !!page
      && page > 1
      && items % limit <= 1
    ) {
      this.paginationFacade.setPage(page - 1);
    }
  }

  setLastPage(
    items: number,
    removedItems: number,
    limit = 10
  ): number {
    const page = +this.activatedRoute.snapshot.queryParams.page || 1;
    const lastPage = Math.ceil(items / limit);
    const lastPageItems = items % limit === 0 ? limit : items % limit;

    if (
      (Number(lastPage) !== page)
      || (Number(lastPageItems) !== Number(removedItems))
      || (page === 1)
    ) {
      return page;
    }

    return page - 1;
  }
}
