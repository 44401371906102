import { Component, Input, OnChanges } from '@angular/core';
import { Campaign, CampaignMessageStatus } from '../../shared/uni-campaign.model';
import {
  failedCounter,
  succeedCounter,
  progressCounter,
  createdCounter,
  getTotal,
  getPercentage,
  getTotalPercentage,
} from './campaign-progress.helper';

@Component({
  selector: 'cc-campaign-progress',
  templateUrl: './campaign-progress.component.html',
  styleUrls: ['./campaign-progress.component.scss']
})
export class CampaignProgressComponent implements OnChanges {
  @Input() campaign: Campaign;
  messageStatus = CampaignMessageStatus;
  getTotal = getTotal;
  getPercentage = getPercentage;
  getTotalPercentage = getTotalPercentage;
  failedCount = 0;
  succeedCount = 0;
  progressCount = 0;
  createdCount = 0;

  ngOnChanges() {
    if (!!this.campaign) {
      this.failedCount = failedCounter(this.campaign);
      this.succeedCount = succeedCounter(this.campaign);
      this.progressCount = progressCounter(this.failedCount, this.succeedCount);
      this.createdCount = createdCounter(this.campaign);
    }
  }
}
