import { Queries, QueryGroups } from '../http-query.model';

export const QUERY_STORE_KEY = 'httpQuery';

export interface HttpQueryState {
  queries: Queries;
  groups: QueryGroups;
}

export const httpQueryInitialState: HttpQueryState = {
  queries: {},
  groups: {},
};
