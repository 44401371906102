import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniLayoutState } from './uni-layout.state';

export const selectLayoutFeature = createFeatureSelector<UniLayoutState>('layout');

export const selectIsLoader = createSelector(
  selectLayoutFeature,
  (state: UniLayoutState) => state.isLoader
);
