import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-tooltip',
  templateUrl: './uni-tooltip.component.html',
  styleUrls: ['./uni-tooltip.component.scss'],
})
export class UniTooltipComponent {
  @Input() onlyIcon = false;
  @Input() tooltip = '';
  @Input() icon = 'question-circle';
  @Input() showLeft = false;
  @HostBinding('class.is-active') @Input() isActive = false;
  @HostBinding('class.is-margin-left-0') @Input() resetMarginLeft = false;

  @Output() isActiveChange = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onOutsideClick(targetElement) {
    if (this.onlyIcon || this.tooltip.length) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    this.setState(clickedInside);
  }

  setState(state: boolean) {
    this.isActive = state;
    this.isActiveChange.emit(state);
  }
}
