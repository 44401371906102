import { QueryActions, queryReducer } from '../../../store/query';

import * as UniAudienceActions from './uni-audience.actions';
import {
  GET_GROUP_QUERY, GET_GROUPS_QUERY, DELETE_GROUP_QUERY,
  GET_DYNAMIC_GROUP_QUERY, GET_DYNAMIC_GROUPS_QUERY, DELETE_DYNAMIC_GROUP_QUERY,
  uniAudienceInitialState, UniAudienceState
} from './uni-audience.state';
import { UniDynamicGroup } from '../shared/uni-audience.model';

const queryState = (state: UniAudienceState, action: UniAudienceActions.All | QueryActions.All): UniAudienceState => ({
  ...state,
  ...queryReducer<UniAudienceState>([
    GET_GROUP_QUERY, GET_GROUPS_QUERY, DELETE_GROUP_QUERY,
    GET_DYNAMIC_GROUP_QUERY, GET_DYNAMIC_GROUPS_QUERY, DELETE_DYNAMIC_GROUP_QUERY
  ], action as QueryActions.All),
});

export function uniAudienceReducer(
  state = uniAudienceInitialState,
  action: UniAudienceActions.All,
): UniAudienceState {
  switch (action.type) {
    case UniAudienceActions.SET_UNI_GROUP_SUCCESS: {
      return {
        ...state,
        group: action.response,
      };
    }
    case UniAudienceActions.SET_UNI_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.response,
        groupsAmount: Number(action.headers.get('Total-Items')),
      };
    }
    case UniAudienceActions.CLEAR_UNI_GROUPS: {
      return {
        ...state,
        getGroupsQuery: {},
        groups: undefined,
      };
    }
    case UniAudienceActions.CLEAR_UNI_GROUP: {
      return {
        ...state,
        getGroupQuery: {},
        group: undefined,
      };
    }
    case UniAudienceActions.SET_UNI_DYNAMIC_GROUP_SUCCESS: {
      return {
        ...state,
        dynamicGroup: action.response,
        dynamicGroups: updateDynamicGroupContacts(state, action.response),
      };
    }
    case UniAudienceActions.SET_UNI_DYNAMIC_GROUPS_SUCCESS: {
      return {
        ...state,
        dynamicGroups: action.response,
        dynamicGroupsAmount: Number(action.headers.get('Total-Items')),
      };
    }
    case UniAudienceActions.CLEAR_UNI_DYNAMIC_GROUPS: {
      return {
        ...state,
        getDynamicGroupsQuery: {},
        dynamicGroups: undefined,
      };
    }
    case UniAudienceActions.CLEAR_UNI_DYNAMIC_GROUP: {
      return {
        ...state,
        getDynamicGroupQuery: {},
        dynamicGroup: undefined,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}

export function updateDynamicGroupContacts(state: UniAudienceState, group: UniDynamicGroup): UniDynamicGroup[] {
  state.dynamicGroups.map((item) => {
    if (item.id === group.id) {
      item.contacts = group.contacts;
    }

    return;
  });

  return state.dynamicGroups;
}
