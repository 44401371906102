<uni-icon
  *ngIf="icon"
  [name]="icon"
  [matTooltip]="tooltip"
  [matTooltipPosition]="'above'"
  matTooltipClass="mat-tooltip--info"
></uni-icon>
<ng-content select="[trigger]"></ng-content>
<div class="uni-tooltip__content" [class]="showLeft ? 'uni-tooltip__content--show-left' : ''">
  <ng-content></ng-content>
</div>
