import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { get } from 'lodash';

import { AutoUnsubscribe } from '../../../../utils';

@Component({
  selector: 'uni-form-search-dropdown',
  templateUrl: './uni-form-search-dropdown.component.html',
  styleUrls: ['./uni-form-search-dropdown.component.scss'],
})
export class UniFormSearchDropdownComponent extends AutoUnsubscribe implements OnInit, OnChanges {
  @Input() data: any[] = [];
  @Input() control: FormControl;
  @Input() valueKey: string;
  @Input() displayValueKey: string;
  @Input() placeholderKey: string;
  @Input() isLoading = false;
  @Input() isMargin = false;

  searchControl = new FormControl();
  searchForm = new FormGroup({ search: this.searchControl });

  filteredData = [];
  isDropdownContentShown = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.initFiltration();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onDataChanged(changes.data);
  }

  showDropdownContent(): void {
    if (this.control.disabled) {
      return;
    }
    this.isDropdownContentShown = true;
  }

  hideDropdownContent(): void {
    this.isDropdownContentShown = false;
    this.searchControl.setValue('');
  }

  onItemSelected(item: any): void {
    this.control.setValue(this.getItemValue(item));
    this.control.markAsDirty();

    this.hideDropdownContent();
  }

  private initFiltration(): Subscription {
    return this.searchControl.valueChanges
      .pipe(debounceTime(400))
      .subscribe(value => this.setFilteredData(value));
  }

  private onDataChanged(dataChange: SimpleChange): void {
    if (!dataChange) {
      return;
    }
    if (!dataChange.currentValue) {
      this.data = [];
    }

    this.setFilteredData(this.searchControl.value);
  }

  private setFilteredData(value: string): void {
    this.filteredData = Boolean(value) && this.data
      ? this.data.filter(item => this.getItemDisplayValue(item).toLowerCase().match(value.toLowerCase()))
      : [...this.data];
  }

  private getItemValue(item: any): string {
    return get(item, this.valueKey);
  }

  private getItemDisplayValue(item: any): string {
    return get(item, this.displayValueKey || '');
  }
}
