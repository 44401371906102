import { Directive, Inject, Input } from '@angular/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MaxRangeStrategy } from '../shared/uni-max-range.service';

@Directive({
  selector: '[maxRange]',
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeStrategy
    }
  ]
})
export class UniMaxRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: MaxRangeStrategy<unknown>
  ) {}

  @Input() set maxRange(value: number) {
    this.maxRangeStrategy.delta = +value || 7;
  }
}
