import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-box',
  templateUrl: './uni-box.component.html',
  styleUrls: ['./uni-box.component.scss'],
})
export class UniBoxComponent {
  @Input() orientation = 'vertical';
  @Input() isMargin = '';
  @Input() class = '';

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-box--${this.orientation} uni-box--margin-${this.isMargin}`;
  }

  @HostBinding('class.overflow-hidden') @Input() isHidden = true;
}
