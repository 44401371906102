import { concat, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as QueryActions from '../../../store/query/query.actions';
import { UniUserRepository } from '../shared/uni-user.repository';
import * as UniUserActions from './uni-user.actions';
import { GET_USERS_QUERY } from './uni-user.state';

@Injectable()
export class UniUserEffects {
  constructor(
    private actions$: Actions,
    private uniUserRepository: UniUserRepository,
  ) { }

  @Effect() setUsers$ = this.actions$
    .pipe(
      ofType<UniUserActions.SetUsers>(UniUserActions.SET_USERS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_USERS_QUERY)),
        this.uniUserRepository
          .getUsers(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_USERS_QUERY, res.body)),
              of(new UniUserActions.SetUsersSuccess(res.body)),
              of(new UniUserActions.SetUsersAmount(Number(res.headers.get('Total-Items')))),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_USERS_QUERY, error)),
            )),
          ),
        ),
      ),
    );
}
