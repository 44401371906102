import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cc-campaign-file-to-sms-form',
  templateUrl: './campaign-file-to-sms-form.component.html',
})
export class CampaignFileToSMSFormComponent {
  @Input() form: FormGroup;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }
}
