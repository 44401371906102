import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-datepicker',
  templateUrl: './uni-datepicker.component.html',
  styleUrls: ['./uni-datepicker.component.scss'],
})
export class UniDatepickerComponent {
  @Input() datepicker;
}
