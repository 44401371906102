import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-campaign-summary-date',
  templateUrl: './campaign-summary-date.component.html',
  styleUrls: ['./campaign-summary-date.component.scss']
})
export class CampaignSummaryDateComponent {
  @Input() date: string;
}
