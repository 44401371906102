<div
  #square
  class="pie-chart"
  (window:resize)="0"
  [ngStyle]="{'height.px': square.offsetWidth }"
>
  <canvas #chart class="pie-chart__circle">
    {{ chart }}
  </canvas>
  <div class="pie-chart__caption">
    <span
      class="pie-chart__percents"
      [ngClass]="{'is-big': isBig }"
    >
      {{ data.value / data.total | percent: '1.0-2' }}
    </span>
    <uni-text-subheader>
      {{ data.value | numberCommas:0 }}
    </uni-text-subheader>
  </div>
</div>
<uni-label [displayInline]="true">
  <uni-tooltip-cloud
    position="top"
    type="success"
  >
    {{ data.label | translate }}
    <uni-icon
      name="info"
      [isLeftSpace]="true"
    ></uni-icon>

    <div tooltip>
      {{ data.tooltip | translate }}
    </div>
  </uni-tooltip-cloud>
</uni-label>
