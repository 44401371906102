import { Component, Input, HostBinding, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormOption } from '../../../uni-form';
import { UniRouteService } from '../../../../shared';

@Component({
  selector: 'uni-more-list',
  templateUrl: './uni-more-list.component.html',
  styleUrls: ['./uni-more-list.component.scss'],
})
export class UniMoreListComponent implements AfterViewInit {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;
  @Input() items: FormOption[] = [];
  @Input() isActive = false;
  @Input() thead: HTMLElement;
  @Input() tableLeft: number;

  @HostBinding('class.is-active')
  get isActiveClass(): boolean {
    return this.isActive;
  }

  @HostBinding('class.in-table')
  get inTableClass(): boolean {
    return !!this.thead;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  onClickOutside(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement || !this.isMoreIcon) {
      return;
    }

    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.onClose();
    }
  }

  get isMoreIcon(): boolean {
    return this.items.length > 1;
  }

  get firstItem(): FormOption {
    return !this.items.length
      ? { id: '', name: '' }
      : this.items[0];
  }

  constructor(
    private elementRef: ElementRef,
    public uniRouteService: UniRouteService,
  ) {}

  ngAfterViewInit() {
    this.setWrapperPosition();
  }

  toggleState(): void {
    this.setWrapperPosition();
    this.isActive = !this.isActive;
  }

  setWrapperPosition(): void {
    if (!this.thead) {
      return;
    }

    this.wrapper.nativeElement.style.left = this.thead.getBoundingClientRect().left - this.tableLeft + 'px';
    this.wrapper.nativeElement.style.maxWidth = this.thead.offsetWidth + 'px';
  }

  onClose(): void {
    this.isActive = false;
  }
}
