import { NgModule } from '@angular/core';

import { UniTileImgComponent } from './components/uni-tile-img/uni-tile-img.component';
import { UniTileComponent } from './components/uni-tile/uni-tile.component';
import { UniTilesComponent } from './components/uni-tiles/uni-tiles.component';

const components = [
  UniTilesComponent,
  UniTileComponent,
  UniTileImgComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
})
export class UniTileModule { }
