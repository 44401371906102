import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UniPageTitleService {
  constructor(
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService,
  ) { }

  init(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getRouteDataField('titleKey'))
      )
      .subscribe((titleKey: string) => {
        if (!!titleKey) {
          this.setTitle(titleKey);
        }
      });
  }

  setTitle(key: string): void {
    this.translateService.get(key).toPromise().then((title: string) => {
      this.titleService.setTitle(
        title + ' - Unifonic Cloud'
      );
    });
  }

  getRouteDataField(field: string): string {
    let route: ActivatedRoute = this.router.routerState.root;
    let routeField = '';
    if (!route) {
      return routeField;
    }

    while (route.firstChild) {
      route = route.firstChild;
    }
    if (route.snapshot && route.snapshot.data && route.snapshot.data[field]) {
      routeField = route.snapshot.data[field];
    }
    return routeField;
  }
}
