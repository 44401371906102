import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-table-top-bar-content',
  templateUrl: './uni-table-top-bar-content.component.html',
  styleUrls: ['./uni-table-top-bar-content.component.scss']
})
export class UniTableTopBarContentComponent {
  @HostBinding('class.is-left-margin') @Input() isLeftMargin = false;
}
