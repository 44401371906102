import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { MessageLogsFetchStore } from "../../store/message-logs-fetch.store";
import { MessageLogsFiltersStore } from '../../store/message-logs-filters.store';
import { CustomerStatus } from "src/app/shared/store-mocks/status.store";
import { ProductsLabels } from "src/app/shared/store-mocks/products.store";
import { status } from "unifonic-spa-common/src";
import { toLower } from "lodash";

type State = {
    selectedItemId: string
}

const initialState: State = {
    selectedItemId: ''
}

@Injectable()
export class MessageLogsTableVoiceStore extends ComponentStore<State>{
    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore,
        private messageLogsFiltersStore: MessageLogsFiltersStore ){
        super(initialState)
    }

    //selectors
    readonly selectedItemId$ = this.select(state => state.selectedItemId)

    readonly selectedItem$ = this.select(
        this.messageLogsFetchStore.entities$,
        this.selectedItemId$,
        (entities, selectedItemId) => {
            return entities.find(e => e.id === selectedItemId) || {}
        }
    )

    readonly entities$ = this.select(
        this.messageLogsFetchStore.entities$,
        this.selectedItemId$,
        (entities, selectedItemId) => {
            return entities.map((entity: any) => {
                return {
                    ...entity,
                    active: entity.recordId === selectedItemId,
                    status: toLower(entity.status),
                    messageRejected: entity.status === CustomerStatus.REJECTED,
                    productLabel: ProductsLabels[entity.product] ? ProductsLabels[entity.product] : entity.product
                }
            })
        }
    )

    readonly vm$ = this.select(
        this.entities$,
        this.messageLogsFetchStore.totalCount$,
        this.messageLogsFetchStore.loading$,
        (entities, totalCount, loading) => {

            return {
                entities,
                totalCount,
                loading,
            }
        },
        {debounce: true}
    )


    //updaters
    readonly setSelectedItemId = this.updater( (state, selectedItemId: string) => {
        if(state.selectedItemId === selectedItemId) {
            return {...state, selectedItemId: ''}
        }
        return {...state, selectedItemId}
    } )

    readonly resetSelectedItemId = this.updater( (state) => {
        return {...state, selectedItemId: ''}
    } )
}
