import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { QueryConfig } from '../http-query.model';

export const QUERY_KEY = '[HTTP query]';
export const QUERY_INIT = `${QUERY_KEY} INIT`;
export const QUERY_IN_PROGRESS = `${QUERY_KEY} IN_PROGRESS`;
export const QUERY_SUCCESS = `${QUERY_KEY} SUCCESS`;
export const QUERY_FAILURE = `${QUERY_KEY} FAILURE`;
export const QUERY_CLEAR = `${QUERY_KEY} CLEAR`;

export class init implements Action {
  readonly type = QUERY_INIT;
  constructor(public queryConfig: QueryConfig) { }
}

export class inProgress implements Action {
  readonly type = QUERY_IN_PROGRESS;
  constructor(public queryConfig: QueryConfig) { }
}

export class success implements Action {
  readonly type = QUERY_SUCCESS;
  constructor(
    public queryConfig: QueryConfig,
    public response: HttpResponse<unknown>
  ) { }
}

export class failure implements Action {
  readonly type = QUERY_FAILURE;
  constructor(
    public queryConfig: QueryConfig,
    public error: HttpErrorResponse,
  ) { }
}

export class clear implements Action {
  readonly type = QUERY_CLEAR;
  constructor(public queryConfig: QueryConfig) { }
}

export type All =
  | init
  | inProgress
  | success
  | failure
  | clear;
