import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'uni-table-search',
  templateUrl: './uni-table-search.component.html',
})
export class UniTableSearchComponent implements OnInit {
  @Input() controlName = 'search';
  @Input() additionalSearchQueryParams: Record<string, string | number>;
  @Input() margin = false;
  @Input() isClear = true;
  @Input() isLoading = false;
  @Input() label = 'label.search';

  private readonly enterKeyKode = 13;
  searchForm: FormGroup;

  get searchControl(): FormControl {
    return this.searchForm.get(this.controlName) as FormControl;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  onSearchInputCleared(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const clearedSearchQueryParams = this.getClearedSearchQueryParams();

    this.router.navigate([], {
      queryParams: {
        ...queryParams,
        ...clearedSearchQueryParams,
      },
    });
  }

  private initForm(): void {
    const defaultValue = this.activatedRoute.snapshot.queryParams[this.controlName] || '';
    this.searchForm = new FormGroup({ [this.controlName]: new FormControl(defaultValue) });
  }

  onSearchKeydown({ keyCode }): void {
    if (keyCode !== this.enterKeyKode) {
      return;
    }

    const queryParams = this.getSearchQueryParams();
    this.router.navigate([], { queryParams });
  }

  private getSearchQueryParams(): Record<string, any> {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const additionalQueryParams = this.additionalSearchQueryParams ?? {};
    return {
      ...queryParams,
      page: undefined,
      [this.controlName]: this.searchControl.value,
      ...additionalQueryParams,
    };
  }

  private getClearedSearchQueryParams(): Record<string, null> {
    const clearedAdditionalSearchQueryParams = this.additionalSearchQueryParams
      ? Object.keys(this.additionalSearchQueryParams).reduce(
        (accu, current) => {
          accu[current] = null;
          return accu;
        }, {})
      : {};

    return {
      [this.controlName]: null,
      ...clearedAdditionalSearchQueryParams,
    };
  }
}
