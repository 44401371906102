import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniIconModule } from '../uni-icon';
import { UniStepSeparatorComponent } from './components/uni-step-separator/uni-step-separator.component';
import { UniStepComponent } from './components/uni-step/uni-step.component';
import { UniStepsComponent } from './components/uni-steps/uni-steps.component';

const components = [
  UniStepComponent,
  UniStepsComponent,
  UniStepSeparatorComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UniIconModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniStepModule { }
