<div
  class="uni-table-details-item"
  [ngClass]="{ 'uni-table-details-item--vertical': item.type === uniDetailType.dropdown }"
>
  <div class="uni-table-details-item__label">
    {{ prefix + item.name | translate }}:
  </div>
  <div
    class="uni-table-details-item__value"
    [ngSwitch]="item.type"
  >
    <ng-container *ngSwitchCase="uniDetailType.date">
      {{ item.value | dateTimeFormat }}
    </ng-container>

    <ng-container *ngSwitchCase="uniDetailType.boolean">
      {{ (item.value ? 'label.yes' : 'label.no') | translate}}
    </ng-container>

    <ng-container *ngSwitchCase="uniDetailType.file">
      <uni-link [path]="path">
        {{ prefix + item.name | translate }}
      </uni-link>
    </ng-container>

    <ng-container *ngSwitchCase="uniDetailType.dropdown">
      <uni-form-autocomplete
        [control]="control"
        [options]="item.value"
        [required]="true"
        [margin]="false"
      ></uni-form-autocomplete>
    </ng-container>

    <ng-container *ngSwitchCase="uniDetailType.integer">
      {{ item.value }}
    </ng-container>

    <ng-container *ngSwitchCase="uniDetailType.audio">
      <uni-audio
        [src]="item.value"
        [displayInline]="true"
        [alignTop]="true"
      ></uni-audio>
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{ parsedValue | translate }}
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>
