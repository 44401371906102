<ng-container *ngIf="isEditable || isActive">
  <uni-form-container [formGroup]="contentForm">
    <ng-container>
      <uni-label>
        {{ 'label.countryCodeAutocomplete' | translate }}
      </uni-label>
      <uni-form-field [margin]="false">
        <mat-form-field>
          <mat-select
            [formControl]="autocompleteControl"
            [disableOptionCentering]="true"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country"
            >
              {{ 'country.' + country.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </uni-form-field>
      <uni-text-info>
        {{ 'tip.chooseCountryTip' | translate }}
      </uni-text-info>
    </ng-container>
  </uni-form-container>
</ng-container>
