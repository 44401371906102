import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-form-steps-item',
  templateUrl: './uni-form-steps-item.component.html',
  styleUrls: ['./uni-form-steps-item.component.scss']
})
export class UniFormStepsItemComponent {
  @HostBinding('class.active') @Input() isActive = false;
}
