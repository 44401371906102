import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NavState } from './uni-nav.state';

export const selectNavFeature = createFeatureSelector<NavState>('nav');

export const selectNavState = createSelector(
  selectNavFeature,
  (state: NavState) => state.isNavOpen,
);

export const selectProductNavState = createSelector(
  selectNavFeature,
  (state: NavState) => state.isProductNavOpen,
);

export const selectProfileNavState = createSelector(
  selectNavFeature,
  (state: NavState) => state.isProfileNavOpen,
);
