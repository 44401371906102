import { Component, Input, HostBinding, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'uni-sort',
  templateUrl: './uni-sort.component.html',
  styleUrls: ['./uni-sort.component.scss'],
})
export class UniSortComponent {
  @HostBinding('class.is-inline') @Input() isInline = false;
  @HostBinding('class.is-sort') @Input() sortBy: string;

  @HostBinding('class.is-active') get isActiveClass(): boolean {
    return this.isActive;
  }

  @HostListener('click') onClick() {
    if (!this.sortBy) {
      return;
    }

    const sortDirection = this.getNextSortDirection();
    const sortBy = !sortDirection ? undefined : this.sortBy;

    this.router.navigate([], {
      queryParams: { sortBy, sortDirection },
      queryParamsHandling: 'merge',
    });
  }

  get isActive(): boolean {
    const { sortBy } = this.activatedRoute.snapshot.queryParams;
    return sortBy && this.sortBy === sortBy;
  }

  get sortDirection(): string {
    const { sortDirection } = this.activatedRoute.snapshot.queryParams;
    return sortDirection;
  }

  get sortIcon(): string {
    return this.sortDirection === 'DESC'
      ? 'sort-down'
      : 'sort-up';
  }

  getNextSortDirection() {
    if (!this.isActive) {
      return 'DESC';
    }

    switch (this.sortDirection) {
      case 'DESC': return 'ASC';
      case 'ASC': return undefined;
      default: return 'DESC';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}
}
