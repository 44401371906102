import { Query } from '../../../../store';
import { Timezone } from '../../shared';

export const GET_TIMEZONES_QUERY = 'getTimezonesQuery';

export interface TimezonesState {
  timezones: Timezone[];
  getTimezonesQuery: Query<Timezone>;
}

export const initialState: TimezonesState = {
  timezones: [],
  getTimezonesQuery: {},
};
