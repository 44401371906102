import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  transform(value: number, type?: string): string {
    if (!value && value !== 0) {
      return '-';
    }

    let timeSec: string | number = Math.round(value % 60);
    const timeMin = Math.floor(value / 60);

    if (timeSec < 10) {
      timeSec = '0' + timeSec;
    }

    return type === 'addLetters' ? `${timeMin}m:${timeSec}s` : `${timeMin}:${timeSec}`;
  }
}
