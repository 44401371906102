import { Action } from '@ngrx/store';

export const SET_NAV_STATE = '[Nav] SET_NAV_STATE';
export const SET_PRODUCT_NAV_STATE = '[Nav] SET_PRODUCT_NAV_STATE';
export const SET_PROFILE_NAV_STATE = '[Nav] SET_PROFILE_NAV_STATE';

export class SetNavState implements Action {
  readonly type = SET_NAV_STATE;
  constructor(public state: boolean) { }
}

export class SetProductNavState implements Action {
  readonly type = SET_PRODUCT_NAV_STATE;
  constructor(public state: boolean) { }
}

export class SetProfileNavState implements Action {
  readonly type = SET_PROFILE_NAV_STATE;
  constructor(public state: boolean) { }
}

export type All =
  | SetNavState
  | SetProductNavState
  | SetProfileNavState;
