import { get, head } from 'lodash';
import { AbstractControl, FormGroup } from '@angular/forms';

import { FormError } from '../modules';

export namespace ValidationUtils {
  export const getError = (error: any) => get(error, 'error');

  export const getJsonError = (error: any): string => get(JSON.parse(getError(error)), 'detail') || 'default';

  export const jsonDetail = (error: any): string => `validation.${getJsonError(error)}`;

  export const getViolations = (error: any): FormError[] => get(error, 'error.violations') || [];

  export const getViolationError = (error: any, returnRawValue: boolean = false): string => {
    const now = new Date();
    const sessionExpirationTime = now.setMinutes(now.getMinutes() - 25);
    const lastRequest = localStorage.getItem('lastRequest');

    if (
      !!lastRequest
      && +lastRequest < sessionExpirationTime
    ) {
      return 'snackbar.errorSessionTimeOut';
    }

    const formError = head(getViolations(error));

    return !formError
      ? 'validation.default'
      : returnRawValue || formError.message.indexOf(' ') >= 0
        ? formError.message
        : `validation.${formError.message}`;
  };

  export const getSubcodeError = (error: any): FormError[] => get(error, 'error.message.error.error_subcode') || [];

  export const getMessageTemplateError = (error: any, returnRawValue: boolean = false): string => {
    const now = new Date();
    const sessionExpirationTime = now.setMinutes(now.getMinutes() - 25);
    const lastRequest = localStorage.getItem('lastRequest');
    const waMessageTemplatesMetaErrors = ['2388025', '2388023', '2388024', '2388124'];

    if ( !!lastRequest && +lastRequest < sessionExpirationTime ) {
      return 'snackbar.errorSessionTimeOut';
    }

    const getErrorSubcode = getSubcodeError(error).toString();
    return waMessageTemplatesMetaErrors.includes(getErrorSubcode)
            ? `channels.whatsapp.error.${getErrorSubcode}` : 'validation.default';
  };

  export const getParams = (error: any): object => {
    const formError = head(getViolations(error));

    return get(formError, 'payload') || {};
  };

  export const getValidationControl = (control: AbstractControl): string => {
    if (!control || control.untouched || control.valid) {
      return '';
    }

    const errorKey = Object.keys(control.errors)[0];
    return `validation.${errorKey}`;
  };

  export const getValidation = (form: FormGroup, controlKey: string): string => {
    const control = form.get(controlKey);
    return getValidationControl(control);
  };

  export const compareGroupByValues = (o1: string, o2: string) => {
    return o1 && o2 && o1 === o2;
  };
}
