import { CreateOrderItem, Order, OrderDto, OrderItemType } from './uni-orders.model';

export function createOrderFromDto(dto: OrderDto): Order {
  const { currency, vatRate } = dto.orderItems[0];
  const itemsCount = dto.orderItems.length;
  const netAmount = dto.orderItems.reduce((sum, item) => sum + item.netAmount, 0);
  const vatAmount = dto.orderItems.reduce((sum, item) => sum + item.vatAmount, 0);
  const totalAmount = netAmount + vatAmount;

  return {
    ...dto,
    currency,
    itemsCount,
    netAmount,
    totalAmount,
    vatAmount,
    vatRate,
  };
}

export function createOrderItem(itemType: OrderItemType, name: string, refId: number): CreateOrderItem {
  return { itemType, name, refId };
}
