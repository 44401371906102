import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlockedBySenderStore } from './blocked-by-sender.store';

@Component({
  selector: 'app-blocked-by-sender',
  templateUrl: './blocked-by-sender.component.html',
  styleUrls: ['./blocked-by-sender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BlockedBySenderStore]
})
export class BlockedBySenderComponent {
  constructor(
    private store: BlockedBySenderStore
  ) {
  }


  ngOnInit(): void {
  }

  vm$ = this.store.vm$
}
