import {
  get,
  map,
  isArray,
  isEqual
} from 'lodash';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'uni-table-filter',
  templateUrl: './uni-table-filter.component.html',
  styleUrls: ['./uni-table-filter.component.scss']
})
export class UniTableFilterComponent implements OnInit, OnChanges {
  @ViewChild('autocomplete', { static: false }) autocomplete: ElementRef;

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() thead: HTMLElement;
  @Input() tableLeft: number;
  @Input() heading: string;
  @Input() placeholder = '';
  @Input() isOpened = false;
  @Input() isQueryParams = true;
  @Input() isSearch = true;
  @Input() isMulti = false;
  @Input() isInputField = false;
  @Input() isLazyLoading = false;
  @Input() isLoading = false;
  @Input() filterKey = 'key';
  @Input() filterValue = 'value';
  @Input() dataParserKey = 'id';
  @Input() dataParserName = 'name';
  @Input() filterInputValue = new FormControl();
  @Input() defaultValue = '';
  @Input() validators = [];
  @Input() data;
  @Input() scrollCallback;
  @Input() width: number;
  @Input() isDisabled = false;
  @Input() alignRight = false;
  @Input() addLrmChar = false;
  // TODO: (mziaja) remove deprecated input
  @Input() filteredDataOnly = false;
  @Input() limitOptions?: number;

  @Output() apply = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() search = new EventEmitter<string>();

  parsedData = [];

  @HostListener('click') onOpen() {
    if (
      this.isDisabled
      || (!this.data && !this.isLazyLoading)
    ) {
      return;
    }

    this.isOpened = true;
    this.open.next();
  }

  get control(): FormGroup {
    return this.form.get(this.controlName) as FormGroup;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initForm();
    this.parsedData = this.getParsedData();
  }

  setFilterInputValue(option: any) {
    const value = isArray(option)
      ? option.map(item => get(item, this.filterValue)).join(', ')
      : get(option, this.filterValue);
    this.filterInputValue.setValue(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.data) {
      this.parsedData = this.getParsedData();
    }

    if (!this.isMulti || !this.form) {
      return;
    }

    const options = this.control.value;

    if (!!this.data && isArray(options) && options.some(option => !get(option, this.filterValue))) {
      const filledOptions = options
        .map(option => this.data.find(item => get(item, this.dataParserKey) === get(option, this.filterKey)))
        .map(item => ({
          key: get(item, this.dataParserKey),
          value: get(item, this.dataParserName),
        }));

      this.setFilterInputValue(filledOptions);
    }
  }

  getControlKey(): any {
    if (!this.form) {
      this.initForm();
      return;
    }

    const results = this.control.value;

    if (!results && !!this.defaultValue) {
      this.control.setValue(this.getDefaultValue());
      return this.defaultValue;
    }

    return this.isMulti && isArray(results)
      ? results.map((item) => item[this.filterKey])
      : get(results, this.filterKey);
  }

  getDefaultValue(): object {
    const value = this.activatedRoute.snapshot.queryParams[this.controlName] || this.defaultValue;

    if (!value) {
      return null;
    }

    return this.isMulti && isArray(value)
      ? value.map((item) => ({[this.filterKey]: item}))
      : { [this.filterKey]: value };
  }

  initForm(): void {
    if (!this.form) {
      this.form = new FormGroup({
        [this.controlName]: new FormControl(this.getDefaultValue(), this.validators),
      });
    }
    if(this.isInputField && !this.filterInputValue.value && this.control.value) {
      this.setFilterInputValue(this.control.value);
    }
    this.resetFilter();
    this.control.valueChanges.subscribe(option => this.setFilterInputValue(option));
  }

  resetFilter() {
    this.activatedRoute.queryParams.subscribe(params => {
      const paramValue = get(params, this.controlName);
      const controlValue = this.getControlKey();

      if (!this.compareValues(paramValue, controlValue)) {
        this.control.reset();
      }
    });
  }

  setQueryParams(): void {
    if (!this.isQueryParams || !this.data) {
      return;
    }

    const paramValue = this.activatedRoute.snapshot.queryParams[this.controlName];
    const controlValue = this.getControlKey();
    const isDefaultValue = (this.defaultValue === controlValue) && !paramValue;

    if (
      (!paramValue && !controlValue)
      || isDefaultValue
      || this.compareValues(controlValue, paramValue)
    ) {
      return;
    }

    this.router.navigate([], {
      queryParams: {
        page: 1,
        [this.controlName]: this.getControlKey(),
      },
      queryParamsHandling: 'merge',
    });
  }

  compareValues(a = [], b = []): boolean {
    return this.isMulti && isArray(a) && isArray(b)
      ? isEqual(a.sort(), b.sort())
      : isEqual(String(a), String(b));
  }

  onApply(): void {
    this.isOpened = false;
    this.apply.next(this.getControlKey());
    this.setQueryParams();
  }

  onDiscard(): void {
    this.isOpened = false;
    this.discard.next();
  }

  getParsedData(): object[] {
    return map(this.data, value => ({
      key: get(value, this.dataParserKey),
      value: get(value, this.dataParserName),
    }));
  }

  onLoadMore(): void {
    this.loadMore.emit();
  }
}
