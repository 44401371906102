import { Component } from '@angular/core';

@Component({
  selector: 'uni-info-box',
  templateUrl: './uni-info-box.component.html',
  styleUrls: ['./uni-info-box.component.scss'],
})
export class UniInfoBoxComponent {
  isActive = true;

  hideBox() {
    this.isActive = false;
  }
}
