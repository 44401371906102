import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AdvancedFiltersFormCallerIdStore } from './advanced-filters-form-caller-id.store';

@Component({
  selector: 'app-advanced-filters-form-caller-id',
  templateUrl: './advanced-filters-form-caller-id.component.html',
  styleUrls: ['./advanced-filters-form-caller-id.component.scss'],
  providers: [AdvancedFiltersFormCallerIdStore],
})
export class AdvancedFiltersFormCallerIdComponent implements OnInit, ControlValueAccessor {

  selectedItems$ = this.store.value$.pipe(
    map((entities) => entities?.length ? entities.split(',') : [])
  )

  constructor(
    public ngControl: NgControl,
    private store: AdvancedFiltersFormCallerIdStore
  ) {
      ngControl.valueAccessor = this;
    }


  onChange: any = () => {}
  onTouch: any = () => {}

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  addCallerId(value: string[]){
    this.saveValue(value.join(','))
  }

  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }
}
