<div class="uni-pagination-controllers">
  <uni-button
    class="uni-pagination-controllers__button"
    [disabled]="isPrevDisabled"
    [style]="'secondary'"
    (click)="onControllerAction(prev)"
  >
    <uni-icon
      class="uni-pagination-controllers__icon-prev"
      name="chevron-left"
      [isRightSpace]="true"
    ></uni-icon>
    {{ 'label.prev' | translate }}
  </uni-button>
  <uni-button
    class="uni-pagination-controllers__button"
    [disabled]="isNextDisabled"
    [style]="'secondary'"
    (click)="onControllerAction(next)"
  >
    {{ 'label.next' | translate }}
    <uni-icon
      class="uni-pagination-controllers__icon-next"
      name="chevron-right"
      [isLeftSpace]="true"
    ></uni-icon>
  </uni-button>
</div>
