import { HttpErrorResponse } from '@angular/common/http';

export enum QueryStatus {
  Success = 'SUCCESS',
  InProgress = 'IN_PROGRESS',
  Failure = 'FAILURE',
}

export interface Query<T> {
  status?: QueryStatus;
  response?: T;
  error?: HttpErrorResponse;
}

export interface QueryAttributes<T> {
  attributes: T;
  id: string;
  type: string;
  relationship?: any;
}

export interface QueryList<T> {
  data: QueryAttributes<T> | QueryAttributes<T>[];
  included: any;
  links: {
    self: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
  };
}

export interface QueryData<T> {
  data: QueryAttributes<T>;
}
