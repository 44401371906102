import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-box-item',
  templateUrl: './uni-box-item.component.html',
  styleUrls: ['./uni-box-item.component.scss'],
})
export class UniBoxItemComponent {
  @Input() margin = 'small';
  @Input() side = '';

  @HostBinding('class') get getClasses(): string {
    return `uni-box-item--margin-${this.margin} uni-box-item--${this.side}`;
  }
}
