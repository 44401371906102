<div class="uni-pagination" *ngIf="pagesAmount > 1 || isReportTableHasNext">
  <div class="uni-pagination__pages">
    <uni-pagination-nav>
        <uni-button
          *ngFor="let page of getPages()"
          [style]="'secondary'"
          (click)="setPage(page)"
          [class]="getPageActiveClass(page)"
        >
          {{ page }}
        </uni-button>
    </uni-pagination-nav>

    <uni-pagination-controllers
      *ngIf="pagesAmount > 1 || isReportTableHasNext"
      [currentPage]="currentPage"
      [pagesAmount]="pagesAmount"
      [hasNextPage]="isReportTableHasNext"
      (controllerAction)="setPage($event)"
    ></uni-pagination-controllers>
  </div>

  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="paginationForm"
  >
    <uni-pagination-form [form]="paginationForm"></uni-pagination-form>
  </form>
</div>
