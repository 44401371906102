<uni-form-container [formGroup]="contentForm">
  <uni-label *ngIf="isVoice; else defaultLabel">
    {{ 'label.recipientsFrom' | translate }}
  </uni-label>

  <ng-template #defaultLabel>
    <uni-label [required]="isEditable">
      {{ 'label.recipients' | translate }}
    </uni-label>
  </ng-template>

  <uni-form-field [margin]="true">
    <mat-form-field>
      <mat-select
        [formControl]="methodControl"
        [disableOptionCentering]="true"
      >
        <mat-option
          [value]="campaignRecipientsMethod.manually"
          (click)="setCustomRecipients()"
        >
          {{ (isVoice ? 'label.custom' : 'campaign.addManually') | translate }}
        </mat-option>
        <mat-option *ngIf="!isVoice"
          [value]="campaignRecipientsMethod.groups"
        >
          {{ 'campaign.yourGroups' | translate }}
        </mat-option>
        <mat-option
          [value]="campaignRecipientsMethod.file"
          (click)="setFileRecipients()"
        >
          {{ 'campaign.recipientsFromFile' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <uni-text-info *ngIf="methodControl.value === campaignRecipientsMethod.groups">
      {{ 'campaign.multipleGroups' | translate }}
    </uni-text-info>
  </uni-form-field>

  <ng-container [ngSwitch]="methodControl?.value">
    <cc-campaign-recipients-file
        *ngSwitchCase="campaignRecipientsMethod.file"
        [contentForm]="contentForm"
        [recipientsForm]="recipientsForm"
        [fileRecipientsControl]="fileRecipientsControl"
        [fileControl]="fileControl"
        [isAutocompleteActiveControl]="isAutocompleteActiveControl"
        [autocompleteControl]="autocompleteControl"
        [isVoice]="isVoice"
        [isEditable]="isEditable"
        [isActive]="isActive"
        >
    </cc-campaign-recipients-file>

    <cc-campaign-recipients-manually
        *ngSwitchCase="campaignRecipientsMethod.manually"
        [contentForm]="contentForm"
        [recipientsForm]="recipientsForm"
        [phoneNumbersControl]="phoneNumbersControl"
        [isVoice]="isVoice"
        [isEditable]="isEditable"
        [isActive]="isActive"
        (onSubmit)="onSubmit()">
    </cc-campaign-recipients-manually>

    <cc-campaign-recipients-groups
        *ngSwitchCase="campaignRecipientsMethod.groups"
        [recipientsForm]="recipientsForm"
        [groupsControl]="groupsControl"
        [isEditable]="isEditable"
        [isActive]="isActive"
        (onSubmit)="onSubmit()">
    </cc-campaign-recipients-groups>
  </ng-container>
</uni-form-container>

<cc-campaign-recipients-result
  *ngIf="fileRecipientsControl?.value || phoneNumbersControl?.value?.length || groupsControl?.value?.length"
  [contentForm]="contentForm"
  [recipientsForm]="recipientsForm"
  [isVoice]="isVoice"
  [isEditable]="isEditable"
  [isShowMultiSelect]="methodControl.value !== campaignRecipientsMethod.groups"
  [isAutocompleteActiveControl]="isAutocompleteActiveControl"
  [autocompleteControl]="autocompleteControl">
</cc-campaign-recipients-result>

<cc-campaign-recipients-modal
  [(isModalActive)]="isModalActive"
  (toggleModal)="toggleModal()"
  [invalidNumbers]="invalidNumbers"
  [validNumbers]="validNumbers"
  (submit)="addNumbers()"
></cc-campaign-recipients-modal>

<uni-loader *ngIf="isLoaderActive"></uni-loader>
