import * as moment from 'moment-timezone';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateUtils } from '../../../../utils/date.utils';

@Component({
  selector: 'cc-campaign-date',
  templateUrl: './campaign-date.component.html',
})
export class CampaignDateComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() timezone: string;
  today: moment.Moment;
  defaultValue: moment.Moment;

  get dateControl(): FormControl {
    return this.form.get('date') as FormControl;
  }

  ngOnInit() {
    this.today = DateUtils.getCurrentDate(this.timezone);
    this.defaultValue = DateUtils.getCurrentDate(this.timezone).add(2, 'd');

    this.setDefaultValue();
    this.setValidators();
  }

  setValidators(): void {
    this.dateControl.setValidators(Validators.required);
    this.dateControl.updateValueAndValidity();
  }

  setDefaultValue(): void {
    this.dateControl.setValue(this.defaultValue.toDate());
  }
}

