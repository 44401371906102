import { get } from 'lodash';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniAuthState } from './uni-auth.state';

export const selectAuthFeature = createFeatureSelector<UniAuthState>('uniAuth');

export const selectUserMe = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => state.userMe
);

export const selectGetUserMeQuery = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => state.getUserMeQuery
);

export const selectGlobalSettings = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => state.globalSettings
);

export const selectGetGlobalSettingsQuery = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => state.getGlobalSettingsQuery
);

export const selectFeatureFlags = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => state.featureFlags
);

export const selectGetFeatureFlagsQuery = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => state.getFeatureFlagsQuery
);

export const selectAccount = createSelector(
  selectAuthFeature,
  (state: UniAuthState) => get(state.userMe, 'user.account'),
);
