export interface UniGroup {
  name?: string;
  contacts?: number;
  createdAt?: string;
  id?: string;
  removed?: boolean;
  isUsed?: boolean;
}

export interface UniDynamicGroup {
  name?: string;
  contacts?: number;
  createdAt?: string;
  id?: string;
  filters: UniDynamicGroupFilters[];
  removed?: boolean;
  isUsed?: boolean;
}

export interface UniDynamicGroupFilters {
  type: string;
  value: any;
}

export enum UniGroupType {
  static = 'static',
  dynamic = 'dynamic'
}

export enum UniGroupFiltersType {
  age = 'age',
  genders = 'genders'
}
