import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartBarComponent } from './chart-bar.component';
@NgModule({
  declarations: [ChartBarComponent],
  exports: [ChartBarComponent],
  imports: [
    HighchartsChartModule
  ]
})
export class ChartBarModule { }
