import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UniCommonModule} from './uni-common.module';
import { UniDataStateModule } from './shared/uni-data/store/uni-data-state.module';
import { TranslateModule } from '@ngx-translate/core';

const modules = [
  CommonModule,
  RouterModule,
  UniCommonModule,
  UniDataStateModule,
  TranslateModule,
];

@NgModule({
  imports: [
    ...modules
  ],
  exports: [...modules],
})
export class UniSharedModule { }
