import { Component, OnInit } from '@angular/core';

import { UniLayoutFacade } from '../../../uni-layout';

@Component({
  selector: 'uni-page-404',
  templateUrl: './uni-page-404.component.html'
})
export class UniPage404Component implements OnInit {
  constructor(private uniLayoutFacade: UniLayoutFacade) {}

  ngOnInit() {
    this.uniLayoutFacade.setIsLoader(false);
  }
}
