import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UniAccountsEffects, uniAccountsReducer } from './store';

@NgModule({
  imports: [
    StoreModule.forFeature('uniAccounts', uniAccountsReducer),
    EffectsModule.forFeature([UniAccountsEffects])
  ],
})
export class UniAccountsModule { }
