import { Query } from '../../../store';
import { UniRole } from '../shared/uni-roles.model';

export const GET_ROLES_QUERY = 'getRolesQuery';

export interface UniRolesState {
  roles: UniRole[];
  getRolesQuery: Query<UniRole>;
}

export const uniRolesInitialState: UniRolesState = {
  roles: [],
  getRolesQuery: {},
};
