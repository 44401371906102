import { Component, HostBinding, Input } from '@angular/core';

import { Info } from './uni-info.model';

@Component({
  selector: 'uni-info',
  templateUrl: './uni-info.component.html',
  styleUrls: ['./uni-info.component.scss'],
})
export class UniInfoComponent {
  @Input() class = '';
  @Input() type = Info.success;
  @Input() margin = 'none';

  @HostBinding('class')
  get getClasses(): string {
    return `${this.class} uni-info--${this.type} uni-info--margin-${this.margin}`;
  }
}
