import { Component, Input } from '@angular/core';
import { UniTextColor, UniTextWeight } from '../../../uni-text/shared/uni-text.model';

@Component({
  selector: 'uni-nav-category',
  templateUrl: './uni-nav-category.component.html',
  styleUrls: ['./uni-nav-category.component.scss']
})
export class UniNavCategoryComponent {
  @Input() categoryName: string;
  uniTextColor = UniTextColor;
  uniTextWeight = UniTextWeight;
}
