import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as OrdersActions from './uni-orders.actions';
import { UniOrdersRepository } from '../shared/uni-orders.repository';
import { createOrderFromDto } from '../shared/uni-orders.factory';

@Injectable()
export class UniOrdersEffects {
  constructor(
    private actions$: Actions,
    private ordersRepository: UniOrdersRepository,
  ) {}

  @Effect() createOrder$ = this.actions$.pipe(
    ofType<OrdersActions.CreateOrder>(OrdersActions.CREATE_ORDER),
    mergeMap(({ payload }) => this.ordersRepository.createOrder(payload).pipe(
      map((orderDto) => new OrdersActions.CreateOrderSuccess(createOrderFromDto(orderDto))),
      catchError((error) => of(new OrdersActions.CreateOrderError(error))),
    )),
  );

  @Effect() finalizeOrder$ = this.actions$.pipe(
    ofType<OrdersActions.FinalizeOrder>(OrdersActions.FINALIZE_ORDER),
    mergeMap(({ orderId, paymentMethod }) => this.ordersRepository.finalizeOrder(orderId, paymentMethod).pipe(
      map((orderDto) => new OrdersActions.FinalizeOrderSuccess(createOrderFromDto(orderDto))),
      catchError((error) => of(new OrdersActions.FinalizeOrderError(error))),
    )),
  );

  @Effect() getOrder$ = this.actions$.pipe(
    ofType<OrdersActions.GetOrder>(OrdersActions.GET_ORDER),
    mergeMap(({ orderId }) => this.ordersRepository.getOrder(orderId).pipe(
      map((orderDto) => new OrdersActions.GetOrderSuccess(createOrderFromDto(orderDto))),
      catchError((error) => of(new OrdersActions.GetOrderError(error))),
    )),
  );
}
