import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { callerIdReducer } from './caller-id.reducers';
import { CallerIdEffects } from './caller-id.effects';
import { CallerIdRepository } from './caller-id.repository';
import { CallerIdChannelRepository } from './caller-id-channel.repository';
import { CallerIdSubscriptionRepository } from './caller-id-subscription.repository';
import { CallerIdFacade } from './caller-id.facade';

@NgModule({
  imports: [
    StoreModule.forFeature('callerId', callerIdReducer),
    EffectsModule.forFeature([CallerIdEffects]),
  ],
  providers: [
    CallerIdRepository,
    CallerIdChannelRepository,
    CallerIdSubscriptionRepository,
    CallerIdFacade,
  ]
})
export class CallerIdStateModule { }
