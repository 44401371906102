import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoUnsubscribe, REGEX } from '../../../../utils';
import * as cardValidator from 'card-validator';

import { InputKeyPattern } from '../../directives/input.directive';

// [UCCC-7028] marks the input with LTR
// despite the specified direction
// more info: https://dev.to/pffigueiredo/stop-fixing-numbers-rtl-in-a-web-platform-6-6-29ne
const LTR_UNICODE = '\u200e';

@Component({
  selector: 'uni-input-credit-card',
  templateUrl: './uni-input-credit-card.component.html',
  styleUrls: ['./uni-input-credit-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniInputCreditCardComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() placeholder = '0000 - 0000 - 0000 - 0000';
  @Input() keyPattern: InputKeyPattern = 'credit-card';

  creditCardType: string;

  get cardNumberControl(): FormControl {
    return this.form.get('cardNumber') as FormControl;
  }

  ngOnInit(): void {
    this.subs.add(
      this.cardNumberControl.valueChanges.subscribe(controlValue => {
        if (!controlValue) {
          this.setControlValue('');

          return;
        }

        const val = this.formatCardNumber(controlValue);
        const cardNumber = cardValidator.number(val);
        this.setControlValue(LTR_UNICODE + val);

        if (!!cardNumber.card) {
          this.creditCardType = cardNumber.card.type;
          this.setControlError(!cardNumber.isValid);

          return;
        }

        this.creditCardType = '';
        this.setControlError(true);
      })
    );
  }

  // format: xxxx - xxxx - xxxx - xxxx
  formatCardNumber(value: string): string {
    const trimmedValue = value.replace(REGEX.allCharactersExceptNumbersRegExRule, '').slice(0, 16);
    let formattedValue = '';

    for (let i = 0; i < trimmedValue.length; i++) {
      formattedValue += trimmedValue[i];

      if (i !== trimmedValue.length - 1 && i % 4 === 3) {
        formattedValue += ' - ';
      }
    }

    return formattedValue;
  }

  private setControlValue(val: string): void {
    this.cardNumberControl.setValue(val, { emitEvent: false });
  }

  private setControlError(hasError: boolean): void {
    this.cardNumberControl.setErrors(hasError ? { pattern: true } : null);
  }
}
