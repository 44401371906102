<uni-box>
  <uni-box-content margin="regular">
    <div class="uni-top-bar-packages-list__header">
      <uni-text-header
        level="200"
        [color]="color.secondary"
        [isUppercase]="false"
      >
        {{ 'topbar.packages' | translate }}
      </uni-text-header>

      <uni-link
        [size]="linkSize"
        (click)="onSeePackages()"
      >
        {{ 'topbar.seePackages' | translate }}
      </uni-link>
    </div>
  </uni-box-content>

  <div
    *ngIf="packages$ | async as packages"
    class="uni-top-bar-packages-list__items"
  >
    <ng-container *ngIf="packages.length > 0; else emptyState">
      <uni-top-bar-packages-details
        *ngFor="let package of mapPackages(packages)"
        [package]="package"
      >
      </uni-top-bar-packages-details>
    </ng-container>
  </div>
</uni-box>

<ng-template #emptyState>
  <uni-box-content margin="regular">
    <div class="uni-top-bar-packages-list__empty-state">
      <uni-icon
        name="sparkles"
        size="56"
        type="tertiary"
        [style]="'fa-thin'"
      ></uni-icon>
      <uni-text-body [isUppercase]="false">
        {{ 'topbar.emptyState' | translate }}
      </uni-text-body>
      <uni-text-body
        weight="regular"
        [isUppercase]="false"
      >
        {{ 'topbar.emptyStateInfo' | translate }}
      </uni-text-body>
    </div>
  </uni-box-content>
</ng-template>
