import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniToggleComponent } from './components/uni-toggle/uni-toggle.component';

const components = [
  UniToggleComponent,
];

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniToggleModule { }
