<uni-label>
  {{ 'campaign.contentTypeLabel' | translate }}
  <uni-icon
    class="campaign-content-type__icon"
    name="info-circle"
    (click)="toggleModal()"
  ></uni-icon>
</uni-label>
<uni-form-container [formGroup]="contentForm">
  <uni-toggle
    formControlName="type"
    [inactiveValue]="campaignDataSource.custom"
    [activeValue]="campaignDataSource.fileToSMS"
    [inactiveLabel]="'campaign.contentTypeCustom' | translate"
    [activeLabel]="'campaign.contentTypeFileToSMS' | translate"
    [isDisabled]="contentForm.disabled || isDisabled"
  ></uni-toggle>
  <uni-text-info>
    {{ 'campaign.tip.contentType' | translate }}
  </uni-text-info>
</uni-form-container>

<cc-campaign-content-modal
  [isActive]="isModalActive"
  (isActiveChange)="toggleModal()"
></cc-campaign-content-modal>
