import { Component, forwardRef, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'uni-toggle',
  templateUrl: './uni-toggle.component.html',
  styleUrls: ['./uni-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniToggleComponent),
      multi: true
    }
  ]
})
export class UniToggleComponent implements ControlValueAccessor {
  @Input() activeValue = true;
  @Input() inactiveValue = false;
  @Input() activeLabel = '';
  @Input() inactiveLabel = '';

  @HostBinding('class.is-disabled')
  @Input() isDisabled = false;

  @HostBinding('class.is-active')
  get getIsActiveClass(): boolean {
    return this.value === this.activeValue;
  }

  value = null;

  @HostListener('click')
  onClick() {
    this.setToggleState();
  }

  setToggleState(): void {
    if (this.isDisabled) {
      return;
    }

    this.value = this.value === this.activeValue
      ? this.inactiveValue
      : this.activeValue;

    this.onChange(this.value);
  }

  onChange: any = () => {};

  propagateChange: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateChange = fn;
  }
}
