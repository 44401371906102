import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CampaignMessageType } from '../../shared/uni-campaign.model';

@Component({
  selector: 'cc-campaign-message-type',
  templateUrl: './campaign-message-type.component.html',
  styleUrls: ['./campaign-message-type.component.scss'],
})
export class CampaignMessageTypeComponent {
  @Input() form: FormGroup;
  @Input() isActive = true;
  @Input() isEditable = true;
  @Input() options?: CampaignMessageType[];
  isModalActive = false;

  campaignMessageTypes = CampaignMessageType;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get messageTypeControl(): FormControl | null {
    return this.contentForm.get('messageType') as FormControl;
  }

  toggleModal(): void {
    this.isModalActive = !this.isModalActive;
  }
}
