<form [formGroup]="recipientsForm" (submit)="!isGroupsModalActive && onSubmit.emit()">
  
  <uni-form-field
    [margin]="false"
    [groupMargin]="groupsControl?.value?.length"
  >
  <uni-table-filter
    [heading]="'campaign.selectGroups' | translate"
    [tableLeft]="0"
    [isMulti]="true"
    [data]="groups"
    [controlName]="'searchGroupsInput'"
    [isInputField]="true"
    [form]="recipientsForm"
    [isLoading]="!groups"
    label="'campaign.selectGroups' | translate"
    [placeholder]="'campaign.groupNamePlaceloder' | translate"
    [width]="480"
    [isDisabled]="recipientsForm.status ==='DISABLED' || !isEditable"
  ></uni-table-filter>
  </uni-form-field>
</form>

<cc-campaign-groups-modal
  [isActive]="this.groupsControl && this.groupsControl.value ? validateGroups(this.groupsControl.value) : false"
  (isActiveChange)="isGroupsModalActive = $event"
></cc-campaign-groups-modal>
