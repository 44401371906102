import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'uni-form-search',
  templateUrl: './uni-form-search.component.html',
  styleUrls: ['./uni-form-search.component.scss']
})
export class UniFormSearchComponent implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() margin = true;
  @Input() isClear = false;
  @Input() isLoading = false;
  @Input() label = 'label.search';
  @Input() autofocus = false;
  @Output() searchInputCleared = new EventEmitter<void>();
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  get searchControl(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  clearSearchInput(): void {
    this.searchControl.reset();
    this.searchInputCleared.next();
  }

  ngAfterViewInit(): void {
    if(this.autofocus) {
      this.inputRef.nativeElement.focus();
    }
  }
}
