import { sum } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { UniCampaignFormFactory } from '../../shared';

@Component({
  selector: 'cc-campaign-recipients-result',
  templateUrl: './campaign-recipients-result.component.html',
})
export class CampaignRecipientsResultComponent {
  @Input() contentForm: FormGroup;
  @Input() recipientsForm: FormGroup;
  @Input() isAutocompleteActiveControl: FormControl;
  @Input() autocompleteControl: FormControl;
  @Input() isEditable;
  @Input() isVoice;
  @Input() isShowMultiSelect = true;

  recipientsLimit = this.campaignFormFactory.recipientsLimit;
  manualRecipientsLimit = this.campaignFormFactory.manualRecipientsLimit;
  isManual = false;

  get fileRecipientsControl(): FormControl {
    return this.recipientsForm.get('fileRecipients') as FormControl;
  }

  get phoneNumbersControl(): FormControl {
    return this.recipientsForm.get('phoneNumbers') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.recipientsForm.get('audienceGroups') as FormControl;
  }

  constructor(private campaignFormFactory: UniCampaignFormFactory) {}

  countRecipients(form): number {
    this.isManual = false;

    if (form.get('audienceGroups') && this.groupsControl.value) {
      return sum(this.groupsControl.value.map(item => item.contacts)) || 0;
    }

    if (form.get('fileRecipients')) {
      return this.fileRecipientsControl.value || 0;
    }

    if (form.get('phoneNumbers')) {
      this.isManual = true;
      return this.phoneNumbersControl.value.length || 0;
    }

    return 0;
  }

  isLimitError(form): boolean {
    const recipients = this.countRecipients(form);
    const limit = this.isManual ? this.manualRecipientsLimit : this.recipientsLimit;

    return limit < recipients;
  }
}
