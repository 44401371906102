import { uniqBy } from 'lodash';
import { Subscription, combineLatest } from 'rxjs';

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { UniAudienceFacade } from '../../../uni-audience/shared/uni-audience.facade';
import { UniGroupType } from '../../../uni-audience/shared/uni-audience.model';

import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cc-campaign-recipients-groups',
  templateUrl: './campaign-recipients-groups.component.html',
})
export class CampaignRecipientsGroupsComponent extends AutoUnsubscribe implements OnInit {
  @Input() recipientsForm: FormGroup;
  @Input() groupsControl: FormControl;
  @Input() isEditable;
  @Input() isActive;
  // eslint-disable-next-line
  @Output() onSubmit = new EventEmitter();

  isGroupsModalActive = false;
  isGroupsInputControlWatched = false;
  isGroupsControlWatched = false;
  groups = [];

  get groupsInputControl(): FormControl {
    return this.recipientsForm.get('groupsInput') as FormControl;
  }

  get searchGroupsControl(): FormControl {
    return this.recipientsForm.get('searchGroupsInput') as FormControl;
  }

  constructor(
    private uniAudienceFacade: UniAudienceFacade,
    public campaignFormFactory: UniCampaignFormFactory
  ) {
    super();
  }

  ngOnInit() {
    this.campaignFormFactory.groupsRecipients(this.recipientsForm);
    this.setGroups();

    this.subs.add(
      this.watchGroups(),
      this.watchDynamicGroups(),
      this.combineGroups(),
      this.watchSearchGroups(),
    );

    this.getContacts(this.groupsControl.value);
  }

  watchDynamicGroups(): Subscription {
    return this.groupsControl
      ? this.groupsControl.valueChanges
        .subscribe(value => {
          this.getContacts(value);
          this.isGroupsControlWatched = true;
        })
      : null;
  }

  watchSearchGroups(): Subscription {
    return this.searchGroupsControl.valueChanges
    .subscribe(searchGroup => {
      searchGroup = searchGroup || [];
      if(this.groups) {
        this.groupsControl.reset();
        this.groups.filter(group => searchGroup.find(item => group.id === item.key)).map((item) =>this.groupsInputControl.setValue(item));
      }
    });
  }

  watchGroups(): Subscription {
    return this.groupsInputControl.valueChanges
    .subscribe(value => {
      this.addGroup(value);
      this.isGroupsInputControlWatched = true;
    });
  }

  getContacts(value) {
    if (value) {
      value.map((item) => {
        if (
          item.filters
          && !item.contacts
          && !item.requestSent
        ) {
          this.uniAudienceFacade.setDynamicGroup(item.id);
          this.recipientsForm.updateValueAndValidity();
          item.requestSent = true;
        }
        return;
      });
    }
    this.recipientsForm.updateValueAndValidity();
  }

  addGroup(value: any) {
    if (!value || !value.name) {
      return;
    }

    if (value.filters) {
      this.openModal();
    }

    const currentValue = this.groupsControl.value || [];
    const uniqueGroups = uniqBy([...currentValue, value], 'id');
    this.groupsControl.setValue(uniqueGroups);
    if (!this.isGroupsControlWatched) {
      this.subs.add(
        this.watchDynamicGroups()
      );
    }

    this.groupsInputControl.reset();
  }

  validateGroups(groups): boolean {
    const latestGroup = groups[groups.length - 1] || {};

    return latestGroup.filters
      && latestGroup.contacts === 0
      && this.isGroupsModalActive
      && this.isActive;
  }

  setGroups() {
    if (this.isEditable) {
      this.uniAudienceFacade.setGroups({ limit: 1000 });
      this.uniAudienceFacade.setDynamicGroups({ limit: 1000 });
    }
  }

  combineGroups(): Subscription {
    return combineLatest([
      this.uniAudienceFacade.groups$.pipe(
        filter(data => !!data),
      ),
      this.uniAudienceFacade.dynamicGroups$.pipe(
        filter(data => !!data),
      )
    ]).subscribe((value) => {
      this.groups = [...value[0], ...value[1]];
      this.groups.map((group) => {
        if (group.updatedName) {
          return;
        }

        if (group.filters) {
          group.updatedName = group.name + ` (${UniGroupType.dynamic})`;
        } else {
          group.updatedName = group.name + ` (${UniGroupType.static})`;
        }

        return;
      });
    });
  }

  openModal(): void {
    this.isGroupsModalActive = true;
  }
}
