import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'uni-plural',
  templateUrl: './uni-plural.component.html'
})
export class UniPluralComponent {
  @Input() amount = 0;
  @Input() single = 'item';
  @Input() multi = 'items';
  @Input() entity = '';

  constructor(
    private translate: TranslateService,
  ) {}

  getTranslation(items: number) {
    if (!this.entity) {
      return this.translate.instant(`label.${
        items === 1
          ? this.single
          : this.multi
      }`);
    }

    let translationKey = `plurals.${this.entity}.`;

    switch (true) {
      case (items === 0): {
        translationKey = `${translationKey}0`;
        break;
      }
      case (items === 1): {
        translationKey = `${translationKey}1`;
        break;
      }
      case (items === 2): {
        translationKey = `${translationKey}2`;
        break;
      }
      case (2 < items && items <= 10): {
        translationKey = `${translationKey}3_10`;
        break;
      }
      case (10 < items): {
        translationKey = `${translationKey}11_up`;
        break;
      }
      default: {
        translationKey = `${translationKey}11_up`;
      }
    }

    return this.translate.instant(translationKey);
  }
}
