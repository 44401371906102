import { Component, Input } from '@angular/core';
import names from 'country-to-iso/data/names.json';
import alpha2 from 'country-to-iso/data/iso-alpha-2.json';
import alpha3 from 'country-to-iso/data/iso-alpha-3.json';

@Component({
  selector: 'uni-country-flag',
  templateUrl: './uni-country-flag.component.html',
  styleUrls: ['./uni-country-flag.component.scss']
})
export class UniCountryFlagComponent {
  @Input() country = 'sa';
  @Input() size = 'lg';
  @Input() shape = 'rectangle';

  getCountryCode(country: string = 'sa'): string {
    // @TODO: remove the condition below once this PR is merged
    // to country-to-iso package module:
    // https://github.com/nojacko/node-country-to-iso/pull/1
    if (country.toLowerCase() === 'ksa') {
      country = 'sa';
    }

    if (country.length < 2) {
      return null;
    }

    const updatedCountry = this.removeSpaces(this.normalize(country));

    // Too short
    if (updatedCountry.length < 2) {
      return null;
    }

    // Already ISO 3166 alpha 2
    if (updatedCountry.length === 2 && alpha2.includes(updatedCountry)) {
      return updatedCountry;
    }

    // Is ISO 3166 alpha 3
    if (updatedCountry.length === 3 && alpha3[updatedCountry]) {
      return alpha3[updatedCountry];
    }

    // Exact match
    if (names[updatedCountry]) {
      return names[updatedCountry];
    }

    return null;
  }

  normalize(country: string): string {
    return country.toLocaleUpperCase()
      .replace(/[,\.\(\)]/g, ' ')
      // Remove punctuation
      .replace(/['\-]/g, '')
      // Remove unimportant words
      .replace(/(^|\s)OF(\s|$)/gi, ' ')
      .replace(/(^|\s)AND(\s|$)/gi, ' ')
      .replace(/(^|\s)THE(\s|$)/gi, ' ')
      .replace(/(^|\s)&AMP;(\s|$)/gi, ' ')
      .replace(/(^|\s)&(\s|$)/gi, ' ')
      // Remove excess
      .replace(/\s+/g, ' ')
      .trim();
  }

  removeSpaces(country: string): string {
    return country.replace(/\s/g, '');
  }

  getFlag(country) {
    return `fp-${this.getCountryCode(country).toLocaleLowerCase()} fp-${this.shape} fp-${this.size}`;
  }
}
