export enum Status {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
  available = 'available',
  unavailable = 'unavailable'
}

export type Margin = 'right' | 'fixed';

export type Size = 'regular' | 'big';
