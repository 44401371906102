import { Component, HostBinding, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'uni-loader',
  templateUrl: './uni-loader.component.html',
  styleUrls: ['./uni-loader.component.scss']
})
export class UniLoaderComponent {
  @HostBinding('class.is-fullscreen') @Input() isFullScreen = false;
  @HostBinding('class.is-absolute') @Input() isAbsolute = false;
  @HostBinding('class.is-grey') @Input() isGrey = false;

  options: AnimationOptions = {
    path: '/assets/loader/loader.json',
  };
}
