import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as selectors from './caller-id.selectors';
import { CallerIdState } from './caller-id.state';
import { isQueryInProgress$ } from '../query/query.utils';
import * as callerIdActions from './caller-id.actions';
import { CallerIdPost, CallerIdChannelPost, CallerIdChannelChargesParams, CallerIdChannelConfigParams } from '../../models';

@Injectable()
export class CallerIdFacade {
  callerIds$ = this.store.pipe(select(selectors.selectCallerIds));
  callerIdsAmount$ = this.store.pipe(select(selectors.selectCallerIdsAmount));
  secondaryCallerIds$ = this.store.pipe(select(selectors.selectSecondaryCallerIds));
  secondaryCallerIdsAmount$ = this.store.pipe(select(selectors.selectSecondaryCallerIdsAmount));
  callerIdsSubscriptions$ = this.store.pipe(select(selectors.selectCallerIdsSubscriptions));
  callerIdsSubscriptionsAmount$ = this.store.pipe(select(selectors.selectCallerIdsSubscriptionsAmount));
  getCallerIdsQuery$ = this.store.pipe(select(selectors.selectGetCallerIdQuery));
  getSecondaryCallerIdsQuery$ = this.store.pipe(select(selectors.selectGetSecondaryCallerIdQuery));
  getCallerIdsSubscriptionsQuery$ = this.store.pipe(select(selectors.selectGetCallerIdSubscriptionsQuery));
  postCallerIdQuery$ = this.store.pipe(select(selectors.selectPostCallerIdQuery));
  callerIdFormErrors$ = this.store.pipe(select(selectors.selectCallerIdFormErrors));
  deleteErrors$ = this.store.pipe(select(selectors.selectCallerIdDeleteErrors));
  accountHasActiveCallerId$ = this.store.pipe(select(selectors.selectAccountHasActiveCallerId));

  callerIdChannels$ = this.store.pipe(select(selectors.selectCallerIdChannels));
  getCallerIdChannelsQuery$ = this.store.pipe(select(selectors.selectGetCallerIdChannelsQuery));
  callerIdChannelsAmount$ = this.store.pipe(select(selectors.selectCallerIdChannelsAmount));
  callerIdChannelConfig$ = this.store.pipe(select(selectors.selectCallerIdChannelConfig));
  getCallerIdChannelConfigQuery$ = this.store.pipe(select(selectors.selectGetCallerIdChannelConfigQuery));
  callerIdChannelCharges$ = this.store.pipe(select(selectors.selectCallerIdChannelCharges));
  getCallerIdChannelChargesQuery$ = this.store.pipe(select(selectors.selectGetCallerIdChannelChargesQuery));
  postCallerIdChannelQuery$ = this.store.pipe(select(selectors.selectPostCallerIdChannelQuery));

  constructor(private store: Store<CallerIdState>) {}

  loader$ = new BehaviorSubject(isQueryInProgress$([
    this.getCallerIdsQuery$,
    this.getSecondaryCallerIdsQuery$,
    this.getCallerIdsSubscriptionsQuery$,
    this.postCallerIdQuery$,
    this.getCallerIdChannelsQuery$,
    this.getCallerIdChannelConfigQuery$,
    this.getCallerIdChannelChargesQuery$,
    this.postCallerIdChannelQuery$,
  ]));
  isLoading$ = this.loader$.pipe(mergeMap(isActive => isActive));

  setCallerIds(params: Params = {}): void {
    this.store.dispatch(new callerIdActions.SetCallerIds(params));
  }

  setSecondaryCallerIds(params: Params = {}): void {
    this.store.dispatch(new callerIdActions.SetSecondaryCallerIds(params));
  }

  setCallerIdsSubscriptions(params: Params = {}): void {
    this.store.dispatch(new callerIdActions.SetCallerIdsSubscriptions(params));
  }

  clearCallerIds(): void {
    this.store.dispatch(new callerIdActions.SetCallerIdsSuccess(null));
    this.store.dispatch(new callerIdActions.SetSecondaryCallerIds(null));
    this.store.dispatch(new callerIdActions.SetCallerIdsSubscriptionsSuccess(null));
  }

  postCallerId(payload: CallerIdPost): void {
    this.store.dispatch(new callerIdActions.PostCallerId(payload));
  }

  deleteCallerIds(ids: string[]): void {
    this.store.dispatch(new callerIdActions.DeleteCallerIds(ids));
  }

  setHasActiveCallerId(): void {
    this.store.dispatch(new callerIdActions.AccountHasActiveCallerId());
  }

  getCallerIdChannels(params: Params = {}): void {
    this.store.dispatch(new callerIdActions.SetCallerIdChannels(params));
  }

  getCallerIdChannelConfig(params: CallerIdChannelConfigParams): void {
    this.store.dispatch(new callerIdActions.SetCallerIdChannelConfig(params));
  }

  getCallerIdChannelCharges(params: CallerIdChannelChargesParams): void {
    this.store.dispatch(new callerIdActions.SetCallerIdChannelCharges(params));
  }

  postCallerIdChannel(payload: CallerIdChannelPost): void {
    this.store.dispatch(new callerIdActions.PostCallerIdChannel(payload));
  }
}
