import { Order } from '../shared/uni-orders.model';

export const ORDERS_KEY = 'orders';

export interface UniOrdersState {
  error?: any;
  finalizedOrder?: Order;
  order?: Order;
}
export const initialState: UniOrdersState = {};
