import { animate, style, transition, trigger } from '@angular/animations';

export const navAnimations = [
  trigger(
    'navToggle', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('200ms', style({ transform: 'translateX(-100%)' }))
      ])
    ]
  )
];
