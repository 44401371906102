<uni-icon
  name="user-clock"
  [isRightSpace]="true"
  [isInherit]="true"
></uni-icon>
<div *ngIf="account$ | async as account">
  <div class="uni-top-bar-timezone__label uni-top-bar-balance__timezone--margin">
    {{ 'navigation.timezone' | translate }}
  </div>
  <div class="uni-top-bar-timezone__value">
    {{ userTimezone }}
  </div>
</div>
