import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GeopermissionsStore } from './geopermissions.store';

@Component({
  selector: 'app-geopermissions',
  templateUrl: './geopermissions.component.html',
  styleUrls: ['./geopermissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GeopermissionsStore]
})
export class GeopermissionsComponent {
  constructor(
    private store: GeopermissionsStore
  ) { }


  ngOnInit(): void {
  }

  vm$ = this.store.vm$
}
