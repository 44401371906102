<div>
  <uni-link
    *ngIf="firstItem.link"
    [routerLink]="!firstItem.isExternalLink ? firstItem.link : null"
    (click)="firstItem.isExternalLink ? uniRouteService.navigate(firstItem.link) : null"
  >
    {{ firstItem.name | translate }}
  </uni-link>
  <ng-container *ngIf="!firstItem.link">
    {{ firstItem.name | translate }}
  </ng-container>
  <span
    class="uni-more-list__more"
    *ngIf="isMoreIcon"
    (click)="toggleState()"
  >
    (+{{ items.length - 1 }})
    <uni-icon-arrow class="uni-more-list__arrow"></uni-icon-arrow>
  </span>
</div>

<div class="uni-more-list" #wrapper>
  <div
    class="uni-more-list__item"
    *ngFor="let item of items; let isFirst = first; let isOdd = odd; let isEven = even; let isLast = last;"
    [ngClass]="{
      'is-odd': isOdd,
      'is-even': isEven,
      'is-last': isLast,
      'is-first': isLast
    }"
  >
    <uni-link
      *ngIf="item.link"
      [routerLink]="!item.isExternalLink ? item.link : null"
      (click)="item.isExternalLink ? uniRouteService.navigate(item.link) : null"
    >
      {{ item.name | translate }}
    </uni-link>
    <ng-container *ngIf="!item.link">
      {{ item.name | translate }}
    </ng-container>
    <span
      class="uni-more-list__more"
      *ngIf="isMoreIcon && isFirst"
      (click)="toggleState()"
    >
      (+{{ items.length - 1 }})
      <uni-icon-arrow
        class="uni-more-list__arrow"
        [isOpen]="true"
      ></uni-icon-arrow>
    </span>
  </div>
</div>
