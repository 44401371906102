import { Component, OnInit } from '@angular/core';
import { FiltersStore } from '../../stores/filters.store';
import { AccountListStore } from 'src/app/shared/store-mocks/account-list.store';

@Component({
  selector: 'app-filter-panel-account-id',
  templateUrl: './filter-panel-account-id.component.html',
  styleUrls: ['./filter-panel-account-id.component.scss']
})
export class FilterPanelAccountIdComponent implements OnInit {

  accounts$ = this.accountListStore.accounts$
  accountsLoading$ = this.accountListStore.loading$

  accountIdValue$ = this.filters.accountId$

  constructor(
    private filters: FiltersStore,
    private accountListStore: AccountListStore,
  ) { }

  ngOnInit(): void {}

  onValueChange(accountId: string){

    this.filters.patchState({accountId})
  }
}
