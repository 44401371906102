import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-plural-text',
  templateUrl: './uni-plural-text.component.html'
})
export class UniPluralTextComponent {
  @Input() singleKey = 'table.itemSelected';
  @Input() multiKey = 'table.itemsSelected';
  @Input() amount: number;
  @Input() params = {};
}
