<ng-container
  *ngIf="newMenuEnabled$ | async as isMenuEnabled; else noMenu"
>
  <uni-top-bar>
    <uni-icon
      #hamburger
      [isBorder]="true"
      class="uni-nav__hamburger-icon"
      name="bars"
      size="40" [style]="'far'"
      (click)="toggleHamburgerMenu()">
    </uni-icon>
  </uni-top-bar>
  <uni-menu-content></uni-menu-content>
  <div
    class="uni-nav__container"
    [ngClass]="{'is-collapsed': isNavCollapsed$ | async }"
  >
    <div
      class="uni-nav" [ngClass]="{'uni-nav--is-open': isMenuOpen}"
    >
        <uni-nav-new-toggle></uni-nav-new-toggle>

        <ng-container *ngIf="navModel$ | async as navModel">
            <ng-container *ngFor="let group of navModel.groups">
                <uni-nav-new-group [group]="group"></uni-nav-new-group>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngTemplateOutlet="noMenu">
    </ng-container>
  </div>
</ng-container>
<ng-template #noMenu>
  <ng-content></ng-content>
  <uni-footer></uni-footer>
</ng-template>

<!-- @TODO: plan better containers structur in project later -->
