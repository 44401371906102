<div class="uni-page">
  <uni-page-logo></uni-page-logo>
  <div class="uni-page__title">
    <ng-content select="[title]"></ng-content>
  </div>
  <div class="uni-page__error">
    <ng-content select="[error]"></ng-content>
  </div>
  <uni-button
    class="uni-page__button"
    path="/"
    [style]="'secondary'"
    [fit]="true"
  >
    {{ 'page.button' | translate }}
  </uni-button>
  <div class="uni-page__footer">
    <uni-icon name="envelope"></uni-icon>
    {{ 'page.option' | translate }}
    <uni-link path="mailto:support@unifonic.com">support@unifonic.com</uni-link>
  </div>
</div>
