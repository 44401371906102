import { Component } from '@angular/core';
import { UniTextWeight, UniTextColor } from '../../shared/uni-text.model';

@Component({
  selector: 'uni-text-result',
  templateUrl: './uni-text-result.component.html',
  styleUrls: ['./uni-text-result.component.scss'],
})
export class UniTextResultComponent {
  fontWeight: UniTextWeight = UniTextWeight.regular;
  fontColor: UniTextColor = UniTextColor.darkLight;
}
