import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-widget-header-title',
  templateUrl: './uni-widget-header-title.component.html',
  styleUrls: ['./uni-widget-header-title.component.scss'],
})
export class UniWidgetHeaderTitleComponent {
  @Input() margin = 'default';

  @HostBinding('class') get getClasses(): string {
    return `uni-widget-header-title--margin-${this.margin}`;
  }

}
