<ng-container *ngIf="(vm$ | async) as vm">
    <div class="data-panel">
        <div class="data-panel-items">
            <strong>{{'ci.logAnalyzer.table.numberOfItems' | translate: {items: vm.totalCount} }}</strong>
        </div>
        <div class="data-panel-actions">
            <app-message-logs-refresh-control></app-message-logs-refresh-control>
            <!-- <app-message-logs-report-generator></app-message-logs-report-generator> -->
        </div>
    </div>

    <uni-table>
        <app-loading-mask *ngIf="vm.loading"></app-loading-mask>
        <table
            cdk-table
            [dataSource]="vm.entities"
            [trackBy]="trackId"
            multiTemplateDataRows
        >
            <tr
                cdk-header-row
                *cdkHeaderRowDef="displayedColumns"
            ></tr>

            <tr
                cdk-header-row
                *cdkHeaderRowDef="['empty']"
                [hidden]="!!vm.totalCount"
            ></tr>

            <ng-container [cdkColumnDef]="columns.empty">
                <th
                    [attr.colspan]="displayedColumns.length"
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    <div class="empty-msg">
                        {{ "ci.common.weFoundNothing" | translate}}
                    </div>
                </th>
            </ng-container>
            <tr
                cdk-row
                *cdkRowDef="let row; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
                [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
            ></tr>
            <tr
                cdk-row
                *cdkRowDef="let row; columns: ['details']; let isOdd = odd; let isEven = even; let isLast = last"
                [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
                [hidden]="!row.active"
            ></tr>
            <ng-container [cdkColumnDef]="columns.details">
                <td
                    [attr.colspan]="displayedColumns.length"
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <app-message-logs-table-details-voice
                        *ngIf="element.active"
                        [id]="element.id"
                    ></app-message-logs-table-details-voice>
                </td>

            </ng-container>
            <ng-container [cdkColumnDef]="columns.recipient">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    {{'ci.logAnalyzer.table.headers.recipient' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <div class="text-overflow">
                        {{ element.recipient }}
                    </div>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.sender">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    {{'ci.common.callerId' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <div class="text-overflow">
                        {{ element.sender }}
                    </div>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.direction">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    <div class="header-cell sort-header left">
                      {{'ci.common.direction' | translate}}
                      <app-data-sort
                          [dir]="sortDir$ | async"
                          [isActive]="sortDataDirectionActive$ | async"
                          (valueChange)="sort( 'direction', $event )"
                      ></app-data-sort>
                    </div>
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <div class="text-overflow status-format">
                        {{ element.direction }}
                    </div>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.start">
              <th
                  cdk-header-cell
                  *cdkHeaderCellDef
                  #thead
              >
                  <div class="header-cell sort-header left">
                    {{'ci.common.timestamp' | translate}}
               
                    <app-data-sort
                        [dir]="sortDir$ | async"
                        [isActive]="sortDataDateActive$ | async"
                        (valueChange)="sort( 'requestReceivedAt', $event )"
                    ></app-data-sort>
                  </div>
              </th>
              <td
                  cdk-cell
                  *cdkCellDef="let element"
              >
                  {{ element.start | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}
              </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.status">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                >
                    {{'ci.logAnalyzer.table.headers.status' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                >
                    <div class="text-overflow status-format">
                        {{ element.status }}
                    </div>
                </td>
            </ng-container>
            <ng-container [cdkColumnDef]="columns.action">
                <th
                    cdk-header-cell
                    *cdkHeaderCellDef
                    #thead
                    [style.width.px]="100"
                    [style.textAlign]="'right'"
                >

                    {{'ci.logAnalyzer.table.headers.action' | translate}}
                </th>
                <td
                    cdk-cell
                    *cdkCellDef="let element"
                    [style.textAlign]="'right'"
                    class="cell-action"
                >
                    <uni-button
                        *ngIf="!element.messageRejected"
                        type="button"
                        [style]="'secondary'"
                        [size]="'small'"
                        [isMargin]="false"
                        [autoWidth]="true"
                        [noHorizontalSpace]="true"
                        [noVerticalSpace]="true"
                        (click)="toggleDetails(element.recordId)"
                    >

                        <i class="fa fa-chevron-down" *ngIf="!element.active"></i>
                        <i class="fa fa-chevron-up" *ngIf="element.active"></i>

                    </uni-button>

                </td>
            </ng-container>
        </table>
    </uni-table>
    <div class="table-pagination">
        <app-pagination
            [items]="vm.totalCount"
            [itemsPerPage]="limit$ | async"
            (pageChange)="onPageChange($event)"
            [valueTracker]="page$ | async"
        ></app-pagination>
    </div>
</ng-container>
