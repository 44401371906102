import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UniFilterBarComponent, UniFilterBarContentComponent, UniFilterBarItemComponent } from './components';

const components = [
  UniFilterBarComponent,
  UniFilterBarContentComponent,
  UniFilterBarItemComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniFilterBarModule { }
