import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-overlay',
  templateUrl: './uni-overlay.component.html',
  styleUrls: ['./uni-overlay.component.scss'],
})
export class UniOverlayComponent {
  @HostBinding('class.is-active') @Input() isActive = false;
}
