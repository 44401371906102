import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniIconArrowComponent } from './components/uni-icon-arrow/uni-icon-arrow.component';
import { UniIconComponent } from './components/uni-icon/uni-icon.component';
import { UniIconActionComponent } from './components/uni-icon-action/uni-icon-action.component';

const components = [
  UniIconComponent,
  UniIconArrowComponent,
  UniIconActionComponent,
];

@NgModule({
  imports: [TranslateModule, CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class UniIconModule { }
