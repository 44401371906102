import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { isQueryInProgress$ } from '../../../store';
import { UniUserState } from '../store';
import * as uniUsersActions from '../store/uni-user.actions';
import { selectGetUsersQuery, selectUsers } from '../store/uni-user.selectors';

@Injectable()
export class UniUserFacade {
  users$ = this.store.pipe(select(selectUsers));
  usersQuery$ = this.store.pipe(select(selectGetUsersQuery));
  isLoading$ = new BehaviorSubject(isQueryInProgress$([
    this.usersQuery$,
  ]));

  constructor(private store: Store<UniUserState>) {}

  setUsers(params: Params = {}): void {
    this.store.dispatch(new uniUsersActions.SetUsers(params));
  }

  clearUsers(): void {
    this.store.dispatch(new uniUsersActions.ClearUsers());
  }
}
