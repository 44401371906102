import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, Input } from '@angular/core';

import { UniLanguage, UniLanguageNative } from '../../models/index';
import { UniLanguageRepository } from '../../shared/uni-language.repository';
import { UniLanguageFormFactory } from '../../shared/uni-language-form.factory';
// import { UniLanguageService } from '../../shared/uni-language.service';

@Component({
  selector: 'uni-language-switcher',
  templateUrl: './uni-language-switcher.component.html',
  styleUrls: ['./uni-language-switcher.component.scss']
})
export class UniLanguageSwitcherComponent {
  /**
   * @deprecated Use 'languageOptions'
   */
  @Input() languages: UniLanguage[] = [];
  @Input() isMarginLeft = false;

  languageForm = this.languageFormFactory.build();
  languageOptions = [
    { value: UniLanguage.en, label: UniLanguageNative.en },
    { value: UniLanguage.ar, label: UniLanguageNative.ar },
  ];

  @HostBinding('class') get getClasses(): string {
    return `${this.isMarginLeft ? 'uni-language-switcher--margin-left' : ''}`;
  }

  constructor(
    // private languageService: UniLanguageService,
    private languageFormFactory: UniLanguageFormFactory,
    private uniLanguageRepository: UniLanguageRepository,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  setLanguage(language: UniLanguage): void {
    this.uniLanguageRepository
      .postLanguage(language)
      .subscribe(() => {
        // Keep this commented as long as we use reload here
        // this.languageService.setLanguage(language);

        this.document.location.reload();
      });
  }
}
