import { Country } from '../../uni-countries/shared/uni-countries.model';
import { FormError } from '../../../modules/uni-form/shared/uni-form.model';

import { BlockWords, Campaign, CampaignExportId, CampaignLog, CampaignTemplate } from '../shared/uni-campaign.model';

export const UNI_CAMPAIGN_KEY = 'uniCampaigns';

export interface CampaignState {
  campaignFormErrors?: FormError[];
  campaign?: Campaign;
  campaignLog?: CampaignLog[];
  campaigns?: Campaign[];
  campaignExportId?: CampaignExportId;
  campaignExport?: Blob;
  campaignsTotalItems?: number;
  campaignsLogTotalItems?: number;
  campaignTemplates?: CampaignTemplate[];
  campaignTrialTemplates?: CampaignTemplate[];
  campaignTemplate?: CampaignTemplate;
  campaignFile?: Blob;
  autocompleteCountries?: Country[];
  changeCampaignStatus?: any;
  blockWords?: BlockWords;
}

export const campaignState: CampaignState = {};
