<div
  class="uni-nav-item-icon__container"
  [ngClass]="{ 'is-route-active-icon': isRouteActive && iconName }"
  [matTooltip]="'navigation.' + labelKey | translate"
  [matTooltipDisabled]="!isNavCollapsed"
  matTooltipPosition="below"
>
  <i
    *ngIf="iconName"
    class="uni-nav-item-icon__symbol"
    class="{{ getStyle(iconName) }} fa-{{ iconName }}"
  ></i>
</div>
