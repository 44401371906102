import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UniTextColor } from '../../../uni-text';
import { UniIconType } from '../../../uni-icon';
import { Package, PackageUnits, ServiceType } from '../../../../shared/uni-data/model/packages.model';
import { UniShortNumberService } from '../../../../shared/uni-short-number.service';

@Component({
  selector: 'uni-top-bar-packages-details',
  templateUrl: './uni-top-bar-packages-details.component.html',
  styleUrls: ['./uni-top-bar-packages-details.component.scss']
})
export class UniTopBarPackagesDetailsComponent {
  @Input() package: Package;
  color = UniTextColor;
  iconType = UniIconType.secondary;

  constructor(
    private translate: TranslateService,
    private uniShortNumberService: UniShortNumberService,
  ) {}

  getType(item: Package) {
    const packages: string[] = [
      ServiceType.sms,
      ServiceType.voice,
      ServiceType.whatsapp,
      ServiceType.chatbot,
      ServiceType.mcc,
      ServiceType.multi
    ];
    return !packages.includes(item.serviceType);
  }

  getIcon(type) {
    switch (type) {
      case ServiceType.sms: {
        return 'uni-channel-sms';
      }
      case ServiceType.whatsapp: {
        return 'uni-channel-whatsapp';
      }
      case ServiceType.voice: {
        return 'uni-channel-voice';
      }
      case ServiceType.mcc:
      case ServiceType.multi: {
        return 'uni-multichannel';
      }
      case ServiceType.chatbot: {
        return 'uni-chatbot';
      }
      default: {
        return 'uni-notice';
      }
    }
  }

  getProgress(item: Package): number {
    return item.remainingUnits / item.totalUnits * 100;
  }

  statusColorClass(item: Package): string {
    return this.getProgress(item) > 10
      ? 'uni-top-bar-packages-details__progress-bar--success'
      : '';
  }

  getUnits(item) {
    const { remainingUnits, totalUnits } = item;

    switch (item.serviceType) {
      case ServiceType.sms: {
        return `${
          this.uniShortNumberService.formatNumber(remainingUnits, true)
        } / ${
          this.uniShortNumberService.formatNumber(totalUnits, true)
        } ${
          this.translate.instant('packages.units.' + PackageUnits.units)
        }`;
      }
      case ServiceType.whatsapp: {
        return `${
          this.uniShortNumberService.formatNumber(remainingUnits, true)
        } / ${
          this.uniShortNumberService.formatNumber(totalUnits, true)
        } ${
          this.translate.instant('currency.' + PackageUnits.usd)
        }`;
      }
      // case ServiceType.voice: {
      //   return `${
      //     this.uniShortNumberService.formatNumber(remainingUnits, true)
      //   } / ${
      //     this.uniShortNumberService.formatNumber(totalUnits, true)
      //   } ${
      //     this.translate.instant('packages.units.' + PackageUnits.seconds)
      //   }`;
      // }
      // case ServiceType.multi:
      // case ServiceType.mcc: {
      //   return `${
      //     this.uniShortNumberService.formatNumber(remainingUnits, true)
      //   } / ${
      //     this.uniShortNumberService.formatNumber(totalUnits, true)
      //   } ${
      //     this.translate.instant('packages.units.' + PackageUnits.messages)
      //   }`;
      // }
      // case ServiceType.chatbot: {
      //   return `${
      //     this.uniShortNumberService.formatNumber(remainingUnits, true)
      //   } / ${
      //     this.uniShortNumberService.formatNumber(totalUnits, true)
      //   } ${
      //     this.translate.instant('packages.units.' + PackageUnits.sessions)
      //   }`;
      // }
      default: {
        return `${
          this.uniShortNumberService.formatNumber(remainingUnits, true)
        } / ${
          this.uniShortNumberService.formatNumber(totalUnits, true)
        } ${
          this.translate.instant('packages.units.' + PackageUnits.units)
        }`;
      }
    }
  }
}
