import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniSwitcherItemComponent } from './components/uni-switcher-item/uni-switcher-item.component';
import { UniSwitcherComponent } from './components/uni-switcher/uni-switcher.component';

const components = [
  UniSwitcherComponent,
  UniSwitcherItemComponent,
];

@NgModule({
  imports: [TranslateModule],
  declarations: [...components],
  exports: [...components],
})
export class UniSwitcherModule { }
