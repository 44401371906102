export const updateStateItem = (state, data) => {
  return {
    ...state,
    [data.id]: data
  };
};

export const updateStateList = (state, data) => {
  state.map((item) => {
    if (item.id === data.id) {
      item = data;
      return;
    }
    return;
  });

  return state;
};
