<ng-container *ngFor="let item of group.items">
  <uni-nav-new-item-group
    *ngIf="(isGroupExpanded || !item.isHidden)"
    [item]="item">
  </uni-nav-new-item-group>
</ng-container>

<uni-nav-new-group-expand-item
  (toggleGroup)="onToggleGroup()"
  *ngIf="group.groupExpandItem"
  [expandItem]="group.groupExpandItem"
  [isGroupExpanded]="isGroupExpanded"
>
</uni-nav-new-group-expand-item>
