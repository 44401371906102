import { get, values } from 'lodash';
import { Injectable, ElementRef } from '@angular/core';
import { Chart, ChartConfiguration, ChartOptions, ChartData } from 'chart.js';

@Injectable()
export class UniPieChartService {

  init(chartRef: ElementRef, data: ChartData, basicSettings = false, cutout = 60): Chart {
    const ctx = chartRef.nativeElement.getContext('2d');
    const config = this.getConfig(data, basicSettings, cutout);

    return new Chart(ctx, config);
  }

  update(chart: Chart, data: ChartData) {
    if (!chart) { return; }
    chart.data.datasets = data.datasets;
    chart.update();
  }

  getBasicOptions(cutout): ChartOptions {
    return {
      responsive: true,
      legend: { display: false },
      title: { display: false },
      maintainAspectRatio: false,
      cutoutPercentage: cutout,
      tooltips: {
        enabled: false,
      },
      hover: {
        mode: null
      },
    };
  }

  getAdvancedOptions(): ChartOptions {
    return {
      rotation: -1 * Math.PI,
      tooltips: {
        enabled: true,
        bodyFontSize: 12,
        bodyFontStyle: 'medium',
        callbacks: {
          // eslint-disable-line
          label(item, data) {
            const dataset = data.datasets[item.datasetIndex];
            const value = dataset.data[item.index];
            const meta = values(dataset._meta);
            const metadata = get(dataset, 'metadata');

            const legendItems = document.querySelectorAll('.campaign-item-hover') || [];
            legendItems.forEach(element => element.classList.remove('campaign-item-hover'));

            if (!meta) {
              return '';
            }

            if (metadata) {
              const legendItem = document.getElementById(`chart-${metadata[item.index]}`);

              if (legendItem) {
                legendItem.classList.add('campaign-item-hover');
              }
            }

            const percent = ((dataset.data[item.index] / get(meta[0], 'total') * 100)).toFixed(2);
            return `  ${value} (${percent}%)`;
          }
        },
      },
      hover: {
        // eslint-disable-line
        onHover(_, item) {
          if (!item.length) {
            const legendItems = document.querySelectorAll('.campaign-item-hover') || [];
            legendItems.forEach(element => element.classList.remove('campaign-item-hover'));
          }
        }
      },
    };
  }

  getConfig(data: ChartData, basicSettings, cutout): ChartConfiguration {
    return {
      type: 'doughnut',
      data,
      options: {
        ...this.getBasicOptions(cutout),
        ...(!basicSettings ? this.getAdvancedOptions() : {})
      }
    };
  }
}
