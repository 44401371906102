import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
/** @deprecated use UniNumberService instead */
export class UniShortNumberService {
  trill = 1000000000000;
  bil   = 1000000000;
  mln   = 1000000;
  thous = 1000;

  constructor(
    private translate: TranslateService,
  ) { }

  /** @deprecated use UniNumberService.formatShortNumber instead */
  formatNumber(price: number, skipThousand = false): string {
    if (price >= this.trill) {
      return this.translate.instant('format.trillion', {
        number: Math.floor(price / this.trill * 10) / 10
      });
    }

    if (price >= this.bil) {
      return this.translate.instant('format.billion', {number: Math.floor(price / this.bil * 10) / 10});
    }

    if (price >= this.mln) {
      return this.translate.instant('format.million', {number: Math.floor(price / this.mln * 10) / 10});
    }

    if (price >= this.thous && !skipThousand) {
      return this.translate.instant('format.thousand', {number: Math.floor(price / this.thous * 10) / 10});
    }

    if (skipThousand) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return String(Math.floor(price * 10) / 10);
  }
}
