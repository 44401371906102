<uni-label>
  {{ 'label.startDate' | translate }}
</uni-label>
<uni-form-container [formGroup]="form">
  <uni-form-field [margin]="false">
    <uni-datepicker [datepicker]="datepicker">
      <input
        formControlName="date"
        [min]="today"
        [placeholder]="'label.chooseDate' | translate"
        [matDatepicker]="datepicker"
        (click)="datepicker.open()"
        autocomplete="off"
        uniInput
        readonly
      >
      <mat-datepicker #datepicker></mat-datepicker>
    </uni-datepicker>
  </uni-form-field>
</uni-form-container>
