import { Action } from '@ngrx/store';

import { CreateOrderPayload, Order, OrderPaymentMethod } from '../shared/uni-orders.model';

export const CLEAR_ERRORS = '[Orders] CLEAR_ERRORS';

export const CREATE_ORDER = '[Orders] CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = '[Orders] CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = '[Orders] CREATE_ORDER_ERROR';

export const FINALIZE_ORDER = '[Orders] FINALIZE_ORDER';
export const FINALIZE_ORDER_SUCCESS = '[Orders] FINALIZE_ORDER_SUCCESS';
export const FINALIZE_ORDER_ERROR = '[Orders] FINALIZE_ORDER_ERROR';

export const GET_ORDER = '[Orders] GET_ORDER';
export const GET_ORDER_SUCCESS = '[Orders] GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = '[Orders] GET_ORDER_ERROR';

export class ClearErrors implements Action {
  readonly type = CLEAR_ERRORS;
}

export class CreateOrder implements Action {
  readonly type = CREATE_ORDER;

  constructor(
    public payload: CreateOrderPayload,
  ) {}
}

export class CreateOrderSuccess implements Action {
  readonly type = CREATE_ORDER_SUCCESS;

  constructor(
    public order: Order,
  ) {}
}

export class CreateOrderError implements Action {
  readonly type = CREATE_ORDER_ERROR;

  constructor(
    public error: any,
  ) {}
}

export class FinalizeOrder implements Action {
  readonly type = FINALIZE_ORDER;

  constructor(
    public orderId: string,
    public paymentMethod: OrderPaymentMethod,
  ) {}
}

export class FinalizeOrderSuccess implements Action {
  readonly type = FINALIZE_ORDER_SUCCESS;

  constructor(
    public order: Order,
  ) {}
}

export class FinalizeOrderError implements Action {
  readonly type = FINALIZE_ORDER_ERROR;

  constructor(
    public error: any,
  ) {}
}


export class GetOrder implements Action {
  readonly type = GET_ORDER;

  constructor(
    public orderId: string,
  ) {}
}

export class GetOrderSuccess implements Action {
  readonly type = GET_ORDER_SUCCESS;

  constructor(
    public order: Order,
  ) {}
}

export class GetOrderError implements Action {
  readonly type = GET_ORDER_ERROR;

  constructor(
    public error: any,
  ) {}
}

export type All =
  | ClearErrors
  | CreateOrder
  | CreateOrderSuccess
  | CreateOrderError
  | FinalizeOrder
  | FinalizeOrderSuccess
  | FinalizeOrderError
  | GetOrder
  | GetOrderSuccess
  | GetOrderError
  ;
