import { Component, OnInit } from '@angular/core';

import { AutoUnsubscribe } from '../../../../utils';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';

@Component({
  selector: 'uni-top-bar-products',
  templateUrl: './uni-top-bar-products.component.html',
  styleUrls: ['./uni-top-bar-products.component.scss']
})
export class UniTopBarProductsComponent extends AutoUnsubscribe implements OnInit {
  isProductNavOpen = false;

  constructor(private uniNavFacade: UniNavFacade) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.uniNavFacade.isProductNavOpen$
      .subscribe(state => this.isProductNavOpen = state);
  }

  onClick(state): void {
    this.uniNavFacade.setProductNavState(!state);
  }
}
