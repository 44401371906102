import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-rich-radio-button',
  templateUrl: './uni-rich-radio-button.component.html',
  styleUrls: [ './uni-rich-radio-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UniRichRadioButtonComponent {
  @Input() isSelected: boolean;
  @Input() isDisabled: boolean;
  @Output() select = new EventEmitter();
}
