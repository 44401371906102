import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniCommonModule } from '@unifonic/common';
import { SharedModule } from './../shared/shared.module';
import { AdvancedFiltersFormMessageTypeComponent } from './components/advanced-filters-form-message-type/advanced-filters-form-message-type.component';
import { AdvancedFiltersFormSenderNameComponent } from './components/advanced-filters-form-sender-name/advanced-filters-form-sender-name.component';
import { AdvancedFiltersFormStatusComponent } from './components/advanced-filters-form-status/advanced-filters-form-status.component';
import { AdvancedFiltersFormComponent } from './components/advanced-filters-form/advanced-filters-form.component';
import { FilterFormsComponent } from './components/filter-forms/filter-forms.component';
import { FilterPanelChannelsComponent } from './components/filter-panel-channels/filter-panel-channels.component';
import { FilterPanelDateRangeComponent } from './components/filter-panel-date-range/filter-panel-date-range.component';
import { FilterPanelProductsComponent } from './components/filter-panel-products/filter-panel-products.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { GeneralFiltersFormAccountComponent } from './components/general-filters-form-account/general-filters-form-account.component';
import { GeneralFiltersFormChannelsComponent } from './components/general-filters-form-channels/general-filters-form-channels.component';
import { GeneralFiltersFormDateComponent } from './components/general-filters-form-date/general-filters-form-date.component';
import { GeneralFiltersFormProductsComponent } from './components/general-filters-form-products/general-filters-form-products.component';
import { GeneralFiltersFormRecipientsComponent } from './components/general-filters-form-recipients/general-filters-form-recipients.component';
import { GeneralFiltersFormComponent } from './components/general-filters-form/general-filters-form.component';
import { MessageLogsTableDetailsComponent } from './components/message-logs-table-details/message-logs-table-details.component';
import { MessageLogsTableComponent } from './components/message-logs-table/message-logs-table.component';
import { LogAnalyserRoutingModule } from './log-analyser-routing.module';
import { PageIndexComponent } from './page-index/page-index.component';
import { TranslateModule } from '@ngx-translate/core';
import { MessageLogsReportGeneratorComponent } from './components/message-logs-report-generator/message-logs-report-generator.component';
import { MessageLogsRefreshControlComponent } from './components/message-logs-refresh-control/message-logs-refresh-control.component';
import { StatusMockStore } from '../shared/store-mocks/status.store';
import { AdvancedFiltersFormCampaignStatusComponent } from './components/advanced-filters-form-campaign-status/advanced-filters-form-campaign-status.component';
import { AdvancedFiltersFormCampaignIdComponent } from './components/advanced-filters-form-campaign-id/advanced-filters-form-campaign-id.component';
import { FilterPanelAccountIdComponent } from './components/filter-panel-account-id/filter-panel-account-id.component';
import { AdvancedFiltersFormSenderNumberComponent } from './components/advanced-filters-form-sender-number/advanced-filters-form-sender-number.component';
import { MessageLogsTableDetailsSmsComponent } from './components/message-logs-table-details-sms/message-logs-table-details-sms.component';
import { MessageLogsTableDetailsWhatsappComponent } from './components/message-logs-table-details-whatsapp/message-logs-table-details-whatsapp.component';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { AdvancedFiltersFormWhatsappStatusComponent } from './components/advanced-filters-form-whatsapp-status/advanced-filters-form-whatsapp-status.component';
import {OverlayModule} from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { AdvancedFiltersFormCustomerReasonComponent } from './components/advanced-filters-form-customer-reason/advanced-filters-form-customer-reason.component';
import { AdvancedFiltersFormButtonComponent } from './components/advanced-filters-form-button/advanced-filters-form-button.component';
import { AdvancedFiltersFormTemplateComponent } from './components/advanced-filters-form-template/advanced-filters-form-template.component';
import { AdvancedFiltersFormLinkComponent } from './components/advanced-filters-form-link/advanced-filters-form-link.component';
import { AdvancedFiltersFormLocationComponent } from './components/advanced-filters-form-location/advanced-filters-form-location.component';
import { FilterPanelMultiCampaignIdsComponent } from './components/filter-panel-multi-campaign-ids/filter-panel-multi-campaign-ids.component';
import { FilterPanelWhatsappStatusComponent } from './components/filter-panel-whatsapp-status/filter-panel-whatsapp-status.component';
import { MessageLogsTableSmsWhatsappComponent } from './components/message-logs-table-sms-whatsapp/message-logs-table-sms-whatsapp.component';
import { MessageLogsTableVoiceComponent } from './components/message-logs-table-voice/message-logs-table-voice.component';
import { MessageLogsTableDetailsVoiceComponent } from './components/message-logs-table-details-voice/message-logs-table-details-voice.component';
import { AdvancedFiltersFormCallerIdComponent } from './components/advanced-filters-form-caller-id/advanced-filters-form-caller-id.component';
import { AdvancedFiltersFormDirectionComponent } from './components/advanced-filters-form-direction/advanced-filters-form-direction.component';
import { DirectionMockStore } from '../shared/store-mocks/direction.store';
import { AdvancedFiltersFormVoiceStatusComponent } from './components/advanced-filters-form-voice-status/advanced-filters-form-voice-status.component';

@NgModule({
  declarations: [
    PageIndexComponent,
    GeneralFiltersFormComponent,
    GeneralFiltersFormChannelsComponent,
    GeneralFiltersFormProductsComponent,
    GeneralFiltersFormAccountComponent,
    GeneralFiltersFormRecipientsComponent,
    AdvancedFiltersFormSenderNameComponent,
    AdvancedFiltersFormStatusComponent,
    AdvancedFiltersFormMessageTypeComponent,
    FilterFormsComponent,
    AdvancedFiltersFormComponent,
    FilterPanelComponent,
    FilterPanelChannelsComponent,
    FilterPanelProductsComponent,
    FilterPanelDateRangeComponent,
    MessageLogsTableComponent,
    MessageLogsTableDetailsComponent,
    GeneralFiltersFormDateComponent,
    MessageLogsReportGeneratorComponent,
    MessageLogsRefreshControlComponent,
    AdvancedFiltersFormCampaignStatusComponent,
    AdvancedFiltersFormCampaignIdComponent,
    FilterPanelAccountIdComponent,
    AdvancedFiltersFormSenderNumberComponent,
    MessageLogsTableDetailsSmsComponent,
    MessageLogsTableDetailsWhatsappComponent,
    AdvancedFiltersFormWhatsappStatusComponent,
    AdvancedFiltersFormCustomerReasonComponent,
    AdvancedFiltersFormButtonComponent,
    AdvancedFiltersFormTemplateComponent,
    AdvancedFiltersFormLinkComponent,
    AdvancedFiltersFormLocationComponent,
    FilterPanelMultiCampaignIdsComponent,
    FilterPanelWhatsappStatusComponent,
    MessageLogsTableSmsWhatsappComponent,
    MessageLogsTableVoiceComponent,
    MessageLogsTableDetailsVoiceComponent,
    AdvancedFiltersFormCallerIdComponent,
    AdvancedFiltersFormDirectionComponent,
    AdvancedFiltersFormVoiceStatusComponent
  ],
  imports: [
    CommonModule,
    LogAnalyserRoutingModule,
    UniCommonModule,
    SharedModule,
    TranslateModule,
    MatTooltipModule,
    OverlayModule,
    FormsModule
  ],
  providers: [
    StatusMockStore,
    DirectionMockStore
  ]
})
export class LogAnalyserModule { }
