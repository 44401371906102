<ng-container *ngIf="form" [formGroup]="form">
  <uni-label [required]="required">
    {{ 'label.roles' | translate }}
  </uni-label>
  <uni-form-field>
    <mat-radio-group [formControl]="control">
      <div class="uni-roles-radio__items">
        <uni-rich-radio-button
          *ngFor="let role of roles$ | async; let i = index"
          [isSelected]="compare(role)"
          (select)="handleSelect(role)"
        >
          <mat-radio-button [value]="role" [checked]="compare(role)">
            <span class="uni-roles-radio__role">{{ 'app.roles.' + role.name | translate }}</span>
          </mat-radio-button>

          <uni-text-body
            [isUppercase]="false"
            weight="regular"
            [innerHTML]="'app.roles.description.' + role.name | translate"
          ></uni-text-body>
        </uni-rich-radio-button>
      </div>
    </mat-radio-group>
  </uni-form-field>
</ng-container>
