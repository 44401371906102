import { Observable } from 'rxjs';
import { get } from 'lodash';

import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';

import { Environment } from '../../../utils/environment.utils';
import { GlobalSettings, UserMe } from './uni-auth.model';
import { UniFeatureFlagsService } from './uni-feature-flags.service';
import { SettingsService } from '../../../shared/settings/settings.service';
import { UNI_AUTH_KEY } from '../store/uni-auth.state';

export class UniAuthSettings {
  clientId: string;
  clientSecret: string;
  code?: string;
  redirectUri?: string;
  ucUrl?: string;
  grantType?: string;
}

@Injectable({ providedIn: 'root' })
export class UniAuthRepository {
  apiUrl = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/app/api/v2`;

  constructor(
    private http: HttpClient,
    private uniFeatureFlagsService: UniFeatureFlagsService,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getUserMeApiUrl(): string {
    const ucUrl = get(this.settingsService.settings, 'ucUrl');
    return ucUrl
      ? `${ucUrl}/api/v2`
      : `${Environment.getMainHost(
        Environment.APP.CONSOLE
      )}/app/api/v2`;
  }

  getUserMe(isLoader = true): Observable<UserMe> {
    return this.http.get<UserMe>(`${this.getUserMeApiUrl()}/user/me`, {
      withCredentials: true,
      headers: {
        queryName: 'getUserMe',
        queryGroups: [isLoader ? UNI_AUTH_KEY : null]
      }
    });
  }

  getGlobalSettings(): Observable<GlobalSettings> {
    return this.http.get<GlobalSettings>(`${this.apiUrl}/public/global/settings`, {
      headers: {
        queryName: 'getGlobalSettings',
        queryGroups: [UNI_AUTH_KEY]
      }
    });
  }

  getFeatureFlags(data: UserMe): void {
    this.uniFeatureFlagsService.initFeatureFlags(data);
  }

  refreshToken({ clientId, clientSecret, redirectUri, grantType, code }: UniAuthSettings, refreshToken?: string): Observable<any> {
    return this.http.post(`${ Environment.getMainHost(
      Environment.APP.CONSOLE
    ) }/oauth/v2/token`, {
      client_id: clientId || get(this.environment, 'clientId'),
      client_secret: clientSecret || get(this.environment, 'clientSecret'),
      redirect_uri: redirectUri,
      ...(!!grantType ? { grant_type: grantType } : { grant_type: 'refresh_token' }),
      ...(!!refreshToken ? { refresh_token: refreshToken } : {}),
      ...(!!code ? { code } : {}),
    },
    {
      headers: {
        queryName: 'getGlobalSettings',
        queryGroups: [UNI_AUTH_KEY]
      }
    });
  }

  checkCode(apiUrl: string, code: string, redirectUri: string): Observable<any> {
    return this.http.get(`${apiUrl}/login/check-unifonic?code=${code}&redirect_uri=${redirectUri}`, {
      headers: {
        queryName: 'checkCode',
        queryGroups: [UNI_AUTH_KEY]
      }
    });
  }
}
