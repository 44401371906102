<uni-box [isHidden]="false">
  <uni-box-content
    borderRadius="all"
    [isHorizontalSpace]="false"
    [isVerticalSpace]="false"
  >

    <uni-box [isHidden]="false">
      <div #table>
        <uni-table-top-bar *ngIf="isLoaded">
          <uni-table-top-bar-content>
            <uni-table-top-bar-item>
              <uni-plural [amount]="(campaignsTotalItems$ | async) || 0">
                {{ (campaignsTotalItems$ | async) || 0 }}
              </uni-plural>
            </uni-table-top-bar-item>
            <uni-table-clear-filters></uni-table-clear-filters>
          </uni-table-top-bar-content>
        </uni-table-top-bar>

        <uni-table [isLoaded]="isLoaded">

          <table cdk-table [dataSource]="dataSource">
            <ng-container [cdkColumnDef]="columns.campaignName">
              <th cdk-header-cell *cdkHeaderCellDef #thead>
                <uni-table-filter-search
                  [heading]="'table.' + columns.campaignName | translate"
                  [thead]="thead"
                  [tableLeft]="tableLeft"
                  [controlName]="filters.campaignName"
                ></uni-table-filter-search>
              </th>
              <td cdk-cell *cdkCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.contentType">
              <th cdk-header-cell *cdkHeaderCellDef>
                {{ 'table.' + columns.contentType | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element">
                <ng-container *ngIf="element.type !== campaignDataSource.fileToSMS">
                    {{ 'dataSource.custom' | translate }}
                </ng-container>
                <uni-link
                  *ngIf="element.type === campaignDataSource.fileToSMS"
                  (click)="getCampaignFile(element)"
                >
                  {{ element.recipients.campaignFile.file.originalName }}
                </uni-link>
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.createdAt">
              <th cdk-header-cell *cdkHeaderCellDef #thead>
                <uni-table-filter-daterange
                  [heading]="'table.' + columns.createdAt | translate"
                  [thead]="thead"
                  [tableLeft]="tableLeft"
                  [controlName]="filters.createdAt"
                ></uni-table-filter-daterange>
              </th>
              <td cdk-cell *cdkCellDef="let element">
                {{ element.createdAt | dateFormat }}
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.senderName">
              <th cdk-header-cell *cdkHeaderCellDef #thead>
                {{ 'table.' + columns.senderName | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element">
                <ng-container *ngIf="element.type !== campaignDataSource.fileToSMS">
                  {{ element.senderName?.name || '-' }}
                </ng-container>
                <uni-link
                  *ngIf="element.type === campaignDataSource.fileToSMS"
                  (click)="getCampaignFile(element)"
                >
                  {{ element.recipients.campaignFile.file.originalName }}
                </uni-link>
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.recipients">
              <th cdk-header-cell *cdkHeaderCellDef>
                {{ 'table.' + columns.recipientsCount | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element; let rowIndex = index; let rowCount = count">
                <ng-container *ngIf="element.recipients?.phoneNumbers">
                  {{ element.recipientsCount | number }}
                </ng-container>
                <ng-container *ngIf="element.recipients?.audienceGroups.length">
                  <uni-tooltip-cloud
                    [isFixed]="true"
                    [position]="getTooltipPosition(rowIndex, rowCount)"
                  >

                    <uni-link>
                      {{ element.recipientsCount | number }}
                    </uni-link>

                    <div tooltip class="campaign-table__tooltip">
                      <uni-text-caption
                        [isUppercase]="false"
                        [weight]="uniTextWeight.regular"
                      >
                        {{ 'table.groups' | translate }}
                      </uni-text-caption>
                      <uni-group
                        *ngFor="let item of element.recipients?.audienceGroups"
                        class="campaign-table__group"
                        [isHorizontal]="false"
                        [isDynamic]="!!item.filters"
                        [isMarginRight]="true"
                      >
                        {{ item.name }}
                      </uni-group>
                    </div>
                  </uni-tooltip-cloud>
                </ng-container>
                <uni-link
                  *ngIf="isCampaignFile(element)"
                  (click)="getCampaignFile(element)"
                >
                  {{ element.recipients.campaignFile.file.originalName }}
                </uni-link>
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.status">
              <th cdk-header-cell *cdkHeaderCellDef>
                {{ 'table.' + columns.status | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element" [ngClass]="showChangeStatus(element.status) ? 'd-flex' : ''">
                <cc-campaign-table-status [campaign]="element"></cc-campaign-table-status>
                <uni-link
                   *ngIf="showChangeStatus(element.status)"
                   class="ml-1"
                   (click)="openStatusModal(element.id)"
                >
                 - {{ 'label.edit' | translate }}
                </uni-link>
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.success">
              <th cdk-header-cell *cdkHeaderCellDef>
                {{ 'table.' + columns.success | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element">
                {{ getStatus(element) ? '-' : (getSuccessRate(element) | percent: '1.0-2') }}

              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.source">
              <th cdk-header-cell *cdkHeaderCellDef>
                {{ 'table.' + columns.source | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element">
                {{ 'dataSource.' + element.type | translate }}
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.accountName">
              <th cdk-header-cell *cdkHeaderCellDef #thead>
                <uni-table-filter
                  [heading]="'table.' + columns.accountName | translate"
                  [thead]="thead"
                  [tableLeft]="tableLeft"
                  [data]="accounts$ | async"
                  [controlName]="filters.accountId"
                ></uni-table-filter>
              </th>
              <td cdk-cell *cdkCellDef="let element">
                {{ element?.account?.name | translate }}
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.messageType">
              <th cdk-header-cell *cdkHeaderCellDef #thead>
                {{ 'table.' + columns.messageType | translate }}
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="campaign-table__column--last"
              >
                {{ 'messageType.' + (element?.messageType || 'default') | translate }}
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.createdBy">
              <th cdk-header-cell *cdkHeaderCellDef #thead>
                <uni-table-filter
                  [heading]="'table.' + columns.createdBy | translate"
                  [thead]="thead"
                  [tableLeft]="tableLeft"
                  [data]="users$ | async"
                  [controlName]="filters.userId"
                  [alignRight]="true"
                ></uni-table-filter>
              </th>
              <td
                cdk-cell
                *cdkCellDef="let element"
                class="campaign-table__column--last"
              >
                {{ element?.user?.fullName | translate }}
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.messageContent">
              <th cdk-header-cell *cdkHeaderCellDef>
                {{ 'table.' + columns.messageContent | translate }}
              </th>
              <td cdk-cell *cdkCellDef="let element">
                <uni-link
                  type="secondary"
                  (click)="toggleDetails(element)"
                  uniLabelSuffix
                >
                  {{ (isExpanded(element) ? 'action.hideContent' : 'action.showContent') | translate }}
                  <uni-icon-arrow [isOpen]="isExpanded(element)"></uni-icon-arrow>
                </uni-link>
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.actions">
              <th cdk-header-cell *cdkHeaderCellDef class="cdk-header-actions">
                <div class="cdk-header-actions__item"></div>
              </th>
              <td cdk-cell *cdkCellDef="let element" class="cdk-actions">
                <uni-table-action
                  name="eye"
                  [path]="'/campaigns/sms/' + element.id"
                  [params]="getParams()"
              ></uni-table-action>
              </td>
            </ng-container>

            <ng-container [cdkColumnDef]="columns.expandedDetail">
              <td cdk-cell *cdkCellDef="let row" colspan="100%">
                <uni-label>
                  {{ 'table.' + columns.messageContent | translate }}
                </uni-label>
                <div [innerHTML]="row.element.content | multiline"></div>
              </td>
            </ng-container>

            <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
            <tr
              cdk-row
              *cdkRowDef="let row; columns: displayedColumns; let i = index;"
              class="uni-table__extendable"
              [ngClass]="{
                'is-expanded': isExpanded(row),
                'is-odd': isRowOdd(i),
                'is-even': !isRowOdd(i)
              }"
            ></tr>
            <tr
              cdk-row
              *cdkRowDef="let row; columns: ['expandedDetail']; let i = index; when: isExpansionDetailRow;"
              class="uni-table__details"
              [ngClass]="{
                'is-expanded': isExpanded(row.element),
                'is-odd': isDetailsRowOdd(i),
                'is-even': !isDetailsRowOdd(i)
              }"
            ></tr>
          </table>
        </uni-table>
      </div>

      <uni-table-empty *ngIf="isEmpty()"></uni-table-empty>

    </uni-box>
  </uni-box-content>
</uni-box>

<uni-pagination
  *ngIf="campaignsTotalItems$ | async as totalItems"
  [items]="totalItems"
></uni-pagination>

<uni-modal
  [isActive]="isActiveStatusModal"
>
<uni-section-header>
    {{ 'campaign.statusConfirm' | translate }}
</uni-section-header>

  <uni-modal-content
    *ngIf = "statusForm"
    [margin]="false"
  >
    <uni-form-field>
      <uni-label [required]="true">
        {{ 'label.status' | translate }}
      </uni-label>
        <mat-form-field>
          <mat-select
            [formControl]="statusForm?.get('status')"
            [disableOptionCentering]="true"
            [placeholder]="'label.status' | translate"
          >
          <mat-option
             *ngFor="let status of changeCampaignStatus"
             [value]="status"
          >
            {{ 'status.'+ status | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </uni-form-field>
  </uni-modal-content>
<uni-modal-footer>
  <uni-button [style]="'secondary'" (click)="hideStatusModal()">
    {{ 'applications.renew.modal.cancel' | translate }}
  </uni-button>
  <uni-button
     [disabled] = "!statusForm?.valid"
     (click)="changeStatus()"
   >
   {{ 'label.confirmUpdate' | translate }}
  </uni-button>
</uni-modal-footer>

</uni-modal>
