import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'uni-form-eye',
  templateUrl: './uni-form-eye.component.html',
})
export class UniFormEyeComponent {
  type = 'password';
  @Input() disabled = false;

  @HostListener('click') onClick() {
    if (this.type === 'password') {
      this.type = 'text';
      return;
    }

    this.type = 'password';
  }
}
