import { Component, OnInit, OnChanges, SimpleChanges, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { HIGHCHARTS_SERIES_PIE_COLORS, HIGHCHART_COLORS } from '../highcharts/highcharts.colors';
import { HCToken } from '../highcharts/highcharts.token';
import { HighchartsInstance, HighchartsItem, HighchartsOptions } from '../highcharts/highcharts.types';

@Component({
  selector: 'app-chart-circle-multi-stacked',
  templateUrl: './chart-circle-multi-stacked.component.html',
  styleUrls: ['./chart-circle-multi-stacked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartCircleMultiStackedComponent implements OnInit, OnChanges {
  @Input() innerSize: string = '80%';
  @Input() dataMulti: any[] = [];

  constructor( @Inject(HCToken) public hc: HighchartsItem) {}

  cInstance!: HighchartsInstance;

  chartOptions: HighchartsOptions = {
    credits:{
      enabled: false,
    },
    chart:{
      height: 170,
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        size: '100%',
        animation: false,
        enableMouseTracking: false,
        dataLabels: {
            enabled: false,
        }
    }
    },
    colors: HIGHCHARTS_SERIES_PIE_COLORS,
    series: []
  }

  ngOnInit(): void {
    this.chartOptions = { ...this.chartOptions };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataMulti && !changes.dataMulti.firstChange){
      this.redrawChart()
    }
  }

  chartInit(chart: Highcharts.Chart) {
    this.cInstance = chart;
    this.redrawChart()
  }

  redrawChart(){
    if(this.cInstance){
      while(this.cInstance.series.length){
        this.cInstance.series[0].remove()
      }
      this.cInstance.addSeries({
        name: '',
        type: 'pie',
        data: this.dataMulti.map((item, index) => {
          return {
            y: item.value,
            color: item.color
          }
        })
      });
      this.cInstance.redraw(true);
    }
  }
}
