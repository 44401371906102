export namespace IpValidationUtils {
  export const isPrivateIp = (ip: string): boolean => {
    let parts: any = ip.split('.');
    parts = parts.map(str => parseInt(str, 10));

    return parts[0] === 10
      || parts[0] === 127
      || (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31)
      || (parts[0] === 192 && parts[1] === 168);
  };

  export const parseIpv6 = (ip: string) => {
    const array = new Array(8).fill(0);

    if (ip === '::') {
      return array;
    }

    const parts = ip.split(':');
    let item = 0;
    const partsAmount = parts.length > 8 ? 8 : parts.length;

    for (let i = 0; i < partsAmount; i++) {
      if (i && parts[i] === '') {
        item = 9 - partsAmount + i;
        continue;
      }

      array[item] = parseInt(`0x0${parts[i]}`, 16);
      item++;
    }

    return array;
  };

  export const convertIpv6toIpv4 = (ip: string) => {
    const parsedIp = parseIpv6(ip);

    return `${parsedIp[6] >> 8}.${parsedIp[6] & 0xff}.${parsedIp[7] >> 8}.${parsedIp[7] & 0xff}`;
  };
}
