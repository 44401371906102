import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"


type State = {
}

const initialState: State = {
}

@Injectable()
export class BlockedCardsStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    readonly vm$ = this.select(
      () => {
          return {
          }
      }
  )
}
