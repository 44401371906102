import { isEmpty } from 'lodash';
import { AfterContentInit, Component, ContentChildren, forwardRef, HostBinding, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UniCellComponent } from '../uni-cell-item/uni-cell.component';


@Component({
  selector: 'uni-segmented-tabs',
  templateUrl: './uni-segmented-tabs.component.html',
  styleUrls: ['./uni-segmented-tabs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniSegmentedTabsComponent),
      multi: true
    }
  ]
})
export class UniSegmentedTabsComponent implements ControlValueAccessor, AfterContentInit {
  @ContentChildren(UniCellComponent) tabs: QueryList<UniCellComponent>;
  @Input() isManual = false;
  @HostBinding('class.is-multi') @Input() isMulti = false;
  @HostBinding('class.is-disabled') @Input() disabled = false;
  @HostBinding('class.is-fit') @Input() isFit = false;
  @HostBinding('class.is-hug') @Input() isHug = true;

  value = '';
  propagateChange: any = () => {};

  get tabValue() {
    return this.tabValue;
  }

  set tabValue(value: string) {
    this.propagateChange(value);
  }

  ngAfterContentInit() {
    if (this.isManual) {
      return;
    }

    this.setDefaultValue();
    this.tabs.map(tab => tab.changedId.subscribe(value => this.settabstate(value)));
  }

  setDefaultValue() {
    setTimeout(() =>  this.tabs.map(tab => {
      tab.setStatus(tab.value === this.value);
    }), 0);
  }

  settabstate(value: string): void {
    if (this.disabled) {
      return;
    }

    this.writeValue(value);
  }

  writeValue(value = ''): void {
    if (!value && !isEmpty(this.tabs)) {
      this.tabs.map(tab => tab.setStatus(false));
    }

    if (!isEmpty(this.tabs)) {
      this.tabs.map(tab => tab.setStatus(tab.value === value));
    }

    this.tabValue = value;
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
