import { concat, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AccountsRepository } from '../repository/accounts.repository';
import { OperatorsRepository } from '../repository/operators.repository';
import { SenderNamesRepository } from '../repository/sender-names.repository';
import * as QueryActions from '../../../store/query/query.actions';

import * as UniDataActions from './uni-data.actions';
import {
  GET_ACCOUNTS_QUERY,
  GET_SUB_ACCOUNTS_QUERY,
  GET_PROVIDERS_QUERY,
  GET_OPERATORS_QUERY,
  GET_SENDER_NAMES_QUERY,
  GET_All_ACCOUNTS_QUERY
} from './uni-data.state';
import { ProvidersRepository } from '../repository';
import { PackagesRepository } from '../repository/packages.repository';
import { mapUniSenderNameResponse } from '../model';
import { uniSnackbarActions } from '../../../modules/uni-snackbar/store';
import { ValidationUtils } from '../../../utils/validation.utils';

@Injectable()
export class UniDataEffects {
  constructor(
    private actions$: Actions,
    private operatorsRepository: OperatorsRepository,
    private providersRepository: ProvidersRepository,
    private accountsRepository: AccountsRepository,
    private senderNamesRepository: SenderNamesRepository,
    private packagesRepository: PackagesRepository
  ) { }

  @Effect() setOperators$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetOperators>(UniDataActions.SET_OPERATORS),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_OPERATORS_QUERY)),
        this.operatorsRepository
          .getOperators()
          .pipe(
            mergeMap(operators => concat(
              of(new QueryActions.QuerySuccess(GET_OPERATORS_QUERY, operators)),
              of(new UniDataActions.SetOperatorsSuccess(operators)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_OPERATORS_QUERY, error)),
            )),
          ),
      )),
    );

  @Effect() setProviders$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetProviders>(UniDataActions.SET_PROVIDERS),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_PROVIDERS_QUERY)),
        this.providersRepository
          .getProviders()
          .pipe(
            mergeMap(providers => concat(
              of(new QueryActions.QuerySuccess(GET_PROVIDERS_QUERY, providers)),
              of(new UniDataActions.SetProvidersSuccess(providers)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_PROVIDERS_QUERY, error)),
            )),
          ),
      )),
    );

  @Effect() setAccounts$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetAccounts>(UniDataActions.SET_ACCOUNTS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_ACCOUNTS_QUERY)),
        this.accountsRepository
          .getAccounts(params)
          .pipe(
            mergeMap(accounts => concat(
              of(new QueryActions.QuerySuccess(GET_ACCOUNTS_QUERY, accounts)),
              of(new UniDataActions.SetAccountsSuccess(accounts)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_ACCOUNTS_QUERY, error)),
            )),
          ),
      )),
    );

    @Effect() setAllAccounts$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetAllAccounts>(UniDataActions.SET_All_ACCOUNTS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_All_ACCOUNTS_QUERY)),
        this.accountsRepository
          .getAllAccounts(params)
          .pipe(
            mergeMap(accounts => concat(
              of(new QueryActions.QuerySuccess(GET_All_ACCOUNTS_QUERY, accounts)),
              of(new UniDataActions.SetAllAccountsSuccess(accounts)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_All_ACCOUNTS_QUERY, error)),
            )),
          ),
      )),
    );

  @Effect() setSubAccounts$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetSubAccounts>(UniDataActions.SET_SUB_ACCOUNTS),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_SUB_ACCOUNTS_QUERY)),
        this.accountsRepository
          .getSubAccounts()
          .pipe(
            mergeMap(subAccounts => concat(
              of(new QueryActions.QuerySuccess(GET_SUB_ACCOUNTS_QUERY, subAccounts)),
              of(new UniDataActions.SetSubAccountsSuccess(subAccounts)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_SUB_ACCOUNTS_QUERY, error)),
            )),
          ),
      )),
    );

  @Effect() setSenderNames$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetSenderNames>(UniDataActions.SET_SENDER_NAMES),
      mergeMap(({ params, isWithSmsServiceTrials, isAccountWithRestrictedTrialSid }) => concat(
        of(new QueryActions.QueryInProgress(GET_SENDER_NAMES_QUERY)),
        this.senderNamesRepository
          .getSenderNames(params)
          .pipe(
            map(results =>
              results.map(sid => mapUniSenderNameResponse(sid, isWithSmsServiceTrials, isAccountWithRestrictedTrialSid))),
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_SENDER_NAMES_QUERY, data)),
              of(new UniDataActions.SetSenderNamesSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_SENDER_NAMES_QUERY, error)),
            )),
          ),
      )),
    );

  @Effect() setAccountsChoice$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetAccountsChoice>(UniDataActions.SET_ACCOUNTS_CHOICE),
      mergeMap(() =>
        this.accountsRepository
          .getAccountsChoice()
          .pipe(
            mergeMap(res => of(new UniDataActions.SetAccountsChoiceSuccess(res)),
            ),
            catchError(() => of(new UniDataActions.SetAccountsChoiceSuccess({})),
          ),
        )
      ),
    );

  @Effect() setProvidersChoice$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetProvidersChoice>(UniDataActions.SET_PROVIDERS_CHOICE),
      mergeMap(() =>
        this.providersRepository
          .getProvidersChoice()
          .pipe(
            mergeMap(res => of(new UniDataActions.SetProvidersChoiceSuccess(res))),
            catchError(() => of(new UniDataActions.SetProvidersChoiceSuccess({})),
          ),
        )
      ),
    );

  @Effect() setOperatorsChoice$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetOperatorsChoice>(UniDataActions.SET_OPERATORS_CHOICE),
      mergeMap(({ params }) =>
        this.operatorsRepository
          .getOperatorsChoice(params)
          .pipe(
            mergeMap(res => of(new UniDataActions.SetOperatorsChoiceSuccess(res))),
            catchError(() => of(new UniDataActions.SetOperatorsChoiceSuccess({})),
          ),
        )
      ),
    );

  @Effect() setSendersChoice$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetSendersChoice>(UniDataActions.SET_SENDERS_CHOICE),
      mergeMap(({ accountID }) =>
        this.senderNamesRepository
          .getSendersChoice(accountID)
          .pipe(
            mergeMap(res => of(new UniDataActions.SetSendersChoiceSuccess(res))),
            catchError(() => of(new UniDataActions.SetSendersChoiceSuccess({})),
          ),
        )
      ),
    );

  @Effect() setActivePackages$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetActivePackages>(UniDataActions.SET_ACTIVE_PACKAGES),
      mergeMap(() =>
        this.packagesRepository
          .getActivePackages()
          .pipe(
            mergeMap(res => of(new UniDataActions.SetActivePackagesSuccess(res))),
            catchError(() => of(),
          ),
        )
      ),
    );

  @Effect() setPackages$ = this.actions$
    .pipe(
      ofType<UniDataActions.SetPackages>(UniDataActions.SET_PACKAGES),
      mergeMap(() =>
        this.packagesRepository
          .getPackages()
          .pipe(
            mergeMap(res => of(new UniDataActions.SetPackagesSuccess(res))),
            // TODO: (mziaja) this part was not returning any action, remove if no problems occur
            // catchError(() => of(),
        )
      ),
    );

  @Effect() putAccountTroubleshootingMode$ = this.actions$
    .pipe(
      ofType<UniDataActions.PutAccountTroubleshootingMode>(UniDataActions.PUT_ACCOUNT_TROUBLESHOOTING_MODE),
      mergeMap(({ accountId, isEnabled }) =>
        this.accountsRepository
          .putAccountTroubleshootingMode(accountId, { enabled: isEnabled })
          .pipe(
            map(() => new UniDataActions.PutAccountTroubleshootingModeSuccess()),
            catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error))))
          )
      ),
    );

  @Effect() putAccountTroubleshootingModeSuccess$ = this.actions$
    .pipe(
      ofType<UniDataActions.PutAccountTroubleshootingModeSuccess>(UniDataActions.PUT_ACCOUNT_TROUBLESHOOTING_MODE_SUCCESS),
      map(() => new uniSnackbarActions.NewSnackbar('success', 'snackbar.successChanges')),
    );
}
