import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { UniDirectivesModule } from '../../directives/directives.module';
import { UniPipesModule } from '../../pipes/pipes.module';
import { UniBoxModule } from '../uni-box';
import { UniFormModule } from '../uni-form';
import { UniIconModule } from '../uni-icon';
import { UniLayoutModule } from '../uni-layout';
import { UniAudioModule } from '../uni-audio/uni-audio.module';
import { UniTableActionComponent } from './components/uni-table-action/uni-table-action.component';
import {
  UniTableCellContentHeaderComponent
} from './components/uni-table-cell-content-header/uni-table-cell-content-header.component';
import { UniTableCellContentComponent } from './components/uni-table-cell-content/uni-table-cell-content.component';
import { UniTableClearFiltersComponent } from './components/uni-table-clear-filters/uni-table-clear-filters.component';
import { UniTableDetailsItemComponent } from './components/uni-table-details-item/uni-table-details-item.component';
import { UniTableDetailsComponent } from './components/uni-table-details/uni-table-details.component';
import { UniTableEmptyComponent } from './components/uni-table-empty/uni-table-empty.component';
import {
  UniTableFilterDaterangeComponent
} from './components/uni-table-filter-daterange/uni-table-filter-daterange.component';
import { UniTableFilterHeaderComponent } from './components/uni-table-filter-header/uni-table-filter-header.component';
import { UniTableFilterSearchComponent } from './components/uni-table-filter-search/uni-table-filter-search.component';
import {
  UniTableFilterWrapperComponent
} from './components/uni-table-filter-wrapper/uni-table-filter-wrapper.component';
import { UniTableFilterComponent } from './components/uni-table-filter/uni-table-filter.component';
import { UniTableHeaderComponent } from './components/uni-table-header/uni-table-header.component';
import { UniTableProgressComponent } from './components/uni-table-progress/uni-table-progress.component';
import {
  UniTableTopBarCheckboxComponent
} from './components/uni-table-top-bar-checkbox/uni-table-top-bar-checkbox.component';
import {
  UniTableTopBarContentComponent
} from './components/uni-table-top-bar-content/uni-table-top-bar-content.component';
import { UniTableTopBarItemComponent } from './components/uni-table-top-bar-item/uni-table-top-bar-item.component';
import { UniTableTopBarComponent } from './components/uni-table-top-bar/uni-table-top-bar.component';
import { UniTableComponent } from './components/uni-table/uni-table.component';
import { UniTextModule } from '../uni-text';
import { UniTableSearchComponent } from './components/uni-table-search/uni-table-search.component';

const components = [
  UniTableComponent,
  UniTableEmptyComponent,
  UniTableHeaderComponent,
  UniTableActionComponent,
  UniTableCellContentComponent,
  UniTableCellContentHeaderComponent,
  UniTableFilterWrapperComponent,
  UniTableFilterComponent,
  UniTableFilterSearchComponent,
  UniTableFilterHeaderComponent,
  UniTableFilterDaterangeComponent,
  UniTableProgressComponent,
  UniTableTopBarComponent,
  UniTableTopBarContentComponent,
  UniTableTopBarCheckboxComponent,
  UniTableTopBarItemComponent,
  UniTableClearFiltersComponent,
  UniTableDetailsComponent,
  UniTableDetailsItemComponent,
  UniTableSearchComponent,
];

const modules = [
  UniBoxModule,
  UniIconModule,
  UniAudioModule,
  CdkTableModule,
  MatDatepickerModule
];

@NgModule({
  imports: [
    UniLayoutModule,
    UniFormModule,
    UniPipesModule,
    UniTextModule,
    TranslateModule,
    UniDirectivesModule,
    CommonModule,
    ...modules,
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
    ...modules
  ],
})
export class UniTableModule { }
