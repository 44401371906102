import { get } from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { UniFormService } from '../../../uni-form/shared/uni-form.service';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { UniCampaignFormService } from '../../shared/uni-campaign-form.service';
import { CampaignDataSource, CampaignRecipientsMethod } from '../../shared/uni-campaign.model';
import { FeatureFlagKeys, UniAuthFacade, UniFeatureFlagsService } from '../../../uni-auth';
import { UniCampaignsPermissionsService } from '../../shared';

@Component({
  selector: 'cc-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
})
export class CampaignFormComponent extends AutoUnsubscribe implements OnInit {
  form = this.campaignFormFactory.build();
  summaryForm = this.campaignFormFactory.build();
  campaignDataSource = CampaignDataSource;
  canWatchCampaignForm = false;
  canDirectSendCampaign = false;
  showRamadanNote = false;

  get isSummary(): boolean {
    return this.form.get('step').value === 4;
  }

  constructor(
    private uniFormService: UniFormService,
    private campaignFormFactory: UniCampaignFormFactory,
    private campaignFacade: UniCampaignsFacade,
    private campaignFormService: UniCampaignFormService,
    private uniAuthFacade: UniAuthFacade,
    private uniCampaignPermissions: UniCampaignsPermissionsService,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.watchCampaignForm()
    );

    this.subs.add(
      this.checkFlags()
    );

    this.selectFormErrors();
    this.restoreFormData();
    this.campaignFormService.setMenuListener();
  }

  selectFormErrors(): void {
    this.uniFormService.selectFormErrors(this.campaignFacade.campaignFormErrors$, this.form);
  }

  watchCampaignForm(): Subscription {
    return this.form.valueChanges
      .subscribe(() => {
        if (this.canWatchCampaignForm) {
          this.campaignFormService.saveFormData(this.form.getRawValue());
        }
      });
  }

  restoreFormData() {
    const restoredData = this.campaignFormService.loadFormData();
    const contentForm = get(restoredData, 'contentForm');
    const type = get(contentForm, 'type');
    const datesForm = get(restoredData, 'datesForm');
    const isFileRecipients = get(contentForm, 'recipients.campaignFile.id') || get(contentForm, 'method') === CampaignRecipientsMethod.file;
    const isGroupsRecipients = get(contentForm, 'method') === CampaignRecipientsMethod.groups;

    if (!!contentForm) {
      this.campaignFormFactory.addContentForm(this.form);
    }

    if (type === CampaignDataSource.custom) {
      this.campaignFormFactory.addCustomControls(this.form, isFileRecipients, isGroupsRecipients);
    }

    if (type === CampaignDataSource.fileToSMS) {
      this.campaignFormFactory.addFileToSMSControls(this.form);
    }

    if (!!datesForm) {
      this.subs.add(
        this.checkPermissions()
      );
    }

    if (restoredData) {
      this.form.patchValue(restoredData);
      this.form.updateValueAndValidity();
    }

    this.canWatchCampaignForm = true;
  }

  checkPermissions(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags),
        tap(() => this.canDirectSendCampaign = this.uniCampaignPermissions.canDirectSendCampaign())
      )
      .subscribe(() => {
        this.campaignFormFactory.addDatesForm(this.form, this.canDirectSendCampaign);
      });
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags),
      )
      .subscribe(() => {
        this.showRamadanNote = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.ramadan_campaign_timing_note_2023_q_1);
      });
  }
}
