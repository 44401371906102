import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CampaignState } from './uni-campaigns.state';
import { UNI_CAMPAIGN_KEY } from './uni-campaigns.state';

export const selectCampaignFeature = createFeatureSelector<CampaignState>(UNI_CAMPAIGN_KEY);

export const selectAuocompleteCountries = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.autocompleteCountries,
);

export const selectCampaignFormErrors = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignFormErrors,
);

export const selectCampaignsTotalItems = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignsTotalItems,
);

export const selectCampaignsLogTotalItems = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignsLogTotalItems,
);

export const selectCampaign = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaign,
);

export const selectCampaigns = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaigns,
);

export const selectCampaignLog = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignLog,
);

export const selectCampaignTemplate = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignTemplate,
);

export const selectCampaignTemplates = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignTemplates,
);

export const selectCampaignTrialTemplates = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignTrialTemplates,
);

export const selectCampaignFile = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignFile,
);

export const selectCampaignExportId = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignExportId,
);

export const selectCampaignExport = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.campaignExport,
);

export const selectCampaignStatus = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.changeCampaignStatus,
);

export const selectBlockWords = createSelector(
  selectCampaignFeature,
  (state: CampaignState) => state.blockWords,
);
