import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniIconModule } from '../uni-icon';
import { UniLayoutCoreModule } from '../uni-layout/uni-layout-core.module';
import { UniModalContentComponent } from './components/uni-modal-content/uni-modal-content.component';
import { UniModalFooterComponent } from './components/uni-modal-footer/uni-modal-footer.component';
import { UniModalComponent } from './components/uni-modal/uni-modal.component';
import { UniModalConfirmationComponent } from './components/uni-modal-confirmation/uni-modal-confirmation.component';
import { UniTextModule } from '../uni-text';
import { UniButtonModule } from '../uni-button';

const components = [
  UniModalComponent,
  UniModalContentComponent,
  UniModalFooterComponent,
  UniModalConfirmationComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UniLayoutCoreModule,
    UniIconModule,
    UniTextModule,
    UniButtonModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniModalModule { }
