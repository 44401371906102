import { map } from 'lodash';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { UniPaginationFormFactory } from '../../shared/uni-pagination-form.factory';
import { UniPaginationFacade } from '../../shared/uni-pagination.facade';
import { PaginationActions } from '../../shared/uni-pagination.model';

@Component({
  selector: 'uni-pagination',
  templateUrl: './uni-pagination.component.html',
  styleUrls: ['./uni-pagination.component.scss']
})
export class UniPaginationComponent implements OnInit {
  @Input() items: number;
  @Input() itemsPerPage = 10;
  @Input() reportHasNextPage: false;
  @Input() isAltOrder = false;
  @Output() change = new EventEmitter();

  paginationForm = this.paginationFormFactory.buildForm();
  currentPage = 1;
  isDisabled = false;

  get pageControl(): FormControl {
    return this.paginationForm.get('page') as FormControl;
  }

  get pagesAmount(): number {
    return Math.ceil(this.items / Number(this.itemsPerPage));
  }

  get isReportTableHasNext(): boolean{
    return this.reportHasNextPage;
  }

  get isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  get isLastPage(): boolean {
    return this.currentPage === this.pagesAmount;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private paginationFacade: UniPaginationFacade,
    private paginationFormFactory: UniPaginationFormFactory,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(({ page }) => this.currentPage = (+page + (this.isAltOrder ? 1 : 0)) || 1);
    this.onFormPageChanges();
  }

  getPages(): (number | string)[] {
    if (this.pagesAmount <= 5) {
      return map(new Array(this.pagesAmount).fill(0), (_, index) => index + 1);
    }

    if (this.isFirstPage || this.currentPage === 2) {
      return [1, 2, 3, '...', this.pagesAmount];
    }

    if (this.currentPage === 3) {
      return [1, 2, 3, 4, ...this.pagesAmount === 6 ? [5] : ['...'], this.pagesAmount];
    }

    if (this.currentPage === 4) {
      return [1, 2, 3, 4, 5, ...this.pagesAmount === 6 ? [6] : ['...', this.pagesAmount]];
    }

    if (this.isLastPage || this.currentPage === this.pagesAmount - 1) {
      return [1, '...', this.pagesAmount - 2, this.pagesAmount - 1, this.pagesAmount];
    }

    if (this.currentPage === this.pagesAmount - 2) {
      return [
        1,
        ...this.pagesAmount === 6 ? [2] : ['...'],
        this.pagesAmount - 3,
        this.pagesAmount - 2,
        this.pagesAmount - 1,
        this.pagesAmount
      ];
    }

    if (this.currentPage === this.pagesAmount - 3) {
      return [
        ...this.pagesAmount === 6 ? [1] : [1, '...'],
        this.pagesAmount - 4,
        this.pagesAmount - 3,
        this.pagesAmount - 2,
        this.pagesAmount - 1,
        this.pagesAmount
      ];
    }

    return [1, '...', this.currentPage - 1, this.currentPage, this.currentPage + 1, '...', this.pagesAmount];
  }

  getPageActiveClass(page): string {
    if(page==='...') {
      return 'button-dots';
    }
    return +page === this.currentPage ? 'active' : '';
  }

  setPage(action: PaginationActions | string): void {
    if (action === '...'){
      return;
    }
    switch (action) {
      case PaginationActions.prev: {
        if (this.currentPage === 1) {
          return;
        }
        this.currentPage -= 1;
        break;
      }
      case PaginationActions.next: {
        if (this.currentPage === this.pagesAmount && !this.reportHasNextPage) {
          return;
        }
        this.currentPage += 1;
        break;
      }
      default: {
        if (action !== '...') {
          this.currentPage = +action;
        }
      }
    }

    this.change.next(this.currentPage);
    this.paginationFacade.setPage(this.currentPage, this.isAltOrder);
  }

  onFormPageChanges(): void {
    this.pageControl.valueChanges.subscribe(page => {
      if (page < 1 || page > this.pagesAmount) {
        this.pageControl.setErrors({ pageInvalid: true });
      }
    });
  }

  onSubmit(): void {
    this.setPage(this.pageControl.value);
  }
}
