
//INTERNAL TYPES
export type SortingDirection = 'ASC' | 'DESC' | ''




// REST API
export type ApiMessageLogsListParams = {

  limit: number
  offset: number
  startDate: string
  endDate: string
  accountId: string
  senderName?: string
  eventStatus?: string
  messageStatus?: string
  sourceStatus?: string
  customerStatus?: string
  messageType?: string
  recipient?: string
  channels?: string
  products?: string
  isRealTime?: boolean
  lastMinutes?: number
  campaignId: string
  campaignStatus: string
  senderNumber?: string
  customerReason?: string
  orderBy?: {
    field: string
    direction: 'ASC' | 'DESC'
  }
}


export type ApiMessageLogsReportBody = {
  startDate: string
  endDate: string
  accountId: string
  senderName?: string
  eventStatus?: string
  messageStatus?: string
  customerStatus?: string
  sourceStatus?: string
  messageType?: string
  recipient?: string
  products?: string[]
  channels?: string[]
  senderNumber?: string,
  notifyByMail?: boolean
}

export type ApiMessageLogsReportHeader = {
    userTimeZone: string
}


export type MessageFiltersUrlParams = Partial<{
  recipients: string
  limit: string
  offset: string
  startDate: string
  endDate: string
  accountId: string
  senderName: string
  products: string
  channels: string
  messageType: string
  status: string
  page: string
  sortBy: string
  sortDir: SortingDirection
  campaignId: string
  autoLoad: boolean
  senderNumber: string,
  templates: string,
  buttons: string,
  links:string,
  locations: string,
  multiCampaignMode: boolean,
  multiCampaignIds: string[],
  customerReason: string,
  caller: string,
  direction: string
}>


export type CampaignListItemApiResponse = {
  campaignName: String
  campaignId: String
}

type RestApiResponse = {
  success: boolean,
  message: string,
  errorCode: number,
  count: number,
}

export type CampaignListApiResponse = {
  findAllCampaignsByAccountID: RestApiResponse & {
    entries: CampaignListItemApiResponse[]
  }
}


export enum MessageLogsResponseType {
  None = 'NONE',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP',
  Voice = 'VOICE'
}
