import { Injectable, Optional, Inject } from '@angular/core';
import { get } from 'lodash';
import { SettingsService } from './settings';
import { ImpersonationUser, User, UserMe } from '../modules/uni-auth/shared/uni-auth.model';
import moment from 'moment/moment';
import { DateUtils } from '../utils';
import { UniFeatureFlagsService } from '../modules/uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../modules/uni-auth/shared/uni-feature-flags.model';
import { UniAccount } from '../modules/uni-accounts/shared/uni-accounts.model';
import { UniLanguage } from '../modules/uni-language/models/uni-language.model';
import mixpanel from 'mixpanel-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, take } from 'rxjs/operators';
import { MixpanelEventName, MixpanelEvent } from './uni-analytics-events.model';
import { UniPageTitleService } from './uni-page-title.service';
import { UniLanguageService } from '../modules/uni-language/shared/uni-language.service';

export interface GroupData {
  name: string;
  country: string;
  accountType?: string;
  crmId: string;
  chargingId: string;
  provisioningId: string;
  createdAt: string;
  parentAccountId?: string;
  parentAccountName?: string;
  category?: string;
  accountManagerName: string;
  accountManagerEmail: string;
  employees: number;
  legacyAccountId: string;
  plan: string;
  balance: string | number;
}

export interface VisitorData {
  name: string;
  groupId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  created: string;
  timeZone: string;
  defaultCurrency: string;
  role: string;
  profile_language?: string;
  screen_resolution?: string;
  browser?: string;
  user_type?: string;
}

export interface UserData {
  $name: string;
  $email: string;
  user_id: string;
  account_id?: string;
  profile_language: string;
  account_country?: string;
  account_name?: string;
  pd_id?: string;
  ocs_id?: string;
  is_impersonated?: boolean;
  is_whitelabel?: boolean;
  console_user_type: string;
  roles?: string[];
}

@Injectable({ providedIn: 'root' })
export class UniAnalyticsService {
  private isMixpanelInitialized = false;

  constructor(
    private readonly router: Router,
    private settingsService: SettingsService,
    private readonly titleService: Title,
    private uniPageTitleService: UniPageTitleService,
    private uniFeatureFlagService: UniFeatureFlagsService,
    private languageService: UniLanguageService,
    @Optional() @Inject('environment') public environment: string
  ) { }

  init(userMe?: UserMe) {
    if (!this.shouldAnalyticsInit(userMe)) {
      return;
    }
    if (this.uniFeatureFlagService.getPermission(FeatureFlagKeys.mixpanel_analytics_2022_q_2)) {
      const mixpanelToken = get(this.environment, 'mixpanelToken');
      if (!this.isMixpanelInitialized && !!mixpanelToken) {
        mixpanel.init(mixpanelToken, {
          loaded: () => {
            this.isMixpanelInitialized = true;
            this.trackCurrentPageView();
            this.watchRouterChanges();
          }
        });
        /* @ts-ignore */
        window.mixpanel = mixpanel;
      }

      if (this.isMixpanelInitialized && userMe) {
        mixpanel.identify(this.getVisitorID(userMe));
        mixpanel.people.set(this.getUserData(userMe));
      }
    }

    return;
  }

  shouldAnalyticsInit(userMe?: UserMe): boolean {
    const { impersonationSession } = userMe || {};

    return !impersonationSession;
  }

  getUserData(userMe: UserMe): UserData {
    const user = userMe.user;
    const account = get(userMe, 'user.account');

    return {
      $name: `${user.firstName} ${user.lastName}`,
      $email: user.email,
      console_user_type: user.isAdminLevel
          ? 'admin_console_user'
          : 'customer_console_user',
      roles: user.roles,
      user_id: this.getVisitorID(userMe),
      profile_language: user.locale || UniLanguage.en,
      ...(user.isAdminLevel
        ? {}
        : {
            account_id: account.id,
            account_country: account.country,
            account_name: account.name,
            pd_id: account.provisioningId,
            ocs_id: account.chargingId,
            is_whitelabel: account.isWhiteLabel
        }
      )
    };
  }

  getVisitorID(userMe: UserMe): string {
    return get(userMe, 'impersonationSession.rootImpersonator.id')
      || get(userMe, 'user.id');
  }

  getVisitorIDSuffix(): string {
    const environmentName = get(this.environment, 'environmentName') || this.settingsService.getValue('environmentName');

    return environmentName !== 'prod'
      ? `_${environmentName}`
      : '';
  }

  identifyUser(userMe: UserMe) {
    const visitorId = this.getVisitorID(userMe) + this.getVisitorIDSuffix();
    const user = userMe.impersonationSession ? userMe.impersonationSession.rootImpersonator : userMe.user;

    return {
      id: visitorId,
      ...this.serializedIdentity(user)
    };
  }

  group(userMe: UserMe) {
    const user = userMe.impersonationSession ? userMe.impersonationSession.rootImpersonator : userMe.user;
    const visitorId = this.getGroupId(user);

    return {
      id: visitorId,
      ...this.serializedGroup(user.account)
    };
  }

  getGroupId(user: User | ImpersonationUser): string {
    const unifonicAdmin = user.account === null || user.account === undefined;
    const group = unifonicAdmin ? 'Unifonic' : get(user, 'account.id');
    return group + this.getVisitorIDSuffix();
  }

  serializedIdentity(user: User | ImpersonationUser): VisitorData {
    return {
      name: `${user.firstName} ${user.lastName}`,
      groupId: this.getGroupId(user),
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone || '',
      created: moment(user.registrationDate).utc().format(DateUtils.Format.default),
      timeZone: user.timezone || '',
      defaultCurrency: user.defaultCurrency || '',
      role: user.roles ? user.roles.join(', ') : '',
      profile_language: user.locale || UniLanguage.en,
      screen_resolution: `${window.screen.availWidth}x${window.screen.availHeight}`,
      browser: this.detectBrowserName(),
      user_type: user.isAdminLevel ? 'UNIFONIC_ADMIN' : 'CLIENT'
    };
  }

  serializedGroup(account: UniAccount): GroupData | any {
    const unifonicAdmin = account === null || account === undefined;
    return unifonicAdmin
      ? {
        name: 'Unifonic Admin'
      }
      : {
        name: account.name,
        country: account.country,
        accountType: account.type,
        crmId: account.crmId,
        chargingId: account.chargingId,
        provisioningId: account.provisioningId,
        createdAt: moment(account.registrationDate).format(DateUtils.Format.default),
        parentAccountId: account.parentAccount ? account.parentAccount.id : null,
        parentAccountName: account.parentAccount ? account.parentAccount.name : null,
        category: account.category,
        accountManagerName: account.accountManagerName,
        accountManagerEmail: account.accountManagerEmail,
        employees: account.userCount,
        legacyAccountId: account.legacyAccountId,
        plan: account.plan,
        balance: account.balance || 0
      };
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  watchRouterChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.trackCurrentPageView();
      });
  }

  public track(event: MixpanelEvent) {
    this.mixpanelTrack(event.name, {
      event_type: event.type,
      page_title: this.titleService.getTitle(),
      ...event.trigger && ({
        [event.trigger.type]: event.trigger.name,
      }),
      ...{ ...event.props ? event.props : {} },
    });
  }

  private trackCurrentPageView(): void {
    const titleKeyName = this.uniPageTitleService.getRouteDataField('titleKey');
    if (!!titleKeyName) {
      this.languageService.languageReady(UniLanguage.en)
        .pipe(
          filter((isReady) => !!isReady),
          take(1),
        )
        .subscribe(() => {
          const title = this.languageService.getTranslationForLanguage(titleKeyName, UniLanguage.en);
          this.mixpanelTrack(
            MixpanelEventName.view,
            {
              page_title: title,
              page_language: this.languageService.getSelectedLanguage()
            }
          );
        });
    }
  }

  mixpanelTrack(eventName: MixpanelEventName | string, properties?: object, transport?: object) {
    if (!this.isMixpanelInitialized) {
      return;
    }

    mixpanel.track(eventName, properties, transport);
  }

  mixpanelTrackTime(eventName: MixpanelEventName | string){
    if (!this.isMixpanelInitialized) {
      return;
    }

    mixpanel.time_event(eventName);
  }

}
