import { Component, HostBinding, Input } from '@angular/core';
import { UniIconType } from './uni-icon.model';

@Component({
  selector: 'uni-icon',
  templateUrl: './uni-icon.component.html',
  styleUrls: [
    './uni-icon.component.scss',
    './uni-icon-types.component.scss',
    './uni-icon-sizes.component.scss'
  ],
})
export class UniIconComponent {
  @Input() name: string;
  @Input() style = 'far';
  @Input() class = '';
  @Input() isBorder = false;
  @Input() type = UniIconType.simple;
  @Input() size = '20';

  // @TODO: DEPRECATED - remove it in the future
  @Input() isSuccess = false;
  @Input() isAlert = false;
  @Input() isArrowIconSuccess = false;
  @Input() isBig = false;

  @HostBinding('class.is-disabled') @Input() disabled = false;
  @HostBinding('class.is-left-space') @Input() isLeftSpace = false;
  @HostBinding('class.is-right-space') @Input() isRightSpace = false;
  @HostBinding('class.is-action') @Input() isAction = false;
  @HostBinding('class.is-bullet-icon') @Input() isBullet = false;
  @HostBinding('class.is-inherit') @Input() isInherit = false;
  @HostBinding('class.is-header-icon') @Input() isHeaderIcon = false;
  @HostBinding('class.is-body-icon') @Input() isBodyIcon = false;
  @HostBinding('class.is-info') @Input() isInfo = false;

  @HostBinding('class') get getClasses(): string {
    return `${this.class}
      uni-icon--type-${this.getType(this.type)}
      uni-icon--size-${this.getSize(this.size)}
      ${this.isBorder ? 'uni-icon--border' : ''}`;
  }

  getSize(size: string): string {
    if (this.isBig) {
      return '40';
    }

    if (this.isInfo) {
      return '20';
    }

    return size;
  }

  getType(type: UniIconType): string {
    if (this.isSuccess) {
      return UniIconType.green;
    }

    if (this.isAlert) {
      return UniIconType.red;
    }

    if (
      this.isArrowIconSuccess
      || this.isHeaderIcon
    ) {
      return UniIconType.gray;
    }

    if (this.isBodyIcon) {
      return UniIconType.white;
    }

    return type;
  }
}
