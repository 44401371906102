
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { DirectionMockStore } from "src/app/shared/store-mocks/direction.store";
import { AdvancedFiltersFormStore } from "../advanced-filters-form/advanced-filters-form.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";

type State = {}

const initialState: State = {}

@Injectable()
export class AdvancedFiltersFormDirectionStore extends ComponentStore<State>{

    constructor(
        private filtersFormStore: FiltersFormStore,
        private advancedFiltersFormStore: AdvancedFiltersFormStore,
        private directionMockStore: DirectionMockStore,
    ){
        super(initialState)
    }

    //selectors
    readonly directionValue$ = this.select(
        this.filtersFormStore.direction$,
        (direction) => direction
    )

    readonly entities$ = this.select(
      this.directionMockStore.entities$,
      (directions) => directions
  )

}
