import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UniIconModule } from '../uni-icon';
import { UniTooltipComponent } from './components/uni-tooltip/uni-tooltip.component';
import { UniTooltipCloudComponent } from './components/uni-tooltip-cloud/uni-tooltip-cloud.component';

const components = [
  UniTooltipComponent,
  UniTooltipCloudComponent,
];

@NgModule({
    imports: [
        UniIconModule,
        MatTooltipModule,
        CommonModule,
    ],
  declarations: [...components],
  exports: [...components],
})
export class UniTooltipModule { }
