import { Observable } from 'rxjs';

import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, httpEncoder } from '../../../utils';
import { PACKAGES_KEY } from '../store/uni-data.state';
import { ActivePackages, Package } from '../model';

@Injectable({
  providedIn: 'root'
})
export class PackagesRepository {
  apiUrl = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/api/v2`;

  constructor(
    private http: HttpClient,
    @Optional() @Inject('environment') public environment,
  ) { }

  getActivePackages(): Observable<ActivePackages> {
    return this.http.get<ActivePackages>(`${this.apiUrl}/packages/active/units`, {
      withCredentials: true,
      headers: {
        queryName: 'getActivePackages',
        queryGroups: [PACKAGES_KEY]
      }
    });
  }

  getPackages(): Observable<Package[]> {
    return this.http.get<Package[]>(`${this.apiUrl}/packages/active`, {
      params: httpEncoder({
        limit: 9999
      }),
      withCredentials: true,
      headers: {
        queryName: 'getPackages',
        queryGroups: [PACKAGES_KEY]
      }
    });
  }
}
