import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-checkbox',
  templateUrl: './uni-checkbox.component.html',
  styleUrls: ['./uni-checkbox.component.scss']
})
export class UniCheckboxComponent {
  @HostBinding('class.uni-checkbox--secondary') @Input() isSecondary = false;
  @HostBinding('class.uni-checkbox--wide') @Input() isWide = true;
}
