import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavMenuSubitem } from '../../shared/uni-nav.model';

@Component({
  selector: 'uni-nav-new-subitem',
  templateUrl: './uni-nav-subitem.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavSubitemComponent {
  @Input() subitem: NavMenuSubitem;
}
