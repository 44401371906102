import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UniButtonModule } from '../uni-button';
import { UniFormModule } from '../uni-form';
import { UniLayoutModule } from '../uni-layout';
import { UniModalModule } from '../uni-modal/uni-modal.module';
import { UniTextModule } from '../uni-text';
import { Uni2faComponent } from './components/uni-2fa/uni-2fa.component';
import { Uni2faRepository } from './shared';

const components = [
  Uni2faComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    UniModalModule,
    TranslateModule,
    UniTextModule,
    UniLayoutModule,
    UniButtonModule,
    UniFormModule,
  ],
  providers: [
    Uni2faRepository
  ]
})
export class Uni2faModule { }
