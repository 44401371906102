import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Params } from '@angular/router';
import { httpEncoder } from '../../../utils';

import {
  Campaign,
  CampaignFile,
  CampaignVoiceFile,
  CampaignTemplate,
  CampaignTemplatesParams,
  CampaignLog,
  CampaignExportId,
  CampaignDataSource,
  changeStatusCampaignData,
  MessageContent,
  Balance,
  CampaignStats,
} from './uni-campaign.model';
import { UNI_CAMPAIGN_KEY } from '../store/uni-campaigns.state';
import { ApplicationPages } from '../../../shared/products/pages.model';

@Injectable()
export class UniCampaignRepository {
  countryUrl = `${ this.environment.apiUrl }/country_codes`;
  apiUrl = `${ this.environment.apiUrl }/campaigns`;
  logApiUrl = `${ this.environment.apiUrl }/campaign/logs`;
  fileApiUrl = `${ this.environment.apiUrl }/campaigns_files`;
  messageApiUrl = `${ this.environment.apiUrl }/message-body-template`;
  voiceFileApiUrl = `${ this.environment.voiceUrl }/api/recipients-file`;

  constructor(
    private http: HttpClient,
    @Optional() @Inject('environment') public environment,
  ) {}

  getCampaign(id: string): Observable<HttpResponse<Campaign>> {
    return this.http.get<Campaign>(`${this.apiUrl}/${id}`, {
      observe: 'response',
      headers: {
        queryName: 'getCampaign',
        queryGroups: [UNI_CAMPAIGN_KEY],
        'X-UNIFONIC-PAGE': ApplicationPages.CampaignDetails
      }
    });
  }

  getCampaignStats(id: string): Observable<HttpResponse<CampaignStats>> {
    return this.http.get<CampaignStats>(`${this.apiUrl}/${id}/campaign-stats`, {
      observe: 'response',
      headers: {
        queryName: 'getCampaignStats',
        queryGroups: [UNI_CAMPAIGN_KEY],
      }
    });
  }

  getCampaignLog(params: Params): Observable<HttpResponse<CampaignLog[]>> {
    return this.http.get<CampaignLog[]>(this.logApiUrl, {
      params: httpEncoder(params),
      observe: 'response' ,
      headers: {
        queryName: 'getCampaignLog',
        queryGroups: [UNI_CAMPAIGN_KEY],
        'X-UNIFONIC-PAGE': ApplicationPages.CampaignReport
      }
    });
  }

  getCampaigns(params: Params): Observable<HttpResponse<Campaign[]>> {
    return this.http.get<Campaign[]>(this.apiUrl, {
      params: httpEncoder(params),
      observe: 'response' ,
      headers: {
        queryName: 'getCampaigns',
        queryGroups: [UNI_CAMPAIGN_KEY],
        'X-UNIFONIC-PAGE': ApplicationPages.CampaignsList
      }
    });
  }

  getCampaignTemplate(id: string): Observable<HttpResponse<CampaignTemplate>> {
    return this.http.get<CampaignTemplate>(`${this.messageApiUrl}/${id}`, {
      observe: 'response',
      headers: {
        queryName: 'getCampaignTemplate',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getCampaignTemplates(queryParams?: CampaignTemplatesParams): Observable<CampaignTemplate[]> {
    return this.http.get<CampaignTemplate[]>(this.messageApiUrl, {
      params: queryParams as Params,
      headers: {
        queryName: 'getCampaignTemplates',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getCampaignTrialTemplates(): Observable<CampaignTemplate[]> {
    return this.http.get<CampaignTemplate[]>(`${this.messageApiUrl}/trial`, {
      headers: {
        queryName: 'getCampaignTrialTemplates',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  postCampaign(payload: Campaign): Observable<HttpResponse<any>> {
    return this.http.post<any>(this.apiUrl, payload, {
      headers: {
        queryName: 'postCampaign',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  postCampaignTemplate(payload: CampaignTemplate): Observable<CampaignTemplate> {
    return this.http.post<any>(this.messageApiUrl, payload, {
      headers: {
        queryName: 'postCampaignTemplate',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  postCampaignFile(payload: File, type: CampaignDataSource, code?: string): Observable<CampaignFile> {
    const formData = new FormData();
    formData.append('file', payload);
    formData.append('type', type);

    if (!!code) {
      formData.append('prefix', code);
    }

    return this.http.post<CampaignFile>(`${this.fileApiUrl}`, formData, {
      headers: {
        queryName: 'postCampaignFile',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  postVoiceCampaignFile(payload: File, type: CampaignDataSource, code?: string): Observable<CampaignVoiceFile> {
    const formData = new FormData();
    formData.append('recipientsFile', payload);
    formData.append('type', type);
    formData.append('file', payload);

    if (!!code) {
      formData.append('prefix', code);
    }
    return this.http.post<CampaignVoiceFile>(this.voiceFileApiUrl, formData, {
      headers: {
        queryName: 'postVoiceCampaignFile',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  deleteScheduledCampaign(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${id}`, {
      headers: {
        queryName: 'deleteScheduledCampaign',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getCampaignFile(id: string): Observable<Blob> {
    return this.http.get(`${this.fileApiUrl}/${id}`, {
      responseType: 'blob',
      headers: {
        queryName: 'getCampaignFile',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  postCampaignExport(id: string): Observable<CampaignExportId> {
    return this.http.post<any>(`${this.apiUrl}/${id}/export`, {
      observe: 'response',
      headers: {
        queryName: 'postCampaignExport',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getCampaignExport(campaignId: string, exportId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/${campaignId}/export-file/${exportId}`, {
      responseType: 'blob',
      headers: {
        queryName: 'getCampaignExport',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getAutocompleteCountries(): Observable<any> {
    return this.http.get(`${this.countryUrl}`, {
      headers: {
        queryName: 'getAutocompleteCountries',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }


  changeCampaignStatus(payload: changeStatusCampaignData): Observable<changeStatusCampaignData> {
    return this.http.put<changeStatusCampaignData>(`${ this.apiUrl }/${ payload.id }/status`, payload, {
      headers: {
        queryName: 'changeCampaignStatus',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getBlockWords(payload: MessageContent): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.apiUrl}/content`,payload, {
      observe: 'response',
      headers: {
        queryName: 'getBlockWords',
        queryGroups: [UNI_CAMPAIGN_KEY]
      }
    });
  }

  getBalance(id: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${ this.environment.ucUrl }/accounts/${id}/balance`,
      { observe: 'response' },
    );
  }

  getAccountBalance(id: string = ''): Observable<any> {
    return this.http.get<any>(`${this.environment.ucUrl}/app/api/v2/accounts/${id}/balance/currency?currency=SAR`, {
      headers: {
        queryName: 'getCampaignStats',
      }
    });
  }

  convertBalanceToUnits(balanceRes: Balance): number {
    const balanceToUnitsRate = Number(this.environment.balanceToUnitsRate);
    return balanceRes.balance ? Number((balanceRes.balance/balanceToUnitsRate).toFixed()): 0;
  }
}
