import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { values } from 'lodash';
import { UniTableSelection } from '../../../uni-table/shared/uni-table-selection.helper';
import { CampaignRejectionReason } from '../../shared/uni-campaign.model';
import { CampaignRejectionReasonsColumns } from './campaign-rejection-reasons.model';

@Component({
  selector: 'cc-campaign-rejection-reasons',
  templateUrl: './campaign-rejection-reasons.component.html',
  styleUrls: ['./campaign-rejection-reasons.component.scss']
})
export class CampaignRejectionReasonsComponent extends UniTableSelection<CampaignRejectionReason> {
  @ViewChild('table', { static: false }) table: ElementRef;
  @Input() reasons: CampaignRejectionReason[] = [];
  columns = CampaignRejectionReasonsColumns;
  dataSource = new MatTableDataSource<CampaignRejectionReason>(this.reasons.length > 0 ? this.reasons
    : [{
      reason: this.translateService.instant('campaign.noReasons'),
      messages: 0
    }]);
  totalItems = this.reasons.length || 0;

  get displayedColumns(): string[] {
    return values(CampaignRejectionReasonsColumns);
  }

  constructor(
    private translateService: TranslateService,
  ) {
    super();
  }
}
