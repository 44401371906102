import { Component, HostListener, OnInit } from '@angular/core';
import { AccountsFacade, UniAuthFacade, UniLanguageService } from '@unifonic/common';
import { AccountListStore } from './shared/store-mocks/account-list.store';
import { AppStore } from './app.store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers :[
    AccountListStore,
    AppStore
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private uniLanguageService: UniLanguageService,
    private accountsFacade: AccountsFacade,
    private store: AppStore,
    private activateRoute: ActivatedRoute
  ){}

  readonly appVersion = "v2.1.138";


  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler() {
    const childRoute = this.store.getRouterChild(this.activateRoute)
    const translatedTitle = this.store.getTranslatedTitle(childRoute.snapshot);
    this.store.trackOnDestroy(translatedTitle)
  }



  ngOnInit(): void {
    console.log(this.appVersion);
    this.uniLanguageService.initLanguages();
    this.accountsFacade.setAccounts()
    this.store.initUserPilot()
    this.store.trackMixpanel()
    this.store.trackMixpanelStartingPage()

  }

}
