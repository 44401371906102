import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniAudienceState } from './uni-audience.state';

export const selectUniAudienceFeature = createFeatureSelector<UniAudienceState>('uniAudience');

export const selectUniGroup = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.group,
);

export const selectUniGroups = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.groups,
);

export const selectUniGroupsAmount = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.groupsAmount,
);

export const selectUniGetGroupQuery = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.getGroupQuery,
);

export const selectUniGetGroupsQuery = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.getGroupsQuery,
);

export const selectUniDeleteGroupQuery = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.deleteGroupQuery,
);

export const selectUniDynamicGroup = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.dynamicGroup,
);

export const selectUniDynamicGroups = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.dynamicGroups,
);

export const selectUniDynamicGroupsAmount = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.dynamicGroupsAmount,
);

export const selectUniGetDynamicGroupQuery = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.getDynamicGroupQuery,
);

export const selectUniGetDynamicGroupsQuery = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.getDynamicGroupsQuery,
);

export const selectUniDeleteDynamicGroupQuery = createSelector(
  selectUniAudienceFeature,
  (state: UniAudienceState) => state.deleteDynamicGroupQuery,
);
