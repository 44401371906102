import { Params } from '@angular/router';
import { Action } from '@ngrx/store';

import { UniAccount } from '../shared/uni-accounts.model';

export const SET_UNI_ACCOUNTS = '[UniAccounts] SET_UNI_ACCOUNTS';
export const SET_UNI_ACCOUNTS_SUCCESS = '[UniAccounts] SET_UNI_ACCOUNTS_SUCCESS';
export const SET_UNI_ACCOUNTS_ERROR = '[UniAccounts] SET_UNI_ACCOUNTS_ERROR';

export class SetUniAccounts implements Action {
  readonly type = SET_UNI_ACCOUNTS;
  constructor(public params: Params = {}) { }
}

export class SetUniAccountsSuccess implements Action {
  readonly type = SET_UNI_ACCOUNTS_SUCCESS;
  constructor(public response: UniAccount[]) { }
}

export class SetUniAccountsError implements Action {
  readonly type = SET_UNI_ACCOUNTS_ERROR;
  constructor() { }
}

export type All =
  | SetUniAccounts
  | SetUniAccountsSuccess
  | SetUniAccountsError;
