import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { UniAuthFacade } from '../modules/uni-auth/shared/uni-auth.facade';
import { DateUtils } from '../utils';
import FormatStandard = DateUtils.FormatStandard;

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  formatStandards: DateUtils.FormatStandard[] = [
    FormatStandard.longest,
    FormatStandard.long,
    FormatStandard.medium,
    FormatStandard.short,
    FormatStandard.numerical,
  ];
  timezone = this.uniAuthFacade.user.timezone;
  userLocale = this.uniAuthFacade.user.locale;

  constructor(private uniAuthFacade: UniAuthFacade) {
    super('en-GB');
  }

  transform(date: string, format?: DateUtils.Format | DateUtils.FormatStandard): any {
    const value = this.formatStandards.includes(format as DateUtils.FormatStandard)
      ? DateUtils.formatDate(date, this.timezone, format as DateUtils.FormatStandard, this.userLocale)
      : DateUtils.display(date, format as DateUtils.Format, this.timezone);

    return value === 'Invalid date'
      ? '-'
      : value;
  }
}
