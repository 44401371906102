import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ItemStatus,
  UniSenderName,
  VerificationStatus
} from '../../../../shared/uni-data/model/sender-name.model';
import { SenderNameFacade } from '../../../../shared/uni-data/facade/sender-names.facade';
import { AutoUnsubscribe } from '../../../../utils';
import { filter, pairwise, startWith, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UniCampaignFormService } from '../../shared/uni-campaign-form.service';
import { FeatureFlagKeys, UniAuthFacade, UniFeatureFlagsService } from '../../../uni-auth';

@Component({
  selector: 'cc-campaign-sender-name',
  templateUrl: './campaign-sender-name.component.html',
})
export class CampaignSenderNameComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() isActive = true;
  @Input() isRequired = true;
  isLoaderActive = false;

  senderNames$ = this.senderNamesFacade.senderNames$.pipe(
    filter(data => !!data && !!data.length),
  );

  get senderNameControl(): FormControl {
    return this.form.get('senderName') as FormControl;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private senderNamesFacade: SenderNameFacade,
    private uniCampaignFormService: UniCampaignFormService,
    private uniFeatureFlagsService: UniFeatureFlagsService,
    private uniAuthFacade: UniAuthFacade,
  ) {
    super();
  }

  ngOnInit() {
    const { value } = this.senderNameControl;

    if(value && value?.isTrial) {
      this.uniCampaignFormService.selectedSenderName$.next(['', value]);
    }

    this.subs.add(
      this.checkFlags(),
      this.watchSenderNameControl(),
      this.watchSenderName()
    );
  }

  private checkFlags(): Subscription {
    return this.uniFeatureFlagsService
      .getPermission$(FeatureFlagKeys.sms_service_trials_2024_q_1)
      .pipe(take(1))
      .subscribe(isWithSmsServiceTrials => {
        this.senderNamesFacade.setSenderNames(
          {
            itemStatus: ItemStatus.active,
            verificationStatus: VerificationStatus.approved,
            limit: 9999,
          },
          isWithSmsServiceTrials,
          this.uniAuthFacade.userMe.user?.account.smsServiceTrialRestriction,
        );
      });
  }

  private watchSenderName(): Subscription {
    return this.senderNamesFacade.isLoading$.subscribe(isActive => {
      this.isLoaderActive = isActive;
      this.cdr.detectChanges();
    });
  }

  private watchSenderNameControl(): Subscription {
    return this.senderNameControl.valueChanges
    .pipe(
      startWith(this.senderNameControl.value),
      pairwise(),
      filter(([preValue, currentValue]: UniSenderName[]) =>
        (preValue && preValue.isTrial) ||
        (currentValue && currentValue.isTrial)
      )
    )
    .subscribe(([preValue, currentValue]) => {
      const senderName = [preValue || '', currentValue || ''];
      this.uniCampaignFormService.selectedSenderName$.next(senderName);
    });
  }
}
