import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { UniIconModule } from '../uni-icon';
import { UniNavBackComponent } from './components/uni-nav-back/uni-nav-back.component';
import { UniNavCategoryComponent } from './components/uni-nav-category/uni-nav-category.component';
import { UniNavHeaderComponent } from './components/uni-nav-header/uni-nav-header.component';
import { UniNavItemIconComponent } from './components/uni-nav-item-icon/uni-nav-item-icon.component';
import { UniNavItemNameComponent } from './components/uni-nav-item-name/uni-nav-item-name.component';
import { UniNavItemComponent } from './components/uni-nav-item/uni-nav-item.component';
import { UniNavNameComponent } from './components/uni-nav-name/uni-nav-name.component';
import { UniNavComponent } from './components/uni-nav/uni-nav.component';
import { UniNavFacade } from './shared/uni-nav.facade';
import { navReducer } from './store/uni-nav.reducers';
import { UniTextModule } from '../uni-text';

const components = [
  UniNavComponent,
  UniNavCategoryComponent,
  UniNavItemComponent,
  UniNavItemIconComponent,
  UniNavItemNameComponent,
  UniNavNameComponent,
  UniNavBackComponent,
  UniNavHeaderComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    UniIconModule,
    UniTextModule,
    StoreModule.forFeature('nav', navReducer),
  ],
  exports: [
    ...components,
  ],
  providers: [
    UniNavFacade
  ]
})
export class UniNavModule { }
