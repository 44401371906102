<uni-box-content
  [isHorizontalSpace]="false"
  [isVerticalSpace]="false"
>
  <uni-box-content>
    <uni-section-header
      size="medium"
      [isBottomSpace]="false"
    >
      <uni-text-caption>
        {{ 'campaign.details' | translate }}
      </uni-text-caption>
    </uni-section-header>
  </uni-box-content>
  <uni-details>

    <uni-details-item
      *ngIf="campaign.name"
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'campaign.name' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.name }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      *ngIf="campaign.status"
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'label.status' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ 'status.' + getStatus(campaign.status) | translate }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'label.initiated' | translate }}
      </uni-label>
      <cc-campaign-detail>
        <ng-container *ngIf="!!campaign.scheduledAt; else initiatedDate">
          {{ 'campaign.scheduledAt' | translate }} {{ campaign.scheduledAt | dateFormat:dateUtils.Format.meridiemDateTime }}
        </ng-container>
        <ng-template #initiatedDate>
          {{ campaign.createdAt | dateFormat:dateUtils.Format.meridiemDateTime }}
        </ng-template>
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'label.recipientsTotal' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.recipientsCount }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      *ngIf="!!campaign.senderName"
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'label.senderName' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ !!campaign.senderName?.name ? campaign.senderName.name : 'campaign.overview.dynamic' | translate }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      *ngIf="campaign.createdAt"
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'table.createdAt' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.createdAt | dateFormat:dateUtils.Format.meridiemDateTime }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      *ngIf="campaign.user"
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'table.createdBy' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.user.fullName }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'table.messageContent' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ !!campaign.content ? campaign.content : 'campaign.overview.dynamic' | translate }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'campaign.id' | translate }}
      </uni-label>
      <cc-campaign-detail>
        <uni-clipboard [text]="campaign.id"></uni-clipboard>
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'label.messageType' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ 'messageType.' + campaign.messageType | translate }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item
      *ngIf="campaign.recipients?.campaignFile?.file"
      columns="4"
      [isMargin]="true"
    >
      <uni-label>
        {{ 'label.file' | translate }}
      </uni-label>
      <cc-campaign-detail>
        <uni-link
          (click)="getCampaignFile(campaign)"
        >
          {{ campaign.recipients.campaignFile.file.originalName }}
        </uni-link>
      </cc-campaign-detail>
    </uni-details-item>

  </uni-details>
</uni-box-content>
