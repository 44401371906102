import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cc-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.scss']
})
export class CampaignDetailComponent {
  @Input() percent: number;
  @Input() status = 'default';
  @HostBinding('class.campaign-detail--bold') @Input() bold = false;
}
