import { Component, OnInit } from '@angular/core';
import { MessageLogsTableStore } from './message-logs-table.store';

@Component({
  selector: 'app-message-logs-table',
  templateUrl: './message-logs-table.component.html',
  styleUrls: ['./message-logs-table.component.scss'],
  providers: [
    MessageLogsTableStore,
  ]
})
export class MessageLogsTableComponent implements OnInit {

  vm$ = this.store.vm$

  constructor(
    private store: MessageLogsTableStore,
  ) { }

  ngOnInit(): void {
  }

}
