{{ displayText() }}
<div
  class="uni-more-items__more"
  *ngIf="isMoreIcon"
  (click)="toggleText()"
>
  <ng-container *ngIf="isActive">
    <uni-icon class="uni-more-items__icon" name="arrow-alt-to-left"></uni-icon>
  </ng-container>
  <ng-container *ngIf="!isActive">
    (+{{ items.length - 1 }})
  </ng-container>
</div>
