import { Component, Input } from '@angular/core';

import { Margin, Size, Status } from './uni-status.model';

@Component({
  selector: 'uni-status',
  templateUrl: './uni-status.component.html',
  styleUrls: ['./uni-status.component.scss'],
})
export class UniStatusComponent {
  @Input() status: Status = Status.success;
  @Input() margin: Margin = 'right';
  @Input() size: Size = 'regular';
}
