import { Observable } from 'rxjs';
import { get } from 'lodash';

import { HttpClient } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';

import { SettingsService } from '../../../shared/settings';
import { UniAccount } from './uni-accounts.model';

@Injectable({
  providedIn: 'root'
})
export class UniAccountsRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getAccounts(params = {}): Observable<UniAccount[]> {
    const ucUrl = get(this.settingsService.settings, 'ucUrl') || get(this.environment, 'ucUrl');

    return this.http.get<UniAccount[]>(`${ ucUrl }/api/v2/accounts/list`, {
      params,
      withCredentials: true
    });
  }
}
