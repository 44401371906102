import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { Environment } from '../../../utils';
import { UniUser } from './uni-user.model';

@Injectable({ providedIn: 'root'})
export class UniUserRepository {
  usersUrl = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/app/api/v2/users`;

  constructor(private http: HttpClient) {}

  getUsers(params: Params): Observable<HttpResponse<UniUser[]>> {
    return this.http.get<UniUser[]>(`${this.usersUrl}`, {
      params,
      observe: 'response',
      withCredentials: true,
    });
  }
}
