import { values } from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'objectToArray' })
export class ObjectToArrayPipe implements PipeTransform {
  transform(data: object): Array<unknown> {
    return values(data);
  }
}
