<ng-container *ngIf="vm$ | async as vm">
  <uni-section>
    <uni-title-container>
        <uni-title-item>
          {{'ci.common.unishield' | translate}}
        </uni-title-item>
    </uni-title-container>
  </uni-section>

  <div class="tabs">
    <uni-box-tabs class="campaign-tabs__container">
      <uni-box-tab
        [isActive]="false"
        (click)="navigateToApplication()"
      >
        {{ 'ci.common.brand.applications' | translate }}
      </uni-box-tab>
      <uni-box-tab
        [isActive]="true"
        (click)="selectTab(campaignTabs.report)"
        [isDisabled]="true"
      >
        {{ 'ci.common.brand.analytics' | translate }}
      </uni-box-tab>
    </uni-box-tabs>
    

  </div>
  <div class="filter-controls">
    <app-filter-panel-account-id></app-filter-panel-account-id>
    <app-filter-panel-date></app-filter-panel-date>
  </div>
  <app-overview></app-overview>
  <app-geopermissions></app-geopermissions>
  <app-blocked-words></app-blocked-words>
</ng-container>
