import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CampaignTemplate } from '../../shared/uni-campaign.model';

@Component({
  selector: 'cc-campaign-template-name-modal',
  templateUrl: './campaign-template-name-modal.component.html',
})
export class CampaignTemplateNameModalComponent {
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() templateNames: CampaignTemplate[] = [];
  @Input() placeholder: string;
  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter();
  @Output() applyButtonClicked = new EventEmitter();

  changeActive(isActive: boolean, isApply: boolean = false): void  {
    this.isActive = isActive;
    this.isActiveChange.emit(isActive);
    if (isApply) {
      this.applyButtonClicked.emit(isApply);
    }
  }
}
