import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-tree-checkbox',
  templateUrl: './uni-tree-checkbox.component.html',
  styleUrls: ['./uni-tree-checkbox.component.scss']
})
export class UniTreeCheckboxComponent {
  @Input() node;
}
