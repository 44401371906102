import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-filters-item',
  templateUrl: './uni-filters-item.component.html',
  styleUrls: ['./uni-filters-item.component.scss'],
})
export class UniFiltersItemComponent {
  @Input() isFullLength = false;

  @HostBinding('class.uni-filters-item--full-length') get isFullLengthClass() {
    return this.isFullLength;
  }
}
