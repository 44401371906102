<div class="uni-modal">
  <div
    class="uni-modal__container"
    [ngClass]="{ 'uni-modal__container--full': isFullScreen }"
  >
    <div
      *ngIf="isActive"
      class="uni-modal__content"
      [ngStyle]="{ 'max-width': maxWitdth }"
    >
      <uni-icon
        name="times"
        class="uni-modal__close-icon"
        *ngIf="isCloseIcon"
        (click)="hideModal()"
      ></uni-icon>
      <ng-content></ng-content>
    </div>
    <div
      *ngIf="isOverlayAction"
      class="uni-modal__overlay"
      [ngClass]="{ 'uni-modal__overlay--lighten': lighten }"
      (click)="hideModal()"
    ></div>
  </div>
</div>
<div
  *ngIf="!isOverlayAction"
  class="uni-modal__overlay"
  [ngClass]="{ 'uni-modal__overlay--lighten': lighten }"
  (click)="hideModal()"
></div>
