import { Component, OnInit } from '@angular/core';
import { FilterPanelDateStore } from './filter-panel-date.store';
import { DateRangeMockStore } from 'src/app/shared/store-mocks/date-range.store';
import { FiltersStore } from '../../stores/filters.store';

@Component({
  selector: 'app-filter-panel-date',
  templateUrl: './filter-panel-date.component.html',
  styleUrls: ['./filter-panel-date.component.scss'],
  providers: [DateRangeMockStore, FilterPanelDateStore]
})
export class FilterPanelDateComponent implements OnInit {

  constructor(
    private store: FilterPanelDateStore,
    private filters: FiltersStore
  ) { }

  vm$ = this.store.vm$

  ngOnInit(): void {
  }

  selectTime(date: string){
    this.filters.patchState({date: date})
  }

  dateRangeValueChange(){}
}
