import { Observable } from 'rxjs';
import { get } from 'lodash';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Params } from '@angular/router';
import { CallerId, CallerIdPost } from '../../models/caller-id.model';
import { SettingsService } from '../../shared/settings/settings.service';
import { httpEncoder } from '../../utils';
import { CALLER_ID_KEY } from './caller-id.state';

@Injectable()
export class CallerIdRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment
  ) {}

  getApiUrl(): string {
    const apiUrl =
      this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');
    return `${apiUrl}/callers`;
  }

  getCallerIds(params: Params): Observable<HttpResponse<CallerId[]>> {
    return this.http.get<CallerId[]>(this.getApiUrl(), {
      params: httpEncoder(params),
      observe: 'response',
      headers: {
        queryName: 'getCallerIds',
        queryGroups: [CALLER_ID_KEY],
      },
    });
  }

  postCallerId(payload: CallerIdPost): Observable<void> {
    return this.http.post<void>(this.getApiUrl(), payload, {
      headers: {
        queryName: 'postCallerId',
        queryGroups: [CALLER_ID_KEY],
      },
    });
  }

  deleteCallerIds(ids: string[]): Observable<null> {
    return this.http.delete<null>(this.getApiUrl(), {
      params: httpEncoder({ 'id[]': ids }),
      headers: {
        queryName: 'deleteCallerIds',
        queryGroups: [CALLER_ID_KEY],
      },
    });
  }
}
