import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-modal-content',
  templateUrl: './uni-modal-content.component.html',
  styleUrls: ['./uni-modal-content.component.scss'],
})
export class UniModalContentComponent {
  @HostBinding('class.is-margin')
  @Input() margin = true;
 }
