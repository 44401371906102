import { Injectable } from '@angular/core';
import { Permission } from '../../uni-auth/shared/uni-auth.model';
import { UniAuthFacade } from '../../uni-auth/shared/uni-auth.facade';
import { UniFeatureFlagsService } from '../../uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../../uni-auth/shared/uni-feature-flags.model';

@Injectable({ providedIn: 'root' })
export class UniCampaignsPermissionsService {
  public isVoice = false;
  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService
  ) { }

  canAdd(): boolean {
    return this.uniAuthFacade.hasSomePermission('CC.CAMPAIGN', [
      Permission.create,
    ]);
  }

  canSeeCampaign(): boolean {
    return this.uniAuthFacade.hasSomePermission('CC.CAMPAIGN', [
      Permission.seeAll,
      Permission.seeOwn,
      Permission.seeUserOwn,
      Permission.superSee,
    ]);
  }

  canSeeList(): boolean {
    return this.uniAuthFacade.hasSomePermission('CC.CAMPAIGN', [
      Permission.seeAll,
      Permission.seeOwn,
      Permission.seeUserOwn,
      Permission.superSee,
    ]);
  }

  canDeleteScheduled(): boolean {
    return this.uniAuthFacade.hasSomePermission('CC.CAMPAIGN', [
      Permission.deleteAll,
      Permission.deleteOwn,
      Permission.deleteUserOwn
    ]);
  }

  canSeeVoiceList(): boolean {
    return this.uniAuthFacade.hasSomePermission('CAMPAIGNS_VOICE', [
      Permission.seeAll,
      Permission.seeOwn,
      Permission.seeUserOwn,
      Permission.superSee,
    ], 'CC.CAMPAIGN');
  }

  canAddVoice(): boolean {
    return this.uniAuthFacade.hasSomePermission('CAMPAIGNS_VOICE', [
      Permission.create,
    ], 'CC.CAMPAIGN');
  }

  canDirectSendCampaign(): boolean {
    return this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.maker_checker_2021_q_2)
      ? this.uniAuthFacade.hasSomePermission('CC.CAMPAIGN_DIRECT_SENDING', [Permission.SendOwn])
      : true;
  }

  canViewReports(): boolean {
    if (
      this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.ci_new_menu_2022_q_4) &&
      !this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.ci_old_menu_2022_q_4)
    ) {
      return false;
    }

    return true;
  }

}
