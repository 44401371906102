import { Component, HostBinding, Input, Optional, Inject } from '@angular/core';
import { get } from 'lodash';

import { ProductKey, ProductsService, SettingsService } from '../../../../shared';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { Permission } from '../../../uni-auth/shared/uni-auth.model';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';

@Component({
  selector: 'uni-products-content',
  templateUrl: './uni-products-content.component.html',
  styleUrls: ['./uni-products-content.component.scss'],
})
export class UniProductsContentComponent {
  productKey = ProductKey;
  isConversationItem = this.uniAuthFacade.hasPermissionPath('CONV');
  isCampaignItem = this.uniAuthFacade.hasPermissionPath('CC.CAMPAIGN');
  isCommunicationPlatform = this.uniAuthFacade.hasSomePermission('UC.LEGACY_IMPERSONATE', [Permission.seeOwn]);
  isTopUpItem = this.uniAuthFacade.hasPermissionPath('UC.TOP_UP');
  isMarketplaceItem = this.uniAuthFacade.hasPermissionPath('UC.MP');
  isNoticeItem = this.uniAuthFacade.hasPermissionPath('UC.NT');
  isAuthenticateItem = this.uniAuthFacade.hasPermissionPath('UC.AU');

  @HostBinding('class.is-open')
  @Input() isProductNavOpen = false;

  get marketplaceUrl(): string {
    return get(this.settingsService.settings, 'marketplaceUrl') || get(this.environment, 'marketplaceUrl');
  }

  get noticeUrl(): string {
    return get(this.settingsService.settings, 'noticeUrl') || get(this.environment, 'noticeUrl');
  }

  get authenticateUrl(): string {
    return get(this.settingsService.settings, 'authenticateUrl') || get(this.environment, 'authenticateUrl');
  }

  get pushUrl(): string {
    return get(this.settingsService.settings, 'pushUrl') || get(this.environment, 'pushUrl');
  }

  constructor(
    private uniNavFacade: UniNavFacade,
    private productsService: ProductsService,
    private uniAuthFacade: UniAuthFacade,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  openSubNavigation() {
    this.uniNavFacade.setNavState(true);
    // eslint-disable-next-line
    event.stopPropagation();
  }

  navigateToProduct(productKey: ProductKey, path = '') {
    this.productsService.navigate(productKey, path);
    this.uniNavFacade.setProductNavState(false);
  }

  canSeeSlackForSupport(): boolean {
    return this.uniAuthFacade.hasSomePermission('CONV.SLACK_FOR_SUPPORT', [
        Permission.seeAll,
        Permission.seeOwn,
        Permission.superSee,
      ]);
  }
}
