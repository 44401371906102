export enum ValidatioErrorTranslateParams {
  min = 'min',
  max = 'max',
}

export interface FormError {
  message: string;
  propertyPath: string;
}

export interface FormOption {
  id: string;
  name: string;
  link?: string;
  isExternalLink?: boolean;
}

export enum UniFormInfoType {
  normal = 'normal',
  success = 'success',
  tip = 'tip',
  confirmation = 'confirmation',
  error = 'error'
}
