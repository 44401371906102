<uni-box-content [isVerticalSpace]="false">
  <uni-separator [isSpace]="false"></uni-separator>
</uni-box-content>
<uni-box-content>
  <cc-campaign-file [form]="contentForm"></cc-campaign-file>
</uni-box-content>

<uni-box-content [isVerticalSpace]="false">
  <uni-separator [isSpace]="false"></uni-separator>
</uni-box-content>
<uni-box-content>
  <cc-campaign-country-autocomplete [form]="form"></cc-campaign-country-autocomplete>
</uni-box-content>
