import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { UniRole, UniRolesFacade } from '../../shared';
import { isQueryInProgress } from '../../../../store/query';

@Component({
  selector: 'uni-roles-radio',
  templateUrl: './uni-roles-radio.component.html',
  styleUrls: [ './uni-roles-radio.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UniRolesRadioComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() required = false;
  @Input() assignableByCurrentUser = false;

  isLoaderActive = false;
  roles$ = this.uniRolesFacade.$roles.pipe(filter(data => !!data.length));

  private unsubscribe$ = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private uniRolesFacade: UniRolesFacade
  ) {
  }

  ngOnInit() {
    this.setRoles();
    this.setLoadingStatus();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setRoles() {
    const { userId } = this.activatedRoute.snapshot.params;
    this.uniRolesFacade.setRoles(userId || '', this.assignableByCurrentUser);
  }

  setLoadingStatus() {
    this.uniRolesFacade.$rolesQuery
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(query => this.isLoaderActive = isQueryInProgress(query));
  }

  compare(role: UniRole): boolean {
    return role && this.control.value && role.name === this.control.value.name;
  }

  handleSelect(role: UniRole): void {
    this.control.setValue(role);
  }
}
