<div
  class="uni-top-bar-impersonation__stop"
  (click)="onStop()"
>
  <uni-icon
    name="stop-circle"
    [isRightSpace]="true"
    [isAlert]="true"
  ></uni-icon>
  {{ 'impersonation.stop' | translate }}
</div>
