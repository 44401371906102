import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { orderBy } from 'lodash';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, tap } from 'rxjs/operators';

import { UniAuthFacade } from '../../uni-auth/shared';
import { UniNavModelService } from './uni-nav-model.service';
import { UniNavNavigationService } from './uni-nav-navigation.service';
import { NavActiveItems, NavLink, NavMenu, NavMenuItem, NavMenuSubitem } from './uni-nav.model';

@Injectable({ providedIn: 'root' })
export class UniNavService {
    readonly navModel$: Observable<NavMenu>;
    readonly isNavCollapsed$: Observable<boolean>;
    readonly activeMenuItems$: Observable<NavActiveItems>;
    readonly expandedMenuItemId$: Observable<string>;

    private readonly navModelSubject = new ReplaySubject<NavMenu>(1);
    private readonly isNavCollapsedSubject = new ReplaySubject<boolean>(1);
    private readonly activeMenuItemsSubject = new ReplaySubject<NavActiveItems>(1);
    private readonly expandedMenuItemIdSubject = new ReplaySubject<string>(1);

    private isNavCollapsed: boolean;

    constructor(
        private readonly router: Router,
        private readonly uniNavModelService: UniNavModelService,
        private readonly uniNavNavigationService: UniNavNavigationService,
        private readonly uniAuthFacade: UniAuthFacade,
    ) {
        this.navModel$ = this.navModelSubject.asObservable()
            .pipe(
                distinctUntilChanged(),
                shareReplay(1),
            );
        this.isNavCollapsed$ = this.isNavCollapsedSubject.asObservable()
            .pipe(
                tap((isNavCollapsed: boolean) => this.isNavCollapsed = isNavCollapsed),
                startWith(false),
                distinctUntilChanged(),
                shareReplay(1),
            );
        this.activeMenuItems$ = this.activeMenuItemsSubject.asObservable()
            .pipe(
                tap(_ => this.forceExpandNav()),
                distinctUntilChanged(),
                shareReplay(1),
            );
        this.expandedMenuItemId$ = this.expandedMenuItemIdSubject.asObservable()
            .pipe(
                tap(_ => this.forceExpandNav()),
                distinctUntilChanged(),
                shareReplay(1),
            );

        this.watchRouterNavigationEnd();
        this.watchFeatureFlags();
    }

    toggleNav(): void {
        this.setIsNavCollapsed(!this.isNavCollapsed);
    }

    forceExpandNav(): void {
        this.setIsNavCollapsed(false);
    }

    navigate(link: NavLink): void {
        this.uniNavNavigationService.navigate(link);
    }

    setExpandedMenuItemId(itemId: string): void {
        this.expandedMenuItemIdSubject.next(itemId);
    }

    private setActiveMenuItems(activeItems: NavActiveItems): void {
        this.activeMenuItemsSubject.next(activeItems);
    }

    private initNavModel(): void {
        const navModel = this.uniNavModelService.getNavModel();
        this.navModelSubject.next(navModel);
    }

    private setIsNavCollapsed(isNavCollapsed: boolean): void {
        this.isNavCollapsedSubject.next(isNavCollapsed);
    }

    private watchFeatureFlags(): void {
        this.uniAuthFacade.featureFlags$
            .pipe(filter(featureFlags => !!featureFlags))
            .subscribe(() => {
                this.initNavModel();
            });
    }

    private watchRouterNavigationEnd(): void {
        combineLatest([
          this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .pipe(startWith({urlAfterRedirects: '/'})), // To run with app load for first time
            this.navModel$,
        ]).pipe(
            map(([{ urlAfterRedirects }, navModel]) => this.getActiveMenuItemsIds(navModel, urlAfterRedirects)),
            tap((activeItems: NavActiveItems) => this.setActiveMenuItems(activeItems)),
            tap((activeItems: NavActiveItems) => this.setExpandedMenuItemId(activeItems.itemId)),
        ).subscribe();
    }

    private getActiveMenuItemsIds(navModel: NavMenu, urlAfterRedirects: string): NavActiveItems {

        let navActiveItem: NavActiveItems | null = null;
        let forcelyActiveCurrentGroup = false;

        for (const group of navModel.groups) {
            for (const item of group.items) {
                const isMenuItemLinkActive = this.isMenuItemLinkActive(item);
                const itemSubitemWithActiveLink = this.getMenuItemSubitemWithActiveLink(item);

                if (isMenuItemLinkActive || itemSubitemWithActiveLink) {
                    if (itemSubitemWithActiveLink) {
                        navActiveItem = {
                            itemId: item.id,
                            subitemId: itemSubitemWithActiveLink?.id,
                        };
                        forcelyActiveCurrentGroup = true;
                    }
                    if (!forcelyActiveCurrentGroup) {
                        navActiveItem = {
                            itemId: item.id,
                            subitemId: itemSubitemWithActiveLink?.id,
                        };
                    }
                }
            }
        }

        if (!!navActiveItem) {
            return navActiveItem;
        }
        console.log(`Navigation menu item is missing for route: ${urlAfterRedirects}.`);
        return {} as NavActiveItems;
    }

    private isMenuItemLinkActive(item: NavMenuItem): boolean {
        return this.uniNavNavigationService.isRouteActive(item?.link);
    }

    private getMenuItemSubitemWithActiveLink(item: NavMenuItem): NavMenuSubitem | null {
        const subitems = item?.items;
        if (!subitems) {
            return null;
        }

        const subitemsOrderedByRoutePartsNumber = orderBy(
            subitems,
            (subitem: NavMenuSubitem) => this.uniNavNavigationService.getNumberOfRouteParts(subitem.link?.route),
            'desc',
        );

        return subitemsOrderedByRoutePartsNumber
            .find((subitem: NavMenuSubitem) => this.uniNavNavigationService.isRouteActive(subitem.link));
    }
}
