import { get } from 'lodash';
import { Subscription, merge } from 'rxjs';
import { CampaignFile, CampaignVoiceFile, CampaignDataSource } from '../../shared/uni-campaign.model';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { UniInputDirective } from '../../../uni-form/directives/input.directive';
import { UniLayoutFacade } from '../../../uni-layout/shared/uni-layout.facade';
import { UniSnackbarFacade } from '../../../uni-snackbar/shared/uni-snackbar.facade';
import { ValidationUtils } from '../../../../utils/validation.utils';
import { Country } from '../../../uni-countries/shared/uni-countries.model';

import { UniCampaignRepository } from '../../shared/uni-campaigns.repository';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';

@Component({
  selector: 'cc-campaign-recipients-file',
  templateUrl: './campaign-recipients-file.component.html',
  styleUrls: ['./campaign-recipients-file.component.scss'],
})
export class CampaignRecipientsFileComponent extends AutoUnsubscribe implements OnInit {
  @ViewChild('file', { static: false }) file: ElementRef;
  @ViewChild(UniInputDirective, { read: ElementRef, static: false }) input: ElementRef;

  @Input() recipientsForm: FormGroup;
  @Input() contentForm: FormGroup;
  @Input () isAutocompleteActiveControl: FormControl;
  @Input() autocompleteControl: FormControl;
  @Input() fileRecipientsControl: FormControl;
  @Input() fileControl: FormControl;
  @Input() isEditable;
  @Input() isActive;
  @Input() isVoice;
  @Output() emptyFile = new EventEmitter();

  fileName = '';
  isLoading = false;
  countries: Country[];
  hasFileLimitError = false;
  isInvalidEncoding = false;

  get fileIdControl(): FormControl {
    return this.recipientsForm.get('campaignFile.id') as FormControl;
  }

  get recipientsFileIdControl(): FormControl {
    return this.recipientsForm.get('recipientsFileId') as FormControl;
  }

  constructor(
    private campaignsRepository: UniCampaignRepository,
    private campaignFacade: UniCampaignsFacade,
    private uniSnackbarFacade: UniSnackbarFacade,
    private uniLayoutFacade: UniLayoutFacade,
    public campaignFormFactory: UniCampaignFormFactory
  ) {
    super();
  }

  ngOnInit() {
    this.setFileName();
    this.subs.add(
      this.resetFile()
    );
  }

  uploadFromFile(): void {
    this.file.nativeElement.click();
  }

  onFilesAdded(): void {
    const { files } = this.file.nativeElement;
    this.hasFileLimitError = false;
    this.isInvalidEncoding = false;

    if (!files || !files.length) {
      return;
    }

    this.isLoading = true;
    const file = files[0];
    this.campaignFormFactory.fileRecipients(this.recipientsForm);
    this.fileControl.setValue(file);
    this.uniLayoutFacade.setIsLoader(true);

    const autocomplete = this.isAutocompleteActiveControl.value
      ? this.autocompleteControl.value.code
      : null;

    if (this.isVoice) {
      this.campaignsRepository
      .postVoiceCampaignFile(file, CampaignDataSource.custom, autocomplete)
      .subscribe(
        (data) => this.onPostVoiceFileSuccess(data),
        (error) => this.onPostFileError(error),
      );
    } else {
      this.campaignsRepository
      .postCampaignFile(file, CampaignDataSource.custom, autocomplete)
      .subscribe(
        (data) => this.onPostFileSuccess(data),
        (error) => this.onPostFileError(error),
      );
    }
  }

  onPostFileSuccess({ id, recipientsCount }: CampaignFile): void {
    this.hasFileLimitError = false;
    this.isInvalidEncoding = false;
    this.uniLayoutFacade.setIsLoader(false);
    this.fileIdControl.setValue(id);
    this.fileRecipientsControl.setValue(recipientsCount);
    this.setFileName();
    this.isLoading = false;
  }

  onPostFileError(error: any) {
    this.uniLayoutFacade.setIsLoader(false);
    const errorMessage = ValidationUtils.getViolationError(error);
    this.hasFileLimitError = errorMessage.includes('tooManyRowsInXlsXlsx');
    this.isInvalidEncoding = errorMessage.includes('invalidEncoding');
    if(!this.hasFileLimitError && !this.isInvalidEncoding) {
      this.uniSnackbarFacade.show('error', errorMessage, ValidationUtils.getParams(error));
    }
    this.deleteFile();
  }

  onPostVoiceFileSuccess({ id }: CampaignVoiceFile): void {
    this.uniLayoutFacade.setIsLoader(false);
    this.recipientsFileIdControl.setValue(id);
    this.fileRecipientsControl.setValue(1);
    this.fileIdControl.setValue(id);
    this.setFileName();

    const campaignVoiceFileControl = this.recipientsForm.get('campaignVoiceFile');
    const fileIdControl = campaignVoiceFileControl.get('id');

    fileIdControl.clearValidators();
    fileIdControl.updateValueAndValidity();
    this.isLoading = false;
  }

  deleteFile(): void {
    if (this.fileControl) {
      this.fileControl.reset();
      this.fileRecipientsControl.reset();
    }
    this.file.nativeElement.value = '';
    this.fileName = '';
    this.isLoading = false;
    this.recipientsForm.markAllAsTouched();
  }

  setFileName(): void {
    if (!!this.fileControl) {
      this.fileName = get(this.fileControl.value, 'name');
    }
  }

  getFile() {
    this.campaignFacade.setCampaignFile(this.fileIdControl.value, this.fileControl.value.name);
  }

  resetFile(): Subscription {
    const autocomplete$ = this.autocompleteControl.valueChanges;
    const isAutocompleted$ = this.isAutocompleteActiveControl.valueChanges;

    return merge(autocomplete$, isAutocompleted$).subscribe(values => {
      if (!this.recipientsForm && !!this.fileIdControl) {
        this.deleteFile();
      }
    });
  }
}
