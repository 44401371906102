import { FormError } from '../../modules/uni-form/shared/uni-form.model';
import { Query } from '../query/query.model';
import {
  CallerIdSubscription,
  CallerId,
  CallerIdChannel,
  CallerIdChannelCharges,
  CallerIdChannelChargesParams,
  CallerIdChannelConfig,
  CallerIdChannelConfigParams,
} from '../../models/caller-id.model';

export const GET_CALLER_IDS_QUERY = 'getCallerIdsQuery';
export const GET_SECONDARY_CALLER_IDS_QUERY = 'getSecondaryCallerIdsQuery';
export const POST_CALLER_ID_QUERY = 'postCallerIdQuery';
export const GET_CALLER_IDS_SUBSCRIPTIONS_QUERY = 'getCallerIdsSubscriptionsQuery';
export const GET_CALLER_ID_CHANNELS_QUERY = 'getCallerIdChannelsQuery';
export const GET_CALLER_ID_CHANNEL_CONFIG_QUERY = 'getCallerIdChannelConfigQuery';
export const GET_CALLER_ID_CHANNEL_CHARGES_QUERY = 'getCallerIdChannelChargesQuery';
export const POST_CALLER_ID_CHANNEL_QUERY = 'postCallerIdChannelQuery';
export const CALLER_ID_KEY = 'callerId';

export interface CallerIdState {
  callerIds: CallerId[];
  callerIdsAmount?: number;
  secondaryCallerIds?: CallerId[];
  secondaryCallerIdsAmount?: number;
  callerIdsSubscriptions: CallerIdSubscription[];
  callerIdsSubscriptionsAmount?: number;
  getCallerIdsQuery: Query<CallerId[]>;
  getSecondaryCallerIdsQuery: Query<CallerId[]>;
  getCallerIdsSubscriptionsQuery: Query<CallerIdSubscription[]>;
  postCallerIdQuery: Query<any>;
  postErrors?: FormError[];
  deleteErrors?: FormError[];
  hasActiveCallerId?: boolean;
  callerIdChannels: CallerIdChannel[];
  callerIdChannelsAmount?: number;
  callerIdChannelConfig: CallerIdChannelConfig | null;
  callerIdChannelCharges: CallerIdChannelCharges | null;
  getCallerIdChannelsQuery: Query<CallerIdChannel[]>;
  getCallerIdChannelChargesQuery: Query<CallerIdChannelChargesParams>;
  getCallerIdChannelConfigQuery: Query<CallerIdChannelConfigParams>;
  postCallerIdChannelQuery: Query<any>;
}

export const callerIdState: CallerIdState = {
  deleteErrors: [],
  callerIds: [],
  getCallerIdsQuery: {},
  getSecondaryCallerIdsQuery: {},
  callerIdsSubscriptions: [],
  getCallerIdsSubscriptionsQuery: {},
  postCallerIdQuery: {},
  callerIdChannels: [],
  callerIdChannelConfig: null,
  callerIdChannelCharges: null,
  getCallerIdChannelsQuery: {},
  getCallerIdChannelChargesQuery: {},
  getCallerIdChannelConfigQuery: {},
  postCallerIdChannelQuery: {},
};
