import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectProviders, selectProvidersChoice, selectProvidersQuery } from '../store/uni-data.selectors';
import { UniDataState } from '../store/uni-data.state';
import * as UniDataActions from '../store/uni-data.actions';
import { BehaviorSubject } from 'rxjs';
import { isQueryInProgress$ } from '../../../store';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProvidersFacade {
  providers$ = this.store.pipe(select(selectProviders));
  providersQuery$ = this.store.pipe(select(selectProvidersQuery));
  providersChoice$ = this.store.pipe(select(selectProvidersChoice));

  loader$ = new BehaviorSubject(isQueryInProgress$([
    this.providersQuery$
  ]));

  isLoading$ = this.loader$.pipe(mergeMap(isActive => isActive));

  constructor(private store: Store<UniDataState>) {}

  setProviders(): void {
    this.store.dispatch(new UniDataActions.SetProviders());
  }

  setProvidersChoice(): void {
    this.store.dispatch(new UniDataActions.SetProvidersChoice());
  }
}
