import { Query } from '../../../store/query';
import { UniAccount } from '../shared/uni-accounts.model';

export const GET_ACCOUNTS_QUERY = 'getAccountsQuery';

export interface UniAccountsState {
  getAccountsQuery: Query<UniAccount[]>;
  accounts?: UniAccount[];
}

export const uniAccountsInitialState: UniAccountsState = {
  getAccountsQuery: {},
};
