import { Action } from '@ngrx/store';

export const SET_IS_LOADER = '[Layout] SET_IS_LOADER';

export class SetIsLoader implements Action {
  readonly type = SET_IS_LOADER;
  constructor(public state: boolean) {}
}

export type All = SetIsLoader;
