import { Query } from '../../../store';
import { GlobalSettings, UserMe } from '../shared/uni-auth.model';
import { FeatureFlags } from '../shared/uni-feature-flags.model';

export const GET_USER_ME_QUERY = 'getUserMeQuery';
export const GET_GLOBAL_SETTINGS_QUERY = 'getGlobalSettingsQuery';
export const GET_FEATURE_FLAGS_QUERY = 'getFeatureFlagsQuery';

export const UNI_AUTH_KEY = 'uniAuth';

export interface UniAuthState {
  userMe?: UserMe;
  getUserMeQuery: Query<UserMe>;
  globalSettings?: GlobalSettings;
  getGlobalSettingsQuery: Query<GlobalSettings>;
  featureFlags?: FeatureFlags;
  getFeatureFlagsQuery: Query<FeatureFlags>;
}

export const initialState: UniAuthState = {
  getUserMeQuery: {},
  getGlobalSettingsQuery: {},
  getFeatureFlagsQuery: {},
};
