<uni-box-content type="transparent">
  <uni-section-header
    size="medium"
  >
    <uni-text-caption>
      {{ 'campaign.topReasonsTitle' | translate }}
    </uni-text-caption>
  </uni-section-header>

  <uni-box-content
    [isBorder]="true"
    [isHorizontalSpace]="false"
    [isVerticalSpace]="false"
  >
    <uni-table>
      <uni-table-top-bar [isReverse]="true">
        <uni-table-top-bar-content>
          <uni-table-top-bar-item>
            {{ 'campaign.total' | translate:{total: totalItems } }}
          </uni-table-top-bar-item>
        </uni-table-top-bar-content>
        <uni-table-top-bar-content>
          <uni-table-top-bar-item>
            {{ 'campaign.topReasons' | translate }}
          </uni-table-top-bar-item>
        </uni-table-top-bar-content>
      </uni-table-top-bar>
      <table
        cdk-table
        [dataSource]="dataSource"
      >
        <ng-container [cdkColumnDef]="columns.reason">
          <th
            cdk-header-cell
            *cdkHeaderCellDef
          >
            {{ 'table.' + columns.reason | translate }}
          </th>
          <td
            cdk-cell
            *cdkCellDef="let element"
          >
            {{ element.reason }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.messages">
          <th
            cdk-header-cell
            *cdkHeaderCellDef
            class="campaign-rejection-reasons__cell"
          >
            {{ 'table.' + columns.messages | translate }}
          </th>
          <td
            cdk-cell
            *cdkCellDef="let element"
            class="campaign-rejection-reasons__cell"
          >
            {{ element.messages }}
          </td>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row
          *cdkRowDef="let row; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
          [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"></tr>
      </table>
    </uni-table>
  </uni-box-content>
</uni-box-content>
