<ng-container [formGroup]="form">
  <uni-form-field [margin]="margin">
    <input
      #phoneRef
      type="tel"
      [formControl]="control"
      [label]="inputLabel | translate"
      (intlTelInputObject)="initPhone($event)"
      (hasError)="onPhoneError($event)"
      (countryChange)="onCountryChange($event)"
      (change)="onInputChange($event)"
      [ng2TelInputOptions]="{
        'preferredCountries': ['sa'],
        'autoHideDialCode': false,
        'nationalMode': false,
        'formatOnDisplay': false,
        'customPlaceholder': setPlaceholder,
        'utilsScript': phoneValidationLibUrl
      }"
      [required]="required"
      [attr.disabled]="disabled ? '' : null"
      autocomplete="off"
      ng2TelInput
      uniInput
    >
    <ng-content></ng-content>
    <uni-form-field-verified *ngIf="verified" uniSuffix></uni-form-field-verified>
    <uni-tooltip *ngIf="tip" uniLabelTip>
      {{ tip }}
    </uni-tooltip>
    <uni-form-field-error *ngIf="error" uniDescription>
      {{ error }}
    </uni-form-field-error>
  </uni-form-field>
</ng-container>
