import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UniAudienceEffects, uniAudienceReducer } from './store';
import { UniAudienceFacade, UniAudienceRepository } from './shared';

import { UniGroupComponent } from './components/uni-group/uni-group.component';

const components = [
  UniGroupComponent
];

@NgModule({
  imports: [
    StoreModule.forFeature('uniAudience', uniAudienceReducer),
    EffectsModule.forFeature([UniAudienceEffects])
  ],
  providers: [
    UniAudienceFacade,
    UniAudienceRepository
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniAudienceModule { }
