import { Query } from '../../../../store';
import { Industry } from '../../shared/uni-industry.model';

export const GET_INDUSTRIES_QUERY = 'getIndustriesQuery';

export interface IndustryState {
  industries: Industry[];
  getIndustriesQuery: Query<Industry>;
}

export const initialState: IndustryState = {
  industries: [],
  getIndustriesQuery: {},
};
