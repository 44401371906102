<div class="countries-autocomplete" [formGroup]="form">
  <uni-label [required]="required">
    {{ 'label.country' | translate }}
  </uni-label>
  <mat-form-field formGroupName="country">
    <uni-form-field [margin]="margin">
      <input
        type="text"
        [placeholder]="isLoaderActive ? ('label.loading' | translate) : ('label.selectCountry' | translate)"
        formControlName="name"
        [matAutocomplete]="auto"
        #autocompleteTrigger
        matInput
        uniInput
      >
      <div uniSuffix>
        <uni-icon
          *ngIf="countryNameControl.value && countryNameControl.value.length"
          name="times"
          (click)="clearAutocomplete()">
        </uni-icon>
        <uni-icon
          *ngIf="!(countryNameControl.value && countryNameControl.value.length)"
          [name]="isPanelOpen ? 'chevron-up' : 'chevron-down'"
        ></uni-icon>
      </div>
    </uni-form-field>
    <mat-autocomplete
      (opened)="isPanelOpen = true"
      (closed)="onPanelClose()"
      #auto="matAutocomplete">
      <mat-option *ngFor="let country of filteredCountries | async" [value]="country.name">
        {{ country.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
