import { map, mergeMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as UniPaginationActions from './uni-pagination.actions';

@Injectable()
export class UniPaginationEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
   ) { }

  @Effect({ dispatch: false })
   setPage$ = this.actions$
     .pipe(
       ofType<UniPaginationActions.SetPage>(UniPaginationActions.SET_PAGE),
       map(({ params }) => params),
       mergeMap(newParams => this.route.queryParams.pipe(
         map(params => ({ queryParams: { ...params, ...newParams }})),
         tap(queryParams => this.router.navigate([], queryParams)),
         take(1),
       )),
     );
}
