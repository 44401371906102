import { omit } from 'lodash';
import { CampaignTemplate } from '../shared/uni-campaign.model';

import * as CampaignActions from './uni-campaigns.actions';
import { CampaignState, campaignState } from './uni-campaigns.state';

export function UniCampaignsReducer(
  state = campaignState,
  action: CampaignActions.All,
): CampaignState {
  switch (action.type) {
    case CampaignActions.POST_CAMPAIGN_TEMPLATE_ERROR:
    case CampaignActions.POST_CAMPAIGN_ERROR: {
      return {
        ...state,
        campaignFormErrors: action.errors || [],
      };
    }
    case CampaignActions.POST_CAMPAIGN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        campaignTemplates: updateState(state, action.response),
      };
    }
    case CampaignActions.SET_CAMPAIGN_TEMPLATE_SUCCESS: {
      return {
        ...state,
        campaignTemplate: action.template,
      };
    }
    case CampaignActions.SET_CAMPAIGN_TEMPLATES_SUCCESS: {
      return {
        ...state,
        campaignTemplates: action.templates || [],
      };
    }
    case CampaignActions.SET_CAMPAIGN_TRIAL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        campaignTrialTemplates: action.templates || [],
      };
    }
    case CampaignActions.SET_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        campaign: {
          ...omit(action.campaign, ['stats']),
          ...action.stats
        }
      };
    }
    case CampaignActions.SET_CAMPAIGN_LOG_SUCCESS: {
      return {
        ...state,
        campaignLog: action.campaignLog || [],
        campaignsLogTotalItems: Number(action.headers.get('Total-Items')),
      };
    }
    case CampaignActions.SET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaigns: action.campaigns || [],
        campaignsTotalItems: Number(action.headers.get('Total-Items')),
      };
    }
    case CampaignActions.SET_CAMPAIGN_FILE_SUCCESS: {
      return {
        ...state,
        campaignFile: action.response,
      };
    }
    case CampaignActions.POST_CAMPAIGN_EXPORT_SUCCESS: {
      return {
        ...state,
        campaignExportId: action.response,
      };
    }
    case CampaignActions.POST_CAMPAIGN_EXPORT_SUCCESS: {
      return {
        ...state,
        campaignExportId: action.response,
      };
    }
    case CampaignActions.SET_CAMPAIGN_EXPORT_SUCCESS: {
      return {
        ...state,
        campaignExport: action.response,
      };
    }
    case CampaignActions.CLEAR_CAMPAIGN: {
      return {
        ...state,
        campaign: undefined,
      };
    }
    case CampaignActions.SET_AUTOCOMPLETE_COUNTRIES_SUCCESS: {
      return {
        ...state,
        autocompleteCountries: action.response,
      };
    }
    case CampaignActions.POST_CAMPAIGN_STATUS_SUCCESS: {
      return {
        ...state,
        changeCampaignStatus: action.response,
      };
    }

    case CampaignActions.SET_BLOCK_WORDS_SUCCESS: {
      const modifiedResponse = {
        isBlockedContent: !action.response
      };
      return {
        ...state,
        blockWords: modifiedResponse,
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

export function updateState(state: CampaignState, data: CampaignTemplate): CampaignTemplate[] {
  state.campaignTemplates = [...state.campaignTemplates, data];

  return state.campaignTemplates;
}
