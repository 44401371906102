import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-group',
  templateUrl: './uni-group.component.html',
  styleUrls: ['./uni-group.component.scss'],
})
export class UniGroupComponent {
  @Input() class = '';
  @Input() isHorizontal = true;
  @Input() isMarginRight = false;
  @Input() isDynamic = false;


  @HostBinding('class') get getClasses(): string {
    return `
      ${this.class}
      ${this.isHorizontal ? '' : ' uni-group--vertical'} ${this.isMarginRight ? ' uni-group--space-right' : ''}
      ${this.isDynamic ? ' uni-group--dynamic' : ''}`;
  }
}
