import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { get } from 'lodash';

import { Injectable, Optional, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UniAuthService } from './uni-auth.service';
import { SettingsService } from '../../../shared/settings';

import { UniAuthRepository } from './uni-auth.repository';
import { Environment } from '../../../utils';

@Injectable({ providedIn: 'root' })
export class UniAuthTokenGuard implements CanActivate {
  authUrl = Environment.getMainHost(
    Environment.APP.CONSOLE
  );
  clientId = this.settingsService.getValue('clientId') || get(this.environment, 'clientId');
  clientSecret = this.settingsService.getValue('clientSecret') || get(this.environment, 'clientSecret');
  uri = [
    `${Environment.getMainHost(
      Environment.APP.CONSOLE
    )}/oauth/v2/auth?response_type=code`,
    `&client_id=${this.clientId}`,
  ].join('');

  constructor(
    private uniAuthService: UniAuthService,
    private authRepository: UniAuthRepository,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { host, protocol } = window.location;
    this.uri = `${this.uri}&redirect_uri=${protocol}//${host}${state.url}`;
    const token = this.uniAuthService.getToken();

    if (!!token) {
      return of(true);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) {
      window.location.href = this.uri;

      return of(false);
    }


    return this.authRepository
      .refreshToken({
        clientSecret: this.clientSecret,
        clientId: this.clientId,
        redirectUri: `${protocol}//${host}${state.url}`,
        grantType: 'authorization_code',
        code,
      })
      .pipe(
        switchMap(({ access_token , refresh_token }) => {
          this.uniAuthService.saveTokens(access_token, refresh_token);

          return of(true);
        }),
        catchError((error) => {
          this.uniAuthService.logout();

          return of(false);
        }),
      );
  }
}
