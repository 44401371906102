<i class="uni-language-switcher__icon fas fa-language"></i>
<form [formGroup]="languageForm" class="uni-language-switcher">
  <!-- <uni-form-field [margin]="false"> -->
    <mat-form-field>
      <mat-select
        [formControl]="languageForm.get('locale')"
        [disableOptionCentering]="true"
        [placeholder]="'label.chooseLanguage' | translate"
        panelClass="mat-select-panel--no-space"
      >
        <mat-option
          *ngFor="let language of languages"
          [value]="language"
          (click)="setLanguage(language)"
        >
          {{ 'language.' + language | translate }}
        </mat-option>

        <ng-container *ngIf="!languages.length">
          <mat-option
            *ngFor="let option of languageOptions"
            [value]="option.value"
            (click)="setLanguage(option.value)"
          >
            {{ option.label }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  <!-- </uni-form-field> -->
</form>
