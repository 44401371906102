import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavItemToggleType } from '../../shared';

@Component({
  selector: 'uni-nav-new-item-content',
  templateUrl: './uni-nav-item-content.component.html',
  styleUrls: ['./uni-nav-item-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavItemContentComponent {
  @Input() iconName: string;
  @Input() labelKey: string;
  @Input() toggleType: NavItemToggleType = NavItemToggleType.none;
  @Input() isSubitem = false;
  @Input() isExpanded = false;
  @Input() isNavCollapsed = false;
  @Input() isRouteActive = false;
  @Input() isExternalLink = false;
}
