export enum ProductKey {
  UC = 'UC',
  CC = 'CC',
  CONV = 'conv',
  NOTICE = 'notice',
  AUTHENTICATE = 'authenticate',
  FLOW_STUDIO = 'FLOW_STUDIO',
  PUSH = 'push',
  ULINK = 'ulink',
}
