import { Subject } from 'rxjs';

import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'uni-switcher-item',
  templateUrl: './uni-switcher-item.component.html',
  styleUrls: ['./uni-switcher-item.component.scss'],
})
export class UniSwitcherItemComponent {
  changedId = new Subject<string>();

  @Input() value = '';

  @HostBinding('class.is-active')
  @Input() isActive = false;

  @HostBinding('class.is-disabled')
  @Input() isDisabled = false;

  @HostListener('click') onClick() {
    this.changedId.next(this.value);
  }

  setStatus(status: boolean) {
    this.isActive = status;
  }

  setDisabled(state: boolean) {
    this.isDisabled = state;
  }
}
