import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { UniSnackbarStatus } from '../components/uni-snackbar/uni-snackbar.model';
import { selectSnackbars, UniSnackbarState } from '../store';
import * as uniSnackbarActions from '../store/uni-snackbar.actions';

@Injectable({ providedIn: 'root' })
export class UniSnackbarFacade {
  snackbars$ = this.store.pipe(select(selectSnackbars));

  constructor(private store: Store<UniSnackbarState>) {}

  show(status: UniSnackbarStatus, message: string, property?: object): void {
    this.store.dispatch(new uniSnackbarActions.NewSnackbar(status, message, property));
  }

  hide() {
    this.store.dispatch(new uniSnackbarActions.HideSnackbar());
  }
}
