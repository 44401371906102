<div
  class="uni-top-bar-products"
  (click)="onClick(isProductNavOpen)"
>
  <uni-icon
    class="uni-top-bar-products__icon"
    [name]="isProductNavOpen ? 'chevron-left' : 'bars'"
    [style]="'far'"
  ></uni-icon>
</div>
