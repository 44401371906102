<ng-template #content>
  <uni-icon
    *ngIf="icon"
    [name]="getFixedName(icon)"
    [size]="getIconSize(size)"
    [isInherit]="true"
  ></uni-icon>
  <span class="uni-link__content">
    <ng-content></ng-content>
  </span>
  <uni-icon
    *ngIf="iconRight"
    [name]="iconRight"
    [size]="getIconSize(size)"
    [isInherit]="true"
  ></uni-icon>
</ng-template>
<ng-container *ngIf="!path">
  <div class="uni-link__path">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>
<a
  class="uni-link__path"
  *ngIf="path"
  [href]="path"
  [target]="target"
  [tabindex]="tabindex"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
