import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortByOptions, SortOptions } from './data-sort.models';
import { DataSortStore } from './data-sort.store';

@Component({
  selector: 'app-data-sort',
  templateUrl: './data-sort.component.html',
  styleUrls: ['./data-sort.component.scss'],
  providers: [DataSortStore],
})
export class DataSortComponent implements OnInit {

  @Input() set dir(dir: SortOptions){ 
    this.store.patchState({dir})
  }

  @Input() set isActive(value : boolean){
    this.store.patchState({isActive: value})
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter()


  vm$ = this.store.vm$

  constructor(private store: DataSortStore) {}



  ngOnInit(): void {
  }

  setValue(sortDir: SortOptions){
    this.valueChange.emit(sortDir)
  }

}
