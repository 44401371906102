<uni-icon
  [name]="getIcon()"
  [style]="'fal'"
></uni-icon>
<ng-container [ngSwitch]="type">
  <div
    *ngSwitchCase="uniPreviewFileType.image"
    [ngStyle]="{'background-image': 'url(' + url + ')'}"
    class="uni-preview-file__image"
  ></div>
  <ng-container *ngSwitchCase="uniPreviewFileType.video">
    <video
      [src]="url"
      class="uni-preview-file__video"
      #video
    ></video>

    <div
      *ngIf="video.readyState >= 3"
      class="uni-preview-file__play-button"
    ></div>
  </ng-container>
  <div
    *ngSwitchCase="uniPreviewFileType.document"
    class="uni-preview-file__image"
  ></div>
</ng-container>
