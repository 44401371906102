<ng-container [formGroup]="form">
  <uni-form-field [margin]="false">
    <uni-label [required]="isRequired">
      {{ 'label.name' | translate }}
      <uni-tooltip
        uniLabelTip
        *ngIf="isActive"
      >
        {{ 'campaign.new.name' | translate }}
      </uni-tooltip>
    </uni-label>
    <input
      type="text"
      formControlName="name"
      [placeholder]="'campaign.namePlaceholder' | translate"
      [required]="true"
      uniInput
    >
  <uni-text-info *ngIf="isCheckingCampaignName">
    {{ 'validation.campaignNameChecker' | translate }}
  </uni-text-info>
  </uni-form-field>
</ng-container>
