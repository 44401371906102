import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-box-items',
  templateUrl: './uni-box-items.component.html',
  styleUrls: ['./uni-box-items.component.scss'],
})
export class UniBoxItemsComponent {
  @Input() margin = 'default';

  @HostBinding('class') get getClasses(): string {
    return `uni-box--margin-${this.margin}`;
  }

}
