import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UniNewNavEnabledService } from '../../shared';
import { UniNavService } from '../../shared/uni-nav.service';

@Component({
  selector: 'uni-nav-new',
  templateUrl: './uni-nav.component.html',
  styleUrls: ['./uni-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavComponent {
  navModel$ = this.uniNavService.navModel$;
  isNavCollapsed$ = this.uniNavService.isNavCollapsed$;
  newMenuEnabled$ = this.uniNewNavEnabledService.isNewMenuEnabled$;
  isMenuOpen = false;
  constructor(
    private readonly uniNavService: UniNavService,
    private readonly uniNewNavEnabledService: UniNewNavEnabledService,
  ) { }

  toggleHamburgerMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
