<tree-root
    #tree
    [nodes]="nodes"
    [options]="options"
    (stateChange)="onChange()"
  >
  <ng-template #treeNodeWrapperTemplate let-node let-index="index">
    <div class="node-wrapper">
      <uni-tree-checkbox [node]="node"></uni-tree-checkbox>
      <tree-node-expander [node]="node"></tree-node-expander>
      <div class="node-content-wrapper">
        <tree-node-content [node]="node" [index]="index"></tree-node-content>
      </div>
    </div>
  </ng-template>
</tree-root>
