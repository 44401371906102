import { flatten, get, includes, map, values, xor } from 'lodash';

import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

export interface ExpandedRow<T> {
  daraRow: boolean;
  element: T;
  isLoading?: boolean;
  isSticky?: boolean;
  details?: any;
  id?: string;
}

export abstract class UniTableSelection<T> {
  columns: Record<string, any>;
  dataSource: MatTableDataSource<T>;
  selection = new SelectionModel<T>(true, []);
  expandedElements: string[];
  expandedKey = 'id';

  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  getDisplayedColumns(isSelction: boolean): string[] {
    return [
      ...(isSelction ? ['select'] : []),
      ...values(this.columns)
    ];
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (!get(this.dataSource, 'data')) {
      return;
    }

    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isEmpty(): boolean {
    const data = get(this.dataSource, 'data');
    return !data ? false : !data.length;
  }

  getMax(): number {
    const data = get(this.dataSource, 'data');
    return !data ? 0 : data.length;
  }

  getSelected(): number {
    return this.selection.selected.length;
  }

  getRows(data: any[]): T[] {
    return flatten(map(data, element => [element, { detailRow: true, element }]));
  }

  isRowOdd(index: number): boolean {
    return !(index / 2 % 2);
  }

  isDetailsRowOdd(index: number): boolean {
    return !((index - 1) / 2 % 2);
  }

  toggleDetails(data: T): void {
    this.expandedElements = xor(this.expandedElements, [get(data, this.expandedKey)]);
  }

  clearDetails(): void {
    this.expandedElements = [];
  }

  openRow(data: T): void {
    this.expandedElements = [...this.expandedElements, get(data, this.expandedKey)];
  }

  openDetails(data: T): void {
    this.expandedElements = [get(data, this.expandedKey)];
  }

  isExpanded(data: T): boolean {
    return includes(this.expandedElements, get(data, this.expandedKey));
  }

  getExpandedIcon(element: T): string {
    if (get(element, 'isLoading')) {
      return 'spinner fa-spin';
    }

    return this.isExpanded(element) ? 'chevron-up' : 'chevron-down';
  }

  setDetailsDataSource(element: T | ExpandedRow<T>, extension: Partial<ExpandedRow<T>>) {
    this.dataSource = new MatTableDataSource<T>(this.dataSource.data.map(item => {
      const isRow = get(element, this.expandedKey) === get(item, this.expandedKey);
      const isExpenededRow = get(element, this.expandedKey) === get(item, `element.${this.expandedKey}`);

      if (isRow || isExpenededRow) {
        return { ...item, ...extension };
      }

      return item;
    }));
  }

  hasDetails(element: T | ExpandedRow<T>): boolean {
    return !!get(element, 'details');
  }
}
