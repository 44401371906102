import { Component, Input } from '@angular/core';

import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniTopBarProfile } from '../../uni-top-bar.model';

@Component({
  selector: 'uni-top-bar-avatar',
  templateUrl: './uni-top-bar-avatar.component.html',
  styleUrls: ['./uni-top-bar-avatar.component.scss']
})
export class UniTopBarAvatarComponent {
  @Input() profile?: UniTopBarProfile;
  user = this.uniAuthFacade?.user;
  impersonatee = this.uniAuthFacade.getImpersonatee();

  constructor(private uniAuthFacade: UniAuthFacade) {}

  get firstName(): string {
    return this.uniAuthFacade.isImpresonationSession()
      ? this.impersonatee?.firstName
      : this.profile?.firstName || this.user?.firstName;
  }

  get lastName(): string {
    return this.uniAuthFacade.isImpresonationSession()
      ? this.impersonatee?.lastName
      : this.profile?.lastName || this.user?.lastName;
  }

  get imageUrl(): string {
    return this.profile?.imageUrl || '/assets/images/avatar.png';
  }
}
