import { Account, SubAccount, Accounts } from '../model/accounts.model';
import { Operator, Operators } from '../model/operator.model';
import { Senders } from '../model/sender-name.model';
import { UniSenderName } from '../model/sender-name.model';
import { Query } from '../../../store/query/query.model';
import { Package, Provider, Providers } from '../model';

export const UNI_DATA_STATE = 'uniData';

export const GET_OPERATORS_QUERY = 'getOperatorsQuery';
export const GET_ACCOUNTS_QUERY = 'getAccountsQuery';
export const GET_All_ACCOUNTS_QUERY = 'getAllAccountsQuery';
export const GET_SUB_ACCOUNTS_QUERY = 'getSubAccountsQuery';
export const GET_SENDER_NAMES_QUERY = 'getSenderNamesQuery';
export const GET_PROVIDERS_QUERY = 'getProvidersQuery';

export const PUT_TROUBLESHOOTING_MODE = 'putAccountTroubleshootingMode';

export const PROVIDERS_KEY = 'providers';
export const ACCOUNTS_KEY = 'accounts';
export const OPERATORS_KEY = 'operators';
export const SENDERS_KEY = 'senders';
export const PACKAGES_KEY = 'uniPackages';

export interface UniDataState {
  getOperatorsQuery: Query<Operator>;
  operators?: Operator[];
  getProvidersQuery: Query<Provider>;
  providers?: Provider[];
  getAccountsQuery: Query<Account>;
  getAllAccountsQuery: Query<Account>;
  accounts?: Account[];
  getSubAccountsQuery: Query<SubAccount>;
  subAccounts?: SubAccount[];
  getSenderNamesQuery: Query<UniSenderName>;
  senderNames?: UniSenderName[];
  operatorsChoice: Operators;
  providersChoice: Providers;
  accountsChoice: Accounts;
  sendersChoice: Senders;
  packages?: Package[];
  activePackages: any;
}

export const initialState: UniDataState = {
  getOperatorsQuery: {},
  getAccountsQuery: {},
  getAllAccountsQuery: {},
  getSubAccountsQuery: {},
  getSenderNamesQuery: {},
  getProvidersQuery: {},
  operatorsChoice: {},
  providersChoice: {},
  accountsChoice: {},
  sendersChoice: {},
  activePackages: {},
};
