<ng-container [formGroup]="form">
  <uni-label
    *ngIf="label"
    [required]="required"
  >
    {{ label | translate }}

    <uni-tooltip
      uniLabelTip *ngIf="tip"
    >
      {{ tip | translate }}
    </uni-tooltip>
  </uni-label>

  <div class="uni-colorpicker mat-form-field">
    <span
      class="uni-colorpicker__palette"
      [ngStyle]="{'backgroundColor': colorpicker.value }"
      uniPrefix
    >
    </span>
    <input class="uni-colorpicker__value"
      [value]="colorpicker.value"
      (input)="colorChanged()"
      type="text"
      #valueholder
      maxlength="7"
    >
    <input
      type="color"
      [formControlName]="fieldName"
      class="uni-colorpicker__control"
      [value]="value"
      #colorpicker
      uniInput
    >
    <uni-icon
      class="uni-colorpicker__icon"
      [style]="'far'"
      name="eye-dropper"
    ></uni-icon>
  </div>
</ng-container>
