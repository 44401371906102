import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UniFooterComponent } from './components/uni-footer/uni-footer.component';
import { UniLanguageModule } from '../uni-language/uni-language.module';
import { UniTextModule } from '../uni-text/uni-text.module';
import { UniSeparatorModule } from '../uni-separator';

const components = [
  UniFooterComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    UniLanguageModule,
    UniTextModule,
    UniSeparatorModule
  ],
  exports: [...components],
})
export class UniFooterModule { }
