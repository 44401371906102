import { Component, Input } from '@angular/core';

import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniNavSettingsService } from '../../../uni-nav-new/shared/uni-nav-settings.service';
import { UniNewNavEnabledService } from '../../../../modules/uni-nav-new/shared/uni-new-nav-enabled.service';

@Component({
  selector: 'uni-top-bar-logo',
  templateUrl: './uni-top-bar-logo.component.html',
  styleUrls: ['./uni-top-bar-logo.component.scss']
})
export class UniTopBarLogoComponent {
  @Input() logoUrl = '/assets/images/unifonic_logo_white-new.png';
  ucUrl = this.uniNavSettingsService.getUcUrl();
  newMenuEnabled$ = this.uniNewNavEnabledService.isNewMenuEnabled$;

  get menuLogo(): string {
    if (this.uniAuthFacade.user.isAdminLevel) {
      return '/assets/images/unifonic-new.png';
    }

    return this.logoUrl || this.uniAuthFacade?.systemSettings?.menuLogo;
  }

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniNavSettingsService: UniNavSettingsService,
    private readonly uniNewNavEnabledService: UniNewNavEnabledService,
  ) {
  }
}
