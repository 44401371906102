import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniPluralComponent } from './components/uni-plural/uni-plural.component';
import { UniPluralTextComponent } from './components/uni-plural-text/uni-plural-text.component';

const components = [
  UniPluralComponent,
  UniPluralTextComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniPluralModule { }
