<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-label>
    {{ 'campaign.cancel.title' | translate }}
  </uni-label>
  <uni-modal-content>
    {{ 'campaign.cancel.body' | translate }}
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button
      [style]="'secondary'"
      (click)="hideModal()"
    >
      {{ 'action.cancel' | translate }}
    </uni-button>
    <uni-button (click)="onConfirm()">
      {{ 'action.confirm' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
