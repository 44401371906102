<uni-form-field-info [type]="uniFormInfoType.tip">
  <div>
    <uni-text-body
      [isUppercase]="false"
      [color]="uniTextColor.black"
    >
     {{ 'campaign.pleaseNote'  | translate }}
    </uni-text-body>
  </div>
  <div>
    <uni-text-body
      [isUppercase]="false"
      [weight]="uniTextWeight.regular"
      [color]="uniTextColor.black"
      [innerHTML]="'campaign.csvLimitInfo' | translate"
    >
    </uni-text-body>
  </div>
</uni-form-field-info>