import { forEach, isArray } from 'lodash';
import { HttpParams } from '@angular/common/http';
import { DateUtils } from './date.utils';

const PROTOCOL_REGEX = /.*:\/\//;
const CREDENTIALS_REGEX = /.*:.*@/;
const PORT_PATH_REGEX = /:.*|\/.*/;

export const httpEncoder = (queryParams: any): HttpParams => {
  let params = new HttpParams({ encoder: new DateUtils.CustomEncoder() });
  forEach(queryParams, (value, key) => {
    if (isArray(value)) {
      value.forEach(id => params = params.append(key, id));
      return;
    }
    params = params.append(key, String(value));
  });

  return params;
};

export const httpEncoderCommaSeparatedValues = (queryParams: any): HttpParams => {
  let params = new HttpParams();
  forEach(queryParams, (value, key) => {
    params = params.append(key, isArray(value) ? String(value.join(',')) : String(value));
  });

  return params;
};

export const resolveHostname = (validUrl: string): string => {
  return validUrl
    .toLowerCase()
    .replace(PROTOCOL_REGEX, '')
    .replace(CREDENTIALS_REGEX, '')
    .replace(PORT_PATH_REGEX, '');
};
