import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-nav-item-icon',
  templateUrl: './uni-nav-item-icon.component.html',
  styleUrls: ['./uni-nav-item-icon.component.scss']
})
export class UniNavItemIconComponent {
  @HostBinding('class.is-on-right')
  @Input() isOnRight = false;

  @HostBinding('class.is-only-on-mobile')
  @Input() isOnlyOnMobile = false;
}
