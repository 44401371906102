import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { UniHttpClient } from '../../../shared';
import { QueryList } from '../../../store';
import { Timezone } from './uni-timezones.model';

@Injectable()
export class UniTimezonesRepository {
  timezonesUrl = `app/api/v2/timezones`;

  constructor(private uniHttp: UniHttpClient) {}

  getTimezones(): Observable<QueryList<Timezone>> {
    return this.uniHttp.get<QueryList<Timezone>>(this.timezonesUrl);
  }
}
