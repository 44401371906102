import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { HttpStatus } from '../../../../models';
import { ValidationUtils } from '../../../../utils';
import { UniSnackbarFacade } from '../../../uni-snackbar';
import { OtpType, TwoFactorTokenResponse, Uni2faRepository } from '../../shared';

@Component({
  selector: 'uni-2fa',
  templateUrl: './uni-2fa.component.html',
  styleUrls: ['./uni-2fa.component.scss']
})
export class Uni2faComponent implements OnInit, OnDestroy {
  @Input() isActive = false;
  @Input() isLoading = false;
  @Input() isAutoRequest = true;
  @Input() codeType = '';
  @Input() additionalOptions = {};
  @Input() generateTokenOptions = {};
  @Input() channel: string;
  @Input() description = '2fa.description';
  @Input() title = '2fa.title';
  @Input() button = '2fa.confirm';
  @Input() maxwidth = '420px';
  @Input() hideCancel = 'false';
  @Input() defaultErrors = true;
  @Output() isActiveChange = new EventEmitter<boolean>();
  @Output() submitChange = new EventEmitter<boolean>();
  @Output() sendCodeChange = new EventEmitter<boolean>();
  @Output() switchAuthChange = new EventEmitter<boolean>();
  @Output() postCodeAction = new EventEmitter<HttpResponse<unknown>>();
  @Output() getTokenAction = new EventEmitter<TwoFactorTokenResponse>();
  @Output() getError = new EventEmitter<HttpErrorResponse>();
  isSmsAuth = true;
  form = this.initForm();
  unsubscribe$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private uni2faRepository: Uni2faRepository,
    private uniSnackbarFacade: UniSnackbarFacade,
    private uniAuthFacade: UniAuthFacade,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isSmsAuth =
      (
        !this.uniAuthFacade.user.otpChannel
        || this.uniAuthFacade.user.otpChannel === OtpType.SMS
        || this.channel === OtpType.SMS
      )
      && this.channel !== OtpType.EMAIL;

    this.sendCode();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): FormGroup {
    return this.formBuilder.group({
      code: [null, [Validators.required]]
    });
  }

  hideModal(): void {
    this.isActiveChange.emit(false);
  }

  onSubmit(): void {
    this.submitChange.emit(this.isSmsAuth);

    if (!this.isAutoRequest) {
      return;
    }

    this.isLoading = true;

    this.uni2faRepository
      .getToken({
        codeType: this.codeType,
        type: this.getOtpType(),
        code: this.form.get('code').value,
        ...(!!this.generateTokenOptions && this.generateTokenOptions )
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => this.getTokenSuccess(response.body),
        (error) => this.getTokenError(error),
      );
  }

  getTokenSuccess(response: TwoFactorTokenResponse): void {
    this.isLoading = false;
    this.getTokenAction.emit(response);
    this.uniSnackbarFacade.show('success', '2fa.getTokenSuccess');
    this.hideModal();
    this.cdr.detectChanges();
  }

  getTokenError(error: HttpErrorResponse): void {
    this.isLoading = false;

    this.getError.emit(error);

    if (this.defaultErrors) {
      this.uniSnackbarFacade.show('error',
        ValidationUtils.getViolationError(error),
        ValidationUtils.getParams(error)
      );
    }

    this.cdr.detectChanges();
  }

  switchAuth(): void {
    this.isSmsAuth = !this.isSmsAuth;
    this.switchAuthChange.emit(this.isSmsAuth);
    this.sendCode();
  }

  getOtpType(): OtpType {
    return this.isSmsAuth
      ? OtpType.SMS
      : OtpType.EMAIL;
  }

  sendCode(): void {
    this.sendCodeChange.emit(this.isSmsAuth);

    if (!this.isAutoRequest) {
      return;
    }

    this.isLoading = true;

    this.uni2faRepository
      .postCode({
        codeType: this.codeType,
        type: this.getOtpType(),
        ...this.additionalOptions
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => this.sendCodeSuccess(response),
        (error) => this.sendCodeError(error),
      );
  }

  sendCodeSuccess(reponse: HttpResponse<unknown>): void {
    this.isLoading = false;
    this.postCodeAction.emit(reponse);
    this.uniSnackbarFacade.show('success', '2fa.codeSuccess');
    this.cdr.detectChanges();
  }

  sendCodeError(error: HttpErrorResponse): void {
    this.isLoading = false;

    if (error.status === HttpStatus.TOO_MANY_REQUESTS) {
      this.uniSnackbarFacade.show('error', '2fa.errorLimit');
    } else {
      this.uniSnackbarFacade.show('error',
        ValidationUtils.getViolationError(error),
        ValidationUtils.getParams(error)
      );
    }

    this.cdr.detectChanges();
  }
}
