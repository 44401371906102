<uni-form-field-info [type]="uniFormInfoType.error">
  <uni-text-body
    [isUppercase]="false"
    [weight]="uniTextWeight.regular"
    [color]="uniTextColor.black"
  >
  <span [innerHTML]="errorMessage | translate"></span>
  <uni-link
      path="/media/samples/File2sms - example file.xlsx"
      target="_blank"
  >
    {{ 'campaign.template' | translate }}
  </uni-link>
  </uni-text-body>
</uni-form-field-info>
