import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { UniSnackbarItemComponent } from './components/uni-snackbar-item/uni-snackbar-item.component';
import { UniSnackbarComponent } from './components/uni-snackbar/uni-snackbar.component';
import { SnackbarEffects } from './store/uni-snackbar.effects';
import { snackbarReducer } from './store/uni-snackbar.reducers';

const components = [
  UniSnackbarComponent,
  UniSnackbarItemComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    StoreModule.forFeature('snackbar', snackbarReducer),
    EffectsModule.forFeature([SnackbarEffects])
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniSnackbarModule { }
