export function mapStringToNumber(input) {
  const inputlength = input.length;
  input = input.toLowerCase();
  let output = '';
  for (let i = 0; i < inputlength; i++) {
    const character = input.charAt(i);

    switch (character) {
      case '0':
        output += '0';
        break;
      case '1':
        output += '1';
        break;
      case '2':
        output += '2';
        break;
      case '3':
        output += '3';
        break;
      case '4':
        output += '4';
        break;
      case '5':
        output += '5';
        break;
      case '6':
        output += '6';
        break;
      case '7':
        output += '7';
        break;
      case '8':
        output += '8';
        break;
      case '9':
        output += '9';
        break;
      case '-':
        output += '-';
        break;
      case '+':
        output += '+';
        break;
      case ' ':
        output += '0';
        break;
      case 'a':
      case 'b':
      case 'c':
        output += '2';
        break;
      case 'd':
      case 'e':
      case 'f':
        output += '3';
        break;
      case 'g':
      case 'h':
      case 'i':
        output += '4';
        break;
      case 'j':
      case 'k':
      case 'l':
        output += '5';
        break;
      case 'm':
      case 'n':
      case 'o':
        output += '6';
        break;
      case 'p':
      case 'q':
      case 'r':
      case 's':
        output += '7';
        break;
      case 't':
      case 'u':
      case 'v':
        output += '8';
        break;
      case 'w':
      case 'x':
      case 'y':
      case 'z':
        output += '9';
        break;
    }
  }
  return output;
}
