import { Params } from '@angular/router';
import { Action } from '@ngrx/store';

import { UniUser } from '../shared/uni-user.model';

export const SET_USERS = '[UniUsers] SET_USERS';
export const SET_USERS_SUCCESS = '[UniUsers] SET_USERS_SUCCESS';
export const CLEAR_USERS = '[UniUsers] CLEAR_USERS';

export const SET_USERS_AMOUNT = '[UniUsers] SET_USERS_AMOUNT';

export class SetUsers implements Action {
  readonly type = SET_USERS;

  constructor(public params: Params) { }
}

export class SetUsersSuccess implements Action {
  readonly type = SET_USERS_SUCCESS;

  constructor(public users: UniUser[]) { }
}

export class ClearUsers implements Action {
  readonly type = CLEAR_USERS;

  constructor() { }
}

export class SetUsersAmount implements Action {
  readonly type = SET_USERS_AMOUNT;

  constructor(public amount: number) { }
}

export type All =
  | ClearUsers
  | SetUsers
  | SetUsersSuccess
  | SetUsersAmount;
