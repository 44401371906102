import { Injectable } from '@angular/core';

import { FeatureFlagKeys, UniAuthFacade, UniFeatureFlagsService, AdditionalGrants } from '../../uni-auth/shared';
import { UniNavSettingsService } from './uni-nav-settings.service';
import { NavPermissions, NavMenu, NavMenuGroup, NavAccessData } from './uni-nav.model';

interface NavItem {
    accessData?: NavAccessData;
    items?: NavItem[];
}

@Injectable({ providedIn: 'root' })
export class UniNavModelService {

    constructor(
        private readonly uniAuthFacade: UniAuthFacade,
        private readonly uniNavSettingsService: UniNavSettingsService,
        private readonly uniFeatureFlagsService: UniFeatureFlagsService,
    ) { }

    getNavModel(): NavMenu {
        const navSettings = this.uniNavSettingsService.getNavMenuSettings();
        const filteredMenuGroups = this.filterNavModel(navSettings.groups);

        return { groups: filteredMenuGroups as NavMenuGroup[] };
    }

    private filterNavModel(items: NavItem[]): NavItem[] {
        return items
            .filter(item => this.hasAccess(item))
            .map(item => {
                if (item.items) {
                    item.items = this.filterNavModel(item.items);
                }
                return item;
            });
    }

    private hasAccess(item: NavItem): boolean {
        if (!item.accessData) {
            return true;
        }
        return this.hasAdditionalGrants(item.accessData.additionalGrants)
            && this.hasPermission(item.accessData.permissions)
            && this.isFeatureFlagOn(item.accessData.featureFlag)
            && this.isFeatureFlagOff(item.accessData.featureFlagOff);
    }

    private hasAdditionalGrants(permissions: AdditionalGrants[]): boolean {
        if (!permissions?.length) {
            return true;
        }

        return permissions.every((permission: AdditionalGrants) => this.uniAuthFacade.hasAdditionalGrants(permission));
    }

    private hasPermission(permissions: NavPermissions): boolean {
        if (!permissions) {
            return true;
        }

        return permissions.permissionsList?.length
            ? this.uniAuthFacade.hasSomePermission(permissions.permissionPath, permissions.permissionsList)
            : this.uniAuthFacade.hasPermissionPath(permissions.permissionPath);
    }

    private isFeatureFlagOn(featureFlag: FeatureFlagKeys | string): boolean {
        return featureFlag
            ? this.uniFeatureFlagsService.getPermission(featureFlag)
            : true;
    }

    private isFeatureFlagOff(featureFlag: FeatureFlagKeys | string): boolean {
      return featureFlag
          ? !this.uniFeatureFlagsService.getPermission(featureFlag)
          : true;
  }
}
