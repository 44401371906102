import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CallerIdState } from './caller-id.state';

export const selectCallerIdFeature = createFeatureSelector<CallerIdState>('callerId');

export const selectCallerIds = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIds,
);

export const selectCallerIdsAmount = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdsAmount,
);

export const selectSecondaryCallerIds = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.secondaryCallerIds,
);

export const selectSecondaryCallerIdsAmount = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.secondaryCallerIdsAmount,
);

export const selectCallerIdsSubscriptions = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdsSubscriptions,
);

export const selectCallerIdsSubscriptionsAmount = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdsSubscriptionsAmount,
);

export const selectGetCallerIdQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.getCallerIdsQuery,
);

export const selectGetSecondaryCallerIdQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.getSecondaryCallerIdsQuery,
);

export const selectGetCallerIdSubscriptionsQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.getCallerIdsSubscriptionsQuery,
);

export const selectPostCallerIdQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.postCallerIdQuery,
);

export const selectCallerIdFormErrors = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.postErrors,
);

export const selectCallerIdDeleteErrors = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.deleteErrors,
);

export const selectAccountHasActiveCallerId = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.hasActiveCallerId
);

export const selectCallerIdChannels = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdChannels,
);

export const selectGetCallerIdChannelsQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.getCallerIdChannelsQuery,
);

export const selectCallerIdChannelsAmount = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdChannelsAmount,
);

export const selectCallerIdChannelConfig = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdChannelConfig
);

export const selectGetCallerIdChannelConfigQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.getCallerIdChannelConfigQuery,
);

export const selectCallerIdChannelCharges = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.callerIdChannelCharges
);

export const selectGetCallerIdChannelChargesQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.getCallerIdChannelChargesQuery,
);

export const selectPostCallerIdChannelQuery = createSelector(
  selectCallerIdFeature,
  (state: CallerIdState) => state.postCallerIdChannelQuery,
);
