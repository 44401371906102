<uni-steps>
  <uni-step
    [step]="1"
    [isActive]="!isSummary"
    [isDisabled]="isSummary"
  >
    {{ 'campaign.create' | translate }}
  </uni-step>
  <uni-step-separator></uni-step-separator>
  <uni-step
    [step]="2"
    [isActive]="isSummary"
    [isDisabled]="!isSummary"
  >
    {{ 'campaign.summary' | translate }}
  </uni-step>
</uni-steps>

<form [formGroup]="form">
  <ng-container *ngIf="!isSummary">
    <cc-campaign-name-form [form]="form"></cc-campaign-name-form>
    <cc-campaign-content-form [form]="form"></cc-campaign-content-form>
    <cc-campaign-dates-form [form]="form" [showRamadanNote]="showRamadanNote"></cc-campaign-dates-form>
  </ng-container>
  <cc-campaign-summary-form
    *ngIf="isSummary"
    [form]="form"
  ></cc-campaign-summary-form>
</form>
