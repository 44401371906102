import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';

@Component({
  selector: 'cc-campaign-cancel-modal',
  templateUrl: './campaign-cancel-modal.component.html'
})
export class CampaignCancelModalComponent {
  @Input() isActive = false;
  @Input() campaignId: string;
  @Output() isActiveChange = new EventEmitter();

  constructor(private campaignsFacade: UniCampaignsFacade) { }

  hideModal(): void {
    this.isActiveChange.emit(false);
  }

  onConfirm(): void {
    this.campaignsFacade.deleteScheduledCampaign(this.campaignId);
  }
}
