import { CdkTable } from '@angular/cdk/table';
import {
  AfterContentInit, Component, ContentChild, ElementRef, HostBinding, HostListener, Input, OnChanges
} from '@angular/core';

import { UniTableActionComponent } from '../uni-table-action/uni-table-action.component';

@Component({
  selector: 'uni-table',
  templateUrl: './uni-table.component.html',
  styleUrls: ['./uni-table.component.scss'],
})
export class UniTableComponent implements AfterContentInit, OnChanges {
  @Input() isLoaded: boolean;
  @ContentChild(UniTableActionComponent, { static: false }) actions: UniTableActionComponent;
  @ContentChild(CdkTable, { static: false }) table: CdkTable<any>;
  isEndOfTable = false;

  constructor(private elementRef: ElementRef) {}

  @HostBinding('class.is-scrollable') @Input() isScrollable = false;
  @HostBinding('class.is-end')
  get isEndClass() {
    return this.isEndOfTable;
  }

  @HostListener('scroll', ['$event'])
  onscroll($event) {
    this.setEndOFTable($event.srcElement);
  }

  ngOnChanges() {
    setTimeout(() => this.setEndOFTable(this.elementRef.nativeElement), 0);
  }

  setEndOFTable(element): void {
    const { firstChild, scrollLeft, offsetWidth } = element;
    const { clientWidth } = firstChild;

    this.isEndOfTable = clientWidth - (scrollLeft + offsetWidth + 15) < 0;
  }

  ngAfterContentInit() {
    this.setEndOFTable(this.elementRef.nativeElement);
  }
}
