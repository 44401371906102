<ng-container *ngIf="form" [formGroup]="form">
  <label>
    {{ 'label.goToPage' | translate }}
  </label>
  <input
    type="text"
    [placeholder]="'label.nr' | translate"
    formControlName="page"
    keyPattern="number"
    (paste)="onPaste($event)"
    uniInput
  >
  <uni-button
    type="submit"
    [style]="'secondary'"
    [disabled]="form.invalid"
  >
    {{ 'action.go' | translate }}
  </uni-button>
</ng-container>
