<ng-content></ng-content>
<div class="uni-sort__icons">
  <uni-icon
    *ngIf="sortBy"
    class="uni-sort__icon"
    name="sort"
  ></uni-icon>
  <uni-icon
    *ngIf="isActive"
    class="uni-sort__arrow"
    [name]="sortIcon"
  ></uni-icon>
</div>
