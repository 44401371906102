import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../utils';
import { UniAuthFacade } from '../../../uni-auth';
import { UniCampaignsPermissionsService } from '../../shared/uni-campaign-permissions.service';

@Component({
  selector: 'cc-campaign-schedule-toggle',
  templateUrl: './campaign-schedule-toggle.component.html',
})
export class CampaignScheduleToggleComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() isVoice = false;
  @Input() isDisabled = false;
  isDisabledType = false;

  constructor(
    private uniCampaignPermissions: UniCampaignsPermissionsService,
    private uniAuthFacade: UniAuthFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.checkFlags();
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags)
      )
      .subscribe(() => {
        this.isDisabledType = this.uniCampaignPermissions.canDirectSendCampaign() || this.isVoice
          ? this.isDisabled
          : true;
      });
  }
}
