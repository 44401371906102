<uni-label *ngIf="label" [required]="required">
  {{ label | translate }}
  <span *ngIf="labelTip.length" uniLabelTip>
    <ng-content select="[uniLabelTip]"></ng-content>
  </span>
  <span *ngIf="labelSuffix.length" uniLabelSuffix>
    <ng-content select="[uniLabelSuffix]"></ng-content>
  </span>
</uni-label>
<div class="uni-form-field__input">
  <div class="uni-form-field__prefix" *ngIf="prefix.length">
    <ng-content select="[uniPrefix]"></ng-content>
  </div>
  <ng-content></ng-content>
  <div class="uni-form-field__suffix" *ngIf="suffix.length">
    <ng-content select="[uniSuffix]"></ng-content>
  </div>
</div>
<ng-content select="[uniDescription]"></ng-content>
<uni-form-field-error *ngIf="validationMessage && isErrorAllowed">
  {{ validationMessage | translate:validationParams }}
</uni-form-field-error>
