<audio #audio [src]="src" [autoplay]="autoPlay"></audio>

<div class="uni-audio">
  <uni-icon
    class="uni-audio__item"
    type="fas"
    [name]="isPlaying ? 'pause' : 'play'"
    (click)="onPlay()"
  ></uni-icon>
  <div class="uni-audio__time uni-audio__item">
    {{ time }}
  </div>
  <uni-audio-progress *ngIf="!displayInline"
    class="uni-audio__item uni-audio__progress"
    [audio]="audio"
    [audioProgress]="audioProgress"
  ></uni-audio-progress>
  <uni-audio-volume *ngIf="!displayInline"
    class="uni-audio__item"
    [audio]="audio"
  ></uni-audio-volume>
  <a
    *ngIf="downloadAudio"
    [href]="src"
    target="_blank"
    [title]="'label.downloadAudio' | translate"
    download
  >
    <uni-icon
      name="file-download"
      type="fas"
    >
    </uni-icon>
  </a>
</div>
