import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-table-progress',
  templateUrl: './uni-table-progress.component.html',
  styleUrls: ['./uni-table-progress.component.scss']
})
export class UniTableProgressComponent {
  @Input() percentage = 0;
  @Input() isDisabled = false;
}
