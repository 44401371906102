import { Observable } from 'rxjs';
import { get } from 'lodash';
import { HttpClient } from '@angular/common/http';

import { Injectable, Optional, Inject } from '@angular/core';
import { SettingsService } from '../../settings/settings.service';
import { Country } from '../../../modules/uni-countries/shared/uni-countries.model';

@Injectable({ providedIn: 'root' })
export class CountriesRepository {
  constructor(
    private uniHttp: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getCountries(): Observable<Country[]> {
    const apiUrl = this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');
    return this.uniHttp.get<Country[]>(`${ apiUrl }/countries`, {});
  }
}
