import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cc-campaign-trial-info',
  templateUrl: './campaign-trial-info.component.html',
  styleUrls: ['./campaign-trial-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignTrialInfoComponent {
  readonly linkLabel = this.translateService.instant('campaign.trialInfo.linkLabel');
  readonly linkUrl = 'https://docs.unifonic.com/docs/sms-service-trials';
  readonly i18nParams = {
    link: `<a href="${this.linkUrl}" target="_blank">${this.linkLabel}</a>`
  };

  constructor(private translateService: TranslateService) {}
}
