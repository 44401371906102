import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';

import { UniButtonModule } from '../uni-button';
import { UniFormModule } from '../uni-form';
import {
  UniPaginationControllersComponent
} from './components/uni-pagination-controllers/uni-pagination-controllers.component';
import { UniPaginationFormComponent } from './components/uni-pagination-form/uni-pagination-form.component';
import { UniPaginationNavComponent } from './components/uni-pagination-nav/uni-pagination-nav.component';
import { UniPaginationComponent } from './components/uni-pagination/uni-pagination.component';
import { UniPaginationFacade } from './shared/uni-pagination.facade';
import { UniPaginationEffects } from './store/uni-pagination.effects';
import { UniPaginationService } from './shared/uni-pagination.service';
import { UniIconModule } from '../uni-icon/uni-icon.module';

const components = [
  UniPaginationComponent,
  UniPaginationNavComponent,
  UniPaginationFormComponent,
  UniPaginationControllersComponent,
];

const modules = [
  UniButtonModule,
  UniFormModule,
  UniIconModule,
];

@NgModule({
  imports: [
    ...modules,
    CommonModule,
    TranslateModule,
    EffectsModule.forFeature([UniPaginationEffects])
  ],
  declarations: [...components],
  exports: [
    ...components,
    ...modules,
  ],
  providers: [
    UniPaginationFacade,
    UniPaginationService
  ]
})
export class UniPaginationModule { }
