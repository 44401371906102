import { Component, OnInit } from '@angular/core';
import { Permission } from '../../../uni-auth/shared/uni-auth.model';
import { ActivePackages, Package, PackagesFacade, PackageStatus } from '../../../../shared/uni-data/index';
import { UniShortNumberService } from '../../../../shared/';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';

@Component({
  selector: 'uni-top-bar-packages',
  templateUrl: './uni-top-bar-packages.component.html',
  styleUrls: ['./uni-top-bar-packages.component.scss']
})
export class UniTopBarPackagesComponent implements OnInit {
  packages$ = this.uniPackagesFacade.packages$;
  canSeePackages = this.uniAuthFacade.hasSomePermission('UC.PACKAGE', [Permission.seeOwn, Permission.transfer]);
  isOpen = false;

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniPackagesFacade: PackagesFacade,
    private uniShortNumberService: UniShortNumberService,
  ) {}

  ngOnInit(): void {
    if (this.canSeePackages) {
      this.uniPackagesFacade.setPackages();
    }
  }

  units(packages: ActivePackages): string {
    return !!packages.units ? this.uniShortNumberService.formatNumber(packages.units) : '0';
  }

  isNumber(value: any): boolean {
    return !isNaN(value);
  }

  filterActive(packages: Package[]) {
    const activePackages = packages.filter(item => item.status === PackageStatus.active);

    return activePackages.length;
  }

  onChange(value: boolean): void {
    this.isOpen = value;
  }
}
