import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { UniTextColor, UniTextWeight } from '../../../uni-text/shared/uni-text.model';

@Component({
  selector: 'uni-box-tab',
  templateUrl: './uni-box-tab.component.html',
  styleUrls: ['./uni-box-tab.component.scss'],
})
export class UniBoxTabComponent {
  @HostBinding('class.is-active') @Input() isActive = false;
  @HostBinding('class.is-disabled') @Input() isDisabled = false;
  isHover = false;

  constructor(public elementRef: ElementRef) {}

  getColor(
    isActive: boolean,
    isDisabled: boolean,
    isHover: boolean
  ) {
    return isDisabled
      ? UniTextColor.disabled
      : isActive || isHover
        ? UniTextColor.primary
        : UniTextColor.secondary;
  }

  getWeight(isActive: boolean) {
    return isActive
      ? UniTextWeight.medium
      : UniTextWeight.regular;
  }
}
