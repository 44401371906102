// https://unifonic.atlassian.net/wiki/spaces/DESIGN/pages/2301952090/Data+formats#Standard.1
import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UniNumberService {
  private readonly trillion = 1000000000000;
  private readonly billion   = 1000000000;
  private readonly million   = 1000000;
  private readonly thousand = 1000;

  constructor(
    private translate: TranslateService,
  ) { }

  formatNumber(value: number, fractionDigits?: number): string {
    const digitsInfo = this.getDigitsInfo(fractionDigits);

    return formatNumber(value, 'en', digitsInfo);
  }

  formatShortNumber(value: number, fractionDigits?: number): string {
    return this.formatWithTranslation(true, value, fractionDigits);
  }

  formatLongNumber(value: number, fractionDigits?: number): string {
    return this.formatWithTranslation(false, value, fractionDigits);
  }

  private formatWithTranslation(isShort: boolean, value: number, fractionDigits?: number): string {
    let shortValue = value;
    let i18nKey = isShort ? 'format.' : 'format.number.long.';

    if (shortValue >= this.trillion) {
      shortValue /= this.trillion;
      i18nKey += 'trillion';
    } else if (shortValue >= this.billion) {
      shortValue /= this.billion;
      i18nKey += 'billion';
    } else if (shortValue >= this.million) {
      shortValue /= this.million;
      i18nKey += 'million';
    } else if (shortValue >= this.thousand) {
      shortValue /= this.thousand;
      i18nKey += 'thousand';
    } else {
      return this.formatNumber(shortValue, fractionDigits);
    }

    return this.translate.instant(
      i18nKey,
      { number: this.formatNumber(shortValue, fractionDigits) });
  }

  private getDigitsInfo(fractionDigits?: number): string {
    return fractionDigits ? `1.${fractionDigits}-${fractionDigits}` : '1.0-2';
  }
}
