import { Component, Input, OnInit } from '@angular/core';
import { MessageLogsTableDetailsVoiceStore } from './message-logs-table-details-voice.store';

@Component({
  selector: 'app-message-logs-table-details-voice',
  templateUrl: './message-logs-table-details-voice.component.html',
  styleUrls: ['./message-logs-table-details-voice.component.scss'],
  providers: [MessageLogsTableDetailsVoiceStore]
})
export class MessageLogsTableDetailsVoiceComponent implements OnInit {

  @Input() set id(id: string){
    this.store.patchState({id})
  }

  vm$ = this.store.vm$

  constructor(
    private store: MessageLogsTableDetailsVoiceStore
  ) { }

  ngOnInit(): void {
    this.store.fetchData()
  }
}
