import { values } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Queries, QueryResponse } from './http-query.model';

export const isAnyHttpQueryInProgress = (queries: QueryResponse<unknown>[]) =>
  queries.some(({ isInProgress }) => isInProgress);

export const isHttpQueryGroupInProgress = (queries: Queries): boolean =>
  isAnyHttpQueryInProgress(values(queries));

export const isHttpQueryInProgress$ = (queries: Observable<QueryResponse<unknown>>[]): Observable<boolean> =>
  combineLatest(queries).pipe(map(isAnyHttpQueryInProgress));
