import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniFormModule } from '../uni-form';
import { UniIconModule } from '../uni-icon';
import { UniAudioProgressComponent } from './components/uni-audio-progress/uni-audio-progress.component';
import { UniAudioVolumeComponent } from './components/uni-audio-volume/uni-audio-volume.component';
import { UniAudioComponent } from './components/uni-audio/uni-audio.component';
import { UniAudioPlayerComponent } from './components/uni-audio-player/uni-audio-player.component';
import { TranslateModule } from '@ngx-translate/core';

const components = [
  UniAudioComponent,
  UniAudioVolumeComponent,
  UniAudioProgressComponent,
  UniAudioPlayerComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    UniIconModule,
    UniFormModule,
    TranslateModule
  ]
})
export class UniAudioModule { }
