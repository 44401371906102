import { Ng2TelInputModule } from 'ng2-tel-input';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { TranslateModule } from '@ngx-translate/core';

import { UniButtonModule } from '../uni-button';
import { UniIconModule } from '../uni-icon/uni-icon.module';
import { UniLayoutModule } from '../uni-layout/uni-layout.module';
import { UniTooltipModule } from '../uni-tooltip';
import { UniCheckboxComponent } from './components/uni-checkbox/uni-checkbox.component';
import { UniColorpickerComponent } from './components/uni-colorpicker/uni-colorpicker.component';
import { UniDatepickerComponent } from './components/uni-datepicker/uni-datepicker.component';
import { UniFormAutocompleteComponent } from './components/uni-form-autocomplete/uni-form-autocomplete.component';
import { UniFormContainerComponent } from './components/uni-form-container/uni-form-container.component';
import { UniFormEyeComponent } from './components/uni-form-eye/uni-form-eye.component';
import { UniFormFieldErrorComponent } from './components/uni-form-field-error/uni-form-field-error.component';
import { UniFormFieldInfoComponent } from './components/uni-form-field-info/uni-form-field-info.component';
import { UniFormFieldVerifiedComponent } from './components/uni-form-field-verified/uni-form-field-verified.component';
import { UniFormFieldComponent } from './components/uni-form-field/uni-form-field.component';
import { UniFormFileComponent } from './components/uni-form-file/uni-form-file.component';
import { UniFormFooterComponent } from './components/uni-form-footer/uni-form-footer.component';
import { UniFormItemComponent } from './components/uni-form-item/uni-form-item.component';
import {
  UniFormMessageCounterComponent
} from './components/uni-form-message-counter/uni-form-message-counter.component';
import {
  UniFormMultiAutocompleteControllersComponent
} from './components/uni-form-multi-autocomplete-controllers/uni-form-multi-autocomplete-controllers.component';
import {
  UniFormMultiAutocompleteHeaderComponent
} from './components/uni-form-multi-autocomplete-header/uni-form-multi-autocomplete-header.component';
import {
  UniFormMultiAutocompleteComponent
} from './components/uni-form-multi-autocomplete/uni-form-multi-autocomplete.component';
import { UniFormPhoneComponent } from './components/uni-form-phone/uni-form-phone.component';
import { UniFormRowComponent } from './components/uni-form-row/uni-form-row.component';
import { UniFormSearchComponent } from './components/uni-form-search/uni-form-search.component';
import { UniFormSelectComponent } from './components/uni-form-select/uni-form-select.component';
import { UniFormStepsItemComponent } from './components/uni-form-steps-item/uni-form-steps-item.component';
import { UniFormStepsComponent } from './components/uni-form-steps/uni-form-steps.component';
import { UniInputPriceComponent } from './components/uni-input-price/uni-input-price.component';
import { UniInputCreditCardComponent } from './components/uni-input-credit-card/uni-input-credit-card.component';
import { UniMultiselectListComponent } from './components/uni-multiselect-list/uni-multiselect-list.component';
import { UniDescriptionDirective } from './directives/input-description.directive';
import { UniPrefixDirective } from './directives/input-prefix.directive';
import { UniSuffixDirective } from './directives/input-suffix.directive';
import { UniInputDirective } from './directives/input.directive';
import { UniLabelSuffixDirective } from './directives/label-suffix.directive';
import { UniLabelTipDirective } from './directives/label-tip.directive';
import { UniFormService } from './shared';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { UniTextModule } from '../uni-text';
import { UniFormFieldsComponent } from './components/uni-form-fields/uni-form-fields.component';
import { UniFormFieldsItemComponent } from './components/uni-form-fields-item/uni-form-fields-item.component';
import { UniMaxRangeDirective } from './directives/max-range.directive';
import { UniFormSearchDropdownComponent } from './components/uni-form-search-dropdown/uni-form-search-dropdown.component';
import { UniDirectivesModule } from '../../directives/directives.module';
import { UniInputDisplayValueFromListDirective } from './directives/input-display-value-from-list.directive';
import { UniFormValidationComponent } from './components/uni-form-validation/uni-form-validation.component';
import { UniFormBoxComponent } from './components/uni-form-box/uni-form-box.component';
import { UniMultiCheckboxComponent } from './components/uni-multi-checkbox/uni-multi-checkbox.component';

const components = [
  UniFormItemComponent,
  UniFormRowComponent,
  UniFormFieldComponent,
  UniFormFieldErrorComponent,
  UniFormSelectComponent,
  UniFormPhoneComponent,
  UniFormEyeComponent,
  UniInputPriceComponent,
  UniInputCreditCardComponent,
  UniColorpickerComponent,
  UniDatepickerComponent,
  UniFormFooterComponent,
  UniFormAutocompleteComponent,
  UniFormContainerComponent,
  UniFormMultiAutocompleteComponent,
  UniFormSearchComponent,
  UniFormMultiAutocompleteControllersComponent,
  UniFormMultiAutocompleteHeaderComponent,
  UniFormFieldInfoComponent,
  UniFormFieldVerifiedComponent,
  UniMultiCheckboxComponent,
  UniMultiselectListComponent,
  UniFormStepsComponent,
  UniFormStepsItemComponent,
  UniFormFileComponent,
  UniFormMessageCounterComponent,
  UniCheckboxComponent,
  UniFormFieldsComponent,
  UniFormFieldsItemComponent,
  UniFormSearchDropdownComponent,
  UniFormValidationComponent,
  UniFormBoxComponent
];

const directives = [
  UniInputDirective,
  UniSuffixDirective,
  UniLabelSuffixDirective,
  UniDescriptionDirective,
  UniPrefixDirective,
  UniLabelTipDirective,
  UniMaxRangeDirective,
  UniInputDisplayValueFromListDirective,
];

const modules = [
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  UniIconModule,
  UniLayoutModule,
  UniTooltipModule,
  Ng2TelInputModule,
  MatAutocompleteModule,
  MatInputModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatCheckboxModule,
  MatRadioModule,
  UniButtonModule,
  UniTextModule,
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    UniIconModule,
    UniDirectivesModule,
    ...modules,
  ],
  exports: [
    ...components,
    ...directives,
    ...modules,
  ],
  providers: [
    UniFormService
  ]
})
export class UniFormModule { }
