import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { sum } from 'lodash';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { CampaignDataSource } from '../../shared/uni-campaign.model';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { FeatureFlagKeys, UniAuthFacade, UniFeatureFlagsService } from '../../../uni-auth';
import { filter } from 'rxjs/operators';
import { UniCampaignsFacade, UniCampaignsPermissionsService } from '../../shared';
import { UniCampaignFormService } from '../../shared/uni-campaign-form.service';
import { UniFormService } from '../../../uni-form/shared/uni-form.service';

@Component({
  selector: 'cc-campaign-content-form',
  templateUrl: './campaign-content-form.component.html',
})
export class CampaignContentFormComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  step = 2;
  isRecipientsFieldValid = false;
  recipientsLimit = this.campaignFormFactory.recipientsLimit;
  manualRecipientsLimit = this.campaignFormFactory.manualRecipientsLimit;
  canDirectSendCampaign = false;
  isManual = false;
  isContentTypeDisabled = false;
  isConfirmStep = false;
  isBlockWordsEnabled = false;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get stepControl(): FormControl {
    return this.form.get('step') as FormControl;
  }

  get isNextStep(): FormControl {
    return this.form.get('datesForm') as FormControl;
  }

  get typeControl(): FormControl {
    return this.contentForm.get('type') as FormControl;
  }

  get isCustomForm(): boolean {
    return this.typeControl.value === CampaignDataSource.custom;
  }

  get isFileToSMSForm(): boolean {
    return this.typeControl.value === CampaignDataSource.fileToSMS;
  }

  get senderNameControl(): FormControl | null {
    return this.contentForm.get('senderName') as FormControl;
  }

  get contentControl(): FormControl {
    return this.contentForm.get('content') as FormControl;
  }

  constructor(
    private campaignFormFactory: UniCampaignFormFactory,
    private uniAuthFacade: UniAuthFacade,
    private uniCampaignPermissions: UniCampaignsPermissionsService,
    private uniCampaignFormService: UniCampaignFormService,
    private campaignFacade: UniCampaignsFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
    private uniFormService: UniFormService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.watchCampaignForm(),
      this.watchSenderNameControl(),
      this.checkBlockWords(),
      this.checkFlags(),
    );

    if (this.contentForm) {
      this.subs.add(
        this.watchForm()
      );
    }
  }

  onConfirmStep(): void {
    if (!this.isNextStep) {
      this.addDatesForm();
    }
    this.stepControl.setValue(this.step + 1);
    const sid = this.senderNameControl?.value;
    const isTrialSenderName = sid && sid.isTrial;
    this.contentForm.disable({ emitEvent: !isTrialSenderName });
    this.contentForm.updateValueAndValidity({ emitEvent: !isTrialSenderName });
  }

  onEditStep(): void {
    this.stepControl.setValue(this.step);
    const sid = this.senderNameControl?.value;
    if(sid?.isTrial) {
      this.senderNameControl.enable({ emitEvent: false });
    } else {
      this.contentForm.enable();
    }
  }

  addDatesForm(): void {
    this.campaignFormFactory.addDatesForm(this.form, this.canDirectSendCampaign);
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(filter(featureFlags => !!featureFlags))
      .subscribe(()=> {
        this.canDirectSendCampaign = this.uniCampaignPermissions.canDirectSendCampaign();
        this.isBlockWordsEnabled = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.campaign_block_words_2022_q_4);
      });
  }

  watchCampaignForm(): Subscription {
    return this.form.valueChanges
    .subscribe(() => {
      if (this.contentForm) {
        this.subs.add(
          this.watchForm()
        );
      }
    });
  }

  watchForm(): Subscription {
    return this.contentForm.valueChanges
    .subscribe(() => this.updateValidity());
  }

  updateValidity() {
    let recipients = 0;
    let groups = 0;
    this.isManual = false;

    if (
      this.contentForm.get('recipients.audienceGroups')
      && this.contentForm.get('recipients.audienceGroups').value
    ) {
      recipients = sum(this.contentForm.get('recipients.audienceGroups').value.map(item => item.contacts));
      groups = this.contentForm.get('recipients.audienceGroups').value.length;
    }
    else if (
      this.contentForm.get('recipients.fileRecipients')
      && this.contentForm.get('recipients.fileRecipients').value
    ) {
      recipients = this.contentForm.get('recipients.fileRecipients').value;
    }
    else if (
      this.contentForm.get('recipients.phoneNumbers')
      && this.contentForm.get('recipients.phoneNumbers').value
    ) {
      this.isManual = true;
      recipients = this.contentForm.get('recipients.phoneNumbers').value.length || 0;
    }
    else if (
      this.contentForm.get('fileResponse')
      && this.contentForm.get('fileResponse').value
    ) {
      recipients = this.validateFile();
    }

    this.isRecipientsFieldValid = recipients > 0
      && this.validateRecipients(recipients)
      && groups <= 5;
  }

  validateFile(): number {
    if (this.contentForm.get('file').value) {
      return this.contentForm.get('fileResponse').value.recipientsCount;
    }

    this.contentForm.get('fileResponse').reset();
    this.contentForm.get('fileName').reset();

    return 0;
  }

  validateRecipients(recipients: number): boolean {
    const limit = this.isManual ? this.manualRecipientsLimit : this.recipientsLimit;
    return recipients <= limit;
  }

  watchSenderNameControl(): Subscription {
    return this.uniCampaignFormService.selectedSenderName$
    .subscribe(([, currentValue]) => {
      this.isContentTypeDisabled = currentValue?.isTrial;
    });
  }

  private canCheckBlockWords(): boolean {
    return (
            this.typeControl.value !== CampaignDataSource.fileToSMS &&
            this.contentControl?.value &&
            this.isBlockWordsEnabled &&
            this.senderNameControl?.value?.id
          );
  }
  public onCheckBlockWords(): void {
    if(this.canCheckBlockWords()) {
      this.campaignFacade.checkBlockWords({
        content: this.contentControl.value,
        senderId: this.senderNameControl?.value.id
      });
    } else {
      this.onConfirmStep();
    }
  }

  checkBlockWords(): Subscription {
    return this.campaignFacade.checkBlockContent$
    .pipe(filter(res=>(!!res && !!this.contentForm)))
    .subscribe(res => {
      if(res && !!res.isBlockedContent) {
        this.contentControl.setErrors({ blockWords: true });
        this.uniFormService.validateForm(this.contentForm);
      } else {
        this.contentControl.setErrors(null);
        this.onConfirmStep();
      }
    });
  }
}
