import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';
import { QueryResponse } from '../http-query.model';
import { HttpQueryState, QUERY_STORE_KEY } from './http-query.state';

export const queryState = createFeatureSelector<HttpQueryState>(QUERY_STORE_KEY);

export const isInProgress = (groupName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => state.groups[groupName]?.isInProgress || false,
);

const getQuery = <T>(state: HttpQueryState, name: string): QueryResponse<HttpResponse<T>> =>
  state.queries[name] as QueryResponse<HttpResponse<T>> || null;

const getQueryFromGroup = <T>(state: HttpQueryState, groupName: string, queryName: string): QueryResponse<HttpResponse<T>> =>
  state.groups[groupName]?.queries[queryName] as QueryResponse<HttpResponse<T>> || null;

export const query = <T>(queryName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => getQuery<T>(state, queryName)
);

// TODO: It might be renamed to isQueryInProgress when the old 'query' logic is removed.
// At the moment there's a naming conflict (isQueryInProgress name already exists).
export const isHttpQueryInProgress = <T>(queryName: string) => createSelector(
  query(queryName),
  (queryResponse: QueryResponse<HttpResponse<T>>) => queryResponse?.isInProgress || false,
);

export const isGroupHttpQueryInProgress = <T>(groupName: string, queryName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => getQueryFromGroup(state, groupName, queryName)?.isInProgress || false,
);

export const body = <T>(queryName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => {
    const data = getQuery<T>(state, queryName);
    return data?.response?.body || null;
  }
);

export const response = <T>(queryName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => {
    const data = getQuery<T>(state, queryName);
    return data?.response || null;
  }
);

export const error = (queryName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => {
    const data = getQuery(state, queryName);
    return data?.error || null;
  }
);

export const status = (queryName: string) => createSelector(
  queryState,
  (state: HttpQueryState) => {
    const data = getQuery(state, queryName);
    return data?.status || null;
  }
);
