import * as LayoutActions from './uni-layout.actions';
import { initialState, UniLayoutState } from './uni-layout.state';

export function layoutReducer(state = initialState, action: LayoutActions.All): UniLayoutState {
  switch (action.type) {
    case LayoutActions.SET_IS_LOADER:
      return {
        ...state,
        isLoader: action.state,
      };
    default:
      return state;
  }
}
