import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-table-top-bar',
  templateUrl: './uni-table-top-bar.component.html',
  styleUrls: ['./uni-table-top-bar.component.scss']
})
export class UniTableTopBarComponent {
  @HostBinding('class.reverse') @Input() isReverse = false;
  @HostBinding('class.is-border') @Input() isBorder = false;
}
