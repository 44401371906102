import { Component } from '@angular/core';
import { UniTextWeight, UniTextColor } from '../../shared/uni-text.model';

@Component({
  selector: 'uni-text-info',
  templateUrl: './uni-text-info.component.html',
  styleUrls: ['./uni-text-info.component.scss'],
})
export class UniTextInfoComponent {
  fontWeight: UniTextWeight = UniTextWeight.regular;
  fontColor: UniTextColor = UniTextColor.darkLight;
}
