import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-nav-item',
  templateUrl: './uni-nav-item.component.html',
  styleUrls: ['./uni-nav-item.component.scss']
})
export class UniNavItemComponent {
  @HostBinding('class.is-dropdown') @Input() isDropdown = false;
  @HostBinding('class.is-sidebar') @Input() isSidebar = false;
}
