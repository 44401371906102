import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CountriesTableStore } from './countries-table.store';

@Component({
  selector: 'app-countries-table',
  templateUrl: './countries-table.component.html',
  styleUrls: ['./countries-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CountriesTableStore]
})
export class CountriesTableComponent {
  data:  any;
  constructor(
    private store: CountriesTableStore
  ) { }


  ngOnInit(): void {
  }

  vm$ = this.store.vm$
}
