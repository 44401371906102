
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { HttpQueryState } from './http-query.state';
import * as QuerySelectors from './http-query.selectors';
import { QueryResponse, HttpQueryStatus } from '../http-query.model';
import { map } from 'rxjs/operators';

@Injectable()
export class HttpQueryFacade {
  constructor(private store: Store<HttpQueryState>) { }

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  isAnyInProgress$(groupNames: string[]): Observable<boolean> {
    return combineLatest(
      groupNames.map(groupName => this.isInProgress$(groupName))
    ).pipe(
      map(combined => combined.some(isInProgress => isInProgress)),
    );
  }

  isInProgress$(groupName: string): Observable<boolean> {
    const selector = QuerySelectors.isInProgress(groupName);
    return this.store.pipe(select(selector));
  }

  // TODO: It might be renamed to isQueryInProgress$ when the old 'query' logic is removed.
  // At the moment there's a naming conflict (isQueryInProgress$ name already exists).
  isHttpQueryInProgress$(queryName: string): Observable<boolean> {
    const selector = QuerySelectors.isHttpQueryInProgress(queryName);
    return this.store.pipe(select(selector));
  }

  isGroupHttpQueryInProgress$(groupName: string, queryName: string): Observable<boolean> {
    const selector = QuerySelectors.isGroupHttpQueryInProgress(groupName, queryName);
    return this.store.pipe(select(selector));
  }

  query$<T>(queryName: string): Observable<QueryResponse<HttpResponse<T>>> {
    const selector = QuerySelectors.query<T>(queryName);
    return this.store.pipe(select(selector));
  }

  response$<T>(queryName: string): Observable<HttpResponse<T>> {
    const selector = QuerySelectors.response<T>(queryName);
    return this.store.pipe(select(selector));
  }

  body$<T>(queryName: string): Observable<T> {
    const selector = QuerySelectors.body<T>(queryName);
    return this.store.pipe(select(selector));
  }

  error$(queryName: string): Observable<HttpErrorResponse> {
    const selector = QuerySelectors.error(queryName);
    return this.store.pipe(select(selector));
  }

  status$(queryName: string): Observable<HttpQueryStatus> {
    const selector = QuerySelectors.status(queryName);
    return this.store.pipe(select(selector));
  }
}
