import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-modal',
  templateUrl: './uni-modal.component.html',
  styleUrls: ['./uni-modal.component.scss'],
})
export class UniModalComponent {
  @Input() isFullScreen = false;
  @Input() lighten = false;
  @Input() isCloseIcon = false;
  @Input() isOverlayAction = true;
  @Input() maxWitdth = '';
  @Output() isActiveChange = new EventEmitter();

  @HostBinding('class.is-active') @Input() isActive = true;

  hideModal() {
    this.isActiveChange.emit(false);
  }
}
