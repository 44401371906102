import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-box-info',
  templateUrl: './uni-box-info.component.html',
  styleUrls: ['./uni-box-info.component.scss'],
})
export class UniBoxInfoComponent {
  @Input() class = '';
  @Input() spacing = 'none';

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-box-info--spacing-${this.spacing}`;
  }
}
