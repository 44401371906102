export enum OrderPaymentMethod {
  bankTransfer = 'app.payment.payment_method.bank_transfer',
  deductedBalance = 'app.payment.payment_method.deducted_balance',
  free = 'app.payment.payment_method.free',
}

export enum OrderItemType {
  senderNameRenewal = 'app.order_item.item_type.sender_name_renewal',
}

export interface OrderItem {
  itemType: OrderItemType;
  name: string;
  refId: number;
  units: number;
  netAmount: number;
  vatAmount: number;
  vatRate: number;
  currency: string;
}

export interface OrderDto {
  orderId: string;
  orderItems: OrderItem[];
}

export interface Order {
  currency: string;
  itemsCount: number;
  netAmount: number;
  orderId: string;
  orderItems: OrderItem[];
  totalAmount: number;
  vatAmount: number;
  vatRate: number;
}

export type CreateOrderItem = Pick<OrderItem, 'itemType' | 'name' | 'refId'>;

export interface CreateOrderPayload {
  orderItems: CreateOrderItem[];
}
