import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { UniAuthFacade } from '../../uni-auth/shared';

@Injectable({
  providedIn: 'root'
})
export class UniNewNavEnabledService {

  public isNewMenuEnabled$ = of(this.isNewMenuEnabled());

  constructor(
    private readonly uniAuthFacade: UniAuthFacade
  ) { }

  private isNewMenuEnabled(): boolean {
    // to be consistent with the logic for old menu in the Twig template (in Twig: isAppShellVisible)
    return !(this.uniAuthFacade.isWhiteLabel() || this.uniAuthFacade.isAdminLevel());
  }
}
