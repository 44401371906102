<uni-box-content *ngIf="campaign" type="grey">
  <uni-section-header size="medium">
    <uni-text-caption>
      {{ 'campaign.details' | translate }}
    </uni-text-caption>
  </uni-section-header>
  <uni-details>

    <uni-details-item *ngIf="campaign.createdAt">
      <uni-label>
        {{ 'table.createdAt' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.createdAt | dateFormat:dateUtils.Format.meridiemDateTime }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item *ngIf="campaign.user">
      <uni-label>
        {{ 'table.createdBy' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.user.fullName }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item *ngIf="campaign.account">
      <uni-label>
        {{ 'table.accountName' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.account?.name }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item *ngIf="campaign.scheduledAt">
      <uni-label>
        {{ 'campaign.scheduledCampaignDateAndTime' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.scheduledAt | dateFormat:dateUtils.Format.meridiemDateTime }}
      </cc-campaign-detail>
    </uni-details-item>

  </uni-details>
</uni-box-content>
