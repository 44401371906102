import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-tile',
  templateUrl: './uni-tile.component.html',
  styleUrls: ['./uni-tile.component.scss'],
})
export class UniTileComponent {
  @HostBinding('class.is-active') @Input() isActive = false;
}
