import { AfterContentInit, Component, ContentChildren, ElementRef, HostBinding, Input, QueryList } from '@angular/core';

import { UniBoxTabComponent } from '../uni-box-tab/uni-box-tab.component';

@Component({
  selector: 'uni-box-tabs',
  templateUrl: './uni-box-tabs.component.html',
  styleUrls: ['./uni-box-tabs.component.scss'],
})
export class UniBoxTabsComponent implements AfterContentInit {
  @ContentChildren(UniBoxTabComponent) tabs: QueryList<UniBoxTabComponent>;
  @HostBinding('class.is-hug') @Input() isHug = true;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    this.tabs
      .filter(({ isActive }) => isActive)
      .map(tab => this.scrollToElement(tab));
  }

  scrollToElement(tab: UniBoxTabComponent) {
    setTimeout(() => {
      const { offsetLeft } = tab.elementRef.nativeElement;
      this.elementRef.nativeElement.scrollTo({ left: offsetLeft });
    }, 100);
  }
}
