export enum UniLanguage {
  en = 'en',
  ar = 'ar',
  // fr = 'fr', @TO DO: uncomment when languages available
  // tr = 'tr',
}

export enum UniLanguageNative {
  ar = 'العربية',
  en = 'English',
  fr = 'français',
  pl = 'polski',
  tr = 'Türkçe',
}

export enum UniRtlLanguage {
  far = 'far',
  arc = 'arc',
  ar = 'ar',
  dv = 'dv',
  ha = 'ha',
  he = 'he',
  khw = 'khw',
  ks = 'ks',
  ku = 'ku',
  ps = 'ps',
  ur = 'ur',
  yi = 'yi',
}

export interface UniLocale {
  locale: string;
}

export const UniLanguages = [
  {name: 'af', code: 'af'},
  {name: 'sq', code: 'sq'},
  {name: 'ar', code: 'ar'},
  {name: 'az', code: 'az'},
  {name: 'bn', code: 'bn'},
  {name: 'bg', code: 'bg'},
  {name: 'ca', code: 'ca'},
  {name: 'zh_CN', code: 'zh_CN'},
  {name: 'zh_HK', code: 'zh_HK'},
  {name: 'zh_TW', code: 'zh_TW'},
  {name: 'hr', code: 'hr'},
  {name: 'cs', code: 'cs'},
  {name: 'da', code: 'da'},
  {name: 'nl', code: 'nl'},
  {name: 'en', code: 'en'},
  {name: 'en_GB', code: 'en_GB'},
  {name: 'en_US', code: 'en_US'},
  {name: 'et', code: 'et'},
  {name: 'fil', code: 'fil'},
  {name: 'fi', code: 'fi'},
  {name: 'fr', code: 'fr'},
  {name: 'de', code: 'de'},
  {name: 'el', code: 'el'},
  {name: 'gu', code: 'gu'},
  {name: 'ha', code: 'ha'},
  {name: 'he', code: 'he'},
  {name: 'hi', code: 'hi'},
  {name: 'hu', code: 'hu'},
  {name: 'id', code: 'id'},
  {name: 'ga', code: 'ga'},
  {name: 'it', code: 'it'},
  {name: 'ja', code: 'ja'},
  {name: 'kn', code: 'kn'},
  {name: 'kk', code: 'kk'},
  {name: 'ko', code: 'ko'},
  {name: 'lo', code: 'lo'},
  {name: 'lv', code: 'lv'},
  {name: 'lt', code: 'lt'},
  {name: 'mk', code: 'mk'},
  {name: 'ms', code: 'ms'},
  {name: 'ml', code: 'ml'},
  {name: 'mr', code: 'mr'},
  {name: 'nb', code: 'nb'},
  {name: 'fa', code: 'fa'},
  {name: 'pl', code: 'pl'},
  {name: 'pt_BR', code: 'pt_BR'},
  {name: 'pt_PT', code: 'pt_PT'},
  {name: 'pa', code: 'pa'},
  {name: 'ro', code: 'ro'},
  {name: 'ru', code: 'ru'},
  {name: 'sr', code: 'sr'},
  {name: 'sk', code: 'sk'},
  {name: 'sl', code: 'sl'},
  {name: 'es', code: 'es'},
  {name: 'es_AR', code: 'es_AR'},
  {name: 'es_ES', code: 'es_ES'},
  {name: 'es_MX', code: 'es_MX'},
  {name: 'sw', code: 'sw'},
  {name: 'sv', code: 'sv'},
  {name: 'ta', code: 'ta'},
  {name: 'te', code: 'te'},
  {name: 'th', code: 'th'},
  {name: 'tr', code: 'tr'},
  {name: 'uk', code: 'uk'},
  {name: 'ur', code: 'ur'},
  {name: 'uz', code: 'uz'},
  {name: 'vi', code: 'vi'},
  {name: 'zu', code: 'zu'},
];
