import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { hasQueryStatus, isQueryFinished } from '../../../store';
import { UniAuthFacade } from './uni-auth.facade';

@Injectable({ providedIn: 'root' })
export class UniGlobalSettingsGuard implements CanActivate {
  constructor(private uniAuthFacade: UniAuthFacade) { }

  getUserMeData(): Observable<any> {
    return this.uniAuthFacade.globalSettingsQuery$.pipe(
      tap(query => !hasQueryStatus(query)
        ? this.uniAuthFacade.setGlobalSettings()
        : false
      ),
      filter(data => isQueryFinished(data)),
      take(1),
    );
  }

  canActivate(): Observable<boolean> {
    return this.getUserMeData()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
  }
}
