
import { queryReducer } from '../../../store';
import * as QueryActions from '../../../store/query/query.actions';
import * as SharedActions from './uni-roles.actions';
import { GET_ROLES_QUERY, uniRolesInitialState, UniRolesState } from './uni-roles.state';

const queryState = (state: UniRolesState, action: SharedActions.All | QueryActions.All): UniRolesState => ({
  ...state,
  ...queryReducer<UniRolesState>([GET_ROLES_QUERY], action as QueryActions.All),
});

export function uniRolesReducer(
  state = uniRolesInitialState,
  action: SharedActions.All,
): UniRolesState {
  switch (action.type) {
    case SharedActions.SET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.response,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
