import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-widget-content',
  templateUrl: './uni-widget-content.component.html',
  styleUrls: ['./uni-widget-content.component.scss'],
})
export class UniWidgetContentComponent {
  @Input() class = '';
  @Input() isWide = false;

  @HostBinding('class') get getClasses(): string {
    const isWide = this.isWide ? 'uni-widget-content-wide' : '';
    return `${this.class} ${isWide}`;
  }
}
