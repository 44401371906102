import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { CampaignTemplate, CampaignTemplatesFilters, CampaignTemplatesParams } from '../../shared/uni-campaign.model';
import { SmsCounter } from '../../../../shared/uni-sms-counter.utils';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';

import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';

const ARABIC_RANGE = /[\u0621-\u064A]/g;

@Component({
  selector: 'cc-campaign-message',
  templateUrl: './campaign-message.component.html',
  styleUrls: ['./campaign-message.component.scss'],
})
export class CampaignMessageComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() isActive = true;
  @Input() isEditable = true;
  @Input() set isInTrialMode(value: boolean) {
    if (value === true && this._isInTrialMode === false) {
      this.trialTemplateNames?.length
        ? this.setDefaultTrialTemplate()
        : this.campaignFacade.setCampaignTrialTemplates();
    }

    this._isInTrialMode = value;
  };

  templateForm = this.campaignFormFactory.buildTemplateForm();
  filters = CampaignTemplatesFilters;
  maxLength = 0;
  body = '';
  smsCounter: SmsCounter.Counter;
  smsContent: string;
  userTemplateNames: CampaignTemplate[] = [];
  trialTemplateNames: CampaignTemplate[] = [];
  isChanged = false;
  isModalActive = false;
  isModalTemplateActive = false;
  savedTemplate = '';
  searchValue = '';
  isEmpty = isEmpty;
  isTextBoxForcedToRtl = false;

  private _isInTrialMode = false;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get isChooseTemplatesVisible(): boolean {
    return this._isInTrialMode || this.isEditable;
  }

  get isChooseTemplatesDisabled(): boolean {
    if (this._isInTrialMode) {
      return false;
    }

    return isEmpty(this.userTemplateNames) || this.contentForm.disabled;
  }

  get senderNameControl(): FormControl {
    return this.contentForm.get('senderName') as FormControl;
  }

  get contentControl(): FormControl {
    return this.contentForm.get('content') as FormControl;
  }

  get templateControl(): FormControl {
    return this.contentForm.get('templateName') as FormControl;
  }

  get templateNameControl(): FormControl {
    return this.templateForm.get('templateName') as FormControl;
  }

  get newTemplateNameControl(): FormControl {
    return this.templateForm.get('newTemplateName') as FormControl;
  }

  get templateNames() {
    return this._isInTrialMode ? this.trialTemplateNames : this.userTemplateNames;
  }

  get modalPlaceholder(): string {
    return this.templateNames?.length ? 'templateNamePlaceholder' : 'noSavedTemplates';
  }

  constructor(
    private campaignFormFactory: UniCampaignFormFactory,
    private campaignFacade: UniCampaignsFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.setCampaignTemplates();
    this.subs.add(
      this.initSmsCounter(),
      this.selectTemplate(),
      this.getTemplates(),
      this.getTrialTemplates(),
    );
    this.checkArabicCharacters(this.contentControl.value);
  }

  getTemplates(): Subscription {
    return this.campaignFacade.campaignTemplates$
      .subscribe(data => {
        this.userTemplateNames = data;
        this.setSavedTemplate();
      });
  }

  getTrialTemplates(): Subscription {
    return this.campaignFacade.campaignTrialTemplates$
      .subscribe(templates => {
        this.trialTemplateNames = templates;

        if (this._isInTrialMode && templates?.length) {
          this.setDefaultTrialTemplate();
        }
      });
  }

  selectTemplate(): Subscription {
    return this.templateNameControl.valueChanges
      .pipe(
        debounceTime(300)
      )
      .subscribe((value) => {
        if (value.name) {
          this.templateControl.setValue(value.name);
        } else if (value !== this.searchValue) {
          const params: CampaignTemplatesParams = {
            [this.filters.namePartial]: value
          };

          this.searchValue = value;

          this.setCampaignTemplates(params);
        }
      });
  }

  initSmsCounter(): Subscription {

    return this.contentControl.valueChanges.subscribe(value => {
      this.smsCounter = SmsCounter.init(value);
      this.trackChanges(value);
      this.smsContent = value;

      this.checkArabicCharacters(value);

      if (this.smsCounter.smsLength > 10) {
        this.contentControl.setErrors({ tooLongSms: true });
      } else {
        this.contentControl.setErrors(null);

        if (this.maxLength !== this.smsCounter.maxLength) {
          this.updateValidators(this.smsCounter.maxLength);
        }
      }
    });
  }

  setSavedTemplate() {
    if (this.savedTemplate.length > 0) {
      this.userTemplateNames.forEach((item) => {
        if (item.name === this.savedTemplate) {
          this.savedTemplate = '';
          this.templateNameControl.setValue(item);
          return;
        }
      });
    }
  }

  setActiveState(state: number): void {
    state === 4
      ? this.contentControl.enable()
      : this.contentControl.disable();
  }

  setCampaignTemplates(params: CampaignTemplatesParams = {}) {
    this.campaignFacade.setCampaignTemplates(params);
  }

  isSubmitted() {
    this.savedTemplate = this.newTemplateNameControl.value;
  }

  trackChanges(value: string) {
    this.isChanged = !(this.body === value);
  }

  useTemplate(template: CampaignTemplate) {
    if (this.savedTemplate.length > 0) {
      return;
    }

    this.isChanged = false;
    this.body = template ? template.body : '';

    this.contentControl.setValue(this.body);
  }

  updateValidators(length: number) {
    this.contentControl.setValidators([
      Validators.required,
      Validators.maxLength(length),
    ]);

    this.contentControl.updateValueAndValidity({ emitEvent: false });
  }

  changeIsModalActive(isActive: boolean): void {
    this.isModalActive = isActive;
  }

  changeIsModalTemplateActive(isActive: boolean): void {
    this.isModalTemplateActive = isActive;
  }

  applyTemplate(){
    const templateName = this.templateNameControl.value;
    this.useTemplate(templateName);
  }

  private checkArabicCharacters(value?: string): void {
    this.toggleRtlTextBox(value && !!value.match(ARABIC_RANGE));
  }

  private toggleRtlTextBox(isActive: boolean): void {
    this.isTextBoxForcedToRtl = isActive;
  }

  private setDefaultTrialTemplate(): void {
    const alreadySetTemplateIndex = this.trialTemplateNames.findIndex(t => t.body === this.contentControl.value);
    const indexToBeSet = alreadySetTemplateIndex >= 0 ? alreadySetTemplateIndex : 0;

    this.templateNameControl.setValue(this.trialTemplateNames[indexToBeSet]);

    this.applyTemplate();
  }

}
