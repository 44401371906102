import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { UniPipesModule } from '../../pipes/pipes.module';
import { UniFormModule } from '../uni-form';
import { UniLayoutModule } from '../uni-layout';
import { UniTimezonesAutocompleteComponent } from './components';
import { UniTimezonesFacade } from './shared/uni-timezones.facade';
import { UniTimezonesRepository } from './shared/uni-timezones.repository';
import { TimezonesEffects, timezonesReducer } from './store/uni-timezones';

const components = [
  UniTimezonesAutocompleteComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    UniFormModule,
    UniPipesModule,
    TranslateModule,
    UniLayoutModule,
    StoreModule.forFeature('timezones', timezonesReducer),
    EffectsModule.forFeature([TimezonesEffects])
  ],
  providers: [
    UniTimezonesRepository,
    UniTimezonesFacade,
  ]
})
export class UniTimezonesModule { }
