import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UniNavFacade } from '../../../uni-nav';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { AccountsFacade } from '../../../../shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UniTextColor } from '../../../uni-text';

@Component({
  selector: 'uni-uni-top-bar-troubleshoot-confirm',
  templateUrl: './uni-top-bar-troubleshoot-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTopBarTroubleshootConfirmComponent {
  uniTextColor = UniTextColor;
  isTroubleshootModalActive$ = this.uniNavFacade.isTroubleshootConfirmationOpen$;
  isTroubleshootingEnabled$: Observable<boolean> = this.uniAuthFacade.userMe$.pipe(
    map(userMe => userMe?.user?.account?.troubleshootingEnabled),
    tap(isTroubleshootingEnabled => this.isTroubleshootingEnabled = isTroubleshootingEnabled),
  );

  confirmLabel$: Observable<string> = this.isTroubleshootingEnabled$.pipe(
    map(enabled => enabled ? 'troubleshooting.action.disableTroubleshootMode' : 'troubleshooting.action.enableTroubleshootMode'),
  );
  confirmType$: Observable<'default' | 'danger'> = this.isTroubleshootingEnabled$.pipe(
    map(enabled => enabled ? 'danger' : 'default'),
  );

  isTroubleshootingEnabled = false;

  constructor(
    private uniNavFacade: UniNavFacade,
    private uniAuthFacade: UniAuthFacade,
    private accountsFacade: AccountsFacade,
  ) {}

  closeTroubleshootModal(): void {
    this.uniNavFacade.closeTroubleshootConfirmation();
  }

  confirmTroubleshootMode(): void {
    if (!this.uniAuthFacade.user.account) {
      return;
    }

    const accountId = this.uniAuthFacade.user.account.id;
    this.accountsFacade.putAccountTroubleshootingMode(accountId, !this.isTroubleshootingEnabled);
  }
}
