import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniFiltersButtonsComponent } from './components/uni-filters-buttons/uni-filters-buttons.component';
import { UniFiltersFieldsComponent } from './components/uni-filters-fields/uni-filters-fields.component';
import { UniFiltersItemComponent } from './components/uni-filters-item/uni-filters-item.component';
import { UniFiltersComponent } from './components/uni-filters/uni-filters.component';

const components = [
  UniFiltersButtonsComponent,
  UniFiltersItemComponent,
  UniFiltersComponent,
  UniFiltersFieldsComponent,
];

@NgModule({
  imports: [TranslateModule],
  declarations: [...components],
  exports: [...components],
})
export class UniFiltersModule { }
