import { SettingsService } from '../../../shared';
import { TokenInterceptor } from './uni-auth-token.interceptor';
import { UniAuthRepository } from './uni-auth.repository';
import { UniAuthService } from './uni-auth.service';

export function TokenInterceptorFactory() {
  return (
    authService: UniAuthService,
    authRepository: UniAuthRepository,
    settingsService: SettingsService,
  ) => new TokenInterceptor(
    authService,
    authRepository,
    settingsService,
  );
}
