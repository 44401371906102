import { get, isEqual, subtract } from 'lodash';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CampaignDataSource, CampaignFile } from '../../shared/uni-campaign.model';
import { UniCampaignRepository } from '../../shared/uni-campaigns.repository';
import { UniSnackbarFacade } from '../../../uni-snackbar/shared/uni-snackbar.facade';
import { UniLayoutFacade } from '../../../uni-layout/shared/uni-layout.facade';
import { ValidationUtils } from '../../../../utils/validation.utils';
import { UniCampaignFormFactory } from '../../shared';

@Component({
  selector: 'cc-campaign-file',
  templateUrl: './campaign-file.component.html',
})
export class CampaignFileComponent {
  @Input() form: FormGroup;
  @Input() isEditable = true;
  @ViewChild('file', { static: false }) file: any;
  recipientsLimit = this.campaignFormFactory.recipientsLimit;
  hasFileLimitError = false;
  isInvalidEncoding = false;

  get fileControl(): FormControl {
    return this.form.get('file') as FormControl;
  }

  get fileNameControl(): FormControl {
    return this.form.get('fileName') as FormControl;
  }

  get fileResponseControl(): FormControl {
    return this.form.get('fileResponse') as FormControl;
  }

  get isAutocompleteActiveControl(): FormControl {
    return this.form.get('isAutocompleteActive') as FormControl;
  }

  get autocompleteControl(): FormControl {
    return this.form.get('autocomplete') as FormControl;
  }

  get recepientsNumber(): number {
    return get(this.fileResponseControl.value, 'recipientsCount') || 0;
  }

  get totalRecepients(): number {
    return get(this.fileResponseControl.value, 'totalCount') || 0;
  }

  get rejectedRecepients(): number {
    return subtract(this.totalRecepients, this.recepientsNumber) || 0;
  }

  get allRecepientsAdded(): boolean {
    return isEqual(this.totalRecepients, this.recepientsNumber);
  }

  get allRecepientsRejected(): boolean {
    return  this.recepientsNumber === 0;
  }

  get showFailedRecepientsCount(): boolean {
    return !!(this.recepientsNumber && this.recepientsNumber < this.totalRecepients);
  }

  constructor(
    private campaignsRepository: UniCampaignRepository,
    private uniSnackbarFacade: UniSnackbarFacade,
    private uniLayoutFacade: UniLayoutFacade,
    private campaignFormFactory: UniCampaignFormFactory,
  ) {}

  onFilesAdded() {
    this.uniLayoutFacade.setIsLoader(true);
    this.hasFileLimitError = false;
    this.isInvalidEncoding = false;
    this.campaignsRepository
      .postCampaignFile(this.fileControl.value, CampaignDataSource.fileToSMS)
      .subscribe(
        (data) => this.onPostFileSuccess(data),
        (error) => this.onPostFileError(error),
      );
  }

  onPostFileSuccess(campaignFile: CampaignFile): void {
    const fileName = get(this.fileControl.value, 'name');
    this.hasFileLimitError = false;
    this.isInvalidEncoding = false;
    this.fileResponseControl.setValue(campaignFile);
    this.fileNameControl.setValue(fileName);
    this.uniLayoutFacade.setIsLoader(false);
  }

  onPostFileError(error: any) {
    this.uniLayoutFacade.setIsLoader(false);
    const errorMessage = ValidationUtils.getViolationError(error);
    this.hasFileLimitError = errorMessage.includes('tooManyRowsInXlsXlsx');
    this.isInvalidEncoding = errorMessage.includes('invalidEncoding');
    if(!this.hasFileLimitError && !this.isInvalidEncoding) {
      this.uniSnackbarFacade.show('error', errorMessage,ValidationUtils.getParams(error));
    }

    const file = get(this.file, 'file.nativeElement');

    if (file) {
      file.value = '';
      this.fileControl.reset();
    }
  }
}
