import * as moment from 'moment';

export const months = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export function getCardYears() {
  let currentYear = moment().year();
  const maxYear = moment().add(19, 'years').year();
  const years = [];

  while (maxYear >= currentYear) {
    years.push(String(currentYear).substring(2, 4));
    currentYear++;
  }

  return years;
}
