import { Component, HostBinding, Input } from '@angular/core';
import { ButtonSize } from '../uni-button/uni-button.model';

@Component({
  selector: 'uni-button-group',
  templateUrl: './uni-button-group.component.html',
  styleUrls: [
    './uni-button-group.component.scss',
  ],
})
export class UniButtonGroupComponent {
  @Input() size: ButtonSize = ButtonSize.medium;

  @HostBinding('class') get getClasses(): string {
    return [
      `uni-button-group--${this.size}`,
    ].join(' ');
  }
}
