import { get, isEmpty } from 'lodash';
import { split } from 'split-sms';

export namespace SmsCounter {
  export enum CharacterSet {
    GSM = 'GSM',
    Unicode = 'Unicode',
  }

  export interface Counter {
    characterSet: CharacterSet;
    maxLength: number;
    smsLength: number;
    contentLength: number;
    remainingInPart: number;
  }

  export function init(content: string = ''): Counter {
    // See: https://unifonic.atlassian.net/browse/UCCC-4606
    const normalizedContent = content ? content.replace(/(\r\n|\n|\r)/g, '  ') : content;
    const normalizedContentLength = get(normalizedContent, 'length');

    if (!normalizedContentLength) {
      return {
        characterSet: CharacterSet.GSM,
        maxLength: 1530,
        smsLength: 0,
        contentLength: 0,
        remainingInPart: 0,
      };
    }

    const sms = split(normalizedContent);
    const parts = get(sms, 'parts');
    const bytes = get(sms, 'bytes');
    const characterSet = get(sms, 'characterSet');
    const remaining = get(sms, 'remainingInPart');

    switch (characterSet) {
      case CharacterSet.GSM:
        return {
          characterSet,
          maxLength: 1530,
          smsLength: !isEmpty(parts) ? parts.length : 0,
          remainingInPart: remaining,
          contentLength: bytes,
        };
      default:
        return {
          characterSet,
          maxLength: 670,
          smsLength: !isEmpty(parts) ? parts.length : 0,
          remainingInPart: remaining,
          contentLength: normalizedContentLength,
        };
    }
  }
}
