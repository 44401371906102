import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map, tap } from "rxjs/operators";
import { DateRangeMockStore } from "src/app/shared/store-mocks/date-range.store";
import { UniAuthFacade, momentTimezone } from "unifonic-spa-common/src";


type State = {
  date: string
}

const initialState: State = {
  date: 'last7d'
}

@Injectable()
export class FilterPanelDateStore extends ComponentStore<State>{
    constructor(
        private dateRangeMockStore: DateRangeMockStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //auth selectors
    readonly timezone$ = this.authFacade.userMe$.pipe(
        map((user: any) => user.user.timezone)
    )

    readonly date$ = this.select(state => state.date)

    //selectors
    readonly entities$ = this.select(
        this.dateRangeMockStore.today$,
        this.dateRangeMockStore.last7d$,
        this.dateRangeMockStore.last30d$,
        this.dateRangeMockStore.this1w$,
        this.dateRangeMockStore.this1m$,
        this.dateRangeMockStore.last1m$,
        this.dateRangeMockStore.selectDate$,
        (...entities) => {
            return [
                ...entities
            ]
        }
    )

    readonly vm$ = this.select(
        this.entities$,
        this.dateRangeMockStore.defaultDateRange$,
        this.date$,
        this.timezone$,
        (periodEntities, defaultDateRange, currentDate, timezone) => {
            return {
                periodEntities,
                defaultDateRange,
                defaultPeriod: 'last7d',
                currentDate,
                timezone
            }
        }
    )


    // updaters
    readonly setEntities = this.updater((state, entities: string) => {
        return {...state, entities}
    })


}

