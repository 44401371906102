<uni-icon
  name="check-circle"
  class="uni-text-result__icon"
></uni-icon>
<uni-text-body
  [weight]="fontWeight"
  [color]="fontColor"
  [isUppercase]="false"
>
  <ng-content></ng-content>
</uni-text-body>
