import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SettingsService } from '../../../shared/settings/settings.service';
import { UniAuthRepository } from './uni-auth.repository';
import { UniAuthService } from './uni-auth.service';
import { isEmpty } from 'lodash';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject = new BehaviorSubject<string>(null);
  settings = this.settingsService.settings;

  constructor(
    private authService: UniAuthService,
    private authRepository: UniAuthRepository,
    public settingsService: SettingsService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.settings = this.settingsService.settings;

    request = request.clone({
      withCredentials: !request.url.includes('/protocol/openid-connect/token')
    });

    if (
      request.url.endsWith('oauth/v2/token')
      || request.url.endsWith('profile/switch-locale')
      || request.url.includes('slack.com/api')
      || (isEmpty(this.settings) && request.url.includes('conv'))
    ) {
      return next.handle(request)
        .pipe(
          catchError(err => {

          if (err instanceof HttpErrorResponse) {
            if (
              err.status === 201
              || err.status === 401
              || err.status === 302
            ) {
              this.authService.logout();
            } else if (
              err.status === 403
              && !request.url.includes('conv')
            ) {
              this.authService.handleForbiddenAccess();
            }
          }

          return throwError(err);
      }));
    }
    if (request.url.includes('/api/keys')) {
      const accessToken = localStorage.getItem('access_token');
      return next.handle(this.addKeycloakToken(request, accessToken))
        .pipe(
          catchError(err => {

            if (err instanceof HttpErrorResponse) {
              if (
                err.status === 201
                || err.status === 401
                || err.status === 302
              ) {
                this.authService.logout();
              } else if (err.status === 403) {
                this.authService.handleForbiddenAccess();
              }
            }

            return throwError(err);
          }));
    }

    // @TODO: remove the code below.
    // I left it for now in case any project still uses it
    // - we can restore it then I think about better solution
    return next.handle(this.addToken(request, this.authService.getToken()))
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 201:
              case 401: {
                return this.handleUnauthorized(request, next);
              }
              default: {
                return throwError(error);
              }
            }
          } else {
            return throwError(error);
          }
        })
      );
  }

  addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const whiteList = [ 'facebook.com' ];

    if (new RegExp(whiteList.join('|')).test(request.url)) {
      return request;
    }

    if (!token) {
      return request;
    }

    return request.clone({ setHeaders: {
      'x-uc-auth-token': `Bearer ${ token }`,
      Authorization: `Bearer ${ token }`,
    }});
  }

  addKeycloakToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!token) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
  }

  handleUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.authRepository
        .refreshToken(this.settings, this.authService.getRefreshToken())
        .pipe(
          switchMap(({ access_token, refresh_token }: Record<string, string>) => {
            if (!!access_token) {
              this.tokenSubject.next(access_token);
              this.authService.saveTokens(access_token, refresh_token);
              return next.handle(this.addToken(req, access_token));
            }

            this.authService.logout();

            return of(false);
          }),
          catchError(() => {
            this.authService.logout();
            return of(false);
          }),
          finalize(() => this.isRefreshingToken = false)
        );
    } else {
      return this.tokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => next.handle(this.addToken(req, token)))
        );
    }
  }
}
