import { Component, HostBinding, Input } from '@angular/core';
import { ButtonSize, ButtonStyle, ButtonType } from '../uni-button/uni-button.model';

@Component({
  selector: 'uni-button-icon',
  templateUrl: './uni-button-icon.component.html',
  styleUrls: [
    './uni-button-icon.component.scss',
    './uni-button-icon-sizes.component.scss',
    './uni-button-icon-types.component.scss',
  ],
})
export class UniButtonIconComponent {
  @Input() disabled = false;
  @Input() isSelected = false;
  @Input() style = ButtonStyle.primary;
  @Input() type: ButtonType = 'button';
  @Input() size: ButtonSize = ButtonSize.medium;
  @Input() icon: string;

  @HostBinding('class') get getClasses(): string {
    return [
      `uni-button-icon--${this.size}`,'',
    ].join(' ');
  }

  getIconSize(size): string {
    switch (size) {
      case ButtonSize.large: {
        return '32';
      }
      case ButtonSize.small: {
        return '20';
      }
      case ButtonSize.extraSmall: {
        return '16';
      }
      default: {
        return '24';
      }
    }
  }
}
