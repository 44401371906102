import { concat, of } from 'rxjs';
import { catchError, mapTo, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { QueryActions } from '../../../store/query';

import { UniAudienceRepository } from '../shared/uni-audience.repository';
import * as UniAudienceActions from './uni-audience.actions';
import {
  GET_GROUP_QUERY,
  GET_GROUPS_QUERY,
  DELETE_GROUP_QUERY,
  GET_DYNAMIC_GROUP_QUERY,
  GET_DYNAMIC_GROUPS_QUERY,
  DELETE_DYNAMIC_GROUP_QUERY
} from './uni-audience.state';
import { uniSnackbarActions } from '../../uni-snackbar';

@Injectable()
export class UniAudienceEffects {
  constructor(
    private route: ActivatedRoute,
    private actions$: Actions,
    private uniAudienceRepository: UniAudienceRepository,
  ) { }

  @Effect() setUniGroup$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.SetUniGroup>(UniAudienceActions.SET_UNI_GROUP),
      mergeMap(({ id }) => concat(
        of(new QueryActions.QueryInProgress(GET_GROUP_QUERY)),
        this.uniAudienceRepository
          .getGroup(id)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_GROUP_QUERY, data)),
              of(new UniAudienceActions.SetUniGroupSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_GROUP_QUERY, error)),
            )),
          ),
        ),
      ),
    );

  @Effect() setUniGroups$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.SetUniGroups>(UniAudienceActions.SET_UNI_GROUPS),
      mergeMap(({ params, page }) => concat(
        of(new QueryActions.QueryInProgress(GET_GROUPS_QUERY)),
        this.uniAudienceRepository
          .getGroups(params, page)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_GROUPS_QUERY, res.body)),
              of(new UniAudienceActions.SetUniGroupsSuccess(res.body, res.headers)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_GROUPS_QUERY, error)),
            )),
          ),
        ),
      ),
    );

  @Effect() deleteGroup$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.DeleteUniGroup>(UniAudienceActions.DELETE_UNI_GROUP),
      mergeMap(({ ids }) => concat(
        of(new QueryActions.QueryInProgress(DELETE_GROUP_QUERY)),
        this.uniAudienceRepository
          .deleteGroup(ids)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_GROUP_QUERY, data)),
              of(new UniAudienceActions.DeleteUniGroupSuccess(ids)),
              of(new uniSnackbarActions.NewSnackbar('success', 'audience.successGroupDelete')),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(DELETE_GROUP_QUERY, error)),
              of(new UniAudienceActions.DeleteUniGroupError()),
              of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDelete')),
            )),
          ),
        ),
      ),
    );

  @Effect() deleteGroupSuccess$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.DeleteUniGroupSuccess>(UniAudienceActions.DELETE_UNI_GROUP_SUCCESS),
      mapTo(new UniAudienceActions.SetUniGroups(this.route.snapshot.queryParams))
    );

  @Effect() setDynamicUniGroup$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.SetUniDynamicGroup>(UniAudienceActions.SET_UNI_DYNAMIC_GROUP),
      mergeMap(({ id }) => concat(
        of(new QueryActions.QueryInProgress(GET_DYNAMIC_GROUP_QUERY)),
        this.uniAudienceRepository
          .getDynamicGroup(id)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_DYNAMIC_GROUP_QUERY, data)),
              of(new UniAudienceActions.SetUniDynamicGroupSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_DYNAMIC_GROUP_QUERY, error)),
            )),
          ),
        ),
      ),
    );

  @Effect() setUniDynamicGroups$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.SetUniDynamicGroups>(UniAudienceActions.SET_UNI_DYNAMIC_GROUPS),
      mergeMap(({ params, page }) => concat(
        of(new QueryActions.QueryInProgress(GET_DYNAMIC_GROUPS_QUERY)),
        this.uniAudienceRepository
          .getDynamicGroups(params, page)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_DYNAMIC_GROUPS_QUERY, res.body)),
              of(new UniAudienceActions.SetUniDynamicGroupsSuccess(res.body, res.headers)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_DYNAMIC_GROUPS_QUERY, error)),
            )),
          ),
        ),
      ),
    );

  @Effect() deleteDynamicGroup$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.DeleteUniDynamicGroup>(UniAudienceActions.DELETE_UNI_DYNAMIC_GROUP),
      mergeMap(({ ids }) => concat(
        of(new QueryActions.QueryInProgress(DELETE_DYNAMIC_GROUP_QUERY)),
        this.uniAudienceRepository
          .deleteDynamicGroup(ids)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(DELETE_DYNAMIC_GROUP_QUERY, data)),
              of(new UniAudienceActions.DeleteUniDynamicGroupSuccess(ids)),
              of(new uniSnackbarActions.NewSnackbar('success', 'audience.successGroupDelete')),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(DELETE_DYNAMIC_GROUP_QUERY, error)),
              of(new UniAudienceActions.DeleteUniDynamicGroupError()),
              of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDelete')),
            )),
          ),
        ),
      ),
    );

  @Effect() deleteDynamicGroupSuccess$ = this.actions$
    .pipe(
      ofType<UniAudienceActions.DeleteUniDynamicGroupSuccess>(UniAudienceActions.DELETE_UNI_DYNAMIC_GROUP_SUCCESS),
      mapTo(new UniAudienceActions.SetUniDynamicGroups(this.route.snapshot.queryParams))
    );
}
