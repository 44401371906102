import { find, get } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { isQueryInProgress } from '../../../../store';
import { AutoUnsubscribe } from '../../../../utils';
import { UniCountriesFacade } from '../../shared/uni-countries.facade';
import { Country } from '../../shared/uni-countries.model';

@Component({
  selector: 'uni-countries-autocomplete',
  templateUrl: './uni-countries-autocomplete.component.html',
  styleUrls: ['./uni-countries-autocomplete.component.scss']
})
export class UniCountriesAutocompleteComponent extends AutoUnsubscribe implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
  @Input() form: FormGroup;
  @Input() margin = true;
  @Input() required = false;
  @Input() formControlName = 'country';
  @Input() defaultCountry: Country;
  @Input() isPublic = false;

  defaultCountryName: string;
  countries: Country[];
  filteredCountries: Observable<Country[]>;
  isLoaderActive = false;
  isPanelOpen = false;

  get countryNameControl() {
    return this.form.get(`${this.formControlName}.name`);
  }

  get countryCodeControl() {
    return this.form.get(`${this.formControlName}.code`);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private uniCountriesFacade: UniCountriesFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.setCountries();

    this.subs.add(
      this.setLoaderStatus(),
      this.selectCountries()
    );
  }

  setCountries(): void {
    this.uniCountriesFacade.setCountries(this.isPublic);
  }

  setLoaderStatus(): Subscription {
    return this.uniCountriesFacade.countriesQuery$.subscribe(query => this.isLoaderActive = isQueryInProgress(query));
  }

  selectCountries(): Subscription {
    return this.uniCountriesFacade.countries$
      .pipe(filter(data => !!data && !!data.length))
      .subscribe(countries => {
        this.countries = countries;

        if (!!this.defaultCountry) {
          this.defaultCountryName = this.getCountryNameByCode(this.defaultCountry.code);
        }

        this.filteredCountries = this.getFilteredCountriesStream();
        this.cdr.detectChanges();
      });
  }

  getFilteredCountriesStream(): Observable<Country[]> {
    return this.countryNameControl.valueChanges
      .pipe(
        startWith(this.getCountryNameByCode(this.countryCodeControl.value)),
        map(name => this.getFilteredCountries(name))
      );
  }

  getCountryNameByCode(code: string) {
    return !!code
      ? get(find(this.countries, { code }), 'name')
      : '';
  }

  getFilteredCountries(name = ''): Country[] {
    const filterValue = name.toLowerCase();
    const matchedCountry: Country = find(this.countries, { name });

    if (this.countryNameControl.value !== name) {
      this.countryNameControl.setValue(name);
    }

    if (!!matchedCountry) {
      this.countryCodeControl.setValue(matchedCountry.code);
    }

    return this.countries.filter(country => country.name.toLowerCase().indexOf(filterValue) === 0);
  }

  clearAutocomplete(): void {
    setTimeout(() => {
      this.countryNameControl.setValue('');
      this.countryCodeControl.setValue('');
      this.autocompleteTrigger.openPanel();
    }, 100);
  }

  onPanelClose(): void {
    this.isPanelOpen = false;

    if (!this.countryNameControl.value.length) {
      this.countryNameControl.setValue(this.defaultCountryName);
    }
  }
}
