import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[uniClickOutside]'
})
export class UniClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}

  @Output() uniClickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const isCalenderOpen = document.querySelector('sat-calendar');

    if (!targetElement || isCalenderOpen) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    if (!clickedInside) {
      this.uniClickOutside.emit(event);
    }
  }
}
