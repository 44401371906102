import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Industry } from './uni-industry.model';

@Injectable()
export class UniIndustryRepository {
  apuUrl = `app/api/v2/public`;

  constructor(private http: HttpClient) {}

  getIndustries(): Observable<Industry[]>  {
    return this.http.get<Industry[]>(`${this.apuUrl}/industries/keys`);
  }
}
