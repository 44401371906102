<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-section-header>
    <uni-text-caption>
      {{ 'campaign.new.tip.autocomplete.title' | translate }}
    </uni-text-caption>
  </uni-section-header>
  <uni-modal-content [margin]="false">
    <uni-section [isFirst]="true">
      {{ 'campaign.new.tip.autocomplete.description' | translate }}
    </uni-section>
    <uni-section>
      {{ 'campaign.new.tip.autocomplete.example-input' | translate }}
      {{ 'campaign.new.tip.autocomplete.example-output' | translate }}
    </uni-section>
    <uni-section>
      {{ 'campaign.new.tip.autocomplete.note' | translate }}
    </uni-section>
    <div class="campaign-recipients-autocomplete-modal--medium">
      {{ 'campaign.new.tip.autocomplete.warning' | translate }}
    </div>
  </uni-modal-content>
</uni-modal>
