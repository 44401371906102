<button
  class="uni-button-icon"
  [ngClass]="
    [
      'uni-button-icon--' + style,
      disabled ? 'is-disabled' : '',
      isSelected ? 'is-selected' : '',
    ]"
  [disabled]="disabled"
  [type]="type"
>
  <uni-icon
    [name]="icon"
    [size]="getIconSize(size)"
    [isInherit]="true"
  ></uni-icon>
</button>
