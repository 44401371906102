<cc-campaign-progress-bar
  [succeed]="getPercentage(succeedCount, campaign.recipientsCount)"
  [failed]="getPercentage(failedCount, campaign.recipientsCount)"
></cc-campaign-progress-bar>
<uni-details>
  <uni-details-item>
    <uni-label>
      {{ 'label.progress' | translate }}
      <uni-tooltip uniLabelTip>
        {{ 'campaign.tip.progress' | translate }}
      </uni-tooltip>
    </uni-label>
    <cc-campaign-detail [percent]="getTotalPercentage(campaign)">
      {{ getTotal(progressCount, campaign.recipientsCount) }}
    </cc-campaign-detail>
  </uni-details-item>
  <uni-details-item>
    <uni-label>
      {{ 'label.succeed' | translate }}
      <uni-tooltip uniLabelTip>
        {{ 'campaign.tip.succeed' | translate }}
      </uni-tooltip>
    </uni-label>
    <cc-campaign-detail
      status="succeed"
      [percent]="getPercentage(succeedCount, campaign.recipientsCount)"
    >
      {{ getTotal(succeedCount, campaign.recipientsCount) }}
    </cc-campaign-detail>
  </uni-details-item>
  <uni-details-item>
    <uni-label>
      {{ 'label.failed' | translate }}
      <uni-tooltip uniLabelTip>
        {{ 'campaign.tip.failed' | translate }}
      </uni-tooltip>
    </uni-label>
    <cc-campaign-detail
      status="failed"
      [percent]="getPercentage(failedCount, campaign.recipientsCount)"
    >
      {{ getTotal(failedCount, campaign.recipientsCount) }}
    </cc-campaign-detail>
  </uni-details-item>
</uni-details>
