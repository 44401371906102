import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UniButtonComponent } from './components/uni-button/uni-button.component';
import { UniButtonsComponent } from './components/uni-buttons/uni-buttons.component';
import { UniTextModule } from '../uni-text/uni-text.module';
import { UniIconModule } from '../uni-icon/uni-icon.module';
import { UniButtonIconComponent } from './components/uni-button-icon/uni-button-icon.component';
import { UniButtonGroupComponent } from './components/uni-button-group/uni-button-group.component';

const components = [
  UniButtonComponent,
  UniButtonsComponent,
  UniButtonIconComponent,
  UniButtonGroupComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    UniTextModule,
    UniIconModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniButtonModule { }
