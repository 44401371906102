import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-table-filter-header',
  templateUrl: './uni-table-filter-header.component.html',
  styleUrls: ['./uni-table-filter-header.component.scss']
})
export class UniTableFilterHeaderComponent {
  @Input() heading: string;
  @Input() hasFilters = false;
  @Output() open = new EventEmitter();

  onOpen(): void {
    this.open.next();
  }
}
