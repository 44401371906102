import { Component, Output, EventEmitter, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-dropdown',
  templateUrl: './uni-dropdown.component.html',
  styleUrls: ['./uni-dropdown.component.scss'],
})
export class UniDropdownComponent {
  @Output() isActiveChange = new EventEmitter();

  @HostBinding('class.is-active') @Input() isActive = true;

  hideDropdown() {
    this.isActiveChange.emit(false);
  }
}
