import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UniFormInfoType } from '../../../uni-form/shared/uni-form.model';
import { UniTextColor, UniTextWeight } from '../../../uni-text/shared/uni-text.model';

@Component({
  selector: 'uni-campaign-csv-error',
  templateUrl: './campaign-csv-error.component.html',
  styleUrls: ['./campaign-csv-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignCsvErrorComponent {
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;
  uniFormInfoType = UniFormInfoType;
  @Input() errorMessage: string;
}
