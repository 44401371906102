import { Query } from '../../../store';
import { UniUser } from '../shared/uni-user.model';

export const GET_USERS_QUERY = 'getUsersQuery';

export interface UniUserState {
  users: UniUser[];
  usersAmount?: number;
  getUsersQuery: Query<UniUser>;
}

export const uniUserInitialState: UniUserState = {
  users: [],
  getUsersQuery: {},
};
