import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Environment } from '../../../utils';
import { UniLanguageService } from './uni-language.service';

@Injectable()
export class UniLanguageRepository {
  languageUrl = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/profile/switch-locale`;

  constructor(
    private http: HttpClient,
    private uniLanguageService: UniLanguageService,
    @Optional() @Inject('environment') public environment) {}

  postLanguage(locale: string): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('locale', locale);
    formData.append('no-redirect', '1');

    return this.http.post<any>(this.languageUrl, formData, {
      headers: {
        queryName: 'getActionTypes',
        queryGroups: [this.uniLanguageService.LANGUAGE_KEY]
      }
    });
  }
}
