import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-details',
  templateUrl: './uni-details.component.html',
  styleUrls: ['./uni-details.component.scss']
})
export class UniDetailsComponent {
  @HostBinding('class.column') @Input() column = false;
  @HostBinding('class.single-row') @Input() isRow = false;
}
