import { find, get } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { isQueryInProgress } from '../../../../store';
import { AutoUnsubscribe } from '../../../../utils';
import { Industry } from '../../shared';
import { UniIndustryFacade } from '../../shared/uni-industry.facade';

@Component({
  selector: 'uni-industry-autocomplete',
  templateUrl: './uni-industry-autocomplete.component.html',
  styleUrls: ['./uni-industry-autocomplete.component.scss']
})
export class UniIndustryAutocompleteComponent extends AutoUnsubscribe implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
  @Input() form: FormGroup;
  @Input() margin = true;
  @Input() required = false;
  @Input() formControlName = 'industry';

  industries: Industry[];
  filteredIndustries: Observable<Industry[]>;
  isLoaderActive = false;
  isPanelOpen = false;

  get industryNameControl() {
    return this.form.get(`${this.formControlName}.name`);
  }

  get industryKeyControl() {
    return this.form.get(`${this.formControlName}.key`);
  }


  constructor(
    private cdr: ChangeDetectorRef,
    private uniIndustriesFacade: UniIndustryFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.setIndustries();

    this.subs.add(
      this.setLoaderStatus(),
      this.selectIndstries()
    );
  }

  setIndustries(): void {
    this.uniIndustriesFacade.setIndustries();
  }

  setLoaderStatus(): Subscription {
    return this.uniIndustriesFacade.industriesQuery$.subscribe(query => this.isLoaderActive = isQueryInProgress(query));
  }

  selectIndstries(): Subscription {
    return this.uniIndustriesFacade.industries$
      .pipe(filter(data => !!data && !!data.length))
      .subscribe(industries => {
        this.industries = industries;
        this.filteredIndustries = this.getFilteredIndstriesStream();
        this.cdr.detectChanges();
      });
  }

  getFilteredIndstriesStream(): Observable<Industry[]> {
    return this.industryNameControl.valueChanges
      .pipe(
        startWith(this.getIndustryNameByKey(this.industryKeyControl.value)),
        map(name => this.getFilteredIndstries(name))
      );
  }

  getIndustryNameByKey(key: string) {
    return !!key
      ? get(find(this.industries, { key }), 'name')
      : '';
  }

  getFilteredIndstries(name = ''): Industry[] {
    const filterValue = name.toLowerCase();
    const matchedIndustry: Industry = find(this.industries, { name });

    if (this.industryNameControl.value !== name) {
      this.industryNameControl.setValue(name);
    }

    if (!!matchedIndustry) {
      this.industryKeyControl.setValue(matchedIndustry.key);
    }

    return this.industries.filter(industry => industry.name.toLowerCase().indexOf(filterValue) === 0);
  }

  clearAutocomplete(): void {
    setTimeout(() => {
      this.industryKeyControl.setValue('');
      this.industryNameControl.setValue('');
      this.autocompleteTrigger.openPanel();
    }, 100);
  }


  onPanelClose(): void {
    this.isPanelOpen = false;

    if (!this.industryNameControl.value.length) {
      this.industryNameControl.setValue('');
    }
  }
}
