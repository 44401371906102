import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { CampaignPieChartService } from './campaign-pie-chart.service';
import { CampaignChartData } from '../../shared/uni-campaign.model';

@Component({
  selector: 'cc-campaign-pie-chart',
  templateUrl: './campaign-pie-chart.component.html',
  styleUrls: ['./campaign-pie-chart.component.scss'],
})
export class CampaignPieChartComponent implements AfterViewInit {
  @Input() data: CampaignChartData;
  @Input() isBig = false;
  @ViewChild('chart', { static: false }) chartRef: ElementRef;
  chart: Chart;

  constructor(
    private chartService: CampaignPieChartService,
  ) {}

  ngAfterViewInit() {
    this.initChart(this.data);
  }

  initChart(data: CampaignChartData): void {
    this.chart = this.chartService.init(this.chartRef, data);
  }
}
