export interface CallerIdPost {
  accountId: string;
  type: CallerIdTypes;
  countryId: number;
}

export enum CallerIdTypes {
  numberMasking = 'number_masking',
  notification = 'notification',
  campaign = 'campaign',
}

export enum CallerIdPriority {
  primary = 'primary',
  secondary = 'secondary',
}

export enum CallerIdStatus {
  processing = 'processing',
  rejected = 'rejected',
  approved = 'approved',
}

export interface CallerId {
  accountId: string;
  country: string;
  type: CallerIdTypes;
  priority: CallerIdPriority;
  callerNumber: {
    id: string;
    phoneNumberId: string;
    phoneNumber: string;
  };
  id: string;
  status: CallerIdStatus;
  price?: number;
  currency?: string;
}

export interface CallerIdChannel {
  id: string;
  accountId: string;
  country: string;
  activationDate: string;
  amount: number;
  ocsChargingId: number;
  quantity: number;
  freeChannels: number;
  status: CallerIdSubscriptionStatus;
  chargingStatus: CallerIdSubscriptionChargingStatus;
  nextChargingDate: string;
  lastPriceChangeDate: string;
}

export interface CallerIdChannelConfigParams {
  countryCode: string;
}

export interface CallerIdChannelConfig {
  minChannels: number;
  maxChannels: number;
  channelPrice: number;
}

export interface CallerIdChannelChargesParams {
  countryCode?: string;
  quantity?: number;
}

export interface CallerIdChannelCharges {
  firstTimeCharges?: number;
  recurringCharges?: number;
  accountId?: string;
  totalChannels?: number;
  countryCode?: string;
}

export interface CallerIdChannelPost {
  country: string;
  quantity: number;
}

export enum CallerIdFilters {
  callerId = 'callerId',
  priority = 'priority',
  activationDate = 'activationDate',
  nextChargingDate = 'nextChargingDate',
  chargingStatus = 'chargingStatus',
  page = 'page',
  size = 'size',
}

export enum CallerIdSubscriptionStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum CallerIdSubscriptionChargingStatus {
  New = 'New',
  Pending = 'Pending',
  Failed = 'Failed',
  Completed = 'Completed',
  Refunded = 'Refunded',
  InsufficientBalance = 'InsufficientBalance',
  Denied = 'Denied',
}

export interface CallerIdSubscription {
  id: string;
  accountId: string;
  callerId: string;
  ocsChargingId: number;
  status: CallerIdSubscriptionStatus;
  chargingStatus: CallerIdSubscriptionChargingStatus;
  amount?: number;
  activationDate: string;
  nextChargingDate: string;
  lastPriceChangeDate?: string;
  priority?: CallerIdPriority;
}

export interface FirstCallerResponse {
  firstCaller: boolean;
}

export type PageableResponse<T> = {
  content: T[] | null;
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  pageable?: {sort: {sorted: boolean; unsorted: boolean; empty: boolean}; offset: number; pageSize: number; pageNumber: number };
  size?: number;
  sort?: {sorted: boolean; unsorted: boolean; empty: boolean};
  totalElements?: number;
  totalPages?: number;
} | null;
