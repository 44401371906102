import { Component, HostBinding, Input } from '@angular/core';

import { UniTagType } from './uni-tag.model';

@Component({
  selector: 'uni-tag',
  templateUrl: './uni-tag.component.html',
  styleUrls: ['./uni-tag.component.scss'],
})
export class UniTagComponent {
  @Input() class = '';
  @Input() type: UniTagType = UniTagType.default;

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-tag--${this.type}`;
  }
}
