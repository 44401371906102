import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UniDataState } from '../store/uni-data.state';
import * as UniDataActions from '../store/uni-data.actions';
import { selectActivePackages, selectPackages } from '../store/uni-data.selectors';

@Injectable({ providedIn: 'root' })
export class PackagesFacade {
  activePackages$ = this.store.pipe(select(selectActivePackages));
  packages$ = this.store.pipe(select(selectPackages));

  constructor(private store: Store<UniDataState>) {}

  setActivePackages(isLoader = true): void {
    this.store.dispatch(new UniDataActions.SetActivePackages());
  }

  setPackages(isLoader = true): void {
    this.store.dispatch(new UniDataActions.SetPackages(isLoader));
  }
}
