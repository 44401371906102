import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TreeNode, ITreeOptions } from '@circlon/angular-tree-component';

@Component({
  selector: 'uni-tree',
  templateUrl: './uni-tree.component.html',
})
export class UniTreeComponent {
  @Input() nodes: TreeNode[];
  @Output() change = new EventEmitter();

  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: true,
  };

  onChange(): void {
    this.change.emit();
  }
}
