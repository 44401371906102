<div class="campaign-progress-bar">
  <div
    class="campaign-progress-bar__bar campaign-progress-bar--succeed"
    [style.width.%]="succeed * 100"
  ></div>
  <div
    class="campaign-progress-bar__bar campaign-progress-bar--failed"
    [style.width.%]="failed * 100"
  ></div>
</div>
<div class="campaign-progress-bar__description">
  <div>{{ 'label.loadingMin' | translate }}</div>
  <div>{{ 'label.loadingMax' | translate }}</div>
</div>
