<uni-box-tabs class="campaign-tabs__container">
  <uni-box-tab
    [isActive]="activeTab === campaignTabs.overview"
    (click)="navigate()"
  >
    {{ 'label.overview' | translate }}
  </uni-box-tab>
  <uni-box-tab
    *ngIf="canViewReports"
    [isActive]="activeTab === campaignTabs.report"
    (click)="navigate(campaignTabs.report)"
    [isDisabled]="isDisabled"
  >
    {{ 'label.report' | translate }}
  </uni-box-tab>
</uni-box-tabs>
