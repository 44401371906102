<div [formGroup]="form">
  <uni-label [required]="required">
    {{ 'navigation.timezone' | translate }}
  </uni-label>
  <uni-form-field [margin]="margin">
    <mat-form-field>
      <input
        type="text"
        formControlName="name"
        [matAutocomplete]="autoGroup"
        [placeholder]="isLoaderActive ? ('label.loading' | translate) : ('label.selectTimezone' | translate)"
        #autocompleteTrigger
        matInput
        uniInput
      >
      <mat-autocomplete
        (opened)="isPanelOpen = true"
        (closed)="isPanelOpen = false"
        #autoGroup="matAutocomplete">
        <mat-optgroup *ngFor="let timezone of filteredTimezones" [label]="'timezone.region.' + timezone.region + 'Region' | translate">
          <mat-option *ngFor="let obj of timezone.names" [value]="obj.name">
            {{ obj.name }}
          </mat-option>
        </mat-optgroup>
        <mat-option *ngIf="isEmpty(filteredTimezones)" disabled>
          {{ 'label.noResults' | translate }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div uniSuffix>
      <uni-icon
        *ngIf="form.enabled && timezoneNameControl.value && timezoneNameControl.value.length; else arrowIcon"
        name="times"
        (click)="clearAutocomplete()">
      </uni-icon>
      <ng-template #arrowIcon>
        <uni-icon
          (click)="toggleAutocomplete(isPanelOpen)"
          [disabled]="form.disabled"
          [name]="isPanelOpen ? 'chevron-up' : 'chevron-down'"
        ></uni-icon>
      </ng-template>
    </div>
  </uni-form-field>
</div>
