<div class="uni-snackbar-item__content">
  {{ snackbar.message | translate:snackbar.property }}
  <span
    *ngIf="snackbar?.action"
    class="uni-snackbar-item__action"
    (click)="onAction()"
  >
    {{ snackbar.action.labelKey | translate }}
  </span>
</div>
