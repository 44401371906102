import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UniAuthModule } from '../uni-auth/uni-auth.module';
import { UniButtonModule } from '../uni-button';
import { UniIconModule } from '../uni-icon/uni-icon.module';
import { UniLayoutModule } from '../uni-layout/uni-layout.module';
import { UniPageComponent, UniPageContentComponent, UniPageLogoComponent } from './components';
import { UniPage403Component, UniPage404Component } from './containers';

const components = [
  UniPage404Component,
  UniPage403Component,
  UniPageComponent,
  UniPageContentComponent,
  UniPageLogoComponent,
];

@NgModule({
  imports: [
    RouterModule,
    UniLayoutModule,
    CommonModule,
    UniIconModule,
    UniButtonModule,
    TranslateModule,
    UniAuthModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniPagesModule { }
