import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniRolesState } from './uni-roles.state';

export const selectRolesFeature = createFeatureSelector<UniRolesState>('uniRoles');

export const selectRoles = createSelector(
  selectRolesFeature,
  (state: UniRolesState) => state.roles,
);

export const selectRolesQuery = createSelector(
  selectRolesFeature,
  (state: UniRolesState) => state.getRolesQuery,
);
