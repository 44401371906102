import * as QueryActions from './query.actions';
import { QueryStatus } from './query.model';

const { QUERY_ACTIONS } = QueryActions;

export const queryReducer = <S extends object>(queryNames: (keyof S)[], action: QueryActions.All) => {
  if ((queryNames as string[]).indexOf(action.queryName) < 0) {
    return {};
  }
  switch (action.type) {
    case QUERY_ACTIONS.INIT:
      return {
        [action.queryName]: {},
      };
    case QUERY_ACTIONS.IN_PROGRESS:
      return {
        [action.queryName]: {
          status: QueryStatus.InProgress,
        },
      };
    case QUERY_ACTIONS.SUCCESS:
      return {
        [action.queryName]: {
          status: QueryStatus.Success,
          response: action.response,
        },
      };
    case QUERY_ACTIONS.FAILURE:
      return {
        [action.queryName]: {
          status: QueryStatus.Failure,
          error: action.error,
        },
      };
    case QUERY_ACTIONS.CLEAR:
      return {
        [action.queryName]: {},
      };
    default:
      return {};
  }
};
