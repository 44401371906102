<uni-form-container>
  <div class="campaign-balance-estimation__price">
    <uni-plural
      [amount]="currentMessageUnits"
      entity="unit"
    >
      {{ currentMessageUnits }}
    </uni-plural>
    <div class="campaign-balance-estimation__tooltip">
      <uni-tooltip>
        {{ 'campaign.tip.summary' | translate }}
      </uni-tooltip>
    </div>
  </div>
  <uni-text-result>
    {{ getCalculation() }}
  </uni-text-result>
</uni-form-container>
<uni-box-info
  *ngIf="groupsControl"
  spacing="top"
>
  {{ 'campaign.new.recipients.info.dynamicGroups' | translate }}
</uni-box-info>
<uni-form-field
  *ngIf="isShowLowBalanceAlert"
  [margin]="false"
  class="campaign-balance-estimation-low-balance-alert"
>
  <uni-form-field-info [type]="uniFormInfoType.error" >
    <uni-text-body
      [isUppercase]="false"
      [weight]="uniTextWeight.regular"
      [color]="uniTextColor.black"
      [innerHTML]="'campaign.campaignLowBalanceAlert' | translate"
    >
    </uni-text-body>
  </uni-form-field-info>
</uni-form-field>
