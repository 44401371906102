import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'cc-campaign-retries',
  templateUrl: './campaign-retries.component.html',
})
export class CampaignRetriesComponent implements OnInit {
  @Input() form: FormGroup;
  retries = [1, 2, 3];

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get retrialControl(): FormControl {
    return this.contentForm.get('retrial') as FormControl;
  }

  get retriesEnabledControl(): FormControl {
    return this.contentForm.get('retriesEnabled') as FormControl;
  }

  ngOnInit() {
    this.contentForm.addControl('retriesEnabled', new FormControl(false));
  }

  updateFields() {
    if (this.contentForm.get('retriesEnabled').value) {
      this.contentForm.addControl('retrial', new FormControl(1));
    } else {
      this.contentForm.removeControl('retrial');
    }
  }

}
