import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignListTabs } from '../../shared/uni-campaign.model';
import { UniCampaignsPermissionsService } from '../../shared/uni-campaign-permissions.service';
import { navigateFromCampaignsToCc, navigateFromCcToCampaigns } from '../../shared';
import { AutoUnsubscribe } from '../../../../utils';
import { FeatureFlagKeys, UniAuthFacade, UniFeatureFlagsService } from '../../../uni-auth';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'uni-campaign-list-tabs',
  templateUrl: './campaign-list-tabs.component.html',
})
export class CampaignListTabsComponent extends AutoUnsubscribe implements OnInit {
  @Input() activeTab: any = this.getActiveTab();
  tabs = CampaignListTabs;
  disableVoice = !this.campaignPermissions.canSeeVoiceList();
  isFSEnabled = false;
  isSMSProEnabled = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private campaignPermissions: UniCampaignsPermissionsService,
    private router: Router,
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.checkFlags()
    );
  }

  onNavigate(route: CampaignListTabs) {
    const cloudRoutes = [
      CampaignListTabs.sms,
      CampaignListTabs.voice
    ];

    const campaignRoutes = [
      CampaignListTabs.flowStudio,
      CampaignListTabs.smsPro,
      CampaignListTabs.whatsapp
    ];

    if (route === this.activeTab) {
      return;
    }

    if (
      cloudRoutes.includes(route)
      && cloudRoutes.includes(this.activeTab)
    ) {
      this.router.navigate([`/campaigns/${route}`]);
      return;
    }

    const campaignRoute = route === CampaignListTabs.smsPro ? CampaignListTabs.sms : route;

    if (
      campaignRoutes.includes(route)
      && campaignRoutes.includes(this.activeTab)
    ) {
      this.router.navigate([`/management/${campaignRoute}`]);
      return;
    }

    if (campaignRoutes.includes(this.activeTab)) {
      navigateFromCampaignsToCc(route);
    } else {
      navigateFromCcToCampaigns(campaignRoute);
    }
  }

  getActiveTab() {
    const path = this.activatedRoute.snapshot.parent.routeConfig.path;
    const isSMS = path === CampaignListTabs.sms;
    const isCC = window.location.href.includes('communication');

    return !isSMS || isSMS && isCC ? path : CampaignListTabs.smsPro;
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags),
      )
      .subscribe(() => {
        this.isFSEnabled = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.flow_studio_campaigns_2022_q_3);
        this.isSMSProEnabled = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.sms_pro_2022_q_3);
      });
  }

}
