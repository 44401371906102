import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-action-widget-content',
  templateUrl: './uni-action-widget-content.component.html',
  styleUrls: ['./uni-action-widget-content.component.scss'],
})
export class UniActionWidgetContentComponent {
  @Input() translationPath: string;
  @Input() rawText = '';
  @Input() numberContent: number | string;
  @Input() isReversed = false;
  @Input() numbersSize = 'regular';
  @Input() buttonId = '';
  @Input() isWarning = false;

  @Output() actionButtonClick = new EventEmitter<void>();

  @HostBinding('class') get getClass(): string {
    return `uni-action-widget-content--${this.numbersSize}`;
  }

  onActionButtonClick(): void {
    this.actionButtonClick.next();
  }
}
