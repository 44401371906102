import { NgModule } from '@angular/core';
import { UniLogsActionComponent } from './components/uni-logs-action/uni-logs-action.component';

const components = [
  UniLogsActionComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
})
export class UniLogsModule { }
