import { NgModule } from '@angular/core';

import { UniClickOutsideDirective } from './uni-click-outside.directive';
import { UniDebounceClickDirective } from './uni-debounce-click.directive';

const directives = [
  UniClickOutsideDirective,
  UniDebounceClickDirective
];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
})
export class UniDirectivesModule { }
