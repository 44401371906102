import { Component,OnInit } from '@angular/core';
import { ProductKey } from '../../../../shared/products/products.model';
import { ProductsService } from '../../../../shared/products/products.service';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';

@Component({
  selector: 'uni-top-bar-topup',
  templateUrl: './uni-top-bar-topup.component.html',
  styleUrls: ['./uni-top-bar-topup.component.scss']
})
export class UniTopBarTopupComponent extends AutoUnsubscribe implements OnInit {
  isEnableNewPayment = false;

  constructor(
    private uniAuthFacade: UniAuthFacade,
    protected productsService: ProductsService,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {
      super();
    }

  ngOnInit() {
    this.subs.add(
      this.checkFlags()
    );
  }

  onTopup(): void {
    const topUpRoute = this.isEnableNewPayment ? '/payment/topup' : '/store/top-up';
    this.productsService.navigate(ProductKey.UC, topUpRoute);
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags)
      )
      .subscribe(() => {
        this.isEnableNewPayment = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.payment_module_wrapper_2022_q2);
      });
  }
}
