export enum OtpType {
  SMS = 'sms',
  EMAIL = 'email'
}

export interface TwoFactorCode {
  type: OtpType;
  codeType: string;
}

export interface TwoFactorToken {
  type: OtpType;
  codeType: string;
  code: string;
}

export interface TwoFactorTokenResponse {
  type: OtpType;
  codeType: string;
  code: string;
  token: string;
}

export interface OtpToken {
  otp: string;
}
