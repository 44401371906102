export enum CampaignTableColumns {
  campaignName = 'name',
  id = 'id',
  contentType = 'contentType',
  senderName = 'senderName',
  messageContent = 'messageContent',
  recipientsCount = 'recipientsCount',
  recipients = 'recipients',
  createdAt = 'createdAt',
  status = 'status',
  progress = 'progress',
  success = 'success',
  source = 'source',
  accountName = 'accountName',
  createdBy = 'createdBy',
  expandedDetail = 'expandedDetail',
  actions = 'actions',
  messageType = 'messageType'
}
