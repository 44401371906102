import { get } from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import {
  PostCampaignData,
  CampaignCountryCode,
  CampaignDataSource,
  CampaignMessageType
} from '../../shared/uni-campaign.model';
import { UniCampaignFormService } from '../../shared/uni-campaign-form.service';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniCampaignsPermissionsService } from '../../shared/uni-campaign-permissions.service';
import { AutoUnsubscribe, DateUtils } from '../../../../utils';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TwoFactorTokenResponse } from '../../../uni-2fa/shared';
import { FeatureFlagsGuard } from '../../../uni-auth/shared/uni-feature-flags.guard';

@Component({
  selector: 'cc-campaign-summary-form',
  templateUrl: './campaign-summary-form.component.html',
  styleUrls: ['./campaign-summary-form.component.scss']
})
export class CampaignSummaryFormComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  canDirectSend = this.uniCampaignPermissions.isVoice || this.uniCampaignPermissions.canDirectSendCampaign();
  isModalActive = false;
  isEnabledTwoFactor = false;
  messageTypeOptions?: CampaignMessageType[];

  get stepControl(): FormControl {
    return this.form.get('step') as FormControl;
  }

  get nameForm(): FormGroup {
    return this.form.get('nameForm') as FormGroup;
  }

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get isAutocompleteActiveControl(): FormControl {
    return this.contentForm.get('isAutocompleteActive') as FormControl;
  }

  get autocompleteControl(): FormControl {
    return this.contentForm.get('autocomplete') as FormControl;
  }

  get fileControl(): FormControl {
    return this.contentForm.get('recipients.file') as FormControl;
  }

  get datesForm(): FormControl {
    return this.form.get('datesForm') as FormControl;
  }

  get isScheduledControl(): FormControl {
    return this.datesForm.get('isScheduled') as FormControl;
  }

  get dateForm(): FormGroup {
    return this.datesForm.get('date') as FormGroup;
  }

  get dateControl(): FormControl {
    return this.dateForm.get('date') as FormControl;
  }

  get timeControl(): FormControl {
    return this.dateForm.get('time') as FormControl;
  }

  get typeControl(): FormControl {
    return this.contentForm.get('type') as FormControl;
  }

  get messageTypeControl(): FormControl | null {
    return this.contentForm.get('messageType') as FormControl;
  }

  get isCustomForm(): boolean {
    return this.typeControl.value === CampaignDataSource.custom;
  }

  get isFileToSMSForm(): boolean {
    return this.typeControl.value === CampaignDataSource.fileToSMS;
  }

  constructor(
    private campaignFacade: UniCampaignsFacade,
    private campaignFormService: UniCampaignFormService,
    private uniAuthFacade: UniAuthFacade,
    private uniCampaignPermissions: UniCampaignsPermissionsService,
    private featureFlagsGuard: FeatureFlagsGuard
  ) {
    super();
  }

  ngOnInit() {
    this.nameForm.disable();
    this.contentForm.disable();
    this.subs.sink = this.checkFlags();
    if(this.datesForm) {
      this.datesForm.disable();
    }
  }

  onEdit() {
    this.stepControl.setValue(3);

    setTimeout(() => {
      this.nameForm.disable();
      this.contentForm.disable({ emitEvent: false });
      this.datesForm.enable();
    }, 100);

    window.scroll(0, 0);
  }

  onSubmit(response: TwoFactorTokenResponse = null): void {
    this.campaignFormService.resetFormData();
    this.campaignFacade.postCampaign(this.getSubmitData(response));
  }

  getParsedData() {
    const formValue = this.form.getRawValue();
    const countryCode = this.getCountryCode();

    if (this.isCustomForm) {
      return {
        senderName: get(formValue, 'contentForm.senderName'),
        recipients: get(formValue, 'contentForm.recipients'),
        content: get(formValue, 'contentForm.content'),
        messageType: get(formValue, 'contentForm.messageType'),
        ...((countryCode && !!this.fileControl) ? { countryCode } : {})
      };
    }

    if (this.isFileToSMSForm) {
      return {
        recipients: {
          campaignFile: {
            id: get(formValue, 'contentForm.fileResponse.id'),
          },
          fileRecipients: get(formValue, 'contentForm.fileResponse.recipientsCount'),
        },
        ...(countryCode ? { countryCode } : {})
      };
    }

    return {};
  }

  getSubmitData(response: TwoFactorTokenResponse = null): PostCampaignData {
    const formValue = this.form.getRawValue();

    const data: PostCampaignData = {
      id: formValue.id,
      name: get(formValue, 'nameForm.name'),
      type: get(formValue, 'contentForm.recipients.audienceGroups') ? 'audience' : get(formValue, 'contentForm.type'),
      scheduledAt: this.date,
      ...this.getParsedData(),
      otp: response ? response.token : ''
    };

    return data;
  }

  get timezone(): string {
    return this.uniAuthFacade.user.timezone;
  }

  get date(): string | null {
    if (!this.isScheduledControl.value) {
      return null;
    }

    const date = DateUtils.getDate(this.dateControl.value);
    const time = this.timeControl.value;

    return DateUtils.getFormattedDate(date + time, this.timezone);
  }

  get dateToDisplay(): string | null {
    if (!this.isScheduledControl.value) {
      return null;
    }
    const date = DateUtils.getDate(this.dateControl.value, DateUtils.Format.default);
    const time = this.timeControl.value;

    return `${date}, ${time}`;
  }

  getCountryCode(): CampaignCountryCode | null {
    if (!this.isAutocompleteActiveControl.value) {
      return null;
    }

    return {
      id: this.autocompleteControl.value.id,
      code: this.autocompleteControl.value.code
    };
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags)
      )
      .subscribe(() => {
        this.isEnabledTwoFactor = this.featureFlagsGuard.hasTwoFactor();
        this.canDirectSend = this.uniCampaignPermissions.isVoice || this.uniCampaignPermissions.canDirectSendCampaign();
      });
  }

  toggleModal(): void {
    this.isModalActive = !this.isModalActive;
  }
}
