import { Action } from '@ngrx/store';

import { Industry } from '../../shared/uni-industry.model';

export const SET_INDUSTRIES = '[Industry] SET_INDUSTRIES';
export const SET_INDUSTRIES_SUCCESS = '[Industry] SET_INDUSTRIES_SUCCESS';

export class SetIndustries implements Action {
  readonly type = SET_INDUSTRIES;
  constructor() { }
}

export class SetIndustriesSuccess implements Action {
  readonly type = SET_INDUSTRIES_SUCCESS;
  constructor(public response: Industry[]) { }
}

export type All =
  | SetIndustries
  | SetIndustriesSuccess;
