
import { queryReducer } from '../../../store';
import * as QueryActions from '../../../store/query/query.actions';
import * as UsersActions from './uni-user.actions';
import { GET_USERS_QUERY, uniUserInitialState, UniUserState } from './uni-user.state';

const queryState = (state: UniUserState, action: UsersActions.All | QueryActions.All): UniUserState => ({
  ...state,
  ...queryReducer<UniUserState>([GET_USERS_QUERY], action as QueryActions.All),
});

export function uniUserReducer(
  state = uniUserInitialState,
  action: UsersActions.All,
): UniUserState {
  switch (action.type) {
    case UsersActions.SET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case UsersActions.SET_USERS_AMOUNT: {
      return {
        ...state,
        usersAmount: action.amount,
      };
    }
    case UsersActions.CLEAR_USERS:
      return {
        ...state,
        getUsersQuery: {},
        users: undefined,
      };
    default: {
      return queryState(state, action);
    }
  }
}
