import { get } from 'lodash';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoUnsubscribe } from '../../../../utils';

@Component({
  selector: 'uni-form-file',
  templateUrl: './uni-form-file.component.html',
})
export class UniFormFileComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() fileControl: FormControl;
  @Input() label: string;
  @Input() placeholder = 'label.selectFileFromDrive';
  @Input() accept = '.csv';
  @Input() fileName = 'fileName';
  @Input() tip: string;
  @Input() isEditable = true;
  @Input() required = false;

  @ViewChild('file', { static: false }) file: ElementRef;

  get fileNameControl() { return this.form.get(this.fileName); }

  ngOnInit(): void {
    this.subs.sink = this.fileControl.statusChanges.subscribe(() => {
      this.setInputAvailabilityState();
      this.setInputErrors();
    });
  }

  uploadFromFile(): void {
    this.file.nativeElement.click();
  }

  deleteFile(): void {
    this.fileControl.reset();
    this.fileNameControl.reset();
    this.file.nativeElement.value = '';
  }

  onFilesAdded(): void {
    const { files } = this.file.nativeElement;

    if (!files || !files.length) {
      return;
    }

    this.fileControl.setValue(files[0]);

    const fileName = get(this.fileControl.value, 'name');
    this.fileNameControl.setValue(fileName);
    this.fileNameControl.markAsDirty();
  }

  private setInputAvailabilityState(): void {
    if (this.fileControl.disabled) {
      this.fileNameControl.disable();
      return;
    }

    this.fileNameControl.enable();
  }

  private setInputErrors(): void {
    const inputErrors = this.fileControl.errors || null;
    this.fileNameControl.setErrors(inputErrors);
  }
}
