import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniCampaignFormService } from '../../shared/uni-campaign-form.service';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { Observable, Subscription } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { UniAuthFacade } from '../../../uni-auth';
import { CampaignDataSource, CampaignMessageType, CampaignRecipientsMethod, } from '../../shared/uni-campaign.model';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { UniSenderName } from '../../../../shared';

@Component({
  selector: 'cc-campaign-custom-form',
  templateUrl: './campaign-custom-form.component.html',
  styleUrls: ['./campaign-custom-form.component.scss'],
})
export class CampaignCustomFormComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  isInTrialMode = false;
  messageTypeOptions$: Observable<CampaignMessageType[]>;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get recipientsForm(): FormGroup {
    return this.contentForm.get('recipients') as FormGroup;
  }

  get phoneNumbersControl(): FormControl {
    return this.recipientsForm.get('phoneNumbers') as FormControl;
  }

  get phoneNumbersInputControl(): FormControl {
    return this.recipientsForm.get('phoneNumbersInput') as FormControl;
  }

  constructor(
    private uniCampaignFormService: UniCampaignFormService,
    private uniAuthFacade: UniAuthFacade,
    public campaignFormFactory: UniCampaignFormFactory,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.watchSenderNameControl()
    );

    this.messageTypeOptions$ = this.contentForm.get('senderName').valueChanges.pipe(
      map((senderName) => senderName?.messageTypes || []),
      tap((messageTypes) => {
        if (messageTypes && messageTypes.length > 0) {
          this.campaignFormFactory.addMessageTypeControl(this.form);
        } else {
          this.campaignFormFactory.clearMessageTypeControl(this.form);
        }
      })
    );
  }

  watchSenderNameControl(): Subscription {
    return this.uniCampaignFormService.selectedSenderName$
      .pipe(
        filter(() => !!this.contentForm),
        delay(1),
      )
      .subscribe(([preValue, currentValue]: UniSenderName[]) => {
        if (currentValue?.isTrial) {
          this.setTrialFormData();
        } else {
          this.setFormState(preValue);
        }
      });
  }

  setTrialFormData(): void {
    this.isInTrialMode = true;
    this.resetContentTypeAndMethod();
    this.contentForm.get('messageType')?.setValue(CampaignMessageType.service);
    this.setAndBlockFormData();
  }

  setFormState(senderName?: UniSenderName): void {
    this.contentForm.enable({ emitEvent: false });
    this.isInTrialMode = false;

    if (senderName?.isTrial) {
      this.resetContentTypeAndMethod();
      this.contentForm.get('messageType')?.reset();
      this.contentForm.get('content').reset();
      this.phoneNumbersInputControl.reset();
      this.phoneNumbersControl.reset();
    }
  }

  private resetContentTypeAndMethod(): void {
    this.contentForm.get('type').setValue(CampaignDataSource.custom);
    this.contentForm.get('method').setValue(CampaignRecipientsMethod.manually);
  }

  private setAndBlockFormData(): void {
    this.campaignFormFactory.customRecipients(this.recipientsForm);
    this.phoneNumbersControl.setValue([this.uniAuthFacade.userMe.user.phone]);
    this.phoneNumbersInputControl.reset();
    this.contentForm.disable({ emitEvent: false });
    this.contentForm.get('senderName').enable({ emitEvent: false });
    this.contentForm.updateValueAndValidity({ emitEvent: false });
  }
}
