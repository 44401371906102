<uni-icon
  class="uni-audio-volume__icon"
  [name]="isMuted ? 'volume-slash' : 'volume'"
  type="fas"
  (click)="onMute()"
></uni-icon>
<div class="uni-audio-volume__slider">
  <mat-slider
    #volumeLevel
    value="1"
    max="1"
    step="0.1"
  ></mat-slider>
</div>
