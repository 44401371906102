import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { UnishieldFetchStore } from 'src/app/shared/data-access/unishield/unishield.store';
import { UniAuthFacade } from "unifonic-spa-common/src";
import { TranslateService } from "@ngx-translate/core";


type State = {
    tabs: any[],
}

const initialState: State = {
    tabs: [{
        label: 'Applications',
        value: 'applications'
    },{
        label: 'Analytics',
        value: 'analytics'
    }],
}

@Injectable()
export class PageIndexStore extends ComponentStore<State>{
    constructor(
        private unishieldFetchStore: UnishieldFetchStore,
        private authFacade: UniAuthFacade,
        private translateService: TranslateService,
    ){
        super(initialState)
    }


    tabs$ = this.select(state => state.tabs)

    readonly viewTabs$ = this.select(
      this.tabs$,
      (entities) => entities.map(e => ({
            ...e,
            label: this.translateService.instant('ci.common.brand.' + e.value.toLowerCase())
        }))
    )

    //selectors
    vm$ = this.select(
      this.viewTabs$,
      (tabs) => {
          return {
            tabs,
            selectedTabValue: 'analytics'
          }
      }
    )

}
