import { Injectable, ElementRef } from '@angular/core';
import { Chart, ChartConfiguration, ChartOptions, ChartData } from 'chart.js';
import { DateUtils, hexToRgba, color } from '../../../utils';
import { UniChartData, UniChartMultiData } from './uni-chart.model';
import { UniChartAxesService } from './uni-chart-axes.service';
import { UniAuthFacade } from '../../uni-auth/shared';
import { UniChartTooltipService } from './uni-chart-tooltip.service';

@Injectable()
export class UniBarChartService {
  chart: Chart;

  constructor(
    private uniChartAxesService: UniChartAxesService,
    private uniAuthFacade: UniAuthFacade,
    private uniChartTooltipService: UniChartTooltipService,
  ) { }

  init(chartRef: ElementRef, data: UniChartData): Chart {
    const ctx = chartRef.nativeElement.getContext('2d');
    const config = this.getConfig(data);

    return new Chart(ctx, config);
  }

  update(chart: Chart, data: UniChartData | UniChartMultiData): void {
    if (!chart) { return; }

    chart.config = this.getConfig(data);
    chart.update();
  }

  getOptions(data: UniChartData | UniChartMultiData): ChartOptions {
    return {
      tooltips: this.uniChartTooltipService.getTooltip(),
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxes: this.uniChartAxesService.getBarChartAxesX(),
        yAxes: this.uniChartAxesService.getAxesY(data.yAxisMin),
      }
    };
  }

  getDateFormat(data: UniChartData | UniChartMultiData): string {
    return data.granularity === 'DAYS'
      ? 'DD.MM'
      : 'HH:mm';
  }

  getDateLabels(data: UniChartData | UniChartMultiData): string[] {
    if (data.granularity === 'CUSTOM') {
      return data.labels;
    }

    const format = this.getDateFormat(data) as DateUtils.Format;
    const timezone = this.uniAuthFacade.user.timezone;

    return data.labels.map(value => DateUtils.getTimezoneDate(value, timezone, format));
  }

  getData(data: UniChartData | UniChartMultiData): ChartData {
    return {
      labels: this.getDateLabels(data),
      datasets: this.getDatasets(data),
    };
  }

  getDatasets(data: UniChartData | UniChartMultiData) {
    const sharedValues = {
      borderWidth: 1,
      lineTension: 0,
      pointRadius: 4,
      pointHoverRadius: 4,
      pointBorderWidth: 3,
      pointBorderColor: hexToRgba(color.white, 1),
    };

    return [{
      label: data.unit[0],
      unit: data.unit[0],
      data: data.values[0],
      backgroundColor: hexToRgba(color.greenBright, 1),
      ...sharedValues
    },
    ...(!!data.unit[1] && [{
      label: data.unit[1],
      unit: data.unit[1],
      data: data.values[1],
      backgroundColor: hexToRgba(color.greenDarker, 1),
      ...sharedValues
    }])];
  }

  getConfig(data: UniChartData | UniChartMultiData): ChartConfiguration {
    return {
      type: 'bar',
      data: this.getData(data),
      options: this.getOptions(data),
    };
  }
}

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.defaults.global.defaultFontFamily = 'Gotham Rounded';
Chart.defaults.global.defaultFontSize = '14';
Chart.defaults.global.titleFontSize = '"Gotham Rounded", "Tahoma"';
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
   draw(ease) {
      Chart.controllers.line.prototype.draw.call(this, ease);

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0];
        const ctx = this.chart.ctx;
        const x = activePoint.tooltipPosition().x;
        const topY = this.chart.scales['y-axis-0'].top;
        const bottomY = this.chart.scales['y-axis-0'].bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.font = '14px Gotham Rounded';
        ctx.lineWidth = 1;
        ctx.strokeStyle = hexToRgba(color.grey, .2);
        ctx.stroke();
        ctx.restore();
      }
   }
});
