import { QueryResponse, QueryConfig, QueryGroups, Queries } from './http-query.model';
import { isHttpQueryGroupInProgress } from './http-query.utils';
import { HttpResponse } from '@angular/common/http';
import { HttpQueryState } from './store/http-query.state';

export const initialQuery: QueryResponse<null> = {
  status: null,
  response: null,
  error: null,
  isDirty: false,
  isInProgress: false,
  isSuccess: false,
  isError: false,
};

export const addQueryToGroup = (
  queryState: HttpQueryState,
  queryConfig: QueryConfig,
  query: QueryResponse<HttpResponse<unknown>>,
  groupName: string,
): Queries => {
  const { groups } = queryState;
  const { name } = queryConfig;

  return {
    ...(groups[groupName] ? { ...groups[groupName].queries } : {}),
    [name]: query,
  };
};

export const addQueryToGroups = (
  queryState: HttpQueryState,
  queryConfig: QueryConfig,
  query: QueryResponse<HttpResponse<unknown>>
): QueryGroups => {
  const { groups: stateGroups } = queryState;
  const { groups } = queryConfig;
  const extendedGroups = { ...stateGroups };

  if (!groups) {
    return extendedGroups;
  }

  groups.map(groupName => {
    const queries = addQueryToGroup(queryState, queryConfig, query, groupName);
    extendedGroups[groupName] = {
      isInProgress: isHttpQueryGroupInProgress(queries),
      queries
    };
  });

  return extendedGroups;
};

export const getQueries = (
  queryState: HttpQueryState,
  queryConfig: QueryConfig,
  query: QueryResponse<HttpResponse<unknown>>
): Queries => {
  return {
    ...queryState.queries,
    [queryConfig.name]: query
  };
};

export const parseHttpQueryState = (
  queryState: HttpQueryState,
  queryConfig: QueryConfig,
  query: QueryResponse<HttpResponse<unknown>>
): HttpQueryState => {
  const groups = addQueryToGroups(queryState, queryConfig, query);
  const queries = getQueries(queryState, queryConfig, query);

  return {
    ...queryState,
    queries,
    groups
  };
};
