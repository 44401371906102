import { Component, OnInit } from '@angular/core';
import { AdvancedFiltersFormVoiceStatusStore } from './advanced-filters-form-voice-status.store';
import { CustomerStatus } from 'src/app/shared/store-mocks/status.store';
import { map } from 'rxjs/operators';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-advanced-filters-form-voice-status',
  templateUrl: './advanced-filters-form-voice-status.component.html',
  styleUrls: ['./advanced-filters-form-voice-status.component.scss'],
  providers: [AdvancedFiltersFormVoiceStatusStore]
})
export class AdvancedFiltersFormVoiceStatusComponent implements OnInit {

  onChange: any = () => {}
  onTouch: any = () => {}
  
  statusCurrentValue$ = this.store.statusValue$.pipe(map(
    (value) => {
      return value.length ? value.split(',').filter(s => s !== CustomerStatus.NONE) : []
    }
  ))
  statusEntities$ = this.store.entities$

  constructor(
    public ngControl: NgControl,
    private store: AdvancedFiltersFormVoiceStatusStore

    ) {
      ngControl.valueAccessor = this;
    }

  ngOnInit(): void {}

  writeValue(value: string){}

  registerOnChange(fn: any){
    this.onChange = fn
  }

  registerOnTouched(fn: any){
    this.onTouch = fn
  }

  statusValueChange(option: string[]) {
    this.saveValue(option.join(','))
  }


  saveValue(value){
    this.onChange(value)
    if(this.ngControl.untouched){
      this.onTouch()
    }
  }

  ngOnDestroy(){
    this.saveValue('')
  }

}
