<div
    *ngIf="vm$ | async as vm"
    class="data-sort"
>
    <div class="data-sort-icons">
        <i
            class="far fa-sort-up"
            (click)="setValue('ASC')"
            [attr.active]="vm.ascActive || null"
        ></i>
        <i
            class="far fa-sort-down"
            (click)="setValue('DESC')"
            [attr.active]="vm.descActive || null"
        ></i>

    </div>
</div>