import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-details-item',
  templateUrl: './uni-details-item.component.html',
  styleUrls: ['./uni-details-item.component.scss']
})
export class UniDetailsItemComponent {
  @Input() class = '';
  @Input() columns = 0;

  @HostBinding('class.uni-details-item--column') @Input() column = false;
  @HostBinding('class.uni-details-item--is-border') @Input() isBorder = true;
  @HostBinding('class.uni-details-item--is-margin') @Input() isMargin = false;

  @HostBinding('class') get getClass(): string {
    return `${this.class} uni-details-item--columns-${this.columns}`;
  }
}
