import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { UniAuthFacade } from './uni-auth.facade';
import { FeatureFlagOperator, FeatureFlagKeys } from './uni-feature-flags.model';
import { UniFeatureFlagsService } from './uni-feature-flags.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// @Example how to use directive:
// *hasFeatureFlag="['2019-q-4-name']; operator 'AND'"
@Directive({ selector: '[hasFeatureFlag]' })
export class UniHasFeatureFlagDirective implements OnInit, OnDestroy {
  permissions: FeatureFlagKeys[] = [];
  operator = FeatureFlagOperator.AND;
  isHidden = true;
  unsubscribe$ = new Subject();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {}

  ngOnInit() {
    this.initFeatureFlags();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @Input()
  set hasFeatureFlag(permissions: FeatureFlagKeys[]) {
    this.permissions = permissions;
    this.updateView();
  }

  @Input()
  set hasFeatureFlagOperator(operator: FeatureFlagOperator) {
    this.operator = operator;
    this.updateView();
  }

  initFeatureFlags(): Subscription {
    return this.uniAuthFacade
      .featureFlags$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateView());
  }

  updateView() {
    const hasPermission = this.uniFeatureFlagsService
      .checkPermission(
        this.operator,
        this.permissions,
      );

    if (hasPermission) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
      return;
    }

    this.isHidden = true;
    this.viewContainer.clear();
  }
}
