import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniUserState } from './uni-user.state';

export const selectUsersFeature = createFeatureSelector<UniUserState>('uniUser');

export const selectUsers = createSelector(
  selectUsersFeature,
  (state: UniUserState) => state.users,
);

export const selectUsersAmount = createSelector(
  selectUsersFeature,
  (state: UniUserState) => state.usersAmount,
);

export const selectGetUsersQuery = createSelector(
  selectUsersFeature,
  (state: UniUserState) => state.getUsersQuery,
);
