import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { QueryPayload } from "../data-access.model";

import { DEFAULT_OUTPUT } from "./unishield.graphql";
import { UnishieldRepository } from "./unishield.repository";


type State = {
    loading: boolean
    loaded: boolean
    entities: any
    count: number
    lastFetchTimestamp: number
}

const initialState: State = {
    loading: false,
    loaded: false,
    entities: {},
    count: 0,
    lastFetchTimestamp: 0
}

type QueryParams = {
}

@Injectable()
export class UnishieldFetchStore extends ComponentStore<State>{
    defaultOutput: string =  DEFAULT_OUTPUT

    constructor(
        private repository: UnishieldRepository,
    ){
        super(initialState)
    }

    //selectors
    loading$ = this.select(state => state.loading)
    loaded$ = this.select(state => state.loaded)
    entities$ = this.select(state => state.entities)
    count$ = this.select(state => state.count)
    lastFetchTimestamp$ = this.select(state => state.lastFetchTimestamp)

    //effect
    readonly fetchData = this.effect((payload$: Observable<QueryPayload<QueryParams>>) => {
        return payload$.pipe(
            tap((p) => {
                this.patchState({loading: true})
            }),
            switchMap(({variables, output}) => this.repository.gqlQueryGetUnishieldFraudData(variables, output || this.defaultOutput).pipe(
                tapResponse(response => {
                      this.patchState({
                          entities: response.entries,
                          loading: false,
                          loaded: true,
                          count: response.count,
                          lastFetchTimestamp: (new Date()).getTime()
                      })
                    },
                    () => {
                      this.patchState({
                          loading: false,
                          loaded:false
                      })
                    }
                ),
                catchError((err) => {
                    return throwError(err)
                })
            ))
        )
    })
}


