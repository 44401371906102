import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'uni-pagination-form',
  templateUrl: './uni-pagination-form.component.html',
  styleUrls: ['./uni-pagination-form.component.scss']
})
export class UniPaginationFormComponent {
  @Input() form: FormGroup;

  onPaste(event): boolean {
    event.preventDefault();
    return false;
  }
}
