import { HttpHeaders } from '@angular/common/http';
import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import { Country } from '../../../modules/uni-countries/shared/uni-countries.model';
import { FormError } from '../../../modules/uni-form/shared/uni-form.model';

import {
  Campaign,
  CampaignExportId,
  CampaignLog,
  CampaignStats,
  CampaignTemplate,
  CampaignTemplatesParams,
  changeStatusCampaignData,
  MessageContent
} from '../shared/uni-campaign.model';

export const POST_CAMPAIGN = '[Campaign] POST_CAMPAIGN';
export const POST_CAMPAIGN_SUCCESS = '[Campaign] POST_CAMPAIGN_SUCCESS';
export const POST_CAMPAIGN_ERROR = '[Campaign] POST_CAMPAIGN_ERROR';

export const DELETE_SCHEDULED_CAMPAIGN = '[Campaign] DELETE_SCHEDULED_CAMPAIGN';
export const DELETE_SCHEDULED_CAMPAIGN_SUCCESS = '[Campaign] DELETE_SCHEDULED_CAMPAIGN_SUCCESS';
export const DELETE_SCHEDULED_CAMPAIGN_ERROR = '[Campaign] DELETE_SCHEDULED_CAMPAIGN_ERROR';

export const SET_CAMPAIGN = '[Campaign] SET_CAMPAIGN';
export const SET_CAMPAIGN_SUCCESS = '[Campaign] SET_CAMPAIGN_SUCCESS';

export const SET_CAMPAIGN_LOG = '[Campaign] SET_CAMPAIGN_LOG';
export const SET_CAMPAIGN_LOG_SUCCESS = '[Campaign] SET_CAMPAIGN_LOG_SUCCESS';

export const POST_CAMPAIGN_EXPORT = '[Campaign] POST_CAMPAIGN_EXPORT';
export const POST_CAMPAIGN_EXPORT_SUCCESS = '[Campaign] POST_CAMPAIGN_EXPORT_SUCCESS';

export const SET_CAMPAIGNS = '[Campaign] SET_CAMPAIGNS';
export const SET_CAMPAIGNS_SUCCESS = '[Campaign] SET_CAMPAIGNS_SUCCESS';

export const POST_CAMPAIGN_TEMPLATE = '[Campaign] POST_CAMPAIGN_TEMPLATE';
export const POST_CAMPAIGN_TEMPLATE_SUCCESS = '[Campaign] POST_CAMPAIGN_TEMPLATE_SUCCESS';
export const POST_CAMPAIGN_TEMPLATE_ERROR = '[Campaign] POST_CAMPAIGN_TEMPLATE_ERROR';

export const SET_CAMPAIGN_TEMPLATE = '[Campaign] SET_CAMPAIGN_TEMPLATE';
export const SET_CAMPAIGN_TEMPLATE_SUCCESS = '[Campaign] SET_CAMPAIGN_TEMPLATE_SUCCESS';

export const SET_CAMPAIGN_TEMPLATES = '[Campaign] SET_CAMPAIGN_TEMPLATES';
export const SET_CAMPAIGN_TEMPLATES_SUCCESS = '[Campaign] SET_CAMPAIGN_TEMPLATES_SUCCESS';

export const SET_CAMPAIGN_TRIAL_TEMPLATES = '[Campaign] SET_CAMPAIGN_TRIAL_TEMPLATES';
export const SET_CAMPAIGN_TRIAL_TEMPLATES_SUCCESS = '[Campaign] SET_CAMPAIGN_TRIAL_TEMPLATES_SUCCESS';

export const SET_CAMPAIGN_FILE = '[Campaign] SET_CAMPAIGN_FILE';
export const SET_CAMPAIGN_FILE_SUCCESS = '[Campaign] SET_CAMPAIGN_FILE_SUCCESS';

export const SET_CAMPAIGN_EXPORT = '[Campaign] SET_CAMPAIGN_EXPORT';
export const SET_CAMPAIGN_EXPORT_SUCCESS = '[Campaign] SET_CAMPAIGN_EXPORT_SUCCESS';

export const SET_AUTOCOMPLETE_COUNTRIES = '[Campaign] SET_AUTOCOMPLETE_COUNTRIES';
export const SET_AUTOCOMPLETE_COUNTRIES_SUCCESS = '[Campaign] SET_AUTOCOMPLETE_COUNTRIES_SUCCESS';

export const CLEAR_CAMPAIGN = '[Campaign] CLEAR_CAMPAIGN';

export const POST_CAMPAIGN_STATUS = '[Campaign] POST_CAMPAIGN_STATUS';
export const POST_CAMPAIGN_STATUS_SUCCESS = '[Campaign] POST_CAMPAIGN_STATUS_SUCCESS';

export const SET_BLOCK_WORDS = '[Campaign] SET_BLOCK_WORDS';
export const SET_BLOCK_WORDS_SUCCESS = '[Campaign] SET_BLOCK_WORDS_SUCCESS';


export class PostCampaign implements Action {
  readonly type = POST_CAMPAIGN;
  constructor(public payload: any) { }
}

export class PostCampaignSuccess implements Action {
  readonly type = POST_CAMPAIGN_SUCCESS;
  constructor() { }
}

export class PostCampaignError implements Action {
  readonly type = POST_CAMPAIGN_ERROR;
  constructor(public errors: FormError[]) { }
}

export class DeleteScheduledCampaign implements Action {
  readonly type = DELETE_SCHEDULED_CAMPAIGN;
  constructor(public id: string) { }
}

export class DeleteScheduledCampaignSuccess implements Action {
  readonly type = DELETE_SCHEDULED_CAMPAIGN_SUCCESS;
  constructor() { }
}

export class DeleteScheduledCampaignError implements Action {
  readonly type = DELETE_SCHEDULED_CAMPAIGN_ERROR;
  constructor() { }
}

export class SetCampaign implements Action {
  readonly type = SET_CAMPAIGN;
  constructor(public id: string) { }
}

export class SetCampaignSuccess implements Action {
  readonly type = SET_CAMPAIGN_SUCCESS;
  constructor(
    public campaign: Campaign,
    public stats: CampaignStats,
  ) { }
}

export class SetCampaignLog implements Action {
  readonly type = SET_CAMPAIGN_LOG;
  constructor(public params: Params = {}) { }
}

export class SetCampaignLogSuccess implements Action {
  readonly type = SET_CAMPAIGN_LOG_SUCCESS;
  constructor(
    public campaignLog: CampaignLog[],
    public headers: HttpHeaders,
  ) { }
}

export class SetCampaigns implements Action {
  readonly type = SET_CAMPAIGNS;
  constructor(public params: Params = {}) { }
}

export class SetCampaignsSuccess implements Action {
  readonly type = SET_CAMPAIGNS_SUCCESS;
  constructor(
    public campaigns: Campaign[],
    public headers: HttpHeaders,
  ) { }
}

export class PostCampaignTemplate implements Action {
  readonly type = POST_CAMPAIGN_TEMPLATE;
  constructor(public payload: CampaignTemplate) { }
}

export class PostCampaignTemplateSuccess implements Action {
  readonly type = POST_CAMPAIGN_TEMPLATE_SUCCESS;
  constructor(public response: CampaignTemplate) {}
}

export class PostCampaignTemplateError implements Action {
  readonly type = POST_CAMPAIGN_TEMPLATE_ERROR;
  constructor(public errors: FormError[]) { }
}

export class SetCampaignTemplate implements Action {
  readonly type = SET_CAMPAIGN_TEMPLATE;
  constructor(public id: string) { }
}

export class SetCampaignTemplateSuccess implements Action {
  readonly type = SET_CAMPAIGN_TEMPLATE_SUCCESS;
  constructor(
    public template: CampaignTemplate,
  ) { }
}

export class SetCampaignTemplates implements Action {
  readonly type = SET_CAMPAIGN_TEMPLATES;
  constructor(public params?: CampaignTemplatesParams) { }
}

export class SetCampaignTemplatesSuccess implements Action {
  readonly type = SET_CAMPAIGN_TEMPLATES_SUCCESS;
  constructor(
    public templates: CampaignTemplate[],
  ) { }
}

export class SetCampaignTrialTemplates implements Action {
  readonly type = SET_CAMPAIGN_TRIAL_TEMPLATES;
}

export class SetCampaignTrialTemplatesSuccess implements Action {
  readonly type = SET_CAMPAIGN_TRIAL_TEMPLATES_SUCCESS;
  constructor(
    public templates: CampaignTemplate[],
  ) { }
}

export class SetCampaignFile implements Action {
  readonly type = SET_CAMPAIGN_FILE;
  constructor(
    public id: string,
    public name: string
  ) { }
}

export class SetCampaignFileSuccess implements Action {
  readonly type = SET_CAMPAIGN_FILE_SUCCESS;
  constructor(
    public response: Blob,
    public name: string,
  ) { }
}

export class PostCampaignExport implements Action {
  readonly type = POST_CAMPAIGN_EXPORT;

  constructor(public id: string) { }
}

export class PostCampaignExportSuccess implements Action {
  readonly type = POST_CAMPAIGN_EXPORT_SUCCESS;

  constructor(public response: CampaignExportId) { }
}

export class SetCampaignExport implements Action {
  readonly type = SET_CAMPAIGN_EXPORT;

  constructor(
    public campaignId: string,
    public exportId: string
  ) { }
}

export class SetCampaignExportSuccess implements Action {
  readonly type = SET_CAMPAIGN_EXPORT_SUCCESS;

  constructor(public response: Blob) { }
}

export class SetAutocompleteCountries implements Action {
  readonly type = SET_AUTOCOMPLETE_COUNTRIES;

  constructor() { }
}

export class SetAutocompleteCountriesSuccess implements Action {
  readonly type = SET_AUTOCOMPLETE_COUNTRIES_SUCCESS;

  constructor(public response: Country[]) { }
}

export class ClearCampaign implements Action {
  readonly type = CLEAR_CAMPAIGN;

  constructor() { }
}

export class PostCampaignStatus implements Action {
  readonly type = POST_CAMPAIGN_STATUS;
  constructor(public payload: changeStatusCampaignData) { }
}

export class PostCampaignStatusSuccess implements Action {
  readonly type = POST_CAMPAIGN_STATUS_SUCCESS;
  constructor(public response: any) { }
}

export class SetBlockWords implements Action {
  readonly type = SET_BLOCK_WORDS;
  constructor(public payload: MessageContent) { }
}

export class SetBlockWordsSuccess implements Action {
  readonly type = SET_BLOCK_WORDS_SUCCESS;
  constructor(
    public response: boolean,
  ) {
  }
}

export type All =
  | SetCampaign
  | SetCampaignSuccess
  | SetCampaignLog
  | SetCampaignLogSuccess
  | SetCampaigns
  | SetCampaignsSuccess
  | PostCampaign
  | PostCampaignError
  | PostCampaignSuccess
  | DeleteScheduledCampaign
  | DeleteScheduledCampaignSuccess
  | DeleteScheduledCampaignError
  | PostCampaignTemplate
  | PostCampaignTemplateSuccess
  | PostCampaignTemplateError
  | SetCampaignTemplate
  | SetCampaignTemplateSuccess
  | SetCampaignTemplates
  | SetCampaignTemplatesSuccess
  | SetCampaignTrialTemplates
  | SetCampaignTrialTemplatesSuccess
  | SetCampaignFile
  | SetCampaignFileSuccess
  | PostCampaignExport
  | PostCampaignExportSuccess
  | SetCampaignExport
  | SetCampaignExportSuccess
  | SetAutocompleteCountries
  | SetAutocompleteCountriesSuccess
  | ClearCampaign
  | PostCampaignStatusSuccess
  | PostCampaignStatus
  | SetBlockWords
  | SetBlockWordsSuccess;
