
export function userBasicData(id = '0', firstName = 'John', isAdminLevel = false){
    return {
      id,
      email: 'example@email.com',
      firstName,
      lastName: 'Unifon',
      account: {
        id: '096db07c-f82e-11e8-809b-0252151e4411'
      },
      registrationDate: '12:31 2022-12-12',
      phone: '123-321-123',
      defaultCurrency: 'USD',
      timezone: 'Asia/Riyadh',
      roles: ['USER', 'ACCOUNT_USER'],
      locale: 'en',
      // locale: 'ar',
      isAdminLevel,
    }
  }

  export function createUser(id = '0', firstName = 'John', isAdminLevel = false){
    return {
      ...userBasicData(id, firstName, isAdminLevel),
      permissions: {
        CI: {
          DASHBOARD: true,
          REPORTS: true,
          LOGS: true
        },
        CC: {
          MESSAGE_LOG: ["SEE_ALL"],
          TRAFFIC_REPORT: ["SEE_ALL"]
        },
        PU: {
          APP: ["SEE_OWN", "SEE_USER_OWN", "SUPER_SEE"]
        }
      },
      account: {id, name: 'DevAccountx', isWhiteLabel: false},
      otpChannel: ['sms'],
      // additionalGrants: ['some', 'grants']
    }
  }


