import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UniNavService } from '../../shared';

@Component({
  selector: 'uni-nav-new-toggle',
  templateUrl: './uni-nav-toggle.component.html',
  styleUrls: ['./uni-nav-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavToggleComponent {
  isNavCollapsed$ = this.uniNavService.isNavCollapsed$;

  constructor(private readonly uniNavService: UniNavService) { }

  onToggleNav(): void {
    this.uniNavService.toggleNav();
  }
}
