<uni-box-content
  class="uni-rich-radio-button"
  [class.selected]="isSelected"
  [class.disabled]="isDisabled"
  (click)="!isDisabled && select.emit()"
>
  <uni-box-content-header class="uni-rich-radio-button__header">
    <ng-content select="mat-radio-button"></ng-content>
  </uni-box-content-header>

  <ng-content></ng-content>
</uni-box-content>
