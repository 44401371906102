
import { find, get, isArray, isString, isEqual } from 'lodash';
import { fromEvent, Subscription } from 'rxjs';
import { exhaustMap, filter, map, pairwise, debounceTime } from 'rxjs/operators';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { AutoUnsubscribe } from '../../../../utils';

@Component({
  selector: 'uni-form-multi-autocomplete',
  templateUrl: './uni-form-multi-autocomplete.component.html',
  styleUrls: ['./uni-form-multi-autocomplete.component.scss'],
})
export class UniFormMultiAutocompleteComponent extends AutoUnsubscribe implements OnInit, OnChanges {
  @ViewChild('autocomplete', { static: false }) autocomplete: ElementRef;
  @ViewChild('listContainer', { static: false }) listContainer: ElementRef<HTMLElement>;

  @Input() control: FormGroup;
  @Input() multi = false;
  @Input() thead: HTMLElement;
  @Input() heading: string;
  @Input() data = [];
  @Input() isSearch: boolean;
  @Input() filterKey: string;
  @Input() filterValue: string;
  @Input() scrollDistance = 30;
  @Input() scrollCallback;
  @Input() isLazyLoading = false;
  @Input() isLoading = false;
  @Input() addLrmChar = false;
  @Input() limitOptions?: number;

  @Output() apply = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() search = new EventEmitter<string>();

  maxPosition = 0;
  searchForm = this.formBuilder.group({ search: [] });
  filteredData = [];
  checkedItems: object[] = !!this.multi ? [] : null;
  moreOptionsToRender = 0;

  get searchControl(): FormControl {
    return this.searchForm.get('search') as FormControl;
  }

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.checkedItems = this.control.value;
    this.filteredData = this.getFilteredData();

    this.subs.add(
      !this.isLazyLoading && this.initFiltration(),
      this.scrollCallback && this.requestCallbackOnScroll(),
      this.initFilterInputControl(),
    );
  }

  ngOnChanges(changes) {
    if (
      this.isLazyLoading
      && !isEqual(
        this.sortData(this.data),
        this.sortData(this.filteredData)
      )
    ) {
      this.filteredData = this.getFilteredData();
    }

    // TODO: This is a quick-fix for list not being refreshed when data is loaded async
    // until the component is refactored.
    // Later on this logic might be combined with this.isLazyLoading=true case, or removed
    // depending on refactor approach.
    if (!this.isLazyLoading && changes.data.currentValue) {
      this.filteredData = this.getFilteredData();
    }
  }

  initFilterInputControl(): Subscription {
    return this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        filter(value => isString(value))
      )
      .subscribe(value => this.search.emit(value));
  }


  initFiltration(): Subscription {
    return this.searchControl.valueChanges
      .subscribe(value => this.filteredData = this.getFilteredData(value));
  }

  getControlKey(item: object) {
    return get(item, this.filterKey);
  }

  getFilterValue(item: object): string {
    return get(item, this.filterValue) || '';
  }

  checkedItemsLength(): number {
    return this.isMulti(this.checkedItems) ? this.checkedItems.length : 0;
  }

  getFilteredData(value: string = ''): object[] {
    if (this.isLazyLoading) {
      return [...this.data];
    }

    const result = !!value.length
      ? this.data.filter(item => this.getFilterValue(item).toLowerCase().match(this.escapeRegExp(value).toLowerCase()))
      : [...this.data];

    if (this.limitOptions) {
      this.moreOptionsToRender = result.length - this.limitOptions;

      return result.slice(0, this.limitOptions);
    }

    return result;
  }

  isMulti(arg: object[] | object): arg is object[] {
    return isArray(arg);
  }

  isChecked(item: object): boolean {
    const key = this.getControlKey(item);
    const controlKey = this.getControlKey(this.checkedItems);

    return isArray(this.checkedItems)
      ? !!find(this.checkedItems, { key })
      : !!this.checkedItems && controlKey === key;
  }

  iconName(item: object): string {
    const icon = this.multi ? 'square' : 'circle';
    return this.isChecked(item) ? `check-${icon}` : icon;
  }

  setState(item: object): void {
    this.setCheckState(item);
    !!this.checkedItems
      ? this.control.setValue(this.checkedItems)
      : this.control.reset();
  }

  setCheckState(item) {
    if (!!this.isChecked(item) && this.multi && isArray(this.checkedItems)) {
      return this.checkedItems = this.checkedItems
        .filter(checkedItem => {
          return this.getControlKey(checkedItem) !== this.getControlKey(item);
        });
    }

    if (!this.isChecked(item) && this.multi) {
      if (isArray(this.checkedItems)) {
        return this.checkedItems = [...this.checkedItems, item];
      }

      if (!this.checkedItems) {
        return this.checkedItems = [item];
      }
    }

    if (!!this.isChecked(item) && !this.multi) {
      return this.checkedItems = null;
    }

    if (!this.isChecked(item) && !this.multi) {
      return this.checkedItems = item;
    }
  }

  onClear(): void {
    this.checkedItems = this.multi ? [] : null;
    this.control.reset();
    this.onApply();
  }

  onApply(): void {
    this.apply.next();
  }

  onDiscard(): void {
    this.discard.next();
  }

  requestCallbackOnScroll(): Subscription {
    return fromEvent(this.listContainer.nativeElement, 'scroll')
      .pipe(
        map((event: any) => ({
          sH: event.target.scrollHeight,
          sT: event.target.scrollTop,
          cH: event.target.clientHeight,
        })),
        pairwise(),
        filter(positions => this.isUserScrollingDown(positions) && this.isScrollExpectedPercent(positions[1])),
        exhaustMap(() => this.scrollCallback())
      )
      .subscribe(() => { });
  }

  isUserScrollingDown(positions): boolean {
    return positions[0].sT < positions[1].sT;
  }

  isScrollExpectedPercent(position): boolean {
    const currentPosition = position.sT + position.cH;

    if (currentPosition <= this.maxPosition) {
      return false;
    }

    this.maxPosition = currentPosition;
    return currentPosition >= position.sH - this.scrollDistance;
  }

  sortData(data) {
    return data.sort((a, b) => (a[this.filterKey] > b[this.filterKey])
      ? 1
      : ((b[this.filterKey] > a[this.filterKey])
        ? -1
        : 0
      )
    );
  }

  escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
