<ng-container *ngIf="vm$ | async as vm">
    <div class="date-container">
        <app-form-period-date-picker
            [value]="vm.currentDate"
            [dateRangeLabel]="'ci.common.select' | translate"
            [clearable]="false"
            [blacklist]="['']"
            [defaultTimePeriod]="'last7d'"
            [defaultDateRange]="vm.defaultDateRange"
            (dateSelect)="selectTime($event)"
            [timezone]="vm.timezone"
            [periodEntities]="vm.periodEntities"
        ></app-form-period-date-picker>
    </div>
</ng-container>

