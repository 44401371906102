import { FeatureFlagKeys, Permission, AdditionalGrants } from '../../uni-auth';

export interface NavMenu {
    groups: NavMenuGroup[];
}

export interface NavMenuGroup extends NavAccessData {
    items: NavMenuItem[];
    accessData?: NavAccessData;
    groupExpandItem?: NavGroupExpandItem;
}

export interface NavGroupExpandItem {
    iconName: string;
    collapsedLabelKey: string;
    expandedLabelKey: string;
}

export interface NavMenuItem {
    id: string;
    iconName: string;
    labelKey: string;
    isHidden?: boolean;
    link?: NavLink;
    accessData?: NavAccessData;
    items?: NavMenuSubitem[];
}

export interface NavMenuSubitem {
    id: string;
    labelKey: string;
    link: NavLink;
    accessData?: NavAccessData;
}

export interface NavAccessData {
    additionalGrants?: AdditionalGrants[];
    permissions?: NavPermissions;
    featureFlag?: FeatureFlagKeys | string;
    featureFlagOff?: FeatureFlagKeys | string;
}

/**
 * There are 3 possible ways of defining navigation link:
 * 1. ex. { externalUrl: https://docs.unifonic.com }
 * In this case external link will be opened in a new browser tab when user clicks the link.
 *
 * 2. ex. { product: Products.cc }
 * In this case UniNavSettingsService will add productUrl link to the object with product domain build
 * based on product key and current environment, ex, productUrl: communication.integration.dev.unifonic.com.
 * When user clicks the link redirection to productUrl will be performed in the same browser tab.
 *
 * 3. ex. { product: Products.cc; route: '/callers' }
 * Same as in point 2, but also route within a product will be taken into acoount when redirecting.
 * User will be redirected to, for example, communication.integration.dev.unifonic.com/sendername.
 */
export interface NavLink {
    externalUrl?: string;
    product?: Products;
    productUrl?: string;
    route?: string;
}

export interface NavPermissions {
    permissionPath: string;
    permissionsList?: Permission[];
}

export interface NavActiveItems {
    itemId: string;
    subitemId: string;
}

export enum NavItemToggleType {
    none = 'none',
    vertical = 'vertical',
    horizontal = 'horizontal',
}

export enum Products {
    uc = 'uc',
    cc = 'cc',
    ulink = 'ulink',
    flow = 'flow',
    webhook = 'webhook',
    integrations = 'integrations',
    audience = 'audience',
    catalog = 'catalog',
    push = 'push',
    conversation = 'conversation',
    campaign = 'campaign',
    notice = 'notice',
    authenticate = 'authenticate',
    ci = 'ci'
}

export const WhiteLabelDomainProducts = Object.freeze([
    Products.uc,
    Products.cc,
    Products.campaign,
]);

export const whiteLabelProductDomainPrefix = 'communication';

export enum ExternalEnvironments {
  oci = 'oci'
}

export enum Environments {
    local = 'local',
    integration = 'integration',
    charlie = 'charlie',
    bravo = 'bravo',
    preprod = 'preprod',
    preprodOci = 'preprodOci',
    prod = 'prod',
    prodOci = 'prodOci',
}
