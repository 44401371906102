import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

export enum RequestHeaderAccept {
  json = 'json',
  jsonAPI = 'jsonAPI',
}

@Injectable({ providedIn: 'root' })
export class UniHttpClient {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, options?: any, headerAccept?: RequestHeaderAccept): Observable<T> {
    return this.httpClient.get<T>(url, {
      headers: this.getHeaders(headerAccept),
      ...options,
    }) as any;
  }

  getHeaders(headerAccept: RequestHeaderAccept = RequestHeaderAccept.jsonAPI): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: headerAccept === RequestHeaderAccept.jsonAPI ? 'application/vnd.api+json' : 'application/json',
    });
  }
}
