import { Pipe, PipeTransform } from '@angular/core';

import { PhoneNumberUtils } from '../utils/phone-number.utils';

@Pipe({
  name: 'uniPhoneNumberFormat'
})
export class UniPhoneNumberFormatPipe implements PipeTransform {
  transform(value: string): string {
    return PhoneNumberUtils.format(value);
  }
}
