import parsePhoneNumber from 'libphonenumber-js';

export namespace PhoneNumberUtils {
  export function format(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }

    const parsed = parsePhoneNumber(phoneNumber);

    return parsed ? parsed.formatInternational() : phoneNumber;
  }

  export function serialize(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }

    const parsed = parsePhoneNumber(phoneNumber);

    return parsed ? parsed.format('E.164') : phoneNumber;
  }
}
