import { HttpResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import {
  CallerId,
  CallerIdPost,
  PageableResponse,
  CallerIdChannel,
  CallerIdChannelPost,
  CallerIdChannelChargesParams,
  CallerIdChannelCharges,
  CallerIdChannelConfig,
  CallerIdChannelConfigParams,
  CallerIdSubscription,
} from '../../models/caller-id.model';
import { FormError } from '../../modules/uni-form/shared/uni-form.model';

export const SET_CALLER_IDS = '[CallerId] SET_CALLER_IDS';
export const SET_CALLER_IDS_SUCCESS = '[CallerId] SET_CALLER_IDS_SUCCESS';

export const SET_CALLER_IDS_SUBSCRIPTIONS = '[CallerId] SET_CALLER_IDS_SUBSCRIPTIONS';
export const SET_CALLER_IDS_SUBSCRIPTIONS_SUCCESS = '[CallerId] SET_CALLER_IDS_SUBSCRIPTIONS_SUCCESS';

export const SET_SECONDARY_CALLER_IDS = '[CallerId] SET_SECONDARY_CALLER_IDS';
export const SET_SECONDARY_CALLER_IDS_SUCCESS = '[CallerId] SET_SECONDARY_CALLER_IDS_SUCCESS';

export const POST_CALLER_ID = '[CallerId] POST_CALLER_ID';
export const POST_CALLER_ID_SUCCESS = '[CallerId] POST_CALLER_ID_SUCCESS';
export const POST_CALLER_ID_ERROR = '[CallerId] POST_CALLER_ID_ERROR';

export const DELETE_CALLER_IDS = '[CallerId] DELETE_CALLER_IDS';
export const DELETE_CALLER_IDS_SUCCESS = '[CallerId] DELETE_CALLER_IDS_SUCCESS';
export const DELETE_CALLER_IDS_ERROR = '[CallerId] DELETE_CALLER_IDS_ERROR';

export const ACCOUNT_HAS_ACTIVE_CALLER_ID = '[CallerId] ACCOUNT_HAS_ACTIVE_CALLER_ID';
export const ACCOUNT_HAS_ACTIVE_CALLER_ID_SUCCESS = '[CallerId] ACCOUNT_HAS_ACTIVE_CALLER_ID_SUCCESS';

export const SET_CALLER_ID_CHANNELS = '[CallerId] SET_CALLER_ID_CHANNELS';
export const SET_CALLER_ID_CHANNELS_SUCCESS = '[CallerId] SET_CALLER_ID_CHANNELS_SUCCESS';

export const SET_CALLER_ID_CHANNEL_CHARGES = '[CallerId] SET_CALLER_ID_CHANNEL_CHARGES';
export const SET_CALLER_ID_CHANNEL_CHARGES_SUCCESS = '[CallerId] SET_CALLER_ID_CHANNEL_CHARGES_SUCCESS';

export const SET_CALLER_ID_CHANNEL_CONFIG = '[CallerId] SET_CALLER_ID_CHANNEL_CONFIG';
export const SET_CALLER_ID_CHANNEL_CONFIG_SUCCESS = '[CallerId] SET_CALLER_ID_CHANNEL_CONFIG_SUCCESS';

export const POST_CALLER_ID_CHANNEL = '[CallerId] POST_CALLER_ID_CHANNEL';
export const POST_CALLER_ID_CHANNEL_SUCCESS = '[CallerId] POST_CALLER_ID_CHANNEL_SUCCESS';
export const POST_CALLER_ID_CHANNEL_ERROR = '[CallerId] POST_CALLER_ID_CHANNEL_ERROR';

export class SetCallerIds implements Action {
  readonly type = SET_CALLER_IDS;
  constructor(public params: Params) { }
}

export class SetCallerIdsSuccess implements Action {
  readonly type = SET_CALLER_IDS_SUCCESS;
  constructor(public res: HttpResponse<CallerId[]>) { }
}

export class SetCallerIdsSubscriptions implements Action {
  readonly type = SET_CALLER_IDS_SUBSCRIPTIONS;
  constructor(public params: Params) { }
}

export class SetCallerIdsSubscriptionsSuccess implements Action {
  readonly type = SET_CALLER_IDS_SUBSCRIPTIONS_SUCCESS;
  constructor(public res: HttpResponse<PageableResponse<CallerIdSubscription>>) { }
}

export class SetSecondaryCallerIds implements Action {
  readonly type = SET_SECONDARY_CALLER_IDS;
  constructor(public params: Params) { }
}

export class SetSecondaryCallerIdsSuccess implements Action {
  readonly type = SET_SECONDARY_CALLER_IDS_SUCCESS;
  constructor(public res: HttpResponse<CallerId[]>) { }
}

export class PostCallerId implements Action {
  readonly type = POST_CALLER_ID;
  constructor(public payload: CallerIdPost) { }
}

export class PostCallerIdSuccess implements Action {
  readonly type = POST_CALLER_ID_SUCCESS;
  constructor() { }
}

export class PostCallerIdError implements Action {
  readonly type = POST_CALLER_ID_ERROR;
  constructor(public errors: FormError[]) { }
}
export class DeleteCallerIds implements Action {
  readonly type = DELETE_CALLER_IDS;
  constructor(public ids: string[]) { }
}

export class DeleteCallerIdsSuccess implements Action {
  readonly type = DELETE_CALLER_IDS_SUCCESS;
  constructor() { }
}

export class DeleteCallerIdsError implements Action {
  readonly type = DELETE_CALLER_IDS_ERROR;
  constructor(public errors: FormError[]) { }
}

export class AccountHasActiveCallerId implements Action {
  readonly type = ACCOUNT_HAS_ACTIVE_CALLER_ID;
}

export class AccountHasActiveCallerIdSuccess implements Action {
  readonly type = ACCOUNT_HAS_ACTIVE_CALLER_ID_SUCCESS;
  constructor(public hasActiveCallerId: boolean) { }
}

export class SetCallerIdChannels implements Action {
  readonly type = SET_CALLER_ID_CHANNELS;
  constructor(public params?: Params) { }
}

export class SetCallerIdChannelsSuccess implements Action {
  readonly type = SET_CALLER_ID_CHANNELS_SUCCESS;
  constructor(public res: HttpResponse<PageableResponse<CallerIdChannel>>) { }
}

export class SetCallerIdChannelCharges implements Action {
  readonly type = SET_CALLER_ID_CHANNEL_CHARGES;
  constructor(public params: CallerIdChannelChargesParams) { }
}

export class SetCallerIdChannelChargesSuccess implements Action {
  readonly type = SET_CALLER_ID_CHANNEL_CHARGES_SUCCESS;
  constructor(public res: HttpResponse<CallerIdChannelCharges>) { }
}

export class SetCallerIdChannelConfig implements Action {
  readonly type = SET_CALLER_ID_CHANNEL_CONFIG;
  constructor(public params: CallerIdChannelConfigParams) { }
}

export class SetCallerIdChannelConfigSuccess implements Action {
  readonly type = SET_CALLER_ID_CHANNEL_CONFIG_SUCCESS;
  constructor(public res: HttpResponse<CallerIdChannelConfig>) { }
}

export class PostCallerIdChannel implements Action {
  readonly type = POST_CALLER_ID_CHANNEL;
  constructor(public payload: CallerIdChannelPost) { }
}

export class PostCallerIdChannelSuccess implements Action {
  readonly type = POST_CALLER_ID_CHANNEL_SUCCESS;
  constructor() { }
}

export class PostCallerIdChannelError implements Action {
  readonly type = POST_CALLER_ID_CHANNEL_ERROR;
  constructor(public errors: FormError[]) { }
}

export type All =
  | PostCallerId
  | PostCallerIdSuccess
  | PostCallerIdError
  | SetCallerIds
  | SetCallerIdsSuccess
  | SetCallerIdsSubscriptions
  | SetCallerIdsSubscriptionsSuccess
  | SetSecondaryCallerIds
  | SetSecondaryCallerIdsSuccess
  | DeleteCallerIds
  | DeleteCallerIdsError
  | DeleteCallerIdsSuccess
  | AccountHasActiveCallerId
  | AccountHasActiveCallerIdSuccess
  | SetCallerIdChannels
  | SetCallerIdChannelsSuccess
  | SetCallerIdChannelConfig
  | SetCallerIdChannelConfigSuccess
  | SetCallerIdChannelCharges
  | SetCallerIdChannelChargesSuccess
  | PostCallerIdChannel
  | PostCallerIdChannelSuccess
  | PostCallerIdChannelError;
