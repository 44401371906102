import { NgModule } from '@angular/core';

import { UniBoxModule } from '../uni-box';
import { UniRichRadioButtonComponent } from './components/uni-rich-radio-button/uni-rich-radio-button.component';
import { UniFormModule } from '../uni-form';

@NgModule({
  imports: [
    UniBoxModule,
    UniFormModule
  ],
  exports: [
    UniRichRadioButtonComponent
  ],
  declarations: [UniRichRadioButtonComponent],
  providers: [],
})
export class UniRichRadioButtonModule {}
