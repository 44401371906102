import { getCountryCallingCode, parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js/mobile';
import { uniq } from 'lodash';
import { Subscription } from 'rxjs';
import { CampaignRecipientsMethod } from '../../shared/uni-campaign.model';

import { Component, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { Country } from '../../../uni-countries/shared/uni-countries.model';
import { UniAudienceFacade } from '../../../uni-audience/shared/uni-audience.facade';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { CampaignRecipientsFileComponent } from '../campaign-recipients-file/campaign-recipients-file.component';
import { CampaignRecipientsManuallyComponent } from '../campaign-recipients-manually/campaign-recipients-manually.component';
import { getCorrectNumberFormat } from '../../shared/uni-campaign.utils';

@Component({
  selector: 'cc-campaign-recipients',
  templateUrl: './campaign-recipients.component.html',
})
export class CampaignRecipientsComponent extends AutoUnsubscribe implements OnInit {
  @ViewChild(CampaignRecipientsFileComponent, { static: false }) fileCompt: CampaignRecipientsFileComponent;
  @ViewChild(CampaignRecipientsManuallyComponent, { static: false }) manualCompt: CampaignRecipientsManuallyComponent;
  @Input() form: FormGroup;
  @Input() isActive = true;
  @Input() isEditable = true;
  @Input() isVoice = false;

  isLoaderActive = false;
  isModalActive = false;
  invalidNumbers: string[] = [];
  validNumbers: string[] = [];
  chosenCountry: Country;
  countries: Country[];
  campaignRecipientsMethod = CampaignRecipientsMethod;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get autocompleteControl(): FormControl {
    return this.contentForm.get('autocomplete') as FormControl;
  }

  get isAutocompleteActiveControl(): FormControl {
    return this.contentForm.get('isAutocompleteActive') as FormControl;
  }

  get methodControl(): FormControl {
    return this.contentForm.get('method') as FormControl;
  }

  get recipientsForm(): FormGroup {
    return this.contentForm.get('recipients') as FormGroup;
  }

  get phoneNumbersControl(): FormControl {
    return this.recipientsForm.get('phoneNumbers') as FormControl;
  }

  get phoneNumbersInputControl(): FormControl {
    return this.recipientsForm.get('phoneNumbersInput') as FormControl;
  }

  get fileControl(): FormControl {
    return this.recipientsForm.get('file') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.recipientsForm.get('audienceGroups') as FormControl;
  }

  get fileRecipientsControl(): FormControl {
    return this.recipientsForm.get('fileRecipients') as FormControl;
  }

  get searchGroupsControl(): FormControl {
    return this.recipientsForm.get('searchGroupsInput') as FormControl;
  }

  constructor(
    private campaignFacade: UniCampaignsFacade,
    private uniAudienceFacade: UniAudienceFacade,
    public campaignFormFactory: UniCampaignFormFactory,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    if (!this.isActive) {
      this.recipientsForm.disable();
      return;
    }

    this.campaignFacade.setAutocompleteCountries();
    this.recipientsForm.enable();
    if(!this.methodControl.value) {
      this.resetFile();
    }
    this.subs.add(
      this.setCountries(),
      this.setChosenCountry(),
      this.watchLoader()
    );
  }

  onSubmit(): void {
    const { value } = this.phoneNumbersInputControl;

    if (!value) {
      return;
    }
    const numbers: string[] = value
      .replace(/ /g, '')
      .split(',');

    if (!numbers.length || this.phoneNumbersInputControl.invalid) {
      return;
    }

    const code = this.chosenCountry ? this.chosenCountry.code as CountryCode : 'SA';
    const callingCode = this.chosenCountry ? this.chosenCountry.callingCode : getCountryCallingCode('SA').toString();
    this.invalidNumbers = [];
    this.validNumbers = [];

    uniq(numbers).forEach(data => {
      let formattedNumber: string;
      let parsedNumber;

      if (this.isAutocompleteActiveControl.value) {
        formattedNumber = getCorrectNumberFormat(data, callingCode);
        parsedNumber = parsePhoneNumberFromString(formattedNumber, code);
      } else {
        formattedNumber = getCorrectNumberFormat(data);
        parsedNumber = parsePhoneNumberFromString(formattedNumber);
      }

      if (!!parsedNumber && parsedNumber.isValid()) {
        this.validNumbers.push(data);
      } else {
        this.invalidNumbers.push(data);
      }
    });

    if (!this.invalidNumbers.length) {
      this.addNumbers();
      return;
    }

    (this.fileCompt || this.manualCompt).input.nativeElement.blur();
    this.toggleModal();
  }

  addNumbers(): void {
    const currentValue = this.phoneNumbersControl.value || [];

    this.parsePhonePrefix();

    const uniquNumbers = uniq([ ...currentValue, ...this.validNumbers ]);

    this.phoneNumbersControl.setValue(uniquNumbers);
    this.phoneNumbersInputControl.reset();
    this.validNumbers = [];
    this.invalidNumbers = [];
    this.isModalActive = false;
  }

  parsePhonePrefix() {
    const code = this.chosenCountry ? this.chosenCountry.code as CountryCode : 'SA';
    const callingCode = this.chosenCountry ? this.chosenCountry.callingCode : getCountryCallingCode('SA').toString();

    this.validNumbers = this.validNumbers.map(phoneNumber => {
      if (!this.isAutocompleteActiveControl.value) {
        const checkedNumber = getCorrectNumberFormat(phoneNumber);

        return parsePhoneNumberFromString(checkedNumber).number as string;
      }

      const num = getCorrectNumberFormat(phoneNumber, callingCode);

      return parsePhoneNumberFromString(num, code).number as string;
    });
  }

  toggleModal(): void {
    this.isModalActive = !this.isModalActive;
  }

  setChosenCountry(): Subscription {
    return this.autocompleteControl.valueChanges.subscribe(chosenCountry =>
      this.chosenCountry = chosenCountry
    );
  }

  resetFile() {
    if (
      !this.fileControl
      || !this.fileControl.value
      || !this.fileControl.value.name
      || this.fileControl.value.name === ''
    ) {
      this.methodControl.patchValue(this.campaignRecipientsMethod.manually);
      this.campaignFormFactory.customRecipients(this.recipientsForm);
    }
  }

  setCountries(): Subscription {
    return this.campaignFacade.autocompleteCountries$.subscribe(countries => {
      if (!!countries) {
        const defaultCountry = countries.find(country => country.code === 'SA');
        this.autocompleteControl.setValue(defaultCountry);
      }

      this.countries = countries;
    });
  }

  watchLoader(): Subscription {
    return this.uniAudienceFacade.isLoading$
      .subscribe(isActive => {
        this.isLoaderActive = isActive;
        this.cdr.detectChanges();
      });
  }

  setCustomRecipients(): void {
    this.campaignFormFactory.customRecipients(this.recipientsForm);
    this.searchGroupsControl.reset();
  }

  setFileRecipients(): void {
    this.campaignFormFactory.fileRecipients(this.recipientsForm);
    this.searchGroupsControl.reset();
  }
}
