import { Component, HostBinding, Input } from '@angular/core';

import { UniSnackbarFacade } from '../../shared';

@Component({
  selector: 'uni-snackbar',
  templateUrl: 'uni-snackbar.component.html',
  styleUrls: ['./uni-snackbar.component.scss'],
})
export class UniSnackbarComponent {
  snackbars$ = this.uniSnackbarFacade.snackbars$;
  @HostBinding('class.is-navigation') @Input() isNavigation = true;

  constructor(private uniSnackbarFacade: UniSnackbarFacade) { }
}
