import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UniPriceService } from '../../shared/uni-price.service';

@Component({
  selector: 'uni-price',
  templateUrl: './uni-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UniPriceComponent {
  @Input() amount: number;
  @Input() currency: string;
  @Input() fractionDigits?: number;
  @Input() type: 'long' | 'short' | 'default' = 'default';

  constructor(
    private uniPriceService: UniPriceService,
  ) {}

  get price(): string {
    const amount = this.amount || 0;

    switch (this.type) {
      case 'long':
        return this.uniPriceService.getLongPrice(amount, this.currency);
      case 'short':
        return this.uniPriceService.getShortPrice(amount, this.currency);
      default:
        return this.uniPriceService.getPrice(amount, this.currency, this.fractionDigits);
    }
  }
}
