import { concat, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as QueryActions from '../../../store/query/query.actions';
import { UniAuthRepository } from '../shared/uni-auth.repository';
import * as UniAuthActions from './uni-auth.actions';
import { GET_FEATURE_FLAGS_QUERY, GET_GLOBAL_SETTINGS_QUERY, GET_USER_ME_QUERY } from './uni-auth.state';
import { UniAuthFacade } from '../shared/uni-auth.facade';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authRepository: UniAuthRepository,
    private uniAuthFacade: UniAuthFacade
  ) { }

  @Effect() getUserMe$ = this.actions$
    .pipe(
      ofType<UniAuthActions.SetUserMe>(UniAuthActions.SET_USER_ME),
      mergeMap(({isLoader}) => concat(
        of(new QueryActions.QueryInProgress(GET_USER_ME_QUERY)),
        this.authRepository
          .getUserMe(isLoader)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_USER_ME_QUERY, data)),
              of(new UniAuthActions.SetUserMeSuccess(data)),
              of(new UniAuthActions.SetFeatureFlags(data)),
            )),
            catchError(error => of(new QueryActions.QueryFailure(GET_USER_ME_QUERY, error))),
          ),
        ),
      ),
    );

  @Effect() getGlobalSettings$ = this.actions$
    .pipe(
      ofType<UniAuthActions.SetGlobalSettings>(UniAuthActions.SET_GLOBAL_SETTINGS),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_GLOBAL_SETTINGS_QUERY)),
        this.authRepository
          .getGlobalSettings()
          .pipe(
            mergeMap(respose => concat(
              of(new QueryActions.QuerySuccess(GET_GLOBAL_SETTINGS_QUERY, respose)),
              of(new UniAuthActions.SetGlobalSettingsSuccess(respose)),
              of(new UniAuthActions.SetFeatureFlags()),
            )),
            catchError(error => of(new QueryActions.QueryFailure(GET_GLOBAL_SETTINGS_QUERY, error))),
          ),
        ),
      ),
    );

  @Effect() getFeatureFlags$ = this.actions$
    .pipe(
      ofType<UniAuthActions.SetFeatureFlags>(UniAuthActions.SET_FEATURE_FLAGS),
      tap(({ data }) => this.authRepository.getFeatureFlags(data)),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_FEATURE_FLAGS_QUERY))),
      ),
    );

  @Effect() getFeatureFlagsSuccess$ = this.actions$
    .pipe(
      ofType<UniAuthActions.SetFeatureFlagsSuccess>(UniAuthActions.SET_FEATURE_FLAGS_SUCCESS),
      mergeMap(({data}) => concat(
        of(new QueryActions.QuerySuccess(GET_FEATURE_FLAGS_QUERY, data))),
      ),
    );

  @Effect({ dispatch: false }) patchUserSuccess$ = this.actions$
    .pipe(
      ofType<UniAuthActions.PatchUserSuccess>(UniAuthActions.PATCH_USER_SUCCESS),
      tap(({ user }) => this.uniAuthFacade.setUser(user)),
    );
}
