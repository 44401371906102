import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavItemToggleType } from '../../shared';

@Component({
  selector: 'uni-nav-new-item-toggle',
  templateUrl: './uni-nav-item-toggle.component.html',
  styleUrls: ['./uni-nav-item-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavItemToggleComponent {
  @Input() toggleType: NavItemToggleType;
  @Input() isExpanded = false;
}
