<uni-label [displayInline]="true">
  {{ 'campaign.scheduleCampaign' | translate }}
</uni-label>
<uni-tooltip uniLabelTip [resetMarginLeft]="true" *ngIf="isVoice">
  {{ 'campaign.new.tip.schedule' | translate }}
</uni-tooltip>
<uni-form-container [formGroup]="form">
  <uni-toggle
    formControlName="isScheduled"
    [activeLabel]="'label.scheduleOn' | translate"
    [inactiveLabel]="'label.scheduleOff' | translate"
    [isDisabled]="isDisabledType"
  ></uni-toggle>
  <uni-text-info *ngIf="!isVoice && !isDisabledType">
    {{ 'campaign.tip.scheduleCampaign' | translate }}
  </uni-text-info>
</uni-form-container>
