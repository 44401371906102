import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { User } from '../modules';

interface Options {
  locale: string;
  roles: string;
  user_created_at: string;
  user_id: string;
  account_created_at?: string;
  account_creation_type?: 'self_serve' | 'crm';
  account_id?: string;
  account_name?: string;
  country?: string;
  parent_account_id?: string;
  sector?: string;
  white_labeled_account?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UniSurvicateService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  init(renderer2: Renderer2, workspaceId: string, user: User) {
    this.configure(renderer2, user);

    const script = renderer2.createElement('script');
    script.text = `
      (function(w) {
        var s = document.createElement('script');
        s.src = 'https://survey.survicate.com/workspaces/${workspaceId}/web_surveys.js';
        s.async = true;
        var e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
      })(window);
    `;

    renderer2.appendChild(this.document.body, script);
  }

  private configure(renderer2: Renderer2, user: User): void {
    const options = this.createOptions(user);
    const script = renderer2.createElement('script');
    script.text = `
      (function(opts) {
        opts.traits = ${JSON.stringify(options)};
      })(window._sva = window._sva || {});
    `;

    renderer2.appendChild(this.document.body, script);
  }

  private createOptions(user: User): Options {
    const options: Options = {
      locale: user.locale,
      roles: user.roles.toString(),
      user_created_at: user.registrationDate,
      user_id: user.id,
    };
    const { account } = user;

    if (account) {
      options.account_created_at = account.registrationDate;
      options.account_creation_type = !!account.isSelfServe ? 'self_serve' : 'crm';
      options.account_id = account.id;
      options.account_name = account.name;
      options.country = account.country;
      options.parent_account_id = account.parentAccount?.id;
      options.sector = account.accountSector;
      options.white_labeled_account = account.isWhiteLabel;
    }

    return options;
  }
}
