import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { InputKeyPattern } from '../../directives/input.directive';
import { UniPriceService } from '../../../uni-price';

@Component({
  selector: 'uni-input-price',
  templateUrl: './uni-input-price.component.html',
  styleUrls: ['./uni-input-price.component.scss'],
})
export class UniInputPriceComponent {
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() currency = 'USD';
  @Input() placeholder = '1.5';
  @Input() keyPattern: InputKeyPattern = 'price';
  @Input() isSummary = false;

  constructor(
    private uniPriceService: UniPriceService,
  ) {}

  get translatedPlaceholder(): string {
    return this.uniPriceService.getPrice(parseFloat(this.placeholder), this.currency);
  }
}
