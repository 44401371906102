import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-form-multi-autocomplete-header',
  templateUrl: './uni-form-multi-autocomplete-header.component.html',
  styleUrls: ['./uni-form-multi-autocomplete-header.component.scss']
})
export class UniFormMultiAutocompleteHeaderComponent {
  @Input() heading: string;
  @Input() checkedItemsLength: number;
  @Input() multi: boolean;
  @Output() clear = new EventEmitter();
  @Output() close = new EventEmitter();

  onClear(): void {
    this.clear.next();
  }

  onClose(): void {
    this.close.next();
  }
}
