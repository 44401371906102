import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, HostBinding } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'uni-audio',
  templateUrl: './uni-audio.component.html',
  styleUrls: ['./uni-audio.component.scss']
})
export class UniAudioComponent implements AfterViewInit {
  @Input() src: any;
  @Input() autoPlay = false;
  @Input() downloadAudio = true;
  @HostBinding('class.is-display-inline') @Input() displayInline = false;
  @HostBinding('class.is-align-top') @Input() alignTop = false;
  isPlaying = false;
  @ViewChild('volumeLevel', { static: false }) volumeLevel: MatSlider;
  @ViewChild('audio', { static: false }) set audioRef(ref: ElementRef<HTMLAudioElement>) {
    this.audio = ref.nativeElement;
    this.src = this.domSanitizer.bypassSecurityTrustUrl(this.src);
    this.isPlaying = this.autoPlay;
    this.cdr.detectChanges();
  }

  audio: HTMLAudioElement;
  time: string;
  audioProgress = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer
  ) {}

  ngAfterViewInit() {
    this.audio.ontimeupdate = () => {
      const { currentTime, duration } = this.audio;
      this.audioProgress = (currentTime / duration) || 0;
      this.time = this.getTime(this.audio);
      this.cdr.detectChanges();
    };
    this.audio.onloadeddata = () => this.time = this.getTime(this.audio);
    this.audio.onended = () => this.isPlaying = false;
  }

  onPlay(): void {
    this.isPlaying
      ? this.audio.pause()
      : this.audio.play();

    this.isPlaying = !this.isPlaying;
  }

  getTime({ currentTime, duration }: HTMLAudioElement): string {
    const currentTimeFormatted = this.getTimeFormatted(currentTime);

    if (duration === Infinity) {
      return currentTimeFormatted;
    }

    const durationFormatted = this.getTimeFormatted(duration);

    return `${ currentTimeFormatted } / ${ durationFormatted }`;
  }

  getTimeFormatted(time: number): string {
    let timeSec: string | number = Math.round(time % 60);
    const timeMin = Math.floor((time / 60) % 60);

    if (timeSec < 10) {
      timeSec = '0' + timeSec;
    }

    return `${ timeMin }:${ timeSec }`;
  }
}
