import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UniLinkSize } from './uni-link.model';

export type UniLinkType = 'primary' | 'secondary';

@Component({
  selector: 'uni-link',
  templateUrl: './uni-link.component.html',
  styleUrls: [
    './uni-link.component.scss',
    './uni-link-sizes.component.scss',
  ],
})
export class UniLinkComponent {
  @Input() class = '';
  @Input() path: string;
  @Input() target = '_self';
  @Input() routerLink = '';
  @Input() type: UniLinkType = 'primary';
  @Input() icon: string;
  @Input() iconRight: string;
  @Input() tabindex = '-1';
  @Input() isDisabled = false;
  @Input() isFlex = false;
  @Input() size = UniLinkSize.medium;

  @HostListener('click')
  onClick() {
    if (this.isDisabled) {
      return;
    }

    if (!!this.routerLink) {
      this.router.navigate([this.routerLink]);
    }
 }

 @HostBinding('class') get getClasses() {
  return [
    `${this.class} uni-link--${this.type}`,
    this.isDisabled ? 'is-disabled' : '',
    this.isFlex ? 'is-flex' : '',
    `uni-link--size-${this.size}`
  ].join(' ');
 }
  constructor(private router: Router) {}

  getIconSize(size): string {
    switch (size) {
      case UniLinkSize.large: {
        return '24';
      }
      case UniLinkSize.small: {
        return '16';
      }
      default: {
        return '20';
      }
    }
  }

  getFixedName(name: string): string {
    return name.replace('fa-', '');
  }
}
