import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { QueryConfig } from './http-query.model';
import { HttpQueryFacade } from './store/http-query.facade';
import * as QueryActions from './store/http-query.actions';
import { DOCUMENT } from '@angular/common';
import { Environment } from '../../utils/environment.utils';


@Injectable()
export class HttpQueryInterceptor implements HttpInterceptor {
  private queryNameKey = 'queryName';
  private queryGroupsKey = 'queryGroups';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpQueryFacade: HttpQueryFacade
  ) { }

  getConfig(request: HttpRequest<unknown>): QueryConfig {
    return {
      name: request.headers.get(this.queryNameKey),
      groups: request.headers.getAll(this.queryGroupsKey)
    };
  }

  parseRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return !request.headers.has(this.queryNameKey)
      ? request
      : request.clone({
        headers: request.headers
          .delete(this.queryNameKey)
          .delete(this.queryGroupsKey)
      });
  }

  convHttpInterceptor(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let pageIndex = '0';
    if (!!request.params.get('page')) {
      const idx = parseInt(request.params.get('page'), 10) - 1;
      pageIndex = (Number.isInteger(idx) && idx > 0 ? idx : 0).toString();
    }

    return request.clone({
      params: request.params.set('page', pageIndex)
    });
  }

  handleSuccess(response: HttpEvent<unknown>, queryConfig: QueryConfig): void {
    if (response instanceof HttpResponse) {
      this.httpQueryFacade.dispatch(new QueryActions.success(queryConfig, response));
    }
  }

  handleError(error: HttpErrorResponse, queryConfig: QueryConfig): Observable<never> {
    if (error.status === 404) {
      this.document.location.href = `${ Environment.getMainHost() }/404`;
    }

    this.httpQueryFacade.dispatch(new QueryActions.failure(queryConfig, error));
    return throwError(error);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | null {
    const queryConfig = this.getConfig(request);

    // TODO: Move to UC (hack that might break Conv application)
    // if (request.url.includes('conv') && !window.location.host.includes('conv')) {
    //   request = this.convHttpInterceptor(request);
    // }

    if (!queryConfig.name) {
      return next.handle(request);
    }

    this.httpQueryFacade.dispatch(new QueryActions.inProgress(queryConfig));

    return next
      .handle(this.parseRequest(request))
      .pipe(
        tap(response => this.handleSuccess(response, queryConfig)),
        catchError(error => this.handleError(error, queryConfig)),
      );
  }
}
