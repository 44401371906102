<form [formGroup]="recipientsForm" (submit)="onSubmit.emit()">
  <uni-label *ngIf="isVoice">
    {{ 'label.enterMobileNumbers' | translate }}
  </uni-label>
  <uni-form-field
    *ngIf="isEditable"
    [margin]="false"
    [groupMargin]="groupMargin && phoneNumbersControl?.value?.length"
  >
    <uni-form-field
      [isButton]="true"
      [margin]="false"
    >
      <input
        type="text"
        formControlName="phoneNumbersInput"
        [placeholder]="'campaign.recipientsPlaceholder' | translate"
        keyPattern="phones"
        class="campaign-recipients__input"
        uniInput
      >
      <uni-button
        *ngIf="isActive && !contentForm.disabled"
        [style]="'primary'"
        [isInputRelated]="true"
        type="submit"
      >
        {{ 'action.add' | translate }}
      </uni-button>
    </uni-form-field>
  </uni-form-field>
</form>
