<uni-nav-item
  class="uni-menu-content--mobile"
  *ngIf="isImpresonationSession"
  (click)="onStopImpersonation()"
>
  <uni-nav-item-icon>
    <uni-icon name="stop-circle"></uni-icon>
  </uni-nav-item-icon>
  <uni-nav-item-name>{{ 'impersonation.stopImpesonation' | translate }}</uni-nav-item-name>
</uni-nav-item>

<uni-nav-item (click)="onProfile()">
  <uni-nav-item-icon>
    <uni-icon name="user"></uni-icon>
  </uni-nav-item-icon>
  <uni-nav-item-name>{{ 'label.profile' | translate }}</uni-nav-item-name>
</uni-nav-item>

<uni-nav-item
  *ngIf="isAccountSwitchEnabled$ | async"
  (click)="onSwitchAccount()"
>
  <uni-text-body
    [color]="uniTextColor.secondary"
    level="050"
    class="uni-nav-menu-content__label"
  >
   {{ account }}
  </uni-text-body>
  <uni-nav-item-icon>
    <uni-icon name="shuffle"></uni-icon>
  </uni-nav-item-icon>
  <uni-nav-item-name>
    {{ 'label.switchAccount' | translate }}
  </uni-nav-item-name>
</uni-nav-item>

<uni-nav-item
  *ngIf="isActivePaymentMenu$ | async"
  (click)="onPayment()"
>
  <uni-nav-item-icon>
    <uni-icon name="money-check"></uni-icon>
  </uni-nav-item-icon>
  <uni-nav-item-name> {{ 'label.payments' | translate }}</uni-nav-item-name>
</uni-nav-item>

<uni-nav-item
  *ngIf="isTroubleshootMenuVisible$ | async"
  (click)="onTroubleshoot()"
>
  <uni-nav-item-name
    [caption]="troubleshootModeCaption$ | async"
  >
    {{ 'troubleshooting.label.troubleshootMode' | translate }}
  </uni-nav-item-name>
</uni-nav-item>

<uni-nav-item (click)="logout()">
  <uni-nav-item-icon>
    <uni-icon name="sign-out-alt"></uni-icon>
  </uni-nav-item-icon>
  <uni-nav-item-name>{{ 'label.logout' | translate }}</uni-nav-item-name>
</uni-nav-item>

