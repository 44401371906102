<a class="uni-top-bar-logo" [href]="ucUrl" *ngIf="!(newMenuEnabled$|async); else hrefToUC">
  <ng-container *ngTemplateOutlet="logo"></ng-container>
</a>

<ng-template #hrefToUC>
  <a class="uni-top-bar-logo" [href]="ucUrl">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </a>
</ng-template>

<ng-template #logo>
  <img class="uni-top-bar-logo__image" [src]="menuLogo" alt="Unifonic"/>
</ng-template>
