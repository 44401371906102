import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'uni-tooltip-cloud',
  templateUrl: './uni-tooltip-cloud.component.html',
  styleUrls: ['./uni-tooltip-cloud.component.scss'],
})
export class UniTooltipCloudComponent {
  @ViewChild('contentEl') contentEl: ElementRef;
  @Input() isActive = false;
  @Input() isFixed = false;
  @Input() type = 'info';
  @Input() position: 'bottom' | 'top' | 'right' = 'bottom';
  @Input() mouseEnterDelay = 0;
  @Input() isVisible = true;
  @Output() isActiveChange = new EventEmitter<boolean>();

  private mouseEnterTimeout: number;

  constructor() { }

  @HostBinding('class') get getClasses(): string {
    return `${this.isActive && this.isVisible ? 'is-active' : ''}
      is-${this.type}
      is-${this.position}`;
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.isFixed) {
      this.positionFixedElement();
    }

    this.mouseEnterTimeout = window.setTimeout(() => this.setState(true), this.mouseEnterDelay);
  }

  @HostListener('mouseleave')
  onMouseLeave() {

    if (this.mouseEnterTimeout) {
      window.clearTimeout(this.mouseEnterTimeout);
      this.mouseEnterTimeout = undefined;
    }

    this.setState(false);
  }

  setState(state: boolean) {
    this.isActive = state;
    this.isActiveChange.emit(state);
  }

  private positionFixedElement(): void {
    const nativeEl = this.contentEl.nativeElement;
    const parentRect = nativeEl.parentElement.getBoundingClientRect();
    const isRtl = window.getComputedStyle(nativeEl).direction === 'rtl';
    nativeEl.style.position = 'fixed';
    nativeEl.style.left = `${parentRect.x + (parentRect.width / 2)}px`;

    switch (this.position) {
      case 'bottom': {
        nativeEl.style.top = `${parentRect.y + parentRect.height}px`;
        break;
      }
      case 'top': {
        nativeEl.style.width = 'auto';
        nativeEl.style.minWidth = 'auto';
        nativeEl.style.bottom = `${parentRect.y + (parentRect.height / (isRtl ? 2 : 1)) + 16}px`;
        break;
      }
      case 'right': {
        nativeEl.style.right = 'auto';
        nativeEl.style.left = isRtl ? `${parentRect.x - nativeEl.clientWidth}px` : `${parentRect.x + parentRect.width}px`;
        nativeEl.style.top = `${parentRect.y}px`;
        break;
      }
      default: {
        delete nativeEl.style.position;
      }
    }
  }
}
