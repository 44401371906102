<uni-campaign-csv-limt-info></uni-campaign-csv-limt-info>
<uni-form-field [margin]="false">
  <label>{{ 'label.recipientNumbers' | translate }}</label>
  <uni-form-field
    [isButton]="isActive"
    [isRow]="!isActive"
    [margin]="!isActive"
  >
    <input
      [placeholder]="(isLoading ? 'label.loading' : 'campaign.uploadExcelOrCSV') | translate"
      (click)="uploadFromFile()"
      [(ngModel)]="fileName"
      [disabled]="!isActive || contentForm.disabled"
      [ngModelOptions]="{standalone: true}"
      uniInput
      readonly
    >
    <uni-button
      *ngIf="fileControl && fileControl.value && isActive && !contentForm.disabled"
      [style]="'primary'"
      (click)="deleteFile()"
    >
      {{ 'action.delete' | translate }}
    </uni-button>
    <uni-link
      *ngIf="!isActive"
      class="campaign-recipients__file-link"
      target="_blank"
      icon="fa-download"
      [isDisabled]="contentForm.disabled"
      (click)="getFile()"
    >
    </uni-link>
  </uni-form-field>
  <uni-text-info
    *ngIf="isActive"
    class="campaign-recipients__example"
  >
    {{ 'campaign.downloadSampleFile' | translate }}
    <uni-link path="/media/samples/List of recipients - example file.xlsx"
      target="_blank"
    >
     {{ 'campaign.excel' | translate }}
    </uni-link>
    {{ 'campaign.or' | translate }}
    <uni-link
      path="/media/samples/List of recipients - example file.csv"
      target="_blank"
    >
     {{ 'campaign.CSV' | translate }}
  </uni-link>
    {{ 'campaign.format' | translate }}
  </uni-text-info>
  <uni-campaign-csv-error
    *ngIf="hasFileLimitError"
    [errorMessage]="'campaign.csvLimitError'"
  >
  </uni-campaign-csv-error>
  <uni-campaign-csv-error
    *ngIf="isInvalidEncoding"
    [errorMessage]="'campaign.invalidEncoding'"
  >
  </uni-campaign-csv-error>
  <div
    *ngIf="(isActive || isEditable) && fileRecipientsControl.value"
    class="campaign-recipients__success"
  >
    {{ 'campaign.new.recipients.uploadSuccess'  | translate }}
  </div>
  <div
    *ngIf="!isLoading && (isActive || isEditable) && fileRecipientsControl.value && fileRecipientsControl.value <= 0"
    class="campaign-recipients__error"
  >
    {{ 'campaign.new.recipients.empty'  | translate }}
  </div>
</uni-form-field>

<input
  *ngIf="isActive"
  type="file"
  #file
  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  style="display: none"
  (change)="onFilesAdded()"
/>
