<div [ngClass]="{
  'uni-table-progress__container': true,
  'uni-table-progress__container--disabled': isDisabled
  }">
  <div
    class="uni-table-progress__bar"
    [style.width.%]="percentage"
  ></div>
</div>
<div>
  {{ !isDisabled ? (percentage / 100 | percent: '1.0-2') :  '-' }}
</div>
