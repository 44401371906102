import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-modal-footer',
  templateUrl: './uni-modal-footer.component.html',
  styleUrls: ['./uni-modal-footer.component.scss'],
})
export class UniModalFooterComponent {
  @HostBinding('class.is-margin') @Input() isMargin = false;
  @HostBinding('class.is-aligned') @Input() isAligned = false;
  @HostBinding('class.is-centered') @Input() isCentered = false;
}
