import { filter, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UniTitleModule } from '../uni-title.module';
import { getLastActivatedRoute, getTitleKey, isPrimary } from './uni-title.factory';

@Injectable({ providedIn: UniTitleModule })
export class UniTitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) { }

  initTitle(suffix: string) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => getLastActivatedRoute(this.activatedRoute)),
        filter(isPrimary),
        mergeMap(({ data }) => data))
      .subscribe(data => this.setTitle(data, suffix));
  }

  setTitle(data: Data, suffix: string) {
    const titleKey = getTitleKey(data);
    this.translate
      .get(titleKey)
      .subscribe(title => this.titleService.setTitle(`${title} | ${suffix}`));
  }
}
