export enum CampaignMessagesTableColumns {
  messageID = 'messageID',
  messageBody = 'messageBody',
  messageUnits = 'messageUnits',
  accountName = 'accountName',
  recipientsNumber = 'recipientsNumber',
  operator = 'operator',
  senderName = 'senderName',
  status = 'status',
  country = 'country',
  mnpUsed = 'mnpUsed',
  sentByClientAt = 'sentByClientAt',
  sentToOperatorStatus = 'sentToOperatorStatus',
  sentToOperatorAt = 'sentToOperatorAt',
  operatorReportReceivedAt = 'operatorReportReceivedAt',
  deliveredToCustomerStatus = 'deliveredToCustomerStatus',
}
