import { Component, HostBinding, Input } from '@angular/core';
import { LogsActionTypes } from '../../shared/uni-logs.model';
import { values } from 'lodash';

@Component({
  selector: 'uni-logs-action',
  templateUrl: './uni-logs-action.component.html',
  styleUrls: ['./uni-logs-action.component.scss'],
})
export class UniLogsActionComponent {
  @Input() type = '';
  types = values(LogsActionTypes);

  @HostBinding('class') get getClasses(): string {
    return `uni-logs-action--${this.checkType(this.type)}`;
  }

  checkType(action: string): string {
    for (const type of this.types) {
      if (action.includes(type)) {
        return type;
      }
    }

    return 'default';
  }
}
