<uni-info type="hint" class="campaign-custom-form__sid-trial">
  <uni-label>{{ 'campaign.trialInfo.title' | translate }}</uni-label>
  <uni-text-body [isUppercase]="false" weight="regular">
    {{ 'campaign.trialInfo.header' | translate }}

    <ul class="campaign-trial-info__list">
      <li [innerHTML]="'campaign.trialInfo.description1' | translate:i18nParams"></li>
      <li [innerHTML]="'campaign.trialInfo.description2' | translate"></li>
      <li [innerHTML]="'campaign.trialInfo.description3' | translate"></li>
    </ul>
  </uni-text-body>
</uni-info>
