import { Observable } from 'rxjs';
import { get } from 'lodash';
import { Injectable, Optional, Inject } from '@angular/core';

import { Provider, Providers } from '../model/provider.model';
import { SettingsService } from '../../settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { PROVIDERS_KEY } from '../store/uni-data.state';

@Injectable({ providedIn: 'root' })
export class ProvidersRepository {
  apiUrl = this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getProviders(): Observable<Provider[]> {
    return this.http.get<Provider[]>(`${ this.apiUrl }/providers`, {});
  }

  getProvidersChoice(): Observable<Providers> {
    return this.http.get<Providers>(`${ this.apiUrl }/providers/list/choice`, {
      headers: {
        queryName: 'getProvidersChoice',
        queryGroups: [PROVIDERS_KEY]
      }
    });
  }
}
