import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'uni-nav-new-item-icon',
  templateUrl: './uni-nav-item-icon.component.html',
  styleUrls: ['./uni-nav-item-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavItemIconComponent {
  @Input() iconName: string;
  @Input() labelKey: string;
  @Input() isNavCollapsed = false;
  @Input() isRouteActive = false;

  getStyle(iconName: string): string {
    return iconName.includes('uni-')
      ? 'fak'
      : 'far';
  }
}
