import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniCommonModule } from 'unifonic-spa-common/src';
import { TranslateModule } from '@ngx-translate/core';
import { SecurityUnishieldRoutingModule } from './security-unishield-routing.module';

import { SharedModule } from '../shared/shared.module';

import { PageIndexComponent } from './page-index/page-index.component';
import { BlockedWordsComponent } from './containers/blocked-words/blocked-words.component';
import { OverviewComponent } from './containers/overview/overview.component';
import { GeopermissionsComponent } from './containers/geopermissions/geopermissions.component';
import { BlockedBySenderComponent } from './containers/blocked-by-sender/blocked-by-sender.component';
import { BlockedCardsComponent } from './containers/blocked-cards/blocked-cards.component';
import { CountriesTableComponent } from './containers/countries-table/countries-table.component';
import { FilterPanelDateComponent } from './containers/filter-panel-date/filter-panel-date.component';
import { FilterPanelAccountIdComponent } from './containers/filter-panel-account-id/filter-panel-account-id.component';

@NgModule({
  declarations: [
    PageIndexComponent,
    BlockedWordsComponent,
    OverviewComponent,
    GeopermissionsComponent,
    BlockedBySenderComponent,
    BlockedCardsComponent,
    CountriesTableComponent,
    FilterPanelDateComponent,
    FilterPanelAccountIdComponent,
  ],
  imports: [
    CommonModule,
    UniCommonModule,
    SharedModule,
    TranslateModule,
    SecurityUnishieldRoutingModule,
  ],
  providers: [
  ]
})
export class SecurityUnishieldModule { }
