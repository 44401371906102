import { QueryActions, queryReducer } from '../../../../store';
import * as IndustryActions from './uni-industry.actions';
import { GET_INDUSTRIES_QUERY, IndustryState, initialState } from './uni-industry.state';

const queryState = (state: IndustryState, action: IndustryActions.All | QueryActions.All): IndustryState => ({
  ...state,
  ...queryReducer<IndustryState>([GET_INDUSTRIES_QUERY], action as QueryActions.All),
});

export function industryReducer(
  state = initialState,
  action: IndustryActions.All,
): IndustryState {
  switch (action.type) {
    case IndustryActions.SET_INDUSTRIES_SUCCESS: {
      return {
        ...state,
        industries: action.response,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
