<ng-container>
  <div
    class="uni-tree-checkbox"
    [ngClass]="{
      'uni-tree-checkbox--selected': node.isSelected || node.data?.isSelected,
      'uni-tree-checkbox--indeterminate': node.isPartiallySelected
    }"
    (click)="node.mouseAction('checkboxClick', $event)"
  >
  </div>
</ng-container>
