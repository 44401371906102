import { Component, Input } from '@angular/core';
import { Campaign, CampaignStatus } from '../../shared/uni-campaign.model';
import { DateUtils } from '../../../../utils/date.utils';

@Component({
  selector: 'cc-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss']
})
export class CampaignDetailsComponent {
  @Input() campaign: Campaign;
  campaignStatus = CampaignStatus;
  dateUtils = DateUtils;
}
