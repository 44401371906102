<ng-container *ngIf="{ isRouteActive: isRouteActive$ | async } as data">
  <uni-nav-new-item-content
    class="uni-nav-item-container"
    [ngClass]="{ 'is-route-active': data.isRouteActive, 'is-subitem': isSubitem }"
    [iconName]="iconName"
    [labelKey]="labelKey"
    [isNavCollapsed]="isNavCollapsed$ | async"
    [isRouteActive]="data.isRouteActive"
    [toggleType]="toggleType"
    [isSubitem]="isSubitem"
    [isExpanded]="isExpanded"
    [isExternalLink]="!!link?.externalUrl"
    (click)="onClick()"
  >
  </uni-nav-new-item-content>
</ng-container>
