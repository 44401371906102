import { Action } from '@ngrx/store';

import { GlobalSettings, UserMe, User } from '../shared/uni-auth.model';
import { FeatureFlags } from '../shared/uni-feature-flags.model';

export const SET_USER_ME = '[Auth] SET_USER_ME';
export const SET_USER_ME_SUCCESS = '[Auth] SET_USER_ME_SUCCESS';

export const PATCH_USER_SUCCESS = '[Auth] PATCH_USER_SUCCESS';

export const SET_GLOBAL_SETTINGS = '[Auth] SET_GLOBAL_SETTINGS';
export const SET_GLOBAL_SETTINGS_SUCCESS = '[Auth] SET_GLOBAL_SETTINGS_SUCCESS';

export const SET_FEATURE_FLAGS = '[Auth] SET_FEATURE_FLAGS';
export const SET_FEATURE_FLAGS_SUCCESS = '[Auth] SET_FEATURE_FLAGS_SUCCESS';

export class SetUserMe implements Action {
  readonly type = SET_USER_ME;

  constructor(public isLoader: boolean) { }
}

export class SetUserMeSuccess implements Action {
  readonly type = SET_USER_ME_SUCCESS;

  constructor(public response: UserMe) { }
}

export class PatchUserSuccess implements Action {
  readonly type = PATCH_USER_SUCCESS;

  constructor(public user: Partial<User>) { }
}

export class SetGlobalSettings implements Action {
  readonly type = SET_GLOBAL_SETTINGS;

  constructor() { }
}

export class SetGlobalSettingsSuccess implements Action {
  readonly type = SET_GLOBAL_SETTINGS_SUCCESS;

  constructor(public response: GlobalSettings) { }
}

export class SetFeatureFlags implements Action {
  readonly type = SET_FEATURE_FLAGS;

  constructor(public data?: UserMe) { }
}

export class SetFeatureFlagsSuccess implements Action {
  readonly type = SET_FEATURE_FLAGS_SUCCESS;

  constructor(public data: FeatureFlags) { }
}

export type All =
  | SetUserMe
  | SetUserMeSuccess
  | PatchUserSuccess
  | SetGlobalSettings
  | SetGlobalSettingsSuccess
  | SetFeatureFlags
  | SetFeatureFlagsSuccess;
