import { concat, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CallerIdFacade } from './caller-id.facade';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { QueryActions } from '../query';
import { uniSnackbarActions } from '../../modules/uni-snackbar/store';

import { CallerIdRepository } from './caller-id.repository';
import * as callerIdActions from './caller-id.actions';
import {
  GET_CALLER_IDS_QUERY,
  GET_CALLER_IDS_SUBSCRIPTIONS_QUERY,
  GET_SECONDARY_CALLER_IDS_QUERY,
  GET_CALLER_ID_CHANNELS_QUERY,
  POST_CALLER_ID_QUERY,
  GET_CALLER_ID_CHANNEL_CHARGES_QUERY,
  GET_CALLER_ID_CHANNEL_CONFIG_QUERY,
  POST_CALLER_ID_CHANNEL_QUERY,
} from './caller-id.state';
import { ValidationUtils } from '../../utils';
import { CallerIdSubscriptionRepository } from './caller-id-subscription.repository';
import { CallerIdChannelRepository } from './caller-id-channel.repository';

@Injectable()
export class CallerIdEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private callerIdRepository: CallerIdRepository,
    private callerIdChannelRepository: CallerIdChannelRepository,
    private callerIdSubscriptionRepository: CallerIdSubscriptionRepository,
    private callerIdFacade: CallerIdFacade,
  ) { }

  @Effect() setCallerIds$ = this.actions$
    .pipe(
      ofType<callerIdActions.SetCallerIds>(callerIdActions.SET_CALLER_IDS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_CALLER_IDS_QUERY)),
        this.callerIdRepository
          .getCallerIds(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_CALLER_IDS_QUERY, res?.body)),
              of(new callerIdActions.SetCallerIdsSuccess(res)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_CALLER_IDS_QUERY, error)),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
      ),
      ),
    );

  @Effect() setSecondaryCallerIds$ = this.actions$
    .pipe(
      ofType<callerIdActions.SetSecondaryCallerIds>(callerIdActions.SET_SECONDARY_CALLER_IDS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_SECONDARY_CALLER_IDS_QUERY)),
        this.callerIdRepository
          .getCallerIds(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_SECONDARY_CALLER_IDS_QUERY, res?.body)),
              of(new callerIdActions.SetSecondaryCallerIdsSuccess(res)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_SECONDARY_CALLER_IDS_QUERY, error)),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
      ),
      ),
    );

  @Effect() postCallerId$ = this.actions$
    .pipe(
      ofType<callerIdActions.PostCallerId>(callerIdActions.POST_CALLER_ID),
      mergeMap(({ payload }) => concat(
        of(new QueryActions.QueryInProgress(POST_CALLER_ID_QUERY)),
        this.callerIdRepository
          .postCallerId(payload)
          .pipe(
            mergeMap(response => concat(
              of(new QueryActions.QuerySuccess(POST_CALLER_ID_QUERY, response)),
              of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successAdd')),
              of(new callerIdActions.PostCallerIdSuccess()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_CALLER_ID_QUERY, error)),
              of(new callerIdActions.PostCallerIdError(error?.error?.violations || [])),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
      ),
      ),
    );

  @Effect() setCallerIdsSubscriptions$ = this.actions$
    .pipe(
      ofType<callerIdActions.SetCallerIdsSubscriptions>(callerIdActions.SET_CALLER_IDS_SUBSCRIPTIONS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_CALLER_IDS_SUBSCRIPTIONS_QUERY)),
        this.callerIdSubscriptionRepository
          .getCallerIdSubscriptions(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_CALLER_IDS_SUBSCRIPTIONS_QUERY, res?.body)),
              of(new callerIdActions.SetCallerIdsSubscriptionsSuccess(res)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_CALLER_IDS_SUBSCRIPTIONS_QUERY, error)),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
      ),
      ),
    );

  @Effect() deleteCallerIds$ = this.actions$
    .pipe(
      ofType<callerIdActions.DeleteCallerIds>(callerIdActions.DELETE_CALLER_IDS),
      mergeMap(({ ids }) => this.callerIdRepository
        .deleteCallerIds(ids)
        .pipe(
          mergeMap(() => concat(
            of(new uniSnackbarActions.NewSnackbar(
              'success',
              'snackbar.' + (ids.length === 1 ? 'successDelete' : 'successDeleteMultiple')
            )),
            of(new callerIdActions.DeleteCallerIdsSuccess()),
            of(new callerIdActions.SetCallerIdsSubscriptions({})),
          )),
          catchError(error => error?.error?.violations
            ? of(new callerIdActions.DeleteCallerIdsError(error?.error?.violations))
            : of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
          ),
        ),
      ),
    );

  @Effect({ dispatch: false }) postCallerIdSuccess$ = this.actions$
    .pipe(
      ofType<callerIdActions.PostCallerIdSuccess>(callerIdActions.POST_CALLER_ID_SUCCESS),
      tap(() => this.callerIdFacade.loader$.next(of(true))),
      tap(() => this.router.navigate(['/callers']))
    );

  @Effect() accountHasActiveCallerId$ = this.actions$
    .pipe(
      ofType<callerIdActions.AccountHasActiveCallerId>(callerIdActions.ACCOUNT_HAS_ACTIVE_CALLER_ID),
      mergeMap(() =>
        this.callerIdSubscriptionRepository.accountHasActiveCallerId().pipe(
          map(
            (res) =>
              new callerIdActions.AccountHasActiveCallerIdSuccess(
                !res?.firstCaller
              )
          ),
          catchError((error) =>
            of(
              new uniSnackbarActions.NewSnackbar(
                'error',
                ValidationUtils.getViolationError(error)
              )
            )
          )
        )
      ),
    );

  @Effect() setCallerIdChannels$ = this.actions$
    .pipe(
      ofType<callerIdActions.SetCallerIdChannels>(callerIdActions.SET_CALLER_ID_CHANNELS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_CALLER_ID_CHANNELS_QUERY)),
        this.callerIdChannelRepository
          .getCallerIdChannels(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_CALLER_ID_CHANNELS_QUERY, res?.body)),
              of(new callerIdActions.SetCallerIdChannelsSuccess(res)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_CALLER_ID_CHANNELS_QUERY, error)),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
        ),
      ),
    );

  @Effect() setCallerIdChannelConfig$ = this.actions$
    .pipe(
      ofType<callerIdActions.SetCallerIdChannelConfig>(callerIdActions.SET_CALLER_ID_CHANNEL_CONFIG),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_CALLER_ID_CHANNEL_CONFIG_QUERY)),
        this.callerIdChannelRepository
          .getCallerIdChannelConfig(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_CALLER_ID_CHANNEL_CONFIG_QUERY, res.body)),
              of(new callerIdActions.SetCallerIdChannelConfigSuccess(res)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_CALLER_ID_CHANNEL_CONFIG_QUERY, error)),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
        ),
      ),
    );

  @Effect() setCallerIdChannelCharges$ = this.actions$
    .pipe(
      ofType<callerIdActions.SetCallerIdChannelCharges>(callerIdActions.SET_CALLER_ID_CHANNEL_CHARGES),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_CALLER_ID_CHANNEL_CHARGES_QUERY)),
        this.callerIdChannelRepository
          .getCallerIdChannelCharges(params)
          .pipe(
            mergeMap(res => concat(
              of(new QueryActions.QuerySuccess(GET_CALLER_ID_CHANNEL_CHARGES_QUERY, res.body)),
              of(new callerIdActions.SetCallerIdChannelChargesSuccess(res)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_CALLER_ID_CHANNEL_CHARGES_QUERY, error)),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
        ),
      ),
    );

  @Effect() postCallerIdChannel$ = this.actions$
    .pipe(
      ofType<callerIdActions.PostCallerIdChannel>(callerIdActions.POST_CALLER_ID_CHANNEL),
      mergeMap(({ payload }) => concat(
        of(new QueryActions.QueryInProgress(POST_CALLER_ID_CHANNEL_QUERY)),
        this.callerIdChannelRepository
          .postCallerIdChannel(payload)
          .pipe(
            mergeMap(response => concat(
              of(new QueryActions.QuerySuccess(POST_CALLER_ID_CHANNEL_QUERY, response)),
              of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successChanges')),
              of(new callerIdActions.PostCallerIdChannelSuccess()),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(POST_CALLER_ID_CHANNEL_QUERY, error)),
              of(new callerIdActions.PostCallerIdChannelError(error?.error?.violations || [])),
              of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))
            )),
          ),
      ),
      ),
    );

  @Effect({ dispatch: false }) postCallerIdChannelSuccess$ = this.actions$
    .pipe(
      ofType<callerIdActions.PostCallerIdChannelSuccess>(callerIdActions.POST_CALLER_ID_CHANNEL_SUCCESS),
      tap(() => this.callerIdFacade.loader$.next(of(true))),
      tap(() => this.callerIdFacade.getCallerIdChannels()),
    );
}
