<div class="uni-form-search" [ngClass]="{ 'uni-form-search--space': margin }" [formGroup]="form">
  <uni-form-field [margin]="margin">
    <div uniPrefix>
      <uni-icon *ngIf="!isLoading" name="search" [style]="'far'"></uni-icon>
      <uni-icon *ngIf="isLoading" name="spinner fa-spin" [disabled]="true"></uni-icon>
    </div>
    <input
      [formControlName]="controlName"
      type="text"
      [placeholder]="(isLoading ? 'label.loading' : label) | translate"
      [readonly]="isLoading"
      uniInput
      #inputRef
    >
    <div uniSuffix *ngIf="isClear">
      <uni-icon
        [ngClass]="{'uni-form-search__clear-icon--hidden': isLoading || !searchControl.value?.length}"
        name="times"
        (click)="clearSearchInput()">
      </uni-icon>
    </div>
  </uni-form-field>
</div>
