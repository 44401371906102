import * as _moment from 'moment';
import * as _momentTimezone from 'moment-timezone';

import { HttpParameterCodec } from '@angular/common/http';
import { RelativeTimeSpecVal } from 'moment/moment';

export const moment = _moment;
export const momentTimezone = _momentTimezone;

customizeMoment();

export namespace DateUtils {
  export enum Format {
    default = 'DD.MM.YYYY',
    defaultDay = 'DD.MM',
    defaultDateTime = 'DD.MM.YYYY, HH:mm',
    meridiemDateTime = 'DD.MM.YYYY, h:mm A',
    apiDefault = 'YYYY-MM-DD',
    apiMeridiemDateTime = 'YYYY-MM-DDh:mma',
    exact = 'DD.MM.YYYY HH:mm',
    hours = 'hh:mm',
    hoursDefault = 'H:mm',
    apiMonth = 'YYYY-MM',
    defaultMonth = 'MM.YYYY',
    apiWeek = 'YYYY-W',
    defaultWeek = '(W)YYYY',
    fullMonth = 'MMMM YYYY',
    withSeconds = 'DD-MM-YYYY H:mm:ss',
    withMilliSeconds = 'DD-MM-YYYY H:mm:ss.SSS',
    isoStandardFull = 'YYYY-MM-DD H:mm:ss',
    isoStandardFullZero = 'YYYY-MM-DD HH:mm:ss',
    dateWithMonthName = 'DD MMM, YYYY',
    longest = 'dddd, MMMM D, YYYY',
    long = 'dddd, MMM D, YYYY',
    medium = 'MMM D, YYYY',
    short = 'MMM D',
    numerical = 'DD/MM/YYYY',
    dateTime = 'MMM D, YYYY - HH:mm',
    dateTimeShort = 'ddd, HH:mm',
    time = 'HH:mm',
  }

  export enum FormatAr {
    longest = 'dddd، D MMMM YYYY',
    long = 'dddd، D MMMM YYYY',
    medium = 'D MMMM YYYY',
    short = 'D MMMM',
    numerical = 'DD/MM/YYYY',
    dateTime = 'D MMMM YYYY - HH:mm',
    dateTimeShort = 'dddd، HH:mm',
    time = 'HH:mm',
  }

  export enum FormatStandard {
    longest = 'longest',
    long = 'long',
    medium = 'medium',
    short = 'short',
    numerical = 'numerical',
    dateTime = 'dateTime',
    dateTimeShort = 'dateTimeShort',
    time = 'time',
  }

  export const datepickerFormat = {
    parse: {
      dateInput: Format.default,
    },
    display: {
      dateInput: Format.default,
      monthYearLabel: 'MMM.YYYY',
      dateA11yLabel: Format.default,
      monthYearA11yLabel: 'MMMM.YYYY',
    },
  };

  export const datepickerMonthFormat = {
    parse: {
      dateInput: Format.apiMonth,
    },
    display: {
      dateInput: Format.fullMonth,
      monthYearLabel: 'MMM.YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  export const getDate = (date: moment.MomentInput, format: string = Format.apiDefault) => moment(date).format(format);

  export const compareDates = (a, b): boolean => {
    return a.unix() > b.unix();
  };

  export const endTimeIsBeforeStartTime = (start, end): boolean => {
    const startTime = moment(start, 'h:mma');
    const endTime = moment(end, 'h:mma');

    return endTime.isBefore(startTime);
  };

  export interface TimeFormatsAttributes {
    inputFormat?: string;
    outputFormat?: string;
  }
  export const convert12HoursTo24Hours = (time: string, format?: TimeFormatsAttributes): string => {
    const {inputFormat = 'h:mm A', outputFormat = 'HH:mm'} = format;
    return moment(time, [inputFormat]).format(outputFormat);
  };

  export const convert24HoursTo12Hours = (time: string, format?: TimeFormatsAttributes): string => {
    const {inputFormat = 'HH:mm', outputFormat = 'h:mm A'} = format;
    return moment(time, [inputFormat]).format(outputFormat);
  };

  export const formatDate = (
    date: string,
    timezone: string,
    format: FormatStandard = FormatStandard.medium,
    locale: 'en' | 'ar' | string = 'en'): string => {
    if (locale === 'ar') {
      return momentTimezone(date)
        .tz(timezone)
        .locale('ar-tn') // Saudi locale contains "indian numbers"
        .format(FormatAr[format]);
    }

    return momentTimezone(date)
      .tz(timezone)
      .format(Format[format]);
  };

  export const formatDateFromNow = (
    date: string,
    timezone: string,
    locale: 'en' | 'ar' = 'en'): string => {
    const mDate = moment(date);
    const daysFromNow = Math.abs(mDate.diff(moment.now(), 'd'));

    if (daysFromNow > 7) {
      return formatDate(date, timezone, FormatStandard.medium, locale);
    }

    if (locale === 'ar') {
      return mDate.locale('ar').fromNow();
    }

    return mDate.fromNow();
  };

  export const formatDatesFromTo = (
    dateFrom: string,
    dateTo: string,
    timezone: string,
    yearsOnly = false,
    locale: 'en' | 'ar' = 'en'): string => {
    const fromYear = momentTimezone(dateFrom)
      .tz(timezone).year();
    const toYear = momentTimezone(dateTo)
      .tz(timezone).year();
    const nowYear = momentTimezone(momentTimezone.now()).tz(timezone).year();
    let from: string;
    let to: string;

    if (yearsOnly) {
      from = fromYear.toString();
      to = toYear.toString();
    } else if (nowYear === fromYear && fromYear === toYear) {
      from = formatDate(dateFrom, timezone, FormatStandard.short, locale);
      to = formatDate(dateTo, timezone, FormatStandard.short, locale);
    } else {
      from = formatDate(dateFrom, timezone, FormatStandard.medium, locale);
      to = formatDate(dateTo, timezone, FormatStandard.medium, locale);
    }

    return locale === 'en'
      ? `From ${from} to ${to}`
      : `من ${from} إلى ${to}`;
  };

  export const get = (date, format = Format.default): string => {
    return moment(date, Format.default).format(format);
  };

  export const getCurrentDate = (timezone): _momentTimezone.Moment => {
    return momentTimezone.tz(moment(), timezone);
  };

  export const getTimeZoneObject = (...values): _momentTimezone.Moment => {
    return momentTimezone.tz.apply(null, values);
  };

  export const getFormattedDate = (date, timezone, format = DateUtils.Format.apiMeridiemDateTime): string => {
    return momentTimezone
      .tz(date, format, timezone)
      .format();
  };

  export const getTimezoneDate = (date, timezone, format = Format.default): string => {
    return momentTimezone(date)
      .tz(timezone)
      .format(format);
  };

  export const display = (date: string, format = Format.default, timezone?: string): string => {
    return timezone ? getTimezoneDate(date, timezone, format) : moment(date).format(format);
  };

  export const getStartOfDay = (date): string => {
    return moment(date, Format.defaultDateTime).startOf('day').format();
  };

  export const getTimezoneStartOfDay = (date, timezone, format = null): string => {
    return momentTimezone(date, Format.defaultDateTime)
      .startOf('day')
      .tz(timezone)
      .format(format);
  };

  export const getTimezoneStartOfMonth = (date, timezone): string => {
    return momentTimezone(date, Format.defaultDateTime)
      .startOf('month')
      .tz(timezone)
      .format();
  };

  export const getEndOfDay = (date): string => {
    return moment(date, Format.defaultDateTime).endOf('day').format();
  };

  export const subtractWeeksFromToday = (timezone, weeksToSubtract): _momentTimezone.Moment => {
    return momentTimezone.tz(moment().subtract(weeksToSubtract, 'weeks').endOf('week'), timezone);
  };

  export const getTimezoneEndOfDay = (date, timezone, format = null): string => {
    return momentTimezone(date, Format.defaultDateTime)
      .endOf('day')
      .tz(timezone)
      .format(format);
  };

  export class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
      return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
      return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
      return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
      return decodeURIComponent(value);
    }
  }
}

function customizeMoment() {
  moment.relativeTimeRounding(Math.floor);
  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 24);
  moment.relativeTimeThreshold('d', 7);
  moment.relativeTimeThreshold('w', 4);
  moment.relativeTimeThreshold('M', 12);

  const nonParsableRelative = [
    'just now', 'yesterday', 'shortly', 'tomorrow',
    'الآن', 'أمس', 'قريبا', 'غداََ',
  ];

  const relativeValueFn = (future: string, past: string): RelativeTimeSpecVal =>
    (_n, _s, _k, isFuture) => isFuture ? future : past;

  moment.updateLocale('ar', {
    preparse: s => s,
    postformat: s => s,
    relativeTime: {
      past: relTime => nonParsableRelative.includes(relTime) ? relTime : `منذ ${relTime}`,
      future: relTime => nonParsableRelative.includes(relTime) ? relTime : `خلال ${relTime}`,
      s: relativeValueFn('قريبا', 'الآن'),
      m: relativeValueFn('١ دقيقة', 'دقيقة'),
      h: relativeValueFn('١ ساعة', 'ساعة'),
      d: relativeValueFn('غداََ', 'أمس'),
      w: '1 أسبوع',
    }
  });
  moment.updateLocale('en', {
    relativeTime: {
      past: relTime => nonParsableRelative.includes(relTime) ? relTime : `${relTime} ago`,
      future: relTime => nonParsableRelative.includes(relTime) ? relTime : `in ${relTime}`,
      s: relativeValueFn('shortly', 'just now'),
      m: relativeValueFn('1 minute', 'a minute'),
      h: '1 hour',
      d: relativeValueFn('tomorrow', 'yesterday'),
      w: '1 week',
    }
  });
}
