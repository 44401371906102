<ng-container *ngIf="isEditable || isActive">
  <uni-label>
    {{ 'label.countryCodesAutocompletion' | translate }}
    <uni-icon
      class="campaign-country-autocomplete__icon"
      name="info-circle"
      (click)="toggleAutocompleteModal()"
    ></uni-icon>
  </uni-label>

  <uni-form-container [formGroup]="contentForm">
    <uni-form-field
      [isRow]="true"
      [margin]="isAutocompleteActiveControl.value"
    >
      <div>
        <uni-toggle
          [formControl]="isAutocompleteActiveControl"
          [activeLabel]="'label.autocompleteOn' | translate"
          [inactiveLabel]="'label.autocompleteOff' | translate"
          [isDisabled]="contentForm.disabled || isDisabled"
        ></uni-toggle>
        <uni-text-info>
          {{ 'tip.autocompleteTip' | translate }}
        </uni-text-info>
      </div>
    </uni-form-field>

    <ng-container *ngIf="isAutocompleteActiveControl.value">
      <uni-label>
        {{ 'label.chooseCountry' | translate }}
      </uni-label>
      <uni-form-field [margin]="false">
        <mat-form-field>
          <mat-select
            [formControl]="autocompleteControl"
            [disableOptionCentering]="true"
          >
            <mat-option
              *ngFor="let country of countries"
              [value]="country"
            >
              {{ 'country.' + country.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </uni-form-field>
      <uni-text-info>
        {{ 'tip.chooseCountryTip' | translate }}
      </uni-text-info>
    </ng-container>
  </uni-form-container>
</ng-container>

<cc-campaign-recipients-autocomplete-modal
  [isActive]="isAutocompleteModalActive"
  (isActiveChange)="toggleAutocompleteModal()"
></cc-campaign-recipients-autocomplete-modal>
