import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cc-campaign-recipients-autocomplete-modal',
  templateUrl: './campaign-recipients-autocomplete-modal.component.html',
  styleUrls: ['./campaign-recipients-autocomplete-modal.component.scss']
})
export class CampaignRecipientsAutocompleteModalComponent {
  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter();

  hideModal(): void {
    this.isActiveChange.emit(false);
  }
}
