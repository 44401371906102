<ng-container [formGroup]="nameForm">
  <uni-section>
    <uni-box>
      <uni-box-content>
        <uni-section-header size="medium">
          <uni-text-caption>
            {{ 'campaign.name' | translate }}
          </uni-text-caption>
        </uni-section-header>
        <uni-form-container>
          <cc-campaign-name
            [form]="nameForm"
            [isRequired]="isRequired"
            [isActive]="isActive"
          ></cc-campaign-name>
        </uni-form-container>
      </uni-box-content>
      <uni-box-footer>
        <uni-buttons>
          <uni-button
            *ngIf="!isNextStep"
            [isMargin]="true"
            [style]="'secondary'"
            routerLink="/campaigns/sms"
          >
            {{ 'action.cancel' | translate }}
          </uni-button>
          <uni-button
            *ngIf="nameForm.enabled"
            type="submit"
            (click)="onConfirmStep()"
            [disabled]="!nameControl.valid"
            [isMargin]="true"
          >
            {{ 'label.confirmStep' | translate }}
          </uni-button>
          <uni-button
            *ngIf="nameForm.disabled"
            [isMargin]="true"
            [style]="'secondary'"
            (click)="onEditStep()"
          >
            {{ 'label.editStep' | translate }}
          </uni-button>
        </uni-buttons>
      </uni-box-footer>
    </uni-box>
  </uni-section>
</ng-container>
