import { values } from 'lodash';

import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { UniLanguage, UniRtlLanguage } from '../models/uni-language.model';
import { UniUserpilotService } from '../../../shared/uni-userpilot.service';

@Injectable({ providedIn: 'root' })
export class UniLocalizationService {
  readonly defaultLanguage = UniLanguage.en;
  readonly languages = values(UniLanguage);
  readonly rtlLanguages = values(UniRtlLanguage);

  constructor(
    private translateService: TranslateService,
    private uniUserpilotService: UniUserpilotService
  ) {}

  detectLocalization(): void {
    this.setLanguage();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) =>
      this.setDOMLanguage(event.lang),
    );
  }

  setLanguage(): void {
    const browserLangage = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang(this.defaultLanguage);
    this.translateService.use(
      this.isSupportedLanguage(browserLangage)
        ? browserLangage
        : this.defaultLanguage,
    );
  }

  setDOMLanguage(lang: string): void {
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    document.body.classList.add(this.isRTL(lang) ? 'rtl' : 'ltr');
    document.body.classList.remove(this.isRTL(lang) ? 'ltr' : 'rtl');
    this.uniUserpilotService.changeTheme(this.isRTL(lang));
  }

  isSupportedLanguage(lang: string): boolean {
    return this.languages.includes(lang as UniLanguage);
  }

  isRTL(lang: string): boolean {
    return this.rtlLanguages.includes(lang as UniRtlLanguage);
  }
}
