import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectUniAccounts, selectUniAccountsQuery, UniAccountsState } from '../store';
import * as uniAccountsActions from '../store/uni-accounts.actions';

@Injectable({
  providedIn: 'root'
})
export class UniAccountsFacade {
  accounts$ = this.store.pipe(select(selectUniAccounts));
  accountsQuery$ = this.store.pipe(select(selectUniAccountsQuery));

  constructor(private store: Store<UniAccountsState>) {}

  setAccounts(params = {}): void {
    this.store.dispatch(new uniAccountsActions.SetUniAccounts(params));
  }
}
