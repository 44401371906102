import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { Country } from '../../../uni-countries/shared/uni-countries.model';

@Component({
  selector: 'cc-campaign-country-autocomplete',
  templateUrl: './campaign-country-autocomplete.component.html',
  styleUrls: ['./campaign-country-autocomplete.component.scss'],
})
export class CampaignCountryAutocompleteComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() isActive = true;
  @Input() isEditable = true;
  @Input() isDisabled = false;

  isAutocompleteModalActive = false;
  chosenCountry: Country;
  countries: Country[];

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get autocompleteControl(): FormControl {
    return this.contentForm.get('autocomplete') as FormControl;
  }

  get isAutocompleteActiveControl(): FormControl {
    return this.contentForm.get('isAutocompleteActive') as FormControl;
  }

  constructor(private campaignFacade: UniCampaignsFacade) {
    super();
  }

  ngOnInit() {
    this.campaignFacade.setAutocompleteCountries();
    this.subs.add(this.setCountries());
  }

  toggleAutocompleteModal(): void {
    this.isAutocompleteModalActive = !this.isAutocompleteModalActive;
  }

  setCountries(): Subscription {
    return this.campaignFacade.autocompleteCountries$.subscribe(countries => {
      if (!!countries) {
        const defaultCountry = countries.find(country => country.code === 'SA');
        this.autocompleteControl.setValue(defaultCountry);
      }

      this.countries = countries;
    });
  }
}
