<a
  *ngIf="globalSettings$ | async as globalSettings"
  routerLink="/"
  class="uni-page-logo__link"
>
  <img
    [src]="isNewDesign && !globalSettings.isWhitelabelActive ?
    '/assets/images/unifonic-new.png'
    : globalSettings.logo"
    class="uni-page-logo__img"
  />
</a>
