import { Injectable } from '@angular/core';
import { PackagesFacade } from '../../../shared/uni-data/facade/packages.facade';
import { UniAuthFacade } from '../../uni-auth/shared/uni-auth.facade';

@Injectable({ providedIn: 'root' })
export class UniTopBarBalanceService {
  balanceChecker: any;
  interval = 25000;

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniPackagesFacade: PackagesFacade,
  ) {}

  initInterval(canSeePackages: boolean) {
    this.balanceChecker = setInterval(() => {
      this.uniAuthFacade.setUserMe(false);
      if (canSeePackages) {
        this.uniPackagesFacade.setActivePackages();
      }
    }, this.interval);
  }

  killInterval() {
    clearInterval(this.balanceChecker);
  }
}
