export enum LogsActionTypes {
  login = 'login',
  impersonate = 'impersonate',
  add = 'add',
  deactivate = 'deactivate',
  activate = 'activate',
  create = 'create',
  edit = 'edit',
  delete = 'delete',
}
