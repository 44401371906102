import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Params } from '@angular/router';

import { Observable } from 'rxjs';
import { CallerIdSubscription, FirstCallerResponse, PageableResponse } from '../../models/caller-id.model';
import { DateUtils, httpEncoder } from '../../utils';
import { CALLER_ID_KEY } from './caller-id.state';

@Injectable()
export class CallerIdSubscriptionRepository {
  voiceApiUrl = this.environment.voiceJavaApiUrl;

  constructor(
    private http: HttpClient,
    @Optional() @Inject('environment') public environment: any,
  ) { }

  getCallerIdSubscriptions(
    params: Params = {}
  ): Observable<HttpResponse<PageableResponse<CallerIdSubscription>>> {
    return this.http.get<PageableResponse<CallerIdSubscription>>(
      `${this.voiceApiUrl}/v1/protected/caller-id-subscriptions`,
      {
        params: httpEncoder({
          ...params,
          size: params?.size ? params.size : 10,
          page: params?.page ? Number(params.page) - 1 : 0,
          ...(params?.activationDateGte ? { activationDateGte: DateUtils.getDate(params?.activationDateGte) } : {}),
          ...(params?.activationDateLte ? { activationDateLte: DateUtils.getDate(params?.activationDateLte) } : {}),
          ...(params?.nextChargingDateGte ? { nextChargingDateGte: DateUtils.getDate(params?.nextChargingDateGte) } : {}),
          ...(params?.nextChargingDateLte ? { nextChargingDateLte: DateUtils.getDate(params?.nextChargingDateLte) } : {}),
        }),
        observe: 'response',
        headers: {
          queryName: 'getCallerIdSubscriptions',
          queryGroups: [CALLER_ID_KEY],
        },
      }
    );
  }

  accountHasActiveCallerId(): Observable<FirstCallerResponse> {
    return this.http.get<FirstCallerResponse>(
      `${this.voiceApiUrl}/v1/protected/caller-id-subscriptions/is-first-caller`, {
      headers: {
        queryName: 'accountHasActiveCallerId',
        queryGroups: [CALLER_ID_KEY],
      },
    });
  }
}
