export enum ButtonStyle {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  group = 'group',
  link = 'link',
  white = 'white'
}

export enum ButtonSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extraSmall = 'extra-small'
}

export type ButtonType = 'submit' | 'button';
