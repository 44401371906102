import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-list-details',
  templateUrl: './uni-list-details.component.html',
  styleUrls: ['./uni-list-details.component.scss'],
})
export class UniListDetailsComponent {
  @HostBinding('class.is-open') @Input() isOpen = false;
}
