import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniListContentItemComponent } from './components/uni-list-content-item/uni-list-content-item.component';
import { UniListContentComponent } from './components/uni-list-content/uni-list-content.component';
import { UniListDetailsComponent } from './components/uni-list-details/uni-list-details.component';
import { UniListFooterComponent } from './components/uni-list-footer/uni-list-footer.component';
import { UniListHeaderComponent } from './components/uni-list-header/uni-list-header.component';
import { UniListItemComponent } from './components/uni-list-item/uni-list-item.component';
import { UniListRowComponent } from './components/uni-list-row/uni-list-row.component';
import { UniListSectionComponent } from './components/uni-list-section/uni-list-section.component';
import { UniListComponent } from './components/uni-list/uni-list.component';

const components = [
  UniListItemComponent,
  UniListRowComponent,
  UniListComponent,
  UniListDetailsComponent,
  UniListHeaderComponent,
  UniListSectionComponent,
  UniListContentComponent,
  UniListContentItemComponent,
  UniListFooterComponent,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class UniListModule { }
