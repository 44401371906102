import { Component, OnInit } from '@angular/core';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';

@Component({
  selector: 'uni-page-logo',
  templateUrl: './uni-page-logo.component.html',
  styleUrls: ['./uni-page-logo.component.scss'],
})
export class UniPageLogoComponent extends AutoUnsubscribe implements OnInit {
  globalSettings$ = this.uniAuthFacade.globalSettings$;
  isNewDesign = false;

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(this.checkFlags());
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(filter(featureFlags => !!featureFlags))
      .subscribe(() => {
        this.isNewDesign = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.new_design_2022_q_1);
      });
  }
}
