import { ValidationUtils } from '../../../utils';
import { FormError } from '../../../modules/uni-form/shared/uni-form.model';
import { ReducedCampaignStatus } from './uni-campaign.model';

export const converCampaignViolations = (item: FormError): FormError => {
  switch (item.propertyPath) {
    case 'senderName.id':
    case 'senderName': {
      return {
        ...item,
        propertyPath: 'customType.senderName'
      };
    }
    default: {
      return item;
    }
  }
};

export const getCampaignViolations = (errors: any) => ValidationUtils
  .getViolations(errors)
  .map(item => converCampaignViolations(item));

export const getCorrectNumberFormat = (num: string, callingCode?: string): string => {
    if (num.startsWith('00')) {
      num = num.slice(2);
    }

    if (num.startsWith('0')) {
      num = num.slice(1);
    }

    if (
      (
        !!callingCode
        && num.startsWith(callingCode)
      )
      || (
        !callingCode
        && !num.startsWith('+')
      )
    ) {
      num = '+' + num;
    }

    return num;
  };

export const reduceCampaignStatus = (status: string): ReducedCampaignStatus => {
  switch (status) {
    case 'processing':
    case 'sending':
      return ReducedCampaignStatus.processing;
    case 'completed':
      return ReducedCampaignStatus.completed;
    case 'failed':
      return ReducedCampaignStatus.failed;
    case 'scheduled':
      return ReducedCampaignStatus.scheduled;
    case 'rejected':
    case 'timeout':
      return ReducedCampaignStatus.rejected;
    default:
      return ReducedCampaignStatus.pending;
  }
};
