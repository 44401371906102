import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'uni-colorpicker',
  templateUrl: './uni-colorpicker.component.html',
  styleUrls: ['./uni-colorpicker.component.scss'],
})
export class UniColorpickerComponent {
  @Input() form: FormGroup;
  @Input() fieldName: string;
  @Input() label: string;
  @Input() tip: string;
  @Input() required = false;
  @Input() value = '#000000';

  @ViewChild('colorpicker', { static: false }) colorpicker: ElementRef;
  @ViewChild('valueholder', { static: false }) valueholder: ElementRef;

  public colorChanged() {
    const regex = new RegExp(/^#([A-Fa-f0-9]{6})$/);
    const val = this.valueholder.nativeElement.value;
    if(val && regex.test(val)) {
      this.form.controls[this.fieldName].patchValue(this.valueholder.nativeElement.value);
    }
  }
}
