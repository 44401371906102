import { Observable } from 'rxjs';
import { get } from 'lodash';

import { Injectable, Optional, Inject } from '@angular/core';
import { Params } from '@angular/router';
import { UniSenderNameResponse } from '../model/sender-name.model';
import { SettingsService } from '../../settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { Senders } from '../model/sender-name.model';
import { SENDERS_KEY } from '../store/uni-data.state';
import { httpEncoder } from '../../../utils/http.utils';

@Injectable({ providedIn: 'root' })
export class SenderNamesRepository {
  apiUrl = this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getSenderNames(params: Params): Observable<UniSenderNameResponse[]> {
    return this.http.get<UniSenderNameResponse[]>(`${ this.apiUrl }/sender-names`, { params: httpEncoder(params) });
  }

  getSendersChoice(accountID = null): Observable<Senders> {
    const sendersUrl = `${ this.apiUrl }/sender-names/list/choice`;
    const apiURL = accountID ? `${sendersUrl}?accountId=${accountID}` : sendersUrl;
    return this.http.get<Senders>(apiURL, {
      headers: {
        queryName: 'getSendersChoice',
        queryGroups: [SENDERS_KEY]
      }
    });
  }
}
