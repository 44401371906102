import { Component, HostBinding, Input } from '@angular/core';

import { BoxMargin } from './uni-box-content.model';

@Component({
  selector: 'uni-box-content',
  templateUrl: './uni-box-content.component.html',
  styleUrls: ['./uni-box-content.component.scss'],
})
export class UniBoxContentComponent {
  @Input() margin: BoxMargin = 'small';
  @Input() class = '';
  @Input() type = 'white';
  @Input() size = 'default';
  @Input() borderRadius = 'none';
  @Input() isActive = false;
  @Input() isHorizontalSpace = true;
  @Input() isVerticalSpace = true;
  @Input() isRowDirection = false;
  @Input() isBottomSpace = false;
  @Input() isRelative = false;
  @Input() isBorder = false;

  @HostBinding('class') get getClasses(): string {
    return `${this.class}
      uni-box--${this.margin}
      uni-box--${this.type}
      uni-box__border--${this.borderRadius}
      uni-box--size-${this.size}
      ${this.isBorder ? 'uni-box__border' : ''}`;
  }

  @HostBinding('class.uni-box--active') get getActiveClass() {
    return this.isActive;
  }

  @HostBinding('class.uni-box--no-horizontal-space') get isHorizontalSpaceClass() {
    return !this.isHorizontalSpace;
  }

  @HostBinding('class.uni-box--no-vertical-space') get isVerticalSpaceClass() {
    return !this.isVerticalSpace;
  }

  @HostBinding('class.uni-box--bottom-space') get isBottomSpaceClass() {
    return this.isBottomSpace;
  }

  @HostBinding('class.uni-box--row') get isRowDirectionClass() {
    return this.isRowDirection;
  }

  @HostBinding('class.uni-box--relative') get isRelativeClass() {
    return this.isRelative;
  }
}
