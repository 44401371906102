<uni-label>
  {{ 'campaign.scheduleCampaign' | translate }}
</uni-label>
<uni-form-container>
  <uni-form-field [margin]="false">
    <input
      readonly
      [value]="date || ('campaign.sendNow' | translate)"
      class="campaign-summary-date__input"
    >
  </uni-form-field>
</uni-form-container>
