import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { UniRouteService } from '../../../shared';
import { NavLink } from './uni-nav.model';

@Injectable({ providedIn: 'root' })
export class UniNavNavigationService {

    constructor(
        private readonly router: Router,
        private readonly uniRouteService: UniRouteService,
        private readonly location: Location,
    ) { }

    navigate(link: NavLink): void {
        if (!link) {
            return;
        }

        if(link.externalUrl){
            this.navigateToExternalPage(link.externalUrl);
            return;
        }

        const isProductLocation = this.isProductLocation(link.productUrl);
        if (isProductLocation) {
            this.navigateWithinProduct(link.route);
        } else {
            this.navigateToProduct(link.productUrl, link.route);
        }
    }

    private navigateToExternalPage(externalUrl: string): void {
        window.open(externalUrl, '_tab');
    }

    private navigateWithinProduct(url: string): void {
        this.router.navigate([url]);
    }

    private navigateToProduct(productUrl: string, route: string): void {
        const url = `${productUrl}${route}`;

        this.uniRouteService.navigate(url);
    }

    private isProductLocation(productUrl: string): boolean {
        const currentLocation = this.uniRouteService.getCurrentLocation();

        return currentLocation.includes(productUrl);
    }

    isRouteActive(link: NavLink): boolean {
      if (!link || link.externalUrl) {
          return false;
      }

      const isMainProductRoute = !link.route;
      if (isMainProductRoute) {
          const currentLocation = this.uniRouteService.getCurrentLocation();
          return this.location.normalize(link.productUrl) === this.location.normalize(currentLocation);
      }

      return link.route
        && this.router.isActive(link.route, false)
        && link.productUrl
        && this.isProductLocation(link.productUrl);
    }

    getNumberOfRouteParts(route: string): number {
        return route?.split('/').length || 0;
    }
}
