import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniChartService, UniChartTooltipService, UniChartAxesService, UniPieChartService, UniBarChartService } from './shared';
import { UniChartTooltipComponent } from './components/uni-chart-tooltip/uni-chart-tooltip.component';

const modules = [
  CommonModule,
];

const providers = [
  UniChartService,
  UniChartTooltipService,
  UniChartAxesService,
  UniPieChartService,
  UniBarChartService
];

const components = [
  UniChartTooltipComponent
];

@NgModule({
  imports: [...modules],
  declarations: [...components],
  exports: [...components],
  providers: [...providers],
})
export class UniChartModule { }
