import { Injectable } from '@angular/core';
import { hexToRgba, color  } from '../../../utils';

@Injectable()
export class UniChartAxesService {
  getAxesX() {
    return [{
      stacked: true,
      display: true,
      scaleLabel: { display: false, labelString: 'time' },
      gridLines: {
        drawBorder: false,
        display: false,
        color: hexToRgba(color.grey, .08),
      },
      ticks: {
        padding: 10,
        autoSkip: false,
        autoSkipPadding: 15,
        fontColor: hexToRgba(color.grey, .6),
      },
    }];
  }

  getBarChartAxesX() {
    return [{
      stacked: false,
      display: true,
      scaleLabel: { display: false, labelString: 'time' },
      gridLines: {
        drawBorder: false,
        display: false,
        color: hexToRgba(color.grey, .08),
      },
      ticks: {
        padding: 10,
        autoSkip: false,
        autoSkipPadding: 15,
        fontColor: hexToRgba(color.grey, .6),
      },
    }];
  }

  getAxesY(min: number) {
    return [{
      stacked: false,
      gridLines: {
        zeroLineColor: hexToRgba(color.grey, 0),
        drawBorder: false,
        color: hexToRgba(color.grey, .08),
      },
      ticks: {
        min,
        padding: 10,
        autoSkipPadding: 15,
        fontColor: hexToRgba(color.grey, .6),
        maxRotation: 0,
        userCallback(label: any): any {
          if (Math.floor(label) === label) {
            return label;
          }
        },
      }
    }];
  }
}
