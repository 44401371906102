import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatSlider } from '@angular/material/slider';

@Component({
  selector: 'uni-audio-volume',
  templateUrl: './uni-audio-volume.component.html',
  styleUrls: ['./uni-audio-volume.component.scss']
})
export class UniAudioVolumeComponent implements AfterViewInit {
  @ViewChild('volumeLevel', { static: false }) volumeLevel: MatSlider;
  @Input() audio: HTMLAudioElement;

  isMuted = false;
  previousVolume: number;

  ngAfterViewInit() {
    this.volumeLevel.valueChange.subscribe(value => {
      this.audio.volume = value;
      this.isMuted = value === 0;
    });
  }

  onMute(): void {
    if (this.isMuted) {
      this.audio.volume = this.previousVolume;
      this.volumeLevel.value = this.previousVolume;
    } else {
      this.previousVolume = this.audio.volume;
      this.audio.volume = 0;
      this.volumeLevel.value = 0;
    }

    this.isMuted = !this.isMuted;
  }
}
