import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoUnsubscribe } from '../../../../utils';
import { map, tap, filter, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { UniCampaignRepository, UniCampaignsPermissionsService } from '../../shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cc-campaign-name',
  templateUrl: './campaign-name.component.html'
})
export class CampaignNameComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() isRequired = true;
  @Input() isVoice = false;
  @Input() isActive = false;
  isCheckingCampaignName = false;

  get nameControl(): FormControl {
    return this.form.get('name') as FormControl;
  }

  constructor(
    private campaignsRepository: UniCampaignRepository,
    private uniCampaignPermissions: UniCampaignsPermissionsService
  ) {
    super();
  }

  ngOnInit() {
    this.uniCampaignPermissions.isVoice = this.isVoice;
    if (!this.isVoice) { // disable name validation for voice campaign form
      this.subs.add(this.validateName());
    }
  }

  validateName(): Subscription {
    const values = this.nameControl.valueChanges;

    return this.nameControl.valueChanges
      .pipe(
        map(value => value.trimStart()),
        tap(value => {
          this.isCheckingCampaignName = false;
          this.nameControl.setValue(value, { emitEvent: false });
        }),
        filter(value => {
          if (this.nameControl.getError('nameValidator') && !this.nameControl.getError('nameValidator').valid) {
            return false;
          } else if (!value) {
            this.nameControl.setErrors({ notBlank: true });
          } else {
            this.isCheckingCampaignName = true;
          }

          return !!value;
        }),
        debounceTime(500),
        switchMap(value => {
          return this.campaignsRepository.getCampaigns({ 'name[eq]': value.trim() })
            .pipe(
              takeUntil(values),
              tap(res => {
                if(!!res.body.length) {
                  this.isCheckingCampaignName = false;
                  this.nameControl.setErrors({ campaignNameTaken: true });
                } else {
                  this.nameControl.setErrors(null);
                  this.isCheckingCampaignName = false;
                }
              }),
            );
          }
        )
      ).subscribe();
  }
}
