<uni-text-caption
  class="uni-nav-category__name"
  [weight]="uniTextWeight.regular"
  [color]="uniTextColor.darkLight"
>
  {{ categoryName | translate }}
</uni-text-caption>
<div class="uni-nav-category__list">
  <ng-content></ng-content>
</div>
