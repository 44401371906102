<uni-modal
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-label>
      {{ 'label.warning' | translate }}
  </uni-label>
  <uni-modal-content>
    {{ 'campaign.new.recipients.modal.warning' | translate }}
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="hideModal()">
      {{ 'action.ok' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
