import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-tile-img',
  templateUrl: './uni-tile-img.component.html',
  styleUrls: ['./uni-tile-img.component.scss'],
})
export class UniTileImgComponent {
  @Input() src: string;

  @HostBinding('class.full-width') @Input() isFullWidth = false;

}
