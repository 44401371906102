import { get } from 'lodash';
import { Subject } from 'rxjs';
import { filter, map, skip, takeUntil } from 'rxjs/operators';

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import {
  Campaign,
  CampaignDataSource,
  CampaignFilters,
  CampaignMessageCounter,
  CampaignMessageStatus,
  CampaignStatus,
  File
} from '../../shared/uni-campaign.model';
import { AccountsFacade } from '../../../../shared/uni-data/facade/accounts.facade';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniTableSelection } from '../../../uni-table/shared/uni-table-selection.helper';
import { UniUserFacade } from '../../../uni-user/shared/uni-user.facade';

import { CampaignTableColumns } from './campaign-table.model';
import { UniTextWeight } from '../../../uni-text';
import {
  failedCounter,
  getPercentage,
  progressCounter,
  succeedCounter
} from '../campaign-progress/campaign-progress.helper';
import { FormGroup } from '@angular/forms';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../../../uni-auth';
import { reduceCampaignStatus } from '../../shared';

@Component({
  selector: 'cc-campaign-table',
  templateUrl: './campaign-table.component.html',
  styleUrls: ['./campaign-table.component.scss'],
})
export class CampaignTableComponent extends UniTableSelection<Campaign> implements OnInit, OnDestroy {
  @ViewChild('table', { static: false }) table: ElementRef;
  campaigns$ = this.campaignsFacade.campaigns$;
  campaignsTotalItems$ = this.campaignsFacade.campaignsTotalItems$;
  isLoaded = false;
  tableLeft: number;
  dataSource: MatTableDataSource<Campaign> = new MatTableDataSource<Campaign>([]);
  unsubscribe$ = new Subject();
  filters = CampaignFilters;
  columns = CampaignTableColumns;
  campaignDataSource = CampaignDataSource;
  campaignStatus = CampaignStatus;
  messageStatus = CampaignMessageStatus;
  timezone: string;
  accounts$ = this.accountsFacade.accounts$.pipe(filter(data => !!data && !!data.length));
  users$ = this.uniUserFacade.users$.pipe(filter(data => !!data && !!data.length));
  uniTextWeight = UniTextWeight;
  readonly isAdmin = this.uniAuthFacade.isAdminLevel();

  isActiveStatusModal = false;
  canChangeStatus = false;
  isSuccessColumnVisible = true;
  statusForm: FormGroup;
  changeCampaignStatus = [
    this.campaignStatus.failed,
    this.campaignStatus.completed,
  ];

  get extendedColumns(): string[] {
    if (this.uniAuthFacade.hasUnifonicAdminRole()) {
      return [
        CampaignTableColumns.accountName,
        CampaignTableColumns.createdBy,
      ];
    }

    if (this.uniAuthFacade.hasAccountAdminRole()) {
      return [CampaignTableColumns.createdBy];
    }

    return [];
  }

  get displayedColumns(): string[] {
    return [
      CampaignTableColumns.campaignName,
      CampaignTableColumns.senderName,
      CampaignTableColumns.createdAt,
      CampaignTableColumns.status,
      ...(this.isSuccessColumnVisible ? [CampaignTableColumns.success] : []),
      CampaignTableColumns.recipients,
      CampaignTableColumns.contentType,
      CampaignTableColumns.messageType,
      ...this.extendedColumns,
      CampaignTableColumns.actions,
    ];
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private campaignsFacade: UniCampaignsFacade,
    private uniAuthFacade: UniAuthFacade,
    private accountsFacade: AccountsFacade,
    private uniUserFacade: UniUserFacade,
    private campaignFormFactory: UniCampaignFormFactory,
    private uniFeatureFlagsService: UniFeatureFlagsService
  ) {
    super();
  }

  ngOnInit() {
    this.checkFeatureFlags();
    this.initTable();
    setTimeout(() => this.tableLeft = this.getTableOffsetLeft(), 0);
    this.setTimezone();
    this.selectChangeCampaignStatus();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setTimezone() {
    this.timezone = this.uniAuthFacade.user.timezone;
  }

  getStatus(campaign: Campaign): boolean {
    return !campaign.messagesCounters
      || campaign.status === this.campaignStatus.processing
      || campaign.status === this.campaignStatus.scheduled;
  }

  getProgress(campaign: Campaign) {
    const counters: CampaignMessageCounter[] = campaign.messagesCounters;
    const messagesCreated: number = campaign.recipientsCount;
    let messagesProcessed = 0;

    counters.map(item => {
      switch (item.messagesStatus) {
        case this.messageStatus.processed:
        case this.messageStatus.successful:
        case this.messageStatus.rejected: {
          messagesProcessed += item.count;
          return;
        }
        default: {
          return;
        }
      }
    });
    const messagesRatio = messagesProcessed / messagesCreated;

    return messagesRatio * 100 || 0;
  }

  getSuccessRate(campaign: Campaign) {
    const failedCount = failedCounter(campaign);
    const succeedCount = succeedCounter(campaign);
    const progressCount = progressCounter(failedCount, succeedCount);

    return getPercentage(succeedCount, progressCount);
  }

  getTableOffsetLeft(): number {
    return this.table.nativeElement.getBoundingClientRect().left;
  }

  getParams(): Params {
    return { queryParams: this.activatedRoute.snapshot.queryParams };
  }

  initTable(): void {
    this.campaignsFacade.campaigns$
      .pipe(
        skip(1),
        map(this.reduceStatuses),
        map(data => this.getRows(data)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(rows => {
        this.dataSource = new MatTableDataSource<Campaign>(rows);
        this.clearDetails();
        this.isLoaded = true;
      });
  }

  isCampaignFile(campaign: Campaign): boolean {
    return !!get(campaign, 'recipients.campaignFile.id');
  }

  getCampaignFile(campaign: Campaign) {
    const id = get(campaign, 'recipients.campaignFile.id');
    const file: Partial<File> = get(campaign, 'recipients.campaignFile.file') || {};
    const { originalName } = file;

    if (!id) {
      return;
    }

    this.campaignsFacade.setCampaignFile(id, originalName);
  }

  checkFeatureFlags() {
    return this.uniAuthFacade.featureFlags$
     .pipe(
        takeUntil(this.unsubscribe$),
        filter(featureFlags => !!featureFlags),
      ).subscribe(() => {
        this.canChangeStatus = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.change_campaign_status_2022_q_1);
        this.isSuccessColumnVisible = !this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.campaign_stats_2022_q_4);
      });
  }
  openStatusModal(id: string) {
    this.statusForm = this.campaignFormFactory.buildStatusForm(id);
    this.isActiveStatusModal = true;
  }

  hideStatusModal() {
   this.isActiveStatusModal = false;
  }

  showChangeStatus(status: CampaignStatus) {
    return this.canChangeStatus && this.isAdmin && [this.campaignStatus.processing, this.campaignStatus.sending].includes(status);
  }

  changeStatus() {
    const formValue = this.statusForm.value;
    this.campaignsFacade.setCampaignStatus(formValue);
  }

  selectChangeCampaignStatus() {
     this.campaignsFacade.changeCampaignStatus$
     .pipe(
       takeUntil(this.unsubscribe$)
     )
     .subscribe(() => {
       this.hideStatusModal();
    });
  }

  getTooltipPosition(index: number, count: number): 'top' | 'bottom' {
    return (count / 2) > index ? 'bottom' : 'top';
  }

  private reduceStatuses(campaigns: Campaign[]): Campaign[] {
    return campaigns.map(campaign => {
      return {
        ...campaign,
        status: reduceCampaignStatus(campaign.status)
      };
    });
  }
}
