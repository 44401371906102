import { Pipe, PipeTransform } from '@angular/core';
import { UniAuthFacade } from '../modules/uni-auth/shared/uni-auth.facade';

@Pipe({ name: 'secureId' })
export class SecureIdPipe implements PipeTransform {
  constructor(private uniAuthFacade: UniAuthFacade) {}

  transform(value: string, hide?: boolean): string {
    if (!value) {
      return '';
    }

    return this.uniAuthFacade.isAdminLevel() || !!hide
      ? `${ value.slice(0, 1) }********${ value.slice(-1) }`
      : value;
  }
}
