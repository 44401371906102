import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'uni-table-filter-wrapper',
  templateUrl: './uni-table-filter-wrapper.component.html',
  styleUrls: ['./uni-table-filter-wrapper.component.scss']
})
export class UniTableFilterWrapperComponent implements AfterViewInit {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;
  @Input() thead: HTMLElement;
  @Input() tableLeft: number;
  @Input() alignRight = false;
  @Input() width: number;

  ngAfterViewInit() {
    if(this.width) {
      this.wrapper.nativeElement.style.minWidth = this.width + 'px';
    }
    if (!this.thead) {
      return;
    }

    this.wrapper.nativeElement.style.left = this.thead.getBoundingClientRect().left - this.tableLeft + 'px';
    this.wrapper.nativeElement.style.width = this.thead.offsetWidth + 30 + 'px';
  }
}
