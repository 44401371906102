import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NavMenuItem, UniNavService } from '../../shared';

@Component({
  selector: 'uni-nav-new-item-group',
  templateUrl: './uni-nav-item-group.component.html',
  styleUrls: ['./uni-nav-item-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavItemGroupComponent implements OnInit {
  @Input() item: NavMenuItem;

  isSubitemsGroupExpanded$: Observable<boolean>;

  private isExpandedMenuItem$ = this.uniNavService.expandedMenuItemId$
    .pipe(
      map((expandedMenuItemId: string) => expandedMenuItemId === this.item.id),
    );

  constructor(private readonly uniNavService: UniNavService) { }

  ngOnInit(): void {
    this.setIsSubitemsGroupExpanded();
  }

  private setIsSubitemsGroupExpanded(): void {
    this.isSubitemsGroupExpanded$ = combineLatest([this.isExpandedMenuItem$, this.uniNavService.isNavCollapsed$])
      .pipe(
        map(([isExpandedMenuItem, isNavCollapsed]) => isExpandedMenuItem && !isNavCollapsed),
      );
  }
}
