import * as SnackbarActions from './uni-snackbar.actions';
import { uniSnackbarInitialState, UniSnackbarState } from './uni-snackbar.state';

export function snackbarReducer(state = uniSnackbarInitialState, action: SnackbarActions.All): UniSnackbarState {
  switch (action.type) {
    case SnackbarActions.SHOW_SNACKBAR:
      return {
        ...state,
        snackbars: [
          ...state.snackbars,
          {
            isActive: true,
            message: action.message,
            property: action.property,
            status: action.status,
            action: action.action,
          },
        ],
      };
    case SnackbarActions.HIDE_SNACKBAR:
      return {
        ...state,
        snackbars: [...state.snackbars.filter((_, index) => index)],
      };
    default:
      return state;
  }
}
