import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UniDirectivesModule } from '../../directives/directives.module';
import { UniIconModule } from '../uni-icon';
import { UniNavModule } from '../uni-nav/uni-nav.module';
import { UniMenuContentComponent } from './components/uni-menu-content/uni-menu-content.component';
import { UniProductsContentComponent } from './components/uni-products-content/uni-products-content.component';
import { UniTopBarAvatarComponent } from './components/uni-top-bar-avatar/uni-top-bar-avatar.component';
import {
  UniTopBarImpersonationComponent
} from './components/uni-top-bar-impersonation/uni-top-bar-impersonation.component';
import { UniTopBarLogoComponent } from './components/uni-top-bar-logo/uni-top-bar-logo.component';
import { UniTopBarMenuComponent } from './components/uni-top-bar-menu/uni-top-bar-menu.component';
import { UniTopBarProductsComponent } from './components/uni-top-bar-products/uni-top-bar-products.component';
import { UniTopBarSeparatorComponent } from './components/uni-top-bar-separator/uni-top-bar-separator.component';
import { UniTopBarComponent } from './components/uni-top-bar/uni-top-bar.component';
import { UniTopBarBalanceComponent } from './components/uni-top-bar-balance/uni-top-bar-balance.component';
import { UniTopBarTimezoneComponent } from './components/uni-top-bar-timezone/uni-top-bar-timezone.component';
import { UniTooltipModule } from '../uni-tooltip';
import { UniTopBarTopupComponent } from './components/uni-top-bar-topup/uni-top-bar-topup.component';
import { UniButtonModule } from '../uni-button/uni-button.module';
import { UniModalModule } from '../uni-modal';
import {
  UniTopBarTroubleshootConfirmComponent
} from './components/uni-top-bar-troubleshoot-confirm/uni-top-bar-troubleshoot-confirm.component';
import { UniTextModule } from '../uni-text';
import { UniLayoutCoreModule } from '../uni-layout/uni-layout-core.module';
import { UniTopBarPackagesComponent } from './components/uni-top-bar-packages/uni-top-bar-packages.component';
import { UniTopBarPackagesListComponent } from './components/uni-top-bar-packages-list/uni-top-bar-packages-list.component';
// import { UniAuthModule } from '../uni-auth';
// import { UniDataStateModule } from '../../shared/uni-data/store/uni-data-state.module';
import { UniTopBarPackagesDetailsComponent } from './components/uni-top-bar-packages-details/uni-top-bar-packages-details.component';
import { UniBoxModule } from '../uni-box/uni-box.module';

const components = [
  UniTopBarComponent,
  UniTopBarProductsComponent,
  UniTopBarMenuComponent,
  UniTopBarLogoComponent,
  UniTopBarAvatarComponent,
  UniProductsContentComponent,
  UniMenuContentComponent,
  UniTopBarSeparatorComponent,
  UniTopBarImpersonationComponent,
  UniTopBarBalanceComponent,
  UniTopBarTimezoneComponent,
  UniTopBarTopupComponent,
  UniTopBarPackagesComponent,
  UniTopBarPackagesListComponent,
  UniTopBarPackagesDetailsComponent
];

@NgModule({
  declarations: [
    ...components,
    UniTopBarTroubleshootConfirmComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    UniIconModule,
    UniNavModule,
    UniDirectivesModule,
    UniTooltipModule,
    UniButtonModule,
    UniModalModule,
    UniTextModule,
    UniLayoutCoreModule,
    UniBoxModule,
    // UniAuthModule,
    // UniDataStateModule
  ],
  exports: [...components],
})
export class UniTopBarModule { }
