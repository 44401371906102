<div [formGroup]="form">
  <uni-form-field>
    <input
      type="text"
      [formControlName]="fieldName"
      [placeholder]="placeholder"
      [required]="true"
      keyPattern="credit-card"
      uniInput
    >
      <span class="uni-input-credit-card__type" uniSuffix>{{ creditCardType }}</span>
  </uni-form-field>
</div>
