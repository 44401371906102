import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-form-row',
  templateUrl: './uni-form-row.component.html',
  styleUrls: ['./uni-form-row.component.scss']
})
export class UniFormRowComponent {
  @HostBinding('class.uni-form-row--margin') @Input() margin = true;
  @HostBinding('class.uni-form-row--has-columns') @Input() hasColumns = false;
}
