import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as countriesActions from '../store/uni-countries/uni-countries.actions';
import {
  selectCountries,
  selectCountriesQuery,
  selectCountriesChoice
} from '../store/uni-countries/uni-countries.selectors';
import { CountriesState } from '../store/uni-countries/uni-countries.state';
import { Country } from './uni-countries.model';

@Injectable()
export class UniCountriesFacade {
  countries$ = this.store.pipe(select(selectCountries));
  countriesQuery$ = this.store.pipe(select(selectCountriesQuery));
  countriesChoice$ = this.store.pipe(select(selectCountriesChoice));

  constructor(private store: Store<CountriesState>) {}

  setCountries(isPublic = false): void {
    this.store.dispatch(new countriesActions.SetCountries(isPublic));
  }

  setCountriesSuccess(countries: Country[]): void {
    this.store.dispatch(new countriesActions.SetCountriesSuccess(countries));
  }

  setCountriesChoice(): void {
    this.store.dispatch(new countriesActions.SetCountriesChoice());
  }
}
