import { Injectable } from "@angular/core";
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import {
  getUniShieldFraudData,
} from "./unishield.graphql";


@Injectable({
    providedIn: 'root'
})
export class UnishieldRepository {
    constructor(
        private apollo: Apollo,
    ){}

    gqlQueryGetUnishieldFraudData(variables, output){
        return this.apollo.query({
          query: getUniShieldFraudData(
              output
          ),
          variables: {...variables}
        })
        .pipe(
            map((response: any) => {
              return response.data.getUniShieldFraudData
            })
        )
    }
}
