import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';

@Component({
  selector: 'cc-campaign-name-form',
  templateUrl: './campaign-name-form.component.html'
})
export class CampaignNameFormComponent {
  @Input() form: FormGroup;
  @Input() isRequired = true;
  @Input() isActive = true;
  step = 1;

  get nameForm(): FormGroup {
    return this.form.get('nameForm') as FormGroup;
  }

  get nameControl(): FormControl {
    return this.nameForm.get('name') as FormControl;
  }

  get stepControl(): FormControl {
    return this.form.get('step') as FormControl;
  }

  get isNextStep(): FormControl {
    return this.form.get('contentForm') as FormControl;
  }

  constructor(private campaignFormFactory: UniCampaignFormFactory) {}

  onConfirmStep(): void {
    if (!this.isNextStep) {
      this.campaignFormFactory.addContentForm(this.form);
      this.campaignFormFactory.addCustomControls(this.form);
    }

    this.stepControl.setValue(this.step + 1);
    this.nameForm.disable();
  }

  onEditStep(): void {
    this.stepControl.setValue(this.step);
    this.nameForm.enable();
  }
}
