import { concat, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { QueryActions } from '../../../../store';
import { UniIndustryRepository } from '../../shared/uni-industry.repository';
import * as IndustryActions from './uni-industry.actions';
import { GET_INDUSTRIES_QUERY } from './uni-industry.state';

@Injectable()
export class IndustryEffects {

  constructor(
    private actions$: Actions,
    private uniIndustryRepository: UniIndustryRepository,
    private translate: TranslateService,
  ) { }

  @Effect() SetIndustries$ = this.actions$
    .pipe(
      ofType<IndustryActions.SetIndustries>(IndustryActions.SET_INDUSTRIES),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_INDUSTRIES_QUERY)),
        this.uniIndustryRepository
          .getIndustries()
          .pipe(
            map(data => data.map(({ key }) => ({
              key,
              name: this.translate.instant(key),
            }))),
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_INDUSTRIES_QUERY, data)),
              of(new IndustryActions.SetIndustriesSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_INDUSTRIES_QUERY, error)),
            )),
          ),
        ),
      ),
    );
}
