import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ORDERS_KEY } from './store/uni-orders.state';
import { uniOrdersReducers } from './store/uni-orders.reducers';
import { EffectsModule } from '@ngrx/effects';
import { UniOrdersEffects } from './store/uni-orders.effects';
import { UniOrdersRepository } from './shared/uni-orders.repository';
import { UniOrdersFacade } from './shared/uni-orders.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(ORDERS_KEY, uniOrdersReducers),
    EffectsModule.forFeature([UniOrdersEffects])
  ],
  exports: [],
  providers: [
    UniOrdersRepository,
    UniOrdersFacade,
  ],
})
export class UniOrdersModule {
}
