<ng-container *ngIf="vm$ | async as vm">
  <div class="chart-container">
    <header>
      <app-span>{{'ci.unishield.blockedBySender' | translate}}</app-span>
    </header>
    <div class="card-content">
      <div class="card-grid">
        <app-chart-bar
          [source]="vm.data || []"
          [xAxis]="vm.xAxis"
          *ngIf="!vm.loading"
          
        ></app-chart-bar>
      </div>
    </div>
  </div>
</ng-container>
