import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Params } from '@angular/router';
import { UniGroup, UniDynamicGroup } from './uni-audience.model';
import { httpEncoder } from '../../../utils/http.utils';
import { Environment } from '../../../utils/environment.utils';
import {AUDIENCE_KEY} from '../store/uni-audience.state';

@Injectable()
export class UniAudienceRepository {
  audienceUrl = `${ Environment.getMainHost(Environment.APP.AUDIENCE) }/api/audience`;

  constructor(
    private http: HttpClient,
    @Optional() @Inject('environment') public environment,
  ) {}

  getGroup(id: string): Observable<UniGroup> {
    return this.http.get<UniGroup>(`${this.audienceUrl}/groups/${id}`);
  }

  getGroups(params: Params, page?: string): Observable<HttpResponse<UniGroup[]>> {
    return this.http.get<UniGroup[]>(`${this.audienceUrl}/groups`, {
      params,
      observe: 'response',
      withCredentials: true,
      ...(page ? {
        headers: {
          queryName: 'getGroups',
          queryGroups: [AUDIENCE_KEY],
          'X-UNIFONIC-PAGE': page
        }
      } : {})
    });
  }

  deleteGroup(ids: string[]): Observable<any> {
    return this.http.delete(`${this.audienceUrl}/groups/bulk`, {
      params: httpEncoder({ 'groupIds[]': ids }),
    });
  }

  getDynamicGroup(id: string): Observable<UniDynamicGroup> {
    return this.http.get<UniDynamicGroup>(`${this.audienceUrl}/dynamic-groups/${id}`,{
    });
  }

  getDynamicGroups(params: Params, page?: string): Observable<HttpResponse<UniDynamicGroup[]>> {
    return this.http.get<UniDynamicGroup[]>(`${this.audienceUrl}/dynamic-groups`, {
      params,
      observe: 'response',
      withCredentials: true,
      ...(page ? {
        headers: {
          queryName: 'getDynamicGroups',
          queryGroups: [AUDIENCE_KEY],
          'X-UNIFONIC-PAGE': page
        }
      } : {})
    });
  }

  deleteDynamicGroup(ids: string[]): Observable<any> {
    return this.http.delete(`${this.audienceUrl}/dynamic-groups/bulk`, {
      params: httpEncoder({ 'groupIds[]': ids }),
    });
  }
}
