export interface UniSenderNameResponse {
  id: string;
  name: string;
  type: string;
  messageTypes?: UniMessageType[];
}

export enum UniMessageType {
  warning = 'warning',
  awareness = 'awareness',
  service = 'service',
  personal = 'personal',
  promotional = 'promotional'
}

export interface UniSenderName extends UniSenderNameResponse {
  isTrial: boolean;
}

export enum ItemStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum VerificationStatus {
  processing = 'processing',
  rejected = 'rejected',
  approved = 'approved',
}

export interface Senders {
  [senderName: string]: number;
}

export function mapUniSenderNameResponse(
  response: UniSenderNameResponse,
  isWithSmsServiceTrials: boolean,
  isAccountWithRestrictedTrialSid: boolean,
): UniSenderName {
  const isTrial = isWithSmsServiceTrials && isAccountWithRestrictedTrialSid && isTrialSenderName(response);

  return { ...response, isTrial };
}

function isTrialSenderName(senderName: UniSenderName | UniSenderNameResponse): boolean {
  if (!senderName) {
    return false;
  }

  return ['UNITEST'].includes(senderName.name);
}
