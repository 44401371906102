<uni-section *ngIf="datesForm">
  <uni-box>
    <uni-box-content>
      <uni-section-header size="medium">
        <uni-text-caption>
          {{ 'campaign.dates' | translate }}
        </uni-text-caption>
      </uni-section-header>
      <cc-campaign-schedule-toggle [form]="datesForm"></cc-campaign-schedule-toggle>

      <uni-form-field
        [margin]="false"
        class="campaign-dates-form-schedule-time-info"
      >
        <uni-form-field-info [type]="uniFormInfoType.tip">
            <uni-text-body
              [isUppercase]="false"
              [weight]="uniTextWeight.regular"
              [color]="uniTextColor.black"
              [innerHTML]="scheduleTimeInfoKey | translate"
            >
            </uni-text-body>
        </uni-form-field-info>
      </uni-form-field>

      <ng-container *ngIf="isScheduledControl.value">
        <uni-box-content
          [isHorizontalSpace]="false"
          [margin]="'small'"
        >
          <cc-campaign-date
            [form]="dateForm"
            [timezone]="timezone"
          ></cc-campaign-date>
        </uni-box-content>
        <uni-box-content
          [isHorizontalSpace]="false"
          [margin]="false"
        >
          <cc-campaign-time
            [form]="dateForm"
            [timezone]="timezone"
          ></cc-campaign-time>
        </uni-box-content>
      </ng-container>
    </uni-box-content>
    <uni-box-footer>
      <uni-buttons>
        <uni-button
          [isMargin]="true"
          [style]="'secondary'"
          routerLink="/campaigns/sms"
        >
          {{ 'action.cancel' | translate }}
        </uni-button>
        <uni-button
          type="submit"
          (click)="onShowSummary()"
          [disabled]="form.invalid"
          [isMargin]="true"
        >
          {{ 'campaign.summary' | translate }}
        </uni-button>
      </uni-buttons>
    </uni-box-footer>
  </uni-box>
</uni-section>
