import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { UniRouteService } from '../../../shared/uni-route.service';
import { hasQueryFailed, hasQueryStatus, isQueryFinished } from '../../../store';
import { UniAuthFacade } from './uni-auth.facade';
import { FeatureFlagKeys } from '../shared/uni-feature-flags.model';
import { User } from '../../../modules/uni-auth/shared/uni-auth.model';
import { UniFeatureFlagsService } from '../shared/uni-feature-flags.service';
import { UniUserpilotService } from '../../../shared/uni-userpilot.service';
import { UniAnalyticsService } from '../../../shared/uni-analytics.service';
import { Userpilot } from 'userpilot';
import { UniAuthService } from './uni-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  userpilotUser = localStorage.getItem('userpilotUser');

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniRouteService: UniRouteService,
    private uniAuthService: UniAuthService,
    private uniFeatureFlagService: UniFeatureFlagsService,
    private uniUserpilotService: UniUserpilotService,
    private uniAnalyticsService: UniAnalyticsService,
  ) { }

  getUserMeData(): Observable<any> {
    return this.uniAuthFacade.userMeQuery$.pipe(
      tap(query => {
        if (hasQueryFailed(query)) {
          const errorCode = query?.error?.status;

          errorCode === 403 ? this.uniAuthService.logout() : this.uniRouteService.navigate('/');
        }
      }),
      tap(() => {
        if ( this.userpilotUser ) {
          Userpilot.reload();
        }
      }),
      tap(query => !hasQueryStatus(query)
        ? this.uniAuthFacade.setUserMe()
        : false
      ),
      filter(data => isQueryFinished(data)),
      tap(({ response }) => {
        this.uniAuthFacade.setData(response);
      }),
      take(1),
      tap(({ response }) => {
        const { user, systemSettings } = response;
        if (user.account) {
          user.account.isWhiteLabel = systemSettings.isWhitelabelActive;
          this.checkFlagsAndInitServices(response);
        }
      })
    );
  }

  canActivate(): Observable<boolean> {
    return this.getUserMeData()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
  }

  private checkFlagsAndInitServices(response: any) {
    this.uniFeatureFlagService.getPermission$(FeatureFlagKeys.mixpanel_analytics_2022_q_2, true)
      .pipe(take(1))
      .subscribe(() => {
        this.uniAnalyticsService.init(response);
      });
    if (!this.isAdmin(response.user)) {
        this.uniFeatureFlagService.getPermission$(FeatureFlagKeys.userpilot_2022_q_1, true)
         .pipe(take(1))
         .subscribe(() => {
            this.uniUserpilotService.init(response.user);
         });
    }
  }

  private isAdmin(user: User) {
    return !!user && !!user.id && user.isAdminLevel;
  }
}
