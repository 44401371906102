import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-card',
  templateUrl: './uni-card.component.html',
  styleUrls: ['./uni-card.component.scss'],
})
export class UniCardComponent {
  @Input() class = '';
  @Input() size = 'default';

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-card--${this.size}`;
  }
}
