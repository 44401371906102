import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { Params } from '@angular/router';
import {
  CallerIdChannel,
  CallerIdChannelCharges,
  CallerIdChannelChargesParams,
  CallerIdChannelConfig,
  CallerIdChannelConfigParams,
  CallerIdChannelPost,
  PageableResponse,
} from '../../models/caller-id.model';
import { httpEncoder } from '../../utils';
import { CALLER_ID_KEY } from './caller-id.state';

@Injectable()
export class CallerIdChannelRepository {
  voiceJavaApiUrl = this.environment.voiceJavaApiUrl;

  constructor(
    private http: HttpClient,
    @Optional() @Inject('environment') public environment
  ) {}

  getCallerIdChannels(
    params?: Params
  ): Observable<HttpResponse<PageableResponse<CallerIdChannel>>> {
    return this.http.get<PageableResponse<CallerIdChannel>>(
      `${this.voiceJavaApiUrl}/v1/protected/channel-subscriptions`,
      {
        params: httpEncoder({
          ...params,
          size: params?.size ? params.size : 10,
          page: params?.page ? Number(params.page) - 1 : 0,
        }),
        observe: 'response',
        headers: {
          queryName: 'getCallerIdChannels',
          queryGroups: [CALLER_ID_KEY],
        },
      }
    );
  }

  getCallerIdChannelConfig(
    params: CallerIdChannelConfigParams
  ): Observable<HttpResponse<CallerIdChannelConfig>> {
    return this.http.get<CallerIdChannelConfig>(
      `${this.voiceJavaApiUrl}/v1/protected/channel-subscriptions/channel-config`, {
        params: httpEncoder(params),
        observe: 'response',
        headers: {
          queryName: 'getCallerIdChannelConfig',
          queryGroups: [CALLER_ID_KEY],
        },
      }
    );
  }

  getCallerIdChannelCharges(
    params: CallerIdChannelChargesParams
  ): Observable<HttpResponse<CallerIdChannelCharges>> {
    return this.http.get<CallerIdChannelCharges>(
      `${this.voiceJavaApiUrl}/v1/protected/channel-subscriptions/expected-charges`, {
        params: httpEncoder(params),
        observe: 'response',
        headers: {
          queryName: 'getCallerIdChannelCharges',
          queryGroups: [CALLER_ID_KEY],
        },
      }
    );
  }

  postCallerIdChannel(payload: CallerIdChannelPost): Observable<void> {
    return this.http.post<void>(`${this.voiceJavaApiUrl}/v1/protected/channel-subscriptions`, payload, {
      headers: {
        queryName: 'postCallerIdChannel',
        queryGroups: [CALLER_ID_KEY],
      },
    });
  }
}
