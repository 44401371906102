<ng-container>
  <uni-section>
    <uni-box>

      <uni-box-content [margin]="'medium'">
        <uni-section-header size="medium">
          <uni-text-caption>
            {{ 'campaign.name' | translate }}
          </uni-text-caption>
        </uni-section-header>
        <uni-form-container>
          <cc-campaign-name
            [form]="nameForm"
            [isRequired]="false"
          ></cc-campaign-name>
        </uni-form-container>
      </uni-box-content>

      <ng-container *ngIf="isCustomForm">
        <uni-separator [isSpace]="false"></uni-separator>
        <uni-box-content [margin]="'medium'">
          <uni-section-header size="medium">
            <uni-text-caption>
              {{ 'campaign.content' | translate }}
            </uni-text-caption>
          </uni-section-header>
          <cc-campaign-sender-name
            [form]="contentForm"
            [isActive]="false"
            [isRequired]="false"
          ></cc-campaign-sender-name>
        </uni-box-content>

        <uni-box-content [isVerticalSpace]="false">
          <uni-separator [isSpace]="false"></uni-separator>
        </uni-box-content>
        <uni-box-content [margin]="'medium'">
          <cc-campaign-recipients
            [form]="form"
            [isActive]="false"
            [isEditable]="false"
          ></cc-campaign-recipients>
        </uni-box-content>

        <uni-box-content [isVerticalSpace]="false">
          <uni-separator [isSpace]="false"></uni-separator>
        </uni-box-content>
        <uni-box-content [margin]="'medium'">
            <cc-campaign-message
              [form]="form"
              [isActive]="false"
              [isEditable]="false"
            ></cc-campaign-message>
        </uni-box-content>
        <uni-box-content [margin]="'medium'" *ngIf="messageTypeControl">
          <cc-campaign-message-type
            [form]="form"
            [options]="messageTypeOptions"
            [isActive]="false"
            [isEditable]="false">
          </cc-campaign-message-type>
        </uni-box-content>
      </ng-container>

      <ng-container *ngIf="isFileToSMSForm">
        <uni-separator [isSpace]="false"></uni-separator>
        <uni-box-content>
          <uni-section-header size="medium">
            <uni-text-caption>
              {{ 'campaign.content' | translate }}
            </uni-text-caption>
          </uni-section-header>
          <cc-campaign-file
            [form]="contentForm"
            [isEditable]="false"
          ></cc-campaign-file>
        </uni-box-content>
      </ng-container>

      <ng-container *ngIf="datesForm">
        <uni-separator [isSpace]="false"></uni-separator>
        <uni-box-content [margin]="'medium'">
          <uni-section-header size="medium">
            <uni-text-caption>
              {{ 'campaign.dates' | translate }}
            </uni-text-caption>
          </uni-section-header>
          <cc-campaign-summary-date
            [date]="dateToDisplay"
          ></cc-campaign-summary-date>
        </uni-box-content>
      </ng-container>

      <ng-container *ngIf="isCustomForm">
        <uni-separator [isSpace]="false"></uni-separator>
        <uni-box-content [margin]="'medium'">
          <uni-section-header size="medium">
            <uni-text-caption>
              {{ 'campaign.costEstimate' | translate }}
            </uni-text-caption>
          </uni-section-header>
          <cc-campaign-balance-estimation [form]="form"></cc-campaign-balance-estimation>
        </uni-box-content>
      </ng-container>

      <uni-box-footer>
        <uni-buttons>
          <uni-button
            [isMargin]="true"
            [style]="'secondary'"
            (click)="onEdit()"
          >
            {{ 'campaign.edit' | translate }}
          </uni-button>
          <uni-button
            [isMargin]="true"
            (click)="isEnabledTwoFactor ? toggleModal() : onSubmit()"
          >
            {{ ( canDirectSend ? 'campaign.send' : 'label.requestApproval' ) | translate }}
          </uni-button>
        </uni-buttons>
      </uni-box-footer>

    </uni-box>
  </uni-section>
</ng-container>

<uni-2fa
  *ngIf="isModalActive"
  [isActive]="isModalActive"
  [codeType]="'create_sms_campaign'"
  (isActiveChange)="toggleModal()"
  (getTokenAction)="onSubmit($event)"
></uni-2fa>
