import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-dropdown-item',
  templateUrl: './uni-dropdown-item.component.html',
  styleUrls: ['./uni-dropdown-item.component.scss'],
})
export class UniDropdownItemComponent {
  @HostBinding('class.is-disabled') @Input() isDisabled = false;
  @HostBinding('class.is-tooltip') @Input() matTooltip = null;
}
