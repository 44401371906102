export enum ApplicationPages {
  CampaignAdd = 'CAMPAIGN_ADD',
  CampaignDetails = 'CAMPAIGN_DETAILS',
  CampaignReport = 'CAMPAIGN_REPORT',
  CampaignsList = 'CAMPAIGNS_LIST',
  CustomRoutingRuleAdd = 'CUSTOM_ROUTING_RULE_ADD',
  CustomRoutingRuleEdit = 'CUSTOM_ROUTING_RULE_DETAIL',
  CustomRoutingRulesList = 'CUSTOM_ROUTING_RULE_LIST',
  DefaultRoutingRuleAdd = 'DEFAULT_ROUTING_RULE_ADD',
  DefaultRoutingRuleEdit = 'DEFAULT_ROUTING_RULE_DETAIL',
  DefaultRoutingRulesList = 'DEFAULT_ROUTING_RULE_LIST',
  SenderNameAdd = 'SENDERNAME_ADD',
  SenderNamesList = 'SENDERNAMES_LIST',
  SubaccountAdd = 'SUBACCOUNT_ADD',
  SubaccountEdit = 'SUBACCOUNT_DETAIL',
  SubaccountsList = 'SUBACCOUNTS_LIST',
  PackagesList = 'PACKAGES_LIST',
  CampaignPendingApprovalList = 'CAMPAIGN_PENDING_APPROVAL_LIST',
  ApplicationListPage = 'APPLICATION_LIST',
  ApplicationDetailPage = 'APPLICATION_DETAIL',
  ApplicationEditPage = 'APPLICATION_EDIT',
  ApplicationNewPage = 'APPLICATION_NEW',
  UserAdd = 'USER_ADD',
  UserDetails = 'USER_DETAIL',
  UsersList = 'USERS_LIST',
  TrafficReportView = 'TRAFFIC_REPORT_VIEW',
  ContactsList = 'CONTACT_LIST',
  GroupsList = 'GROUP_LIST',
  ContactUpdated = 'CONTACT_UPDATED',
  GroupsUpdated = 'GROUP_UPDATED',
  BalanceTransfer = 'BALANCE_TRANSFER',
  UnitTransfer = 'UNIT_TRANSFER',
  ProfileGeneral = 'PROFILE_GENERAL',
  ProfileAuthentication = 'PROFILE_AUTHENTICATION',
  ProfileNotificationSettings = 'PROFILE_NOTIFICATION_SETTINGS',
  QuickSms = 'QUICK_SMS',
  MessageLogs = 'MESSAGE_LOGS',
  SenderNames = 'SENDER_NAMES',
  GroupDelete = 'GROUP_DELETE'
}
