import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UniCampaignFormService {
  public selectedSenderName$ = new Subject();

  saveFormData(data: any): void {
    localStorage.setItem('campaign_form', JSON.stringify(data));
    localStorage.setItem('campaign_form_date', new Date().getTime().toString());
  }

  loadFormData(): FormData {
    const expirationTime = 15 * 60 * 1000; // 15 minutes
    const currentDate = new Date().getTime();
    const formDate = localStorage.getItem('campaign_form_date');
    const dataEncoded = localStorage.getItem('campaign_form');

    return formDate && currentDate - Number(formDate) < expirationTime
      ? JSON.parse(dataEncoded)
      : null;
  }

  resetFormData(): void {
    localStorage.removeItem('campaign_form');
  }

  setMenuListener(): void {
    const monolithLink = document.querySelector('a[href=\'/campaigns/sms/create\']');

    if (monolithLink) {
      monolithLink.addEventListener('click', () => this.resetFormData());
    }
  }
}
