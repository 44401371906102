import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { isQueryInProgress } from '../../../../store';
import { AutoUnsubscribe } from '../../../../utils';
import { UniTimezonesFacade } from '../../shared/uni-timezones.facade';
import { Timezone, TimezoneName } from '../../shared/uni-timezones.model';

@Component({
  selector: 'uni-timezones-autocomplete',
  templateUrl: './uni-timezones-autocomplete.component.html',
})
export class UniTimezonesAutocompleteComponent extends AutoUnsubscribe implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
  @Input() form: FormGroup;
  @Input() margin = true;
  @Input() required = false;

  timezones: Timezone[];
  filteredTimezones: Timezone[];
  isLoaderActive = false;
  isPanelOpen = false;
  isEmpty = isEmpty;

  get timezoneValueControl(): FormControl {
    return this.form.get('value') as FormControl;
  }

  get timezoneNameControl(): FormControl {
    return this.form.get('name') as FormControl;
  }

  constructor(private uniTimezonesFacade: UniTimezonesFacade) {
    super();
  }

  ngOnInit() {
    this.uniTimezonesFacade.setTimezones();

    this.subs.add(
      this.selectTimezones(),
      this.setLoadingStatus()
    );
  }

  setLoadingStatus(): Subscription {
    return this.uniTimezonesFacade.timezonesQuery$.subscribe(status => this.isLoaderActive = isQueryInProgress(status));
  }

  selectTimezones(): Subscription {
    return this.uniTimezonesFacade.timezones$
      .pipe(filter(data => !!data.length))
      .subscribe(timezones => {
        this.timezones = timezones;

        if (!this.timezoneNameControl.value.length) {
          this.timezoneNameControl.setValue(this.getTimezoneName(this.timezoneValueControl.value));
        }

        this.onTimezoneChange();
      });
  }

  onTimezoneChange(): void {
    this.subs.add(
      this.timezoneNameControl.valueChanges
        .pipe(
          startWith(this.timezoneNameControl.value),
          map(value => this.getFilteredTimezones(value))
        )
        .subscribe(filteredTimezones => this.filteredTimezones = filteredTimezones)
    );
  }

  getFilteredRegions(names: TimezoneName[], value: string): TimezoneName[] {
    return names.filter(obj => obj.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }

  getFilteredTimezones(value: string): Timezone[] {
    this.timezoneValueControl.setValue(this.getTimezoneValue(value));

    if (value) {
      return this.timezones
        .map(timezone => ({ region: timezone.region, names: this.getFilteredRegions(timezone.names, value)}))
        .filter(timezone => timezone.names.length > 0);
    }

    return this.timezones;
  }

  getTimezoneValue(name: string): string {
    for (const timezone of this.timezones) {
      for (const timezoneName of timezone.names) {
        if (timezoneName.name === name) {
          return timezoneName.value;
        }
      }
    }

    return '';
  }

  getTimezoneName(value: string): string {
    for (const timezone of this.timezones) {
      for (const timezoneName of timezone.names) {
        if (timezoneName.value === value) {
          return timezoneName.name;
        }
      }
    }

    return '';
  }

  clearAutocomplete(): void {
    setTimeout(() => {
      this.timezoneNameControl.setValue('');
      this.timezoneValueControl.setValue('');
      this.autocompleteTrigger.openPanel();
    }, 100);
  }

  openAutocomplete(): void {
    setTimeout(() => this.autocompleteTrigger.openPanel(), 0);
  }

  toggleAutocomplete(isPanelOpen: boolean): void {
    setTimeout(() => isPanelOpen
        ? this.autocompleteTrigger.closePanel()
        : this.autocompleteTrigger.openPanel()
    , 0);

  }
}
