import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-details-headline',
  templateUrl: './uni-details-headline.component.html',
  styleUrls: ['./uni-details-headline.component.scss']
})
export class UniDetailsHeadlineComponent {
  @Input() marginType = 'small';
  @Input() paddingType = 'big';
  @HostBinding('class') get getClasses(): string {
    return `uni-details-headline--margin-${ this.marginType } uni-details-headline--padding-${ this.paddingType }`;
  }
}
