<uni-box-content [isVerticalSpace]="false">
  <uni-separator [isSpace]="false"></uni-separator>
</uni-box-content>
<uni-box-content class="campaign-custom-form__sid-wrapper">
  <cc-campaign-sender-name [form]="contentForm" class="campaign-custom-form__sid-component"></cc-campaign-sender-name>

  <cc-campaign-trial-info
    *ngIf="isInTrialMode"
    class="campaign-custom-form__sid-trial"
  ></cc-campaign-trial-info>
</uni-box-content>

<uni-box-content [isVerticalSpace]="false">
  <uni-separator [isSpace]="false"></uni-separator>
</uni-box-content>
<uni-box-content>
  <cc-campaign-country-autocomplete [form]="form"></cc-campaign-country-autocomplete>
</uni-box-content>

<uni-box-content [isVerticalSpace]="false">
  <uni-separator [isSpace]="false"></uni-separator>
</uni-box-content>
<uni-box-content>
  <cc-campaign-recipients [form]="form"></cc-campaign-recipients>
</uni-box-content>

<uni-box-content [isVerticalSpace]="false">
  <uni-separator [isSpace]="false"></uni-separator>
</uni-box-content>
<uni-box-content>
  <cc-campaign-message
    [form]="form"
    [isInTrialMode]="isInTrialMode"
  ></cc-campaign-message>
</uni-box-content>
<uni-box-content *ngIf="messageTypeOptions$ | async as messageTypeOptions">
  <cc-campaign-message-type
    [form]="form"
    [options]="messageTypeOptions"
  ></cc-campaign-message-type>
</uni-box-content>
