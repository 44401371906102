<div class="search-dropdown" (uniClickOutside)="hideDropdownContent()">
    <uni-form-field
        [margin]="isMargin"
        (click)="showDropdownContent()"
    >
        <input
            class="search-dropdown__input"
            [ngClass]="{'search-dropdown__input--disabled': control.disabled}"
            type="text"
            [formControl]="control"
            [placeholder]="(isLoading ? 'label.loading' : placeholderKey) | translate"
            readonly
            uniInput
            uniInputDisplayValueFromList
            [data]="data"
            [valueKey]="valueKey"
            [displayValueKey]="displayValueKey"
        >
        <uni-icon
            [disabled]="control.disabled"
            [name]="isLoading ? 'spinner fa-spin' : (isDropdownContentShown ? 'angle-up' : 'angle-down')"
            uniSuffix
        ></uni-icon>
    </uni-form-field>

    <div *ngIf="isDropdownContentShown" class="search-dropdown__content">
        <uni-form-search
            [form]="searchForm"
            controlName="search"
            [isLoading]="isLoading"
            [isClear]="true"
            [margin]="false"
        ></uni-form-search>

        <div *ngIf="data?.length" class="search-dropdown__list">
            <div
                *ngFor="let item of filteredData"
                class="search-dropdown__list__item"
                [ngClass]="{'selected': item && item[valueKey] === control.value }"
                (click)="onItemSelected(item)"
            >
                {{ item ? item[displayValueKey] : '' }}
            </div>
        </div>
    </div>
</div>