import { SubSink } from 'subsink';

import { OnDestroy, Component } from '@angular/core';

@Component({ template: '' })
  // eslint-disable-next-line
export class AutoUnsubscribe implements OnDestroy {
  subs = new SubSink();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
