import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as paginationActions from '../store/uni-pagination.actions';
import { UniPaginationState } from '../store/uni-pagination.state';

@Injectable()
export class UniPaginationFacade {
  constructor(private store: Store<UniPaginationState>) {}

  setPage(pageNumber: number, isAltOrder?: boolean): void {
    const page = pageNumber - (isAltOrder ? 1 : 0);
    this.store.dispatch(new paginationActions.SetPage({ page }));
  }
}
