import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { CampaignTabs } from '../../shared/uni-campaign.model';

@Component({
  selector: 'cc-campaign-tabs',
  templateUrl: './campaign-tabs.component.html',
})
export class CampaignTabsComponent {
  campaignTabs = CampaignTabs;
  @Input() activeTab: string = this.campaignTabs.overview;
  @Input() campaignId: string;
  @Input() canViewReports = true;
  @Input() isDisabled = true;
  @Input() queryParams: {};
  @Output() tabChange = new EventEmitter();
  params: Params = {};

  constructor(
    private location: Location,
  ) {}

  navigate(route: string = '') {
    let tab;

    if (route === '') {
      tab = this.campaignTabs.overview;
    } else {
      const params = Object.keys(this.queryParams).map(key => key + '=' + this.queryParams[key]).join('&');
      tab = route;
      route = params.length > 0 ? route + '?' + params : route;
    }

    this.location.go(`/campaigns/sms/${this.campaignId}/${route}`);
    this.tabChange.emit(tab);
    this.activeTab = tab;
  }
}
