import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniAccountsState } from './uni-accounts.state';

export const selectUniAccountsFeature = createFeatureSelector<UniAccountsState>('uniAccounts');

export const selectUniAccounts = createSelector(
  selectUniAccountsFeature,
  (state: UniAccountsState) => state.accounts,
);

export const selectUniAccountsQuery = createSelector(
  selectUniAccountsFeature,
  (state: UniAccountsState) => state.getAccountsQuery,
);
