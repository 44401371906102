import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import * as navActions from '../store/uni-nav.actions';
import { selectNavState, selectProductNavState, selectProfileNavState } from '../store/uni-nav.selectors';
import { NavState } from '../store/uni-nav.state';

@Injectable()
export class UniNavFacade {
  isNavOpen$ = this.store.pipe(select(selectNavState));
  isProductNavOpen$ = this.store.pipe(select(selectProductNavState));
  isProfileNavOpen$ = this.store.pipe(select(selectProfileNavState));

  private _isTroubleshootConfirmationOpen$ = new BehaviorSubject<boolean>(false);
  isTroubleshootConfirmationOpen$ = this._isTroubleshootConfirmationOpen$.asObservable();

  constructor(private store: Store<NavState>) {}

  setNavState(state: boolean): void {
    this.store.dispatch(new navActions.SetNavState(state));
  }

  setProductNavState(state: boolean): void {
    this.store.dispatch(new navActions.SetProductNavState(state));
  }

  setProfileNavState(state: boolean): void {
    this.store.dispatch(new navActions.SetProfileNavState(state));
  }

  openTroubleshootConfirmation(): void {
    this._isTroubleshootConfirmationOpen$.next(true);
  }

  closeTroubleshootConfirmation(): void {
    this._isTroubleshootConfirmationOpen$.next(false);
  }
}
