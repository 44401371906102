import { NgModule } from '@angular/core';

import { UniDirectivesModule } from './directives/directives.module';
import { UniAccountsModule } from './modules/uni-accounts/uni-accounts.module';
import { UniAppModule } from './modules/uni-app/uni-app.module';
import { UniAudioModule } from './modules/uni-audio/uni-audio.module';
import { UniAuthModule } from './modules/uni-auth/uni-auth.module';
import { UniBoxModule } from './modules/uni-box/uni-box.module';
import { UniButtonModule } from './modules/uni-button/uni-button.module';
import { UniCardModule } from './modules/uni-card/uni-card.module';
import { UniClipboardModule } from './modules/uni-clipboard/uni-clipboard.module';
import { UniCountriesModule } from './modules/uni-countries/uni-countries.module';
import { UniDetailsModule } from './modules/uni-details/uni-details.module';
import { UniFiltersModule } from './modules/uni-filters';
import { UniFormModule } from './modules/uni-form/uni-form.module';
import { UniIconModule } from './modules/uni-icon/uni-icon.module';
import { UniIndustryModule } from './modules/uni-industry/uni-industry.module';
import { UniLayoutModule } from './modules/uni-layout/uni-layout.module';
import { UniModalModule } from './modules/uni-modal/uni-modal.module';
import { UniNavModule } from './modules/uni-nav/uni-nav.module';
import { UniNavNewModule } from './modules/uni-nav-new/uni-nav-new.module';
import { UniPaginationModule } from './modules/uni-pagination/uni-pagination.module';
import { UniRolesModule } from './modules/uni-roles/uni-roles.module';
import { UniSnackbarModule } from './modules/uni-snackbar/uni-snackbar.module';
import { UniStepModule } from './modules/uni-step/uni-step.module';
import { UniSwitcherModule } from './modules/uni-switcher/uni-switcher.module';
import { UniTableModule } from './modules/uni-table/uni-table.module';
import { UniTileModule } from './modules/uni-tile/uni-tile.module';
import { UniTimezonesModule } from './modules/uni-timezones/uni-timezones.module';
import { UniTitleModule } from './modules/uni-title/uni-title.module';
import { UniToggleModule } from './modules/uni-toggle/uni-toggle.module';
import { UniTooltipModule } from './modules/uni-tooltip/uni-tooltip.module';
import { UniTopBarModule } from './modules/uni-top-bar/uni-top-bar.module';
import { UniTreeModule } from './modules/uni-tree/uni-tree.module';
import { UniListModule } from './modules/uni-list/uni-list.module';
import { UniDropdownModule } from './modules/uni-dropdown/uni-dropdown.module';
import { UniPipesModule } from './pipes/pipes.module';
import { UniSeparatorModule } from './modules/uni-separator';
import { UniAudienceModule } from './modules/uni-audience/uni-audience.module';
import { UniFilterBarModule } from './modules/uni-filter-bar/uni-filter-bar.module';
import { UniSortModule } from './modules/uni-sort/uni-sort.module';
import { UniPagesModule } from './modules/uni-pages/uni-pages.module';
import { UniPluralModule } from './modules/uni-plural/uni-plural.module';
import { UniTextModule } from './modules/uni-text/uni-text.module';
import { UniFooterModule } from './modules/uni-footer';
import { UniLogsModule } from './modules/uni-logs';
import { UniPreviewModule } from './modules/uni-preview/uni-preview.module';
import { UniPriceModule } from './modules/uni-price';
import { UniSegmentedTabsModule } from './modules/uni-segmented-tabs/uni-segmented-tabs.module';

const modules = [
  UniFormModule,
  UniLayoutModule,
  UniTitleModule,
  UniTableModule,
  UniPipesModule,
  UniClipboardModule,
  UniIconModule,
  UniButtonModule,
  UniCardModule,
  UniSwitcherModule,
  UniBoxModule,
  UniModalModule,
  UniPaginationModule,
  UniAuthModule,
  UniTimezonesModule,
  UniCountriesModule,
  UniIndustryModule,
  UniFiltersModule,
  UniSnackbarModule,
  UniTooltipModule,
  UniRolesModule,
  UniNavModule,
  UniNavNewModule,
  UniTopBarModule,
  UniFooterModule,
  UniDirectivesModule,
  UniAppModule,
  UniTileModule,
  UniStepModule,
  UniToggleModule,
  UniDetailsModule,
  UniAudioModule,
  UniAccountsModule,
  UniAudienceModule,
  UniLogsModule,
  UniPluralModule,
  UniPagesModule,
  UniTreeModule,
  UniListModule,
  UniDropdownModule,
  UniTextModule,
  UniSeparatorModule,
  UniFilterBarModule,
  UniSortModule,
  UniPreviewModule,
  UniPriceModule,
  UniSegmentedTabsModule,
];

@NgModule({
  exports: [...modules]
})
export class UniCommonModule { }
