import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-buttons',
  templateUrl: './uni-buttons.component.html',
  styleUrls: ['./uni-buttons.component.scss'],
})
export class UniButtonsComponent {
  @Input() isFlexEnd = true;
  @Input() isFlexStart = false;
  @Input() isAlignCenter = false;

  @HostBinding('class') get getClasses(): string {
    return [
      this.isFlexEnd ? 'is-flex-end' : '',
      this.isFlexStart ? 'is-flex-start' : '',
      this.isAlignCenter ? 'is-align-center' : ''
    ].join(' ');
  }
}
