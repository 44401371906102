import { concat, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { getQueryData, QueryActions, QueryAttributes } from '../../../../store';
import { Timezone } from '../../shared';
import { UniTimezonesRepository } from '../../shared/uni-timezones.repository';
import * as TimezonesActions from './uni-timezones.actions';
import { GET_TIMEZONES_QUERY } from './uni-timezones.state';

@Injectable()
export class TimezonesEffects {

  constructor(
    private actions$: Actions,
    private uniTimezonesRepository: UniTimezonesRepository,
  ) { }

  @Effect() SetTimezones$ = this.actions$
    .pipe(
      ofType<TimezonesActions.SetTimezones>(TimezonesActions.SET_TIMEZONES),
      mergeMap(() => concat(
        of(new QueryActions.QueryInProgress(GET_TIMEZONES_QUERY)),
        this.uniTimezonesRepository
          .getTimezones()
          .pipe(
            map(({ data }) => getQueryData(data as QueryAttributes<Timezone>[])),
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_TIMEZONES_QUERY, data)),
              of(new TimezonesActions.SetTimezonesSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_TIMEZONES_QUERY, error)),
            )),
          ),
        ),
      ),
    );
}
