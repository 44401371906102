import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectSenderNames, selectSenderNamesQuery, selectSendersChoice } from '../store/uni-data.selectors';
import { UniDataState } from '../store/uni-data.state';
import * as UniDataActions from '../store/uni-data.actions';
import { isQueryInProgress$ } from '../../../store';

@Injectable({ providedIn: 'root' })
export class SenderNameFacade {
  senderNames$ = this.store.pipe(select(selectSenderNames));
  senderNamesQuery$ = this.store.pipe(select(selectSenderNamesQuery));
  sendersChoice$ = this.store.pipe(select(selectSendersChoice));

  loader$ = new BehaviorSubject(isQueryInProgress$([
    this.senderNamesQuery$
  ]));

  isLoading$ = this.loader$.pipe(mergeMap(isActive => isActive));

  constructor(private store: Store<UniDataState>) {}

  setSenderNames(params: Params = {}, isWithSmsServiceTrials = false, isAccountWithRestrictedTrialSid =  false): void {
    this.store.dispatch(new UniDataActions.SetSenderNames(
      params,
      isWithSmsServiceTrials,
      isAccountWithRestrictedTrialSid,
    ));
  }

  clearSenderNames(): void {
    this.store.dispatch(new UniDataActions.ClearSenderNames());
  }

  setSendersChoice(id: string = null): void {
    this.store.dispatch(new UniDataActions.SetSendersChoice(id));
  }
}
