import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CampaignDataSource } from '../../../uni-campaign/shared/uni-campaign.model';

import { DateUtils } from '../../../../utils';
import { UniTableDetail, UniTableDetailName, UniTableDetailType } from '../../shared/uni-table-details.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'uni-table-details-item',
  templateUrl: './uni-table-details-item.component.html',
  styleUrls: ['./uni-table-details-item.component.scss'],
})
export class UniTableDetailsItemComponent {
  @Input() item: UniTableDetail;
  @Input() id: string;
  @Input() control?: FormControl;
  @Input() prefix = '';

  uniDetailType = UniTableDetailType;
  uniDetailName = UniTableDetailName;

  get path(): string {
    return `requests/${this.id}/resource?path=${this.item.value}`;
  }

  get parsedValue(): string {
    const originalValue = String(this.item.value);
    if (!!originalValue && originalValue.toLowerCase() === CampaignDataSource.fileToSMS) {
      return this.translate.instant(`${this.prefix}${originalValue}`);
    }

    const value = DateUtils.display(originalValue);

    return value === 'Invalid date' || this.item.name !== this.uniDetailName.submittedAt
      ? this.item.value
      : value;
  }

  constructor(
    private translate: TranslateService,
  ) {}
}
