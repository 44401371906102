<div [ngClass]="{
  'uni-multiselect-list__content': true,
  'uni-multiselect-list--no-margin': noMargin,
  'uni-multiselect-list--numbers': numbers
}">
  <div
    *ngFor="let item of value"
    class="uni-multiselect-list__item"
    [ngClass]="{
      'uni-multiselect-list__item--alternative': versionKey && item[versionKey]
    }"
  >
    <span class="uni-multiselect-list__value">
      {{ getName(item) }}
    </span>
    <uni-icon
      class="uni-multiselect-list__close"
      name="times"
      (click)="onRemove(item)"
    ></uni-icon>
  </div>
</div>
<div
  *ngIf="isTotalItemsContent()"
  class="uni-multiselect-list__footer"
  [ngPlural]="getTotalItems()"
>
  {{ getTotalItems() }}&nbsp;
    <ng-template ngPluralCase="=1">
      {{ totalItemsKey[0] | translate }}
    </ng-template>
    <ng-template ngPluralCase="other">
      {{ totalItemsKey[1]  | translate }}
    </ng-template>
</div>
