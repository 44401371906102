import { ActivatedRoute, Data } from '@angular/router';

export const DEFAULT_TITLE = 'title.default';

export function getLastActivatedRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
  while (activatedRoute.firstChild) {
    activatedRoute = activatedRoute.firstChild;
  }

  return activatedRoute;
}

export function getTitleKey(routeData: Data): string {
  return routeData.title
    ? routeData.title
    : DEFAULT_TITLE;
}

export function isPrimary(routeData: Data): boolean {
  const { outlet } = routeData;
  return outlet === 'primary';
}
