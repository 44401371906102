import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-card-header',
  templateUrl: './uni-card-header.component.html',
  styleUrls: ['./uni-card-header.component.scss'],
})
export class UniCardHeaderComponent {

  @Input() background = 'default';

  @HostBinding('class') get getClasses(): string {
    return `uni-card--${this.background}`;
  }
}
