import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HIGHCHARTS_SERIES_COLUMN_COLORS } from '../highcharts/highcharts.colors';
import { HCToken } from '../highcharts/highcharts.token';
import { HighchartsInstance, HighchartsItem, HighchartsOptions } from '../highcharts/highcharts.types';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit, OnChanges {

  @Input() source!: [string,number][]
  @Input() xAxis!: string[]

  constructor( @Inject(HCToken) public hc: HighchartsItem) {}

  cInstance!: HighchartsInstance;

  chartOptions: HighchartsOptions =  {
    credits:{
      enabled: false,
    },

    chart:{
      plotShadow: false,
      type: 'bar',
      height: '200',
      animation: false
    },

    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },


    xAxis: {
        categories: [''],
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 0
    },
    yAxis: {
        labels: {},
        min: 0,
        title: {
          text:null
        },
        gridLineWidth: 0
    },
    plotOptions: {
      bar: {
          borderRadius: 3,
          dataLabels: {
              enabled: false
          },
          groupPadding: 0.1,
          
      },
      series: {
        //@ts-ignore
        pointWidth: 25
      }
   
  },
    colors: [...HIGHCHARTS_SERIES_COLUMN_COLORS],
    series: []
  }


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.xAxis && !changes.xAxis.firstChange && changes.source && !changes.source.firstChange){
      this.redrawChart()
    }
  }

  chartInit(chart: Highcharts.Chart){
    this.cInstance = chart;
    this.redrawChart()
  }

  redrawChart(){
    if(this.cInstance){
      while(this.cInstance.series.length){
        this.cInstance.series[0].remove()
      }

      const series =  [...this.source]

      series.forEach((serie: any, idx) => {
        this.cInstance.addSeries({
          type: 'bar',
          name: serie.name,
          id: serie.name,
          data: serie.data,
        
        })
      }, false)

      this.cInstance.setSize(null, series.length * 35 + 100)
      this.cInstance.redraw(true)
    }
  }



}


