<uni-icon
  *ngIf="isOpen"
  style="fas"
  name="sort-up"
  class="uni-icon-arrow__up"
></uni-icon>
<uni-icon
  *ngIf="!isOpen"
  style="fas"
  name="sort-down"
  class="uni-icon-arrow__down"
></uni-icon>
