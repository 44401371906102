<uni-modal
  [isActive]="isModalActive"
  (isActiveChange)="changeModalState()"
>
  <uni-section-header>
    <uni-text-caption>
      {{ 'campaign.new.recipients.modal.title' | translate }}
    </uni-text-caption>
  </uni-section-header>
  <uni-modal-content>
    {{ hasValidNumbers
      ? ('campaign.new.recipients.modal.description' | translate)
      : ('campaign.new.recipients.modal.descriptionAllInvalid' | translate)
    }}

    <ul
      class="campaign-recipients-modal__numbers"
      *ngIf="invalidNumbers && invalidNumbers.length"
    >
      <li
        class="campaign-recipients-modal__number"
        *ngFor="let number of invalidNumbers"
      >
        {{ number }}
      </li>
    </ul>
  </uni-modal-content>
  <uni-modal-footer *ngIf="hasValidNumbers">
    <uni-button [style]="'secondary'" (click)="changeModalState()">
      {{ 'action.cancel' | translate }}
    </uni-button>
    <uni-button (click)="onSubmitButton()">
      {{ 'action.submit' | translate }}
    </uni-button>
  </uni-modal-footer>
  <uni-modal-footer *ngIf="!hasValidNumbers" class="campaign-recipients-modal__footer">
    <uni-button (click)="changeModalState()">
      {{ 'action.confirm' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
