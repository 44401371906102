import { filter, map } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { isQueryInProgress } from '../../../../store';
import { AutoUnsubscribe } from '../../../../utils';
import { UniRolesFacade } from '../../shared/uni-roles.facade';
import { Subscription } from 'rxjs';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';

@Component({
  selector: 'uni-roles-select',
  templateUrl: './uni-roles-select.component.html',
})
export class UniRolesSelectComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() required = false;
  @Input() assignableByCurrentUser = false;

  isLoaderActive = false;
  canSeeImpersonator = false;
  isRolesWatch = false;
  roles = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private uniRolesFacade: UniRolesFacade,
    private readonly uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.setRoles();

    this.subs.add(
      this.checkFlags(),
      this.setLoadingStatus()
    );
  }

  setRoles() {
    const { userId } = this.activatedRoute.snapshot.params;
    this.uniRolesFacade.setRoles(userId || '', this.assignableByCurrentUser);
  }

  setLoadingStatus(): Subscription {
    return this.uniRolesFacade.$rolesQuery.subscribe(query => this.isLoaderActive = isQueryInProgress(query));
  }

  compare(o1, o2) {
    return o1 && o2 && o1.name === o2.name;
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags)
      )
      .subscribe(() => {
        this.canSeeImpersonator = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.admin_console_account_impersonation_2023_q_2);
        this.getRoles();

        if (!this.isRolesWatch) {
          this.subs.add(
            this.getRoles()
          );

          this.isRolesWatch = true;
        }
      });
  }

  getRoles(): Subscription {
    return this.uniRolesFacade.$roles
      .pipe(
        filter(data => !!data.length),
        map(data => data.filter(item => {
          return item.name.toLowerCase().includes('impersonator')
            && this.uniAuthFacade.isAdminLevel()
              ? this.canSeeImpersonator
              : true;
         })),
      ).subscribe((data) => this.roles = data);
  }
}
