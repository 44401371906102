import { v1 as uuid } from 'uuid';

import { NavMenu, Products } from './uni-nav.model';

export const activeMenuItemId = uuid();
export const activeMenuSubitemId = uuid();

export const storybookNavMenu: NavMenu = {
    groups: [
        {
            items: [
                {
                    id: uuid(),
                    iconName: 'edit',
                    labelKey: 'externalLink',
                    link: {
                        externalUrl: 'https://docs.unifonic.com',
                    },
                },
                {
                    id: uuid(),
                    iconName: 'sitemap',
                    labelKey: 'flowStudio',
                },
                {
                    id: activeMenuItemId,
                    iconName: 'bell',
                    labelKey: 'notice.notice',
                    link: {
                        product: Products.ulink,
                        route: '/storybook',
                    },
                    items: [
                        {
                            id: activeMenuSubitemId,
                            labelKey: 'notice.overview',
                            link: {
                                product: Products.ulink,
                                route: '/storybook/test',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'notice.configurations',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'notice.documentation',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                    ],
                },
                {
                    id: uuid(),
                    iconName: 'lock-alt',
                    labelKey: 'authenticate',
                    isHidden: true,
                    link: {
                        product: Products.ulink,
                        route: '',
                    },
                },
                {
                    id: uuid(),
                    iconName: 'robot',
                    labelKey: 'chatbot',
                    isHidden: true,
                    link: {
                        product: Products.ulink,
                        route: '',
                    },
                },
            ],
            groupExpandItem: {
                iconName: 'th',
                expandedLabelKey: 'lessProducts',
                collapsedLabelKey: 'allProducts',
            },
        },
        {
            items: [
                {
                    id: uuid(),
                    iconName: 'sliders-v',
                    labelKey: 'admin.admin',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'admin.user',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'admin.subaccounts',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'admin.requests',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                    ],
                },
                {
                    id: uuid(),
                    iconName: 'project-diagram',
                    labelKey: 'channels.channels',
                    items: [
                        {
                            id: uuid(),
                            labelKey: 'channels.sms',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.voice',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.whatsapp',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.webWidget',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                        {
                            id: uuid(),
                            labelKey: 'channels.instagram',
                            link: {
                                product: Products.ulink,
                                route: '',
                            },
                        },
                    ],
                },
            ],
        }
    ],
};
