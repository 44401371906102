import { Action } from '@ngrx/store';

import { UniRole } from '../shared/uni-roles.model';

export const SET_ROLES = '[Roles] SET_ROLES';
export const SET_ROLES_SUCCESS = '[Roles] SET_ROLES_SUCCESS';

export class SetRoles implements Action {
  readonly type = SET_ROLES;

  constructor(public userId?: string, public assignableByCurrentUser?: boolean) { }
}

export class SetRolesSuccess implements Action {
  readonly type = SET_ROLES_SUCCESS;

  constructor(public response: UniRole[]) { }
}

export type All =
  | SetRoles
  | SetRolesSuccess;
