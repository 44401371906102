<div class="uni-preview-mobile__layer--bottom">
  <div class="uni-preview-mobile__notch-container">
    <div class="uni-preview-mobile__notch-joint--left"></div>
    <div class="uni-preview-mobile__notch-container--bottom"></div>
    <div class="uni-preview-mobile__notch-container--top"></div>
    <div class="uni-preview-mobile__notch-joint--right"></div>
  </div>

  <div class="uni-preview-mobile__layer--top">
    <div class="uni-preview-mobile__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
