<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
  [maxWitdth]="maxwidth"
>
  <form [formGroup]="form" (submit)="onSubmit()">
    <uni-section-header>
      <uni-text-caption>
        {{ title | translate }}
      </uni-text-caption>
    </uni-section-header>
    <div class="uni-2fa__content">
      <uni-section [isFirst]="true">
        <span [innerHTML]="description | translate"></span>
      </uni-section>
      <uni-modal-content>
        <uni-label [required]="true">
          {{ (isSmsAuth ? '2fa.smsCode' : '2fa.emailCode') | translate }}
          <uni-link type="secondary" (click)="sendCode()" uniLabelSuffix>
            {{ '2fa.resendCode' | translate }}
          </uni-link>
        </uni-label>
        <uni-form-field>
          <input
            type="text"
            formControlName="code"
            [required]="true"
            autocomplete="off"
            uniInput
          >
        </uni-form-field>

        <uni-link
          *ngIf="!channel"
          type="secondary"
          (click)="switchAuth()"
        >
          {{ (isSmsAuth ? '2fa.switchToEmailAuth' : '2fa.switchToSmsAuth') | translate }}
        </uni-link>
      </uni-modal-content>
      <uni-loader
        *ngIf="isLoading"
        [isAbsolute]="true"
      ></uni-loader>
      <uni-modal-footer [isCentered]="hideCancel">
        <uni-button
          *ngIf="!hideCancel"
          [style]="'secondary'"
          (click)="hideModal()"
        >
          {{ '2fa.cancel' | translate }}
        </uni-button>
        <uni-button
          [disabled]="form.invalid"
          [fit]="hideCancel"
          type="submit"
        >
          {{ button | translate }}
        </uni-button>
      </uni-modal-footer>
    </div>


  </form>
</uni-modal>
