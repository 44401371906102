import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-list-content-item',
  templateUrl: './uni-list-content-item.component.html',
  styleUrls: ['./uni-list-content-item.component.scss'],
})
export class UniListContentItemComponent {
  @Input() prefix = '';
}
