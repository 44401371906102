<ng-container *ngIf="{ isSubitemsGroupExpanded: isSubitemsGroupExpanded$ | async } as data">
    <uni-nav-new-item [item]="item" [isExpanded]="data.isSubitemsGroupExpanded"></uni-nav-new-item>

    <ng-container *ngIf="data.isSubitemsGroupExpanded">
        <uni-nav-new-subitem
          *ngFor="let subitem of item.items"
          [subitem]="subitem"
        >
        </uni-nav-new-subitem>
        <div *ngIf="item?.items?.length > 0" class="uni-nav-item-group__divider"></div>
    </ng-container>
</ng-container>
