import { Component } from '@angular/core';

import { ProductsService } from '../../../../shared/products/products.service';
import { UniTopBarBalanceService } from '../../shared/uni-top-bar-balance.service';

@Component({
  selector: 'uni-top-bar-impersonation',
  templateUrl: './uni-top-bar-impersonation.component.html',
  styleUrls: ['./uni-top-bar-impersonation.component.scss']
})
export class UniTopBarImpersonationComponent {

  constructor(
    private productsService: ProductsService,
    private uniTopBarBalanceService: UniTopBarBalanceService,
  ) {}

  onStop(): void {
    this.uniTopBarBalanceService.killInterval();
    this.productsService.stopImpersonation();
  }
}
