<ng-container *ngIf="vm$ | async as vm">

  <form #form="ngForm">
  <uni-box>
    <uni-box-content>

      <!-- <app-create-form-foreword></app-create-form-foreword> -->
      <div class="row">
        <div class="col-12">
          <app-create-form-name required [ngModel]="vm.formName" name="name"></app-create-form-name>
        </div>
        <div #productChannel="ngModelGroup" ngModelGroup="combination" appChannelProductCombinationValidator>
          <div class="col-12">
            <app-create-form-channels required [ngModel]="vm.formChannels" (ngModelChange)="changeChannel($event)" name="channels"></app-create-form-channels>
          </div>
          <div class="col-12">
            <app-create-form-products required [ngModel]="vm.formProducts" (ngModelChange)="changeProducts($event)" name="products"></app-create-form-products>
          </div>
        </div>
        <div class="col-12" *ngIf="productChannel.hasError('invalidProductChannnelCombination')" >
          <uni-form-field-error>
            {{'ci.common.notAllowedSelectionMessage' | translate}}
          </uni-form-field-error>
        </div>
        <div class="col-12">
          <app-create-form-templates required [ngModel]="vm.formTemplate" (ngModelChange)="changeTemplate($event)" name="template" [templates]="vm.availableTemplates"></app-create-form-templates>
        </div>
        <div class="col-6 col-sm-12" *ngIf="vm.dateRangeVisibility">
          <app-create-form-date-range required [ngModel]="vm.formDate" name="date"></app-create-form-date-range>
        </div>
        <div class="col-6 col-sm-12">
          <app-create-form-account-id required [ngModel]="vm.formAccountId" (ngModelChange)="selectAccountId($event)" name="accountId"></app-create-form-account-id>
        </div>
        <div class="col-6 col-sm-12" *ngIf="vm.campaignExtensionVisibility">
          <app-create-form-campaign-id required [ngModel]="vm.formCampaignId" name="campaignId" [accountId]="vm.userInfo.accountId" (selectCampaign)="selectCampaign($event)"></app-create-form-campaign-id>
        </div>
        <ng-container *ngIf="vm.textSearchVisibility">
            <div class="col-6 col-sm-12" *ngIf="vm.dateRangeVisibility">
              <app-create-form-keywords [ngModel]="vm.formKeywords" name="keywords"></app-create-form-keywords>
            </div>
            <div class="col-6 col-sm-12">
              <app-create-form-recipients [ngModel]="vm.formRecipients" name="recipients"></app-create-form-recipients>
            </div>
        </ng-container>

        <ng-container *ngIf="vm.scheduledReportsVisibility">

          <div class="col-12">
            <app-create-form-recurring-type [ngModel]="vm.formRecurringType" name="recurringType" (ngModelChange)="changeRecurringType($event)"></app-create-form-recurring-type>
          </div>

          <ng-container *ngIf="vm.formRecurringType === 1">
            <div class="col-6">
              <app-create-form-date-range-selector-2 [ngModel]="vm.formReportOneTimeDateRange" (ngModelChange)="changeOneTimeDateRange($event)" name="oneTimeDateRange"></app-create-form-date-range-selector-2>
            </div>
            <ng-container *ngIf="vm.formReportOneTimeDateRange === '_custom'">
              <div class="col-3">
                <app-create-form-date-range-selector-from [ngModel]="vm.formReportOneTimeDateRangeFrom" (ngModelChange)="changeOneTimeDateRangeFrom($event)" name="oneTimeDateRangeFrom"></app-create-form-date-range-selector-from>
              </div>

              <div class="col-3">
                <app-create-form-date-range-selector-to [ngModel]="vm.formReportOneTimeDateRangeTo" (ngModelChange)="changeOneTimeDateRangeTo($event)" name="oneTimeDateRangeTo"></app-create-form-date-range-selector-to>

              </div>
            </ng-container>

            <div class="col-12">
              <app-create-form-schedule-toggle [ngModel]="vm.formScheduledReport" (ngModelChange)="changeScheduledReport($event)" name="sheduledReport"></app-create-form-schedule-toggle>
            </div>

            <ng-container *ngIf="vm.formScheduledReport">
              <div class="col-4">
                <app-create-form-delivery-date [ngModel]="vm.formDeliveryDate" (ngModelChange)="changeDeliveryDate($event)" name="deliveryDate"></app-create-form-delivery-date>
              </div>
              <div class="col-4">
                <app-create-form-delivery-time [ngModel]="vm.formDeliveryTime" (ngModelChange)="changeDeliveryTime($event)" name="deliveryTime"></app-create-form-delivery-time>
              </div>
            </ng-container>


          </ng-container>

          <ng-container *ngIf="vm.formRecurringType === 2">

            <div class="col-6">
              <app-create-form-date-range-recurring name="formRecurringDateRange" [ngModel]="vm.formRecurringDateRange" (ngModelChange)="changeRecurringDateRange($event)"></app-create-form-date-range-recurring>
            </div>

            <div class="col-6">
              <ng-container *ngIf="vm.formRecurringDateRange === '_previousNDays'">
                <app-create-form-date-range-recurring-n-days [ngModel] name="previousNDays"></app-create-form-date-range-recurring-n-days>
              </ng-container>
            </div>

            <div class="col-3">
              <app-create-form-frequency [ngModel]="vm.formReportFrequency" (ngModelChange)="changeFrequency($event)" name="frequency"></app-create-form-frequency>
            </div>

            <ng-container *ngIf="vm.formReportFrequency === 'YEARLY'">
              <div class="col-3">
                <app-create-form-frequency-month [ngModel]="vm.formReportFrequencyMonth" (ngModelChange)="changeFrequencyMonth($event)" name="frequencyMonth"></app-create-form-frequency-month>
              </div>
            </ng-container>

            <ng-container *ngIf="vm.formReportFrequency === 'WEEKLY'">
              <div class="col-3">
                <app-create-form-frequency-week-day [ngModel]="vm.formReportFrequencyWeekDay" (ngModelChange)="changeFrequencyWeekDay($event)" name="frequencyWeekDay"></app-create-form-frequency-week-day>
              </div>
            </ng-container>

            <ng-container
            *ngIf="vm.formReportFrequency === 'YEARLY'
            || vm.formReportFrequency === 'MONTHLY'
            ">
            <div class="col-3">
              <app-create-form-frequency-month-day [ngModel]="vm.formReportFrequencyMonthDay" (ngModelChange)="changeFrequencyMonthDay($event)" name="frequencyMonthDay"></app-create-form-frequency-month-day>
            </div>
          </ng-container>

            <ng-container *ngIf="
              vm.formReportFrequency === 'YEARLY'
              || vm.formReportFrequency === 'MONTHLY'
              || vm.formReportFrequency === 'WEEKLY'
              || vm.formReportFrequency === 'DAILY'
              "
            >
              <div class="col-3">
                <app-create-form-frequency-time [ngModel]="vm.formReportFrequencyTime" (ngModelChange)="changeFrequencyTime($event)" name="frequencyTime"></app-create-form-frequency-time>
              </div>
            </ng-container>

          </ng-container>




        </ng-container>

        <div class="col-12">
          <app-create-form-notification [ngModel]="vm.formNotification" name="notification"></app-create-form-notification>
        </div>

      </div>
  
    </uni-box-content>
    <uni-box-footer>
      <uni-buttons>
        <uni-button
            type="submit"
            [style]="'primary'"
            [isMargin]="true"
            (click)="generateReport(form)"
            [disabled]="form.invalid"
        >
            <uni-icon
                [isRightSpace]="true"
                name="spinner fa-spin"
                *ngIf="vm.loading"
            ></uni-icon>
            {{'ci.reports.form.generateReport' | translate}}
        </uni-button>
    </uni-buttons>
    </uni-box-footer>

  </uni-box>
</form>
</ng-container>
