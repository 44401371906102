import { Component, HostBinding, Input } from '@angular/core';
import { UniPreviewFileType } from './uni-preview-file.model';

@Component({
  selector: 'uni-preview-file',
  styleUrls: ['./uni-preview-file.component.scss'],
  templateUrl: './uni-preview-file.component.html',
})
export class UniPreviewFileComponent {
  @Input() class = '';
  @Input() size = 'small';
  @Input() url = '';
  @Input() type = UniPreviewFileType.image;
  uniPreviewFileType = UniPreviewFileType;

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-preview-file--${this.size}`;
  }

  getIcon() {
    switch (this.type) {
      case UniPreviewFileType.document: {
        return 'file';
      }
      case UniPreviewFileType.video: {
        return 'film';
      }
      default: {
        return 'image-polaroid';
      }
    }
  }
}
