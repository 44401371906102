<div class="industry-autocomplete" [formGroup]="form">
  <uni-label [required]="required">
    {{ 'label.industry' | translate }}
  </uni-label>
  <mat-form-field formGroupName="industry">
    <uni-form-field [margin]="margin">
      <input
        type="text"
        [placeholder]="isLoaderActive ? ('label.loading' | translate) : ('label.selectIndustry' | translate)"
        formControlName="name"
        [matAutocomplete]="auto"
        #autocompleteTrigger
        matInput
        uniInput
      >
      <div uniSuffix>
        <uni-icon
          *ngIf="industryNameControl.value && industryNameControl.value.length"
          class="industry-autocomplete__clear-icon"
          name="times"
          (click)="clearAutocomplete()">
        </uni-icon>
        <uni-icon
          *ngIf="!(industryNameControl.value && industryNameControl.value.length)"
          [name]="isPanelOpen ? 'chevron-up' : 'chevron-down'"
        ></uni-icon>
      </div>
    </uni-form-field>
    <mat-autocomplete
      (opened)="isPanelOpen = true"
      (closed)="onPanelClose()"
      #auto="matAutocomplete"
    >
      <mat-option
        *ngFor="let industry of filteredIndustries | async"
        [value]="industry.name"
      >
        {{ industry.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
