import { isEmpty } from 'lodash';
import { AfterContentInit, Component, ContentChildren, forwardRef, HostBinding, Input, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { UniSwitcherItemComponent } from '../uni-switcher-item/uni-switcher-item.component';

@Component({
  selector: 'uni-switcher',
  templateUrl: './uni-switcher.component.html',
  styleUrls: ['./uni-switcher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniSwitcherComponent),
      multi: true
    }
  ]
})
export class UniSwitcherComponent implements ControlValueAccessor, AfterContentInit {
  @ContentChildren(UniSwitcherItemComponent) switchers: QueryList<UniSwitcherItemComponent>;
  @Input() isManual = false;
  @HostBinding('class.is-multi') @Input() isMulti = false;
  @HostBinding('class.is-disabled') @Input() disabled = false;
  @HostBinding('class.is-fit') @Input() isFit = false;

  value = '';
  propagateChange: any = () => {};

  get switcherValue() {
    return this.switcherValue;
  }

  set switcherValue(value: string) {
    this.propagateChange(value);
  }

  ngAfterContentInit() {
    if (this.isManual) {
      return;
    }

    this.setDefaultValue();
    this.switchers.map(switcher => switcher.changedId.subscribe(value => this.setSwitcherState(value)));
  }

  setDefaultValue() {
    setTimeout(() =>  this.switchers.map(switcher => {
      switcher.setStatus(switcher.value === this.value);
      switcher.setDisabled(this.disabled);
    }), 0);
  }

  setSwitcherState(value: string): void {
    if (this.disabled) {
      return;
    }

    this.writeValue(value);
  }

  writeValue(value = ''): void {
    if (!value && !isEmpty(this.switchers)) {
      this.switchers.map(switcher => switcher.setStatus(false));
    }

    if (!isEmpty(this.switchers)) {
      this.switchers.map(switcher => switcher.setStatus(switcher.value === value));
    }

    this.switcherValue = value;
    this.value = value;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}
}
