import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"
import { UnishieldFetchStore } from "src/app/shared/data-access/unishield/unishield.store"


type State = {
}

const initialState: State = {
}

@Injectable()
export class CountriesTableStore extends ComponentStore<State>{

    constructor(
        private unishieldFetchStore: UnishieldFetchStore
      ){
        super(initialState)
      }
  
      //selectors
      readonly loading$ = this.unishieldFetchStore.loading$

      readonly countries$ = this.select(
        this.unishieldFetchStore.entities$,
        (entity) => {
          const countries = entity?.topCountries || []
          
          countries.sort((a,b) => b.messagesBlocked - a.messagesBlocked)

          return countries
        }
      )

      readonly countriesGroup1$ = this.select(
        this.countries$,
        (countries) => countries.slice(0, Math.ceil(countries.length/2) )
      )

      readonly countriesGroup2$ = this.select(
        this.countries$,
        (countries) => countries.slice(Math.ceil(countries.length/2), countries.length)
      )
  
      readonly vm$ = this.select(
        this.unishieldFetchStore.entities$,
        this.countriesGroup1$,
        this.countriesGroup2$,
        this.loading$,
        (entity, countriesGroup1, countriesGroup2, loading) => {
          return {
            entity,
            loading,
            countriesGroup1,
            countriesGroup2
          }
        }
      )
}
