<uni-icon
  name="user-circle"
  [isRightSpace]="true"
  [isInherit]="true"
></uni-icon>
<div>
  <div class="uni-top-bar-avatar__name">
    <div>{{ firstName }} {{ lastName }}</div>
  </div>
  <div class="uni-top-bar-avatar__account">
    <div>{{ user?.account?.name }}</div>
  </div>
</div>
