import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-step',
  templateUrl: './uni-step.component.html',
  styleUrls: ['./uni-step.component.scss'],
})
export class UniStepComponent {
  @Input() step: number;
  @HostBinding('class.is-active') @Input() isActive = false;
  @HostBinding('class.is-disabled') @Input() isDisabled = false;
}
