import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UniTextColor } from '../../../uni-text';
import { UniLinkSize } from '../../../uni-layout/components/uni-link/uni-link.model';
import { Package, PackagesFacade, PackageStatus, ServiceType } from '../../../../shared/uni-data/';
import { ProductKey } from '../../../../shared/products/products.model';
import { ProductsService } from '../../../../shared/products/products.service';

@Component({
  selector: 'uni-top-bar-packages-list',
  templateUrl: './uni-top-bar-packages-list.component.html',
  styleUrls: ['./uni-top-bar-packages-list.component.scss']
})
export class UniTopBarPackagesListComponent {
  packages$ = this.uniPackagesFacade.packages$;
  color = UniTextColor;
  linkSize = UniLinkSize.small;

  constructor(
    private uniPackagesFacade: PackagesFacade,
    private translate: TranslateService,
    protected productsService: ProductsService,
  ) {}

  mapPackages(packages: Package[]) {
    packages = packages.filter(item => item.status === PackageStatus.active);

    const mappedPackages = packages.reduce((accumulator, currentValue) => {
      const matchingPackage = accumulator.findIndex((item) => item.serviceType === currentValue.serviceType);

      const translatedType = this.translate.instant('packages.serviceType.' + currentValue.serviceType);

      if (matchingPackage < 0) {
        accumulator.push({
          ...currentValue,
          translatedType
        });
      } else {
        const oldPackage = accumulator[matchingPackage];
        accumulator[matchingPackage] = {
          ...oldPackage,
          totalUnits: oldPackage.totalUnits + currentValue.totalUnits,
          remainingUnits: oldPackage.remainingUnits + currentValue.remainingUnits,
          usedUnits: oldPackage.usedUnits + currentValue.usedUnits,
          items: oldPackage.items ? oldPackage.items + 1 : 2,
        };
      }

      return accumulator;
    }, []);

    return this.sortPackages(mappedPackages);
  }

  sortPackages(packages: any[]) {
    const sortOrder = [
      ServiceType.sms,
      ServiceType.whatsapp,
      ServiceType.voice,
      ServiceType.push
    ] as string[];
    const ordering = {};

    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
      packages.sort(
        (a, b) => {

          if (
            !sortOrder.includes(a.serviceType)
            && sortOrder.includes(b.serviceType)
          ) {
            return 1;
          }

          if (
            sortOrder.includes(a.serviceType)
            && !sortOrder.includes(b.serviceType)
          ) {
            return -1;
          }

          if (
            !sortOrder.includes(a.serviceType)
            && !sortOrder.includes(b.serviceType)
          ) {
            return a.translatedType
              .toLowerCase()
              .localeCompare(
                b.translatedType.toLowerCase()
              );
          }

          return ordering[a.serviceType] - ordering[b.serviceType];
        }
      );
    }

    return packages;
  }

  onSeePackages() {
    const packagesRoute = '/payment/packages';
    this.productsService.navigate(ProductKey.UC, packagesRoute);
  }

}
