import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { tap } from "rxjs/operators";
import { UniAuthFacade } from "unifonic-spa-common/src";


type State = {
  date: string,
  accountId: string
}

const initialState: State = {
  date: 'last7d',
  accountId: ''
}

@Injectable()
export class FiltersStore extends ComponentStore<State>{
    constructor(
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }


    readonly date$ = this.select(state => state.date)
    readonly accountId$ = this.select(state => state.accountId)

    //selectors
    readonly params$ = this.select(
        this.date$,
        this.accountId$,
        (date, accountId) => ({
            date,
            timezone: this.authFacade.userMe?.user?.timezone,
            accountId: accountId || this.authFacade?.user?.account?.id || ""
        })
    )

}

