import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as uniRolesActions from '../store/uni-roles.actions';
import { selectRoles, selectRolesQuery } from '../store/uni-roles.selectors';
import { UniRolesState } from '../store/uni-roles.state';

@Injectable()
export class UniRolesFacade {
  $roles = this.store.pipe(select(selectRoles));
  $rolesQuery = this.store.pipe(select(selectRolesQuery));

  constructor(private store: Store<UniRolesState>) {}

  setRoles(userId?: string, assignableByCurrentUser?: boolean): void {
    this.store.dispatch(new uniRolesActions.SetRoles(userId, assignableByCurrentUser));
  }
}
