import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UniUserFacade, UniUserRepository } from './shared';
import { UniUserEffects, uniUserReducer } from './store';

@NgModule({
  imports: [
    StoreModule.forFeature('uniUser', uniUserReducer),
    EffectsModule.forFeature([UniUserEffects])
  ],
  providers: [
    UniUserRepository,
    UniUserFacade,
  ],
})
export class UniUserModule { }
