import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniPriceComponent } from './components/uni-price/uni-price.component';
import { UniPriceService } from './shared/uni-price.service';

@NgModule({
  imports: [
    TranslateModule,
  ],
  exports: [UniPriceComponent],
  declarations: [UniPriceComponent],
  providers: [
    UniPriceService,
  ],
})
export class UniPriceModule {
}
