import { Component, Input } from '@angular/core';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { UniTextWeight } from '../../../uni-text';

@Component({
  selector: 'uni-footer',
  templateUrl: './uni-footer.component.html',
  styleUrls: ['./uni-footer.component.scss'],
})
export class UniFooterComponent {
  @Input() isSwitcherVisible = false;
  user = this.uniAuthFacade.userMe;
  uniTextWeight = UniTextWeight;

  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {}

  canSetLanguage(): boolean {
    return this.isSwitcherVisible
      || (!this.uniAuthFacade.isAdminLevel()
        && this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.languages_Q3_2020)
      );
  }
}
