import { get } from 'lodash';

import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'uni-table-filter-search',
  templateUrl: './uni-table-filter-search.component.html',
  styleUrls: ['./uni-table-filter-search.component.scss']
})
export class UniTableFilterSearchComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() thead: HTMLElement;
  @Input() tableLeft: number;
  @Input() heading: string;
  @Input() isOpened = false;
  @Input() isQueryParams = true;
  @Input() validators = [];

  @Output() apply = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() open = new EventEmitter();

  search: ElementRef;

  private _subscription: Subscription;

  @HostListener('click') onOpen() {
    this.isOpened = true;
    this.open.next();
  }

  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initForm();
    this.resetFilter();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  getDefaultValue(): string {
    return this.activatedRoute.snapshot.queryParams[this.controlName] || '';
  }

  initForm(): void {
    if (this.form) {
      return;
    }

    this.form = new FormGroup({
      [this.controlName]: new FormControl(this.getDefaultValue(), this.validators),
    });
  }

  isValidationError(): boolean {
    return !!this.control.invalid;
  }

  onClear(): void {
    this.control.reset();
    this.onApply();
  }

  onApply(): void {
    if (this.isValidationError()) {
      this.control.setValue('');
      this.isOpened = false;
      return this.discard.next();
    }

    this.apply.next();
    this.isOpened = false;
    this.setQueryParams();
  }

  resetFilter() {
    this._subscription = this.activatedRoute.queryParams.subscribe(params => {
      const paramValue = get(params, this.controlName);

      if (String(paramValue) !== String(this.control.value)) {
        this.onClear();
      }
    });
  }

  setQueryParams(): void {
    if (!this.isQueryParams) {
      return;
    }

    const paramValue = this.activatedRoute.snapshot.queryParams[this.controlName];
    const controlValue = this.control.value;

    if (paramValue === controlValue || (!paramValue && !controlValue)) {
      return;
    }

    this.router.navigate([], {
      queryParams: {
        page: 1,
        ...this.form.value,
      },
      queryParamsHandling: 'merge',
    });
  }

  onDiscard(): void {
    const defaultValue = this.getDefaultValue();

    this.isOpened = false;
    this.control.setValue(defaultValue);
    this.discard.next();
  }
}
