import {
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { HttpQueryFacade } from '../../../../store/http-query/store/http-query.facade';
import { ActivatedRoute } from '@angular/router';
import { iif, merge } from 'rxjs';
import { UniLanguageService } from '../../../uni-language';
import { RoutingData } from './uni-view.model';

@Component({
  selector: 'uni-view',
  templateUrl: './uni-view.component.html',
  styleUrls: ['./uni-view.component.scss'],
})
export class UniViewComponent extends AutoUnsubscribe implements OnInit {
  isLoaderActive = false;
  isQueryWatched = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private httpQueryFacade: HttpQueryFacade,
    private activatedRoute: ActivatedRoute,
    private uniLanguageService: UniLanguageService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.activatedRoute.data.subscribe(data => !!data && this.isQueryInProgress(data as RoutingData))
    );
  }

  isQueryInProgress(data: RoutingData) {
    if (this.isQueryWatched) {
      return;
    }

    // @TODO: Replace 'key' with 'keys' in routing in the future and update this code

    const keys = [
      ...!!data.keys ? data.keys.map(item => this.httpQueryFacade.isInProgress$(item)) : [],
      !!data.key ? this.httpQueryFacade.isInProgress$(data.key) : null
    ];

    this.subs.add(
      merge(
        this.httpQueryFacade.isInProgress$(this.uniLanguageService.LANGUAGE_KEY),
        iif(() => keys.length > 0, ...keys),
      )
      .subscribe(isActive => {
        this.isLoaderActive = isActive;
        this.isQueryWatched = true;
        this.cdr.detectChanges();
      })
    );
  }
}
