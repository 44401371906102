import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UniDropdownComponent } from './components/uni-dropdown/uni-dropdown.component';
import { UniDropdownItemComponent } from './components/uni-dropdown-item/uni-dropdown-item.component';

const components = [
  UniDropdownComponent,
  UniDropdownItemComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniDropdownModule { }
