import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-table-top-bar-item',
  templateUrl: './uni-table-top-bar-item.component.html',
  styleUrls: ['./uni-table-top-bar-item.component.scss']
})
export class UniTableTopBarItemComponent {
  @HostBinding('class.margin') @Input() margin = true;
}
