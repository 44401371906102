import { Action } from '@ngrx/store';
import { Params } from '@angular/router';
import { UniGroup, UniDynamicGroup } from '../shared/uni-audience.model';
import { HttpHeaders } from '@angular/common/http';

export const SET_UNI_GROUPS = '[UniGroups] SET_UNI_GROUPS';
export const SET_UNI_GROUPS_SUCCESS = '[UniGroups] SET_UNI_GROUPS_SUCCESS';
export const CLEAR_UNI_GROUPS = '[UniGroups] CLEAR_UNI_GROUPS';

export const SET_UNI_GROUP = '[UniGroups] SET_UNI_GROUP';
export const SET_UNI_GROUP_SUCCESS = '[UniGroups] SET_UNI_GROUP_SUCCESS';
export const CLEAR_UNI_GROUP = '[UniGroups] CLEAR_UNI_GROUP';

export const DELETE_UNI_GROUP = '[UniGroups] DELETE_UNI_GROUP';
export const DELETE_UNI_GROUP_SUCCESS = '[UniGroups] DELETE_UNI_GROUP_SUCCESS';
export const DELETE_UNI_GROUP_ERROR = '[UniGroups] DELETE_UNI_GROUP_ERROR';

export const SET_UNI_DYNAMIC_GROUPS = '[UniGroups] SET_UNI_DYNAMIC_GROUPS';
export const SET_UNI_DYNAMIC_GROUPS_SUCCESS = '[UniGroups] SET_UNI_DYNAMIC_GROUPS_SUCCESS';
export const CLEAR_UNI_DYNAMIC_GROUPS = '[UniGroups] CLEAR_UNI_DYNAMIC_GROUPS';

export const SET_UNI_DYNAMIC_GROUP = '[UniGroups] SET_UNI_DYNAMIC_GROUP';
export const SET_UNI_DYNAMIC_GROUP_SUCCESS = '[UniGroups] SET_UNI_DYNAMIC_GROUP_SUCCESS';
export const CLEAR_UNI_DYNAMIC_GROUP = '[UniGroups] CLEAR_UNI_DYNAMIC_GROUP';

export const DELETE_UNI_DYNAMIC_GROUP = '[UniGroups] DELETE_UNI_DYNAMIC_GROUP';
export const DELETE_UNI_DYNAMIC_GROUP_SUCCESS = '[UniGroups] DELETE_UNI_DYNAMIC_GROUP_SUCCESS';
export const DELETE_UNI_DYNAMIC_GROUP_ERROR = '[UniGroups] DELETE_UNI_DYNAMIC_GROUP_ERROR';

export class SetUniGroup implements Action {
  readonly type = SET_UNI_GROUP;

  constructor(public id: string) { }
}

export class SetUniGroupSuccess implements Action {
  readonly type = SET_UNI_GROUP_SUCCESS;

  constructor(
    public response: UniGroup,
  ) { }
}

export class SetUniGroups implements Action {
  readonly type = SET_UNI_GROUPS;

  constructor(public params: Params, public page?: string) { }
}

export class SetUniGroupsSuccess implements Action {
  readonly type = SET_UNI_GROUPS_SUCCESS;

  constructor(
    public response: UniGroup[],
    public headers: HttpHeaders,
  ) { }
}

export class ClearUniGroups implements Action {
  readonly type = CLEAR_UNI_GROUPS;

  constructor() { }
}

export class ClearUniGroup implements Action {
  readonly type = CLEAR_UNI_GROUP;

  constructor() { }
}

export class DeleteUniGroup implements Action {
  readonly type = DELETE_UNI_GROUP;

  constructor(public ids: string[]) { }
}

export class DeleteUniGroupSuccess implements Action {
  readonly type = DELETE_UNI_GROUP_SUCCESS;

  constructor(public ids: string[]) { }
}

export class DeleteUniGroupError implements Action {
  readonly type = DELETE_UNI_GROUP_ERROR;

  constructor() { }
}

export class SetUniDynamicGroup implements Action {
  readonly type = SET_UNI_DYNAMIC_GROUP;

  constructor(public id: string) { }
}

export class SetUniDynamicGroupSuccess implements Action {
  readonly type = SET_UNI_DYNAMIC_GROUP_SUCCESS;

  constructor(
    public response: UniDynamicGroup,
  ) { }
}

export class SetUniDynamicGroups implements Action {
  readonly type = SET_UNI_DYNAMIC_GROUPS;

  constructor(public params: Params, public page?: string) { }
}

export class SetUniDynamicGroupsSuccess implements Action {
  readonly type = SET_UNI_DYNAMIC_GROUPS_SUCCESS;

  constructor(
    public response: UniDynamicGroup[],
    public headers: HttpHeaders,
  ) { }
}

export class ClearUniDynamicGroups implements Action {
  readonly type = CLEAR_UNI_DYNAMIC_GROUPS;

  constructor() { }
}

export class ClearUniDynamicGroup implements Action {
  readonly type = CLEAR_UNI_DYNAMIC_GROUP;

  constructor() { }
}

export class DeleteUniDynamicGroup implements Action {
  readonly type = DELETE_UNI_DYNAMIC_GROUP;

  constructor(public ids: string[]) { }
}

export class DeleteUniDynamicGroupSuccess implements Action {
  readonly type = DELETE_UNI_DYNAMIC_GROUP_SUCCESS;

  constructor(public ids: string[]) { }
}

export class DeleteUniDynamicGroupError implements Action {
  readonly type = DELETE_UNI_DYNAMIC_GROUP_ERROR;

  constructor() { }
}

export type All =
  | SetUniGroup
  | SetUniGroupSuccess
  | SetUniGroups
  | SetUniGroupsSuccess
  | ClearUniGroups
  | ClearUniGroup
  | DeleteUniGroup
  | DeleteUniGroupSuccess
  | DeleteUniGroupError
  | SetUniDynamicGroup
  | SetUniDynamicGroupSuccess
  | SetUniDynamicGroups
  | SetUniDynamicGroupsSuccess
  | ClearUniDynamicGroups
  | ClearUniDynamicGroup
  | DeleteUniDynamicGroup
  | DeleteUniDynamicGroupSuccess
  | DeleteUniDynamicGroupError;
