import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { UniCardComponent } from './components/uni-card/uni-card.component';
import { UniCardHeaderComponent } from './components/uni-card-header/uni-card-header.component';
import { UniCardHeaderIllustrationComponent } from './components/uni-card-header-illustration/uni-card-header-illustration.component';
import { UniCardHeaderTitleComponent } from './components/uni-card-header-title/uni-card-header-title.component';
import { UniCardDescriptionComponent } from './components/uni-card-description/uni-card-description.component';
import { UniCardHeaderItemComponent } from './components/uni-card-header-item/uni-card-header-item.component';
import { UniCardsComponent } from './components/uni-cards/uni-cards.component';


const components = [
  UniCardsComponent,
  UniCardComponent,
  UniCardHeaderComponent,
  UniCardHeaderItemComponent,
  UniCardHeaderIllustrationComponent,
  UniCardHeaderTitleComponent,
  UniCardDescriptionComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniCardModule { }
