import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-icon-action',
  templateUrl: './uni-icon-action.component.html',
})
export class UniIconActionComponent {
  @Input() name = 'angle-right';
  @Input() isDropdown = false;
  @Input() isActive = false;

  get iconName(): string {
    if (!this.isDropdown) {
      return this.name;
    }

    return this.isActive
      ? 'angle-up'
      : 'angle-down';
  }
}
