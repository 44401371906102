import { Component, HostBinding, Input } from '@angular/core';
import { UniSeparatorOrientation, UniSeparatorSpace } from './uni-separator.model';

@Component({
  selector: 'uni-separator',
  templateUrl: './uni-separator.component.html',
  styleUrls: ['./uni-separator.component.scss'],
})
export class UniSeparatorComponent {
  @Input() orientation: UniSeparatorOrientation = 'horizontal';
  @Input() class = '';
  @Input() space: UniSeparatorSpace = '';
  @Input() icon = '';
  @Input() text = '';
  @Input() isButton = false;

  @HostBinding('class') get getClasses(): string {
    const isContent = !!this.text ? 'uni-separator-content' : '';
    const isButton = this.isButton ? 'uni-separator-button' : '';
    return `${this.class} uni-separator--${this.orientation} is-space--${this.space} ${isContent} ${isButton}`;
  }
  @HostBinding('class.is-space') @Input() isSpace = true;
}
