<uni-form-container [formGroup]="contentForm">
  <uni-form-field
    [formGroup]="recipientsForm"
    [margin]="true"
  >
    <uni-multiselect-list
      *ngIf="phoneNumbersControl"
      [isTotalItems]="false"
      [formControl]="phoneNumbersControl"
      [noMargin]="true"
      [numbers]="true"
    ></uni-multiselect-list>

    <uni-multiselect-list
      *ngIf="groupsControl && isShowMultiSelect"
      [isTotalItems]="false"
      [formControl]="groupsControl"
      [noMargin]="true"
      nameKey="name"
      versionKey="filters"
    ></uni-multiselect-list>
    <uni-form-field-error *ngIf="groupsControl?.value?.length > 5">
      {{ 'validation.maxGroups' | translate }}
    </uni-form-field-error>

    <uni-text-result *ngIf="!(isVoice && fileRecipientsControl)">
      <uni-plural
        single="recipientAdded"
        multi="recipientsAdded"
        [amount]="countRecipients(recipientsForm)"
      >
        {{ countRecipients(recipientsForm) }}
      </uni-plural>
    </uni-text-result>
    <uni-form-field-error *ngIf="countRecipients(recipientsForm) === 0">
      {{ 'validation.minRecipients' | translate }}
    </uni-form-field-error>
    <uni-form-field-error *ngIf="isLimitError(recipientsForm)">
      {{ 'validation.maxRecipients' | translate:{ limit: isManual ? manualRecipientsLimit : recipientsLimit }  }}
    </uni-form-field-error>
    <uni-text-result *ngIf="!isEditable && isAutocompleteActiveControl.value">
      {{
        'campaign.new.tip.autocomplete.on' | translate:{
          country: ('country.' + autocompleteControl.value.name | translate),
          callingCode: autocompleteControl.value.callingCode
        }
      }}
    </uni-text-result>
  </uni-form-field>
</uni-form-container>
