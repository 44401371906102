import { combineLatest, concat, of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { BlobUtils, ValidationUtils } from '../../../utils';
import { uniSnackbarActions } from '../../uni-snackbar';

import { getCampaignViolations } from '../shared/uni-campaign.utils';
import { UniCampaignRepository } from '../shared/uni-campaigns.repository';
import * as CampaignActions from './uni-campaigns.actions';
import { CampaignViolationErrors, ApiResponseCountry } from '../shared/uni-campaign.model';

@Injectable()
export class UniCampaignEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private campaignRepository: UniCampaignRepository,
  ) { }

  @Effect() getCampaign$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaign>(CampaignActions.SET_CAMPAIGN),
      mergeMap(({ id }) => combineLatest([
        this.campaignRepository
        .getCampaign(id),
        this.campaignRepository
        .getCampaignStats(id)
      ]).pipe(
          mergeMap(([ campaign, stats ])  => of(new CampaignActions.SetCampaignSuccess(campaign.body, stats.body))),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))),
        ),
      ),
    );

  @Effect() getCampaigns$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaigns>(CampaignActions.SET_CAMPAIGNS),
      mergeMap(({ params }) => this.campaignRepository
        .getCampaigns(params)
        .pipe(
          mergeMap(response => of(new CampaignActions.SetCampaignsSuccess(response.body, response.headers))),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))),
        ),
      ),
    );

  @Effect() getCampaignLogs$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignLog>(CampaignActions.SET_CAMPAIGN_LOG),
      mergeMap(({ params }) => this.campaignRepository
        .getCampaignLog(params)
        .pipe(
          mergeMap(response => of(new CampaignActions.SetCampaignLogSuccess(response.body, response.headers))),
        ),
      ),
    );

  @Effect() getCampaignTemplate$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignTemplate>(CampaignActions.SET_CAMPAIGN_TEMPLATE),
      mergeMap(({ id }) => this.campaignRepository
        .getCampaignTemplate(id)
        .pipe(
          mergeMap(response => of(new CampaignActions.SetCampaignTemplateSuccess(response.body))),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))),
        ),
      ),
    );

  @Effect() getCampaignTemplates$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignTemplates>(CampaignActions.SET_CAMPAIGN_TEMPLATES),
      mergeMap(({ params }) => this.campaignRepository
        .getCampaignTemplates(params)
        .pipe(
          map(response => new CampaignActions.SetCampaignTemplatesSuccess(response)),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))),
        ),
      ),
    );

  @Effect() getCampaignTrialTemplates$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignTemplates>(CampaignActions.SET_CAMPAIGN_TRIAL_TEMPLATES),
      mergeMap(() => this.campaignRepository
        .getCampaignTrialTemplates()
        .pipe(
          map(response => new CampaignActions.SetCampaignTrialTemplatesSuccess(response)),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))),
        ),
      ),
    );

  @Effect() postCampaign$ = this.actions$
    .pipe(
      ofType<CampaignActions.PostCampaign>(CampaignActions.POST_CAMPAIGN),
      mergeMap(({ payload }) => concat(
        this.campaignRepository
          .postCampaign(payload)
          .pipe(
            mergeMap(() => concat(
              of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successAddCampaign')),
              of(new CampaignActions.PostCampaignSuccess()),
            )),
            catchError((errors: HttpErrorResponse) => {
              let errorBar = of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault'));
              const error = ValidationUtils.getViolations(errors);
              const isPropertyPath = !!error.length;
              if (isPropertyPath && error[0].propertyPath === 'scheduledAt') {
                errorBar = of(new uniSnackbarActions.NewSnackbar('error', 'validation.invalidScheduledAtTime'));
              }
              return concat(
                errorBar,
                of(new CampaignActions.PostCampaignError(getCampaignViolations(error))),
              );
            }),
          ),
      )),
    );

  @Effect() deleteScheduledCampaign$ = this.actions$
    .pipe(
      ofType<CampaignActions.DeleteScheduledCampaign>(CampaignActions.DELETE_SCHEDULED_CAMPAIGN),
      mergeMap(({ id }) => this.campaignRepository
        .deleteScheduledCampaign(id)
        .pipe(
          mergeMap(() => concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successDeleteScheduled')),
            of(new CampaignActions.DeleteScheduledCampaignSuccess()),
          )),
          catchError(errors => {
            const error = ValidationUtils.getViolations(errors);
            const isMessage = !!error.length;
            let errorBar = of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorDefault'));

            if (isMessage && error[0].message === CampaignViolationErrors.campaignAlreadyStarted) {
              errorBar = of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorCampaignStarted'));
            }

            if (isMessage && error[0].message === CampaignViolationErrors.notScheduledCampaign) {
              errorBar = of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorCampaignNotScheduled'));
            }

            return concat(
              errorBar,
              of(new CampaignActions.DeleteScheduledCampaignError())
            );
          }),
        ),
      ),
    );

  @Effect({ dispatch: false }) deleteScheduledCampaignError$ = this.actions$
    .pipe(
      ofType<CampaignActions.All>(
        CampaignActions.DELETE_SCHEDULED_CAMPAIGN_ERROR,
        CampaignActions.DELETE_SCHEDULED_CAMPAIGN_SUCCESS,
        CampaignActions.POST_CAMPAIGN_SUCCESS
      ),
      tap(() => this.router.navigate(['/campaigns/sms'])),
    );

  @Effect() postCampaignTemplate$ = this.actions$
    .pipe(
      ofType<CampaignActions.PostCampaignTemplate>(CampaignActions.POST_CAMPAIGN_TEMPLATE),
      mergeMap(({ payload }) => this.campaignRepository
        .postCampaignTemplate(payload)
        .pipe(
          mergeMap(res => concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successAddCampaignTemplate')),
            of(new CampaignActions.PostCampaignTemplateSuccess(res)),
          )),
          catchError(error => concat(
            of(new CampaignActions.PostCampaignError(error.error.violations || [])),
            of(new uniSnackbarActions.NewSnackbar('error', 'snackbar.errorSaveTemplate')),
          )),
        ),
      ),
    );

  @Effect() setCampaignFile$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignFile>(CampaignActions.SET_CAMPAIGN_FILE),
      mergeMap(({ id, name }) => this.campaignRepository
        .getCampaignFile(id)
        .pipe(
          mergeMap((data) => concat(
            of(new CampaignActions.SetCampaignFileSuccess(data, name)),
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successDownload')),
          )),
        ),
      )
    );

  @Effect() postCampaignExport$ = this.actions$
    .pipe(
      ofType<CampaignActions.PostCampaignExport>(CampaignActions.POST_CAMPAIGN_EXPORT),
      mergeMap(({ id }) => this.campaignRepository
        .postCampaignExport(id)
        .pipe(
          mergeMap(response => concat(
            of(new CampaignActions.PostCampaignExportSuccess(response)),
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successExportStarted')),
          )),
        ),
      ),
    );

  @Effect() setCampaignExport$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignExport>(CampaignActions.SET_CAMPAIGN_EXPORT),
      mergeMap(({ campaignId, exportId }) => this.campaignRepository
        .getCampaignExport(campaignId, exportId)
        .pipe(
          retry(9),
          mergeMap((response) => concat(
            of(new CampaignActions.SetCampaignExportSuccess(response)),
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successDownload')),
          )),
          catchError(() => of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successExportEnded'))),
        ),
      )
    );

  @Effect({ dispatch: false }) setCampaignFileSuccess$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignFileSuccess>(CampaignActions.SET_CAMPAIGN_FILE_SUCCESS),
      tap(({ response, name }) => BlobUtils.download(response, name))
    );

  @Effect({ dispatch: false }) setCampaignExportSuccess$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetCampaignExportSuccess>(CampaignActions.SET_CAMPAIGN_EXPORT_SUCCESS),
      tap(({ response }) => BlobUtils.download(response, 'campaign_log_export.zip'))
    );

  @Effect() setAutocompleteCountries$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetAutocompleteCountries>(CampaignActions.SET_AUTOCOMPLETE_COUNTRIES),
      mergeMap(() => this.campaignRepository
        .getAutocompleteCountries()
        .pipe(
          mergeMap((data: ApiResponseCountry[]) => concat(
            of(new CampaignActions.SetAutocompleteCountriesSuccess(data.map(apiCountry => ({
              id: apiCountry.id,
              name: apiCountry.name,
              code: apiCountry.prefix,
              callingCode: apiCountry.code
            })
            ))),
          )),
        )),
    );

    @Effect() changeCampaignStatus$ = this.actions$
    .pipe(
      ofType<CampaignActions.PostCampaignStatus>(CampaignActions.POST_CAMPAIGN_STATUS),
      mergeMap(({ payload }) => this.campaignRepository
        .changeCampaignStatus(payload)
        .pipe(
          mergeMap((res) => concat(
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successChangeCampaignStatus')),
            of(new CampaignActions.PostCampaignStatusSuccess(res)),
            of(new CampaignActions.SetCampaigns({})),
          )),
          catchError((error) =>
          concat(
            of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)))),
          )
        ),
      ),
    );

    @Effect() setblockWords$ = this.actions$
    .pipe(
      ofType<CampaignActions.SetBlockWords>(CampaignActions.SET_BLOCK_WORDS),
      mergeMap(({ payload }) => this.campaignRepository
        .getBlockWords(payload)
        .pipe(
          mergeMap(response => of(new CampaignActions.SetBlockWordsSuccess(response.body))),
          catchError((errors: HttpErrorResponse) => {
            if(errors && errors.status === 400) {
              return concat(
                of(new CampaignActions.SetBlockWordsSuccess(false)),
                of(new uniSnackbarActions.NewSnackbar('error','validation.blockWords'))
              );
            } else {
              return concat(
                of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(errors)))
              );
            }
          }),
        )
      ));
}
