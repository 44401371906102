<uni-form-container [formGroup]="contentForm" *ngIf="messageTypeControl">
  <uni-label>
     {{ 'label.messageType' | translate }}
    <span class="star_icon">*</span>
    <uni-icon
      class="type_icon"
      name="info-circle"
      (click)="toggleModal()"
    ></uni-icon>
  </uni-label>

  <uni-form-field [margin]="true">
    <mat-form-field>
      <mat-select
        [formControl]="messageTypeControl"
        [disableOptionCentering]="true"
        [placeholder]="'campaign.messageTypePlaceholder' | translate"
      >
        <ng-container *ngIf="options && options.length; else defaultOptions">
          <mat-option *ngFor="let option of options" [value]="option">
            {{ 'messageType.' + option | translate }}
          </mat-option>
        </ng-container>

        <ng-template #defaultOptions>
          <mat-option *ngFor="let type of campaignMessageTypes | keyvalue" [value]="type.value">
            {{ 'messageType.' + type.key | translate }}
          </mat-option>
        </ng-template>
      </mat-select>
    </mat-form-field>
  </uni-form-field>
</uni-form-container>

<uni-modal
  maxWitdth="580px"
  isCloseIcon="true"
  [isActive]="isModalActive"
  (isActiveChange)="toggleModal()"
>
  <uni-modal-content [margin]="false">
    <uni-section>
      <p [innerHTML]="'campaign.new.tip.content.messageType' | translate"></p>
    </uni-section>
  </uni-modal-content>
</uni-modal>
