<uni-label [required]="true">
    {{'ci.form.common.dateRange' | translate}}
</uni-label>
<app-form-date-range-selector
    [from]="value.from"
    [to]="value.to"
    (dateChange)="onDateSelect($event)"
></app-form-date-range-selector>
<uni-form-field-error *ngIf="ngControl.invalid && ngControl.touched">
    {{'ci.common.fieldRequired' | translate}}
</uni-form-field-error>
