import { concat, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { QueryActions } from '../../../store/query';
import { UniAccountsRepository } from '../shared/uni-accounts.repository';
import * as UniAccountsActions from './uni-accounts.actions';
import { GET_ACCOUNTS_QUERY } from './uni-accounts.state';

@Injectable()
export class UniAccountsEffects {
  constructor(
    private actions$: Actions,
    private uniAccountsRepository: UniAccountsRepository,
  ) { }

  @Effect() setUniAccounts$ = this.actions$
    .pipe(
      ofType<UniAccountsActions.SetUniAccounts>(UniAccountsActions.SET_UNI_ACCOUNTS),
      mergeMap(({ params }) => concat(
        of(new QueryActions.QueryInProgress(GET_ACCOUNTS_QUERY)),
        this.uniAccountsRepository
          .getAccounts(params)
          .pipe(
            mergeMap(accounts => concat(
              of(new QueryActions.QuerySuccess(GET_ACCOUNTS_QUERY, accounts)),
              of(new UniAccountsActions.SetUniAccountsSuccess(accounts)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_ACCOUNTS_QUERY, error)),
              of(new UniAccountsActions.SetUniAccountsError()),
              )),
          ),
      )),
    );

}
