import { get, isObject, isString, isEmpty } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, debounceTime, filter } from 'rxjs/operators';

import { Component, ContentChildren, Input, OnChanges, QueryList, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UniLabelTipDirective } from '../../directives/label-tip.directive';
import { AutoUnsubscribe } from '../../../../utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'uni-form-autocomplete',
  templateUrl: './uni-form-autocomplete.component.html',
  styleUrls: ['./uni-form-autocomplete.component.scss']
})
export class UniFormAutocompleteComponent extends AutoUnsubscribe implements OnChanges {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
  @ContentChildren(UniLabelTipDirective) labelTip: QueryList<UniLabelTipDirective>;
  @Input() control: FormControl;
  @Input() options: any[];
  @Input() label: string;
  @Input() placeholder = 'label.select';
  @Input() required: boolean;
  @Input() margin = true;
  @Input() isReadOnly = false;
  @Input() isLoading = false;
  @Input() isValidation = true;
  @Input() filterKey: string;
  @Input() isValidationOnBlur = false;
  @Input() fetchTranslations = false;
  @Input() displayValueFn: () => {};
  @Input() isLazyLoading = false;
  @Input() translationPath = 'label';
  @Input() limitOptions?: number;
  @Output() search = new EventEmitter<string>();

  isPanelOpen = false;
  filteredOptions$: Observable<string[]>;
  isEmpty = isEmpty;
  moreOptionsToRender = 0;

  constructor(private translateService: TranslateService) {
    super();
    this.subs.sink = this.initControl();
  }

  ngOnChanges(): void {
    this.initData();
  }

  onBlur() {
    if (!this.isValidationOnBlur) {
      return;
    }

    setTimeout(() => {
      if (!isObject(this.control.value)) {
        this.control.setValue('');
      }
    }, 200);
  }

  initControl(): Subscription {
    if (!this.control) {
      this.control = new FormControl();
    }

    return this.control.valueChanges
      .pipe(
        debounceTime(400),
        filter(value => !isObject(value))
      )
      .subscribe(value => this.search.emit(value));
  }

  initData() {
    this.filteredOptions$ = this.control.valueChanges
      .pipe(
        startWith(this.getOptionValue() || ''),
        map(value => this.getFilteredData(value))
      );
  }

  displayFn(item: any) {
    return item ? get(item, this.filterKey) : undefined;
  }

  displayValue(item: any) {
    if (this.fetchTranslations && item) {
      return this.translateService.instant(`${this.translationPath}.${item}`);
    }

    return item;
  }

  getOptionValue(item: object = this.control.value): string {
    return !!this.filterKey
      ? get(item, this.filterKey) || ''
      : item || '';
  }

  getFilteredData(value = ''): any[] {
    if (this.isLazyLoading) {
      return this.options || [];
    }

    const result = this.options && this.options
      .filter(option => this.getOptionValue(option)
        .toLowerCase()
        .includes(!!value && isString(value) ? value.toLowerCase() : '')
      );

    if (this.limitOptions && result) {
      this.moreOptionsToRender = result.length - this.limitOptions;

      return result.slice(0, this.limitOptions);
    }

    return result;
  }

  clearAutocomplete(): void {
    this.control.setValue('');
    setTimeout(() => this.autocompleteTrigger.openPanel(), 100);
  }

  toggleAutocomplete(isPanelOpen: boolean): void {
    setTimeout(() => isPanelOpen
      ? this.autocompleteTrigger.closePanel()
      : this.autocompleteTrigger.openPanel(), 0);
  }

  onOpen() {
    this.isPanelOpen = true;
  }

  onClose() {
    this.isPanelOpen = false;
  }
}
