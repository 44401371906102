<uni-nav-new-item-container
    *ngIf="navItem"
    [id]="navItem.id"
    [iconName]="navItem.iconName"
    [labelKey]="navItem.labelKey"
    [link]="navItem.link"
    [toggleType]="toggleType"
    [isExpanded]="isExpanded"
    (toggle)="onToggle()"
>
</uni-nav-new-item-container>
