import { Component, HostBinding, Input } from '@angular/core';

import { SmsCounter } from '../../../../shared/uni-sms-counter.utils';

@Component({
  selector: 'uni-form-message-counter',
  templateUrl: './uni-form-message-counter.component.html',
  styleUrls: ['./uni-form-message-counter.component.scss']
})
export class UniFormMessageCounterComponent {
  @HostBinding('class.uni-form-message-counter--disabled') @Input() disabled = false;
  @Input() isSMSCounter = false;
  @Input() maxLength: number;
  counter = SmsCounter.init(this.message);
  messageLength = 0;

  @Input()
  set message(value: string) {
    this.counter = SmsCounter.init(value);
    this.messageLength = value?.length || 0;
  }
}
