<uni-form-container>
  <uni-form-autocomplete
    [placeholder]="'senderNames.placeholder' | translate"
    [control]="senderNameControl"
    [options]="senderNames$ | async"
    [required]="isRequired"
    [margin]="false"
    [isLoading]="isLoaderActive"
    [isValidationOnBlur]="true"
    label="label.senderName"
    filterKey="name"
  ></uni-form-autocomplete>
  <uni-text-info *ngIf="isActive">
    {{ 'senderNames.info' | translate }}
  </uni-text-info>
</uni-form-container>
