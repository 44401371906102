import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectError, selectFinalizedOrder, selectOrder } from '../store/uni-orders.selectors';
import { UniOrdersState } from '../store/uni-orders.state';

import * as ordersActions from '../store/uni-orders.actions';
import { CreateOrderPayload, OrderPaymentMethod } from './uni-orders.model';

@Injectable()
export class UniOrdersFacade {
  error$ = this.store.pipe(select(selectError));
  order$ = this.store.pipe(select(selectOrder));
  finalizedOrder$ = this.store.pipe(select(selectFinalizedOrder));

  constructor(private store: Store<UniOrdersState>) { }

  clearErrors(): void {
    this.store.dispatch(new ordersActions.ClearErrors());
  }

  createOrder(payload: CreateOrderPayload): void {
    this.store.dispatch(new ordersActions.CreateOrder(payload));
  }

  getOrder(orderId: string): void {
    this.store.dispatch(new ordersActions.GetOrder(orderId));
  }

  finalizeOrder(orderId: string, paymentMethod: OrderPaymentMethod): void {
    this.store.dispatch(new ordersActions.FinalizeOrder(orderId, paymentMethod));
  }
}
