<div (uniClickOutside)="onApply()">
  <uni-table-filter-header
    [heading]="heading"
    [hasFilters]="!!control.value"
    (open)="onOpen()"
  ></uni-table-filter-header>

  <uni-table-filter-wrapper *ngIf="isOpened" [tableLeft]="tableLeft" [thead]="thead">
    <div class="uni-table-filter-search">
      <uni-form-multi-autocomplete-header
        [heading]="heading"
        (clear)="onClear()"
        (close)="onDiscard()"
      ></uni-form-multi-autocomplete-header>

      <form (ngSubmit)="onApply()">
        <uni-form-search [form]="form" [controlName]="controlName" [margin]="false"></uni-form-search>
      </form>

      <uni-form-multi-autocomplete-controllers
        [isValidationError]="isValidationError()"
        (apply)="this.isOpened = false"
        (clear)="onClear()"
      ></uni-form-multi-autocomplete-controllers>
    </div>
  </uni-table-filter-wrapper>
</div>
