import * as XRegExp_ from 'xregexp';

import { Directive, HostBinding, HostListener, Input, OnInit, Self, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';

import { REGEX } from '../../../utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type InputKeyPattern =
  | 'alphanumeric'
  | 'price'
  | 'number'
  | 'name'
  | 'phone'
  | 'phones'
  | 'senderName'
  | 'ips'
  | 'credit-card';

const XRegExp = XRegExp_;

@Directive({
  selector: '[uniInput]',
})
export class UniInputDirective implements OnInit, OnDestroy {
  @Input() class = '';
  @Input() id: string;
  @Input() label: string;
  @Input() required = false;
  @Input() isValidation = true;
  @Input() keyPattern: InputKeyPattern;

  validationMessage: string;
  validationParams;
  unsubscribe$ = new Subject();

  @HostBinding('class')
  get getClass() {
    return `${this.class} uni-input unifonic-spa`;
  }

  @HostBinding('class.uni-input--error')
  get isError(): boolean {
    return this.isValidation
      && this.ngControl.dirty
      && this.ngControl.invalid;
  }

  @HostListener('focus')
  onFocus() {
    this.updateInputValues();
  }

  @HostListener('blur')
  onBlur() {
    this.updateInputValues();
  }

  ngOnInit() {
    this.updateInputValues();

    this.ngControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateInputValues());

    this.ngControl.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.updateInputValues());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateInputValues() {
    this.validationMessage = this.getValidationMessage();
  }

  getValidationMessage(): string {
    if (!this.isError || !this.ngControl.errors) {
      return '';
    }

    const errorKey = Object.keys(this.ngControl.errors)[0];
    this.validationParams = this.ngControl.errors[errorKey];
    return `validation.${errorKey}`;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    switch (this.keyPattern) {
      case 'name': {
        this.nameKeyPattern(event);
        break;
      }
      case 'alphanumeric': {
        this.keyPatternWithSpecialKey(event, /^[0-9a-zA-Z]+$/);
        break;
      }
      case 'number': {
        this.keyPatternWithSpecialKey(event, /[0-9]/);
        break;
      }
      case 'price': {
        this.keyPatternWithSpecialKey(event, /[0-9]|\./);
        break;
      }
      case 'phone': {
        this.keyPatternWithSpecialKey(event, REGEX.phone);
        break;
      }
      case 'phones': {
        this.keyPatternWithSpecialKey(event, /[0-9 ,+]/);
        break;
      }
      case 'ips': {
        this.keyPatternWithSpecialKey(event, /[0-9 ,\.]/);
        break;
      }
      case 'senderName': {
        this.keyPatternWithSpecialKey(event, REGEX.senderNameCharset);
        break;
      }
      case 'credit-card': {
        this.keyPatternWithSpecialKey(event, /[0-9 ,-]/);
        break;
      }
    }
  }

  constructor(@Self() public ngControl: NgControl) {}

  nameKeyPattern(event: KeyboardEvent) {
    if (this.isSpecialKey(event)) {
      return;
    }

    const regex = XRegExp('^([\\s\\pL,.\'-])+$');
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  keyPatternWithSpecialKey(event: KeyboardEvent, regex: RegExp) {
    if (this.isSpecialKey(event)) {
      return;
    }

    if (!String(event.key).match(regex)) {
      event.preventDefault();
    }
  }

  isSpecialKey(event: KeyboardEvent) {
    // eslint-disable-next-line
    const { keyCode, metaKey, ctrlKey } = event;

    return [46, 8, 9, 27, 13].indexOf(keyCode) !== -1 ||
      (keyCode === 65 && ctrlKey === true) || // Allow: Ctrl+A
      (keyCode === 67 && ctrlKey === true) || // Allow: Ctrl+C
      (keyCode === 86 && ctrlKey === true) || // Allow: Ctrl+V
      (keyCode === 88 && ctrlKey === true) || // Allow: Ctrl+X
      (keyCode === 65 && metaKey === true) || // Cmd+A (Mac)
      (keyCode === 67 && metaKey === true) || // Cmd+C (Mac)
      (keyCode === 86 && metaKey === true) || // Cmd+V (Mac)
      (keyCode === 88 && metaKey === true) || // Cmd+X (Mac)
      (keyCode >= 35 && keyCode <= 39);       // Home, End, Left, Right
  }
}
