import { Observable, of } from 'rxjs';
import { filter, take, tap, switchMap, catchError } from 'rxjs/operators';
import { get } from 'lodash';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { UniRouteService } from '../../../shared';
import { UniAuthFacade } from './uni-auth.facade';
import { FeatureFlagGuard, FeatureFlagKeys, FeatureFlagOperator } from './uni-feature-flags.model';
import { UniFeatureFlagsService } from './uni-feature-flags.service';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsGuard implements CanActivate {
  constructor(
    private uniAuthFacade: UniAuthFacade,
    private uniRouteService: UniRouteService,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) { }

  checkFeatureFlag(routeData: FeatureFlagGuard): Observable<any> {
    return this.uniAuthFacade
      .featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags),
        tap(() => this.hasPermission(routeData)),
        take(1),
      );
  }

  hasPermission(routeData: FeatureFlagGuard): boolean {
    const permissions = get(routeData, 'featureFlags') || [];
    const operator = get(routeData, 'operator') || FeatureFlagOperator.AND;

    const hasPermission = this.uniFeatureFlagsService.checkPermission(operator, permissions);

    if (!hasPermission) {
      this.uniRouteService.navigate('/403');
      return false;
    }

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const routeData = get(route, 'data') as FeatureFlagGuard;

    return this.checkFeatureFlag(routeData)
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
  }

  hasTwoFactor(): boolean {
    return this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.otp_campaign_2021_q_2);
  }
}
