import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UniPipesModule } from '../../pipes/pipes.module';
import { UniFormModule } from '../uni-form';
import {
  UniIndustryAutocompleteComponent
} from './components/uni-industry-autocomplete/uni-industry-autocomplete.component';
import { UniIndustryFacade } from './shared/uni-industry.facade';
import { UniIndustryRepository } from './shared/uni-industry.repository';
import { IndustryEffects, industryReducer } from './store/uni-industry';

const components = [
  UniIndustryAutocompleteComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    UniFormModule,
    UniPipesModule,
    TranslateModule,
    StoreModule.forFeature('industry', industryReducer),
    EffectsModule.forFeature([IndustryEffects])
  ],
  providers: [
    UniIndustryRepository,
    UniIndustryFacade,
    TranslateService,
  ],
})
export class UniIndustryModule { }
