import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cc-campaign-groups-modal',
  templateUrl: './campaign-groups-modal.component.html'
})
export class CampaignGroupsModalComponent {
  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter();

  hideModal(): void {
    this.isActiveChange.emit(false);
  }
}
