import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as campaignActions from '../store/uni-campaigns.actions';
import * as selectors from '../store/uni-campaigns.selectors';
import { CampaignState } from '../store/uni-campaigns.state';
import {
  CampaignTemplate,
  CampaignTemplatesParams,
  changeStatusCampaignData,
  MessageContent,
  PostCampaignData
} from './uni-campaign.model';

@Injectable()
export class UniCampaignsFacade {
  campaign$ = this.store.pipe(select(selectors.selectCampaign));
  campaigns$ = this.store.pipe(select(selectors.selectCampaigns));
  campaignFile$ = this.store.pipe(select(selectors.selectCampaignFile));
  campaignLog$ = this.store.pipe(select(selectors.selectCampaignLog));
  campaignExportId$ = this.store.pipe(select(selectors.selectCampaignExportId));
  campaignExport$ = this.store.pipe(select(selectors.selectCampaignExport));
  campaignTemplate$ = this.store.pipe(select(selectors.selectCampaignTemplate));
  campaignTemplates$ = this.store.pipe(select(selectors.selectCampaignTemplates));
  campaignTrialTemplates$ = this.store.pipe(select(selectors.selectCampaignTrialTemplates));
  campaignsTotalItems$ = this.store.pipe(select(selectors.selectCampaignsTotalItems));
  campaignsLogTotalItems$ = this.store.pipe(select(selectors.selectCampaignsLogTotalItems));
  campaignFormErrors$ = this.store.pipe(select(selectors.selectCampaignFormErrors));
  autocompleteCountries$ = this.store.pipe(select(selectors.selectAuocompleteCountries));
  changeCampaignStatus$ = this.store.pipe(select(selectors.selectCampaignStatus));
  checkBlockContent$ = this.store.pipe(select(selectors.selectBlockWords));

  constructor(private store: Store<CampaignState>) {}

  setCampaign(id: string) {
    this.store.dispatch(new campaignActions.SetCampaign(id));
  }

  setCampaigns(params: Params = {}) {
    this.store.dispatch(new campaignActions.SetCampaigns(params));
  }

  setCampaignLog(params: Params = {}) {
    this.store.dispatch(new campaignActions.SetCampaignLog(params));
  }

  setCampaignTemplate(id: string) {
    this.store.dispatch(new campaignActions.SetCampaignTemplate(id));
  }

  setCampaignTemplates(params: CampaignTemplatesParams) {
    this.store.dispatch(new campaignActions.SetCampaignTemplates(params));
  }

  setCampaignTrialTemplates() {
    this.store.dispatch(new campaignActions.SetCampaignTrialTemplates());
  }

  setCampaignFile(id: string, name: string) {
    this.store.dispatch(new campaignActions.SetCampaignFile(id, name));
  }

  postCampaign(payload: PostCampaignData): void {
    this.store.dispatch(new campaignActions.PostCampaign(payload));
  }

  postCampaignTemplate(payload: CampaignTemplate): void {
    this.store.dispatch(new campaignActions.PostCampaignTemplate(payload));
  }

  deleteScheduledCampaign(id: string): void {
    this.store.dispatch(new campaignActions.DeleteScheduledCampaign(id));
  }

  postCampaignExport(id: string): void {
    this.store.dispatch(new campaignActions.PostCampaignExport(id));
  }

  setCampaignExport(campaignId: string, exportId: string): void {
    this.store.dispatch(new campaignActions.SetCampaignExport(campaignId, exportId));
  }

  clearCampaign(): void {
    this.store.dispatch(new campaignActions.ClearCampaign());
  }

  setAutocompleteCountries(): void {
    this.store.dispatch(new campaignActions.SetAutocompleteCountries());
  }

  setCampaignStatus(payload: changeStatusCampaignData) {
    this.store.dispatch(new campaignActions.PostCampaignStatus(payload));
  }

  checkBlockWords(content: MessageContent) {
    this.store.dispatch(new campaignActions.SetBlockWords(content));
  }
}
