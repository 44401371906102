<uni-main-container>
  <uni-top-bar *ngIf="!topBarTemplate"></uni-top-bar>
  <ng-container *ngIf="topBarTemplate" [ngTemplateOutlet]="topBarTemplate"></ng-container>

  <uni-menu-content *ngIf="!menuContentTemplate"></uni-menu-content>
  <ng-container *ngIf="topBarTemplate" [ngTemplateOutlet]="menuContentTemplate"></ng-container>

  <uni-layout-content>
    <ng-content></ng-content>
    <uni-main-content>
      <router-outlet></router-outlet>
      <uni-footer></uni-footer>
    </uni-main-content>
    <uni-overlay
      class="uni-app-logged__overlay"
      [isActive]="isProductNavOpen$ | async"
      (click)="onOverlayClick()"
    ></uni-overlay>
  </uni-layout-content>
</uni-main-container>
<uni-snackbar></uni-snackbar>
