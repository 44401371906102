<div
  class="uni-top-bar-packages-details__item"
>
  <uni-icon
    [name]="getIcon(package.serviceType)"
    [type]="iconType"
    [style]="'fak'"
  ></uni-icon>

  <div class="uni-top-bar-packages-details__content">
    <div class="uni-top-bar-packages-details__header">
      <uni-text-header
        level="100"
        [isUppercase]="false"
      >
        {{ 'packages.serviceType.' + package.serviceType | translate }}
      </uni-text-header>
      <uni-text-body
        *ngIf="!!package.items && package.items > 1"
        level="050"
        [color]="color.tertiary"
        [isUppercase]="false"
      >
        <span class="uni-top-bar-packages-details__summary">
          {{ package.items }} {{ 'topbar.packages' | translate }}
        </span>
      </uni-text-body>
    </div>

    <div class="uni-top-bar-packages-details__progress-bar-container">
      <div
        class="uni-top-bar-packages-details__progress-bar"
        [class]="statusColorClass(package)"
        [style.width.%]="getProgress(package)"
      ></div>
    </div>

    <uni-text-body
      level="050"
      [color]="color.tertiary"
      [isUppercase]="false"
    >
      {{ getUnits(package) }}
    </uni-text-body>
  </div>
</div>
