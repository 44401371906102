import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniDetailsHeadlineComponent } from './components/uni-details-headline/uni-details-headline.component';
import { UniDetailsItemComponent } from './components/uni-details-item/uni-details-item.component';
import { UniDetailsComponent } from './components/uni-details/uni-details.component';

const components = [
  UniDetailsComponent,
  UniDetailsItemComponent,
  UniDetailsHeadlineComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule
  ]
})
export class UniDetailsModule { }
