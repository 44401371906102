import { get, isObject } from 'lodash';

import { Component, ContentChild, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';

import { UniPrefixDirective } from '../../directives/input-prefix.directive';
import { UniSuffixDirective } from '../../directives/input-suffix.directive';
import { UniInputDirective } from '../../directives/input.directive';
import { UniLabelSuffixDirective } from '../../directives/label-suffix.directive';
import { UniLabelTipDirective } from '../../directives/label-tip.directive';

@Component({
  selector: 'uni-form-field',
  templateUrl: './uni-form-field.component.html',
  styleUrls: ['./uni-form-field.component.scss']
})
export class UniFormFieldComponent {
  @ContentChild(UniInputDirective, { static: false }) input: UniInputDirective;
  @ContentChildren(UniSuffixDirective) suffix: QueryList<UniSuffixDirective>;
  @ContentChildren(UniPrefixDirective) prefix: QueryList<UniPrefixDirective>;
  @ContentChildren(UniLabelSuffixDirective) labelSuffix: QueryList<UniLabelSuffixDirective>;
  @ContentChildren(UniLabelTipDirective) labelTip: QueryList<UniLabelTipDirective>;
  @Input() isButton = false;
  @Input() isErrorAllowed = true;
  @Input() disable = true;

  get label(): string {
    return get(this.input, 'label');
  }

  get required(): boolean {
    return get(this.input, 'required');
  }

  get validationMessage(): string {
    return get(this.input, 'validationMessage');
  }

  get validationParams(): object {
    const params = get(this.input, 'validationParams');
    return isObject(params) ? params : {};
  }

  @HostBinding('class.uni-form-field') true;

  @HostBinding('class.uni-form-field--margin') @Input() margin = true;

  @HostBinding('class.uni-form-field--margin-right') @Input() marginRight = false;

  @HostBinding('class.uni-form-field--group-margin') @Input() groupMargin = false;

  @HostBinding('class.is-row') @Input() isRow = false;

  @HostBinding('class.is-button')
  get isButtonClass() {
    return this.isButton;
  }

  @HostBinding('class.is-disabled')
  get isDisabledClass() {
    return this.disable && !!get(this.input, 'ngControl.disabled');
  }

  @HostBinding('class.is-suffix')
  get isSuffixClass(): boolean {
    return !!this.suffix.length;
  }

  @HostBinding('class.is-prefix')
  get isPrefixClass(): boolean {
    return !!this.prefix.length;
  }
}
