import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-box-content-header-item',
  templateUrl: './uni-box-content-header-item.component.html',
  styleUrls: ['./uni-box-content-header-item.component.scss'],
})
export class UniBoxContentHeaderItemComponent {
  @HostBinding('class.is-last') @Input() isLast = false;
}
