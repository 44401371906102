import { Query } from '../../../../store';
import { Country, Countries } from '../../shared/uni-countries.model';

export const GET_COUNTRIES_QUERY = 'getCountriesQuery';
export const COUNTRIES_KEY = 'countries';

export interface CountriesState {
  countries: Country[];
  countriesChoice: Countries;
  getCountriesQuery: Query<Country>;
}

export const initialState: CountriesState = {
  countries: [],
  countriesChoice: {},
  getCountriesQuery: {},
};
