import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UniRouteService } from '../../../shared/uni-route.service';
import { UniCampaignsPermissionsService } from './uni-campaign-permissions.service';

@Injectable()
export class UniCampaignGuard implements CanActivate {
  constructor(
    private uniCampaignPermissions: UniCampaignsPermissionsService,
    private uniRouteService: UniRouteService,
  ) { }

  canActivate(): boolean {
    if (!this.uniCampaignPermissions.canSeeCampaign()) {
      this.uniRouteService.navigate('/403');
      return false;
    }

    return true;
  }
}
