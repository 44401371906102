<uni-section *ngIf="isActive">
  <uni-box-content
    class="uni-info-box__container"
    [type]="'blue'"
    [margin]="'regular'"
  >
    <uni-icon
      name="info-circle"
      class="uni-info-box__info-icon"
    ></uni-icon>
    <div class ="uni-info-box__content">
      <ng-content></ng-content>
    </div>
    <uni-icon
      name="times"
      class="uni-info-box__close-icon"
      (click)="hideBox()"
    ></uni-icon>
  </uni-box-content>
</uni-section>
