import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniPreviewMobileComponent } from './components/uni-preview-mobile/uni-preview-mobile.component';
import { UniPreviewFileComponent } from './components/uni-preview-file/uni-preview-file.component';
import { UniIconModule } from '../uni-icon';

const components = [
  UniPreviewMobileComponent,
  UniPreviewFileComponent
];

@NgModule({
  imports: [
    CommonModule,
    UniIconModule
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
})
export class UniPreviewModule { }
