import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-campaign-progress-bar',
  templateUrl: './campaign-progress-bar.component.html',
  styleUrls: ['./campaign-progress-bar.component.scss']
})
export class CampaignProgressBarComponent {
  @Input() succeed = 0;
  @Input() failed = 0;
}
