import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-table-empty',
  templateUrl: './uni-table-empty.component.html',
  styleUrls: ['./uni-table-empty.component.scss'],
})
export class UniTableEmptyComponent {
  @Input() isTitle = true;
  @Input() title = 'label.emptyListTitle';
  @Input() description = 'label.emptyListDescription';
}
