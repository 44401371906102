import { HttpResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'uni-audio-player',
  templateUrl: './uni-audio-player.component.html',
  styleUrls: ['./uni-audio-player.component.scss']
})
export class UniAudioPlayerComponent {
  @Input() request: Observable<HttpResponse<{ file: string }>>;
  audioUrl: string;
  isLoading = false;

  fetchAudioFile(): void {
    this.audioUrl = undefined;
    this.isLoading = true;

    this.request.subscribe(res => {
      if (res) {
        this.audioUrl = res.body.file;
        this.isLoading = false;
      }
    });
  }
}
