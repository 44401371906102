import { Component, HostBinding } from '@angular/core';

import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';

@Component({
  selector: 'uni-top-bar-separator',
  templateUrl: './uni-top-bar-separator.component.html',
  styleUrls: ['./uni-top-bar-separator.component.scss'],
})
export class UniTopBarSeparatorComponent {
  @HostBinding('class.is-impersonation-session')
  get isImpresonationSession(): boolean {
    return this.uniAuthFacade.isImpresonationSession();
  }

  constructor(private uniAuthFacade: UniAuthFacade) {}
}
