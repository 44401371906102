import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { SortOptions } from "./data-sort.models";


type State = {
    dir: SortOptions,
    isActive: boolean
    
}

const initialState: State = {
    dir: '',
    isActive: false
}

@Injectable()
export class DataSortStore extends ComponentStore<State>{

    constructor(){
        super(initialState)
    }

    //selectors
    readonly dir$ = this.select(state => state.dir)
    readonly isActive$ = this.select(state => state.isActive)



    readonly vm$ = this.select(
        this.isActive$,
        this.dir$,
        (isActive, dir) => {
            return {
                ascActive: isActive && dir === 'ASC',
                descActive: isActive && dir === 'DESC',
            }
        }
    )
}