import { Component, Input, OnInit } from '@angular/core';
import { Campaign, CampaignChartData, CampaignStats, CampaignStatus } from '../../shared/uni-campaign.model';
import * as moment from 'moment-timezone';
import { DateUtils } from '../../../../utils/date.utils';
import { color } from '../../../../utils/color.utils';
import { SmsCounter } from '../../../../shared';
import { sum } from 'lodash';

@Component({
  selector: 'cc-campaign-performance',
  templateUrl: './campaign-performance.component.html',
  styleUrls: ['./campaign-performance.component.scss'],
})
export class CampaignPerformanceComponent implements OnInit {
  @Input() campaign: Campaign;
  @Input() timezone: string;
  counter: SmsCounter.Counter;

  ngOnInit() {
    this.counter = SmsCounter.init(this.campaign.content);
  }

  getUnits(messages: number = 1): number {
    return messages * this.campaign.recipientsCount;
  }

  getStatus(campaign: Campaign): boolean {
    const updatedAt = campaign.stats?.updatedAt?.date || DateUtils.getCurrentDate(this.timezone);
    const today = DateUtils.getCurrentDate(this.timezone);

    return campaign.status !== CampaignStatus.scheduled
      && moment(updatedAt).add(2, 'd').isAfter(today);
  }

  getCampaignStatus(): boolean {
    const {
      delivered,
      undelivered,
      initialMessages,
      deliveryNotConfirmed
    } = this.campaign.stats;

    return sum([delivered, undelivered, deliveryNotConfirmed]) === initialMessages;
  }

  getChartData(messages: string): CampaignChartData {
    const stats: CampaignStats = this.campaign.stats;
    const isCompleted = this.getCampaignStatus();

    switch(messages) {
      case 'failed': {
        return {
          color: color.redLight,
          total: stats.sent || stats.initialMessages,
          value: stats.undelivered,
          label: 'campaign.chart.failLabel',
          tooltip: 'campaign.chart.failTooltip'
        };
      }
      case 'success': {
        return {
          color: color.lime,
          total: stats.sent || stats.initialMessages,
          value: stats.delivered,
          label: 'campaign.chart.successLabel',
          tooltip: 'campaign.chart.successTooltip'
        };
      }
    }

    return {
      color: color.blueLight,
      total: stats.initialMessages,
      value: isCompleted ? stats.deliveryNotConfirmed : stats.sent,
      label: `campaign.chart.${isCompleted ? 'notConfirmed' : 'total'}Label`,
      tooltip: `campaign.chart.${isCompleted ? 'notConfirmed' : 'total'}Tooltip`
    };
  }
}
