<uni-campaign-csv-limt-info></uni-campaign-csv-limt-info>
<uni-form-file
  [form]="form"
  [label]="'campaign.contentFromFile' | translate"
  [fileControl]="fileControl"
  [isEditable]="isEditable"
  (change)="onFilesAdded()"
  accept=".csv, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  #file
></uni-form-file>
<uni-text-info
  *ngIf="isEditable"
  class="campaign-recipients__example"
>
  <uni-link
    path="/media/samples/File2sms - example file.xlsx"
    target="_blank"
  >
    {{ 'label.downloadSample' | translate }}
  </uni-link>
</uni-text-info>
<uni-campaign-csv-error
  *ngIf="hasFileLimitError"
  [errorMessage]="'campaign.csvLimitError'"
>
</uni-campaign-csv-error>
<uni-campaign-csv-error
  *ngIf="isInvalidEncoding"
  [errorMessage]="'campaign.invalidEncoding'"
>
</uni-campaign-csv-error>
<uni-text-result *ngIf="fileControl.value">
  <uni-plural
    *ngIf="fileControl.value && allRecepientsAdded"
    single="recipientAdded"
    multi="recipientsAdded"
    [amount]="recepientsNumber"
  >
    {{ recepientsNumber }}
  </uni-plural>
  <uni-plural
    *ngIf="fileControl.value && allRecepientsRejected"
    single="recipientRejected"
    multi="recipientsRejected"
    [amount]="recepientsNumber"
  >
    {{ totalRecepients }}
  </uni-plural>
  <ng-container *ngIf="fileControl.value && showFailedRecepientsCount">
    <uni-plural
      single="recipientAdded"
      multi="recipientsAdded"
      [amount]="recepientsNumber"
    >
      {{ recepientsNumber }}
    </uni-plural>
    <uni-plural
      single="recipientRejected"
      multi="recipientsRejected"
      [amount]="recepientsNumber"
    >
      {{ rejectedRecepients }}
    </uni-plural>
 </ng-container>
</uni-text-result>
<uni-form-field-error *ngIf="fileControl.value && recepientsNumber && recepientsNumber === 0">
  {{ 'validation.minRecipients' | translate }}
</uni-form-field-error>
<uni-form-field-error *ngIf="fileControl.value && recepientsNumber > recipientsLimit">
  {{ 'validation.maxRecipients' | translate:{ limit: recipientsLimit } }}
</uni-form-field-error>
<uni-text-result *ngIf="!isEditable && isAutocompleteActiveControl.value">
  {{
    'campaign.new.tip.autocomplete.on' | translate:{
      country: ('country.' + autocompleteControl.value.name | translate),
      callingCode: autocompleteControl.value.callingCode
    }
  }}
</uni-text-result>
