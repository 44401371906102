export interface SenderName {
  id: string;
  name: string;
}

export enum CampaignDataSource {
  custom = 'custom',
  fileToSMS = 'file2sms',
  audience = 'audience'
}

export enum CampaignStatus {
  active = 'active',
  processing = 'processing',
  sending = 'sending',
  completed = 'completed',
  failed = 'failed',
  scheduled = 'scheduled',
  rejected = 'rejected'
}

export enum ReducedCampaignStatus {
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
  scheduled = 'scheduled',
  rejected = 'rejected',
  pending = 'pending',
}

export enum CampaignMessageStatus {
  sent = 'sent',
  failed = 'failed',
  rejected = 'rejected',
  created = 'created',
  queued = 'queued',
  processing = 'processing',
  processed = 'processed',
  successful = 'successful',
  failure = 'failure',
  duplicated = 'duplicated',
  delivered = 'delivered'
}

export enum CampaignRecipientsMethod {
  manually = 'manually',
  groups = 'groups',
  file = 'file',
}

export enum CampaignLogMessageStatus {
  sent = 'Sent',
  rejected = 'Rejected',
  queued = 'Queued',
}

export enum CampaignListTabs {
  sms = 'sms',
  smsPro = 'smsPro',
  whatsapp = 'whatsapp',
  flowStudio = 'flow_studio',
  voice = 'voice'
}

export enum CampaignTabs {
  overview = 'overview',
  report = 'report',
}

export interface CampaignFile {
  id: string;
  recipientsCount: number;
  totalCount: number;
}

export interface CampaignRecipients {
  phoneNumbers: string[];
  campaignFile: CampaignFile;
  audienceGroups?: Record<string, string>[];
}

export interface CampaignSenderName {
  id: string;
  name: string;
}

export interface CampaignAccount {
  id: string;
  name: string;
}

export interface CampaignExportId {
  exportId: string;
}

export interface CampaignUser {
  id: string;
  fullName: string;
}

export enum CampaignMessageType {
  warning = 'warning',
  awareness = 'awareness',
  service = 'service',
  personal = 'personal',
  promotional = 'promotional'
}

export interface Campaign {
  id: string;
  type: string;
  name: string;
  recipients: CampaignRecipients;
  senderName: CampaignSenderName;
  content: string;
  recipientsCount: number;
  account: CampaignAccount;
  user: CampaignUser;
  status?: string;
  messagesCounters?: CampaignMessageCounter[];
  rejectionReason: string;
  createdAt: string;
  scheduledAt: string;
  messageType: CampaignMessageType;
  stats?: CampaignStats;
}

export interface CampaignStats {
  id: string;
  sent: number;
  delivered: number;
  undelivered: number;
  initialMessages: number;
  deliveryNotConfirmed: number;
  messageUnitsConsumed: number;
  updatedAt: {
    date: string;
  };
}

export interface CampaignLog {
  messageId: number;
  messageUnits: number;
  accountName: string;
  sendDate: string;
  recipientNumber: string;
  operatorName: string;
  senderName: string;
  status: string;
  messageBody: string;
  dlr: string;
  countryName: string;
  mnpUsed: '1' | '0' | null;
  sentByClientDate: string;
  operatorReportReceivedAt: string;
  sentToOperatorStatus: string;
  createDate?: string;
  rejectionReason?: string;
  providerName?: string;
  deliveredDate?: string;
  receivedDate?: string;
}

export interface CampaignChartData {
  total: number;
  value: number;
  color: string;
  label: string;
  tooltip: string;
}

export interface CampaignRejectionReason {
  reason: string;
  messages: number;
}

// @TODO: It is general class for files in backend - probably can be moved to some generic module.
export interface File {
  id: string;
  name: string;
  originalName: string;
}

export interface CampaignFile {
  id: string;
  recipientsCount: number;
  file: File;
}

export interface CampaignVoiceFile {
  id: string;
  recipientsFile: File;
}

export interface CampaignMessageCounter {
  messagesStatus: string;
  count: number;
}

export interface CampaignTemplate {
  id?: string;
  body: string;
  name: string;
  user?: CampaignUser;
}

export interface ApiResponseCountry {
  id: string;
  name: string;
  code: string;
  prefix: string;
}

export interface CampaignCountryCode {
  id: string;
  code: string;
}

export interface PostCampaignData {
  id: string;
  type: CampaignDataSource;
  name: string;
  senderName?: SenderName;
  recipients?: CampaignRecipients;
  content?: string;
  scheduledAt?: string;
  countryCode?: CampaignCountryCode;
  otp: string;
}

export interface changeStatusCampaignData {
  id: string;
  status: string;
}

export enum CampaignFilters {
  campaignName = 'name[partial]',
  campaignId = 'campaignId',
  accountId = 'accountId',
  userId = 'userId',
  senderNameId = 'senderNameId',
  callerId = 'callerId',
  createdAt = 'createdAt',
  startTime = 'startTime'
}

export enum CampaignViolationErrors {
  notScheduledCampaign = 'notScheduledCampaign',
  campaignAlreadyStarted = 'campaignAlreadyStarted',
  invalidRunningTimeTooLate = 'invalidRunningTimeTooLate',
  invalidRunningTimeTooEarly = 'invalidRunningTimeTooEarly'
}

export enum CampaignTemplatesFilters {
  namePartial = 'name[partial]',
  nameFull = 'name[eq]',
}

export type CampaignTemplatesParams = {
  [K in CampaignTemplatesFilters]?: number | string;
};

export enum CampaignLogFilters {
  campaign = 'campaignId[eq]',
  operator = 'operator[eq]',
  recipientNumber = 'recipientNumber[eq]',
  messageId = 'messageId[eq]',
  status = 'status'
}

export type CampaignLogParams = {
  [K in CampaignLogFilters]?: number | string;
};

export interface BlockWords {
  isBlockedContent: boolean;
}

export interface MessageContent {
  content: string;
  senderId: string;
}

export interface Balance {
  balance: number;
}
