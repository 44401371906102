import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavMenuGroup, UniNavService } from '../../shared';

@Component({
  selector: 'uni-nav-new-group',
  templateUrl: './uni-nav-group.component.html',
  styleUrls: ['./uni-nav-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavGroupComponent {
  @Input() group: NavMenuGroup;

  isGroupExpanded = false;

  constructor(private readonly uniNavService: UniNavService) { }

  onToggleGroup(): void {
    this.isGroupExpanded = !this.isGroupExpanded;
    if (this.isGroupExpanded) {
      this.uniNavService.forceExpandNav();
    }
  }
}
