import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BlockedCardsStore } from './blocked-cards.store';

@Component({
  selector: 'app-blocked-cards',
  templateUrl: './blocked-cards.component.html',
  styleUrls: ['./blocked-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BlockedCardsStore]
})
export class BlockedCardsComponent {
  @Input() blocked: number = 0;
  @Input() savings: number = 0;
  constructor(
    private store: BlockedCardsStore
  ) { }


  ngOnInit(): void {
  }

  vm$ = this.store.vm$
}
