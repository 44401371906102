import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

export interface UniMultiCheckboxOption {
  value: string;
  name: string;
}

@Component({
  selector: 'uni-multi-checkbox',
  templateUrl: './uni-multi-checkbox.component.html',
  styleUrls: [ './uni-multi-checkbox.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UniMultiCheckboxComponent implements OnDestroy, OnInit, OnChanges {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() options: UniMultiCheckboxOption[];
  @Input() selectAllLabel = 'Select all';
  @Input() canSelectAll = true;
  @Input() layout: 'grid' | 'horizontal' | 'vertical' = 'grid';

  isAllChecked = false;
  isAllIndeterminate = false;

  selection = new SelectionModel<string>(true, [], true);

  private subs = new Subscription();


  ngOnChanges(): void {
    this.checkEnabledAllFlags();
  }

  ngOnInit() {
    this.selection.select(...this.control.value);

    this.subs.add(this.control.valueChanges.subscribe(value => {
      this.selection.clear();
      this.selection.select(...value);
    }));

    this.subs.add(
      this.selection.changed
        .subscribe(() => {
          this.checkEnabledAllFlags();
          this.control.setValue(this.selection.selected, { emitEvent: false });
          this.control.markAsDirty();
          this.control.markAsTouched();
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onCheckAllChange(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.selection.clear();
    } else {
      this.selection.select(...this.options.map(o => o.value));
    }
  }

  onCheckChange(value: string): void {
    this.selection.toggle(value);
  }

  private checkEnabledAllFlags(): void {
    if (!this.canSelectAll) {
      return;
    }

    const { length } = this.selection.selected;
    this.isAllChecked = length > 0 && length === this.options.length;
    this.isAllIndeterminate = length > 0 && length < this.options.length;
  }
}
