import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NavGroupExpandItem, NavItemToggleType } from '../../shared';

@Component({
  selector: 'uni-nav-new-group-expand-item',
  templateUrl: './uni-nav-group-expand-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavGroupExpandItemComponent {
  @Input() expandItem: NavGroupExpandItem;
  @Input() isGroupExpanded = false;

  @Output() toggleGroup = new EventEmitter<boolean>();

  readonly toggleType = NavItemToggleType.horizontal;

  get labelKey(): string {
    return this.isGroupExpanded
      ? this.expandItem?.expandedLabelKey
      : this.expandItem?.collapsedLabelKey;
  };

  onToggleGroup(): void {
    this.toggleGroup.emit();
  }
}
