import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'uni-value',
  templateUrl: './uni-value.component.html',
  styleUrls: ['./uni-value.component.scss'],
})
export class UniValueComponent {
  @HostBinding('class.is-display-inline') @Input() displayInline = false;
}
