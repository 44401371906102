<uni-modal
  maxWitdth="680px"
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-modal-content [margin]="false">
    <uni-section [isFirst]="true">
      {{ 'campaign.new.tip.content.upload' | translate }}
      <p>{{ 'campaign.new.tip.content.file' | translate }}</p>
    </uni-section>
    <uni-section>
      {{ 'campaign.new.tip.content.description' | translate }}
    </uni-section>
    <uni-section>
      <p [innerHTML]="'campaign.new.tip.content.messageType' | translate"></p>
    </uni-section>
    <div class="campaign-recipients-autocomplete-modal--medium">
      {{ 'campaign.new.tip.content.note_optional' | translate }}
    </div>
    <div class="campaign-recipients-autocomplete-modal--medium">
      {{ 'campaign.new.tip.content.note_download' | translate }}
    </div>
  </uni-modal-content>
</uni-modal>
