import { Component, ContentChildren, Input, QueryList, HostBinding } from '@angular/core';

import { UniLabelSuffixDirective } from '../../../uni-form/directives/label-suffix.directive';
import { UniLabelTipDirective } from '../../../uni-form/directives/label-tip.directive';

@Component({
  selector: 'uni-label',
  templateUrl: './uni-label.component.html',
  styleUrls: ['./uni-label.component.scss'],
})
export class UniLabelComponent {
  @Input() required = false;
  @HostBinding('class.is-display-inline') @Input() displayInline = false;
  @ContentChildren(UniLabelSuffixDirective) labelSuffix: QueryList<UniLabelSuffixDirective>;
  @ContentChildren(UniLabelTipDirective) labelTip: QueryList<UniLabelTipDirective>;
}
