import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { UniPipesModule } from '../../pipes/pipes.module';
import { UniFormModule } from '../uni-form';
import {
  UniCountriesAutocompleteComponent
} from './components/uni-countries-autocomplete/uni-countries-autocomplete.component';
import { UniCountryFlagComponent } from './components/uni-country-flag/uni-country-flag.component';
import { UniCountriesFacade } from './shared/uni-countries.facade';
import { UniCountriesRepository } from './shared/uni-countries.repository';
import { CountriesEffects, countriesReducer } from './store/uni-countries';

const components = [
  UniCountriesAutocompleteComponent,
  UniCountryFlagComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    UniFormModule,
    UniPipesModule,
    TranslateModule,
    StoreModule.forFeature('countries', countriesReducer),
    EffectsModule.forFeature([CountriesEffects])
  ],
  providers: [
    UniCountriesRepository,
    UniCountriesFacade,
  ],
})
export class UniCountriesModule { }
