export interface NavState {
  isNavOpen: boolean;
  isProductNavOpen: boolean;
  isProfileNavOpen: boolean;
}

export const initialState: NavState = {
  isNavOpen: false,
  isProductNavOpen: false,
  isProfileNavOpen: false,
};
