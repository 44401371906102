<div class="card-content">
  <div class="card-grid">
    <app-card-key-value-invert
      [message]="'ci.unishield.totalBlockedMessages' | translate"
      [value]="blocked"
    ></app-card-key-value-invert>
    <app-card-key-value-invert
      [message]="'ci.unishield.estimatedCostSavings' | translate"
      [value]="savings | currency:'USD'"
    ></app-card-key-value-invert>
  </div>
</div>
