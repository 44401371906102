<a *ngIf="!audioUrl && !isLoading"
  [href]=""
  target="_blank"
  (click)="fetchAudioFile()"
>
  <uni-icon
    name="play"
    type="fas"
  >
  </uni-icon>
</a>
<uni-icon *ngIf="isLoading"
  name="spinner fa-spin"
  [disabled]="true"
></uni-icon>
<uni-audio *ngIf="audioUrl"
  [src]="audioUrl"
  [displayInline]="true"
  [autoPlay]="true"
></uni-audio>
