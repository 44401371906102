import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../../shared';
import { Environment } from '../../../utils';
import { CreateOrderPayload, OrderDto, OrderPaymentMethod } from './uni-orders.model';
import { Observable } from 'rxjs';
import { ORDERS_KEY } from '../store/uni-orders.state';

@Injectable()
export class UniOrdersRepository {
  ucUrl = this.settingsService.settings?.ucUrl;
  ucApiUrl = this.ucUrl ? `${this.ucUrl}/api/v2` : `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/app/api/v2`;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
  ) {}

  createOrder(payload: CreateOrderPayload): Observable<OrderDto> {
    return this.http.post<OrderDto>(`${this.ucApiUrl}/orders`, payload, {
      headers: {
        queryName: 'createOrder',
        queryGroups: [ORDERS_KEY]
      }
    });
  }

  finalizeOrder(orderId: string, paymentMethod: OrderPaymentMethod): Observable<OrderDto> {
    return this.http.post<OrderDto>(`${this.ucApiUrl}/orders/${orderId}/finalize`, { paymentMethod }, {
      headers: {
        queryName: 'finalizeOrder',
        queryGroups: [ORDERS_KEY]
      }
    });
  }

  getOrder(orderId: string): Observable<OrderDto> {
    return this.http.get<OrderDto>(`${this.ucApiUrl}/orders/${orderId}`, {
      headers: {
        queryName: 'getOrder',
        queryGroups: [ORDERS_KEY]
      }
    });
  }
}
