    <span
        *ngIf="!!text || !!icon"
        class="uni-separator-cotnainer"
    >
        <uni-icon
            [name]="icon"
            [isRightSpace]="true"
        ></uni-icon>
        {{text}}
    </span>
