import * as moment from 'moment';
import { get } from 'lodash';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

import { DateUtils } from '../../../../utils';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';

@Component({
  selector: 'uni-table-filter-daterange',
  templateUrl: './uni-table-filter-daterange.component.html',
  styleUrls: ['./uni-table-filter-daterange.component.scss']
})
export class UniTableFilterDaterangeComponent implements OnInit {
  @Input() controlName: string;
  @Input() thead: HTMLElement;
  @Input() tableLeft: number;
  @Input() heading: string;
  @Input() isOpened = false;
  @Input() isQueryParams = true;
  @Input() validators = [];
  @Input() alignRight = false;
  @Input() after = '[gte]';
  @Input() before = '[lte]';
  @Input() isReversed = false;
  @Input() format = null;

  @Output() apply = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() open = new EventEmitter();

  form: FormGroup;
  search: ElementRef;
  timezone: string;
  controlNameBegin;
  controlNameEnd;

  @HostListener('click') onOpen() {
    this.isOpened = true;
    this.open.next();
  }

  get endDateControl(): FormControl {
    return this.form.get(this.getFilter(this.before, this.controlName)) as FormControl;
  }

  get startDateControl(): FormControl {
    return this.form.get(this.getFilter(this.after, this.controlName)) as FormControl;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uniAuthFacade: UniAuthFacade,
  ) { }

  ngOnInit() {
    this.timezone = this.uniAuthFacade.user.timezone;
    this.controlNameBegin = this.getFilter(this.after, this.controlName);
    this.controlNameEnd = this.getFilter(this.before, this.controlName);
    this.initForm();
    this.resetFilter();
  }

  getFilter(modifier: string, name: string) {
    const queryParam = [name, modifier];

    return (
      this.isReversed ?
        [...queryParam].reverse()
        : queryParam
      ).join('');
  }

  getDefaultValue(range: string = this.after): string {
    const paramName = this.getFilter(range, this.controlName);

    return this.activatedRoute.snapshot.queryParams[paramName]
      ?  moment(this.activatedRoute.snapshot.queryParams[paramName]).format()
      : '';
  }

  initForm(): void {
    this.form = new FormGroup({
      [this.controlNameBegin]: new FormControl(this.getDefaultValue(), this.validators),
      [this.controlNameEnd]: new FormControl(this.getDefaultValue(this.before), this.validators),
    });
  }

  getBeginValue(): string {
    const value = this.startDateControl.value;
    return !!value
      ? DateUtils.getTimezoneStartOfDay(value, this.timezone, this.format)
      : '';
  }

  getEndValue(): string {
    const value = this.endDateControl.value;
    return !!value
      ? DateUtils.getTimezoneEndOfDay(value, this.timezone, this.format)
      : '';
  }

  isValidationError(): boolean {
    return !!this.startDateControl.invalid
      && !!this.endDateControl.invalid;
  }

  isCalendarClicked(event) {
    return !!event && (event.target.classList
      .toString()
      .includes('mat-calendar') || event.target.id
      .includes('mat-calendar'));
  }

  onClear(): void {
    this.form.reset();
    this.onApply();
  }

  onApply(event = null): void {
    if (this.isCalendarClicked(event)) {
      return;
    }

    if (this.isValidationError()) {
      this.startDateControl.setValue('');
      this.endDateControl.setValue('');
      this.isOpened = false;
      return this.discard.next();
    }

    this.apply.next();
    this.isOpened = false;
    this.setQueryParams();
  }

  resetFilter(): void {
    this.activatedRoute.queryParams
      .pipe(debounceTime(100))
      .subscribe(params => {
        if (!get(params, this.controlNameBegin) && !get(params, this.controlNameEnd)) {
          this.onClear();
        }
      });
  }

  setQueryParams(): void {
    if (!this.isQueryParams) {
      return;
    }

    const paramBeginValue = this.activatedRoute.snapshot.queryParams[this.controlNameBegin];
    const paramEndValue = this.activatedRoute.snapshot.queryParams[this.controlNameEnd];
    const controlBeginValue = this.getBeginValue();
    const controlEndValue = this.getEndValue();

    if ((paramBeginValue === controlBeginValue && paramEndValue === controlEndValue)
      || (!paramEndValue && !controlEndValue)
      || (!paramBeginValue && !controlBeginValue)
      || controlBeginValue === 'Invalid date'
      || controlEndValue === 'Invalid date'
    ) {
      return;
    }

    this.router.navigate([], {
      queryParams: {
        page: 1,
        [this.controlNameBegin]: !!controlBeginValue ? controlBeginValue : undefined,
        [this.controlNameEnd]: !!controlEndValue ? controlEndValue : undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  onDiscard(): void {
    this.isOpened = false;
    this.startDateControl.setValue(this.getDefaultValue());
    this.endDateControl.setValue(this.getDefaultValue(this.before));
    this.discard.next();
  }
}
