import { Component, Input, HostBinding } from '@angular/core';

export enum SpaceDirection {
  vertical = 'vertical',
  bottom = 'bottom',
  top = 'top',
}

export enum SpaceSizes {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

@Component({
  selector: 'uni-space-container',
  templateUrl: './uni-space-container.component.html',
  styleUrls: ['./uni-space-container.component.scss']
})
export class UniSpaceContainerComponent {
  @Input() class = '';
  @Input() size: SpaceSizes = SpaceSizes.big;
  @Input() direction: SpaceDirection = SpaceDirection.bottom;
  @HostBinding('class') get getClasses(): string {
    return `${ this.class } uni-space-container__direction--${ this.direction } uni-space-container__size--${ this.size }`;
  }
}
