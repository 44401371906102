<uni-form-field
  [margin]="false"
  [disable]="false"
>
  <div
    [formGroup]="form"
    [ngClass]="{ 'is-summary': isSummary }"
    class="uni-input-price"
  >
    <input
      class="unifonic-spa"
      type="text"
      [placeholder]="translatedPlaceholder"
      [formControlName]="fieldName"
      [keyPattern]="keyPattern"
      uniInput
    >
  </div>
</uni-form-field>
