import { Action } from '@ngrx/store';

import { UniSnackbarStatus, UniSnackbarAction } from '../components/uni-snackbar/uni-snackbar.model';

export const NEW_SNACKBAR = '[Snackbar] NEW_SNACKBAR';
export const SHOW_SNACKBAR = '[Snackbar] SHOW_SNACKBAR';
export const HIDE_SNACKBAR = '[Snackbar] HIDE_SNACKBAR';

export class NewSnackbar implements Action {
  readonly type = NEW_SNACKBAR;

  constructor(
    public status: UniSnackbarStatus,
    public message: string,
    public property?: object,
    public action?: UniSnackbarAction,
  ) { }
}

export class ShowSnackbar implements Action {
  readonly type = SHOW_SNACKBAR;

  constructor(
    public status: UniSnackbarStatus,
    public message: string,
    public property?: object,
    public action?: UniSnackbarAction,
  ) { }
}

export class HideSnackbar implements Action {
  readonly type = HIDE_SNACKBAR;

  constructor() { }
}
export type All =
  | NewSnackbar
  | ShowSnackbar
  | HideSnackbar;
