export interface Operator {
  id: string;
  name: string;
}

export interface Operators {
  [operatorName: string]: number;
}

export enum OperatorsFilters {
  countryId = 'countryId',
}

export type OperatorsParams = {
  [K in OperatorsFilters]?: number | string;
};
