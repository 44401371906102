import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TimezonesState } from './uni-timezones.state';

export const selectTimezonesFeature = createFeatureSelector<TimezonesState>('timezones');

export const selectTimezones = createSelector(
  selectTimezonesFeature,
  (state: TimezonesState) => state.timezones,
);

export const selectTimezonesQuery = createSelector(
  selectTimezonesFeature,
  (state: TimezonesState) => state.getTimezonesQuery,
);
