import { Params } from '@angular/router';
import { Action } from '@ngrx/store';

export const SET_PAGE = '[PAGINATION] SET_PAGE';

export class SetPage implements Action {
  readonly type = SET_PAGE;
  constructor(public params: Params) {}
}

export type All =
  | SetPage;
