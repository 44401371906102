import { Component, Input } from '@angular/core';

// TODO: (mziaja) try to refactor this component with UniPriceModule
@Component({
  selector: 'uni-price-box',
  templateUrl: './uni-price-box.component.html',
  styleUrls: ['./uni-price-box.component.scss'],
})
export class UniPriceBoxComponent {
  @Input() currency: string;
}
