import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Country, Countries  } from './uni-countries.model';
import { COUNTRIES_KEY } from '../store/uni-countries/uni-countries.state';

@Injectable()
export class UniCountriesRepository {
  apiUrl = `app/api/v2`;

  constructor(private http: HttpClient) {}

  getCountries(isPublic = false): Observable<Country[]>  {
    const url = isPublic
      ? `${this.apiUrl}/public/accounts/countries`
      : `${this.apiUrl}/countries`;

    return this.http.get<Country[]>(url);
  }

  getCountriesChoice(): Observable<Countries> {
    return this.http.get<Countries>(`${this.apiUrl}/countries/list/choice`, {
      headers: {
        queryName: 'getCountriesChoice',
        queryGroups: [COUNTRIES_KEY]
      }
    });
  }
}
