import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { UniFormModule } from '../uni-form/uni-form.module';
import { UniRolesSelectComponent } from './components/uni-roles-select/uni-roles-select.component';
import { UniRolesRadioComponent } from './components/uni-roles-radio/uni-roles-radio.component';
import { UniRolesFacade } from './shared/uni-roles.facade';
import { UniRolesRepository } from './shared/uni-roles.repository';
import { UniRolesEffects, uniRolesReducer } from './store';
import { UniBoxModule } from '../uni-box';
import { UniRichRadioButtonModule } from '../uni-rich-radio-button/uni-rich-radio-button.module';

const components = [
  UniRolesSelectComponent,
  UniRolesRadioComponent,
];

const modules = [
  CommonModule,
  UniRichRadioButtonModule,
  UniBoxModule,
  UniFormModule,
  TranslateModule,
  StoreModule.forFeature('uniRoles', uniRolesReducer),
  EffectsModule.forFeature([UniRolesEffects])
];

@NgModule({
  imports: [...modules],
  declarations: [...components],
  exports: [...components],
  providers: [
    UniRolesRepository,
    UniRolesFacade,
  ]
})
export class UniRolesModule { }
