import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IndustryState } from './uni-industry.state';

export const selectIndustryFeature = createFeatureSelector<IndustryState>('industry');

export const selectIndustries = createSelector(
  selectIndustryFeature,
  (state: IndustryState) => state.industries,
);

export const selectIndustriesQuery = createSelector(
  selectIndustryFeature,
  (state: IndustryState) => state.getIndustriesQuery,
);
