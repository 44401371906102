import { v1 as _uuid } from 'uuid';

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UniFormService } from '../../uni-form/shared/uni-form.service';

import { CampaignDataSource, CampaignRecipientsMethod } from './uni-campaign.model';
import { UniCampaignsPermissionsService } from './uni-campaign-permissions.service';

const uuid = _uuid;

@Injectable()
export class UniCampaignFormFactory {
  form: FormGroup;
  recipientsLimit = 50000000;
  manualRecipientsLimit = 4000;

  constructor(
    private formBuilder: FormBuilder,
    private uniFormService: UniFormService,
    private uniCampaignPermissions: UniCampaignsPermissionsService,
  ) {
    this.form = this.build();
  }

  build(): FormGroup {
    return this.formBuilder.group({
      id: [uuid(), [Validators.required]],
      step: [1],
      nameForm: this.getNameForm(),
    });
  }

  getNameForm(): FormGroup {
    return this.formBuilder.group({
      name: [null, this.uniFormService.getCampaignValidators()],
    });
  }

  getContentForm() {
    return this.formBuilder.group({
      type: [CampaignDataSource.custom, [Validators.required]],
      isAutocompleteActive: [false, Validators.required],
      autocomplete: [''],
    });
  }

  addContentForm(form: FormGroup) {
    form.addControl('contentForm', this.getContentForm());
  }

  addCustomControls(form: FormGroup, isFileRecipients?: boolean, isGroupsRecipients?: boolean) {
    const contentForm = form.get('contentForm') as FormGroup;

    if (!contentForm) {
      return;
    }

    contentForm.addControl('senderName', new FormControl('', [ Validators.required, this.uniFormService.objectValidator ]));
    contentForm.addControl('recipients', this.buildRecipients(isFileRecipients, isGroupsRecipients));
    contentForm.addControl('method', new FormControl(CampaignRecipientsMethod.manually));
    contentForm.addControl('templateName', new FormControl(''));
    contentForm.addControl('content', new FormControl('', Validators.required));
  }

  clearCustomControls(form: FormGroup) {
    const contentForm = form.get('contentForm') as FormGroup;

    if (!contentForm) {
      return;
    }

    contentForm.removeControl('senderName');
    contentForm.removeControl('recipients');
    contentForm.removeControl('method');
    contentForm.removeControl('templateName');
    contentForm.removeControl('content');
    contentForm.removeControl('messageType');
  }

  addMessageTypeControl(form: FormGroup) {
    const contentForm = form.get('contentForm') as FormGroup;

    if (!contentForm) {
      return;
    }

    contentForm.addControl('messageType', new FormControl('',Validators.required));
  }

  clearMessageTypeControl(form: FormGroup) {
    const contentForm = form.get('contentForm') as FormGroup;

    if (!contentForm) {
      return;
    }

    contentForm.removeControl('messageType');
  }

  addFileToSMSControls(form: FormGroup) {
    const contentForm = form.get('contentForm') as FormGroup;

    if (!contentForm) {
      return;
    }

    contentForm.addControl('file', new FormControl(''));
    contentForm.addControl('fileName', new FormControl(''));
    contentForm.addControl('fileResponse', new FormControl('', Validators.required));
  }

  clearFileToSMSControls(form: FormGroup) {
    const contentForm = form.get('contentForm') as FormGroup;

    if (!contentForm) {
      return;
    }

    contentForm.removeControl('file');
    contentForm.removeControl('fileName');
    contentForm.removeControl('fileResponse');
  }

  addDatesForm(form: FormGroup, canDirectSendCampaign: boolean = false) {
    const isScheduled = !(canDirectSendCampaign || this.uniCampaignPermissions.isVoice);
    const datesForm = this.formBuilder.group({
      isScheduled: [isScheduled, [Validators.required]],
      date: this.formBuilder.group({
        date: [],
        time: [],
      }),
    });

    form.addControl('datesForm', datesForm);
  }

  buildRecipients(isFileRecipients: boolean, isGroupsRecipients: boolean): FormGroup {
    const form = this.formBuilder.group({});

    if (isFileRecipients) {
      this.fileRecipients(form);
    } else if (isGroupsRecipients) {
      this.groupsRecipients(form);
    } else {
      this.customRecipients(form);
    }

    return form;
  }

  customRecipients(form: FormGroup): void {
    form.addControl('phoneNumbersInput', new FormControl(''));
    form.addControl('phoneNumbers', new FormControl('', Validators.required));
    form.removeControl('file');
    form.removeControl('campaignFile');
    form.removeControl('fileRecipients');
    form.removeControl('groupsInput');
    form.removeControl('audienceGroups');
    form.removeControl('recipientsFileId');
  }

  fileRecipients(form: FormGroup): void {
    form.addControl('recipientsFileId', new FormControl(''));
    form.addControl('file', new FormControl('', Validators.required));
    form.addControl('campaignFile', new FormGroup({}));
    form.addControl('searchGroupsInput', new FormControl(''));
    (form.get('campaignFile') as FormGroup).addControl('id', new FormControl('', Validators.required));
    form.addControl('fileRecipients', new FormControl('', Validators.required));
    form.removeControl('phoneNumbersInput');
    form.removeControl('phoneNumbers');
    form.removeControl('groupsInput');
    form.removeControl('audienceGroups');
  }

  groupsRecipients(form: FormGroup): void {
    form.addControl('searchGroupsInput', new FormControl(''));
    form.addControl('groupsInput', new FormControl(''));
    form.addControl('audienceGroups', new FormControl('', Validators.required));
    form.removeControl('file');
    form.removeControl('campaignFile');
    form.removeControl('fileRecipients');
    form.removeControl('phoneNumbersInput');
    form.removeControl('phoneNumbers');
  }

  buildTemplateForm(): FormGroup {
    return this.formBuilder.group({
      templateName: [''],
      newTemplateName: ['', [Validators.maxLength(25), Validators.required]],
    });
  }

  buildStatusForm(id: String): FormGroup {
    return this.formBuilder.group({
      id: [id],
      status: ['',[Validators.required]],
    });
  }
}
