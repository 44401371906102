import { NgModule } from '@angular/core';

import { UniTextComponent } from './components/uni-text/uni-text.component';
import { UniTextCaptionComponent } from './components/uni-text-caption/uni-text-caption.component';
import { UniTextBodyComponent } from './components/uni-text-body/uni-text-body.component';
import { UniTextSubheaderComponent } from './components/uni-text-subheader/uni-text-subheader.component';
import { UniTextHeaderComponent } from './components/uni-text-header/uni-text-header.component';
import { UniTextInfoComponent } from './components/uni-text-info/uni-text-info.component';
import { UniTextResultComponent } from './components/uni-text-result/uni-text-result.component';
import { UniIconModule } from '../uni-icon/uni-icon.module';

const components = [
  UniTextComponent,
  UniTextCaptionComponent,
  UniTextBodyComponent,
  UniTextHeaderComponent,
  UniTextSubheaderComponent,
  UniTextInfoComponent,
  UniTextResultComponent,
];

@NgModule({
  imports: [UniIconModule],
  declarations: [...components],
  exports: [...components]
})
export class UniTextModule { }
