import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpQueryReducer } from './store/http-query.reducer';
import { QUERY_STORE_KEY } from './store/http-query.state';
import { HttpQueryFacade } from './store/http-query.facade';
import { HttpQuerySessionInterceptor } from './http-query-session.interceptor';
import { HttpQueryInterceptor } from './http-query.interceptor';

@NgModule({
  imports: [
    StoreModule.forFeature(QUERY_STORE_KEY, HttpQueryReducer),
  ],
  providers: [
    HttpQueryFacade,
    { provide: HTTP_INTERCEPTORS, useClass: HttpQuerySessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpQueryInterceptor, multi: true },
  ]
})
export class HttpQueryModule {}
