import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  selectAccounts,
  selectAccountsChoice,
  selectAccountsQuery,
  selectAllAccounts,
  selectAllAccountsQuery,
  selectSubAccounts,
  selectSubAccountsQuery
} from '../store/uni-data.selectors';
import { UniDataState } from '../store/uni-data.state';
import * as UniDataActions from '../store/uni-data.actions';
import { BehaviorSubject } from 'rxjs';
import { isQueryInProgress$ } from '../../../store';
import { mergeMap } from 'rxjs/operators';
import { GetAccountsParams } from '../model';

@Injectable({ providedIn: 'root' })
export class AccountsFacade {
  accounts$ = this.store.pipe(select(selectAccounts));
  accountsQuery$ = this.store.pipe(select(selectAccountsQuery));
  allAccounts$ = this.store.pipe(select(selectAllAccounts));
  allAccountsQuery$ = this.store.pipe(select(selectAllAccountsQuery));
  subAccounts$ = this.store.pipe(select(selectSubAccounts));
  subAccountsQuery$ = this.store.pipe(select(selectSubAccountsQuery));
  accountsChoice$ = this.store.pipe(select(selectAccountsChoice));

  loader$ = new BehaviorSubject(isQueryInProgress$([
    this.accountsQuery$,
    this.allAccountsQuery$,
    this.subAccountsQuery$
  ]));

  isLoading$ = this.loader$.pipe(mergeMap(isActive => isActive));

  constructor(private store: Store<UniDataState>) {}

  setAccounts(params?: GetAccountsParams): void {
    this.store.dispatch(new UniDataActions.SetAccounts(params));
  }

  setAllAccounts(params = {}): void {
    this.store.dispatch(new UniDataActions.SetAllAccounts(params));
  }

  setSubAccounts(): void {
    this.store.dispatch(new UniDataActions.SetSubAccounts());
  }

  setAccountsChoice(): void {
    this.store.dispatch(new UniDataActions.SetAccountsChoice());
  }

  putAccountTroubleshootingMode(accountId: string, isEnabled: boolean): void {
    this.store.dispatch(new UniDataActions.PutAccountTroubleshootingMode(accountId, isEnabled));
  }
}
