import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CampaignDataSource } from '../../shared/uni-campaign.model';
import { UniCampaignFormFactory } from '../../shared/uni-campaign-form.factory';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'cc-campaign-content-type',
  templateUrl: './campaign-content-type.component.html',
  styleUrls: ['./campaign-content-type.component.scss'],
})
export class CampaignContentTypeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() isDisabled = false;
  campaignDataSource = CampaignDataSource;
  isModalActive = false;

  get contentForm(): FormGroup {
    return this.form.get('contentForm') as FormGroup;
  }

  get typeControl(): FormControl {
    return this.contentForm.get('type') as FormControl;
  }

  get fileIdControl(): FormControl {
    return this.contentForm.get('recipients.campaignFile.id') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.contentForm.get('recipients.groups') as FormControl;
  }

  constructor(
    private campaignFormFactory: UniCampaignFormFactory
  ) {}

  ngOnInit() {
    this.typeControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value === this.campaignDataSource.fileToSMS) {
          this.campaignFormFactory.clearCustomControls(this.form);
          this.campaignFormFactory.addFileToSMSControls(this.form);
        }

        if (value === this.campaignDataSource.custom) {
          const isFileRecipients = !!this.fileIdControl;
          const isGroupsRecipients = !!this.groupsControl;
          this.campaignFormFactory.clearFileToSMSControls(this.form);
          this.campaignFormFactory.addCustomControls(this.form, isFileRecipients, isGroupsRecipients);
        }

        this.contentForm.updateValueAndValidity();
      });
  }

  toggleModal(): void {
    this.isModalActive = !this.isModalActive;
  }
}
