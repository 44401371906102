export enum UniTableDetailType {
  string = 'string',
  date = 'date',
  integer = 'integer',
  boolean = 'boolean',
  file = 'file',
  dropdown = 'drop-down',
  audio = 'audio',
}

export interface UniTableDetail {
  name: string;
  label: string;
  value: any;
  type: UniTableDetailType;
}

export enum UniTableDetailName {
  submittedAt = 'submitted_at',
}
