import { Component } from '@angular/core';
import { get } from 'lodash';

import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';

@Component({
  selector: 'uni-top-bar-timezone',
  templateUrl: './uni-top-bar-timezone.component.html',
  styleUrls: ['./uni-top-bar-timezone.component.scss']
})
export class UniTopBarTimezoneComponent {
  account$ = this.uniAuthFacade.account$;

  get userTimezone(): string {
    return get(this.uniAuthFacade.user, 'timezone');
  }

  constructor(
    private uniAuthFacade: UniAuthFacade,
  ) {}
}
