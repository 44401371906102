import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-form-container',
  templateUrl: './uni-form-container.component.html',
  styleUrls: ['./uni-form-container.component.scss']
})
export class UniFormContainerComponent {
  @HostBinding('class.uni-form-container--wide') @Input() isWide = false;
  @HostBinding('class.uni-form-container--flex') @Input() isFlex = false;
}
