import { get } from 'lodash';
import { Component, Input } from '@angular/core';
import { Campaign, CampaignStatus } from '../../shared/uni-campaign.model';
import { DateUtils } from '../../../../utils/date.utils';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { reduceCampaignStatus } from '../../shared';

@Component({
  selector: 'cc-campaign-overview',
  templateUrl: './campaign-overview.component.html',
})
export class CampaignOverviewComponent {
  @Input() campaign: Campaign;
  campaignStatus = CampaignStatus;
  dateUtils = DateUtils;

  constructor(private campaignsFacade: UniCampaignsFacade) {}

  getCampaignFile(campaign: Campaign) {
    const id = get(campaign, 'recipients.campaignFile.id');
    const originalName = get(campaign, 'recipients.campaignFile.file.originalName');

    if (!id) {
      return;
    }

    this.campaignsFacade.setCampaignFile(id, originalName);
  }

  getStatus(status: string): string {
    return reduceCampaignStatus(status);
  }
}
