<input class="clipboard__input" type="text" [value]="text" #userinput>
{{ displayText() }}
<div *ngIf="isMoreIcon">
  <div
    *ngIf="isExpandable else isExpandableContainer"
    class="clipboard__more"
    (click)="toggleText()"
  >
    <ng-container *ngIf="isActive else isInActive">
      <uni-icon class="clipboard__more-icon" name="arrow-alt-to-left"></uni-icon>
    </ng-container>
    <ng-template #isInActive>
      (...)
    </ng-template>
  </div>
  <ng-template #isExpandableContainer>
    ...
  </ng-template>
</div>
<uni-icon
  class="clipboard__icon"
  *ngIf="isIcon && text"
  (click)="copyInputMessage(userinput)"
  name="copy"
></uni-icon>
