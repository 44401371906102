import { Environments, Products } from './uni-nav.model';

// TODO: This hard-coded domains approach is temporary until a better solution is found.
export const productDomains: Record<Environments, Record<Products, string>> = {
    [Environments.local]: {
        [Products.uc]: 'unifonic.local',
        [Products.cc]: 'communication.unifonic.local',
        [Products.ulink]: 'ulink.unifonic.local:4200',
        [Products.flow]: 'localflow.integration.dev.unifonic.com',
        [Products.webhook]: 'localwebhook.integration.dev.unifonic.com',
        [Products.integrations]: 'localintegrations.integration.dev.unifonic.com',
        [Products.audience]: 'localaudience.integration.dev.unifonic.com',
        [Products.catalog]: 'localcatalog.integration.dev.unifonic.com',
        [Products.push]: 'push.unifonic.local:4200',
        [Products.conversation]: 'conversation.integration.dev.unifonic.com',
        [Products.campaign]: 'communication.unifonic.local',
        [Products.notice]: 'notice.unifonic.local:4200',
        [Products.authenticate]: 'authenticate.unifonic.local:4200',
        [Products.ci]: '0.0.0.0:4200'
    },
    [Environments.integration]: {
        [Products.uc]: 'integration.dev.unifonic.com',
        [Products.cc]: 'communication.integration.dev.unifonic.com',
        [Products.ulink]: 'ulink.integration.dev.unifonic.com',
        [Products.flow]: 'flow.integration.dev.unifonic.com',
        [Products.webhook]: 'webhook.integration.dev.unifonic.com',
        [Products.integrations]: 'integrations.integration.dev.unifonic.com',
        [Products.audience]: 'audience.integration.dev.unifonic.com',
        [Products.catalog]: 'catalog.integration.dev.unifonic.com',
        [Products.push]: 'push.integration.dev.unifonic.com',
        [Products.conversation]: 'conversation.integration.dev.unifonic.com',
        [Products.campaign]: 'campaign.integration.dev.unifonic.com',
        [Products.notice]: 'notice.integration.dev.unifonic.com',
        [Products.authenticate]: 'authenticate.integration.dev.unifonic.com',
        [Products.ci]: 'insights.integration.dev.unifonic.com'
    },
    [Environments.bravo]: {
      [Products.uc]: 'bravo.dev.unifonic.com',
      [Products.cc]: 'communication.bravo.dev.unifonic.com',
      [Products.ulink]: 'ulink.integration.dev.unifonic.com',
      [Products.flow]: 'flow.integration.dev.unifonic.com',
      [Products.webhook]: 'webhook.integration.dev.unifonic.com',
      [Products.integrations]: 'integrations.integration.dev.unifonic.com',
      [Products.audience]: 'audience.integration.dev.unifonic.com',
      [Products.catalog]: 'catalog.integration.dev.unifonic.com',
      [Products.push]: 'push.integration.dev.unifonic.com',
      [Products.conversation]: 'conversation.integration.dev.unifonic.com',
      [Products.campaign]: 'communication.integration.dev.unifonic.com',
      [Products.notice]: 'notice.integration.dev.unifonic.com',
      [Products.authenticate]: 'authenticate.integration.dev.unifonic.com',
      [Products.ci]: 'insights.integration.dev.unifonic.com'
    },
    [Environments.charlie]: {
        [Products.uc]: 'charlie.dev.unifonic.com',
        [Products.cc]: 'communication.charlie.dev.unifonic.com',
        [Products.ulink]: 'ulink.integration.dev.unifonic.com',
        [Products.flow]: 'flow.integration.dev.unifonic.com',
        [Products.webhook]: 'webhook.integration.dev.unifonic.com',
        [Products.integrations]: 'integrations.integration.dev.unifonic.com',
        [Products.audience]: 'audience.integration.dev.unifonic.com',
        [Products.catalog]: 'catalog.integration.dev.unifonic.com',
        [Products.push]: 'push.integration.dev.unifonic.com',
        [Products.conversation]: 'conversation.charlie.dev.unifonic.com',
        [Products.campaign]: 'campaign.integration.dev.unifonic.com',
        [Products.notice]: 'notice.integration.dev.unifonic.com',
        [Products.authenticate]: 'authenticate.integration.dev.unifonic.com',
        [Products.ci]: 'insights.integration.dev.unifonic.com'
    },
    [Environments.preprod]: {
        [Products.uc]: 'preprod.cloud.unifonic.com',
        [Products.cc]: 'communication.preprod.cloud.unifonic.com',
        [Products.ulink]: 'ulink.preprod.cloud.unifonic.com',
        [Products.flow]: 'flow.preprod.cloud.unifonic.com',
        [Products.webhook]: 'webhook.preprod.cloud.unifonic.com',
        [Products.integrations]: 'integrations.preprod.cloud.unifonic.com',
        [Products.audience]: 'audience.preprod.cloud.unifonic.com',
        [Products.catalog]: 'catalog.preprod.cloud.unifonic.com',
        [Products.push]: 'push.preprod.cloud.unifonic.com',
        [Products.conversation]: 'conversation.preprod.cloud.unifonic.com',
        [Products.campaign]: 'campaign.preprod.cloud.unifonic.com',
        [Products.notice]: 'notice.preprod.cloud.unifonic.com',
        [Products.authenticate]: 'authenticate.preprod.cloud.unifonic.com',
        [Products.ci]: 'insights.preprod.cloud.unifonic.com'
    },
    [Environments.preprodOci]: {
        [Products.uc]: 'preprod.oci.ksa.cloud.unifonic.com',
        [Products.cc]: 'communication.preprod.oci.ksa.cloud.unifonic.com',
        [Products.ulink]: 'ulink.preprod.cloud.unifonic.com',
        [Products.flow]: 'flow.preprod.cloud.unifonic.com',
        [Products.webhook]: 'webhook.preprod.cloud.unifonic.com',
        [Products.integrations]: 'integrations.preprod.cloud.unifonic.com',
        [Products.audience]: 'audience.preprod.cloud.unifonic.com',
        [Products.catalog]: 'catalog.preprod.cloud.unifonic.com',
        [Products.push]: 'push.preprod.cloud.unifonic.com',
        [Products.conversation]: 'conversation.preprod.cloud.unifonic.com',
        [Products.campaign]: 'campaign.preprod.oci.ksa.cloud.unifonic.com',
        [Products.notice]: 'notice.preprod.cloud.unifonic.com',
        [Products.authenticate]: 'authenticate.preprod.cloud.unifonic.com',
        [Products.ci]: 'insights.preprod.oci.ksa.cloud.unifonic.com'
    },
    [Environments.prod]: {
        [Products.uc]: 'cloud.unifonic.com',
        [Products.cc]: 'communication.cloud.unifonic.com',
        [Products.ulink]: 'ulink.cloud.unifonic.com',
        [Products.flow]: 'flow.cloud.unifonic.com',
        [Products.webhook]: 'webhook.cloud.unifonic.com',
        [Products.integrations]: 'integrations.cloud.unifonic.com',
        [Products.audience]: 'audience.cloud.unifonic.com',
        [Products.catalog]: 'catalog.cloud.unifonic.com',
        [Products.push]: 'push.cloud.unifonic.com',
        [Products.conversation]: 'conversation.cloud.unifonic.com',
        [Products.campaign]: 'campaign.cloud.unifonic.com',
        [Products.notice]: 'notice.cloud.unifonic.com',
        [Products.authenticate]: 'authenticate.cloud.unifonic.com',
        [Products.ci]: 'insights.cloud.unifonic.com'
    },
    [Environments.prodOci]: {
        [Products.uc]: 'oci.ksa.cloud.unifonic.com',
        [Products.cc]: 'communication.oci.ksa.cloud.unifonic.com',
        [Products.ulink]: 'ulink.cloud.unifonic.com',
        [Products.flow]: 'flow.cloud.unifonic.com',
        [Products.webhook]: 'webhook.cloud.unifonic.com',
        [Products.integrations]: 'integrations.cloud.unifonic.com',
        [Products.audience]: 'audience.cloud.unifonic.com',
        [Products.catalog]: 'catalog.cloud.unifonic.com',
        [Products.push]: 'push.cloud.unifonic.com',
        [Products.conversation]: 'conversation.cloud.unifonic.com',
        [Products.campaign]: 'campaign.oci.ksa.cloud.unifonic.com',
        [Products.notice]: 'notice.cloud.unifonic.com',
        [Products.authenticate]: 'authenticate.cloud.unifonic.com',
        [Products.ci]: 'insights.oci.ksa.cloud.unifonic.com'
    },
};
