import { Injectable } from '@angular/core';
import { values } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UniTableFilterOption } from './uni-table-filter.model';

@Injectable({
  providedIn: 'root',
})
export class UniTableService {
  constructor(private translate: TranslateService) {}

  getFilterOptions = (data: object,filterKeys: String = 'tableFilter'): UniTableFilterOption[] => {
    return values(data).map(item => ({
      id: item,
      name: this.translate.instant(`${filterKeys}.${item}`),
    }));
  };
}
