import { get } from 'lodash';
import { Campaign, CampaignMessageStatus } from '../../shared/uni-campaign.model';

export const failedCounter = (campaign: Campaign): number => {
  const messagesCounters = get(campaign, 'messagesCounters') || [];
  let counter = 0;

  messagesCounters.map(item => {
    switch (item.messagesStatus) {
      case CampaignMessageStatus.rejected: {
        counter += item.count;
        return;
      }
      default: {
        return;
      }
    }
  });

  return counter;
};

export const succeedCounter = (campaign: Campaign): number => {
  const messagesCounters = get(campaign, 'messagesCounters') || [];
  let counter = 0;

  messagesCounters.map(item => {
    switch (item.messagesStatus) {
      case CampaignMessageStatus.successful:
      case CampaignMessageStatus.processed: {
        counter += item.count;
        return;
      }
      default: {
        return;
      }
    }
  });

  return counter;
};

export const createdCounter = (campaign: Campaign): number => {
  const messagesCounters = get(campaign, 'messagesCounters') || [];
  const sentCounter = messagesCounters.find(item => item.messagesStatus === CampaignMessageStatus.created);
  return get(sentCounter, 'count') || 0;
};

export const progressCounter = (failed: number, succeed: number): number => {
  return failed + succeed;
};

export const getPercentage = (value: number, total: number): number => {
  return total > 0 ? value / total : 0;
};

export const getTotal = (value: number, total: number): string => {
  return '(' + value + '/' + total + ')';
};

export const getTotalPercentage = (campaign: Campaign): number => {
  const failedCount = failedCounter(campaign);
  const succeedCount = succeedCounter(campaign);
  const progressCount = progressCounter(failedCount, succeedCount);

  return getPercentage(progressCount, campaign.recipientsCount);
};

export const getSendingPercentage = (campaign: Campaign): number => {
  const succedCount = succeedCounter(campaign);
  const failedCount = failedCounter(campaign);
  const recipients = get(campaign, 'recipientsCount');
  const total = succedCount + failedCount;

  return (total && recipients) ? (total / recipients) : 0;
};
