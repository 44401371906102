import { Action } from '@ngrx/store';

import { Country, Countries } from '../../shared/uni-countries.model';

export const SET_COUNTRIES = '[Countries] SET_COUNTRIES';
export const SET_COUNTRIES_SUCCESS = '[Countries] SET_COUNTRIES_SUCCESS';

export const SET_COUNTRIES_CHOICE = '[Countries] SET_COUNTRIES_CHOICE';
export const SET_COUNTRIES_CHOICE_SUCCESS = '[Countries] SET_COUNTRIES_CHOICE_SUCCESS';

export class SetCountries implements Action {
  readonly type = SET_COUNTRIES;

  constructor(public isPublic: boolean) { }
}

export class SetCountriesSuccess implements Action {
  readonly type = SET_COUNTRIES_SUCCESS;

  constructor(public response: Country[]) { }
}

export class SetCountriesChoice implements Action {
  readonly type = SET_COUNTRIES_CHOICE;

  constructor() { }
}

export class SetCountriesChoiceSuccess implements Action {
  readonly type = SET_COUNTRIES_CHOICE_SUCCESS;

  constructor(public response: Countries) { }
}

export type All =
  | SetCountries
  | SetCountriesSuccess
  | SetCountriesChoice
  | SetCountriesChoiceSuccess;
