import { Subject } from 'rxjs';

import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { UniTextWeight } from '../../../uni-text/shared/uni-text.model';

@Component({
  selector: 'uni-cell',
  templateUrl: './uni-cell.component.html',
  styleUrls: ['./uni-cell.component.scss'],
})
export class UniCellComponent {
  changedId = new Subject<string>();
  uniTextWeight = UniTextWeight;
  @Input() value = '';

  @HostBinding('class.is-active') @Input() isActive = false;

  @HostListener('click') onClick() {
    this.changedId.next(this.value);
  }

  setStatus(status: boolean) {
    this.isActive = status;
  }
}
