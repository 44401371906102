import { Subscription } from 'rxjs';

import { Component, HostBinding, OnInit } from '@angular/core';

import { AutoUnsubscribe } from '../../../../utils';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';
import { filter, map } from 'rxjs/operators';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';

@Component({
  selector: 'uni-top-bar',
  templateUrl: './uni-top-bar.component.html',
  styleUrls: ['./uni-top-bar.component.scss'],
})
export class UniTopBarComponent extends AutoUnsubscribe implements OnInit {

  public flagsFetched$ = this.uniAuthFacade.featureFlags$.pipe(
    filter(featureFlags => !!featureFlags),
  );

  public newDesignEnabled$ = this.flagsFetched$.pipe(
    map(() => this.uniFeatureFlagService.getPermission(FeatureFlagKeys.new_design_2022_q_1)
  ));

  public logoUrl$ = this.newDesignEnabled$.pipe(
    map(newDesignEnabled => newDesignEnabled ? '/assets/images/unifonic_logo_white-new.png': '')
  );

  isProductNavOpen = false;
  isProfileNavOpen = false;
  isNavOpen = false;

  @HostBinding('class.is-admin')
  get isAdminLevel(): boolean {
    return this.uniAuthFacade.isAdminLevel();
  }

  @HostBinding('class.is-nav-open')
  get isNavOpenClass(): boolean {
    return this.isNavOpen;
  }

  get isImpresonationSession(): boolean {
    return this.uniAuthFacade.isImpresonationSession();
  }

  get isBalance(): boolean {
    return this.uniAuthFacade.isBalance();
  }

  constructor(
    protected uniNavFacade: UniNavFacade,
    protected uniAuthFacade: UniAuthFacade,
    protected uniFeatureFlagService: UniFeatureFlagsService
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.subscibeProductsNavToggle(),
      this.subscibeNavToggle(),
      this.subscibeProfileNavToggle(),
    );
  }

  subscibeProductsNavToggle(): Subscription {
    return this.uniNavFacade.isProductNavOpen$.subscribe(state => {
      this.isProductNavOpen = state;
      this.uniNavFacade.setNavState(false);
    });
  }

  subscibeNavToggle(): Subscription {
    return this.uniNavFacade.isNavOpen$.subscribe(state => {
      this.isNavOpen = state;
    });
  }

  subscibeProfileNavToggle(): Subscription {
    return this.uniNavFacade.isProfileNavOpen$.subscribe(state => {
      this.isProfileNavOpen = state;
    });
  }

  onChange(value: boolean): void {
    this.uniNavFacade.setProfileNavState(value);
  }

  onOpenProfileNav(value: boolean): void {
    this.uniNavFacade.setProfileNavState(value);
    this.uniNavFacade.setNavState(false);
    this.uniNavFacade.setProductNavState(false);
  }
}
