import * as moment from 'moment-timezone';
import { times } from './campaign-time.repository';

export const getSmsRoundedTimes = (now: moment.Moment): string[] => {
  return getRoundedTimes(now, times);
};

export const getVoiceRoundedTimes = (timezone: string, now: moment.Moment, isTodaySelected: boolean): string[] => {

  const userTimezoneOffset = moment.tz(timezone).utcOffset() / 60;
  const KSATimzoneOffset = moment.tz('Asia/Riyadh').utcOffset() / 60;
  const diffTimezone = userTimezoneOffset - KSATimzoneOffset;
  const timeIndexDiff = diffTimezone * 2;
  const startTimeKSAIndex = 16;
  const endTimeKSAIndex = 44;
  const startTimeIndex = (startTimeKSAIndex + timeIndexDiff);
  const endTimeIndex = (endTimeKSAIndex + timeIndexDiff);
  const extendedEndTime = endTimeIndex - times.length;
  const voiceTimes = [
    ...times.slice(startTimeIndex, endTimeIndex),
    ...(startTimeIndex < 0 ? times.slice(0, endTimeIndex) : []),
    ...(extendedEndTime > 0 ? times.slice(0, extendedEndTime) : []),
  ];

  return isTodaySelected ? getRoundedTimes(now, voiceTimes) : voiceTimes;
};

export const getVoiceBypassTimes = (now: moment.Moment, isTodaySelected: boolean): string[] => {
  return isTodaySelected ? getRoundedTimes(now, times) : times;
};

export const getRoundedTimes = (now: moment.Moment, totalTimes: string[]): string[] => {

  const nowHours = now.hours();
  const nowMinutes = now.minutes();

  let roundedHours: number;
  let roundedMinutes: number;

  if (nowMinutes >= 30) {
    roundedMinutes = 0;
    roundedHours = nowHours + 1;
  } else {
    roundedMinutes = 30;
    roundedHours = nowHours;
  }

  if (roundedHours === 24) {
    return [];
  }

  const roundedTime = moment(`${roundedHours}:${roundedMinutes}`, 'hh:mm').format('h:mm A');

  const indexOfRoundedTime = totalTimes.indexOf(roundedTime);
  if (indexOfRoundedTime < 0) {
    console.error(`Cannot find time ${roundedTime} in times list.`);
    return totalTimes;
  }
  return totalTimes.slice(indexOfRoundedTime);
};
