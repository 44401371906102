import { QueryActions, queryReducer } from '../../../../store';
import * as CountriesActions from './uni-countries.actions';
import { CountriesState, GET_COUNTRIES_QUERY, initialState } from './uni-countries.state';

const queryState = (state: CountriesState, action: CountriesActions.All | QueryActions.All): CountriesState => ({
  ...state,
  ...queryReducer<CountriesState>([GET_COUNTRIES_QUERY], action as QueryActions.All),
});

export function countriesReducer(
  state = initialState,
  action: CountriesActions.All,
): CountriesState {
  switch (action.type) {
    case CountriesActions.SET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.response,
      };
    }
    case CountriesActions.SET_COUNTRIES_CHOICE_SUCCESS: {
      return {
        ...state,
        countriesChoice: action.response,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
