export class Settings {
  production: boolean;
  environmentName: string;
  ucUrl: string;
  ccUrl: string;
  convUrl: string;
  convApiUrl: string;
  convTrafficApiUrl: string;
  launchDarklyKey: string;
  redirectUri: string;
  clientId: string;
  clientSecret: string;
  facebookAppID: string;
  facebookSecret: string;
  instagramAppID: string;
  instagramSecret: string;
  segmentKeyAnalyticsJS: string;
  chatbotUrl: string;
  marketplaceUrl: string;
  noticeUrl: string;
  authenticateUrl: string;
  pushUrl: string;
  ulinkUrl: string;
  pushApiUrl: string;
  mixpanelToken?: string;
  userpilotToken?: string;
  userpilotKey?: string;
}
