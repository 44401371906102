import { Component } from '@angular/core';
import { UniTextWeight, UniTextColor } from '../../../uni-text/shared/uni-text.model';
import { UniFormInfoType } from '../../../uni-form/shared/uni-form.model';

@Component({
  selector: 'uni-campaign-csv-limt-info',
  templateUrl: './campaign-csv-limt-info.component.html',
  styleUrls: ['./campaign-csv-limt-info.component.scss']
})
export class CampaignCSVLimtInfoComponent {
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;
  uniFormInfoType = UniFormInfoType;
}
