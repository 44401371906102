export enum UniIconType {
  simple = 'simple',
  white = 'white',
  gray = 'gray',
  blue = 'blue',
  red = 'red',
  green = 'green',
  yellow = 'yellow',
  ornage ='orange',
  purple = 'purple',
  teal = 'teal',
  pink = 'pink',
  destructive = 'destructive',
  secondary = 'secondary',
  link = 'link',
  inverse = 'inverse'
}
