<uni-box-content *ngIf="campaign" type="grey">
  <uni-section-header size="medium">
    <uni-text-caption>
      {{ 'campaign.content' | translate }}
    </uni-text-caption>
  </uni-section-header>
  <uni-details *ngIf="isCustomType">

    <uni-details-item>
      <uni-label>
        {{ 'table.senderName' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.senderName?.name }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item>
      <uni-label>
        {{ 'table.recipientsCount' | translate }}
      </uni-label>
      <cc-campaign-detail *ngIf="campaign.recipients.campaignFile">
        <uni-link (click)="getFile(campaign.recipients.campaignFile.file.originalName)">
          {{ campaign.recipients.campaignFile.file.originalName }}
          <uni-icon
            name="download"
            [isLeftSpace]="true"
            [style]="'far'"
          ></uni-icon>
        </uni-link>
        <uni-text-info>
          {{ campaign.recipientsCount }} {{ 'label.recipients' | translate }}
        </uni-text-info>
      </cc-campaign-detail>
      <cc-campaign-detail *ngIf="!campaign.recipients.campaignFile">
        {{ campaign.recipientsCount }} {{ 'label.recipients' | translate }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item>
      <uni-label>
        {{ 'table.messageContent' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.content }}
      </cc-campaign-detail>
      <uni-text-info>
        <uni-form-message-counter
          [message]="campaign.content"
          [disabled]="true"
          [isSMSCounter]="true"
        ></uni-form-message-counter>
      </uni-text-info>
    </uni-details-item>
  </uni-details>

  <uni-details *ngIf="isFileType">
    <uni-details-item>
      <uni-label>
        {{ 'dataSource.file2sms' | translate }}
      </uni-label>
      <cc-campaign-detail *ngIf="campaign.recipients.campaignFile">
        <uni-link (click)="getFile(campaign.recipients.campaignFile.file.originalName)">
          {{ campaign.recipients.campaignFile.file.originalName }}
          <uni-icon
            name="download"
            [isLeftSpace]="true"
            [style]="'far'"
          ></uni-icon>
        </uni-link>
        <uni-text-info>
          {{ campaign.recipientsCount }} {{ 'label.recipients' | translate }}
        </uni-text-info>
      </cc-campaign-detail>
    </uni-details-item>
  </uni-details>

  <uni-details *ngIf="isAudienceType">
    <uni-details-item>
      <uni-label>
        {{ 'table.senderName' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.senderName?.name }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item>
      <uni-label>
        {{ 'table.recipientsCount' | translate }}
      </uni-label>
      <cc-campaign-detail>
        <uni-group
         *ngFor="let group of campaign.recipients?.audienceGroups"
         [isHorizontal]="false"
         [isDynamic]="!!group.filters"
        >
          {{ group.name }}
        </uni-group>
        <uni-text-caption
          [weight]="uniTextWeight.regular"
          [color]="uniTextColor.darkLight"
          [isUppercase]="false"
          class="campaign-summary__text"
        >
          <uni-plural
            single="recipient"
            multi="recipients"
            [amount]="campaign.recipientsCount"
          >
            {{ campaign.recipientsCount }}
          </uni-plural>
        </uni-text-caption>
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item>
      <uni-label>
        {{ 'table.messageBody' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ campaign.content }}

        <uni-text-caption
          [weight]="uniTextWeight.regular"
          [color]="uniTextColor.darkLight"
          [isUppercase]="false"
          class="campaign-summary__text"
        >
          <uni-form-message-counter
            [isSMSCounter]="true"
            [message]="campaign.content"
            [disabled]="true"
          ></uni-form-message-counter>
        </uni-text-caption>
      </cc-campaign-detail>
    </uni-details-item>
  </uni-details>
</uni-box-content>
