import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UniLanguageGuard implements CanActivate {
  constructor(private translate: TranslateService) {}

  canActivate() {
    return this.translate.get('languages.en')
      .pipe(
        map(() => true),
        catchError(() => of(true)),
      );
  }
}
