
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, combineLatest, merge } from "rxjs";
import { delay, filter, map, skip, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BlobUtils, UniAuthFacade } from "@unifonic/common";
import { ReportsRepository } from "../../data-access/reports.repository";
import { ViewAggregateReports } from "../../reports.models";
import { ReportsFiltersStore } from "../../store/reports-filters.store";
import { mapReportListToView } from "../../utils/map-report-list-to-view";
import { ReportsDataTableFiltersStore } from "./reports-data-table-filters.store";


export type EntityItem = {
    value: string,
    label: string,
    description: string
    disabled?: boolean
}

type State = {
    entities: ViewAggregateReports[]
    entitiesLoading: boolean,
    entitiesLoaded: boolean,
    totalCount: number,
    detailsLoading: boolean,
    detailsLoaded: boolean,
    selectedReportId: string,
    entity: any
}

const initialState: State = {
    entities: [],
    totalCount: 0,
    entitiesLoading: false,
    entitiesLoaded: false,
    detailsLoading: false,
    detailsLoaded: false,
    selectedReportId: '',
    entity: {}
}

@Injectable()
export class ReportDataTableStore extends ComponentStore<State>{
    constructor(
        private reportRepository: ReportsRepository,
        private reportFiltersStore: ReportsFiltersStore,
        private reportInternalFiltersStore: ReportsDataTableFiltersStore,
        private authFacade: UniAuthFacade
    ){
        super({...initialState})
    }

    //selectors
    readonly entities$ = this.select((state) => state.entities)
    readonly totalCount$ = this.select((state) => state.totalCount)
    readonly entitiesLoading$ = this.select((state) => state.entitiesLoading)
    readonly entitiesLoaded$ = this.select((state) => state.entitiesLoaded)
    readonly detailsLoading$ = this.select((state) => state.detailsLoading)
    readonly detailsLoaded$ = this.select((state) => state.detailsLoaded)
    readonly selectedReportId$ = this.select((state) => state.selectedReportId)
    readonly entity$ = this.select((state) => state.entity)

    readonly vm$ = this.select(
        this.entities$,
        this.reportFiltersStore.page$,
        this.reportFiltersStore.limit$,
        this.totalCount$,
        this.entitiesLoading$,
        this.entitiesLoaded$,
        this.detailsLoading$,
        this.detailsLoaded$,
        this.selectedReportId$,
        this.entity$,
        this.reportFiltersStore.sortBy$,
        this.reportFiltersStore.sortDir$,

        (entities,
            page,
            limit,
            totalCount,
            entitiesLoading,
            entitiesLoaded,
            detailsLoading,
            detailsLoaded,
            selectedReportId,
            entity,
            sortBy,
            sortDir,
        ) => {
            return {
                entities: entities.map(e => {
                    return {
                        ...e,
                        open: e.reportId === selectedReportId && detailsLoaded,
                        detailsLoading: e.reportId === selectedReportId && detailsLoading,
                    }
                }),
                page,
                totalCount,
                limit,
                entitiesLoading,
                entitiesLoaded,
                entity,
                sortByStartedAtActive: sortBy === 'startedAt',
                sortReportStateActive: sortBy === 'reportState',
                sortDir
            }
        }
    )

    readonly userInfo$ = this.select(
        this.authFacade.userMe$.pipe(skip(1)),
        (userMe) => {
            return {
                timezone: userMe?.user?.timezone,
                userId: userMe?.user?.id
            }
        }
    )

    readonly userId$ = this.select(
        this.authFacade.userMe$.pipe(map( (userMe: any) => userMe?.user?.id)),
        (userId) => userId
    )

    readonly reportFilters$ = combineLatest([
        this.reportFiltersStore.params$,
        this.reportInternalFiltersStore.params$
    ]).pipe(
        map(([globalFilters, localFilters]) => {
            return {
                ...globalFilters,
                ...localFilters
            }
        })
    )

    //updaters
    readonly setEntities = this.updater((state, entities: ViewAggregateReports[]) => {
        return {...state, entities: [...entities]}
    })
    readonly setTotalCount = this.updater((state, totalCount: number) => {
        return {...state, totalCount}
    })
    readonly setEntityStatus = this.updater((state, {reportId, status}: any) => {
        return {
            ...state,
            entities: state.entities.map((entity) => {
                return entity.reportId === reportId ? {
                    ...entity,
                    status
                } : entity
            })
        }
    })

    //effects
    readonly fetchReports = this.effect((trigger$) => {
        return merge(
                this.reportFilters$,
                trigger$.pipe(withLatestFrom(this.reportFilters$), map(([_, filters]) => filters))
            ).pipe(
                tap(() => {
                    this.patchState({entitiesLoading: true})
                }),
                switchMap((params) => this.reportRepository.gqlQueryFetchReports(params).pipe(
                    tapResponse(
                        (response) => {
                            this.patchState({
                                entitiesLoading: false,
                                entitiesLoaded: true,
                                totalCount: response.totalCount,
                                entities: mapReportListToView(response.entries || [])
                            })
                        },
                        () => {
                            this.patchState({
                                entitiesLoading: false,
                                entitiesLoaded: true
                            })
                        }
                    )
                ))
            )
    })

    readonly changePage = this.effect((page$: Observable<number>) => {
        return page$.pipe(
            tap((page: number) => {
                this.reportFiltersStore.setPage(page)
                this.fetchReports()
            })
        )
    })

    readonly featchReportDetails = this.effect((id$: Observable<any>) => {
        return id$.pipe(
            withLatestFrom(
                this.reportFiltersStore.userId$,
                this.selectedReportId$
            ),
            tap(([id, _, selectedReportId]) => {
                if(id === selectedReportId){
                    this.patchState({
                        selectedReportId: '',
                        detailsLoading: false,
                        detailsLoaded: false
                    })
                }
            }),
            filter(([id, _, selectedReportId]) => id !== selectedReportId),

            tap(([id, _, _2]) => {
                this.patchState({selectedReportId: id, detailsLoading: true, detailsLoaded: false })
            }),

            switchMap(([id, userId, _]) => this.reportRepository.gqlQueryFetchReportDetails(id, userId).pipe(
                tapResponse(
                    (entity: any) => {
                        this.patchState({
                            detailsLoading: false,
                            detailsLoaded: true,
                            entity: {
                                ...entity
                            }
                        })
                        /**
                         * Sometimes on the table report staus is IN_PROGRESS.
                         * After a few seconds it is change on backend for "COMPLETED", however frontend doesn't know about that
                         * When user fetch the details for report we update the report status on the table with the one from details
                         * as this one is the latest status.
                         */
                        this.setEntityStatus({reportId: entity.id, status: entity.reportState})
                    },
                    () => {
                        this.patchState({detailsLoading: false, detailsLoaded: false, entity: {}})
                    }
                )
            ))

        )
    })
}
