import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  UniLayoutLoggedComponent,
  AuthGuard as UniAuthGuard,
  UniViewComponent
} from '@unifonic/common';
import { errorRoutes } from './error-routing.module';
import { AuthRedirectGuard } from './shared/utils/auth-redirect.guard';
import { FeatureFlagNewMenuGuard } from './shared/utils/feature-flag-new-menu.guard';
import { FeatureFlagsGuard } from 'unifonic-spa-common/src';


const routesForLoggedUsers: Routes = [
  {
    path: '',
    component: UniViewComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivateChild: [FeatureFlagNewMenuGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivateChild: [FeatureFlagNewMenuGuard]
      },
      {
        path: 'log-analyser',
        loadChildren: () => import('./log-analyser/log-analyser.module').then(mod => mod.LogAnalyserModule),
        canActivateChild: [FeatureFlagNewMenuGuard]
      }, {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(mod => mod.ReportsModule),
        canActivateChild: [FeatureFlagNewMenuGuard]
      },
    ],
  }
];

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'insights'
  },
  {
    path: 'insights',
    component: UniLayoutLoggedComponent,
    canActivate: [AuthRedirectGuard],
    children: [
      ...routesForLoggedUsers
    ],
  },
  {
    path: 'security',
    component: UniLayoutLoggedComponent,
    canActivate: [AuthRedirectGuard],
    children: [
      {
        path: 'unishield',
        component: UniViewComponent,
        loadChildren: () => import('./security-unishield/security-unishield.module').then(mod => mod.SecurityUnishieldModule),
        canActivate: [FeatureFlagNewMenuGuard, FeatureFlagsGuard],
        data: {
          featureFlags: ['2024-q-3-unishield']
        }
      }
    ],
  },
  ...errorRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
