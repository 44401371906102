import { HttpHeaders } from '@angular/common/http';

export namespace BlobUtils {

  export const getFilename = (headers: HttpHeaders): string => {
    const disposition = headers.get('Content-Disposition');

    if (!disposition || disposition.indexOf('attachment') === -1) {
      return '';
    }

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    return matches != null && matches[1]
      ? matches[1].replace(/['"]/g, '')
      : '';
  };

  export const downloadPDF = (response: Blob, fileName: string = 'document'): void => {
    const file = new Blob([response], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);

    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = url;
    a.target = '_blank';
    a.download = `${fileName}.pdf`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  export const download = (response: Blob | string, fileName: string, headers?: HttpHeaders): void => {
    const objectUrl: string = URL.createObjectURL(response);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = objectUrl;
    a.target = '_blank';

    if (!!fileName) {
      a.download = fileName;
    }

    if (!!headers) {
      const headerFilename = getFilename(headers);

      if (!!headerFilename) {
        a.download = getFilename(headers);
      }
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  };

  export const simpleDownload = (url: string): void => {
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = url;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

}
