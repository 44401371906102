import { Component, Input } from '@angular/core';

@Component({
  selector: 'uni-icon-arrow',
  templateUrl: './uni-icon-arrow.component.html',
  styleUrls: ['./uni-icon-arrow.component.scss'],
})
export class UniIconArrowComponent {
  @Input() isOpen = false;
}
