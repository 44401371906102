<div (uniClickOutside)="isOpened && onApply($event)">
  <uni-table-filter-header
    [heading]="heading"
    [hasFilters]="!!startDateControl.value || !!endDateControl.value"
    (open)="onOpen()"
  ></uni-table-filter-header>

  <uni-table-filter-wrapper
    *ngIf="isOpened"
    [tableLeft]="tableLeft"
    [thead]="thead"
    [alignRight]="alignRight"
  >
    <div class="uni-table-filter-daterange">
      <uni-form-multi-autocomplete-header
        [heading]="heading"
        (clear)="onClear()"
        (close)="onDiscard()"
      ></uni-form-multi-autocomplete-header>

      <form (ngSubmit)="onApply()">
        <uni-datepicker
          [formGroup]="form"
          [datepicker]="datepicker"
        >
          <mat-date-range-input
            [rangePicker]="datepicker"
          >
            <input
              [placeholder]="'label.chooseDates' | translate"
              [formControlName]="controlNameBegin"
              autocomplete="off"
              (click)="datepicker.open()"
              uniInput
              matStartDate
              readonly
            >
            <input
              [placeholder]="''"
              [formControlName]="controlNameEnd"
              autocomplete="off"
              (click)="datepicker.open()"
              uniInput
              matEndDate
              readonly
            >
          </mat-date-range-input>

          <mat-date-range-picker #datepicker></mat-date-range-picker>
        </uni-datepicker>
      </form>

      <uni-form-multi-autocomplete-controllers
        [isValidationError]="isValidationError()"
        (apply)="this.isOpened = false"
        (clear)="onClear()"
      ></uni-form-multi-autocomplete-controllers>
    </div>
  </uni-table-filter-wrapper>
</div>
