<uni-section>
    <router-outlet></router-outlet>
    <uni-title-container>
        <uni-title-item>
            <div class="text-body">
                <span>{{'ci.reports.reports' | translate}}</span>
                <uni-button
                    type="button"
                    [style]="'primary'"
                    [isMargin]="true"
                    (click)="redirectToCreationForm()"
                >
                    {{'ci.reports.createReport' | translate}}
                    <i class="fa fa-chevron-right"></i>
                </uni-button>
            </div>

            <uni-separator [isSpace]="false"></uni-separator>
        </uni-title-item>
    </uni-title-container>
</uni-section>
<uni-section>

<app-reports-data-table></app-reports-data-table>
