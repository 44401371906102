import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanDeactivateComponent {
  canDeactivate: (event?: BeforeUnloadEvent) => Observable<boolean> | boolean;
}

@Injectable()
export class CanDeactivateComponentGuard implements CanDeactivate<CanDeactivateComponent>{
  canDeactivate(component: CanDeactivateComponent): Observable<boolean> | boolean {
    return component.canDeactivate();
  }
}
