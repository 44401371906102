import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniTreeComponent } from './components/uni-tree/uni-tree.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { UniTreeCheckboxComponent } from './components/uni-tree-checkbox/uni-tree-checkbox.component';

const components = [
  UniTreeComponent,
  UniTreeCheckboxComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TreeModule,
  ],
  exports: [...components]
})
export class UniTreeModule { }
