import { NgModule } from '@angular/core';

import { DateFormatPipe } from './date-format.pipe';
import { DateTimeFormatPipe } from './date-time-format.pipe';
import { MultilinePipe } from './multiline.pipe';
import { ObjectToArrayPipe } from './object-to-array.pipe';
import { TranslateCutPipe } from './translate-cut.pipe';
import { NumberCommasPipe } from './number-commas.pipe';
import { TimePipe } from './time.pipe';
import { SecureIdPipe } from './secure-id.pipe';
import { UniPhoneNumberFormatPipe } from './uni-phone-number-format.pipe';

const pipes = [
  ObjectToArrayPipe,
  DateFormatPipe,
  DateTimeFormatPipe,
  MultilinePipe,
  TranslateCutPipe,
  NumberCommasPipe,
  MultilinePipe,
  TimePipe,
  SecureIdPipe,
  UniPhoneNumberFormatPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class UniPipesModule { }
