

<ng-container *ngIf="vm$ | async as vm">
  

<div class="row">
  <div class="col-6" *ngIf="vm.countriesGroup1.length">

    <table>
      <thead>
        <tr>
          <th [style.width.%]="80">
            <div class="flex justify-content-start">
              {{'ci.unishield.topCountries' | translate | uppercase}}
            </div>
          </th>
          <th [style.width.%]="20">
            <div class="flex justify-content-end">
              {{'ci.unishield.messagesBlocked' | translate | uppercase}}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container  *ngFor="let item of vm.countriesGroup1">
          <tr>
            <td>
              {{item.countryName}}
            </td>
            <td>
              <div class="flex justify-content-end">
                {{item.messagesBlocked | number}}
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>


  </div>
  <div class="col-6" *ngIf="vm.countriesGroup2.length">

    <table>
      <thead>
        <tr>
          <th [style.width.%]="80">
            <div class="flex justify-content-start">
              {{'ci.unishield.topCountries' | translate | uppercase}}
            </div>
          </th>
          <th [style.width.%]="20">
            <div class="flex justify-content-end">
              {{'ci.unishield.messagesBlocked' | translate | uppercase}}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container  *ngFor="let item of vm.countriesGroup2">
          <tr>
            <td>
              {{item.countryName}}
            </td>
            <td>
              <div class="flex justify-content-end">
                {{item.messagesBlocked | number}}
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>


  </div>
</div>



</ng-container>