import { BehaviorSubject } from 'rxjs';
import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isQueryInProgress$ } from '../../../store';

import * as uniAudienceActions from '../store/uni-audience.actions';
import {
  selectUniGroup,
  selectUniGroups,
  selectUniGroupsAmount,
  selectUniGetGroupQuery,
  selectUniGetGroupsQuery,
  selectUniDeleteGroupQuery,
  selectUniDynamicGroup,
  selectUniDynamicGroups,
  selectUniDynamicGroupsAmount,
  selectUniGetDynamicGroupQuery,
  selectUniGetDynamicGroupsQuery,
  selectUniDeleteDynamicGroupQuery
} from '../store/uni-audience.selectors';
import { UniAudienceState } from '../store';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class UniAudienceFacade {
  group$ = this.store.pipe(select(selectUniGroup));
  groups$ = this.store.pipe(select(selectUniGroups));
  dynamicGroup$ = this.store.pipe(select(selectUniDynamicGroup));
  dynamicGroups$ = this.store.pipe(select(selectUniDynamicGroups));
  groupsAmount$ = this.store.pipe(select(selectUniGroupsAmount));
  getGroupQuery$ = this.store.pipe(select(selectUniGetGroupQuery));
  getGroupsQuery$ = this.store.pipe(select(selectUniGetGroupsQuery));
  deleteGroupQuery$ = this.store.pipe(select(selectUniDeleteGroupQuery));
  dynamicGroupsAmount$ = this.store.pipe(select(selectUniDynamicGroupsAmount));
  getDynamicGroupQuery$ = this.store.pipe(select(selectUniGetDynamicGroupQuery));
  getDynamicGroupsQuery$ = this.store.pipe(select(selectUniGetDynamicGroupsQuery));
  deleteDynamicGroupQuery$ = this.store.pipe(select(selectUniDeleteDynamicGroupQuery));

  loader$ = new BehaviorSubject(isQueryInProgress$([
    this.getGroupQuery$,
    this.getGroupsQuery$,
    this.deleteGroupQuery$,
    this.getDynamicGroupQuery$,
    this.getDynamicGroupsQuery$,
    this.deleteDynamicGroupQuery$
  ]));

  isLoading$ = this.loader$.pipe(mergeMap(isActive => isActive));

  constructor(private store: Store<UniAudienceState>) { }

  setGroup(id: string): void {
    this.store.dispatch(new uniAudienceActions.SetUniGroup(id));
  }

  setGroups(params: Params = {}, page?: string) {
    this.store.dispatch(new uniAudienceActions.SetUniGroups(params, page));
  }

  deleteGroup(ids: string[]): void {
    this.store.dispatch(new uniAudienceActions.DeleteUniGroup(ids));
  }

  clearGroup(): void {
    this.store.dispatch(new uniAudienceActions.ClearUniGroup());
  }

  setDynamicGroup(id: string): void {
    this.store.dispatch(new uniAudienceActions.SetUniDynamicGroup(id));
  }

  setDynamicGroups(params: Params = {}, page?: string) {
    this.store.dispatch(new uniAudienceActions.SetUniDynamicGroups(params, page));
  }

  deleteDynamicGroup(ids: string[]): void {
    this.store.dispatch(new uniAudienceActions.DeleteUniDynamicGroup(ids));
  }

  clearDynamicGroup(): void {
    this.store.dispatch(new uniAudienceActions.ClearUniDynamicGroup());
  }
}
