<uni-box [isHidden]="false">
  <div #table>
    <uni-table-top-bar>
      <uni-table-top-bar-content>
        <uni-table-top-bar-item>
          <uni-plural [amount]="getItems(totalItems)">
            {{ getItems(totalItems) }}
          </uni-plural>
        </uni-table-top-bar-item>
        <uni-table-top-bar-item>
          <uni-table-clear-filters></uni-table-clear-filters>
        </uni-table-top-bar-item>
        <uni-table-top-bar-item>
          <uni-button
            (click)="onExport()"
            [disabled]="isDisabled(logs)"
            [fitContent]="true"
          >
          <uni-icon
            class="campaign-messages__icon"
            name="file-export"
            [style]="'far'"
          ></uni-icon>
            {{ 'action.exportFile' | translate }}
          </uni-button>
          </uni-table-top-bar-item>
      </uni-table-top-bar-content>
    </uni-table-top-bar>

    <uni-table>
      <table cdk-table [dataSource]="dataSource">

        <ng-container [cdkColumnDef]="columns.messageID">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.messageID | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.messageId }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.messageBody">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.messageBody | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            <uni-clipboard
              [text]="element.messageBody"
            ></uni-clipboard>
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.messageUnits">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.messageUnits | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.messageUnits }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.accountName">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.accountName | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            <uni-clipboard
              [text]="element.accountName"
            ></uni-clipboard>
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.recipientsNumber">
          <th cdk-header-cell *cdkHeaderCellDef #thead>
            <uni-table-filter-search
              [heading]="'table.' + columns.recipientsNumber | translate"
              [thead]="thead"
              [tableLeft]="tableLeft"
              [controlName]="filters.recipientNumber"
            ></uni-table-filter-search>
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.recipientNumber }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.operator">
          <th cdk-header-cell *cdkHeaderCellDef #thead>
            <uni-table-filter
              [heading]="'table.' + columns.operator | translate"
              [thead]="thead"
              [tableLeft]="tableLeft"
              [data]="operators$ | async"
              [controlName]="filters.operator"
              dataParserKey="name"
            ></uni-table-filter>
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.operatorName }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.senderName">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.senderName | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            <uni-clipboard
              [text]="element.senderName"
            ></uni-clipboard>
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.status">
          <th cdk-header-cell *cdkHeaderCellDef #thead>
            <uni-table-filter
              [heading]="'table.' + columns.status | translate"
              [thead]="thead"
              [tableLeft]="tableLeft"
              [data]="statuses"
              [controlName]="filters.status"
            ></uni-table-filter>
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.status }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.country">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.country | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            <uni-tooltip-cloud
              *ngIf="element.countryName"
              position="top"
            >
              <uni-country-flag
                [country]="element.countryName"
              ></uni-country-flag>

              <div tooltip>
                {{ element.countryName }}
              </div>
            </uni-tooltip-cloud>
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.mnpUsed">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.mnpUsed | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ 'label.' + (element.mnpUsed === '1' ? 'yes' : 'no') | translate }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.sentByClientAt">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.sentByClientAt | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.sentByClientDate | dateFormat:dateUtils.Format.meridiemDateTime }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.sentToOperatorStatus">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.sentToOperatorStatus | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.sentToOperatorStatus }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.sentToOperatorAt">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.sentToOperatorAt | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.sendDate | dateFormat:dateUtils.Format.meridiemDateTime }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.operatorReportReceivedAt">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.operatorReportReceivedAt | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.operatorReportReceivedAt | dateFormat:dateUtils.Format.meridiemDateTime }}
          </td>
        </ng-container>

        <ng-container [cdkColumnDef]="columns.deliveredToCustomerStatus">
          <th cdk-header-cell *cdkHeaderCellDef>
            {{ 'table.' + columns.deliveredToCustomerStatus | translate }}
          </th>
          <td cdk-cell *cdkCellDef="let element">
            {{ element.dlr }}
          </td>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr
          cdk-row
          *cdkRowDef="let row; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
          [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
        ></tr>
      </table>
    </uni-table>
    <uni-table-empty
      *ngIf="isEmpty()"
      [title]="'label.' + (isFiltered ? 'emptyListTitle' : 'emptyReportTitle') | translate"
      [description]="(isFiltered ? 'label.emptyListDescription' : '') | translate"
    ></uni-table-empty>
  </div>
</uni-box>
<uni-pagination
  *ngIf="totalItems"
  [items]="totalItems"
></uni-pagination>
