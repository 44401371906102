import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UniAuthService, UniHasFeatureFlagDirective } from './shared';
import { AuthEffects } from './store/uni-auth.effects';
import { authReducer } from './store/uni-auth.reducers';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UniTableModule } from '../uni-table/uni-table.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('uniAuth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    UniTableModule,
    TranslateModule
  ],
  providers: [
    UniAuthService
  ],
  declarations: [
    UniHasFeatureFlagDirective,
  ],
  exports: [
    UniHasFeatureFlagDirective,
  ],
})
export class UniAuthModule { }
