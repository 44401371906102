import { queryReducer } from '../../../store';
import * as QueryActions from '../../../store/query/query.actions';
import * as AuthActions from './uni-auth.actions';
import {
  GET_FEATURE_FLAGS_QUERY, GET_GLOBAL_SETTINGS_QUERY, GET_USER_ME_QUERY, initialState, UniAuthState
} from './uni-auth.state';

const queryState = (state: UniAuthState, action: AuthActions.All | QueryActions.All): UniAuthState => ({
  ...state,
  ...queryReducer<UniAuthState>(
    [GET_USER_ME_QUERY, GET_GLOBAL_SETTINGS_QUERY, GET_FEATURE_FLAGS_QUERY], action as QueryActions.All
  ),
});

export function authReducer(state = initialState, action: AuthActions.All): UniAuthState {
  switch (action.type) {
    case AuthActions.SET_USER_ME_SUCCESS: {
      return {
        ...state,
        userMe: action.response,
      };
    }
    case AuthActions.SET_GLOBAL_SETTINGS_SUCCESS: {
      return {
        ...state,
        globalSettings: action.response,
      };
    }
    case AuthActions.SET_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        featureFlags: action.data,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
