import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniLanguageSwitcherComponent } from './components/index';
import { UniLanguageFormFactory, UniLanguageRepository } from './shared/index';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    UniLanguageSwitcherComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    TranslateModule,
  ],
  providers: [
    UniLanguageFormFactory,
    UniLanguageRepository
  ],
  exports: [
    UniLanguageSwitcherComponent,
    TranslateModule,
  ]
})
export class UniLanguageModule { }
