import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Environment, httpEncoder } from '../../../utils';
import { UniRole } from './uni-roles.model';

@Injectable()
export class UniRolesRepository {
  rolesUrl = `${Environment.getMainHost(
    Environment.APP.CONSOLE
  )}/app/api/v2/roles`;

  constructor(private http: HttpClient) {}

  getRoles(userId: string = '', assignableByCurrentUser: boolean = false): Observable<UniRole[]> {
    return this.http.get<UniRole[]>(
      `${ this.rolesUrl }`,
      {
        withCredentials: true,
        params: httpEncoder({
          'user[id]': userId,
          assignableByCurrentUser
        })
      }
    );
  }
}
