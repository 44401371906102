import { merge, of } from 'rxjs';
import { delay, switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as SnackbarActions from './uni-snackbar.actions';
import { selectSnackbars } from './uni-snackbar.selectors';
import { UniSnackbarState } from './uni-snackbar.state';

@Injectable()
export class SnackbarEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<UniSnackbarState>,
  ) { }

  @Effect() newSnackbar$ = this.actions$
    .pipe(
      ofType<SnackbarActions.NewSnackbar>(SnackbarActions.NEW_SNACKBAR),
      withLatestFrom(this.store$.pipe(select(selectSnackbars))),
      switchMap(([{ status, message, property, action }, snackbars]) => {
        return merge(
          of(new SnackbarActions.HideSnackbar()),
          of(new SnackbarActions.ShowSnackbar(status, message, property, action)),
          of(new SnackbarActions.HideSnackbar()).pipe(delay(6000))
        );
      }),
    );
}
