import { Injectable } from '@angular/core';
import { cloneDeep, get, startCase } from 'lodash';
import { UniAuthFacade } from '../../uni-auth/shared';

import { productDomains } from './product-domains';
import { navMenuSettings } from './uni-nav-menu.settings';
import {
  Environments,
  ExternalEnvironments,
  NavLink,
  NavMenu,
  NavMenuGroup,
  Products,
  WhiteLabelDomainProducts,
  whiteLabelProductDomainPrefix,
} from './uni-nav.model';

interface NavItem {
    link?: NavLink;
    items?: NavItem[];
}

const mfeProducts: Products[] = [
  Products.uc,
  Products.cc,
];

const productPath = {
  [Products.uc]: '/cloud',
  [Products.cc]: '/communication',
};

@Injectable({ providedIn: 'root' })
export class UniNavSettingsService {

    constructor(private readonly uniAuthFacade: UniAuthFacade) { }

    getNavMenuSettings(): NavMenu {
        const settings = cloneDeep(navMenuSettings);
        const navGroupsWithProductUrls = this.addProductUrls(settings.groups);

        return { groups: navGroupsWithProductUrls as NavMenuGroup[] };
    }

    private addProductUrls(items: NavItem[]): NavItem[] {
        return items
            .map((item: NavItem) => {
                if (item.link?.product) {
                    item.link.productUrl = this.getProductUrl(item.link.product);
                }
                if (item.items) {
                    item.items = this.addProductUrls(item.items);
                }
                return item;
            });
    }

    private getProductUrl(product: Products): string {
        const environment = this.getEnvironment();
        const productDomainPath = `${environment}.${product}`;

        const productDomain = this.isWhiteLabelProductDomain(product)
            ? this.getWhiteLabelDomain(product)
            : this.isMfeProductDomain(product)
              ? get(productDomains, `${environment}.${Products.uc}`)
              : get(productDomains, productDomainPath);

        if (!productDomain) {
            throw new Error(`Product domain for product: ${product} and environment: ${environment} not found.`);
        }

        const productSuffix = this.isMfeProductDomain(product) ? productPath[product] : '';
        const protocol = this.getProtocol(environment);

        return `${protocol}//${productDomain}${productSuffix}`;
    }

    getUcUrl(): string {
      return this.getProductUrl(Products.uc);
    }

    private isWhiteLabelProductDomain(product: Products): boolean {
        const isWhiteLabelAccount = this.uniAuthFacade.systemSettings.isWhitelabelActive;
        const isWhiteLabelDomainProduct = WhiteLabelDomainProducts.includes(product);

        return isWhiteLabelAccount && isWhiteLabelDomainProduct;
    }

    private isMfeProductDomain(product: Products): boolean {
      return mfeProducts.includes(product);
    }

    private getWhiteLabelDomain(product: Products): string {
        const whiteLabelDomain = this.uniAuthFacade.systemSettings.domain;
        const normalizedWhiteLabelDomain = this.normalizeWhiteLabelDomain(whiteLabelDomain);

        switch (product) {
            case Products.cc:
            case Products.uc: {
                return normalizedWhiteLabelDomain;
            }
            case Products.campaign: {
                return `${whiteLabelProductDomainPrefix}.${normalizedWhiteLabelDomain}`;
            }
            default: {
                throw new Error(`Product: ${product} is not a white-label domain product.`);
            }
        }
    }

    getKey(keys: string[]): string {
      const domain = this.uniAuthFacade.systemSettings.domain;

      for (const envKey of keys) {
        if (domain.includes(envKey.toLowerCase())) {
            return envKey;
        }
      }

      return '';
    }

    // TODO: Make sure all apps work
    private getEnvironment(): Environments {
      const isExternalEnvironment = this.getKey(Object.values(ExternalEnvironments));

      const environmentKeys = Object.values(Environments);

      return (this.getKey(environmentKeys) || Environments.prod) + startCase(isExternalEnvironment) as Environments;
    }

    private getProtocol(environment: Environments): string {
        return `http${environment === Environments.local ? '' : 's'}:`;
    }

    private normalizeWhiteLabelDomain(domain: string): string {
        return domain.charAt(domain.length - 1) === '.' ? domain.slice(0, -1) : domain;
    }
}
