export interface Color {
  green: string;
  greenLight: string;
  greenLighter: string;
  greenBright: string;
  lime: string;
  blue: string;
  blueLight: string;
  grey: string;
  greyLight: string;
  darkLighter: string;
  white: string;
  alert: string;
  alertLight: string;
  black: string;
  yellow: string;
  orange: string;
  greenDark: string;
  greenDarker: string;
  violet: string;
  redLight: string;
}

export const color: Color = ({
  green: '#23b09b',
  greenLight: '#91d7cd',
  greenLighter: '#def3f0',
  greenBright: '#6DEFC0',
  blue: '#009ade',
  grey: '#2f4050',
  darkLighter: '#d0d4d7',
  white: '#ffffff',
  alert: '#dd1c4c',
  alertLight: '#ee8da5',
  black: '#000000',
  yellow: '#ffe600',
  orange: '#ff9900',
  greenDark: '#54894a',
  greenDarker: '#52C0AC',
  violet: '#6f2a85',
  redLight: '#f6a6a1',
  lime: '#1ad678',
  blueLight: '#b6d5f2',
  greyLight: '#d9d9d9'
});

export function hexFactory(value: string) {
  const hex  = value.substring(1).split('');
  return hex.length === 3
    ? [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]]
    : hex;
}

export function hexToRgba(value: string, alpha = 1): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(value)) {
    return 'rgba(0, 0, 0, 0)';
  }

  const hex = hexFactory(value);
  const c = `0x${hex.join('')}` as any;
  // eslint-disable-next-line
  const rgb = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');

  return `rgba(${rgb}, ${alpha})`;
}

export function setAlpha(rgbaColor: string, alpha: number): string {
  return rgbaColor.replace(', 0)', `, ${alpha})`);
}
