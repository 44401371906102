import { QueryActions, queryReducer } from '../query';

import * as CallerIdActions from './caller-id.actions';
import {
  CallerIdState,
  callerIdState,
  GET_CALLER_IDS_QUERY,
  POST_CALLER_ID_QUERY,
  GET_SECONDARY_CALLER_IDS_QUERY,
  GET_CALLER_ID_CHANNELS_QUERY,
  GET_CALLER_IDS_SUBSCRIPTIONS_QUERY,
  POST_CALLER_ID_CHANNEL_QUERY,
} from './caller-id.state';

const queryState = (state: CallerIdState, action: CallerIdActions.All | QueryActions.All): CallerIdState => ({
  ...state,
  ...queryReducer<CallerIdState>([
    GET_CALLER_IDS_QUERY,
    GET_SECONDARY_CALLER_IDS_QUERY,
    POST_CALLER_ID_QUERY,
    GET_CALLER_ID_CHANNELS_QUERY,
    GET_CALLER_IDS_SUBSCRIPTIONS_QUERY,
    POST_CALLER_ID_CHANNEL_QUERY,
  ], action as QueryActions.All),
});

export function callerIdReducer(
  state = callerIdState,
  action: CallerIdActions.All,
): CallerIdState {
  switch (action.type) {
    case CallerIdActions.SET_CALLER_IDS_SUCCESS: {
      return !!action.res ? {
        ...state,
        callerIds: action.res?.body || [],
        callerIdsAmount: Number(action.res?.headers.get('Total-Items'))
      } : {
        ...state,
        callerIds: [],
        callerIdsAmount: 0,
      };
    }
    case CallerIdActions.SET_SECONDARY_CALLER_IDS_SUCCESS: {
      return !!action.res ? {
        ...state,
        secondaryCallerIds: action.res?.body || [],
        secondaryCallerIdsAmount: Number(action.res?.headers.get('Total-Items'))
      } : {
        ...state,
        secondaryCallerIds: [],
        secondaryCallerIdsAmount: 0,
      };
    }
    case CallerIdActions.SET_CALLER_IDS_SUBSCRIPTIONS_SUCCESS: {
      return !!action.res ? {
        ...state,
        callerIdsSubscriptions: action.res?.body?.content || [],
        callerIdsSubscriptionsAmount: Number(action.res?.body?.totalElements || 0)
      } : {
        ...state,
        callerIdsSubscriptions: [],
        callerIdsSubscriptionsAmount: 0,
      };
    }
    case CallerIdActions.POST_CALLER_ID_ERROR: {
      return {
        ...state,
        postErrors: action.errors || [],
      };
    }
    case CallerIdActions.DELETE_CALLER_IDS:
    case CallerIdActions.DELETE_CALLER_IDS_SUCCESS: {
      return {
        ...state,
        deleteErrors: [],
      };
    }
    case CallerIdActions.DELETE_CALLER_IDS_ERROR: {
      return {
        ...state,
        deleteErrors: action.errors || [],
      };
    }
    case CallerIdActions.ACCOUNT_HAS_ACTIVE_CALLER_ID_SUCCESS: {
      return {
        ...state,
        hasActiveCallerId: action.hasActiveCallerId,
      };
    }
    case CallerIdActions.SET_CALLER_ID_CHANNELS_SUCCESS: {
      return !!action.res ? {
        ...state,
        callerIdChannels: action.res?.body?.content || [],
        callerIdChannelsAmount: Number(action.res?.body?.totalElements || 0)
      } : {
        ...state,
        callerIdChannels: [],
        callerIdChannelsAmount: 0,
      };
    }
    case CallerIdActions.SET_CALLER_ID_CHANNEL_CONFIG_SUCCESS: {
      return !!action.res ? {
        ...state,
        callerIdChannelConfig: action.res.body || null,
      } : {
        ...state,
        callerIdChannelConfig: null,
      };
    }
    case CallerIdActions.SET_CALLER_ID_CHANNEL_CHARGES_SUCCESS: {
      return !!action.res ? {
        ...state,
        callerIdChannelCharges: action.res.body || null,
      } : {
        ...state,
        callerIdChannelCharges: null,
      };
    }
    case CallerIdActions.POST_CALLER_ID_CHANNEL_ERROR: {
      return {
        ...state,
        postErrors: action.errors || [],
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
