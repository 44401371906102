import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UniSnackbarState } from './uni-snackbar.state';

export const selectSnackbarFeature = createFeatureSelector<UniSnackbarState>('snackbar');

export const selectSnackbars = createSelector(
  selectSnackbarFeature,
  (state: UniSnackbarState) => state.snackbars
);
