import { UniGroup, UniDynamicGroup } from '../shared/uni-audience.model';
import { Query } from '../../../store';

export const GET_GROUP_QUERY = 'getGroupQuery';
export const GET_GROUPS_QUERY = 'getGroupsQuery';
export const DELETE_GROUP_QUERY  = 'deleteGroupQuery';

export const GET_DYNAMIC_GROUP_QUERY = 'getDynamicGroupQuery';
export const GET_DYNAMIC_GROUPS_QUERY = 'getDynamicGroupsQuery';
export const DELETE_DYNAMIC_GROUP_QUERY  = 'deleteDynamicGroupQuery';

export const AUDIENCE_KEY = 'audience';

export interface UniAudienceState {
  group?: UniGroup;
  groups: UniGroup[];
  dynamicGroup?: UniDynamicGroup;
  dynamicGroups: UniDynamicGroup[];
  groupsAmount?: number;
  getGroupQuery: Query<UniGroup>;
  getGroupsQuery: Query<UniGroup>;
  deleteGroupQuery: Query<any>;
  dynamicGroupsAmount?: number;
  getDynamicGroupQuery: Query<UniDynamicGroup>;
  getDynamicGroupsQuery: Query<UniDynamicGroup>;
  deleteDynamicGroupQuery: Query<any>;
}

export const uniAudienceInitialState: UniAudienceState = {
  groups: [],
  dynamicGroups: [],
  getGroupQuery: {},
  getGroupsQuery: {},
  deleteGroupQuery: {},
  getDynamicGroupQuery: {},
  getDynamicGroupsQuery: {},
  deleteDynamicGroupQuery: {}
};
