import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UniSeparatorComponent } from './components';
import { UniIconModule } from '../uni-icon';

const components = [
  UniSeparatorComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UniIconModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniSeparatorModule { }
