import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniTextWeight, UniTextColor } from '../../../uni-text/shared/uni-text.model';
import { UniFormInfoType } from '../../../uni-form/shared/uni-form.model';

@Component({
  selector: 'cc-campaign-dates-form',
  templateUrl: './campaign-dates-form.component.html',
  styleUrls: ['./campaign-dates-form.component.scss']
})
export class CampaignDatesFormComponent {
  @Input() form: FormGroup;
  @Input() showRamadanNote: boolean;
  timezone = this.uniAuthFacade.user.timezone;
  isSummaryActive = false;
  step = 3;
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;
  uniFormInfoType = UniFormInfoType;

  get datesForm(): FormGroup {
    return this.form.get('datesForm') as FormGroup;
  }

  get dateForm(): FormGroup {
    return this.datesForm.get('date') as FormGroup;
  }

  get stepControl(): FormControl {
    return this.form.get('step') as FormControl;
  }

  get isActiveStep(): boolean {
    return this.stepControl.value === this.step;
  }

  get isScheduledControl(): FormControl {
    return this.datesForm.get('isScheduled') as FormControl;
  }

  get scheduleTimeInfoKey(): string {
    return this.showRamadanNote ? 'campaign.scheduleTimeInfoRamadan' : 'campaign.scheduleTimeInfo';
  }

  constructor(private uniAuthFacade: UniAuthFacade) {}

  onShowSummary(): void {
    this.stepControl.setValue(this.step + 1);
    window.scroll(0, 0);
  }
}
