import { QueryActions, queryReducer } from '../../../../store';
import * as TimezonesActions from './uni-timezones.actions';
import { GET_TIMEZONES_QUERY, initialState, TimezonesState } from './uni-timezones.state';

const queryState = (state: TimezonesState, action: TimezonesActions.All | QueryActions.All): TimezonesState => ({
  ...state,
  ...queryReducer<TimezonesState>([GET_TIMEZONES_QUERY], action as QueryActions.All),
});

export function timezonesReducer(
  state = initialState,
  action: TimezonesActions.All,
): TimezonesState {
  switch (action.type) {
    case TimezonesActions.SET_TIMEZONES_SUCCESS: {
      return {
        ...state,
        timezones: action.response,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
