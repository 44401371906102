<div class="products-content__wrapper">
  <uni-nav-category categoryName="navigation.products">
    <uni-nav-item
      [isSidebar]="true"
      (click)="navigateToProduct(productKey.UC)"
    >
      <uni-nav-item-name>
        {{ 'products.unifonicCloud' | translate }}
      </uni-nav-item-name>
    </uni-nav-item>

    <uni-nav-item
      [isSidebar]="true"
      (click)="navigateToProduct(productKey.CC)"
    >
      <uni-nav-item-name>
        {{ 'products.communicationCloud' |  translate }}
      </uni-nav-item-name>
    </uni-nav-item>
  </uni-nav-category>

  <uni-nav-category categoryName="navigation.applications">
    <uni-nav-item
      [isSidebar]="true"
      *ngIf="isConversationItem"
      (click)="navigateToProduct(productKey.CONV)"
    >
      <uni-nav-item-name>
        {{ 'navigation.conversations' | translate }}
      </uni-nav-item-name>
      <uni-nav-item-icon
        [isOnRight]="true"
        [isOnlyOnMobile]="true"
        (click)="openSubNavigation()"
      >
        <uni-icon name="chevron-right"></uni-icon>
      </uni-nav-item-icon>
    </uni-nav-item>
    <uni-nav-item
      [isSidebar]="true"
      *ngIf="isCampaignItem"
      (click)="navigateToProduct(productKey.CC, '/campaigns')"
    >
      <uni-nav-item-name>
        {{ 'navigation.campaigns.campaigns' | translate }}
      </uni-nav-item-name>
    </uni-nav-item>
    <uni-nav-item
      [isSidebar]="true"
      *ngIf="canSeeSlackForSupport()"
      (click)="navigateToProduct(productKey.CONV, '/integrations/slack')"
    >
      <uni-nav-item-name>
        {{ 'navigation.slackForSupport' | translate }}
      </uni-nav-item-name>
    </uni-nav-item>
    <uni-nav-item
      [isSidebar]="true"
      (click)="navigateToProduct(productKey.CONV, '/integrations/bot-builder')"
    >
      <uni-nav-item-name>
        {{ 'navigation.botBuilder' | translate }}
      </uni-nav-item-name>
    </uni-nav-item>
    <a *ngIf="isNoticeItem"  [href]="noticeUrl">
      <uni-nav-item [isSidebar]="true">
        <uni-nav-item-name>
          {{ 'navigation.notice' | translate }}
        </uni-nav-item-name>
      </uni-nav-item>
    </a>
    <a *ngIf="isAuthenticateItem" [href]="authenticateUrl">
      <uni-nav-item [isSidebar]="true">
        <uni-nav-item-name>
          {{ 'navigation.authenticate' | translate }}
        </uni-nav-item-name>
      </uni-nav-item>
    </a>
  </uni-nav-category>

  <uni-nav-category
    *ngIf="isTopUpItem || isMarketplaceItem"
    categoryName="navigation.store"
  >
    <uni-nav-item
      [isSidebar]="true"
      *ngIf="isTopUpItem"
      (click)="navigateToProduct(productKey.UC, '/store/top-up')"
    >
      <uni-nav-item-name>
        {{ 'navigation.topUp' | translate }}
      </uni-nav-item-name>
    </uni-nav-item>
    <a *ngIf="isMarketplaceItem" [href]="marketplaceUrl">
      <uni-nav-item [isSidebar]="true">
        <uni-nav-item-name>
          {{ 'navigation.marketplace' | translate }}
        </uni-nav-item-name>
      </uni-nav-item>
    </a>
  </uni-nav-category>

  <uni-nav-category
    *ngIf="isCommunicationPlatform"
    categoryName="navigation.olderProducts"
  >
    <uni-nav-item
      [isSidebar]="true"
      *ngIf="isCommunicationPlatform"
      (click)="navigateToProduct(productKey.UC, '/access-old-product')"
    >
      <uni-nav-item-name>
        {{ 'navigation.communicationPlatform' | translate }}
      </uni-nav-item-name>
    </uni-nav-item>
  </uni-nav-category>
</div>
