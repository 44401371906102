import { Inject, Injectable, Optional } from '@angular/core';
import { get } from 'lodash';
import { Environment } from '../../utils';

import { SettingsService } from '../settings';
import { UniRouteService } from '../uni-route.service';
import { ProductKey } from './products.model';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  constructor(
    private settingsService: SettingsService,
    private uniRouteService: UniRouteService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getProductUrl(productKey: ProductKey): string {
    switch (productKey) {
      case ProductKey.UC: {
        return get(this.settingsService, 'settings.ucUrl') || get(this.environment, 'ucUrl');
      }
      case ProductKey.CC: {
        return get(this.settingsService, 'settings.ccUrl') || get(this.environment, 'ccUrl');
      }
      case ProductKey.CONV: {
        return get(this.settingsService, 'settings.convUrl') || get(this.environment, 'convUrl');
      }
      case ProductKey.NOTICE: {
        return get(this.settingsService, 'settings.noticeUrl') || get(this.environment, 'noticeUrl');
      }
      case ProductKey.AUTHENTICATE: {
        return get(this.settingsService, 'settings.authenticateUrl') || get(this.environment, 'authenticateUrl');
      }
      case ProductKey.FLOW_STUDIO: {
        return get(this.settingsService, 'settings.flowStudioUrl') || get(this.environment, 'flowStudioUrl');
      }
      case ProductKey.PUSH: {
        return get(this.settingsService, 'settings.pushUrl') || get(this.environment, 'pushUrl');
      }
      case ProductKey.ULINK: {
        return get(this.settingsService, 'settings.ulinkUrl') || get(this.environment, 'ulinkUrl');
      }
      default: {
        return get(this.settingsService, 'settings.ucUrl') || get(this.environment, 'ucUrl');
      }
    }
  }

  navigate(productKey: ProductKey, path: string = ''): void {
    const MfeApps = [
      ProductKey.UC,
      ProductKey.CC
    ];
    let url;

    if (!MfeApps.includes(productKey)) {
      url = this.getProductUrl(productKey);
      this.uniRouteService.navigate(`${url}${path}`);
      return;
    }

    switch (productKey) {
      case ProductKey.UC: {
        url = 'cloud';
        break;
      }
      case ProductKey.CC: {
        url = 'communication';
        break;
      }
    }

    this.uniRouteService.navigate(`${Environment.getMainHost()}/${url}${path}`);
  }

  logout(): void {
    window.location.href = `${Environment.getMainHost(Environment.APP.CONSOLE)}/logout`;
  }

  stopImpersonation(): void {
    const url = `${Environment.getMainHost()}/impersonate/stop`;
    this.uniRouteService.navigate(url);
  }
}
