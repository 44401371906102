import { Action } from '@ngrx/store';

import { Timezone } from '../../shared';

export const SET_TIMEZONES = '[Timezones] SET_TIMEZONES';
export const SET_TIMEZONES_SUCCESS = '[Timezones] SET_TIMEZONES_SUCCESS';

export class SetTimezones implements Action {
  readonly type = SET_TIMEZONES;

  constructor() { }
}

export class SetTimezonesSuccess implements Action {
  readonly type = SET_TIMEZONES_SUCCESS;

  constructor(public response: Timezone[]) { }
}

export type All =
  | SetTimezones
  | SetTimezonesSuccess;
