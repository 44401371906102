import { concat, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import * as QueryActions from '../../../store/query/query.actions';
import { UniRolesRepository } from '../shared/uni-roles.repository';
import * as UniRolesActions from './uni-roles.actions';
import { GET_ROLES_QUERY } from './uni-roles.state';

@Injectable()
export class UniRolesEffects {
  constructor(
    private actions$: Actions,
    private uniRolesRepository: UniRolesRepository,
  ) { }

  @Effect() setRoles$ = this.actions$
    .pipe(
      ofType<UniRolesActions.SetRoles>(UniRolesActions.SET_ROLES),
      mergeMap(({ userId, assignableByCurrentUser }) => concat(
        of(new QueryActions.QueryInProgress(GET_ROLES_QUERY)),
        this.uniRolesRepository
          .getRoles(userId, assignableByCurrentUser)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_ROLES_QUERY, data)),
              of(new UniRolesActions.SetRolesSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_ROLES_QUERY, error)),
            )),
          ),
        ),
      ),
    );
}
