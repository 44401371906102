import { Component, Input } from '@angular/core';
import { UniFormInfoType } from '../../shared/uni-form.model';

@Component({
  selector: 'uni-form-field-info',
  templateUrl: './uni-form-field-info.component.html',
  styleUrls: ['./uni-form-field-info.component.scss']
})
export class UniFormFieldInfoComponent {
  @Input() type = UniFormInfoType.normal;

  get class(): string {
    return `uni-form-field-info--${ this.type }`;
  }
}
