import { capitalize, sum } from 'lodash';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SmsCounter } from '../../../../shared/uni-sms-counter.utils';
import { UniTextColor, UniTextWeight } from '../../../uni-text/shared/uni-text.model';
import { UniFormInfoType } from '../../../uni-form/shared/uni-form.model';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { UniCampaignRepository } from '../../shared/uni-campaigns.repository';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';
import { forkJoin, Subscription } from 'rxjs';
import { UniFeatureFlagsService } from '../../../uni-auth/shared/uni-feature-flags.service';
import { FeatureFlagKeys } from '../../../uni-auth/shared/uni-feature-flags.model';
import { filter } from 'rxjs/operators';
import { PackagesRepository } from '../../../../shared/uni-data/repository/packages.repository';

@Component({
  selector: 'cc-campaign-balance-estimation',
  templateUrl: './campaign-balance-estimation.component.html',
  styleUrls: ['./campaign-balance-estimation.component.scss']
})
export class CampaignBalanceEstimationComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  counter: SmsCounter.Counter;
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;
  uniFormInfoType = UniFormInfoType;
  accountId = this.uniAuthFacade.user.account?.id;
  currentMessageUnits: number;
  totalUnits: number;
  isLowBalanceAlertEnabled = false;
  isShowLowBalanceAlert = false;

  get contentControl(): FormControl {
    return this.form.get('contentForm.content') as FormControl;
  }

  get fileRecipientsControl(): FormControl {
    return this.form.get('contentForm.recipients.fileRecipients') as FormControl;
  }

  get phoneNumbersControl(): FormControl {
    return this.form.get('contentForm.recipients.phoneNumbers') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.form.get('contentForm.recipients.audienceGroups') as FormControl;
  }

  constructor(
    private translateService: TranslateService,
    private uniCampaignRepository: UniCampaignRepository,
    private packagesRepository: PackagesRepository,
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.counter = SmsCounter.init(this.contentControl.value);
    this.calculateMessageUnits();
    this.subs.add(
      this.checkFeatureFlags(),
    );
  }

  getMessages(): number {
    return this.counter.smsLength;
  }

  getRecipients(): number {
    if (!!this.fileRecipientsControl) {
      return this.fileRecipientsControl.value;
    }

    if (!!this.phoneNumbersControl) {
      const phoneNumbersValue = this.phoneNumbersControl.value || [];
      return phoneNumbersValue.length;
    }

    if (!!this.groupsControl && !!this.groupsControl.value) {
      return sum(this.groupsControl.value.map(item => item.contacts));
    }

    return 0;
  }

  getCalculation(): string {
    const messagesAmount = this.getMessages();
    const recipientsAmount = this.getRecipients();

    const messages = `${messagesAmount} ${capitalize(this.translateService.instant(messagesAmount === 1
      ? 'label.message'
      : 'label.messages'))
    }`;
    const recipients = `${recipientsAmount} ${this.translateService.instant(recipientsAmount === 1
      ? 'label.recipient'
      : 'label.recipients')
    }`;

    return `(${messages} x ${recipients})`;
  }

  private calculateMessageUnits(): void {
   this.currentMessageUnits = this.getMessages() * this.getRecipients();
  }

  private calculateTotalUnits(): void {
    this.subs.sink = forkJoin([
      this.uniCampaignRepository.getAccountBalance(this.accountId),
      this.packagesRepository.getActivePackages(),
    ])
    .subscribe(([balanceRes, unitsRes]) => {
      const units = unitsRes ? Number(unitsRes.units): 0;
      const balanceUnits = this.uniCampaignRepository.convertBalanceToUnits(balanceRes);
      this.totalUnits = units + Number((balanceUnits));
      this.isShowLowBalanceAlert = this.isLowBalanceAlertEnabled && (this.currentMessageUnits > this.totalUnits);
    });
  }

  checkFeatureFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
    .pipe(
      filter(featureFlags => !!featureFlags)
    )
    .subscribe(() => {
      this.isLowBalanceAlertEnabled = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.campaign_low_balance_alert_2022_q_4);
      if(this.isLowBalanceAlertEnabled) {
        this.calculateTotalUnits();
      }
    });
  }

}
