import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberCommas'
})
export class NumberCommasPipe implements PipeTransform {
  transform(value: string | number, fix = 2): string {
    const num = Number(value).toFixed(fix);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
