import { Component, Input, TemplateRef } from '@angular/core';
import { UniTopBarComponent } from '../../../uni-top-bar/components/uni-top-bar/uni-top-bar.component';
import { UniNavFacade } from '../../../uni-nav';

@Component({
  selector: 'uni-app-logged',
  templateUrl: './uni-app-logged.component.html',
  styleUrls: ['./uni-app-logged.component.scss'],
})
export class UniAppLoggedComponent {
  @Input() topBarTemplate: TemplateRef<UniTopBarComponent>;
  @Input() menuContentTemplate: TemplateRef<UniTopBarComponent>;
  isProductNavOpen$ = this.uniNavFacade.isProductNavOpen$;

  constructor(private uniNavFacade: UniNavFacade) {}

  onOverlayClick() {
    this.uniNavFacade.setProductNavState(false);
    this.uniNavFacade.setNavState(false);
  }
}
