<uni-section *ngIf="contentForm">
  <uni-box>

    <uni-box-content>
      <uni-section-header size="medium">
        <uni-text-caption>
          {{ 'campaign.content' | translate }}
        </uni-text-caption>
      </uni-section-header>
      <cc-campaign-content-type [form]="form" [isDisabled]="isContentTypeDisabled"></cc-campaign-content-type>
    </uni-box-content>

    <cc-campaign-custom-form
      *ngIf="isCustomForm"
      [form]="form"
    ></cc-campaign-custom-form>

    <cc-campaign-file-to-sms-form
      *ngIf="isFileToSMSForm"
      [form]="form"
    ></cc-campaign-file-to-sms-form>

    <uni-box-footer>
      <uni-buttons>
        <uni-button
          *ngIf="!isNextStep"
          [isMargin]="true"
          [style]="'secondary'"
          routerLink="/campaigns/sms"
        >
          {{ 'action.cancel' | translate }}
        </uni-button>
        <uni-button
          *ngIf="contentForm.enabled"
          type="submit"
          (click)="onCheckBlockWords()"
          [disabled]="!contentForm.valid || !isRecipientsFieldValid"
          [isMargin]="true"
        >
          {{ 'label.confirmStep' | translate }}
        </uni-button>
        <uni-button
          *ngIf="contentForm.disabled"
          [isMargin]="true"
          [style]="'secondary'"
          (click)="onEditStep()"
        >
          {{ 'label.editStep' | translate }}
        </uni-button>
      </uni-buttons>
    </uni-box-footer>

  </uni-box>
</uni-section>
