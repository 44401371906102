<ng-container [formGroup]="contentForm">
  <uni-form-container>
    <uni-form-field [margin]="false">
      <uni-label [required]="isEditable">
        {{ isEditable || templateControl.value?.length === 0 ? ('campaign.messageContent' | translate) : ('campaign.messageContentTemplate' | translate:{ template: templateControl.value }) }}
        <uni-link
          *ngIf="isChooseTemplatesVisible"
          type="secondary"
          icon="fa-comment-alt-lines"
          class="campaign-message__link"
          (click)="isModalTemplateActive = true"
          [isDisabled]="isChooseTemplatesDisabled"
          uniLabelSuffix
        >
          {{ 'campaign.chooseFromTemplates' | translate }}
        </uni-link>
      </uni-label>
      <textarea
        class="campaign-message__textarea"
        [class.campaign-message__textarea--is-rtl]="isTextBoxForcedToRtl"
        type="text"
        formControlName="content"
        [placeholder]="'campaign.messageContentPlaceholder' | translate"
        [required]="true"
        [readOnly]="!isActive"
        uniInput
      ></textarea>
      <div class="campaign-message__footer">
        <uni-form-message-counter
          [isSMSCounter]="true"
          [message]="contentControl.value"
          [disabled]="!isEditable || contentForm.disabled"
        ></uni-form-message-counter>
        <uni-link
          *ngIf="isChanged && isEditable"
          type="secondary"
          (click)="isModalActive = true"
          icon="fa-comment-alt-medical"
          [isDisabled]="contentForm.disabled"
          uniLabelSuffix
        >
          {{ 'campaign.saveTemplate' | translate }}
        </uni-link>
      </div>
    </uni-form-field>
  </uni-form-container>
</ng-container>

<cc-campaign-save-template-modal
  *ngIf="isActive && isModalActive"
  [form]="templateForm"
  [contentControl]="contentControl"
  [control]="newTemplateNameControl"
  [isActive]="isModalActive"
  (isActiveChange)="changeIsModalActive($event)"
  (disable)="isSubmitted()"
></cc-campaign-save-template-modal>

<cc-campaign-template-name-modal
  *ngIf="isModalTemplateActive"
  [control]="templateNameControl"
  [form]="templateForm"
  [templateNames]="templateNames"
  [placeholder]="modalPlaceholder"
  [isActive]="isModalTemplateActive"
  (isActiveChange)="changeIsModalTemplateActive($event)"
  (applyButtonClicked)="applyTemplate()"
></cc-campaign-template-name-modal>
