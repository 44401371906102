import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UniCampaignsStateModule } from './store/uni-campaigns-state.module';
import { CampaignCancelModalComponent } from './components/campaign-cancel-modal/campaign-cancel-modal.component';
import { CampaignDetailComponent } from './components/campaign-detail/campaign-detail.component';
import { CampaignDetailsComponent } from './components/campaign-details/campaign-details.component';
import { CampaignMessagesComponent } from './components/campaign-messages/campaign-messages.component';
import { CampaignProgressBarComponent } from './components/campaign-progress-bar/campaign-progress-bar.component';
import { CampaignProgressComponent } from './components/campaign-progress/campaign-progress.component';
import { CampaignStatusComponent } from './components/campaign-status/campaign-status.component';
import { CampaignSummaryComponent } from './components/campaign-summary/campaign-summary.component';
import { CampaignTabsComponent } from './components/campaign-tabs/campaign-tabs.component';
import { CampaignTableComponent } from './components/campaign-table/campaign-table.component';
import { CampaignTableStatusComponent } from './components/campaign-table-status/campaign-table-status.component';
import {
  CampaignBalanceEstimationComponent
} from './components/campaign-balance-estimation/campaign-balance-estimation.component';
import { CampaignCustomFormComponent } from './components/campaign-custom-form/campaign-custom-form.component';
import { CampaignDateComponent } from './components/campaign-date/campaign-date.component';
import {
  CampaignFileToSMSFormComponent
} from './components/campaign-file-to-sms-form/campaign-file-to-sms-form.component';
import { CampaignFileComponent } from './components/campaign-file/campaign-file.component';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { CampaignMessageComponent } from './components/campaign-message/campaign-message.component';
import { CampaignNameComponent } from './components/campaign-name/campaign-name.component';
import {
  CampaignRecipientsAutocompleteModalComponent
} from './components/campaign-recipients-autocomplete-modal/campaign-recipients-autocomplete-modal.component';
import {
  CampaignRecipientsModalComponent
} from './components/campaign-recipients-modal/campaign-recipients-modal.component';
import { CampaignRecipientsComponent } from './components/campaign-recipients/campaign-recipients.component';
import {
  CampaignSaveTemplateModalComponent
} from './components/campaign-save-template-modal/campaign-save-template-modal.component';
import {
  CampaignScheduleToggleComponent
} from './components/campaign-schedule-toggle/campaign-schedule-toggle.component';
import { CampaignSenderNameComponent } from './components/campaign-sender-name/campaign-sender-name.component';
import { CampaignSummaryDateComponent } from './components/campaign-summary-date/campaign-summary-date.component';
import { CampaignSummaryFormComponent } from './components/campaign-summary-form/campaign-summary-form.component';
import {
  CampaignTemplateNameModalComponent
} from './components/campaign-template-name-modal/campaign-template-name-modal.component';
import { CampaignTimeComponent } from './components/campaign-time/campaign-time.component';
import { CampaignContentTypeComponent } from './components/campaign-content-type/campaign-content-type.component';
import { CampaignContentFormComponent } from './components/campaign-content-form/campaign-content-form.component';
import { CampaignDatesFormComponent } from './components/campaign-dates-form/campaign-dates-form.component';
import { CampaignNameFormComponent } from './components/campaign-name-form/campaign-name-form.component';
import { CampaignCountryAutocompleteComponent } from './components/campaign-country-autocomplete/campaign-country-autocomplete.component';
// eslint-disable-next-line
import { CampaignCountryNewAutocompleteComponent } from './components/campaign-country-new-autocomplete/campaign-country-new-autocomplete.component';
import { CampaignListTabsComponent } from './components/campaign-list-tabs/campaign-list-tabs.component';
import { UniCampaignFormFactory } from './shared/uni-campaign-form.factory';
import { UniCampaignFormService } from './shared/uni-campaign-form.service';
import { UniCampaignNewGuard } from './shared/uni-campaign-new.guard';
import { UniCampaignListGuard } from './shared/uni-campaign-list.guard';
import { UniCampaignGuard } from './shared/uni-campaign.guard';
import { UniCommonModule } from '../../uni-common.module';
import { CampaignListHeaderComponent } from './components/campaign-list-header/campaign-list-header.component';
import { CampaignRetriesComponent } from './components/campaign-retries/campaign-retries.component';
import { CampaignGroupsModalComponent } from './components/campaign-groups-modal/campaign-groups-modal.component';
import { CampaignContentModalComponent } from './components/campaign-content-modal/campaign-content-modal.component';
import { CampaignRecipientsGroupsComponent } from './components/campaign-recipients-groups/campaign-recipients-groups.component';
import { CampaignRecipientsFileComponent } from './components/campaign-recipients-file/campaign-recipients-file.component';
import { CampaignRecipientsManuallyComponent } from './components/campaign-recipients-manually/campaign-recipients-manually.component';
import { CampaignRecipientsResultComponent } from './components/campaign-recipients-result/campaign-recipients-result.component';
import { Uni2faModule } from '../uni-2fa/uni-2fa.module';
import { CampaignMessageTypeComponent } from './components/campaign-message-type/campaign-message-type.component';
import { CampaignCSVLimtInfoComponent } from './components/campaign-csv-limt-info/campaign-csv-limt-info.component';
import { CampaignCsvErrorComponent } from './components/campaign-csv-error/campaign-csv-error.component';
import { CampaignOverviewComponent } from './components/campaign-overview/campaign-overview.component';
import { CampaignRejectionReasonsComponent } from './components/campaign-rejection-reasons/campaign-rejection-reasons.component';
import { CampaignPerformanceComponent } from './components/campaign-performance/campaign-performance.component';
import { CampaignPieChartService } from './components/campaign-pie-chart/campaign-pie-chart.service';
import { CampaignPieChartComponent } from './components/campaign-pie-chart/campaign-pie-chart.component';
import { CampaignTrialInfoComponent } from './components/campaign-trial-info/campaign-trial-info.component';

const components = [
  CampaignPieChartComponent,
  CampaignPerformanceComponent,
  CampaignRejectionReasonsComponent,
  CampaignDetailComponent,
  CampaignDetailsComponent,
  CampaignOverviewComponent,
  CampaignMessagesComponent,
  CampaignProgressComponent,
  CampaignProgressBarComponent,
  CampaignSummaryComponent,
  CampaignStatusComponent,
  CampaignCancelModalComponent,
  CampaignTabsComponent,
  CampaignTableComponent,
  CampaignTableStatusComponent,
  CampaignFormComponent,
  CampaignFileToSMSFormComponent,
  CampaignSenderNameComponent,
  CampaignRecipientsComponent,
  CampaignRecipientsModalComponent,
  CampaignMessageComponent,
  CampaignFileComponent,
  CampaignSaveTemplateModalComponent,
  CampaignTemplateNameModalComponent,
  CampaignNameComponent,
  CampaignDateComponent,
  CampaignScheduleToggleComponent,
  CampaignTimeComponent,
  CampaignSummaryFormComponent,
  CampaignSummaryDateComponent,
  CampaignRecipientsAutocompleteModalComponent,
  CampaignBalanceEstimationComponent,
  CampaignContentModalComponent,
  CampaignContentTypeComponent,
  CampaignContentFormComponent,
  CampaignDatesFormComponent,
  CampaignCustomFormComponent,
  CampaignNameFormComponent,
  CampaignCountryAutocompleteComponent,
  CampaignCountryNewAutocompleteComponent,
  CampaignListTabsComponent,
  CampaignListHeaderComponent,
  CampaignRetriesComponent,
  CampaignGroupsModalComponent,
  CampaignRecipientsGroupsComponent,
  CampaignRecipientsFileComponent,
  CampaignRecipientsManuallyComponent,
  CampaignRecipientsResultComponent,
  CampaignMessageTypeComponent,
  CampaignCSVLimtInfoComponent,
  CampaignCsvErrorComponent,
  CampaignTrialInfoComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    UniCampaignsStateModule,
    UniCommonModule,
    Uni2faModule,
  ],
  providers: [
    UniCampaignFormFactory,
    UniCampaignFormService,
    UniCampaignNewGuard,
    UniCampaignListGuard,
    UniCampaignGuard,
    CampaignPieChartService
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
})
export class UniCampaignModule { }
