import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UniCampaignsReducer } from './uni-campaigns.reducers';
import { UniCampaignEffects } from './uni-campaigns.effects';
import { UniCampaignsFacade } from '../shared/uni-campaigns.facade';
import { UniCampaignRepository } from '../shared/uni-campaigns.repository';
import { UNI_CAMPAIGN_KEY } from './uni-campaigns.state';

@NgModule({
  imports: [
    StoreModule.forFeature(UNI_CAMPAIGN_KEY, UniCampaignsReducer),
    EffectsModule.forFeature([UniCampaignEffects]),
  ],
  providers: [
    UniCampaignsFacade,
    UniCampaignRepository,
  ]
})
export class UniCampaignsStateModule { }
