import { Injectable, ElementRef } from '@angular/core';
import { Chart, ChartData, ChartDataset } from 'chart.js';
import { color, hexToRgba } from '../../../../utils/color.utils';
import { UniPieChartService } from '../../../uni-chart/shared/uni-pie-chart.service';
import { CampaignChartData } from '../../shared/uni-campaign.model';

@Injectable()
export class CampaignPieChartService {
  chart: Chart;

  constructor(
    private uniPieChartService: UniPieChartService,
  ) {}

  init(elementRef: ElementRef, data: CampaignChartData): Chart {
    const chartData = this.chartData(data);
    return this.uniPieChartService.init(elementRef, chartData, true, 75);
  }

  chartData(data: CampaignChartData): ChartData {
    return {
      datasets: [
        this.getMainDataset(data),
      ],
    };
  }

  getMainDataset(data: CampaignChartData): ChartDataset  {
    return {
      data: [ data.value, data.total - data.value ],
      backgroundColor: [hexToRgba(data.color, 1), hexToRgba(color.greyLight, 1)],
      hoverBorderWidth: 0,
      borderColor: hexToRgba(color.alertLight, 0),
      borderWidth: 1,
      weight: 0.4,
    };
  }
}
