import { Observable } from 'rxjs';
import { get } from 'lodash';
import { Injectable, Optional, Inject } from '@angular/core';

import { Operator, Operators, OperatorsParams  } from '../model/operator.model';
import { SettingsService } from '../../settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { OPERATORS_KEY } from '../store/uni-data.state';
import { httpEncoder } from '../../../utils/http.utils';

@Injectable({ providedIn: 'root' })
export class OperatorsRepository {
  apiUrl = this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}

  getOperators(): Observable<Operator[]> {
    return this.http.get<Operator[]>(`${ this.apiUrl }/operators`, {});
  }

  getOperatorsChoice(queryParams?: OperatorsParams): Observable<Operators> {
    return this.http.get<Operators>(`${ this.apiUrl }/operators/list/choice`, {
      params: httpEncoder(queryParams),
      headers: {
        queryName: 'getOperatorsChoice',
        queryGroups: [OPERATORS_KEY]
      }
    });
  }
}
