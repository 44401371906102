import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UniRouteService {
  constructor(@Inject(DOCUMENT) private document) {}

  navigate(url: string) {
    this.document.location.href = url;
  }

  getCurrentLocation(): string {
    return this.document.location.href;
  }
}
