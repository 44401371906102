import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniIconModule } from '../uni-icon/uni-icon.module';
import { UniClipboardComponent } from './components/uni-clipboard/uni-clipboard.component';
import { UniMoreItemsComponent } from './components/uni-more-items/uni-more-items.component';

const components = [
  UniClipboardComponent,
  UniMoreItemsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UniIconModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniClipboardModule { }
