import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AutoUnsubscribe } from '../../../../utils';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';
import { HttpQueryFacade, HttpQueryStatus } from '../../../../store/http-query';
import { PUT_TROUBLESHOOTING_MODE } from '../../../../shared/uni-data/store/uni-data.state';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';

@Component({
  selector: 'uni-top-bar-menu',
  templateUrl: './uni-top-bar-menu.component.html',
  styleUrls: ['./uni-top-bar-menu.component.scss']
})
export class UniTopBarMenuComponent extends AutoUnsubscribe implements OnInit {
  isProfileNavOpen = false;

  constructor(
    private uniNavFacade: UniNavFacade,
    private httpQueryFacade: HttpQueryFacade,
    private uniAuthFacade: UniAuthFacade,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.selectprofileNavToggle();
    this.subs.sink = this.watchTroubleshootingChange();
  }

  selectprofileNavToggle(): Subscription {
    return this.uniNavFacade.isProfileNavOpen$.subscribe(state => {
      this.isProfileNavOpen = state;
    });
  }

  watchTroubleshootingChange(): Subscription {
    return this.httpQueryFacade.status$(PUT_TROUBLESHOOTING_MODE)
      .pipe(filter(status => status === HttpQueryStatus.Success))
      .subscribe(() => this.uniAuthFacade.setUserMe(false));
  }
}
