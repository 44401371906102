<uni-modal
  [isCloseIcon]="true"
  [isActive]="isActive"
  (isActiveChange)="changeActive($event)"
>
  <uni-modal-content>
    <uni-form-autocomplete
      [placeholder]="'campaign.' + placeholder | translate"
      [control]="control"
      [options]="templateNames"
      [isLoading]="!(templateNames)"
      filterKey="name"
    ></uni-form-autocomplete>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button
      [style]="'primary'"
      [disabled]="!control.value"
      (click)="changeActive(false, true)"
    >
      {{ 'action.apply' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
