import { QueryActions, queryReducer } from '../../../store/query';
import * as UniAccountsActions from './uni-accounts.actions';
import { GET_ACCOUNTS_QUERY, uniAccountsInitialState, UniAccountsState } from './uni-accounts.state';

const queryState = (state: UniAccountsState, action: UniAccountsActions.All | QueryActions.All): UniAccountsState => ({
  ...state,
  ...queryReducer<UniAccountsState>([ GET_ACCOUNTS_QUERY ], action as QueryActions.All),
});

export function uniAccountsReducer(
  state = uniAccountsInitialState,
  action: UniAccountsActions.All,
): UniAccountsState {
  switch (action.type) {
    case UniAccountsActions.SET_UNI_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: action.response,
      };
    }
    case UniAccountsActions.SET_UNI_ACCOUNTS_ERROR: {
      return {
        ...state,
        accounts: [],
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
