import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { map, startWith, tap, withLatestFrom } from 'rxjs/operators';

import { AutoUnsubscribe } from '../../../../utils';
import { NavActiveItems, NavItemToggleType, NavLink, UniNavService } from '../../shared';

@Component({
  selector: 'uni-nav-new-item-container',
  templateUrl: './uni-nav-item-container.component.html',
  styleUrls: ['./uni-nav-item-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavItemContainerComponent extends AutoUnsubscribe implements OnInit {
  @Input() id: string;
  @Input() iconName: string;
  @Input() labelKey: string;
  @Input() link: NavLink;
  @Input() toggleType: NavItemToggleType = NavItemToggleType.none;
  @Input() isSubitem = false;
  @Input() isExpanded = false;

  @Output() toggle = new EventEmitter<boolean>();

  isNavCollapsed$ = this.uniNavService.isNavCollapsed$;
  isRouteActive$ = this.uniNavService.activeMenuItems$
    .pipe(
      map((activeItems: NavActiveItems) => this.isRouteActive(activeItems)),
      startWith(false),
    );

  onClickSubject = new Subject<void>();
  onClick$ = this.onClickSubject
    .pipe(
      withLatestFrom(this.isRouteActive$),
      tap(([_, isRouteActive]) => this.onClickInternal(isRouteActive)),
    );

  constructor(private readonly uniNavService: UniNavService) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.onClick$.subscribe();
  }

  onClick(): void {
    this.onClickSubject.next();
  }

  private onClickInternal(isRouteActive: boolean): void {
    if (this.link && !isRouteActive && this.toggleType === NavItemToggleType.none) {
      this.uniNavService.navigate(this.link);
    }

    this.toggle.next();
  }

  private isRouteActive(activeItems: NavActiveItems): boolean {
    const activeItemId = this.isSubitem ? activeItems.subitemId : activeItems.itemId;
    return activeItemId === this.id;
  }
}
