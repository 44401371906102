<div class="uni-footer__container">
  <div
    *ngIf="!!user"
    class="uni-footer__links"
  >
    <a
      class="uni-footer__link"
      [href]="user.systemSettings.isWhitelabelActive ? 'mailto:' + user.systemSettings.emailSupport : 'https://support.unifonic.com/'"
    >
      {{ 'footer.support' | translate }}
    </a>

    |

    <a
      class="uni-footer__link"
      [href]="'footer.cookiesLink' | translate"
    >
      {{ 'footer.cookies' | translate }}
    </a>

    |

    <a
      class="uni-footer__link"
      [href]="'footer.privacyLink' | translate"
    >
      {{ 'footer.privacy' | translate }}
    </a>

    |

    <a
      class="uni-footer__link"
      [href]="'footer.termsLink' | translate"
    >
      {{ 'footer.terms' | translate }}
    </a>

    |

    <a
      class="uni-footer__link"
      [href]="'footer.dataLink' | translate"
    >
      {{ 'footer.data' | translate }}
    </a>
  </div>

  <uni-language-switcher
    *ngIf="canSetLanguage()"
    [isMarginLeft]="true"
  ></uni-language-switcher>
</div>

<!--
  Translations keys can be copied
  from attached uni-footer.translations.json file
  and then pulled from phraseApp
-->
