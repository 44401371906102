import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UniLanguageService } from './uni-language.service';

@Injectable()
export class UniLanguageFormFactory {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private uniLanguageService: UniLanguageService
  ) {}

  build(): FormGroup {
    this.uniLanguageService.detectLanguage();
    return this.formBuilder.group({
      locale: this.uniLanguageService.getSelectedLanguage(),
    });
  }
}
