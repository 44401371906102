import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { UniButtonModule } from '../uni-button';
import { UniTextModule } from '../uni-text/uni-text.module';
import {
  UniActionWidgetComponent,
  UniActionWidgetContentComponent,
  UniActionWidgetContainerComponent,
} from './components';
import { UniIconModule } from '../uni-icon';

const components = [
  UniActionWidgetComponent,
  UniActionWidgetContentComponent,
  UniActionWidgetContainerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UniTextModule,
    UniButtonModule,
    UniIconModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniActionWidgetModule { }
