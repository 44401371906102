import { initialState, UniOrdersState } from './uni-orders.state';
import * as OrdersActions from './uni-orders.actions';


export function uniOrdersReducers(state = initialState, action: OrdersActions.All): UniOrdersState {
  switch (action.type) {
    case OrdersActions.CLEAR_ERRORS: {
      return {
        ...state,
        error: undefined,
      };
    }
    case OrdersActions.CREATE_ORDER:
    case OrdersActions.GET_ORDER:
    case OrdersActions.FINALIZE_ORDER: {
      return {
        ...initialState
      };
    }
    case OrdersActions.CREATE_ORDER_SUCCESS:
    case OrdersActions.GET_ORDER_SUCCESS: {
      return {
        ...state,
        order: action.order,
      };
    }
    case OrdersActions.FINALIZE_ORDER_SUCCESS: {
      return {
        ...state,
        finalizedOrder: action.order,
      };
    }
    case OrdersActions.CREATE_ORDER_ERROR:
    case OrdersActions.GET_ORDER_ERROR:
    case OrdersActions.FINALIZE_ORDER_ERROR:{
      return {
        ...state,
        error: action.error,
      };
    }
    default: {
      return { ...state };
    }
  }
}
