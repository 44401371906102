import { gql } from "apollo-angular";

/**
 * QUERY MESSAGE LOGS
 * TODO:
 * add isRealTiem and LastMinutes variables
 *      $lastMinutes: Int,
        $isRealTime: Boolean
 */

const DEFAULT_MESSAGE_LOGS_OUTPUT = `
    sender:senderName
    recipient:receiver
    date:sentByClient
    status:status
    messageId:messageId
`

export const createMessageLogs = (output: string = DEFAULT_MESSAGE_LOGS_OUTPUT) => {
    return gql`
    query MessageLogsFindFactSmsTrafficLogs(
        $offset: Int!,
        $limit: Int!,
        $startDate: String!,
        $endDate: String!,
        $accountId: String!,
        $campaignId: String,
        $senderName: String,
        $eventStatus: String,
        $recipient: String,
        $sourceStatus: String,
        $customerStatus: String,
        $messageStatus: String,
        $messageType: String,
        $channels: Channel,
        $products: Product,
        $isRealTime: Boolean,
        $lastMinutes: Int,
        $orderBy: OrderBy,
        $senderNumber: String,
        $customerReason: String
        $templates: String
        $buttons: String
        $links: String
        $locations: [String]

    ) {
        findFactSmsTrafficLogs(
            offset: $offset
            limit: $limit
            startDate: $startDate
            endDate: $endDate
            accountId: $accountId
            campaignId: $campaignId
            senderName: $senderName
            eventStatus: $eventStatus
            recipient: $recipient
            sourceStatus: $sourceStatus
            customerStatus: $customerStatus
            messageStatus: $messageStatus
            messageType: $messageType
            channels: $channels
            products: $products
            isRealTime: $isRealTime
            lastMinutes: $lastMinutes
            orderBy: $orderBy
            senderNumber: $senderNumber
            customerReason: $customerReason
            templates: $templates
            buttons: $buttons
            links: $links
            locations: $locations
        ) {
            totalCount
            success
            errorCode
            message
            count
            entries {
              ${output}
            }
        }
    }`;
}


/**
 * QUERY MESSAGE LOGS DETAILS
 */


const DEFAULT_MESSAGE_LOGS_DETAILS_OUTPUT = `
    cdrKind: cdr_kind,
    messageId: message_id,
    messageStatus: message_status,
    submitDate: submit_date,
    senderName: sender_name,
    receiver,
    eventStatus: event_status,
    sourceProtocol: source_protocol,
    accountId: account_id,
    networkId: network_id,
    numberOfUnits: number_of_units,
    operatorId: operator_id,
    countryId: country_id,
    providerId: provider_id,
    dlrStatus: dlr_status,
    dlrReceivedDate: dlr_received_date,
    region,
    sourceKind: source_kind,
    shortMessage: short_message,
    failure,
    elMessageId: el_message_id,
    correlationId: correlation_id,
    campaignId: campaign_id,
    messageType: message_type,
    mnpUsed: mnp_used,
    diameterSessionId: diameter_session_id,
    diameterResponseStatus: diameter_response_status,
    normalizedReason: normalized_reason,
    normalizedStatus: normalized_status,
    userSubmitDate: user_submit_date,
    messageBodyLength: message_body_length,
    traceId: trace_id,
    stgCreatedTime: stg_created_time,
    createdTime: created_time,
    updatedTime: updated_time,
    finalrec,
    udhFirstMessageId: udh_first_message_id,
    compoleteMessageBodyFlag: complete_message_body_flag,
    finalMessage: final_message,
    accountName: account_name,
    countryName: country_name
    customerStatus: customer_status
`

export const createMessageLogsDetails = (output: string = DEFAULT_MESSAGE_LOGS_DETAILS_OUTPUT) => {
    return gql`
    query MessageLogsFindMessageById(
        $messageId: String!
        $accountId : String!
        $isRealTime: Boolean!
        $elMessageId: String
        $submitDate: String
    ){
        findMessageById(
            messageId: $messageId
            accountId: $accountId
            isRealTime: $isRealTime
            elMessageId: $elMessageId
            submitDate: $submitDate
        ){
            ${output}
        }
    }
    `
}



const DEFAULT_MESSAGE_LOGS_WHATSAPP_DETAILS_OUTPUT = `
    eventId:event_id
    messageId:message_id
    externalId:external_id
    applicationId:application_id
    accountId:account_id
    contentType:content_type
    channel
    endCustomerContact:end_customer_contact
    commDirection:comm_direction
    createdTime:created_time
    sender
    accountName:account_name
    country
    product
    status
    campaignId:campaign_id
`


export const createMessageLogsWhatsappDetails = (output: string = DEFAULT_MESSAGE_LOGS_WHATSAPP_DETAILS_OUTPUT) => {
    return gql`
    query MessageLogsFindMessageByIdForWhatsApp(
        $messageId: String!
        $accountId : String!
    ){
        findMessageByIdForWhatsApp(
            messageId: $messageId
            accountId: $accountId
        ){
            ${output}
        }
    }
    `
}

const DEFAULT_MESSAGE_LOGS_VOICE_OUTPUT = `
    sender: sender,
    recipient: recipient,
    direction: direction,
    status: status,
    start: start,
    id: id
`

const DEFAULT_MESSAGE_LOGS_VOICE_DETAILS_OUTPUT = `
    id: id,
    sender: sender,
    recipient: recipient,
    status: status,
    date: date,
    channel: channel,
    product: product,
    isRealTime: isRealTime,
    parentCallSid: parentCallSid,
    callSid: callSid,
    secondaryCallerId: secondaryCallerId,
    callSource: callSource,
    type: type,
    eventType: eventType,
    direction: direction,
    accountId: accountId,
    accountName: accountName,
    fromCountry: fromCountry,
    toCountry: toCountry,
    operator: operator,
    failureReason: failureReason,
    start: start,
    end: end,
    duration: duration,
    requestReceivedAt
    start,
    callSource

`

export const createVoiceMessageLogs = (output: string = DEFAULT_MESSAGE_LOGS_VOICE_OUTPUT) => {
  return gql`
  query MessageLogsFindFactVoiceTrafficLogs(
      $offset: Int!,
      $limit: Int!,
      $startDate: String!,
      $endDate: String!,
      $accountId: String!,
      $customerStatus: String,
      $campaignId: String,
      $recipient: String,
      $channels: Channel,
      $products: Product!,
      $orderBy: OrderBy!,
      $caller: String,
      $direction: String,
  ) {
      findFactVoiceTrafficLogs(
        request: {
          offset: $offset
          limit: $limit
          startDate: $startDate
          endDate: $endDate
          accountId: $accountId
          status: $customerStatus
          campaignId: $campaignId
          recipient: $recipient
          channels: $channels
          products: $products
          orderBy: $orderBy
          caller: $caller
          direction: $direction
        }
      ) {
          totalCount
          success
          errorCode
          message
          count
          entries {
            ${output}
          }
      }
  }`;
}


export const createVoiceMessageLogsDetails = (output: string = DEFAULT_MESSAGE_LOGS_VOICE_DETAILS_OUTPUT) => {
  return gql`
  query MessageLogsFindFactVoiceTrafficLogs(
      $id: String!
      $offset: Int!,
      $limit: Int!,
      $startDate: String!,
      $endDate: String!,
      $accountId: String!,
      $customerStatus: String,
      $campaignId: String,
      $caller: String,
      $recipient: String,
      $channels: Channel,
      $products: Product!,
      $orderBy: OrderBy!,
  ){
      findFactVoiceTrafficLogs(
        request: {
          id: $id
          offset: $offset
          limit: $limit
          startDate: $startDate
          endDate: $endDate
          accountId: $accountId
          status: $customerStatus
          campaignId: $campaignId
          caller: $caller
          recipient: $recipient
          channels: $channels
          products: $products
          orderBy: $orderBy
        }
      ){
          totalCount
          success
          errorCode
          message
          count
          entries {
            ${output}
          }
      }
  }
  `
}
