import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"
import { UnishieldFetchStore } from "src/app/shared/data-access/unishield/unishield.store"


type State = {
}

const initialState: State = {
}

@Injectable()
export class BlockedWordsStore extends ComponentStore<State>{

    constructor(
        private unishieldFetchStore: UnishieldFetchStore
    ){
        super(initialState)
    }

    readonly loading$ = this.unishieldFetchStore.loading$


    readonly vm$ = this.select(
        this.loading$,
        this.unishieldFetchStore.entities$,
        (loading, entities) => {
            return {
                loading,
                blocked: entities?.blockedMessagesTotalBlockedMessages || 0,
                savings: entities?.blockedMessagesEstimatedCostSavingUSD || 0
            }
        }
  )
}
