import { Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { SettingsService } from '../../shared/settings';
import { get } from 'lodash';

const PERIOD_REQUEST_PATHS = [
  '/api/v2/packages/active/units',
  '/app/api/v2/user/me',
  '/api/v2/user/me'
];

@Injectable()
export class HttpQuerySessionInterceptor implements HttpInterceptor {
  allowedHosts = ['unifonic.local', 'unifonic.com'];
  cacheKey = 'cache_';

  constructor(
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isUnifonicDomainRequest(request)) { // it's not a unifonic domain
      return next.handle(request);
    }

    const url = request.url.startsWith('http') ? new URL(request.url).pathname : request.url;

    if (!PERIOD_REQUEST_PATHS.includes(url)) { // it's not one of the period requests
      PERIOD_REQUEST_PATHS.forEach((path: string) => {
        localStorage.removeItem(this.getCacheKey(path));
      });
      localStorage.setItem('lastRequest', Date.now().toString());
      return next.handle(request);
    }

    const cachedUserMe = localStorage.getItem(this.getCacheKey(url));
    if (!!cachedUserMe) {
      return of(new HttpResponse({ body: JSON.parse(cachedUserMe) }));
    }

    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        localStorage.setItem(this.getCacheKey(url), JSON.stringify(event.body));
      })
    );
  }

  private isUnifonicDomainRequest(request: HttpRequest<unknown>): boolean {
    let host: string | string[] = request.url.startsWith('http')
      ? new URL(request.url).host
      : window.location.host;

    host = host.replace(/(https?:\/\/)?(www.)?/i, '')
      .split('.');
    host = host.slice(host.length - 2)
      .join('.');

    const envSettings = get(this.settingsService.settings, 'allowedHosts')
    || get(this.environment, 'allowedHosts');

    if (!!envSettings) {
      this.allowedHosts = envSettings.replace(/\s/g, '').split(',');
    }

    return this.allowedHosts.includes(host);
  }

  private getCacheKey(url: string): string {
    return this.cacheKey + url;
  }
}
