<uni-box-content
  class="uni-table-empty__content"
  margin="medium"
  borderRadius="bottom"
>
  <div class="uni-table-empty__title" *ngIf="isTitle">
    <uni-text-subheader [isUppercase]="false">
      {{ title | translate }}
    </uni-text-subheader>
  </div>
  <div class="uni-table-empty__description">
    {{ description | translate }}
    <ng-content></ng-content>
  </div>
</uni-box-content>
