import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { UniAuthFacade } from "@unifonic/common";
import { MessageFiltersUrlParams } from "../log-analyser.models";
import { MessageLogsFetchStore } from "../store/message-logs-fetch.store";
import { MessageLogsFiltersStore } from "../store/message-logs-filters.store";
import { MessageLogsLayoutStore } from "../store/message-logs-layout.store";
import momentTimezone from "moment-timezone";
import { Products } from "src/app/shared/store-mocks/products.store";
import { Channels } from "src/app/shared/store-mocks/channels.store";

type State = {}

const initialState: State = {}

@Injectable()
export class PageIndexStore extends ComponentStore<State>{
    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore,
        private messageLogsLayoutStore: MessageLogsLayoutStore,
        private messageLogsFiltersStore: MessageLogsFiltersStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }



    readonly vm$ = this.select(
        this.messageLogsFetchStore.loaded$,
        this.messageLogsFetchStore.fetchOnInit$,
        this.messageLogsLayoutStore.filterPanelVisibility$,
        this.messageLogsLayoutStore.multiCampaignMode$,
        (loaded, fetchOnInit, filterPanelVisibility, multiCampaignMode) => {
            return {
                loaded, fetchOnInit, filterPanelVisibility,
                multiCampaignMode

            }
        }
    )

    readonly setDefaultFilterState = this.effect((urlParams$: Observable<MessageFiltersUrlParams>) => {
        return urlParams$.pipe(
            tap((qp: MessageFiltersUrlParams) => {

                const timezone = this.authFacade.userMe?.user?.timezone
                const startDate = momentTimezone.tz(qp.startDate, timezone).utc().format()
                const endDate = momentTimezone.tz(qp.endDate, timezone).endOf('day').utc().format()

                const defaultStartDate = momentTimezone.tz(timezone).subtract(6, 'days').startOf('day').utc().format()
                const defaultEndDate = momentTimezone.tz(timezone).subtract(0, 'days').endOf('day').utc().format()

                const f = qp.startDate && qp.startDate !== 'Invalid date' ? startDate : defaultStartDate
                const t = qp.endDate && qp.endDate !== 'Invalid date' ? endDate : defaultEndDate

                const sortByDefault = qp.channels === Channels.VOICE ? 'requestReceivedAt' : 'sentByClient'

                this.messageLogsFiltersStore.patchState({
                    channels: qp.channels || 'SMS',
                    products: qp.products || Products.ALL,
                    dateRange: `${f}_${t}`,
                    recipients: qp.recipients || '',
                    accountId: qp.accountId || this.authFacade.userMe?.user?.account?.id || '',
                    campaignId: qp.campaignId || qp.multiCampaignIds?.join(',') || '',
                    senderName: qp.senderName || '',
                    status: qp.status || '',
                    messageType: qp.messageType || '',
                    page: qp.page || '1',
                    sortBy: qp.sortBy || sortByDefault,
                    sortDir: qp.sortDir || 'DESC',
                    senderNumber: qp.senderNumber || '',
                    autoload: qp.autoLoad || false,
                    templates: qp.templates || '',
                    buttons: qp.buttons || '',
                    links: qp.links || '',
                    locations: qp.locations || '',
                    customerReason: qp.customerReason || '',
                    caller: qp.caller || '',
                    direction: qp.direction || ''
                })

                this.messageLogsLayoutStore.patchState({
                    multiCampaignMode: qp.multiCampaignMode || false,
                })

                this.messageLogsFetchStore.patchState({fetchOnInit: qp.autoLoad || false})
            })
        )
    })
}
