import { values } from 'lodash';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignLog, CampaignLogFilters, CampaignLogMessageStatus } from '../../shared/uni-campaign.model';
import { DateUtils } from '../../../../utils/date.utils';
import { UniFormService } from '../../../uni-form/shared/uni-form.service';
import { UniTableSelection } from '../../../uni-table/shared/uni-table-selection.helper';
import { OperatorsFacade } from '../../../../shared/uni-data/facade/operators.facade';

import { CampaignMessagesTableColumns } from './campaign-messages.model';
import { UniTableService } from '../../../uni-table/shared/uni-table.service';

@Component({
  selector: 'cc-campaign-messages',
  templateUrl: './campaign-messages.component.html',
  styleUrls: ['./campaign-messages.component.scss']
})
export class CampaignMessagesComponent extends UniTableSelection<CampaignLog> implements OnInit, OnChanges {
  @ViewChild('table', { static: false }) table: ElementRef;
  @Input() logs: CampaignLog[] = [];
  @Input() totalItems = 0;
  @Input() campaignId: string;
  @Input() isFiltered = false;
  @Output() export = new EventEmitter();
  statuses = [];
  filters = CampaignLogFilters;
  columns = CampaignMessagesTableColumns;
  unsubscribe$ = new Subject();
  messageVisibleIndex: number;
  tableLeft: number;
  dateUtils = DateUtils;
  operators$ = this.operatorsFacade.operators$.pipe(filter(data => !!data && !!data.length));
  dataSource = new MatTableDataSource<CampaignLog>(this.logs);

  get displayedColumns(): string[] {
    return values(CampaignMessagesTableColumns);
  }

  constructor(
    private operatorsFacade: OperatorsFacade,
    public uniFormService: UniFormService,
    public uniTableService: UniTableService
  ) {
    super();
  }

  ngOnInit() {
    setTimeout(() => this.tableLeft = this.getTableOffsetLeft(), 0);
    this.updateTable();
    this.statuses = this.uniTableService.getFilterOptions(CampaignLogMessageStatus);
  }

  ngOnChanges() {
    this.updateTable();
  }

  getTableOffsetLeft(): number {
    return this.table.nativeElement.getBoundingClientRect().left;
  }

  getItems(totalItems: number = 0) {
    return totalItems;
  }

  updateTable() {
    if (this.logs) {
      this.dataSource.data = this.logs;
    }
  }

  onExport(): void {
    this.export.emit(true);
  }

  isDisabled(logs = []) {
    return !logs || logs.length <= 0;
  }
}
