import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UniLayoutModule } from '../uni-layout/uni-layout.module';
import { UniSnackbarModule } from '../uni-snackbar';
import { UniTopBarModule } from '../uni-top-bar/uni-top-bar.module';
import { UniAppLoggedComponent } from './containers/uni-app-logged/uni-app-logged.component';
import { UniFooterModule } from '../uni-footer';

const components = [
  UniAppLoggedComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UniTopBarModule,
    UniLayoutModule,
    UniSnackbarModule,
    UniFooterModule,
    RouterModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniAppModule { }
