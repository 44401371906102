import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'uni-widget-content-value',
  templateUrl: './uni-widget-content-value.component.html',
  styleUrls: ['./uni-widget-content-value.component.scss'],
})
export class UniWidgetContentValueComponent {
  @HostBinding('class.uni-widget-content-value--margin-bottom') @Input() isSmall = false;
}
