import { Directive, HostListener, Input, OnChanges, OnInit, Self, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { get } from 'lodash';

@Directive({
  selector: '[uniInputDisplayValueFromList]',
})
export class UniInputDisplayValueFromListDirective implements OnInit, OnChanges {
  @Input() valueKey: string;
  @Input() displayValueKey: string;
  @Input() data: any = [];

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value: any) {
    this.writeValue(value);
  }

  private get control(): AbstractControl {
    return this.ctrlDir.control;
  }

  constructor(@Self() private readonly ctrlDir: NgControl) { }

  ngOnInit(): void {
    this.control.setValue(this.control.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onDataChanged(changes.data);
  }

  private onDataChanged(dataChange: SimpleChange): void {
    if (!dataChange) {
      return;
    }
    if (!dataChange.currentValue) {
      this.data = [];
    }

    this.writeValue(this.control.value);
  }

  private writeValue(value: any): void {
    const displayValue = this.getDisplayValue(value);
    this.ctrlDir.valueAccessor.writeValue(displayValue);
  }

  private getDisplayValue(value: any): string {
    const selectedOption = this.data.find((option: any) => get(option, this.valueKey) === value);
    return get(selectedOption, this.displayValueKey, '');
  }
}
