<uni-box-content
  type="transparent"
>
  <uni-section-header
    size="medium"
  >
    <uni-text-caption>
      {{ 'campaign.performance' | translate }}
    </uni-text-caption>
  </uni-section-header>

  <uni-info
    *ngIf="getStatus(campaign)"
    type="hint"
    margin="bottom"
  >
    {{ 'campaign.overviewInfo' | translate }}
  </uni-info>

  <uni-details [isRow]="true">
    <uni-box-content class="campaign-performance__tile">
      <span class="campaign-performance__number">
        {{ campaign.stats?.initialMessages | numberCommas:0 }}
      </span>
      <uni-label [displayInline]="true">
        <uni-tooltip-cloud
          position="top"
          type="success"
        >
          {{ 'table.messages' | translate }}
          <uni-icon
            name="info"
            [isLeftSpace]="true"
          ></uni-icon>

          <div tooltip>
            {{ 'campaign.messagesTip' | translate }}
          </div>
        </uni-tooltip-cloud>
      </uni-label>
      <uni-separator></uni-separator>
      <span
        *ngIf="!!campaign.stats?.messageUnitsConsumed || !!counter"
        class="campaign-performance__number--small"
      >
        {{ (!!campaign.stats?.messageUnitsConsumed ? campaign.stats.messageUnitsConsumed : getUnits(counter.smsLength)) | numberCommas:0 }}
      </span>
      <uni-label [displayInline]="true">
        <uni-tooltip-cloud
          position="top"
          type="success"
        >
          {{ 'label.units' | translate }}
          <uni-icon
            name="info-circle"
            [isLeftSpace]="true"
          ></uni-icon>

          <div tooltip>
            {{ 'campaign.unitsTip' | translate }}
          </div>
        </uni-tooltip-cloud>
      </uni-label>
    </uni-box-content>

    <uni-box-content class="campaign-performance__tile">
      <cc-campaign-pie-chart
        [data]="getChartData('total')"
      ></cc-campaign-pie-chart>
    </uni-box-content>

    <uni-box-content class="campaign-performance__tile--big">
      <cc-campaign-pie-chart
        [data]="getChartData('success')"
        [isBig]="true"
      ></cc-campaign-pie-chart>
    </uni-box-content>

    <uni-box-content class="campaign-performance__tile">
      <cc-campaign-pie-chart
        [data]="getChartData('failed')"
      ></cc-campaign-pie-chart>
    </uni-box-content>
  </uni-details>
</uni-box-content>
