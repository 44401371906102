<div
  class="uni-nav-item-content__main-content uni-nav-item-content__dropdown--{{ toggleType }}"
  [ngClass]="{
    'subitem': isSubitem,
    'is-route-active': isRouteActive
  }"
>
  <uni-nav-new-item-icon
    [iconName]="iconName"
    [labelKey]="labelKey"
    [isNavCollapsed]="isNavCollapsed"
    [isRouteActive]="isRouteActive"
  >
  </uni-nav-new-item-icon>
  <div *ngIf="!isNavCollapsed" class="uni-nav-item-content__label">
    {{ "navigation." + labelKey | translate }}
  </div>
</div>

<ng-container *ngIf="!isNavCollapsed">
  <uni-nav-new-item-toggle
    *ngIf="!isSubitem"
    [toggleType]="toggleType"
    [isExpanded]="isExpanded"
  >
  </uni-nav-new-item-toggle>

  <i
    *ngIf="isSubitem && isExternalLink"
    class="far fa-external-link">
  </i>
</ng-container>
