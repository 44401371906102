import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../utils';
import { FeatureFlagKeys, UniAuthFacade, UniFeatureFlagsService } from '../../../uni-auth';

import {
  UniCampaignsPermissionsService,
  UniCampaignFormService,
  CampaignListTabs,
  getURLFromCcToCampaigns,
  navigate,
} from '../../shared';


@Component({
  selector: 'uni-campaign-list-header',
  templateUrl: './campaign-list-header.component.html',
})
export class CampaignListHeaderComponent extends AutoUnsubscribe implements OnInit {
  hasCreatePermission = this.campaignPermissions.canAdd();
  isDropdownOpen = false;
  type = CampaignListTabs;
  disableVoice = !this.campaignPermissions.canAddVoice();
  isFSEnabled = false;
  isSMSProEnabled = false;

  constructor(
    private router: Router,
    private campaignPermissions: UniCampaignsPermissionsService,
    private campaignFormService: UniCampaignFormService,
    private uniAuthFacade: UniAuthFacade,
    private uniFeatureFlagsService: UniFeatureFlagsService,
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.checkFlags()
    );
  }

  onAddNewCampaign(type: CampaignListTabs) {
    this.campaignFormService.resetFormData();
    const domainURL = `${getURLFromCcToCampaigns()}/management/create`;

    switch (type) {
      case CampaignListTabs.sms:
      case CampaignListTabs.voice:
        this.router.navigate([`/campaigns/${type}/create`]);
        break;
      case CampaignListTabs.whatsapp:
      case CampaignListTabs.flowStudio:
        navigate(`${domainURL}/${type}/settings`);
        break;
      case CampaignListTabs.smsPro:
        navigate(`${domainURL}/${CampaignListTabs.sms}/settings`);
        break;
      default:
        break;
    }
  }

  onToogleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  checkFlags(): Subscription {
    return this.uniAuthFacade.featureFlags$
      .pipe(
        filter(featureFlags => !!featureFlags),
      )
      .subscribe(() => {
        this.isFSEnabled = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.flow_studio_campaigns_2022_q_3);
        this.isSMSProEnabled = this.uniFeatureFlagsService.getPermission(FeatureFlagKeys.sms_pro_2022_q_3);
      });
  }
}
