import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { IndustryState, selectIndustries, selectIndustriesQuery } from '../store/uni-industry';
import * as industryActions from '../store/uni-industry/uni-industry.actions';

@Injectable()
export class UniIndustryFacade {
  industries$ = this.store.pipe(select(selectIndustries));
  industriesQuery$ = this.store.pipe(select(selectIndustriesQuery));

  constructor(private store: Store<IndustryState>) {}

  setIndustries(): void {
    this.store.dispatch(new industryActions.SetIndustries());
  }
}
