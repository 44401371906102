import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_KEY, UniOrdersState } from './uni-orders.state';

export const selectOrdersFeature = createFeatureSelector<UniOrdersState>(ORDERS_KEY);

export const selectOrder = createSelector(
  selectOrdersFeature,
  state => state.order,
);

export const selectFinalizedOrder = createSelector(
  selectOrdersFeature,
  state => state.finalizedOrder,
);

export const selectError = createSelector(
  selectOrdersFeature,
  state => state.error,
);
