import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'uni-audio-progress',
  templateUrl: './uni-audio-progress.component.html',
  styleUrls: ['./uni-audio-progress.component.scss']
})
export class UniAudioProgressComponent {
  @Input() audio: HTMLAudioElement;
  @Input() audioProgress: number;
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const percent = event.offsetX / (event.target as HTMLElement).offsetWidth;
    this.audio.currentTime = percent * this.audio.duration;
    this.audioProgress = percent / 100;
  }
}
