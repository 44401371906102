import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UniSortComponent } from './components';
import { UniIconModule } from '../uni-icon/uni-icon.module';

const components = [
  UniSortComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UniIconModule,
    TranslateModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniSortModule { }
