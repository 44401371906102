import { Component, Input } from '@angular/core';
import { UniTextWeight, UniTextColor } from '../../../uni-text/shared/uni-text.model';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { Campaign, CampaignDataSource } from '../../shared/uni-campaign.model';

@Component({
  selector: 'cc-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.scss'],
})
export class CampaignSummaryComponent {
  @Input() campaign: Campaign;
  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;

  get isAudienceType(): boolean {
    return this.campaign.type === CampaignDataSource.audience;
  }

  get isCustomType(): boolean {
    return this.campaign.type === CampaignDataSource.custom;
  }

  get isFileType(): boolean {
    return this.campaign.type === CampaignDataSource.fileToSMS;
  }

  constructor(
    private campaignFacade: UniCampaignsFacade,
  ) {}

  getFile(name) {
    const fileId = this.campaign.recipients.campaignFile.id;
    this.campaignFacade.setCampaignFile(fileId, name);
  }
}
