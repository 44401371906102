<uni-label *ngIf="label" [required]="required">
  {{ label | translate }}
  <span *ngIf="labelTip.length" uniLabelTip>
    <ng-content select="[uniLabelTip]"></ng-content>
  </span>
</uni-label>
<uni-form-field
  [margin]="margin"
  [ngClass]="{ 'is-loading': isLoading }"
>
  <mat-form-field appearance="standard">
    <input
      matInput
      uniInput
      type="text"
      #autocompleteTrigger
      [placeholder]="isLoading ? ('label.loading' | translate) : (placeholder | translate)"
      [formControl]="control"
      [matAutocomplete]="auto"
      [readonly]="isLoading || isReadOnly"
      [isValidation]="isValidation"
      (blur)="onBlur()"
    >
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="filterKey ? (displayValueFn ? displayValueFn : displayFn.bind(this)) : displayValue.bind(this)"
      (opened)="onOpen()"
      (closed)="onClose()"
    >
      <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
        {{ fetchTranslations ? (translationPath + '.' + getOptionValue(option) | translate) : getOptionValue(option) }}
      </mat-option>
      <mat-option *ngIf="isEmpty(filteredOptions$ | async)" disabled class="uni-form-autocomplete__disabled-options">
        {{ (!isLoading ? 'label.noResults' : 'label.loading') | translate }}
      </mat-option>
      <mat-option *ngIf="moreOptionsToRender > 0" disabled class="uni-form-autocomplete__disabled-options">
        {{ 'label.moreCount' | translate:{count:moreOptionsToRender} }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div
    class="uni-form-autocomplete__icons"
    uniSuffix
  >
    <uni-icon
      *ngIf="!isLoading && control.value && !control.disabled"
      name="times"
      (click)="clearAutocomplete()"
    >
    </uni-icon>
    <uni-icon
      *ngIf="!isLoading && !(control.value && !control.disabled)"
      (click)="toggleAutocomplete(isPanelOpen)"
      [name]="isPanelOpen ? 'chevron-up' : 'chevron-down'"
      [disabled]="control.disabled || isLoading"
    ></uni-icon>
    <uni-icon
      *ngIf="isLoading && !isReadOnly"
      name="spinner fa-spin"
      [disabled]="true"
    ></uni-icon>

  </div>
</uni-form-field>
