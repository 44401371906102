import { Component, HostBinding, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonSize, ButtonStyle, ButtonType } from './uni-button.model';
import { UniIconType } from '../../../uni-icon/components/uni-icon/uni-icon.model';

@Component({
  selector: 'uni-button',
  templateUrl: './uni-button.component.html',
  styleUrls: [
    './uni-button.component.scss',
    './uni-button-sizes.component.scss',
    './uni-button-default.component.scss',
    './uni-button-destructive.component.scss',
  ],
})
export class UniButtonComponent {
  // Links
  @Input() routerLink = '';
  @Input() path: string;
  @Input() queryParams = {};
  @Input() target = '_self';
  @Input() isRelativePath = false;
  @Input() onClick: () => void = () => {
    if (this.disabled) {
      return;
    }

    if (!!this.routerLink) {
      this.router.navigate([this.routerLink], {
        queryParams: this.queryParams,
        relativeTo: this.isRelativePath ? this.activatedRoute : undefined
      });
    }
  };

  // Main styles
  @Input() isDefault = true;
  @Input() disabled = false;
  @Input() style = ButtonStyle.primary;
  @Input() size: ButtonSize = ButtonSize.medium;
  @Input() noHorizontalSpace = false;
  @Input() noVerticalSpace = false;
  @Input() isGroupItem = false;

  // Margins
  @Input() isMargin = false;
  @Input() isMarginVertical = false;
  @Input() isMarginHorizontal = false;

  // Additional styles if part of other elements
  @Input() isSuffix = false;
  @Input() isInputRelated = false;

  // Icons
  @Input() iconLeft: string;
  @Input() iconRight: string;

  // @TODO: deprecated - remove in the future
  @Input() class = '';
  @Input() type: ButtonType = 'button';
  @Input() autoWidth = false;
  @Input() isFullWidth = false;
  @Input() fit = false;
  @Input() fitContent = false;

  @HostBinding('class') get getClasses(): string {
    return [
      this.class,
      this.disabled ? 'is-disabled' : '',
      this.isSuffix ? 'uni-button--suffix' : '',
      this.isMargin ? 'uni-button--margin' : '',
      this.isMarginVertical ? 'uni-button--margin-vertical' : '',
      this.isMarginHorizontal ? 'uni-button--margin-horizontal' : '',
      `uni-button--${this.size}`,
      this.isFullWidth && 'uni-button--full-width',
      this.isInputRelated ? 'uni-button--input-size' : '',
    ].join(' ');
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  getSize(size): string {
    switch (size) {
      case ButtonSize.large: {
        return '200';
      }
      case ButtonSize.extraSmall:
      case ButtonSize.small: {
        return '050';
      }
      default: {
        return '100';
      }
    }
  }

  getIconSize(size): string {
    switch (size) {
      case ButtonSize.large: {
        return '24';
      }
      case ButtonSize.extraSmall:
      case ButtonSize.small: {
        return '16';
      }
      default: {
        return '20';
      }
    }
  }

  getIconType(style, isDefault): string {
    if (isDefault) {
      switch (style) {
        case ButtonStyle.primary: {
          return UniIconType.simple;
        }
        default: {
          return UniIconType.secondary;
        }
      }
    }

    switch (style) {
      case ButtonStyle.primary: {
        return UniIconType.inverse;
      }
      default: {
        return UniIconType.destructive;
      }
    }
  }
}
