import { HttpQueryStatus } from '../http-query.model';

import * as QueryActions from './http-query.actions';
import { httpQueryInitialState, HttpQueryState } from './http-query.state';
import { initialQuery, parseHttpQueryState } from '../http-query.helpers';

export function HttpQueryReducer(
  state = httpQueryInitialState,
  action: QueryActions.All
): HttpQueryState {
  switch (action.type) {
    case QueryActions.QUERY_INIT: {
      return parseHttpQueryState(state, action.queryConfig, initialQuery);
    }
    case QueryActions.QUERY_CLEAR: {
      return parseHttpQueryState(state, action.queryConfig, initialQuery);
    }
    case QueryActions.QUERY_IN_PROGRESS: {
      return parseHttpQueryState(state, action.queryConfig, {
        ...state.queries[action.queryConfig.name],
        status: HttpQueryStatus.InProgress,
        isDirty: true,
        isInProgress: true,
        isSuccess: false,
        isError: false,
      });
    }
    case QueryActions.QUERY_SUCCESS: {
      return parseHttpQueryState(state, action.queryConfig, {
        status: HttpQueryStatus.Success,
        response: (action as QueryActions.success).response,
        error: null,
        isDirty: true,
        isInProgress: false,
        isSuccess: true,
        isError: false,
      });
    }
    case QueryActions.QUERY_FAILURE: {
      return parseHttpQueryState(state, action.queryConfig, {
        status: HttpQueryStatus.Failure,
        response: null,
        error: (action as QueryActions.failure).error,
        isDirty: true,
        isInProgress: false,
        isSuccess: false,
        isError: true,
      });
    }
    default: {
      return { ...state };
    }
  }
}
