import { Component, HostBinding, Input } from '@angular/core';

import { UniSectionSize } from './uni-section.model';

@Component({
  selector: 'uni-section',
  templateUrl: './uni-section.component.html',
  styleUrls: ['./uni-section.component.scss'],
})
export class UniSectionComponent {
  @Input() class = '';
  @Input() size: UniSectionSize = UniSectionSize.default;

  @HostBinding('class') get getClasses(): string {
    return `${this.class} uni-section--${this.size}`;
  }

  @HostBinding('class.is-first') @Input() isFirst = false;

  @HostBinding('class.is-last') @Input() isLast = false;
}
