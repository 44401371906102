<uni-modal
  [isActive]="isActive"
  (isActiveChange)="changeActive($event)"
  [isCloseIcon]="true"
>
  <form [formGroup]="form" (submit)="onSubmit()">
    <uni-modal-content>
      <uni-label>
        {{ 'label.title' | translate }}
      </uni-label>
      <input
        type="text"
        formControlName="newTemplateName"
        [placeholder]="'campaign.newTemplateNamePlaceholder' | translate"
        uniInput
      >
    </uni-modal-content>
    <uni-modal-footer>
      <uni-button
        [style]="'primary'"
        type="submit"
        [disabled]="!(form.valid)"
      >
        {{ 'action.apply' | translate }}
      </uni-button>
    </uni-modal-footer>
  </form>
</uni-modal>
