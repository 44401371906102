<div
  *ngIf="packages$ | async as packages"
  class="uni-top-bar-packages__container"
  (click)="onChange(!isOpen)"
  (uniClickOutside)="onChange(false)"
>
  <div>
    <div
      class="uni-top-bar-packages__label uni-top-bar-packages__label--margin"
    >
      {{ 'topbar.packages' | translate }}
    </div>
    <div class="uni-top-bar-packages__value">
      <ng-container *ngIf="isNumber(packages?.length); else loaderContainer">
        {{ filterActive(packages) }} {{ 'topbar.active' | translate }}
      </ng-container>
    </div>
  </div>
  <div
    class="uni-top-bar-packages__icon-container"
  >
    <uni-icon
      class="uni-top-bar-packages__icon"
      [name]="isOpen ? 'chevron-up' : 'chevron-down'"
    ></uni-icon>
  </div>
  <uni-top-bar-packages-list
    *ngIf="isOpen"
  ></uni-top-bar-packages-list>
</div>

<ng-template #loaderContainer>
  <uni-icon
    name="spinner fa-spin"
  ></uni-icon>
</ng-template>
