import { Component, Input } from '@angular/core';
import * as XRegExp from 'xregexp';
import { Status } from '../../../uni-layout/components/uni-status/uni-status.model';

@Component({
  selector: 'uni-form-validation',
  templateUrl: './uni-form-validation.component.html',
  styleUrls: ['./uni-form-validation.component.scss']
})
export class UniFormValidationComponent {
  @Input() rule: RegExp;
  @Input() value: string;

  validateInput(value: string): string {
    const regex = XRegExp(this.rule);

    return regex.test(value)
      ? Status.success
      : Status.unavailable;
  }
}
