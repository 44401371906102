import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverviewStore } from './overview.store';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OverviewStore]
})
export class OverviewComponent {
  constructor(
    private store: OverviewStore
  ) { }


  ngOnInit(): void {
  }

  vm$ = this.store.vm$
}
