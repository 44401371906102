<uni-box-content *ngIf="campaign">
  <uni-section-header size="medium">
    <uni-text-caption>
      {{ 'campaign.status' | translate }}
    </uni-text-caption>
  </uni-section-header>

  <uni-details>
    <uni-details-item [isBorder]="false">
      <cc-campaign-detail [bold]="true">
        {{ campaign.name }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item columns="2" [isBorder]="false">
      <cc-campaign-detail [bold]="true">
        <ng-container *ngIf="campaign.scheduledAt && campaign.status === campaignStatus.scheduled">
          {{ 'label.scheduledFor' | translate }} {{ campaign.scheduledAt | dateFormat:dateUtils.Format.meridiemDateTime }}
          <uni-button
            *ngIf="canDelete()"
            [style]="'secondary'"
            [size]="'small'"
            (click)="openModal()"
            class="campaign-status__button"
          >
            {{ 'campaign.cancel.button' | translate }}
          </uni-button>
        </ng-container>
        <ng-container *ngIf="campaign.status === campaignStatus.rejected">
          <div class="campaign-status__alert">
            {{ 'status.' + campaign.status | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="campaign.status !== campaignStatus.scheduled && campaign.status !== campaignStatus.rejected">
          {{ 'status.' + campaign.status | translate }}
          <ng-container *ngIf="campaign.status === campaignStatus.sending && (getSendingPercentage(campaign) || getSendingPercentage(campaign) === 0)">
            ({{ getSendingPercentage(campaign) | percent: '1.0-2' }})
          </ng-container>
          <ng-container *ngIf="campaign.content">
            - {{ getUnitCost() }} {{ 'label.units' | translate }}
          </ng-container>
        </ng-container>
      </cc-campaign-detail>

      <cc-campaign-detail *ngIf="isRejectionReason(campaign)">
        {{ 'campaign.error.' + campaign.rejectionReason | translate }}
      </cc-campaign-detail>
    </uni-details-item>
  </uni-details>

  <uni-details>

    <uni-details-item>
      <uni-label>
        {{ 'label.contentType' | translate }}
      </uni-label>
      <cc-campaign-detail>
        {{ 'dataSource.' + campaign.type | translate }}
      </cc-campaign-detail>
    </uni-details-item>

    <uni-details-item columns="2">
      <uni-label>
        {{ 'campaign.id' | translate }}
      </uni-label>
      <cc-campaign-detail>
        <uni-clipboard [text]="campaign.id" length="10"></uni-clipboard>
      </cc-campaign-detail>
    </uni-details-item>

  </uni-details>

  <cc-campaign-progress
    *ngIf="campaign.status !== campaignStatus.rejected"
    [campaign]="campaign"
  ></cc-campaign-progress>
</uni-box-content>

<cc-campaign-cancel-modal
  *ngIf="isModalActive && campaign"
  [campaignId]="campaign.id"
  [isActive]="isModalActive"
  (isActiveChange)="isModalActive = $event"
></cc-campaign-cancel-modal>
