<div class="uni-multi-checkbox uni-multi-checkbox--{{ layout }}">
  <div *ngIf="canSelectAll" class="uni-multi-checkbox__select-all">
    <uni-checkbox [isSecondary]="true" [isWide]="false">
      <mat-checkbox
        [checked]="isAllChecked"
        [indeterminate]="isAllIndeterminate"
        (change)="onCheckAllChange($event)"
      >{{ selectAllLabel }}</mat-checkbox>
    </uni-checkbox>
  </div>

  <div class="uni-multi-checkbox__options">
    <uni-checkbox *ngFor="let option of options" [isSecondary]="true" [isWide]="false">
      <mat-checkbox
        [checked]="selection.isSelected(option.value)"
        (change)="onCheckChange(option.value)"
      >{{ option.name }}</mat-checkbox>
    </uni-checkbox>
  </div>
</div>
