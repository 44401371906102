import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniInputDirective } from '../../../uni-form/directives/input.directive';

@Component({
  selector: 'cc-campaign-recipients-manually',
  templateUrl: './campaign-recipients-manually.component.html',
  styleUrls: ['./campaign-recipients-manually.component.scss'],
})
export class CampaignRecipientsManuallyComponent {
  @Input() recipientsForm: FormGroup;
  @Input() contentForm: FormGroup;
  @Input () phoneNumbersControl: FormControl;
  @Input() isVoice;
  @Input() isEditable;
  @Input() isActive;
  @Input() groupMargin = true;
  // eslint-disable-next-line
  @Output() onSubmit = new EventEmitter();
  @ViewChild(UniInputDirective, { read: ElementRef, static: false }) input: ElementRef;
}
