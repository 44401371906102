import { isEmpty, omit } from 'lodash';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'uni-table-clear-filters',
  templateUrl: './uni-table-clear-filters.component.html',
  styleUrls: ['./uni-table-clear-filters.component.scss']
})
export class UniTableClearFiltersComponent {

  @HostBinding('class.is-visible')
  get getIsVisibleClass() {
    const { queryParams } = this.activatedRoute.snapshot;
    return !isEmpty(omit(queryParams, ['page', 'limit', 'code', 'sortBy', 'sortDirection']));
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  clearAllFilters(): void {
    this.router.navigate([], {});
  }
}
