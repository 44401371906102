import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectOperators, selectOperatorsChoice, selectOperatorsQuery } from '../store/uni-data.selectors';
import { UniDataState } from '../store/uni-data.state';
import * as UniDataActions from '../store/uni-data.actions';
import { BehaviorSubject } from 'rxjs';
import { isQueryInProgress$ } from '../../../store';
import { mergeMap } from 'rxjs/operators';
import { OperatorsParams } from '../model/operator.model';

@Injectable({ providedIn: 'root' })
export class OperatorsFacade {
  operators$ = this.store.pipe(select(selectOperators));
  operatorsQuery$ = this.store.pipe(select(selectOperatorsQuery));
  operatorsChoice$ = this.store.pipe(select(selectOperatorsChoice));

  loader$ = new BehaviorSubject(isQueryInProgress$([
    this.operatorsQuery$
  ]));

  isLoading$ = this.loader$.pipe(mergeMap(isActive => isActive));

  constructor(private store: Store<UniDataState>) {}

  setOperators(): void {
    this.store.dispatch(new UniDataActions.SetOperators());
  }

  setOperatorsChoice(params: OperatorsParams): void {
    this.store.dispatch(new UniDataActions.SetOperatorsChoice(params));
  }
}
