import { get } from 'lodash';
import { Injectable } from '@angular/core';

import { Settings } from './settings';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  readonly settings: Settings;

  constructor() {
    this.settings = new Settings();
  }

  getValue(key: string): string {
    return get(this.settings, key);
  }

  convApiUrl(isSession = true): string {
    if (!this.settings.convApiUrl) {
      return null;
    }

    return isSession
      ? `${this.settings.convApiUrl}/api/v1`
      : `${this.settings.convApiUrl}/api`;
  }

  ucApiUrl(isSession = true): string {
    if (!this.settings.ucUrl) {
      return null;
    }

    return isSession
      ? `${this.settings.ucUrl}/api/v2`
      : `${this.settings.ucUrl}/app/api/v2`;
  }

  setCCApiUrl(url: string) {
    this.settings.ccUrl = url;
  }

  ccApiUrl(isSession = true): string {
    if (!this.settings.ccUrl) {
      return null;
    }

    return isSession
      ? `${this.settings.ccUrl}/api`
      : `${this.settings.ccUrl}/app/api/v2`;
  }

  pushApiUrl(isSession = true): string {
    if (!this.settings.pushApiUrl) {
      return null;
    }

    return this.settings.pushApiUrl;
  }
}
