import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'uni-form-fields-item',
  templateUrl: './uni-form-fields-item.component.html',
  styleUrls: ['./uni-form-fields-item.component.scss']
})
export class UniFormFieldsItemComponent {
  @Input() class = '';
  @Input() columns = 2;

  @HostBinding('class') get getClass(): string {
    return `${this.class} uni-form-fields-item--${this.columns}`;
  }
}
