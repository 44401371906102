import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UniSegmentedTabsComponent } from './components/uni-segmented-tabs/uni-segmented-tabs.component';
import { UniTextModule } from '../uni-text/uni-text.module';
import { UniCellComponent } from './components/uni-cell-item/uni-cell.component';


const components = [
  UniCellComponent,
  UniSegmentedTabsComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    TranslateModule,
    UniTextModule,
  ],
  exports: [...components],
})
export class UniSegmentedTabsModule { }
