import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CampaignStatus, Campaign } from '../../shared/uni-campaign.model';
import { getSendingPercentage } from '../campaign-progress/campaign-progress.helper';

@Component({
  selector: 'cc-campaign-table-status',
  templateUrl: './campaign-table-status.component.html',
  styleUrls: ['./campaign-table-status.component.scss'],
})
export class CampaignTableStatusComponent {
  @Input() campaign: Campaign;
  @Input() isVoice = false;

  constructor(private translate: TranslateService) {}

  getStatus() {
    if (this.isVoice) {
      return this.getVoiceStatus();
    }

    switch (this.campaign.status) {
      case CampaignStatus.sending: {
        const progress = getSendingPercentage(this.campaign) * 100;
        return `${this.translate.instant(`status.${this.campaign.status}`)} (${progress.toFixed(2)}%)`;
      }
      default: {
        return this.translate.instant(`status.${this.campaign.status}`) || '-';
      }
    }
  }

  getVoiceStatus() {
    return this.translate.instant(`status.${this.campaign.status}`) || '-';
  }
}
