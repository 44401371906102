import { concat, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { QueryActions } from '../../../../store';
import { UniCountriesRepository } from '../../shared/uni-countries.repository';
import * as CountriesActions from './uni-countries.actions';
import { GET_COUNTRIES_QUERY } from './uni-countries.state';

@Injectable()
export class CountriesEffects {

  constructor(
    private actions$: Actions,
    private uniCountriesRepository: UniCountriesRepository,
  ) { }

  @Effect() SetCountries$ = this.actions$
    .pipe(
      ofType<CountriesActions.SetCountries>(CountriesActions.SET_COUNTRIES),
      mergeMap(({ isPublic }) => concat(
        of(new QueryActions.QueryInProgress(GET_COUNTRIES_QUERY)),
        this.uniCountriesRepository
          .getCountries(isPublic)
          .pipe(
            mergeMap(data => concat(
              of(new QueryActions.QuerySuccess(GET_COUNTRIES_QUERY, data)),
              of(new CountriesActions.SetCountriesSuccess(data)),
            )),
            catchError(error => concat(
              of(new QueryActions.QueryFailure(GET_COUNTRIES_QUERY, error)),
            )),
          ),
        ),
      ),
    );

  @Effect() setCountriesChoice$ = this.actions$
    .pipe(
      ofType<CountriesActions.SetCountriesChoice>(CountriesActions.SET_COUNTRIES_CHOICE),
      mergeMap(() =>
        this.uniCountriesRepository
          .getCountriesChoice()
          .pipe(
            mergeMap(res => of(new CountriesActions.SetCountriesChoiceSuccess(res))),
            catchError(() => of(new CountriesActions.SetCountriesChoiceSuccess({})),
          ),
          )
      ),
    );
}
