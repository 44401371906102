<div class="tile">
    <header class="tile-title" [ngClass]="{'success': type === 'success', 'danger': type === 'danger'}">
        <div class="tile-title-value">
            {{value}}
        </div>

        <div *ngIf="explanation.length" class="tile-title-explanation">
            <i class="fa fa-regular fa-info-circle"
                [matTooltip]="explanation"
                matTooltipPosition="above"></i>
        </div>
    </header>
    <footer>
        <span class="tile-desc">
            {{label}}
        </span>
        <span class="tile-link">
            <a [routerLink]="['/', 'insights', 'log-analyser']" [queryParams]="queryParams">{{btnLabel}}</a>
        </span>
    </footer>
</div>