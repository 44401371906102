<ng-container *ngIf="form" [formGroup]="form">
  <uni-label [required]="required">
    {{ 'label.roles' | translate }}
  </uni-label>
  <uni-form-field>
    <mat-form-field>
      <mat-select
        [compareWith]="compare"
        [formControl]="control"
        [disableOptionCentering]="true"
        [placeholder]="isLoaderActive ? ('label.loading' | translate) : ('label.selectRoles' | translate)"
        multiple
      >
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ 'app.roles.' + role.name | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </uni-form-field>
</ng-container>
