import { Component, HostBinding, Input } from '@angular/core';

import { WidgetsNumberInRow } from './uni-action-widget.model';

@Component({
  selector: 'uni-action-widget',
  templateUrl: './uni-action-widget.component.html',
  styleUrls: ['./uni-action-widget.component.scss'],
})
export class UniActionWidgetComponent {
  @Input() widgetsNumberInRow: WidgetsNumberInRow = 3;

  @HostBinding('class') get getClass(): string {
    return `uni-action-widget--${this.widgetsNumberInRow}`;
  }
}
