import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectIsLoader, UniLayoutState } from '../store';
import * as uniLayoutActions from '../store/uni-layout.actions';

@Injectable({ providedIn: 'root' })
export class UniLayoutFacade {
  isLoaderActive$ = this.store.pipe(select(selectIsLoader));

  constructor(private store: Store<UniLayoutState>) {}

  setIsLoader(state: boolean): void {
    this.store.dispatch(new uniLayoutActions.SetIsLoader(state));
  }
}
