import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavItemToggleType, NavMenuItem, UniNavService } from '../../shared';

@Component({
  selector: 'uni-nav-new-item',
  templateUrl: './uni-nav-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavItemComponent {
  @Input() set item(item: NavMenuItem) {
    this.navItem = item;
    this.toggleType = item?.items?.length ? NavItemToggleType.vertical : NavItemToggleType.none;
  }
  @Input() isExpanded: boolean;

  navItem: NavMenuItem;
  toggleType: NavItemToggleType;

  constructor(private readonly uniNavService: UniNavService) { }

  onToggle(): void {
    this.setExpandedMenuItemId();
  }

  private setExpandedMenuItemId(): void {
    const expandedMenuItemId = this.isExpanded ? undefined : this.navItem.id;
    this.uniNavService.setExpandedMenuItemId(expandedMenuItemId);
  }
}
