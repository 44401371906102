import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampaignDetailsRepository } from '../../data-access/campaign-details/campaign-details.repository';
import { CampaignListRepository } from '../../data-access/campaign-list/campaign-list.repository';
import { CampaignListFetchStore } from '../../data-access/campaign-list/campaign-list.store';
import { CampaignIdSelectorStore } from './campaign-id-selector.store';

@Component({
  selector: 'app-campaign-id-selector',
  templateUrl: './campaign-id-selector.component.html',
  styleUrls: ['./campaign-id-selector.component.scss'],
  providers: [
    CampaignIdSelectorStore,
    CampaignListFetchStore,
    CampaignDetailsRepository,
    CampaignListRepository
  ]
})
export class CampaignIdSelectorComponent implements OnInit {

  constructor(
    private store: CampaignIdSelectorStore
  ) { }

  @Input() set accountId(accountId: string){
    this.store.patchState({accountId: accountId || ''})
  }

  @Input() set typeaheadConfiguration(config: any){
    this.store.patchState({config})
  }

  @Input() set value(label: string){
    this.store.patchState({label})
    this.store.patchState({defaultValue: label})
  }

  @Input() set channels(channels: string){
    this.store.patchState({channels})
  }

  @Output() valueChange: EventEmitter<any> = new EventEmitter()

  vm$ = this.store.vm$

  ngOnInit(): void {
    this.store.fetchLatest()
  }

  onValueChange(event: any){
    this.store.onFormValueChange(event)
    this.valueChange.emit(event)
  }

  onTypeaheadEvent(phrase){
    this.store.fetchCampaignsByPhrase(phrase)
  }

  reset(){
    this.store.patchState({label: ''})
  }

  onOptionsClose(){
    this.store.patchState({entities: []})
  }
}
