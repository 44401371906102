import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { concatMap, distinctUntilChanged, tap } from "rxjs/operators";
import { Channels } from "src/app/shared/store-mocks/channels.store";
import { MessageLogsFetchStore } from "../../store/message-logs-fetch.store";
import { MessageLogsFiltersStore } from './../../store/message-logs-filters.store';
import { mapResponseTypeToVisibleColumns } from '../../utils/map-responst-type-to-visible-columns';
import { MessageLogsResponseType } from "../../log-analyser.models";
import { CustomerStatus } from "src/app/shared/store-mocks/status.store";
import { ProductsLabels } from "src/app/shared/store-mocks/products.store";

type State = {
    selectedItemId: string
}

const initialState: State = {
    selectedItemId: ''
}

@Injectable()
export class MessageLogsTableStore extends ComponentStore<State>{
    constructor(
        private messageLogsFetchStore: MessageLogsFetchStore,
        private messageLogsFiltersStore: MessageLogsFiltersStore ){
        super(initialState)
    }

    //selectors



    readonly vm$ = this.select(
        this.messageLogsFiltersStore.channels$,
        (channels) => {
            return {
                channels,
                isSms: channels.includes(Channels.SMS),
                isWhatsapp: channels.includes(Channels.WHATSAPP),
                isVoice: channels.includes(Channels.VOICE),
            }
        },
        {debounce: true}
    )

}
