import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UniCampaignsFacade } from '../../shared/uni-campaigns.facade';
import { CampaignTemplate } from '../../shared/uni-campaign.model';

@Component({
  selector: 'cc-campaign-save-template-modal',
  templateUrl: './campaign-save-template-modal.component.html',
})
export class CampaignSaveTemplateModalComponent {
  @Input() form: FormGroup;
  @Input() contentControl: FormControl;
  @Input() control: FormControl;
  @Output() disable = new EventEmitter();
  @Output() isActiveChange = new EventEmitter();
  @Input() isActive = false;

  constructor(private campaignFacade: UniCampaignsFacade) {}

  onSubmit() {
    const name = this.control.value;

    if (!name.length) {
      return;
    }

    const data: CampaignTemplate = {
      body: this.contentControl.value,
      name,
    };

    this.campaignFacade.postCampaignTemplate(data);
    this.resetForm();
  }

  resetForm() {
    this.disable.emit();
    this.control.setValue(null);
    this.changeActive(false);
  }

  changeActive(isActive: boolean): void {
    this.isActive = isActive;
    this.isActiveChange.emit(isActive);
  }
}
