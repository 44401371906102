import { Component, HostBinding, Input } from '@angular/core';

import { UniSectionHeaderSize } from './uni-section-header.model';

@Component({
  selector: 'uni-section-header',
  templateUrl: './uni-section-header.component.html',
  styleUrls: ['./uni-section-header.component.scss'],
})
export class UniSectionHeaderComponent {
  @Input() size: UniSectionHeaderSize = UniSectionHeaderSize.default;
  @Input() class = '';
  @Input() isBottomSpace = true;

  @HostBinding('class') get getClasses(): string {
    return `
      ${this.class}
      uni-section-header--${this.size}
      ${this.isBottomSpace ? '' : 'uni-section-header--no-space'}
    `;
  }
}
