import { get } from 'lodash';
import * as moment from 'moment-timezone';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { DateUtils } from '../../../../utils';
import { DatepickerControl } from './uni-datepicker.model';

@Injectable({ providedIn: 'root' })
export class UniDatepickerService {
  beginDateKey = 'startDate';
  endDateKey = 'endDate';

  constructor(private activatedRoute: ActivatedRoute) { }

  getDates(
    beginDateKey = this.beginDateKey,
    endDateKey = this.endDateKey,
  ) {
    const { queryParams } = this.activatedRoute.snapshot;
    const begin = get(queryParams, beginDateKey) || DateUtils.getStartOfDay(moment().subtract(1, 'months'));
    const end = get(queryParams, endDateKey) || DateUtils.getStartOfDay(moment());

    return { begin, end };
  }

  parseData(
    values: DatepickerControl,
    beginDateKey = this.beginDateKey,
    endDateKey = this.endDateKey,
  ): Params {
    const begin = get(values, 'startDate');
    const end = get(values, 'endDate');

    return (!begin || !end)
      ? {
        [beginDateKey]: DateUtils.getStartOfDay(moment().subtract(1, 'months')),
        [endDateKey]: DateUtils.getEndOfDay(moment())
      } : {
        [beginDateKey]: DateUtils.getStartOfDay(moment(begin)),
        [endDateKey]: DateUtils.getEndOfDay(moment(end)),
      };
  }
}
